import { toast } from "react-toastify";
import { API, get } from "../../../config";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const getUserPopupDetail = async (userId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_USER}/${userId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.GET_USER}/${userId}`);
      return data.data;
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(
        data.errors && data.errors.myna_error && data.errors.myna_error[0]
      );
    }
    return null;
  }
};

export const useOfficeList = (client_id) => {
  const [officeList, setOfficeList] = useState([]);
  const [officeLoading, setOfficeLoading] = useState(false);
  const params = useParams();
  const clientIDParams = params?.client_id;
  const clientID = localStorage.getItem('sme-clientId') ? localStorage.getItem('sme-clientId') : clientIDParams

  const init = useCallback(async () => {
    try {
      setOfficeLoading(true);
      const { data } = await get(`${API.GET_OFFICE_LIST}?page=1&limit=-1${clientID ? `&client_id=${clientID}` : client_id ? `&client_id=${client_id}`: ''}`);
      setOfficeLoading(false);
      setOfficeList(data.data.offices.data);
    } catch (e) {
      setOfficeList([]);
      setOfficeLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeLoading,
  };
};

export const useRoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRoleLoading(true);
      const { data } = await get(`${API.GET_ROLE_LIST}?page=1&limit=-1`);
      setRoleLoading(false);
      setRoleList(data.data);
    } catch (e) {
      setRoleList([]);
      setRoleLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    roleList,
    roleLoading,
  };
};

export const useBusinessList = () => {
  const [businessList, setBusinessList] = useState([]);
  const [businessLoading, setBusinessLoading] = useState(false);
  const params = useParams();
  const clientIDParams = params?.client_id;
  const clientID = localStorage.getItem('sme-clientId') ? localStorage.getItem('sme-clientId') : clientIDParams
  const init = useCallback(async () => {
    try {
      setBusinessLoading(true);
      const { data } = await get(`${API.GET_BUSINESS}?page=1&limit=-1${clientID ? `&client_id=${clientID}` : ''}`);
      setBusinessLoading(false);
      setBusinessList(data.data.data);
    } catch (e) {
      setBusinessList([]);
      setBusinessLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    businessList,
    businessLoading,
  };
};

export const useUserList = (client_id) => {
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const params = useParams();
  const clientIDParams = params?.client_id;
  const clientID = localStorage.getItem('sme-clientId') ? localStorage.getItem('sme-clientId') : clientIDParams
  const init = useCallback(async () => {
    try {
      setUserLoading(true);
      const { data } = await get(`${API.GET_CLIENT_USER}?page=1&limit=-1${clientID ? `&client_id=${clientID}` : client_id ? `&client_id=${client_id}`: ''}`);
      setUserLoading(false);
      setUserList(data.data.data);
    } catch (e) {
      setUserList([]);
      setUserLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    userList,
    userLoading,
  };
};

export const getUserDetail = async (userId, clientID) => {
  try {
    const { data } = await get(`${clientID ? API.GET_CLIENT_USER : API.USER}/` + userId);
    return data.data;
  } catch (e) {
    return null;
  }
};
