import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { DatePicker } from "rsuite";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  businessId,
  toggleBankModal,
  accountLoading,
  accountData,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    businessId: PropTypes.any,
    toggleBankModal: PropTypes.any,
    accountLoading: PropTypes.any,
    accountData: PropTypes.any,
  };

  // const [accountLoading, setAccountLoading] = useState(false);
  // const [accountData, setAccountData] = useState([]);

  const transactionType = [
    { label: "Credit", value: 1 },
    { label: "Debit", value: 0 },
  ];

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Account
          </label>
          <Select
            name="account"
            value={values?.account ? values?.account : ""}
            onChange={(e) => {
              setFieldValue("account", e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={accountData}
            isLoading={accountLoading}
            placeholder="Select Account"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="account" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Transaction Type
          </label>
          <Select
            // type="select"
            name="baseType"
            value={values?.baseType ? values?.baseType : ""}
            onChange={(e) => {
              setFieldValue("baseType", e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={transactionType}
            placeholder="Select Transaction Type"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="baseType" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Transaction Date
          </label>
          <DatePicker
            onChange={(e) => setFieldValue('transactionDate', moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"))}
            format="dd-MM-yyyy"
            placeholder="Select Transaction Date"
            disabledDate={date => moment(date).isAfter(moment())}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="transactionDate" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Amount
          </label>
          <input
            type="text"
            name="amount"
            value={values?.amount ? values?.amount : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="amount" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Description
          </label>
          <input
            type="text"
            name="description"
            value={values?.description ? values?.description : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="description" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleBankModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
