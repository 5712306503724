import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TrialBalanceReport from "./TrialBalanceReport";
import GeneralLedgerReport from "./GeneralLedgerReport";
import PLReport from "./PLReport"
import { useEffect } from "react";
import { metaTitle } from "../../../config";
import BalanceSheetReport from "./BalanceSheetReport";

const tabsKey = {
  "trial-balance": 1,
  "general-ledger": 2,
  "pl-report": 3,
  "balance-sheet": 4
};

const FinancialReportTab = () => {
  const location = useLocation();
  const params = useParams();
  const businessId = params?.business_id;
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const selectedTab = query.get("tab") || "trial-balance";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Trial Balance"
      : parseInt(defaultActiveKey) === 2
      ? "General Ledger"
      : parseInt(defaultActiveKey) === 3
      ? "P&L Report"
      : "Balance Sheet"
  );

  // For the financial year
  // Oct-Dec = 1
  // Jan-Mar = 2
  // Apr-Jun = 3
  // Jul-Sep = 4
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let date = new Date();
  let quarter = getQuarter(date);
  let defaultMonthRange = [];
  if(quarter === 1){
    defaultMonthRange = [new Date(date.getFullYear(), 9, 1), new Date(date.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    defaultMonthRange = [new Date(date.getFullYear(), 0, 1), new Date(date.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    defaultMonthRange = [new Date(date.getFullYear(), 3, 1), new Date(date.getFullYear(), 6, 0)]
  } else {
    defaultMonthRange = [new Date(date.getFullYear(), 6, 1), new Date(date.getFullYear(), 9, 0)]
  }

  const [ dateRangeReport, setDateRangeReport ] = useState(defaultMonthRange)

  useEffect(() => {
    window.document.title = `${metaTitle} | ${activeTab}`
  },[activeTab])

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                    navigate(
                      `/business/${businessId}/financial-reports?tab=${
                        event?.target?.innerText === "Trial Balance" ? "trial-balance" :
                        event?.target?.innerText === "General Ledger" ? "general-ledger" :
                        event?.target?.innerText === "Balance Sheet" ? "balance-sheet" :
                        "pl-report"
                      }`
                    );
                  }}
                >
                  <li
                    className={
                      activeTab === "Trial Balance" ? "active" : ""
                    }
                  >
                    <Link
                      key={1}
                    >
                      Trial Balance
                    </Link>
                  </li>
                  <li
                    className={activeTab === "General Ledger" ? "active" : ""}
                  >
                    <Link
                      key={2}
                    >
                      General Ledger
                    </Link>
                  </li>
                  <li className={activeTab === "P&L Report" ? "active" : ""}>
                    <Link
                      key={3}
                    >
                      P&L Report
                    </Link>
                  </li>
                  <li className={activeTab === "Balance Sheet" ? "active" : ""}>
                    <Link
                      key={4}
                    >
                      Balance Sheet
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Trial Balance" && (
                  <TrialBalanceReport setDateRangeReport={setDateRangeReport} dateRangeReport={dateRangeReport} />
                )}
                {activeTab === "General Ledger" && (
                  <GeneralLedgerReport  setDateRangeReport={setDateRangeReport} dateRangeReport={dateRangeReport} />
                )}
                {activeTab === "P&L Report" && (
                  <PLReport setDateRangeReport={setDateRangeReport} dateRangeReport={dateRangeReport} />
                )}
                {activeTab === "Balance Sheet" && (
                  <BalanceSheetReport setDateRangeReport={setDateRangeReport} dateRangeReport={dateRangeReport} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialReportTab;
