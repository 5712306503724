import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, FormControl, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, fileUpload, get, metaTitle } from "../../../config";
import BootStrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import ClientActionButton from "./components/ClientActionButton";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import Business from "../Business/Business";
import { formatMobile, formatName, formatPhone } from "../../common/Misc";
import * as XLSX from 'xlsx';
import cellEditFactory from 'react-bootstrap-table2-editor';
// import { Type } from 'react-bootstrap-table2-editor';
// import paginationFactory from "react-bootstrap-table2-paginator";
import UploadFile from "../../common/UploadFile";
import BootstrapTable from "react-bootstrap-table-next";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { DragIndicatorOutlined } from "@material-ui/icons";
import TitleBar from "../../common/TitleBar";
// import InputMask from "react-input-mask";

const Clients = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [clientsList, setClientsList] = useState([]);
  const [clientLoading, setClientLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelClientData, setExcelClientData] = useState();

  const handleDelete = () => {
    fetchClients();
  };

  /**
   * Client List API
   */
  const fetchClients = useCallback(async () => {
    try {
      setClientLoading(true);
      const { data } = await get(
        `${API.GET_CLIENTS}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}`
      );
      setClientLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const clientsList = data.data.data;
      setClientsList(clientsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setClientLoading(false);
      setClientsList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, searchParam, sortOrder, sortField]);

  /**
   *
   * @param {Number of Total Records} quantity
   * @returns Clients List
   */
  const clientsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: clientsList[i]?.id,
        client_id: clientsList[i]?.client?.id ? clientsList[i]?.client?.id : "",
        // name: (
        //   <Link
        //     to={{
        //       pathname: `/client-dashboard/${clientsList[i]?.client?.id}`,
        //     }}
        //   >
        //     {formatName(clientsList[i]?.first_name, clientsList[i]?.last_name)}
        //   </Link>
        // ),
        name: (
          // <Link
          //   to={{
          //     // pathname: `/businesses/${clientsList[i]?.id}`,
          //     pathname: `/client/${clientsList[i]?.client?.id}`,
          //   }}
          // >
            <>
              {clientsList[i]?.client?.head_client?.business_name ? clientsList[i]?.client?.head_client?.business_name : formatName(clientsList[i]?.first_name, clientsList[i]?.last_name)}
            </>
          // </Link>
        ),
        email: clientsList[i]?.client?.head_client?.email ? clientsList[i]?.client?.head_client?.email : clientsList[i]?.email,
        phone: clientsList[i]?.client?.head_client?.phone ? formatPhone(clientsList[i]?.client?.head_client?.phone) : clientsList[i]?.phone ? formatPhone(clientsList[i]?.phone) : "-",
        mobile: clientsList[i]?.mobile
          ? formatMobile(clientsList[i]?.mobile)
          : "-",
        crn: clientsList[i]?.client?.crn ? clientsList[i]?.client?.crn : "-",
        action: (
          <ClientActionButton
            id={clientsList[i]?.id}
            handleDelete={handleDelete}
            email={clientsList[i]?.email}
            status={clientsList[i]?.status}
            name={clientsList[i]?.first_name ? formatName(clientsList[i]?.first_name, clientsList[i]?.last_name) : (clientsList[i]?.client?.business_name ? clientsList[i]?.client?.business_name : formatName(clientsList[i]?.first_name, clientsList[i]?.last_name))}
          />
        ),
      });
    }
    return items;
  };
  const clients = clientsGenerator(clientsList?.length);

  /**
   * Table Heading Data
   */
  const columns = [
    // {
    //   dataField: "clientId",
    //   text: "",
    // },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      sort: true,
    },
    {
      dataField: "crn",
      text: "CRN",
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  /**
   * Table Change For Sorting
   */
  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    navigate(
      `/clients?limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  useEffect(() => {
    fetchClients();
    window.document.title = `${metaTitle} | Clients`
  }, [fetchClients]);

  /**
   * Search
   */

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );
  
  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  /**
   * Clients Limit Per Page
   */
  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  /**
   * Pagination
   */
  const handleChangePage = (page) => {
    setPage(page);
  };

  /**
   * Expand Row
   */
  const expandRow = {
    renderer: (row) => (
      <>
        <Business id={row?.client_id} setClientsList={setClientsList} fetchClients={fetchClients}/>
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  /**
   * Download
   */
  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(API.DOWNLOAD_CLIENT_TEMPLATE);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "businessess.xls"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  /**
   * Import Module
   */
  const handleImportModal = async () => {
    setShowImport(true);
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const handleExcelClose = () => {
    setSelectedRow([]);
    setSelectedMaxRowRecord([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const ExcelBusinessColumns = [
    {
      dataField: "order",
      text: "",
      editable: false,
    },
    {
      dataField: "first_name",
      text: "First Name",
      editCellClasses: 'edit-cell-excel-class',
      classes: (cell, row) => {
        return `${row.key}`
      }
    },
    {
      dataField: "last_name",
      text: "Last Name",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue, row) => {
        if(row.client_business === 0){
          if(newValue === '' || newValue === undefined || newValue === null){
            return {
              valid: false,
              message: 'Last Name is Required'
            };    
          }
        }
        return true;
      }
    },
    {
      dataField: "crn",
      text: "Client Ref.",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue === '' || newValue === undefined || newValue === null){
          return {
            valid: false,
            message: 'CRN is Required'
          };    
        }
        if(newValue.length > 20){
          return {
            valid: false,
            message: 'Client Ref. can contain maximum 20 character'
          };
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.length > 10){
            return "error-cell crn"
          }
          return "crn"
        }
      },
      editable: (cell, row) => {
        if(row.client_business === 0){
          return true
        } else {
          return false
        }
      }
    },
    {
      dataField: "ref",
      text: "Business Ref.",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue, row) => {
        if(row.client_business === 1){
          if(newValue === '' || newValue === undefined || newValue === null){
            return {
              valid: false,
              message: 'Business Ref. is Required'
            };    
          }
        }
        if(newValue.length > 20){
          return {
            valid: false,
            message: 'Business Ref. can contain maximum 20 character'
          };
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.length > 20){
            return "error-cell crn"
          }
        }
      },
    },
    {
      dataField: "business_name",
      text: "Business Name",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue === '' || newValue === undefined || newValue === null){
          return {
            valid: false,
            message: 'Business Name is Required'
          };    
        }
        if(!newValue.match(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/)){
          return {
            valid: false,
            message: 'Business name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.'
          };
        }
        return true;
      }
    },
    {
      dataField: "email",
      text: "Email",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!newValue.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            return {
              valid: false,
              message: 'Please enter a valid email address'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(!String(cell).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "phone",
      text: "Phone",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(newValue.split(" ").join("").length !== 12){
            return {
              valid: false,
              message: 'Please enter valid phone number'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.split(" ").join("").length !== 12){
            // toast.error("Enter 9 Digit Phone Number")
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "mobile",
      text: "Mobile",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(newValue.split(" ").join("").length !== 12){
            return {
              valid: false,
              message: 'Please enter valid phone number'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.split(" ").join("").length !== 12){
            // toast.error("Enter 9 Digit Mobile Number")
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "abn",
      text: "ABN",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if(!String(newValue).split(" ").join("").match(/^[\d]{11}$/)){
            return {
              valid: false,
              message: 'Enter 11 Digit ABN Number'
            };
          }
          if(newValue.split(" ").join("").length === 11){
            let newNumber = newValue.split(" ").join("")
            let sum = 0
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit 
            })
            if((sum%89) !== 0){
             return {
              valid: false,
              message: 'Enter Valid ABN Number'
             }
            }
          }
        }
      },
      classes: (cell) => {
        if(cell){
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if(cell.split(" ").join("").length !== 11){
            // toast.error("Enter 11 Digit ABN Number")
            return "error-cell"
          }
          if(cell.split(" ").join("").length === 11){
            let newNumber = cell.split(" ").join("")
            let sum = 0
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit 
            })
            if((sum%89) !== 0){
             return "error-cell"
            }
          }
        }
      }
    },
    {
      dataField: "street",
      text: "Street",
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: "state",
      text: "State",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "suburb",
      text: "Suburb",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!newValue.match(/^[\d]{4}$/)){
            return {
              valid: false,
              message: 'Postal Code can contain 4 digit numbers.'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(!String(cell).match(/^[\d]{4}$/)){
            return "error-cell"
          }
        }
      }
    }
  ]

  const [ selectedRow, setSelectedRow ] = useState([]);
  const [ selectMaxRowRecord, setSelectedMaxRowRecord ] = useState([]);
  const [ businessCount, setBusinessCount ] = useState(0);


  let singleSelectedRows = [];
  let allSelectedRows = [];
  let selectedMaxRecord = [];
  let allSelectedMaxRecord = [];

  function removeDuplicates(arr) { 
    return [...new Set(arr)]; 
  }

  const businessSelectCount = (mainArray, removeArray) => {
    let businessSelectedArray = mainArray?.filter( function( el ) {
      return removeArray?.indexOf( el ) < 0;
    } );
    setBusinessCount(businessSelectedArray?.length)
  }

  useEffect(() => {
    businessSelectCount(selectedRow, selectMaxRowRecord)
    // eslint-disable-next-line
  },[selectedRow, selectMaxRowRecord])

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ['not-select'],
    selected: selectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if(selectMaxRowRecord){
        selectedMaxRecord=[...selectMaxRowRecord];
      }
      if (isSelect) {
        if(row.client_business === 0){
          let data = excelClientData.filter((i) => i.crn === row.crn)
          let keys = [];
          // eslint-disable-next-line
          data.map((i) => {
            keys.push(i.key)
          })
          let rowKey = removeDuplicates([...selectedRow, ...keys])
          singleSelectedRows = rowKey;
          selectedMaxRecord = [...selectMaxRowRecord, row?.key];
        } else {
          let data = excelClientData.filter((i) => i.crn === row.crn)
          let parentData = data.find((i) => i.client_business === 0)
          if(selectedRow.includes(parentData.key)){
            singleSelectedRows = [...selectedRow, row?.key]
          } else {
            singleSelectedRows = [...selectedRow, row?.key, parentData.key]
            selectedMaxRecord = [...selectMaxRowRecord, parentData?.key]
          }
        }
      } else {
        if(row.client_business === 0){
          let data = excelClientData.filter((i) => i.crn === row.crn)
          let keys = [];
          // eslint-disable-next-line
          data.map((i) => {
            keys.push(i.key)
          })
          let rowKey = selectedRow.filter(function(el) {
            return !keys.includes(el);
          });
          singleSelectedRows = rowKey;
          selectedMaxRecord?.splice(selectMaxRowRecord?.indexOf(row?.key), 1);
        } else {
          singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
        }
      }
      setSelectedRow(singleSelectedRows);
      setSelectedMaxRowRecord(selectedMaxRecord);
    },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
          } else {
            allSelectedRows.push(rows[i].key);
          }
          if(rows[i].client_business === 0){
            if(selectMaxRowRecord.includes(rows[i].key)){
            } else {
              allSelectedMaxRecord.push(rows[i].key);
            }
          }
        }
      }
      else {
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
          if(rows[i].client_business === 0){
            if(selectMaxRowRecord.includes(rows[i].key)){
              selectMaxRowRecord.splice(selectMaxRowRecord.indexOf(rows[i].key), 1);
            } 
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
      setSelectedMaxRowRecord([...selectMaxRowRecord, ...allSelectedMaxRecord]);
    }
  };

  const ref = useRef();

  const phoneFormat = (value) => {
    if(String(value)?.match(/\+61/)){
      return `+${Number(String(value)?.split(" ")?.join("")).toLocaleString('en-US').replaceAll(",", " ")}`;
    } else {
      return `+61 ${Number(String(value)?.split(" ")?.join("")).toLocaleString('en-US').replaceAll(",", " ")}`;
    }
  }

  const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);

  const ExcelReader = () => {
    let f = files
    const reader = new FileReader();
    let row = null;
    reader.onload = async(evt) => { // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      row = await XLSX.utils.sheet_to_json(ws, {header:1});
      let data = [];
      if(
        row[0][0] !== "First Name" ||
        String(row[0][1]).replace('*', '') !== "Last Name" ||
        String(row[0][2]).replace('*', '') !== "Client Reference" ||
        String(row[0][3]).replace('*', '') !== "Business Reference" ||
        String(row[0][4]).replace('*', '') !== "Business Name" ||
        row[0][5] !== "Email" ||
        row[0][6] !== "Phone" ||
        row[0][7] !== "Mobile" ||
        row[0][8] !== "abn" ||
        row[0][9] !== "Street" ||
        row[0][10] !== "State" ||
        row[0][11] !== "Suburb" ||
        row[0][12] !== "Postal Code"
      ){
        setShowExcelModal(false);
        setFiles();
        setIsImportLoading(false);
        toast.error("Sorry, File is not in the given format, please download the latest format again");
      } else {
        for(let j=1; j<row.length; j++){
          let intialObject = {
            key: j,
            first_name: row[j][0] ? row[j][0] : "",
            last_name: row[j][1] ? row[j][1] : "",
            crn: row[j][2] ? row[j][2] : "",
            ref: row[j][3] ? row[j][3] : "",
            business_name: row[j][4] ? row[j][4] : "",
            email: row[j][5] ? row[j][5] : "",
            phone: row[j][6] ? row[j][6] : "",
            mobile: row[j][7] ? row[j][7] : "",
            abn: row[j][8] ? row[j][8] : "",
            street: row[j][9] ? row[j][9] : "",
            state: row[j][10] ? row[j][10] : "",
            suburb: row[j][11] ? row[j][11] : "",
            postal_code: row[j][12] ? row[j][12] : "",
          }
          if(row[j][3] && !row[j][1]){
            intialObject.client_business = 1
          } else {
            intialObject.client_business = 0
          }
          data.push(intialObject);
        }
        let finalData = data

        /* Update state */
        let selectItem = []
        let parentItem = []
        const businessExcelGenerator = (quantity) => {
          const items = [];
          for (let i = 0; i < quantity; i++) {
            // if(finalData[i]?.client_business === 0){
            selectItem.push(i);
            if(finalData[i]?.client_business === 0){
              parentItem.push(i);
            }
            items.push({
              key: i,
              order: finalData[i]?.client_business === 1 ? <DragIndicatorOutlined className="draggable handle" type="swap" /> : <></>,
              first_name: finalData[i]?.first_name ? finalData[i]?.first_name : "",
              last_name: finalData[i]?.last_name ? finalData[i]?.last_name : "",
              business_name: finalData[i]?.business_name ? finalData[i]?.business_name : "",
              email: finalData[i]?.email ? finalData[i]?.email : "",
              phone: finalData[i]?.phone ? phoneFormat(finalData[i]?.phone) : "",
              mobile: finalData[i]?.mobile ? phoneFormat(finalData[i]?.mobile) : "",
              abn: finalData[i]?.abn ? `${Number(String(finalData[i]?.abn)?.split(" ")?.join("")).toLocaleString("en-US").replaceAll(",", " ")}` : "",
              crn: finalData[i]?.crn ? String(finalData[i]?.crn) : "",
              ref: finalData[i]?.ref ? finalData[i]?.ref : "",
              street: finalData[i]?.street ? finalData[i]?.street : "",
              state: finalData[i]?.state  ? finalData[i]?.state : "",
              suburb: finalData[i]?.suburb ? finalData[i]?.suburb : "",
              postal_code: finalData[i]?.postal_code ? finalData[i]?.postal_code : "",
              // businesses: finalData[i + 1] && finalData[i + 1]?.client_business !== 0 ? finalData[i + 1] : "",
              client_business: finalData[i]?.client_business === 0 ? 0 : 1,
            });
          // }
          }
          return items;
        };
        setSelectedRow(selectItem)
        setSelectedMaxRowRecord(parentItem)
        setExcelClientData(businessExcelGenerator(finalData?.length))
      }
    };
    reader.readAsBinaryString(f);
  }

  useEffect(() => {
    showExcelModal && ExcelReader();
    // eslint-disable-next-line
  },[showExcelModal])

  const handleExcelModal = () => {
    setShowExcelModal(true)
  }

  const handleUpload = async () => {
    if(selectMaxRowRecord.length > 500){
      toast.warn("Maximum limit to import clients is 500")
    } else {
    if (files) {
      setShowExcelModal(true)
      const formData = new FormData();
      let data = excelClientData
      let row = []
      for(let i=0; i<selectedRow.length; i++){
        let item = data.find((item) => item.key === selectedRow[i])
        let rowPhone = item.phone.split(" ")
        let rowMobile = item.mobile.split(" ")
        item.phone = rowPhone.join("")
        item.mobile = rowMobile.join("")
        item.abn = item.abn.split(" ").join("")
        delete item.order;
        row.push(item)
      }
      let crnArray = row?.filter((item) => item.client_business === 0);
      for(let p=0; p<crnArray.length; p++){
        crnArray[p].businesses =  row.filter((item) => item.crn === crnArray[p].crn && item.client_business === 1)
        formData.append(`clients[${p}]`, JSON.stringify(crnArray[p]))
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      try {
        setIsImportLoading(true);
        const { data } = await fileUpload(
          `${API.IMPORT_CLIENT}`,
          formData,
          config
        );
        setIsImportLoading(false);
        if (data) {
          toast.success(data?.message);
          setSelectedRow([]);
          setSelectedMaxRowRecord([]);
          handleExcelClose();
          fetchClients();
          handleImportClose();
        }
        return data?.data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
          if(error?.response?.data?.data?.failedData.length > 0){
            let finalData = []
            for(let i=0; i<error?.response?.data?.data?.failedData?.length; i++){
              let parentData = error?.response?.data?.data?.failedData[i]?.data
              if(parentData?.phone)  {
                parentData.phone = `+${Number(parentData?.phone).toLocaleString("en-US").replaceAll(",", " ")}`
              } else {
                parentData.phone = ""
              }
              if(parentData?.mobile)  {
                parentData.mobile = `+${Number(parentData?.mobile).toLocaleString("en-US").replaceAll(",", " ")}`
              } else {
                parentData.mobile = ""
              }
              if(parentData?.abn)  {
                parentData.abn = `${Number(parentData?.abn).toLocaleString("en-US").replaceAll(",", " ")}`
              } else {
                parentData.abn = ""
              }
              finalData.push(parentData)
              if(parentData?.businesses) {
                // eslint-disable-next-line
                parentData?.businesses?.map((item) => 
                  {
                  if(item?.phone)  {
                    item.phone = `+${Number(item?.phone).toLocaleString("en-US").replaceAll(",", " ")}`
                  } else {
                    item.phone = ""
                  }
                  if(item?.mobile)  {
                    item.mobile = `+${Number(item?.mobile).toLocaleString("en-US").replaceAll(",", " ")}`
                  } else {
                    item.mobile = ""
                  }
                  item.order = <DragIndicatorOutlined className="draggable" type="swap" />
                  finalData.push(item);
                })
              }
              if(error?.response?.data?.data?.failedData[i]?.businesses){
                for(let j=0; j<error?.response?.data?.data?.failedData[i]?.businesses?.length; j++){
                  let business = error?.response?.data?.data?.failedData[i]?.data?.businesses[j];
                  if(business?.phone)  {
                    business.phone = `+${Number(business?.phone).toLocaleString("en-US").replaceAll(",", " ")}`
                  } else {
                    business.phone = ""
                  }
                  if(business?.mobile)  {
                    business.mobile = `+${Number(business?.mobile).toLocaleString("en-US").replaceAll(",", " ")}`
                  } else {
                    business.mobile = ""
                  }
                  if(business?.abn)  {
                    business.abn = `${Number(business?.abn).toLocaleString("en-US").replaceAll(",", " ")}`
                  } else {
                    business.abn = ""
                  }
                  business.order = <DragIndicatorOutlined className="draggable" type="swap" />
                  finalData.push(business)
                }
              }
            }
            setSelectedRow([]);
            setSelectedMaxRowRecord([]);
            setExcelClientData(finalData);
          }
          fetchClients();
          setIsImportLoading(false);
        } else {
          toast.error(error?.message);
          setIsImportLoading(false);
        }
      }
    }
    }
  };

  const handleExcelTableChange = async(type, { data, cellEdit: { rowId, dataField, newValue } }) => {
    const getWithPromiseAll = async() => {
      let temp = await Promise.all(
        data.map(async (row) => {
          let newCRN = null;
          const newRow = { ...row };
          if (row?.key === rowId) {
            if(dataField !==  'crn'){
              newRow[dataField] = newValue;
            } 
            if(dataField ===  'crn') {
              newCRN = row.crn
              newRow[dataField] = newValue;
            }
            if(dataField === 'mobile' || dataField === 'phone'){
              newRow[dataField] = phoneFormat(newValue)
            } else if(dataField === 'abn'){
              newRow[dataField] = `${Number(newValue.split(" ").join("")).toLocaleString("en-US").replaceAll(",", " ")}`
            }
          }
          if(dataField === 'crn'){
            let tempData = excelClientData.filter((i) => i.crn === newCRN)
            // eslint-disable-next-line
            tempData.map((i) => {
              if(i.crn === newCRN){
                i[dataField] = newValue
              }
            })
          }
          if(row?.key === rowId){
            return newRow;
          }
          return row;
        })
      )
      return temp
   }
    const result = await getWithPromiseAll();
    setExcelClientData(result);
  }

  const handleReorder = (dragIndex, draggedIndex, el) => {
    setExcelClientData((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      let temp = el.previousSibling;
      let notes = null
      for (var i = 0; i < temp.childNodes.length; i++) {
        if (temp.childNodes[i]?.className === "crn") {
          notes = temp.childNodes[i]?.innerHTML;
          break;
        }        
      }
      item.crn = notes;
      newState.splice(draggedIndex, 0, item);
      return newState;
    });
  };

  useEffect(() => {
    let start;
    let end;
    const container = document.querySelector(".excel-table-list .react-bootstrap-table tbody");
    const drake = dragula([container], {
      moves: (el, container, handle) => {
        // if(el?.childNodes[1]?.childNodes[0] !== undefined){
          start = getIndexInParent(el);
          return handle.classList.contains('handle');
        // }
      },
    });

    drake.on("drop", async(el) => {
      end = getIndexInParent(el);
      await handleReorder(start, end, el);
    });
    //eslint-disable-next-line
  }, [showExcelModal]);

  return (
    <>
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar
            label={"Clients"}
            businessNameData={false}
            globalSearch={false}
          /> 
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={handleSearchChange}
                    type="text"
                    value={searchParamData}
                    placeholder="Search Client"
                  />
                  {searchParamData 
                  ? <Button className="btn-close" onClick={() => {
                    setSearchParamData("")
                    setSearchParam("")
                  }}
                  >x</Button> 
                  : <Button className="fa fa-search">Search</Button>}
                </Form>
              </div>
              <div className="table-btn">
                {/* {role === "Accounting Firm" && (
                  <Button
                    className="small headerbtn"
                    // onClick={() => {
                    //   handleInviteAccountingFirm();
                    // }}
                    // disabled={accountingFirmId ? true : false}
                  >
                    Invite Client
                  </Button>
                )} */}
                <div className="border-btn" style={{ alignItems: "center", maxHeight: "39px"}}>
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p>Last Name - Required</p>
                      <p>Business Name - Required</p>
                      <p>CRN - Required</p>
                    </Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="link"
                    disabled={isLoadingDownload}
                    onClick={() => handleDownload()}
                    className="download-template"
                  >
                    Download Template
                  </Button>
                  <Button variant="primary" 
                  onClick={handleImportModal}
                  >
                    Import Clients
                  </Button>
                </div>
                <Button
                  variant="primary"
                  onClick={() => navigate("/add-client")}
                >
                  Add New
                </Button>

                {/* <Button variant="primary">Import</Button> */}
              </div>
            </div>
            <div className="custom-table">
              {clientLoading && <Spin />}
              <BootStrapTable
                keyField="client_id"
                remote
                data={clients}
                columns={columns}
                onTableChange={handleTableChange}
                expandRow={expandRow}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {showImport && (
      <Modal
        size="lg"
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Import Clients
        </Modal.Header>
        <Modal.Body>
          <div>
            <UploadFile
              isLoading={isImportLoading}
              setFiles={setFiles}
              files={files}
              handleUpload={handleExcelModal}
              handleCancel={handleImportClose}
              acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ErrorMessage="xls and xlsx"
            />
          </div>
        </Modal.Body>
      </Modal>
    )}
    {showExcelModal && (
      <Modal
        // size="xl"
        show={showExcelModal}
        onHide={handleExcelClose}
        dialogClassName="full-popup large-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Import Clients
        </Modal.Header>
        <Modal.Body>
          <div className="excel-table-list" style={{padding: "20px"}}>
            {isImportLoading && <Spin />}
            <BootstrapTable
              keyField="key"
              selectRow={selectRow}
              ref={ref}
              // expandRow={excelExpandRow}
              remote={{cellEdit: true}}
              data={excelClientData ? excelClientData : []}
              columns={ExcelBusinessColumns}
              noDataIndication="No Data Found"
              cellEdit={ cellEditFactory({
                mode: 'click',
                blurToSave: true,
                timeToCloseMessage: 30000
              }) }
              onTableChange={handleExcelTableChange}
              // pagination={paginationFactory({
              //   sizePerPage: 10, 
              //   sizePerPageList: [ 
              //     {
              //       text: '10', value: 10
              //     }, 
              //     {
              //       text: '25', value: 25
              //     },
              //     {
              //       text: '50', value: 50
              //     },
              //     {
              //       text: '100', value: 100
              //     }
              //   ],
              //   showTotal: true,
              //   alwaysShowAllBtns: true,
              //   // nextPageText: (<>Next <i class="fa fa-angle-right"></i></>),
              //   // prePageText: (<><i class="fa fa-angle-left"></i> Prev </>)
              // })}
            />
            <div className="excel-list-footer">
              <Button
                variant="primary"
                onClick={handleUpload}
                data-toggle="modal"
                data-target="#business"
                disabled={selectedRow.length === 0 ? true : false}
              >
                Import
              </Button>
              <div style={{display: "flex", alignItems: "center", gap: 15}}>
                <div>Selected Client(s): {selectMaxRowRecord?.length}</div>
                <div>Selected Business(s): {businessCount}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
    }
    </>
  );
};

export default Clients;
