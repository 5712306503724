import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';
import Select from "react-select";
import { Button, Modal } from 'react-bootstrap';
import Spin from '../../../common/Spin';
import { API, fileUpload, get } from '../../../../config';
import { AddDiscountSchema } from '../../../common/Validation';


const UpdateDiscountCode = ({ discountId, businessId, showEdit, toggleEditModal, handleEdit }) => {
  const formValues = useRef();
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState(null);

  const discountTypeList = [
    { label: '%', value: 1 },
    { label: 'Flat Amount', value: 0 }
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const {data} = await get(`${API.GET_DISCOUNT_CODE}/${discountId}`);
        setDiscountData(data?.data);
        setLoading(false);
      } catch (e) {
        setDiscountData(null);
      }
    }
    fetchData();
  }, [discountId]);

  const discountTypeValue = discountTypeList?.find(
    (item) => item?.value === discountData?.discount_type
  );

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.discount_type = value?.discount_type?.value
    formData.append('client_business_id', businessId ? businessId : '');
    formData.append('code', value?.code ? value?.code : '');
    formData.append('description', value?.description ? value?.description : '');
    formData.append('discount_type', value?.discount_type);
    formData.append('discount', value?.discount ? value?.discount : '');
    formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.UPDATE_DISCOUNT_CODE}/${discountId}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleEdit();
        toggleEditModal();
      }
      return data?.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showEdit}
        onHide={toggleEditModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Discount Code
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              code: discountData?.code ,
              description: discountData?.description,
              discount_type: discountTypeValue,
              discount: discountData?.discount,
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddDiscountSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                    <div className="form-box">
                      <div className="form-group tri-width">
                        <label>
                          <span className="required">*</span>Code
                        </label>
                        <input
                          type="text"
                          name="code"
                          value={values?.code ? values?.code : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          rules={[
                            {
                              pattern: new RegExp(
                                /^[a-zA-Z\S\d]*$/
                              ),
                              message:
                                "Code can contain letters and numbers",
                            },
                          ]}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="code" />
                        </span>
                      </div>
                      <div className="form-group tri-width">
                        <label>
                          <span className="required">*</span>Discount Type
                        </label>
                        <Select
                          // type="select"
                          name="discount_type"
                          onChange={(e) => {
                            setFieldValue('discount_type', e ? e : '');
                            // handleChange(e);
                          }}
                          value={values?.discount_type ? values?.discount_type : ""}
                          onBlur={handleBlur}
                          options={discountTypeList}
                          placeholder="Select Discount Type"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "grey",
                            },
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="discount_type" />
                        </span>
                      </div>
                      <div className="form-group tri-width">
                        <label>
                          <span className="required">*</span>Discount
                        </label>
                        <input
                          type="text"
                          name="discount"
                          value={values?.discount ? values?.discount : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="discount" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>Description
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          value={values?.description ? values?.description : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          style={{
                            borderRadius: "10px",
                            margin: "0 0 15px",
                            minHeight: "70px",
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          toggleEditModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateDiscountCode;
