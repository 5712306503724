import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, get, metaTitle } from "../../config";
import Pagination from "../common/Pagination";
import Spin from "../common/Spin";
import { debounce } from "lodash";
import OfficeActionButton from "./components/OfficeActionButton";
import AddOffice from "./AddOffice";
import TitleBar from "../common/TitleBar";
// import { getClientDetail } from "../Settings/clients/components/ClientHelper";

const Offices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  // const role = localStorage.getItem("sme-role");
  const [officesList, setOfficesList] = useState([]);
  const [officeLoading, setOfficeLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [showOffice, setShowOffice] = useState(false);

  // const [clientIde, setClientIde] = useState("");

  const params = useParams();
  const clientID = params?.client_id;

  // // Fetching of Client Details
  // const fetchClientData = useCallback(async () => {
  //   try {
  //     setOfficeLoading(true);
  //     const clientDetailData = await getClientDetail(clientID);
  //     setClientIde(clientDetailData?.client?.id)
  //     setOfficeLoading(false);
  //   } catch (e) {
  //     const { data } = e;
  //     setOfficeLoading(false);
  //     toast.error(
  //       data &&
  //         data.errors &&
  //         data.errors.myna_error &&
  //         data.errors.myna_error[0]
  //     );
  //   }
  // }, [clientID]);

  // useEffect(() => {
  //   if(role !== "Client" && clientID !== undefined) {fetchClientData()};
  // }, [fetchClientData, role, clientID]);

  const clientId = localStorage.getItem("sme-clientId") === null ? clientID : localStorage.getItem("sme-clientId");

  const fetchOffices = useCallback(async () => {
    try {
      setOfficeLoading(true);
      const { data } = await get(
        `${API.GET_OFFICE}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&client_id=${clientId ? clientId : ""}`
      );
      setOfficeLoading(false);
      setLimit(data?.data?.offices?.per_page);
      setPage(data?.data?.offices?.current_page);
      setTotal(data?.data?.offices?.total);
      setFrom(data?.data?.offices?.from);
      setTo(data?.data?.offices?.to);
      const officesList = data?.data?.offices?.data;
      setOfficesList(officesList);
      return 0;
    } catch (e) {
      const { data } = e;
      setOfficeLoading(false);
      setOfficesList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, searchParam, sortOrder, sortField, clientId]);

  const officeModal = () => {
    fetchOffices();
  };

  const usersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: officesList[i]?.id,
        office_id: officesList[i]?.id ? officesList[i]?.id : "",
        suburb: officesList[i]?.suburb?.name
          ? officesList[i]?.suburb?.name
          : "-",
        state: officesList[i]?.state?.name ? officesList[i]?.state?.name : "-",
        street: officesList[i]?.street ? officesList[i]?.street : "-",
        postal_code: officesList[i]?.postal_code
          ? officesList[i]?.postal_code
          : "-",
        // role: officesList[i]?.role?.name ? officesList[i]?.role?.name : "-",
        action: (
          <OfficeActionButton
            id={officesList[i]?.id}
            officeModal={officeModal}
          />
        ),
      });
    }
    return items;
  };
  const officesData = usersGenerator(officesList?.length);

  const columns = [
    {
      dataField: "street",
      text: "Street",
      sort: true,
    },
    {
      dataField: "suburb",
      text: "Suburb",
      // sort: true,
    },
    {
      dataField: "state",
      text: "State",
      // sort: true,
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      // sort: true,
    },
    // {
    //   dataField: "role",
    //   text: "Role",
    //   // sort: true,
    // },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );

  
  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if(clientId !== "" || clientID === undefined){
      fetchOffices();
    }
    if(clientID || clientId){
      window.document.title = `${metaTitle} | Client Offices`
    } else {
      window.document.title = `${metaTitle} | Offices`
    }
  }, [fetchOffices, clientId, clientID]);

  // useEffect(() => {
  //   navigate(
  //     `/offices?limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
  //       searchParam ? searchParam : ""
  //     }&sort_column=${sortField ? sortField : ""}&sort_order=${
  //       sortOrder ? sortOrder : ""
  //     }`,
  //     {
  //       replace: true,
  //     }
  //   );
  // }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  const toggleOfficeModal = () => {
    showOffice && officeModal();
    setShowOffice(!showOffice);
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={"Offices"}
              businessNameData={false}
              globalSearch={false}
            />
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Office"
                    />
                    {searchParamData 
                    ? <Button className="btn-close" onClick={() => {
                      setSearchParamData("")
                      setSearchParam("")
                    }}
                    >x</Button> 
                    : <Button className="fa fa-search">Search</Button>}
                  </Form>
                </div>
                <div className="table-btn">
                  <Button
                    variant="primary"
                    onClick={toggleOfficeModal}
                    // onClick={() => navigate("/add-client")}
                  >
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {officeLoading && <Spin />}
                <BootstrapTable
                  keyField="office_id"
                  remote
                  data={officesData}
                  columns={columns}
                  onTableChange={handleTableChange}
                  // expandRow={expandRow}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showOffice && (
        <AddOffice
          toggleOfficeModal={toggleOfficeModal}
          officeModal={officeModal}
          showOffice={showOffice}
          clientId={clientId}
        />
      )}
    </>
  );
};

export default Offices;
