import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Sitelogo from "../../components/Header/Logo";
import { API, post } from "../../config";
import { toast } from "react-toastify";
import { ForgotPasswordSchema } from "../common/Validation";
import Spin from "../common/Spin";
import SlideToggle from "react-slide-toggle";
import { useEffect } from "react";
import wFavicon from "../../assets/images/myna-white-favicon.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ success: "", error: "" });

  const userId = localStorage.getItem("sme-userId");
  const EmailVarification = localStorage.getItem("sme-EmailVarification");
  const hasToken = localStorage.getItem("sme-auth-token");
  const clientId = localStorage.getItem("sme-clientId");

  const handleFormSubmit = async (value) => {
    try {
      setMessage({});
      setLoading(true);
      const { data } = await post(API.FORGOT_PASSWORD, value);
      if (data.status === 200) {
        setMessage({ success: data.message });
        setLoading(false);
      } else {
        setLoading(false);
        setMessage({ error: data.errors?.myna_error[0] });
      }
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    userId &&
      EmailVarification === "1" &&
      hasToken &&
      (clientId ? navigate("/businesses") : navigate("/clients"));
  }, [userId, EmailVarification, hasToken, clientId, navigate]);

  return (
    <>
      <div className="login-screen">
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" /> SME
              CashBook acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          <Formik
            initialValues={{
              email: "",
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            // validateOnBlur={false}
            // validateOnChange={false}
            validationSchema={ForgotPasswordSchema}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-title-para">
                  Enter email address to receive an email to reset your password
                </div>
                <div className="form-group">
                  <input
                    placeholder="Email Address"
                    type="text"
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className="form-control"
                    required
                  />
                </div>
                {loading && <Spin />}
                <span className="text-danger">{errors?.email}</span>
                {message.error && (
                  <p className="text-danger">{message.error}</p>
                )}
                {message.success && (
                  <p className="text-success">{message.success}</p>
                )}
                <button
                  type="submit"
                  className="button"
                  style={{ marginTop: "20px", width: '100%' }}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn-nolink"
                  onClick={() => navigate("/")}
                >
                  Back to Login
                </button>
              </form>
            )}
          </Formik>

          <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a href="/#" rel="noreferrer" target="_blank">
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="https://smecashbook.com.au/terms-conditions/" rel="noreferrer" target="_blank1">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a href="/#" rel="noreferrer" target="_blank2">
                Privacy Policy
              </a>
            </p>
          </div>
          <SlideToggle>
            {({ toggle, setCollapsibleElement }) => (
              <div className="my-collapsible">
                <i
                  className="fa fa-info my-collapsible__toggle"
                  onClick={toggle}
                ></i>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                >
                  <div className="my-collapsible__content-inner">
                    <p style={{ fontSize: "12px" }}>
                      <img
                        className="small-white-favicon"
                        src={wFavicon}
                        alt=""
                      />{" "}
                      SME CashBook acknowledges the Traditional Custodians of
                      country throughout Australia and their connections to
                      land, sea and community. We pay our respect to their
                      Elders past and present and extend that respect to all
                      Aboriginal and Torres Strait Islander peoples today.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
