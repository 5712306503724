import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TaskDelete from "./TaskDelete";
import EditTask from "../EditTask";

const TaskActionButton = ({ id, handleDelete, fetchTasks }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  return (
    <>
      <span>
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
          onClick={toggleShowModal}
          data-toggle="modal"
          data-target="#editTask"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteTask"
        />
      </span>

      {showDelete && (
        <TaskDelete
          taskId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}

      {showView && (
        <EditTask
          taskId={id}
          businessId={businessId}
          toggleShowModal={toggleShowModal}
          fetchTasks={fetchTasks}
          showView={showView}
        />
      )}
    </>
  );
};

export default TaskActionButton;
