import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import CustomerDelete from "./CustomerDelete";
import CustomerDetailPopup from "./CustomerDetailPopup";

const CustomerActionButton = ({ id, handleDelete }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  return (
    <>
      <span>
        <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#customerDetail"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <Link to={`/business/${businessId}/edit-customer?id=${id}`}>
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </Link>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteCustomer"
        />
      </span>

      {showDelete && (
        <CustomerDelete
          customerId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}

      {showView && (
        <CustomerDetailPopup
          customerId={id}
          toggleShowModal={toggleShowModal}
          showView={showView}
        />
      )}
    </>
  );
};

export default CustomerActionButton;
