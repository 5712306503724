import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, get, metaTitle } from "../../../../config";
import Pagination from "../../../common/Pagination";
import Spin from "../../../common/Spin";
import { debounce } from "lodash";
import SearchTermActionButton from "./components/SearchTermActionButton";
import TitleBar from "../../../common/TitleBar";

const SearchTerms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = useParams();
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const businessId = params?.business_id;
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [loading, setLoading] = useState(false);
  const [termsList, setTermsList] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();


  const handleDelete = () => {
    fetchTerms();
  };

  const fetchTerms = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_TERMS}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&client_business_id=${
          businessId ? businessId : ""
        }`
      );
      navigate(
        `?limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`,
        {
          replace: true,
        }
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const customersList = data?.data?.data;
      setTermsList(customersList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setLoading(false);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [businessId, limit, navigate, page, searchParam, sortField, sortOrder]);
  
  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const termsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: termsList[i]?.id,
        term: termsList[i]?.term,
        // account_name: termsList[i]?.chart_of_account?.name,
        account_name:
          termsList[i]?.chart_of_account?.name +
          (termsList[i]?.chart_of_account?.account_type?.name
            ? " (" + termsList[i]?.chart_of_account?.account_type?.name + ")"
            : "") +
          (termsList[i]?.chart_of_account?.code?.length < 4
            ? " - " + padWithLeadingZeros(Number(termsList[i]?.chart_of_account?.code), 4)
            : " - " + termsList[i]?.chart_of_account?.code.toString()) +
          (termsList[i]?.chart_of_account?.sub_code
            ? "." + termsList[i]?.chart_of_account?.sub_code.toString()
            : ""),
        // (termsList[i]?.chart_of_account?.tax === 1
        //   ? " - GST Inc."
        //   : " - GST Ex."),
        job_code: termsList[i]?.business_job?.code ? termsList[i]?.business_job?.code : '-',
        task_code: termsList[i]?.business_task?.code ? termsList[i]?.business_task?.code : '-',
        contact: termsList[i]?.contact?.first_name && termsList[i]?.contact?.last_name
        ? `${termsList[i]?.contact?.first_name} ${termsList[i]?.contact?.last_name}`
        : termsList[i]?.contact?.first_name ? termsList[i]?.contact?.first_name :
        termsList[i]?.contact?.last_name ? termsList[i]?.contact?.last_name : '-',

        action: (
          <SearchTermActionButton
            id={termsList[i]?.id}
            handleDelete={handleDelete}
          />
        ),
      });
    }
    return items;
  };

  const terms = termsGenerator(termsList?.length);

  const columns = [
    {
      dataField: "term",
      text: "Term to Memorise",
      sort: true,
    },
    {
      dataField: "account_name",
      text: "Account Name",
      // sort: true,
    },
    {
      dataField: "contact",
      text: "Contact Name",
      // sort: true,
    },
    {
      dataField: "job_code",
      text: "Job Code",
      // sort: true,
    },
    {
      dataField: "task_code",
      text: "Task Code",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );

  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchTerms();
    window.document.title = `${metaTitle} | Memorisation`
  }, [fetchTerms]);

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"Memorisation"}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={handleSearchChange}
                    type="text"
                    value={searchParamData}
                    placeholder="Search Terms"
                  />
                  {searchParamData 
                  ? <Button className="btn-close" onClick={() => {
                    setSearchParamData("")
                    setSearchParam("")
                  }}
                  >x</Button> 
                  : <Button className="fa fa-search">Search</Button>}
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate("/business/" + businessId + "/add-term")
                  }
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="custom-table">
              {loading && <Spin />}
              <BootstrapTable
                keyField="key"
                remote
                data={terms}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTerms;
