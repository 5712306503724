import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React from 'react';

const EmailTemplatePreview = ({ body }) => {
  return (
    <div>
      <table cellspacing="0" cellpadding="0" border="0" style={{ fontSize: '14px' }}>
        <tbody>
          <tr>
            <td>
              <p>
                Dear <strong>## CONTACT_NAME ##,</strong>
              </p>
              <br />
              <p>
                I trust this email finds you well. Thank you for considering{' '}
                <strong>## BUSINESS_NAME ##</strong> for your needs. We are pleased to provide you
                with a detailed quotation based on your requirements.
              </p>
              <br />
              {body && (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(convertToRaw(body?.getCurrentContent()))
                    }}
                  ></span>
                  <br />
                </>
              )}
              <p>
                <strong>Total Amount: </strong>$0.00
              </p>
              <br />
              <p>
                <strong>Validity Period: </strong>This invoice is valid until{' '}
                <strong>## DUE_DATE ##</strong> to ensure the accuracy of your budgeting process.
                After this date, prices are subject to change.
              </p>
              <br />
              <p>
                <strong>Next Steps: </strong>If you have any questions or require further
                clarification, please feel free to contact us at ## BUSINESS_EMAIL ##. We are open
                to discussing any modifications or adjustments to better suit your needs.
              </p>
              <br />
              <p>
                We appreciate the opportunity to work with you and look forward to the possibility
                of being of service. Thank you for considering ## BUSINESS_NAME ##.
              </p>
              <br />
              <br />
              <p>
                Best regards,
                <br />
                <strong>## USER_NAME ##</strong>
                <br />
                <strong>## BUSINESS_NAME ##</strong>
                <br />
                <strong>## BUSINESS_EMAIL ##</strong>
              </p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              Thanks,
              <br />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default EmailTemplatePreview;
