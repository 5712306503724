import moment from "moment";
import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import BackButton from "../../../components/Form/BackButton";
import ReconcileItem from "./ReconcileItem";
import ReconcileTransactions from "./ReconcileTransactions";
// import UnderReview from "./UnderReview";
import Slider from "react-slick";
import { imageDefaultPrefixPath, metaTitle } from "../../../config";
import { getBusinessData } from "../components/BusinessHelper";
import { useEffect } from "react";
import { checkIfFileExists } from "../../common/Misc";
import AuditTrail from "./AuditTrail";
import BankTransactions from "./BankTransactions";
import MonthlyBankReconciliationReport from "./MonthlyBankReconciliationReport";
import TitleBar from "../../common/TitleBar";

const tabsKey = {
  "reconcile-transactions": 1,
  "reconcile-item": 2,
  "under-review": 3,
  "bank-transactions": 4,
  "audit-trail": 5,
  "monthly-bank-reconciliation": 6
};

const ReconcileTransactionsTab = () => {
  const today = new Date();
  const defaultDateRange =
    parseInt(moment(today).format('MM')) === 7
      ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
      : parseInt(moment(today).format('MM')) > 7
      ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
      : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const businessId = params?.business_id;
  const [balanceInfo, setBalanceInfo] = useState([]);
  const [ selectedRow, setSelectedRow ] = useState([]);
  let stringForm = location?.state?.form?.split("-");
  let startDateParams = location?.state?.startDate?.split("-");
  let endDateParams = location?.state?.endDate?.split("-");
  const [valueRange, setValueRange] = useState(
    stringForm 
      ? [new Date(stringForm[2], stringForm[1] - 1, 1), new Date(stringForm[2], stringForm[1], 0)] 
      : startDateParams 
        ? [new Date(startDateParams[2], startDateParams[1] - 1, startDateParams[0]), new Date(endDateParams[2], endDateParams[1]-1, endDateParams[0])] 
        : defaultDateRange);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [bankAccountId, setBankAccountId] = useState(location?.state?.accountStatusID ? location?.state?.accountStatusID : '');
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab");
  const defaultActiveKey = tabsKey[selectedTab];
  const search = query.get('search');
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Reconcile Transactions"
      : parseInt(defaultActiveKey) === 3
      ? "Under Review"
      : parseInt(defaultActiveKey) === 4 
      ? "Bank Transactions" 
      : parseInt(defaultActiveKey) === 5
      ? "Aduti Trail"
      // : "Reconciled Items"
      : parseInt(defaultActiveKey) === 2
      ? "Reconciled Items"
      : "Monthly Bank Reconciliation"
  );
  const businessName = localStorage.getItem("sme-businessName");
  const businessLogo = localStorage.getItem("sme-businessLogo");
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);

  useEffect(() => {
    !businessName &&
      getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo("");
      }
    });
  }, [BusinessLogo]);

  useEffect(() => {
    if(selectedTab === 'bank-transactions'){
      navigate(`/business/${businessId}/reconcile-transactions?tab=bank-transactions&search=${search ? search : ''}`);
      setActiveTab("Bank Transactions");
    }
    if(selectedTab === 'reconcile-transactions'){
      navigate(`/business/${businessId}/reconcile-transactions?tab=reconcile-transactions&search=${search ? search : ''}`);
      setActiveTab("Reconcile Transactions");
    }
    if(selectedTab === 'monthly-bank-reconciliation'){
      navigate(`/business/${businessId}/reconcile-transactions?tab=monthly-bank-reconciliation`);
      setActiveTab("Monthly Bank Reconciliation");
    }
  }, [businessId, selectedTab, navigate, search]);

  useEffect(() => {
    window.document.title = `${metaTitle} | ${activeTab}`
  },[activeTab])
  

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    loop: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: balanceInfo?.length > 2 ? 3 : balanceInfo?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            {/* <h1 className="page-title">
              <BackButton />
              {activeTab}
              <div className="page-title-right">
                {BusinessLogo && (
                  <img
                    src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                    alt=""
                  />
                )}{" "}
                {BusinessName}
              </div>
            </h1> */}
            {/* <div className="content-details reconcilation-screen"> */}
            <TitleBar 
              label={activeTab}
            />
            <div className="content-details reconcilation-screen">
              {balanceInfo?.length > 0 &&
              <div className="firm-details">
                <div className="firm-summary">
                  <Slider {...settings}>
                    {balanceInfo &&
                      balanceInfo?.length > 0 &&
                      balanceInfo?.map((item) => {
                        return (
                          item?.accountStatus === 1 &&
                          <div className="account-wrapper" key={item.id}>
                            <h4 style={{ margin: 0, marginBottom: '10px' }}>
                              {item?.accountName
                                ? item?.accountName
                                : "Account Name"}
                            </h4>
                            <div className="bank-detail">
                              <img
                                src={item?.provider?.logo}
                                className="account-logo"
                                alt=""
                              />
                              <p>
                                {item?.provider?.providerName
                                  ? item?.provider?.providerName
                                  : "Bank Name"}{" "}
                                <i>
                                  {`(${
                                    item?.accountNumber
                                      ? item?.accountNumber
                                      : ""
                                  })`}
                                </i>
                              </p>
                            </div>
                            <div className="reconciliation-block">
                              <div className="balance">
                                <h6>Statement Balance</h6>
                                <h5>
                                  $
                                  {item?.balance_amount
                                    ? item?.balance_amount?.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : "0.00"}
                                </h5>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "12px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Balance till date{" "}
                                  {item?.lastUpdated
                                    ? moment(
                                        item?.lastUpdated,
                                        "DD-MM-YYYY HH:mm:ss"
                                      ).format("DD-MM-YYYY")
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </div>
              }
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li
                    className={
                      activeTab === "Reconcile Transactions" ? "active" : ""
                    }
                  >
                    <Link
                      key={1}
                    >
                      Reconcile Transactions
                    </Link>
                  </li>
                  <li
                    className={activeTab === "Reconciled Items" ? "active" : ""}
                  >
                    <Link
                      key={2}
                    >
                      Reconciled Items
                    </Link>
                  </li>
                  <li className={activeTab === "Under Review" ? "active" : ""}>
                    <Link
                      key={3}
                    >
                      Under Review
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab === "Bank Transactions" ? "active" : ""
                    }
                  >
                    <Link
                      key={4}
                    >
                      Bank Transactions
                    </Link>
                  </li>
                  <li className={activeTab === "Audit Trail" ? "active" : ""}>
                    <Link
                      key={5}
                    >
                      Audit Trail
                    </Link>
                  </li>
                  <li className={activeTab === "Monthly Bank Reconciliation" ? "active" : ""}>
                    <Link
                      key={6}
                    >
                      Monthly Bank Reconciliation
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Reconcile Transactions" && (
                  <ReconcileTransactions 
                  setBalanceInfo={setBalanceInfo} 
                  setValueRange={setValueRange} 
                  valueRange={valueRange} 
                  setSortField={setSortField}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                  sortField={sortField}
                  setBankAccountId={setBankAccountId}
                  bankAccountId={bankAccountId}
                  balanceInfo={balanceInfo}
                  />
                )}
                {activeTab === "Reconciled Items" && (
                  <ReconcileItem setBalanceInfo={setBalanceInfo} setValueRange={setValueRange} valueRange={valueRange} 
                  setBankAccountId={setBankAccountId}
                  bankAccountId={bankAccountId}
                  />
                )}
                {activeTab === "Under Review" && (
                  <ReconcileTransactions
                    isUnderReviewed={true}
                    setBalanceInfo={setBalanceInfo}
                    setValueRange={setValueRange} valueRange={valueRange}
                    setSortField={setSortField}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    sortField={sortField}
                    setBankAccountId={setBankAccountId}
                    bankAccountId={bankAccountId}
                    balanceInfo={balanceInfo}
                  />
                )}
                {activeTab === "Audit Trail" && (
                  <AuditTrail setBalanceInfo={setBalanceInfo} setValueRange={setValueRange} valueRange={valueRange}/>
                )}
                {activeTab === "Bank Transactions" && (
                  <BankTransactions 
                  setBalanceInfo={setBalanceInfo} 
                  setSelectedRow={setSelectedRow} 
                  selectedRow={selectedRow} 
                  setValueRange={setValueRange} valueRange={valueRange}
                  setAccountId={setBankAccountId}
                  accountId={bankAccountId}
                  balanceInfo={balanceInfo}
                  />
                )}
                {activeTab === "Monthly Bank Reconciliation" && (
                  <MonthlyBankReconciliationReport 
                  setBalanceInfo={setBalanceInfo} 
                  setValueRange={setValueRange} 
                  valueRange={valueRange} 
                  setBankAccountId={setBankAccountId}
                  BusinessName={BusinessName}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconcileTransactionsTab;
