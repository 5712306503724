import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, FormControl, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API, entityTypes, fileUpload, get, metaTitle } from "../../../config";
import Pagination from "../../common/Pagination";
import { toast } from "react-toastify";
import { debounce } from "lodash";
// import SupplierActionButton from "./components/SupplierActionButton";
import Spin from "../../common/Spin";
import ContactActionButton from "./components/ContactActionButton";
import {
  formatMobile,
  formatName,
  formatPhone,
} from "../../common/Misc";
import UploadFile from "../../common/UploadFile";
import * as XLSX from 'xlsx';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Type } from 'react-bootstrap-table2-editor';
import { useRef } from "react";
import TitleBar from "../../common/TitleBar";

const AllContacts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [contactsList, setContacts] = useState([]);
  const [contactLoading, setContactsLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelContactData, setExcelContactData] = useState();

  const handleDelete = () => {
    fetchSuppliers();
  };

  const fetchSuppliers = useCallback(async () => {
    try {
      setContactsLoading(true);
      const { data } = await get(
        `${API.GET_CONTACTS}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&client_business_id=${
          businessId ? businessId : ""
        }`
      );

      setContactsLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const contactsList = data.data.data;
      setContacts(contactsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setContactsLoading(false);
      setContacts([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, searchParam, sortOrder, sortField, businessId]);

  const suppliersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: contactsList[i]?.id,
        name: formatName(
          contactsList[i]?.first_name,
          contactsList[i]?.last_name
        ),
        email: contactsList[i]?.email,
        contact_type:
          parseInt(contactsList[i]?.contact_type) === 1
            ? "Customer"
            : parseInt(contactsList[i]?.contact_type) === 2
            ? "Supplier"
            : parseInt(contactsList[i]?.contact_type) === 3
            ? "Contractor"
            : "",
        phone: contactsList[i]?.phone
          ? formatPhone(contactsList[i]?.phone)
          : "-",
        mobile: contactsList[i]?.mobile
          ? formatMobile(contactsList[i]?.mobile)
          : "-",
        business_name: contactsList[i]?.business_name
          ? contactsList[i]?.business_name
          : "-",
        ref_number: contactsList[i]?.ref_number
          ? contactsList[i]?.ref_number
          : "-",
        bpay_ref_number: contactsList[i]?.bpay_ref_number
          ? contactsList[i]?.bpay_ref_number
          : "-",
        entity_type: contactsList[i]?.entity_type
          ? contactsList[i]?.entity_type
          : "-",
        action: (
          <ContactActionButton
            id={contactsList[i]?.id}
            handleDelete={handleDelete}
          />
        ),
      });
    }
    return items;
  };
  const suppliers = suppliersGenerator(contactsList?.length);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "contact_type",
      text: "Contact Type",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      sort: true,
      style: { width: "10%" },
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      sort: true,
      style: { width: "10%" },
    },
    {
      dataField: "business_name",
      text: "Business Name",
      sort: true,
    },
    {
      dataField: "ref_number",
      text: "Ref. Number",
      style: { width: "10%" },
      // sort: true,
    },
    {
      dataField: "bpay_ref_number",
      text: "BPay Ref. Number",
      style: { width: "11%" },
      // sort: true,
    },
    {
      dataField: "entity_type",
      text: "Entity Type",
    },
    {
      dataField: "action",
      text: "Actions",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  useEffect(() => {
    fetchSuppliers();
    window.document.title = `${metaTitle} | All Contacts`
  }, [fetchSuppliers]);

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );

  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(API.DOWNLOAD_CONTACTS_TEMPLATE);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "contacts.xls"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  
  const handleImportModal = async () => {
    setShowImport(true);
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const handleExcelClose = () => {
    setSelectedRow([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const ContactTypes = [
    { label: "Customer", value: "Customer", data: 1 },
    { label: "Supplier", value: "Supplier", data: 2 },
    { label: "Contractor", value: "Contractor", data: 3 },
  ];

  const ExcelContactColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "last_name",
      text: "Last Name",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue === '' || newValue === undefined || newValue === null){
          return {
            valid: false,
            message: 'Last Name is Required'
          };    
        }
        return true;
      }
    },
    {
      dataField: "phone",
      text: "Phone",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(newValue.split(" ").join("").length !== 12){
            return {
              valid: false,
              message: 'Please enter valid phone number'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.split(" ").join("").length !== 12){
            // toast.error("Enter 9 Digit Phone Number")
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "mobile",
      text: "Mobile",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(newValue.split(" ").join("").length !== 12){
            return {
              valid: false,
              message: 'Please enter valid mobile number'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.split(" ").join("").length !== 12){
            // toast.error("Enter 9 Digit Mobile Number")
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "email",
      text: "Email",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!newValue.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            return {
              valid: false,
              message: 'Please enter a valid email address'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(!String(cell).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "business_name",
      text: "Business Name",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "abn",
      text: "ABN",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if(!String(newValue).split(" ").join("").match(/^[\d]{11}$/)){
            return {
              valid: false,
              message: 'Enter 11 Digit ABN Number'
            };
          }
          if(newValue.split(" ").join("").length === 11){
            let newNumber = newValue.split(" ").join("")
            let sum = 0
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit 
            })
            if((sum%89) !== 0){
             return {
              valid: false,
              message: 'Enter Valid ABN Number'
             }
            }
          }
        }
      },
      classes: (cell) => {
        if(cell){
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if(cell.split(" ").join("").length !== 11){
            // toast.error("Enter 11 Digit ABN Number")
            return "error-cell"
          }
          if(cell.split(" ").join("").length === 11){
            let newNumber = cell.split(" ").join("")
            let sum = 0
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit 
            })
            if((sum%89) !== 0){
             return "error-cell"
            }
          }
        }
      }
    },
    {
      dataField: "entity_type",
      text: "Entity Type",
      editor: {
        type: Type.SELECT,
        options: entityTypes
      },
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: "ref",
      text: "Reference",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!newValue.match(/^[a-zA-Z\d]*$/)){
            return {
              valid: false,
              message: 'Ref. Number can contain letters, numbers.'
            };
          }
          if(newValue.length > 10){
            return {
              valid: false,
              message: 'Ref. Number can contain maximum 10 Character'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(cell.length > 10){
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "contact_type",
      text: "Contact Type",
      editor: {
        type: Type.SELECT,
        options: ContactTypes
      },
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: "street",
      text: "Street",
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: "state",
      text: "State",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "suburb",
      text: "Suburb",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!newValue.match(/^[\d]{4}$/)){
            return {
              valid: false,
              message: 'Postal Code can contain 4 digit numbers.'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(!String(cell).match(/^[\d]{4}$/)){
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "shipping_street",
      text: "Shipping Street",
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: "shipping_state",
      text: "Shipping State",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "shipping_suburb",
      text: "Shipping Suburb",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "shipping_postal_code",
      text: "Shipping Postal Code",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!newValue.match(/^[\d]{4}$/)){
            return {
              valid: false,
              message: 'Postal Code can contain 4 digit numbers.'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(!String(cell).match(/^[\d]{4}$/)){
            return "error-cell"
          }
        }
      }
    },
  ]
  
  const [ selectedRow, setSelectedRow ] = useState([]);

  let singleSelectedRows = [];
  let allSelectedRows = [];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ['not-select'],
    selected: selectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        singleSelectedRows = [...selectedRow, row?.key];
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      }
      else {
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    }
  };

  const ref = useRef();

  const phoneFormat = (value) => {
    if(String(value)?.match(/\+61/)){
      return `+${Number(String(value)?.split(" ")?.join("")).toLocaleString('en-US').replaceAll(",", " ")}`;
    } else {
      return `+61 ${Number(String(value)?.split(" ")?.join("")).toLocaleString('en-US').replaceAll(",", " ")}`;
    }
  }

  const ExcelReader = () => {
    let f = files
    const reader = new FileReader();
    let data = null;
    reader.onload = async(evt) => { // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, {header:1});
      /* Update state */
      let selectItem = []
      const businessExcelGenerator = (quantity) => {
        const items = [];
        if(data[0][0] !== "First Name" ||
          String(data[0][1]).replace('*', '') !== "Last Name" ||
          data[0][2] !== "Phone" ||
          data[0][3] !== "Mobile" ||
          data[0][4] !== "Email" ||
          data[0][5] !== "Business Name" ||
          data[0][6] !== "abn" ||
          data[0][7] !== "Entity Type" ||
          data[0][8] !== "Reference" ||
          String(data[0][9]).replace('*', '') !== "Contact Type" ||
          data[0][10] !== "Street" ||
          data[0][11] !== "State" ||
          data[0][12] !== "Suburb" ||
          data[0][13] !== "Postal Code" ||
          data[0][14] !== "Shipping Street" ||
          data[0][15] !== "Shipping State" ||
          data[0][16] !== "Shipping Suburb" ||
          data[0][17] !== "Shipping Postal Code"
        )
        {
          setShowExcelModal(false);
          setFiles();
          setIsImportLoading(false);
          toast.error("Sorry, File is not in the given format, please download the latest format again");
        } else {
          for (let i = 1; i < quantity; i++) {
            if(data[i].length > 0){
              selectItem.push(i);
              items.push({
                key: i,
                first_name: data[i][0] ? data[i][0] : "",
                last_name: data[i][1] ? data[i][1] : "",
                phone: data[i][2] ? phoneFormat(data[i][2]) : "",
                mobile: data[i][3] ? phoneFormat(data[i][3]) : "",
                email: data[i][4] ? data[i][4] : "",
                business_name: data[i][5] ? data[i][5] : "",
                abn: data[i][6] ? `${Number(String(data[i][6])?.split(" ")?.join("")).toLocaleString("en-US").replaceAll(",", " ")}` : "",
                // eslint-disable-next-line
                entity_type: data[i][7] ? (entityTypes.find((item) => item.value === data[i][7]) ? data[i][7] : "Other" ): "",
                ref: data[i][8] ? data[i][8] : "",
                // eslint-disable-next-line
                contact_type: data[i][9] ? (ContactTypes.find((item) => item.value === data[i][9]) ? data[i][9] : "Customer" ): "",
                street: data[i][10] ? data[i][10] : "",
                state: data[i][11] ? data[i][11]: "",
                suburb: data[i][12] ? data[i][12] : "",
                postal_code: data[i][13] ? data[i][13] : "",
                shipping_street: data[i][14] ? data[i][14] : "",
                shipping_state: data[i][15] ? data[i][15]: "",
                shipping_suburb: data[i][16] ? data[i][16] : "",
                shipping_postal_code: data[i][17] ? data[i][17] : "",
              });
            }
          }
        }
        return items;
      };
      setSelectedRow(selectItem)
      setExcelContactData(businessExcelGenerator(data?.length))
    };
    reader.readAsBinaryString(f);
  }

  useEffect(() => {
    showExcelModal && ExcelReader();
    // eslint-disable-next-line
  },[showExcelModal])

  const handleExcelModal = () => {
    setShowExcelModal(true)
  }

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleUpload = async () => {
    if(selectedRow.length > 1000){
      toast.warn("Maximum limit to import contacts is 1000")
    } else {
    if (files) {
      setShowExcelModal(true)
      const formData = new FormData();

      formData.append("client_business_id", businessId);

      for(let i=0; i<selectedRow.length; i++){
        let row = excelContactData.find((item) => item.key === selectedRow[i])
        let rowPhone = row.phone.split(" ")
        let rowMobile = row.mobile.split(" ")
        row.phone = rowPhone.join("")
        row.mobile = rowMobile.join("")
        row.abn = row.abn.split(" ").join("")
        row.contact_type = ContactTypes.find((i) => i.value === row.contact_type)?.data
        formData.append(`contacts[${i}]`, JSON.stringify(row))
      }

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      try {
        setIsImportLoading(true);
        const { data } = await fileUpload(
          `${API.IMPORT_CONTACT}`,
          formData,
          config
        );
        setIsImportLoading(false);
        if (data) {
          toast.success(data?.message);
          setSelectedRow([]);
          handleExcelClose();
          fetchSuppliers();
          handleImportClose();
        }
        return data?.data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
          if(error?.response?.data?.data?.failedData.length > 0){
            let failData = []
            for(let i=0; i<error?.response?.data?.data?.failedData.length; i++){
              let contactItem = error?.response?.data?.data?.failedData[i]?.data
              if(contactItem?.phone)  {
                contactItem.phone = `+${Number(contactItem?.phone).toLocaleString("en-US").replaceAll(",", " ")}`
              } else {
                contactItem.phone = ""
              }
              if(contactItem?.mobile)  {
                contactItem.mobile = `+${Number(contactItem?.mobile).toLocaleString("en-US").replaceAll(",", " ")}`
              } else {
                contactItem.mobile = ""
              }
              if(contactItem?.abn)  {
                contactItem.abn = `${Number(contactItem?.abn).toLocaleString("en-US").replaceAll(",", " ")}`
              } else {
                contactItem.abn = ""
              }
              failData.push(contactItem);
            }
            setSelectedRow([]);
            setExcelContactData(failData);
          }
          fetchSuppliers();
          setIsImportLoading(false);
        } else {
          toast.error(error?.message);
          setIsImportLoading(false);
        }
      }
    }
    }
  };

  const handleExcelTableChange = async(type, { data, cellEdit: { rowId, dataField, newValue } }) => {
    const getWithPromiseAll = async() => {
      let temp = await Promise.all(
        data.map(async (row) => {
          if (row?.key === rowId) {
            const newRow = { ...row };
            if(dataField === 'account_number'){
              newRow[dataField] = padWithLeadingZeros(Number(newValue), 9)
            } else if(dataField === 'mobile' || dataField === 'phone'){
              newRow[dataField] = phoneFormat(newValue)
            } else if(dataField === 'abn'){
              newRow[dataField] = `${Number(newValue.split(" ").join("")).toLocaleString("en-US").replaceAll(",", " ")}`
            } else if(dataField === 'bsb'){
              if(String(newValue).match("-")){
                newRow[dataField] = padWithLeadingZeros(Number(String(newValue).split("-").join('')), 6).slice(0,3) + '-' + padWithLeadingZeros(Number(String(newValue).split("-").join('')), 6).slice(3,6)
              } else {
                newRow[dataField] = padWithLeadingZeros(Number(newValue), 6).slice(0,3) + '-' + padWithLeadingZeros(Number(newValue), 6).slice(3,6)
              }
            } else {
              newRow[dataField] = newValue;
            }
            return newRow;
          }
          return row;
        })
      )
      return temp
   }
    const result = await getWithPromiseAll();;
    setExcelContactData(result)
  }

  return (
    <>
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"All Contacts"}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={handleSearchChange}
                    type="text"
                    value={searchParamData}
                    placeholder="Search Contact"
                  />
                  {searchParamData 
                  ? <Button className="btn-close" onClick={() => {
                    setSearchParamData("")
                    setSearchParam("")
                  }}
                  >x</Button> 
                  : <Button className="fa fa-search">Search</Button>}
                </Form>
              </div>
              <div className="table-btn">
                <div className="border-btn">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p>Last Name - Required</p>
                    </Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="link"
                    disabled={isLoadingDownload}
                    onClick={() => handleDownload()}
                    className="download-template"
                  >
                    Download Template
                  </Button>
                  <Button variant="primary" 
                  onClick={handleImportModal}
                  >
                    Import Contacts
                  </Button>
                </div>
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate("/business/" + businessId + "/add-contact")
                  }
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="custom-table">
              {contactLoading && <Spin />}

              <BootstrapTable
                keyField="key"
                remote
                data={suppliers}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
                // expandRow={expandRow}
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={Number(page)}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {showImport && (
      <Modal
        size="lg"
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Import Contacts
        </Modal.Header>
        <Modal.Body>
          <div>
            <UploadFile
              businessId={businessId}
              isLoading={isImportLoading}
              setFiles={setFiles}
              files={files}
              handleUpload={handleExcelModal}
              handleCancel={handleImportClose}
              acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ErrorMessage="xls and xlsx"
            />
          </div>
        </Modal.Body>
      </Modal>
    )}
    {showExcelModal && (
      <Modal
        // size="xl"
        show={showExcelModal}
        onHide={handleExcelClose}
        dialogClassName="full-popup large-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Import Contacts
        </Modal.Header>
        <Modal.Body>
          <div className="excel-table-list" style={{padding: "20px"}}>
            {isImportLoading && <Spin />}
            <BootstrapTable
              keyField="key"
              selectRow={selectRow}
              ref={ref}
              remote={{cellEdit: true}}
              data={excelContactData ? excelContactData : []}
              columns={ExcelContactColumns}
              noDataIndication="No Data Found"
              cellEdit={ cellEditFactory({
                mode: 'click',
                blurToSave: true,
                timeToCloseMessage: 30000
              }) }
              onTableChange={handleExcelTableChange}
              // pagination={paginationFactory({
              //   sizePerPage: 10, 
              //   sizePerPageList: [ 
              //     {
              //       text: '10', value: 10
              //     }, 
              //     {
              //       text: '25', value: 25
              //     },
              //     {
              //       text: '50', value: 50
              //     },
              //     {
              //       text: '100', value: 100
              //     }
              //   ],
              //   showTotal: true,
              //   alwaysShowAllBtns: true,
              //   // nextPageText: (<>Next <i class="fa fa-angle-right"></i></>),
              //   // prePageText: (<><i class="fa fa-angle-left"></i> Prev </>)
              // })}
            />
            <div className="excel-list-footer">
              <Button
                variant="primary"
                onClick={handleUpload}
                data-toggle="modal"
                data-target="#business"
                disabled={selectedRow.length === 0 ? true : false}
              >
                Import
              </Button>
              <div>Selected Records: {selectedRow?.length}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
    }
    </>
  );
};

export default AllContacts;
