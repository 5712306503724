import React, { useCallback, useEffect, useRef, useState } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { API, fileUpload, get, yodleeConfigName, yodleeFastLink } from "../../../config";
import Spin from "../../common/Spin";
import moment from "moment";
import Pagination from "../../common/Pagination";
import BankTransactionActionButton from "./components/BankTransactionActionButton";
import { refreshBankTransactions } from "./components/TransactionsHelper";
import { toast } from "react-toastify";
import AddBankTransaction from "./AddBankTransaction";
// import { pastFinancialdate } from "../../common/Misc";
import Select from 'react-select';
import { getProviderBankDetail, getYodleeAccessToken } from "../settings/integrations/components/IntegrationsHelper";
import { YodleeConnectModal } from "../settings/integrations/components/YodleeConnetctModal";
import CustomTooltip from "../../common/CustomTooltip";

const today = new Date();
const defaultDateRange =
parseInt(moment(today).format("MM")) === 7
  ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
  : parseInt(moment(today).format("MM")) > 7
  ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
  : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

const BankTransactions = ({ setBalanceInfo, setSelectedRow, selectedRow, setValueRange, valueRange, accountId, setAccountId, balanceInfo }) => {
  const { afterToday } = DateRangePicker;

  const [bankTransactionData, setBankTransactionData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  // const [startDate, setStartDate] = useState(pastFinancialdate);
  // const [endDate, setEndDate] = useState();
  // const [valueRange, setValueRange] = useState(pastFinancialdate);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const [showBank, setShowBank] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [accountOptions, setAccountOptions] = useState();

  const [accountFetchOptions, setAccountFetchOptions] = useState();
  const [accountFetchId, setAccountFetchId] = useState();
  const [accountMFA, setAccountMFA] = useState();

  const [accountYodleeId, setAccountYodleeId] = useState();
  const [openYodlee, setOpenYodlee] = useState(false);
  const [openYodleeLoading, setOpenYodleeLoading] = useState(false);
  const [fastLinkData, setFastLinkData] = useState({});
  const [providerBankData, setProviderBankData] = useState([]);
  const [isYodleeConnect, setIsYodleeConnect] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);

  // const [accountId, setAccountId] = useState('');
  const [ showDuplicate, setShowduplicate ] = useState(false);
  const [ alreadySelectedDuplicate, setAlreadySelectedDuplicate] = useState([]);
  let singleSelectedRows = [];
  let allSelectedRows = [];
  let alreadySelectedItems = [];

  const config = {
    params: { configName: "" },
  }

  const [dateValue, setDateValue] = useState(defaultDateRange);
  const [startDate, setStartDate] = useState(
    defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );

  const handleDateModalRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  const toggleRefreshModal = () => {
    setShowRefresh(!showRefresh);
    if(!showRefresh){
      if(accountId && accountFetchOptions){
        if(accountFetchOptions?.find((i) => i.value === accountId)){
          setAccountFetchId(accountId)
        }
      }
    }
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  }

  const toggleDuplicate = () => {
    setBankTransactionData([]);
    setShowduplicate(!showDuplicate);
    setPage(1);
  }

  // const [clientBusinessYodleeAccountId, setClientBusinessYodleeAccountId] =
  //   useState();

  const fetchBankTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_LIST}?from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&client_business_id=${businessId ? businessId : ""}&sort_column=${
          sortField ? sortField : ""
        }&search=${search ? search : ''}&sort_order=${sortOrder ? sortOrder : ""}${accountId ? '&yodlee_account_id=' + accountId : ''}${showDuplicate ? '&duplicate=1' : ''}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      let accountActiveList = [];
      if(data?.accounts){
        accountActiveList = data?.accounts?.filter((i) => i?.accountStatus === 1 )
      }
      setBalanceInfo(accountActiveList);
      const accountList = 
      data?.accounts?.length > 0 &&
      data?.accounts?.map((item) => {
        const list = {
          label: item?.provider?.providerName + (item?.accountNumber ? "-" + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)') : ""),
          value: item?.id
        };
        return list;
      });
      // setClientBusinessYodleeAccountId(
      //   data?.last_transaction?.client_business_yodlee_account_id
      // );
      setAccountOptions(accountList && [ { label: "ALL", value: ''}, ...accountList]);
      const accountActiveStatusList = 
      accountActiveList.length > 0 &&
      accountActiveList.map((item) => {
        const list = {
          label: item?.provider?.providerName + (item?.accountNumber ? "-" + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)') : ""),
          value: item?.id,
          mfa: item?.provider?.isRealTimeMFA,
          accountId: item?.provider?.providerAccountId
        };
        return list;
      });
      setAccountFetchOptions(accountActiveStatusList);
      // eslint-disable-next-line
      alreadySelectedItems = [];
      for (let i = 0; i < data?.data?.data?.length; i++) {
        if(showDuplicate)
        {
          if(i > 0){
            if(data?.data?.data[i].transactionDate === data?.data?.data[i-1].transactionDate 
              && data?.data?.data[i].amount === data?.data?.data[i-1].amount 
              && data?.data?.data[i].baseType === data?.data?.data[i-1].baseType 
              && data?.data?.data[i]?.deleted_at === null 
              && (data?.data?.data[i-1]?.is_reconciled !== 1
              && data?.data?.data[i]?.is_reconciled !== 1)){
                alreadySelectedItems.push(data?.data?.data[i]?.id);
            }
          }
        }
      }
      setAlreadySelectedDuplicate(alreadySelectedItems);
      setSelectedRow(alreadySelectedItems);
      setBankTransactionData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [ businessId, valueRange, limit, page, setBalanceInfo, sortField, sortOrder, accountId, showDuplicate ]);

  useEffect(() => {
  },[alreadySelectedDuplicate])

  const handleDelete = () => {
    fetchBankTransactions();
  };

  const handleDeleteAll = async() => {
    const formData = new FormData();
    if(selectedRow?.length > 0){
      for(let i=0; i<selectedRow?.length; i++){
        formData.append(`ids[${i}]`, selectedRow[i])
      }
    }
    formData.append("client_business_id", businessId);
    accountId && formData.append("yodlee_account_id", accountId);
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BANK_TRANSACTION_DELETE_ALL}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleDeleteAllModal();
        cleanFilters();
        setSelectedRow([]);
        fetchBankTransactions();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }

  const bankTransactionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: bankTransactionData[i]?.deleted_at !== null ? 'not-select' : (bankTransactionData[i]?.is_reconciled !== 1 ? bankTransactionData[i]?.id : 'not-select'),
        transactionDate: bankTransactionData[i]?.transactionDate
          ? moment(bankTransactionData[i]?.transactionDate, "DD-MM-YYYY").format(
              "DD-MM-YYYY"
            )
          : "-",
        description: bankTransactionData[i]?.description
          ? bankTransactionData[i]?.description
          : "-",
        credit:
          bankTransactionData[i]?.baseType === 1 ? (
            <span className="rag-green">
              $
              {bankTransactionData[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        debit:
          bankTransactionData[i]?.baseType === 0 ? (
            <span className="rag-red">
              $
              {bankTransactionData[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        bank_transaction_source: bankTransactionData[i]?.source
          ? bankTransactionData[i]?.source
          : "-",
        action: bankTransactionData[i]?.deleted_at ? (
          ""
        ) : (
          <BankTransactionActionButton
            id={bankTransactionData[i]?.id}
            baseType={bankTransactionData[i]?.baseType}
            handleDelete={handleDelete}
            bankTransactionData={bankTransactionData}
            fetchBankTransactions={fetchBankTransactions}
            setIsImportLoading={setIsImportLoading}
            loading={isImportLoading}
            isReconciled={bankTransactionData[i]?.is_reconciled}
          />
        ),
        archived: bankTransactionData[i]?.deleted_at ? (
          <CustomTooltip
            text={"Archived"}
            children={
              <span color={"red"} key={"red"}>
                <i class="fa fas fa-ban" style={{color: "red"}}></i>
              </span>
            }
          />
        ) : 
        (bankTransactionData[i]?.is_reconciled === 1 ? (
          <CustomTooltip
            text={"Reconciled"}
            children={
              <span color={"green"} key={"green"}>
                <i class="fa fas fa-check" style={{color: "green"}}></i>
              </span>
            }
          />
        ) 
        : (
          ""
        )),

        // action: "",
      });
    }
    return items;
  };

  const bankTransactions = bankTransactionGenerator(
    bankTransactionData?.length
  );

  useEffect(() => {
    fetchBankTransactions();
  }, [fetchBankTransactions, isImportLoading, page]);

  // console.log(isloa);
  useEffect(() => {
    navigate(
      `/business/${businessId}/reconcile-transactions?tab=bank-transactions&limit=${
        limit ? limit : 10
      }&page=${page ? page : 1}&search=${search ? search : ''}`
    );
  }, [navigate, businessId, page, limit, search]);

  const handleDateRange = (e) => {
    setBankTransactionData([]);
    setValueRange(e);
    setPage(1);
    setAccountId(accountId);
    cleanFilters();
    setSelectedRow([]);
    // setStartDate(e ? e[0] : "");
    // setEndDate(e ? e[1] : "");
  };

  const handleUpdateLimit = (e) => {
    setBankTransactionData([]);
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setBankTransactionData([]);
    setPage(page);
  };

  const handleRefresh = async () => {
    if (businessId) {
      setLoading(true);
      cleanFilters();
      setSelectedRow([]);
      if(startDate && endDate){
        const getTransaction = await refreshBankTransactions(businessId, startDate, endDate, accountFetchId);
        if (getTransaction?.status === 200) {
          toast.success(getTransaction?.message);
          setBankTransactionData([]);
          fetchBankTransactions();
        }
        setAccountId(accountFetchId);
        toggleRefreshModal();
      } else {
        toast.error("Start Date and End Date both are required");
      }
      setLoading(false);
    }
  };

  const handleExport = useCallback(
    async (format) => {
      try {
        setExportLoading(true);
        const { data } = await get(
          `${API.BANK_TRANSACTION_LIST}?from=${
            valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
          }&to=${
            valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
          }&client_business_id=${businessId ? businessId : ""}&format=${
            format ? format : ""
          }&export=1${accountId ? '&yodlee_account_id=' + accountId : ''}`
        );
        const export_report_path = data?.data?.file;
        setExportLoading(false);
        if (export_report_path) {
          var a = document.createElement("a");
          a.href = export_report_path;
          var file = export_report_path.split("/");
          a.setAttribute(
            "download",
            file[file.length - 1] || "bank_transaction.pdf"
          );
          a.setAttribute("target", "_blank");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          setExportLoading(false);
        }
      } catch (e) {
        setExportLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    },
    [businessId, valueRange, accountId]
  );

  const columns = [
    {
      dataField: "transactionDate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
    },
    {
      dataField: "debit",
      text: "Debit",
      sort: true,
    },
    {
      dataField: "bank_transaction_source",
      text: "Bank Transaction Source",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px", textAlign: "center" },
    },
    {
      dataField: "archived",
      text: "",
      style: { textAlign: "center" },
      // sort: true,
    },
  ];

  const bankModal = () => {
    fetchBankTransactions();
  };

  const toggleBankModal = () => {
    showBank && bankModal();
    setShowBank(!showBank);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    setBankTransactionData([]);
    if (type === "sort") {
      setPage(1);
      setSortField(
        sortField === "credit" || sortField === "debit" ? "amount" : sortField
      );
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleAccountChange = (item) => {
    cleanFilters();
    if (accountId !== '') {
      setSelectedRow([]);
      setBankTransactionData([]);
    }
    setAccountId(item?.value);
    setPage(1);
    // fetchBankTransactions(item?.value);
  }

  const handleAccountFetchChange = (item) => {
    setAccountFetchId(item?.value);
    setAccountMFA(item?.mfa);
    setAccountYodleeId(item?.accountId);
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ['not-select'],
    selected: selectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        singleSelectedRows = [...selectedRow, row?.key];
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      }
      else {
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    }
  };

  const ref = useRef();
  function cleanFilters() {
    //unselet table
    if (ref && typeof ref === "object") {
      if (ref.current !== undefined) {
        ref.current.selectionContext.selected = [];
      }
    }
  }

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }
  
  const dateFilterRange = [
    {
      label: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
  ]

  const getProviderBankData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getProviderBankDetail(businessId);
      setProviderBankData(data?.data);
      const yodleeStatus = data?.data[0]?.providerAccountId;
      setIsYodleeConnect(yodleeStatus);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [businessId]);

  const handleProviderBank = () => {
    getProviderBankData();
    setDisconnectModal(false);
  };

  
  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  const handleYodleeRefreshControl = async (providerID) => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(businessId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: "refresh",
        providerAccountId: providerID,
        configName: yodleeConfigName,
      };
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  return (
    <>
      <div className="cms-page bank-transaction-page">
        <div className="table-top-btn">
          <div className="search-section">
            <Form>
              <Form.Group>
                <p>Selected Period</p>
                <DateRangePicker
                  placeholder="Select Date Range"
                  onChange={(e) => handleDateRange(e)}
                  format="dd-MM-yyyy"
                  value={valueRange}
                  disabledDate={afterToday()}
                  ranges={dateFilterRange}
                  locale={{ok: "Apply"}}
                />
              </Form.Group>
            </Form>
            <div className="bank-account-dropdown">
              <p>Account</p>
              <Select 
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Account"
              value={accountId ? accountOptions?.find((i) => i.value === accountId) : {label: 'ALL', value: ''}}
              onChange={(item) => {
                handleAccountChange(item);
              }}
              loading={loading}
              classNamePrefix="account_select_bank"
              options={accountOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              />
            </div>
          </div>
          <div className="table-btn">
            <Button
              variant="primary"
              onClick={() => {
                toggleRefreshModal();
              }}
              disabled={balanceInfo.length > 0 ? false : true}
              title={balanceInfo.length > 0 ? "" : "Please connect bank account"}
            >
              Fetch
            </Button>
            <Button
              onClick={() => {
                toggleDuplicate()
              }}
              disabled={showDuplicate === false ? bankTransactionData?.length === 0 ? true : false : false}
            >
              {showDuplicate ? 'Show All' : 'Show Duplicate'}
            </Button>
            <Button
              onClick={() => {
                navigate(
                  `/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`
                );
              }}
            >
              Reconcile Transactions
            </Button>
            <Button
              onClick={() => toggleBankModal()}
              data-toggle="modal"
              data-target="#bankTransaction"
            >
              Add New
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                toggleDeleteAllModal();
              }}
              disabled={showDuplicate ? ((selectedRow?.length === 0) ? true : false) : bankTransactionData?.length === 0 ? true : false}
            >
              {showDuplicate ? "Delete Selected" : selectedRow?.length === 0 ? "Delete ALL" : "Delete Selected"}
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="primary" /*onClick={handleExport}*/  disabled={bankTransactionData?.length === 0 ? true : false}>
                {exportLoading ? "Loading..." : "Export"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport("csv")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport("pdf")}>
                  PDF
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport("xlsx")}>
                  Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="custom-table">
          {loading && <Spin />}
          <BootstrapTable
            ref={ref}
            keyField="key"
            remote
            data={bankTransactions}
            columns={columns}
            selectRow={selectRow}
            onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={Number(page)}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
      {showBank && (
        <AddBankTransaction
          businessId={businessId}
          showBank={showBank}
          // clientBusinessYodleeAccountId={clientBusinessYodleeAccountId}
          toggleBankModal={toggleBankModal}
        />
      )}

      {/* Modal of the Refresh */}
      <Modal
        size="lg"
        show={showRefresh}
        onHide={toggleRefreshModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section fetch-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Fetch Bank Feeds
        </Modal.Header>
        <Modal.Body>
          {(loading || openYodleeLoading) && <Spin />}
          <div className="modal-body">
           <div className="date-picker-refresh">
            <>
            <div>
            <p>Account</p>
            <Select 
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Account"
              value={accountFetchId ? accountFetchOptions?.find((i) => i.value === accountFetchId) : (accountFetchOptions ? accountFetchOptions[0] : '')}
              onChange={(item) => {
                handleAccountFetchChange(item);
              }}
              loading={loading}
              classNamePrefix="account_select_bank"
              options={accountFetchOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              />
              </div>
            <Form>
                <p>Select Date Range</p>
                <DateRangePicker
                  placeholder="Select Date Range"
                  onChange={(e) => handleDateModalRange(e)}
                  format="dd-MM-yyyy"
                  value={dateValue}
                  placement="bottomEnd"
                  shouldDisableDate={afterToday()}
                  ranges={[]}
                  // showOneCalendar
                />
              </Form>
            </>
              {/* <h5>Select Date Range</h5> */}
            
           </div>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                if(accountMFA === 1){
                  handleYodleeRefreshControl(accountYodleeId)
                } else {
                  handleRefresh();
                }
              }}
            >
              Fetch
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleRefreshModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal of Delete All */}
      {showDeleteAll && (
        <Modal
        size="lg"
        show={showDeleteAll}
        onHide={toggleDeleteAllModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Delete Bank Transaction
        </Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div className="modal-body">
            Are you sure you want to delete {selectedRow?.length === 0 ? 'all' : 'selected'} unreconciled transactions?
          </div>
          <div className="modal-footer">
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleDeleteAllModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                handleDeleteAll();
              }}
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      )}
      <Modal
        size="lg"
        show={openYodlee}
        onHide={handleYodleeModal}
        dialogClassName="modal-50w medium-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Auto Bank Feeds Account Integration
        </Modal.Header>
        <Modal.Body>
          <YodleeConnectModal
            handleYodleeModal={handleYodleeModal}
            fastLinkData={fastLinkData}
            businessId={businessId}
            handleProviderBank={handleProviderBank}
            setOpenYodleeLoading={setLoading}
            handleSucess={handleRefresh}
            accountFetchId={accountFetchId}
            setAccountId={setAccountId}
            providerBankData={providerBankData}
            isYodleeConnect={isYodleeConnect}
            disconnectModal={disconnectModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BankTransactions;
