import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  API,
  // entityTypes,
  fileUpload,
  imageDefaultPrefixPath,
  metaTitle,
  // putCall,
} from "../../../config";
import {
  formatedAddress,
  formatedPostalCode,
  formatName,
} from "../../common/Misc";
import Spin from "../../common/Spin";
import { AddClientSchema } from "../../common/Validation";
import {
  getClientDetail,
  useManagerList,
  useOfficeList,
  useStaffList,
  useStateList,
  useSuburbListE,
} from "./components/ClientHelper";
import Form from "./components/Form";
import TitleBar from "../../common/TitleBar";

const EditClient = () => {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const clientId = query.get("id");
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  useEffect(() => {
    async function fetchData() {
      try {
        // await getUsers();
        setLoading(true);
        const data = await getClientDetail(clientId);
        setClientData(data);
        setLoading(false);
      } catch (e) {
        setClientData(null);
      }
    }
    fetchData();
    window.document.title = `${metaTitle} | Edit Client`
  }, [clientId]);

  // Submit Form
  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.profile_pic = value?.image
      ? value?.image
      : value?.profile_pic
      ? value?.profile_pic
      : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : ""; // value.entity_type = value?.entity_type?.value;

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.value;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.value;

    value.staff_id = value?.staff_id?.value;
    value.manager_id = value?.manager_id?.value;
    value.office_id = value?.office_id?.value;
    value.profile_pic =
      value?.profile_pic && typeof value?.profile_pic === "string"
        ? value?.profile_pic - imageDefaultPrefixPath
        : value?.profile_pic;

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone != null ? value?.phone : "");
    formData.append("mobile", value?.mobile != null ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "display_name",
      value?.display_name ? value?.display_name : ""
    );
    formData.append("abn", value?.abn != null ? value?.abn : "");
    // formData.append(
    //   "entity_type",
    //   value?.entity_type ? value?.entity_type : ""
    // );
    formData.append("crn", value?.crn ? value?.crn : "");
    formData.append("_method", "put");
    formData.append(
      "profile_pic",
      value?.profile_pic ? value?.profile_pic : ""
    );
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );
    formData.append("staff_id", value?.staff_id ? value?.staff_id : "");
    formData.append("manager_id", value?.manager_id ? value?.manager_id : "");
    formData.append("office_id", value?.office_id ? value?.office_id : "");
    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.CLIENT_ADD}/${clientId}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate("/clients");
      }
      return data?.data;
    } catch (error) {
      setClientData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) =>
      item?.value ===
      (clientData?.state_id
        ? clientData?.state_id
        : clientData?.client?.state_id)
  );

  // Suburb
  const { suburbLoading, suburbList = [] } = useSuburbListE(
    clientData?.state_id ? clientData?.state_id : clientData?.client?.state_id
  );
  const suburbListing = suburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const suburb = suburbListing?.find(
    (item) =>
      item?.value ===
      (clientData?.suburb_id
        ? clientData?.suburb_id
        : clientData?.client?.suburb_id)
  );

  // ShippingState
  const shippingStateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const shippingState = shippingStateListing?.find(
    (item) =>
      item?.value ===
      (clientData?.shipping_state_id
        ? clientData?.shipping_state_id
        : clientData?.client?.shipping_state_id)
  );

  // ShippingSuburb
  const { suburbLoading2, shippingSuburbList = [] } = useSuburbListE(
    clientData?.shipping_state_id
      ? clientData?.shipping_state_id
      : clientData?.client?.shipping_state_id
  );
  const shippingSuburbListing = shippingSuburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const shippingSuburb = shippingSuburbListing?.find(
    (item) =>
      item?.value ===
      (clientData?.shipping_suburb_id
        ? clientData?.shipping_suburb_id
        : clientData?.client?.shipping_suburb_id)
  );

  // Staff
  const { staffLoading, staffList = [] } = useStaffList();
  const staffListing = staffList?.map((item) => {
    const name = formatName(item?.first_name, item?.last_name);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  const staff = staffListing?.find(
    (item) =>
      item?.value ===
      (clientData?.staff_id
        ? clientData?.staff_id
        : clientData?.client?.staff_id)
  );

  // Manager
  const { managerLoading, managerList = [] } = useManagerList();
  const managerListing = managerList?.map((item) => {
    const name = formatName(item?.first_name, item?.last_name);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  const manager = managerListing?.find(
    (item) =>
      item?.value ===
      (clientData?.manager_id
        ? clientData?.manager_id
        : clientData?.client?.manager_id)
  );

  // Office
  const { officeLoading, officeList = [] } = useOfficeList();
  const officeListing = officeList?.map((item) => {
    const name = formatedAddress(item?.street, item?.suburb?.name, item?.state?.short_name, item?.postal_code);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  const office = officeListing?.find(
    (item) =>
      item?.value ===
      (clientData?.office_id
        ? clientData?.office_id
        : clientData?.client?.office_id)
  );

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar
            label={formatName(clientData?.first_name, clientData?.last_name)}
            businessNameData={false}
            globalSearch={false}
          />
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  first_name: clientData?.first_name
                    ? clientData?.first_name
                    : "",
                  last_name: clientData?.last_name ? clientData?.last_name : "",
                  phone: clientData?.phone ? clientData?.phone : "",
                  mobile: clientData?.mobile ? clientData?.mobile : "",
                  email: clientData?.email ? clientData?.email : "",
                  business_name: clientData?.business_name
                    ? clientData?.business_name
                    : clientData?.client?.business_name,
                  display_name: clientData?.display_name
                    ? clientData?.display_name
                    : clientData?.client?.display_name,
                  abn: clientData?.abn
                    ? clientData?.abn
                    : clientData?.client?.abn,
                  // entity_type: entityTypes?.find(
                  //   (item) => item?.value === clientData?.client?.entity_type
                  // ),
                  crn: clientData?.crn
                    ? clientData?.crn
                    : clientData?.client?.crn,
                  profile_pic: clientData?.profile_pic
                    ? imageDefaultPrefixPath + clientData?.profile_pic
                    : "",
                  street: clientData?.street
                    ? clientData?.street
                    : clientData?.client?.street,
                  state_id: state,
                  suburb_id: suburb,
                  postal_code: clientData?.postal_code
                    ? formatedPostalCode(clientData?.postal_code)
                    : formatedPostalCode(clientData?.client?.postal_code),

                  shipping_street: clientData?.shipping_street
                    ? clientData?.shipping_street
                    : clientData?.client?.shipping_street,
                  shipping_state_id: shippingState,
                  shipping_suburb_id: shippingSuburb,
                  shipping_postal_code: clientData?.shipping_postal_code
                    ? formatedPostalCode(clientData?.shipping_postal_code)
                    : formatedPostalCode(
                        clientData?.client?.shipping_postal_code
                      ),

                  staff_id: staff,
                  manager_id: manager,
                  office_id: office,
                }}
                enableReinitialize
                innerRef={formValues}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddClientSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                        officeListing={officeListing}
                        officeLoading={officeLoading}
                        managerListing={managerListing}
                        managerLoading={managerLoading}
                        staffListing={staffListing}
                        staffLoading={staffLoading}
                        suburbListing={suburbListing}
                        shippingSuburbListing={shippingSuburbListing}
                        erSuburbLoading={suburbLoading}
                        erSuburbLoading2={suburbLoading2}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClient;
