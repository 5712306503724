import React, { useEffect, useRef, useState } from 'react';
import { API, fileUpload, get } from '../../../../config';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Form from './components/Form';
import { Formik } from 'formik';
import { useCallback } from 'react';
import { formatName } from '../../../common/Misc';
import { AddCashPaymentSchema } from '../../../common/Validation';
import Spin from '../../../common/Spin';
import moment from 'moment';

const EditCashReceipts = ({
  cash_receiptId,
  toggleEditModal,
  businessId,
  fetchReceipts,
  showEdit
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const [accountLoading, setAccountLoading] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [allAccountValue, setAllAccountValue] = useState([]);
  const [allContactList, setAllContactList] = useState([]);
  const [loadingContactList, setLoadingContactList] = useState(false);
  const [allContactValue, setAllContactValue] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [jobOptions, setJobOptions] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskOptions, setTaskOptions] = useState([]);

  const [newTransactionDate, setNewTransactionDate] = useState()
  const [newDescription, setNewDescription] = useState();
  const [newGst, setNewGst] = useState();
  const [newAmount, setNewAmount] = useState();

  const [addNewContact, setAddNewContact] = useState(false);
  const [newContact, setNewContact] = useState();

  const [addNewAccount, setAddNewAccount] = useState(false);
  const [newAccount, setNewAccount] = useState();

  const [addNewJob, setAddNewJob] = useState(false);
  const [newJob, setNewJob] = useState();

  const [addNewTask, setAddNewTask] = useState(false);
  const [newTask, setNewTask] = useState();

  const handleSubmit = async (value) => {
    const formData = new FormData();

    value.account_id = value?.account_id ? value?.account_id?.value : newAccount ? newAccount.value : '';
    value.contact_id = value?.contact_id ? value?.contact_id?.value : newContact ? newContact.value : '';
    value.business_job_id = value?.business_job_id ? value?.business_job_id?.value : newJob ? newJob.value : '';
    value.business_task_id = value?.business_task_id ? value?.business_task_id?.value : newTask ? newTask.value : '';

    value.gst = value?.gst ? value?.gst?.value : newGst ? newGst.value : '';
    value.amount = parseFloat(value?.amount).toFixed(2);

    formData.append('client_business_id', businessId);
    formData.append('transactionDate', value?.transactionDate
      ? moment(value?.transactionDate, 'DD-MM-YYYY').format('DD-MM-YYYY') 
      : newTransactionDate ? moment(newTransactionDate, 'DD-MM-YYYY').format('DD-MM-YYYY') : ''
    );
    formData.append('chart_of_account_id', value?.account_id ? value?.account_id : '');
    formData.append('gst', value?.gst);
    formData.append('contact_id', value?.contact_id ? value?.contact_id : '');
    formData.append('description', value?.description ? value?.description : '');
    formData.append('amount', value?.amount ? value?.amount : newAmount ? parseFloat(newAmount).toFixed(2) : '');
    if(value?.business_job_id){
      formData.append('business_job_id', value?.business_job_id ? value?.business_job_id : '');
    }
    if(value?.business_task_id){
      formData.append('business_task_id', value?.business_task_id ? value?.business_task_id : '');
    }
    formData.append('_method', 'put');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.CASH_RECEIPT_ADD}/${cash_receiptId}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleEditModal();
        fetchReceipts();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await get(`${API.CASH_RECEIPT_DETAILS}/` + cash_receiptId);
        setPaymentData(data.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setPaymentData(null);
      }
    }
    fetchData();
  }, [cash_receiptId]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const getAccounts = useCallback(async () => {
    try {
      setAccountLoading(true);
      get(
        `${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`
        ).then((response) => {
          setAccountLoading(false);
          var revenue =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "fada83c9-4769-49b9-ab0e-fcd8c43de30e"
                  );
                })
              : [];
  
          const revenueList =
            revenue && revenue?.length > 0
              ? revenue?.map((item, index) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];
  
            var expenses =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fad90f00-6651-4c51-a29a-78398fa226fd"
                    );
                  })
                : [];
    
            const expensesList =
              expenses && expenses?.length > 0
                ? expenses?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var currentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "3e5a94ef-b819-47c3-9955-d47da29e2ab0"
                    );
                  })
                : [];
    
            const currentLiabilitiesList =
              currentLiabilities && currentLiabilities?.length > 0
                ? currentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fcb7e129-7666-42ae-8c3d-11d016bbe340"
                    );
                  })
                : [];
        
            const nonCurrentLiabilitiesList =
              nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
                ? nonCurrentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
            
            var currentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "994d6d38-497b-465d-9f57-3bdc2d70594a"
                    );
                  })
                : [];
          
            const currentAssetsList =
              currentAssets && currentAssets?.length > 0
                ? currentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "4051efd3-9fb8-4adb-b0c7-7e565a078ac1"
                    );
                  })
                : [];
            
            const nonCurrentAssetsList =
              nonCurrentAssets && nonCurrentAssets?.length > 0
                ? nonCurrentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var equity =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "520f936e-3017-4574-a9ab-6c9e68b591e4"
                    );
                  })
                : [];
              
            const equityList =
              equity && equity?.length > 0
                ? equity?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
              const allAccountListing = [
              revenueList && { label: <div className="list-subHeading">Revenue</div>, options: revenueList },
              expensesList && { label: <div className="list-subHeading">Expenses</div>, options: expensesList },
              currentLiabilitiesList && { label: <div className="list-subHeading">Current Liabilities</div>, options: currentLiabilitiesList },
              nonCurrentLiabilitiesList && { label: <div className="list-subHeading">Non Current Liabilities</div>, options: nonCurrentLiabilitiesList },
              currentAssetsList && { label: <div className="list-subHeading">Current Assets</div>, options: currentAssetsList},
              nonCurrentAssetsList && { label: <div className="list-subHeading">Non Current Assets</div>, options: nonCurrentAssetsList},
              equityList && { label: <div className="list-subHeading">Equity</div>, options: equityList},
              { label: <strong>+ Add New Account</strong>, value: "new" }
            ];
  
            setAccountData(allAccountListing)
            setAllAccountValue([
              ...revenueList,
              ...expensesList,
              ...currentLiabilitiesList,
              ...nonCurrentLiabilitiesList,
              ...currentAssetsList,
              ...nonCurrentAssetsList,
              ...equityList
            ])
        });
    } catch (e) {
      setAccountLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  const handleAddNewAccount = () => {
    setAddNewAccount(!addNewAccount);
  };

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const handleContactList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingContactList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && { label: <div className="list-subHeading">Contractors</div>, options: contractorList },
          supplierList && { label: <div className="list-subHeading">Suppliers</div>, options: supplierList },
          customerList && { label: <div className="list-subHeading">Customers</div>, options: customerList },
          { label: <strong>+ Add New Contact</strong>, value: "new" }
        ];

        setAllContactValue([...contractorList, ...supplierList, ...customerList]);

        setAllContactList(allContactListing);

        setLoading(false);
        setLoadingContactList(false);
      });
    } catch (error) {
      setLoading(false);
      setLoadingContactList(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  const handleJobChange = useCallback(async () => {
    try {
      setJobLoading(true);
      get(
        `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const jobList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id,
              tax: item?.tax
            };
            return list;
          });
        setJobOptions(jobList ? [ ...jobList, { label: <strong>+ Add New Job</strong>, value: "new" }] : [{ label: <strong>+ Add New Job</strong>, value: "new" }]);
        setJobLoading(false);
      });
    } catch (error) {
      setJobLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleAddNewJob = () => {
    setAddNewJob(!addNewJob);
  };

  const handleTaskChange = useCallback(async () => {
    try {
      setTaskLoading(true);
      get(
        `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const taskList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id,
              tax: item?.tax
            };
            return list;
          });
        setTaskOptions(taskList ? [ ...taskList, { label: <strong>+ Add New Task</strong>, value: "new" }] : [{ label: <strong>+ Add New Task</strong>, value: "new" }]);
        setTaskLoading(false);
      });
    } catch (error) {
      setTaskLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleAddNewTask = () => {
    setAddNewTask(!addNewTask);
  };

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  useEffect(() => {
    handleJobChange();
  }, [handleJobChange]);

  useEffect(() => {
    handleTaskChange();
  }, [handleTaskChange]);

  const GSTOptions = [
    { label: "GST 10%", value: 1 },
    { label: "GST Excluded", value: 0 },
    { label: "BAS Excluded", value: 2 },
    { label: "GST on Imports", value: 3 },
  ];

  return (
    <>
      <Modal
        size="lg"
        show={showEdit}
        onHide={toggleEditModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section new-transaction-modal cash-transaction-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Edit Cash Receipts
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              transactionDate: newTransactionDate ? newTransactionDate : paymentData?.transactionDate
                ? new Date (moment(paymentData?.transactionDate, 'DD-MM-YYYY'))
                : '',
              account_id: newAccount ? newAccount : paymentData?.chart_of_account_id
                ? allAccountValue?.find((item) => item?.value === paymentData?.chart_of_account_id)
                : '',
              gst: newAccount ? GSTOptions.find((i) => i.value === parseInt(newAccount?.tax))  : newGst ? newGst : GSTOptions.find((i) => i.value === paymentData?.gst),
              contact_id: newContact ? newContact : paymentData?.contact_id
              ? allContactValue?.find((item) => item?.value === paymentData?.contact_id)
              : '',
              description: newDescription ? newDescription : paymentData?.description ? paymentData.description : '',
              amount: newAmount ? newAmount : paymentData?.amount ? paymentData.amount : '',
              business_job_id: newJob ? newJob : paymentData?.business_job_id
              ? jobOptions?.find((item) => item?.value === paymentData?.business_job_id)
              : '',
              business_task_id: newTask ? newTask : paymentData?.business_task_id
              ? taskOptions?.find((item) => item?.value === paymentData?.business_task_id)
              : ''
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddCashPaymentSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => {
              return (
                <>
                  {(loading ||
                    loadingContactList ||
                    taskLoading ||
                    jobLoading ||
                    accountLoading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleShowModal={toggleEditModal}
                    fetchReceipts={fetchReceipts}
                    accountLoading={accountLoading}
                    accountData={accountData}
                    handleContactList={handleContactList}
                    allContactList={allContactList}
                    loadingContactList={loadingContactList}
                    allContactValue={allContactValue}
                    jobOptions={jobOptions}
                    jobLoading={jobLoading}
                    taskOptions={taskOptions}
                    taskLoading={taskLoading}
                    GSTOptions={GSTOptions}
                    handleAddNewContact={handleAddNewContact}
                    setAddNewContact={setAddNewContact}
                    addNewContact={addNewContact}
                    businessId={businessId}
                    setNewContact={setNewContact}
                    newContact={newContact}
                    setNewAccount={setNewAccount}
                    newAccount={newAccount}
                    handleAddNewAccount={handleAddNewAccount}
                    setAddNewAccount={setAddNewAccount}
                    addNewAccount={addNewAccount}
                    getAccounts={getAccounts}
                    setNewJob={setNewJob}
                    newJob={newJob}
                    handleAddNewJob={handleAddNewJob}
                    setAddNewJob={setAddNewJob}
                    addNewJob={addNewJob}
                    handleJobChange={handleJobChange}
                    setNewTask={setNewTask}
                    newTask={newTask}
                    handleAddNewTask={handleAddNewTask}
                    setAddNewTask={setAddNewTask}
                    addNewTask={addNewTask}
                    handleTaskChange={handleTaskChange}
                    setNewTransactionDate={setNewTransactionDate}
                    newTransactionDate={newTransactionDate}
                    setNewDescription={setNewDescription}
                    newDescription={newDescription}
                    setNewGst={setNewGst}
                    newGst={newGst}
                    setNewAmount={setNewAmount}
                    newAmount={newAmount}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCashReceipts;
