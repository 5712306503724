import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import QuoteSettings from "./QuoteSettings";
// import InvoiceSettings from "./InvoiceSettings";
// import ReceiptSettings from "./ReceiptSettings";
// import BackButton from '../../../../components/Form/BackButton';
import ChildQuoteSettingsTab from './ChildQuoteSettingsTab';
import ChildInvoiceSettingsTab from './ChildInvoiceSettingsTab';
import ChildReceiptSettingsTab from './ChildReceiptSettingsTab';
import TitleBar from '../../../common/TitleBar';
import JobTaskSettings from '../../settings/JobTaskSettings';
import StandardParagraph from '../../StandardParagrah/StandardParagrah';
import DiscountCode from '../../DiscountCode/DiscountCode';

const tabsKey = {
  quote: 1,
  invoice: 2,
  receipt: 3,
  'job-task': 4,
  'standard-paragraph': 5,
  'discount-code': 6
};

const InvoicingSettingsTab = () => {
  const location = useLocation();
  const params = useParams();
  const businessId = params?.business_id;
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const selectedTab = query.get('tab') || 'quote';
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? 'Quote'
      : parseInt(defaultActiveKey) === 2
      ? 'Invoice'
      : parseInt(defaultActiveKey) === 3
      ? 'Receipt'
      : parseInt(defaultActiveKey) === 4
      ? 'Job/Task'
      : parseInt(defaultActiveKey) === 5
      ? 'Standard Paragraph'
      : 'Discount Code'
  );

  useEffect(() => {
    window.document.title = `SME CashBook | ${activeTab} Settings`;
  }, [activeTab]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            {/* <h1 className="page-title">
              <BackButton />
            </h1> */}
            {activeTab === 'Job/Task' ||
            activeTab === 'Standard Paragraph' ||
            activeTab === 'Discount Code' ? (
              <></>
            ) : (
              <TitleBar
                label={
                  activeTab === 'Invoice'
                    ? 'Invoice Settings'
                    : activeTab === 'Quote'
                    ? 'Quote Settings'
                    : 'Receipt Settings'
                }
              />
            )}
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                    navigate(
                      `/business/${businessId}/invoicing/settings?tab=${
                        event?.target?.innerText === 'Quote'
                          ? 'quote'
                          : event?.target?.innerText === 'Invoice'
                          ? 'invoice'
                          : event?.target?.innerText === 'Receipt'
                          ? 'receipt'
                          : event?.target?.innerText === 'Job/Task'
                          ? 'job-task'
                          : event?.target?.innerText === 'Standard Paragraph'
                          ? 'standard-paragraph'
                          : 'discount-code'
                      }`
                    );
                  }}
                >
                  <li className={activeTab === 'Quote' ? 'active' : ''}>
                    <Link key={1}>Quote</Link>
                  </li>
                  <li className={activeTab === 'Invoice' ? 'active' : ''}>
                    <Link key={2}>Invoice</Link>
                  </li>
                  <li className={activeTab === 'Receipt' ? 'active' : ''}>
                    <Link key={3}>Receipt</Link>
                  </li>
                  <li className={activeTab === 'Job/Task' ? 'active' : ''}>
                    <Link key={4}>Job/Task</Link>
                  </li>
                  <li className={activeTab === 'Standard Paragraph' ? 'active' : ''}>
                    <Link key={5}>Standard Paragraph</Link>
                  </li>
                  <li className={activeTab === 'Discount Code' ? 'active' : ''}>
                    <Link key={6}>Discount Code</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === 'Quote' && (
                  // <QuoteSettings />
                  <ChildQuoteSettingsTab />
                )}
                {activeTab === 'Invoice' && (
                  // <InvoiceSettings />
                  <ChildInvoiceSettingsTab />
                )}
                {activeTab === 'Receipt' && (
                  // <ReceiptSettings />
                  <ChildReceiptSettingsTab />
                )}
                {activeTab === 'Job/Task' && <JobTaskSettings />}
                {activeTab === 'Standard Paragraph' && <StandardParagraph />}
                {activeTab === 'Discount Code' && <DiscountCode />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicingSettingsTab;
