import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { API, fileUpload, get } from '../../../config';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import UploadFile from '../../common/UploadFile';
import BootstrapTable from 'react-bootstrap-table-next';
import Spin from '../../common/Spin';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { useParams } from 'react-router';
import { formatNumber } from '../../common/Misc';
import NumberFormat from 'react-number-format';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import AddNewAccountJournalEntry from './AddNewAccountJournalEntry';
import CustomTooltip from '../../common/CustomTooltip';

const ExcelJournalEntry = ({
  fetchJournalEntries,
  lastBatchId,
  accountOptions,
  accountTypesList,
  handleAccountList
}) => {
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [disableSelectedRow, setDisableSelectedRow] = useState([]);
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelData, setExcelData] = useState();
  const [period, setPeriod] = useState();
  const [rowID, setRowID] = useState();

  const today = new Date();
  const [transactionDate, setTransactionDate] = useState(today);
  const [batchID, setBatchID] = useState(lastBatchId);
  const [debitTotal, setDebitTotal] = useState();
  const [creditTotal, setCreditTotal] = useState();
  const [addNewAccount, setAddNewAccount] = useState(false);

  const [newAccountName, setNewAccountName] = useState('');
  const [newAccountCode, setNewAccountCode] = useState('');
  const [newAccountType, setNewAccountType] = useState('');
  const [newAccountTransactionType, setNewAccountTransactionType] = useState('');

  const params = useParams();
  const businessId = params?.business_id;

  const handleAddNewAccount = () => {
    setAddNewAccount(!addNewAccount);
  };

  /**
   * Download
   */
  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(API.DOWNLOAD_JOURNAL_ENTRIES);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement('a');
        a.href = export_report_path;
        var file = export_report_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'businessess.xls');
        a.setAttribute('target', '_blank');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  /**
   * Import Module
   */
  const handleImportModal = async () => {
    setShowImport(true);
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const handleExcelClose = () => {
    setSelectedRow([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const accuntingList = accountTypesList?.map((item) => {
    const { name: label, name: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const ExcelColumns = [
    {
      dataField: 'classification',
      text: 'Classification',
      editor: {
        type: Type.SELECT,
        options: accuntingList
      },
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: 'code',
      text: 'Account No.',
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if (newValue === '' || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: 'Account No. is Required'
          };
        }
        return true;
      }
    },
    {
      dataField: 'account_name',
      text: 'Account Name',
      editCellClasses: 'edit-cell-excel-class'
    },
    {
      dataField: 'debit',
      text: 'Debit',
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if (newValue) {
          if (!newValue.match(/^[\d.,]*$/)) {
            return {
              valid: false,
              message: 'Debit can contain digit numbers.'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (!String(cell).match(/^[\d.,]*$/)) {
            return 'error-cell';
          }
        }
      }
    },
    {
      dataField: 'credit',
      text: 'Credit',
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if (newValue) {
          if (!newValue.match(/^[\d.,]*$/)) {
            return {
              valid: false,
              message: 'Credit can contain digit numbers.'
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (!String(cell).match(/^[\d.,]*$/)) {
            return 'error-cell';
          }
        }
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      editable: false
    }
  ];

  let singleSelectedRows = [];
  let allSelectedRows = [];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: 'selection-row',
    nonSelectable: disableSelectedRow,
    selected: selectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        singleSelectedRows = [...selectedRow, row?.key];
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    }
  };

  const ref = useRef();

  const ExcelReader = () => {
    let f = files;
    const reader = new FileReader();
    let data = null;
    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      let selectItem = [];
      let disableSelectItem = [];
      const businessExcelGenerator = (quantity) => {
        const items = [];
        if (
          data[0][0] !== 'Classification' ||
          String(data[0][1]).replace('*', '') !== 'Account No.' ||
          data[0][2] !== 'Account Name' ||
          String(data[0][3]).replace('*', '') !== 'Debit' ||
          String(data[0][4]).replace('*', '') !== 'Credit'
        ) {
          setShowExcelModal(false);
          setFiles();
          setIsImportLoading(false);
          toast.error(
            'Sorry, File is not in the given format, please download the latest format again'
          );
        } else {
          for (let i = 1; i < quantity; i++) {
            if (data[i].length > 0) {
              let classification = data[i][0];
              let code =
                String(data[i][1])?.length < 4
                  ? padWithLeadingZeros(data[i][1], 4)
                  : String(data[i][1]);
              let account_type_id = accuntingList?.find((m) => m.value === classification)
                ? accuntingList?.find((m) => m.value === classification)
                : '';
              let transaction_type = data[i][3] ? 0 : 1;
              let account_name = data[i][2];
              if(accountOptions?.find((m) => String(m.code) === String(code))){
                selectItem.push(i);
              } else {
                disableSelectItem.push(i);
              }
              items.push({
                key: i,
                classification: data[i][0] ? data[i][0] : '',
                account_type_id: accuntingList?.find((m) => m.value === classification)
                  ? accuntingList?.find((m) => m.value === classification)
                  : '',
                code: code ? code : '',
                account_name: data[i][2] ? data[i][2] : '',
                debit: data[i][3]
                  ? `${formatNumber(Number(String(data[i][3]).replace('$', '').replace(',', '')))}`
                  : '',
                credit: data[i][4]
                  ? `${formatNumber(Number(String(data[i][4]).replace('$', '').replace(',', '')))}`
                  : '',
                chart_of_account_id: accountOptions?.find((m) => String(m.code) === String(code))
                  ? accountOptions?.find((m) => String(m.code) === String(code))?.value
                  : '',
                action: accountOptions?.find((m) => String(m.code) === String(code)) ? (
                  ''
                ) : (
                  <CustomTooltip
                    text={'Add Chart of Account'}
                    children={
                      <FontAwesomeIcon
                        icon={faAdd}
                        onClick={() => {
                          handleAddNewAccount();
                          setRowID(i);
                          setNewAccountName(account_name);
                          setNewAccountType(account_type_id);
                          setNewAccountCode(code);
                          setNewAccountTransactionType(transaction_type);
                        }}
                      />
                    }
                  />
                )
              });
            }
          }
        }

        return items;
      };
      setDisableSelectedRow(disableSelectItem);
      setSelectedRow(selectItem);
      setExcelData(businessExcelGenerator(data?.length));
    };
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    if (showExcelModal) {
      showExcelModal && ExcelReader();
    }
    // eslint-disable-next-line
  }, [showExcelModal]);

  const handleExcelModal = () => {
    setShowExcelModal(true);
  };

  const handleUpload = async () => {
    if (selectedRow.length > 1000) {
      toast.warn('Maximum limit to import contacts is 1000');
    } else {
      if (files) {
        setShowExcelModal(true);
        const formData = new FormData();

        formData.append('client_business_id', businessId);
        formData.append(
          'transactionDate',
          transactionDate ? moment(transactionDate).format('DD-MM-YYYY') : ''
        );
        formData.append('period', period ? period : '');
        // formData.append('cash_transaction', cashTransaction ? 1 : 0);
        formData.append('batch_id', batchID ? batchID : lastBatchId);

        for (let i = 0; i < selectedRow.length; i++) {
          let row = excelData.find((item) => item.key === selectedRow[i]);
          row.transaction_type = row?.debit ? 0 : 1;
          delete row.action;
          if (row.debit === '' && row.credit === '') {
            toast.warn(`Row - ${i + 1} please enter amount either for debit or for credit`);
            return false;
          }
          formData.append(`journal_entry[${i}]`, JSON.stringify(row));
        }

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        try {
          setIsImportLoading(true);
          const { data } = await fileUpload(`${API.IMPORT_JOURNAL_ENTRIES}`, formData, config);
          setIsImportLoading(false);
          if (data) {
            toast.success(data?.message);
            setSelectedRow([]);
            handleExcelClose();
            fetchJournalEntries();
            handleImportClose();
          }
          return data?.data;
        } catch (error) {
          if (error?.response?.data?.errors) {
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
            if (error?.response?.data?.data?.failedData.length > 0) {
              let failData = [];
              for (let i = 0; i < error?.response?.data?.data?.failedData.length; i++) {
                let contactItem = error?.response?.data?.data?.failedData[i]?.data;
                failData.push(contactItem);
              }
              setSelectedRow([]);
              setExcelData(failData);
            }
            fetchJournalEntries();
            setIsImportLoading(false);
          } else {
            toast.error(error?.message);
            setIsImportLoading(false);
          }
        }
      }
    }
  };

  const handleExcelTableChange = async (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    const getWithPromiseAll = async () => {
      let temp = await Promise.all(
        data.map(async (row) => {
          if (row?.key === rowId) {
            const newRow = { ...row };
            if (dataField === 'debit') {
              if (newValue !== '') {
                newRow[dataField] = formatNumber(Number(String(newValue).replace(',', '')));
              } else {
                newRow[dataField] = newValue;
              }
            } else if (dataField === 'credit') {
              if (newValue !== '') {
                newRow[dataField] = formatNumber(Number(String(newValue).replace(',', '')));
              } else {
                newRow[dataField] = newValue;
              }
            } else if (dataField === 'code') {
              if (accountOptions?.find((m) => String(m.code) === newValue)) {
                newRow['action'] = '';
                newRow['chart_of_account_id'] = accountOptions?.find(
                  (m) => String(m.code) === newValue
                )?.value;
                newRow[dataField] = newValue;
              } else {
                let transaction_type = newRow['debit'] ? 0 : 1;
                newRow['action'] = (
                  <>
                    <CustomTooltip
                      text={'Add Chart of Account'}
                      children={
                        <FontAwesomeIcon
                          icon={faAdd}
                          onClick={() => {
                            setRowID(rowId);
                            handleAddNewAccount();
                            setNewAccountName(newRow['account_name']);
                            setNewAccountType(newRow['account_type_id']);
                            setNewAccountCode(newValue);
                            setNewAccountTransactionType(transaction_type);
                          }}
                        />
                      }
                    />
                  </>
                );
                newRow[dataField] = newValue;
              }
            } else {
              newRow[dataField] = newValue;
            }
            return newRow;
          }
          return row;
        })
      );
      return temp;
    };
    const result = await getWithPromiseAll();
    setExcelData(result);
  };

  useEffect(() => {
    var month = moment(transactionDate).month() + 1;
    if (month === 7) {
      setPeriod(1);
    } else if (month === 8) {
      setPeriod(2);
    } else if (month === 9) {
      setPeriod(3);
    } else if (month === 10) {
      setPeriod(4);
    } else if (month === 11) {
      setPeriod(5);
    } else if (month === 12) {
      setPeriod(6);
    } else if (month === 1) {
      setPeriod(7);
    } else if (month === 2) {
      setPeriod(8);
    } else if (month === 3) {
      setPeriod(9);
    } else if (month === 4) {
      setPeriod(10);
    } else if (month === 5) {
      setPeriod(11);
    } else {
      setPeriod(12);
    }
  }, [transactionDate]);

  useEffect(() => {
    let debitTotalData = 0;
    let creditTotalData = 0;
    for (let i = 0; i < selectedRow.length; i++) {
      let row = excelData.find((item) => item.key === selectedRow[i]);
      row.debit = row.debit.replace(',', '');
      row.credit = row.credit.replace(',', '');
      debitTotalData = debitTotalData + Number(row.debit);
      creditTotalData = creditTotalData + Number(row.credit);
    }
    setDebitTotal(debitTotalData);
    setCreditTotal(creditTotalData);
  }, [selectedRow, excelData]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  return (
    <div>
      <div className="border-btn" style={{ alignItems: 'center', maxHeight: '48px' }}>
        <DropdownButton className="info-icon">
          <Dropdown.Item>
            <p>Account no. - Required</p>
            <p>Debit - Required</p>
            <p>Credit - Required</p>
          </Dropdown.Item>
        </DropdownButton>
        <Button
          variant="link"
          disabled={isLoadingDownload}
          onClick={() => handleDownload()}
          className="download-template"
        >
          Download Template
        </Button>
        <Button variant="primary" onClick={handleImportModal}>
          Import Journal Entries
        </Button>
      </div>
      {showImport && (
        <Modal
          size="lg"
          show={showImport}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Import Journal Entries
          </Modal.Header>
          <Modal.Body>
            <div>
              <UploadFile
                isLoading={isImportLoading}
                setFiles={setFiles}
                files={files}
                handleUpload={handleExcelModal}
                handleCancel={handleImportClose}
                acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ErrorMessage="xls and xlsx"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showExcelModal && (
        <Modal
          // size="xl"
          show={showExcelModal}
          onHide={handleExcelClose}
          dialogClassName="full-popup large-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="excel-journalentry-modal"
        >
          <Modal.Header className="mb-0" closeButton>
            Import Journal Entries
          </Modal.Header>
          <Modal.Body>
            <div className="excel-table-list" style={{ padding: '20px' }}>
              {isImportLoading && <Spin />}
              <span className="journal-entry-status" style={{ justifyContent: 'space-between' }}>
                <div className="row" style={{ marginBottom: '15px' }}>
                  <div className="col-md-3">
                    <div className="journal-table">
                      <label>
                        <span className="required">*</span>Batch No.
                      </label>
                      <NumberFormat
                        type="text"
                        placeholder="Enter Batch No."
                        value={batchID ? batchID : lastBatchId}
                        onChange={(e) => {
                          setBatchID(e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="journal-date new-transaction-modal">
                      <label>
                        <span className="required">*</span>Date:
                      </label>
                      <DatePicker
                        name="transactionDate"
                        onChange={(e) => {
                          setTransactionDate(e);
                        }}
                        style={{
                          height: '25.14px'
                        }}
                        cleanable={false}
                        value={transactionDate ? transactionDate : ''}
                        placeholder="Select Date"
                        format="dd-MM-yyyy"
                        disabledDate={(date) => moment(date).isAfter(moment())}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="journal-table">
                      <label>Period:</label>
                      <input type="text" placeholder="Period" value={period} disabled />
                    </div>
                  </div>
                </div>
                <div className="journal-entry">
                  <p>
                    Total Debit
                    <i>{debitTotal ? formatNumber(debitTotal) : 0.0}</i>
                  </p>
                  <p>
                    Total Credit<i>{creditTotal ? formatNumber(creditTotal) : 0.0}</i>
                  </p>
                  <p>
                    Difference
                    <i>
                      {creditTotal || debitTotal ? formatNumber(creditTotal - debitTotal) : 0.0}
                    </i>
                  </p>
                </div>
              </span>
              <BootstrapTable
                keyField="key"
                selectRow={selectRow}
                ref={ref}
                // expandRow={excelExpandRow}
                remote={{ cellEdit: true }}
                data={excelData ? excelData : []}
                columns={ExcelColumns}
                noDataIndication="No Data Found"
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  timeToCloseMessage: 30000
                })}
                onTableChange={handleExcelTableChange}
              />
              <div className="excel-list-footer">
                <Button
                  variant="primary"
                  onClick={handleUpload}
                  data-toggle="modal"
                  data-target="#business"
                  disabled={
                    selectedRow.length === 0 ||
                    creditTotal !== debitTotal ||
                    excelData?.find((m) => m.action !== '')
                      ? true
                      : false
                  }
                >
                  Import
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {addNewAccount && (
        <AddNewAccountJournalEntry
          addNewAccount={addNewAccount}
          getAccounts={handleAccountList}
          handleAddNewAccount={handleAddNewAccount}
          businessId={businessId}
          newAccountName={newAccountName}
          newAccountCode={newAccountCode}
          newAccountType={newAccountType}
          newAccountTransactionType={newAccountTransactionType}
          setExcelData={setExcelData}
          excelData={excelData}
          rowID={rowID}
          disableSelectedRow={disableSelectedRow}
          setDisableSelectedRow={setDisableSelectedRow}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
        />
      )}
    </div>
  );
};

export default ExcelJournalEntry;
