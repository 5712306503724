import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, get } from "../../../../config";
import Spin from "../../../common/Spin";
import moment from "moment";
// import { Tooltip } from "react-bootstrap";
import Pagination from "../../../common/Pagination";
import BankTransactionActionButton from "./BankTransactionActionButton";
import CustomTooltip from "../../../common/CustomTooltip";

const BankTransactionByBatch = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [bankTransactionData, setBankTransactionData] = useState([]);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const fetchBankTransactionsByBatch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_BY_BATCHES}/` +
          id +
          `?limit=${limit ? limit : 10}&page=${page ? page : 1}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setBankTransactionData(data?.data?.data);
      setLoading(false);
    } catch (e) {
      const { data } = e;
      setLoading(false);
      setBankTransactionData([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [id, limit, page]);

  const handleDelete = () => {
    fetchBankTransactionsByBatch();
  };

  const bankTransactionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: bankTransactionData[i]?.id,
        date: bankTransactionData[i]?.transactionDate
          ? moment(
              bankTransactionData[i]?.transactionDate,
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY")
          : "-",
        description: bankTransactionData[i]?.description
          ? bankTransactionData[i]?.description
          : "-",
        credit:
          bankTransactionData[i]?.baseType === 1 ? (
            <span className="rag-green">
              $
              {bankTransactionData[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        debit:
          bankTransactionData[i]?.baseType === 0 ? (
            <span className="rag-red">
              $
              {bankTransactionData[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        bank_transaction_source: bankTransactionData[i]?.source
          ? bankTransactionData[i]?.source
          : "-",
        action: bankTransactionData[i]?.deleted_at ? (
          ""
        ) : (
          <BankTransactionActionButton
            id={bankTransactionData[i]?.id}
            handleDelete={handleDelete}
            baseType={bankTransactionData[i]?.baseType}
            bankTransactionData={bankTransactionData}
            setIsImportLoading={setIsImportLoading}
            loading={isImportLoading}
          />
        ),
        archived: bankTransactionData[i]?.deleted_at ? (
          <CustomTooltip
            text={"Archived"}
            children={
              <span color={"red"} key={"red"}>
                <i class="fa fas fa-ban" style={{color: "red"}}></i>
              </span>
            }
          />
        ) : (
          ""
        ),
      });
    }
    return items;
  };

  const bankTransaction = bankTransactionGenerator(bankTransactionData?.length);

  useEffect(() => {
    fetchBankTransactionsByBatch();
  }, [fetchBankTransactionsByBatch]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "date",
      text: "Date",
      // sort: true,
      style: { width: "8%" },
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      // sort: true,
    },
    {
      dataField: "debit",
      text: "Debit",
      // sort: true,
    },
    {
      dataField: "bank_transaction_source	",
      text: "Bank Transaction Source	",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      // sort: true,
      className: "text-center",
      style: { width: "127px", textAlign: "center" },
    },
    {
      dataField: "archived",
      text: "",
      style: { textAlign: "center" },
      // sort: true,
    },
  ];
  return (
    <>
      <div className="content-details">
        <div className="cms-page audit-statement">
          {loading && <Spin />}
          <BootstrapTable
            keyField="batch_id"
            remote
            data={bankTransaction}
            columns={columns}
            // onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default BankTransactionByBatch;
