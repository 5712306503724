import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QuoteDetailsSetting from './QuoteDetailsSetting';


const QuoteLayoutSettingsTab = ({ values, handleChange, handleBlur, settingsData, paymentTypes, pickerTypeList, setFieldValue }) => {
  const [activeTab, setActiveTab] = useState('Detail');

  return (
    <>
      <div className="table-top-btn">
        <ul
          className="nav nav-tabs"
          onClick={(event) => {
            setActiveTab(event?.target?.innerText);
          }}
        >
          <li className={activeTab === 'Detail' ? 'active' : ''}>
            <Link key={1}>Detail</Link>
          </li>
        </ul>
      </div>
      <div className="custom-table tab-table">
        {activeTab === 'Detail' && (
          <QuoteDetailsSetting
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            pickerTypeList={pickerTypeList}
            setFieldValue={setFieldValue}
          />
        )}
      </div>
    </>
  );
};
export default QuoteLayoutSettingsTab;
