import React from "react";
import { Navigate } from "react-router-dom";

const AuthRoute = ({ component: Component, ...rest }) => {
  const tfaCheck = localStorage.getItem("sme-auth-tfa");
  const token = localStorage.getItem("sme-auth-token");
  const role = localStorage.getItem("sme-role");
  const pathname = window.location.pathname;
  if (token) {
    if (tfaCheck === "2" && (pathname !== "/2fa/change" && pathname !== "/2fa/disable")) {
      return (
          (role === "Client" || role === "Client User")
          ? <Navigate to='/businesses' />
          : role === "Admin"
          ? <Navigate to='/admin/dashboard' />
          : <Navigate to='/clients' />
      );
    } else if (tfaCheck === "1" && pathname === "/2fa/register") {
      return <Navigate to="/2fa" />;
    } else if (tfaCheck === "0" && pathname === "/2fa") {
      return <Navigate to="/2fa/register" />;
    } else {
      return (<>
        <Component {...rest} /> 
      </>)
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default AuthRoute;