import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../config";
import Spin from "../../../common/Spin";

const ReconcileItemUndo = ({
  itemId,
  toggleUndoModal,
  handleUndo,
  showUndo,
  businessId,
}) => {
  const [loading, setLoading] = useState(false);
  const handleReconcileItemUndo = useCallback(async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("client_business_id", businessId);

      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/undo/${itemId}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        handleUndo();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [itemId, handleUndo, businessId]);

  return (
    <Modal
      size="lg"
      show={showUndo}
      onHide={toggleUndoModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Unallocate Reconciled Item
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are you sure you want to unallocate this reconciled item?
        </div>
        <div className="modal-footer">
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleUndoModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleReconcileItemUndo();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
    // <div
    //   className="modal fade"
    //   id="undoReconcileItem"
    //   tabindex="-1"
    //   role="dialog"
    //   aria-labelledby="exampleModalCenterTitle"
    //   aria-hidden="true"
    //   onClick={() => {
    //     toggleUndoModal();
    //   }}
    // >
    //   <div className="modal-dialog modal-dialog-centered" role="document">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title" id="exampleModalLongTitle">
    //           Undo Reconciled Item
    //         </h5>
    //         <button
    //           type="button"
    //           className="close"
    //           data-dismiss="modal"
    //           aria-label="Close"
    //         >
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">
    //         Are you sure you want to undo this reconciled item?
    //       </div>
    //       <div className="modal-footer">
    //         <button
    //           type="button"
    //           className="btn btn-secondary"
    //           data-dismiss="modal"
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           type="submit"
    //           className="btn btn-primary"
    //           data-dismiss="modal"
    //           onClick={() => {
    //             handleReconcileItemUndo();
    //           }}
    //         >
    //           OK
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ReconcileItemUndo;
