import React, { useCallback, useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import BootStrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API, get, metaTitle } from "../../config";
import { formatMobile, formatName, formatPhone } from "../common/Misc";
import Pagination from "../common/Pagination";
import Spin from "../common/Spin";
import TitleBar from "../common/TitleBar";
import ExpandRegisteredUser from "./ExpandRegisteredUser";
// import { debounce } from "lodash";

const SubscriptionsForAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  // const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [subscriptionsList, setSubscriptionList] = useState([]);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  // const [searchParam, setSearchParam] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const fetchSubscriptions = useCallback(async () => {
    try {
      setSubscriptionLoading(true);
      const { data } = await get(
        `${API.GET_SUBSCRIPTION_FOR_ADMIN}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setSubscriptionLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const subscriptionsList = data.data.data;
      setSubscriptionList(subscriptionsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setSubscriptionLoading(false);
      setSubscriptionList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, sortOrder, sortField]);

  const subscriptionsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: subscriptionsList[i]?.id,
        id: subscriptionsList[i]?.id,
        name: formatName(
          subscriptionsList[i]?.first_name,
          subscriptionsList[i]?.last_name
        ),
        email: subscriptionsList[i]?.email
          ? subscriptionsList[i]?.email
          : "",
        phone: subscriptionsList[i]?.phone
          ? formatPhone(subscriptionsList[i]?.phone)
          : "",
        mobile: subscriptionsList[i]?.mobile
          ? formatMobile(subscriptionsList[i]?.mobile)
          : "",
        role: subscriptionsList[i]?.role?.name
          ? subscriptionsList[i]?.role?.name
          : "",
      });
    }
    return items;
  };
  const subscriptions = subscriptionsGenerator(subscriptionsList?.length);

  useEffect(() => {
    fetchSubscriptions();
    window.document.title = `${metaTitle} | Subscriptions`
  }, [fetchSubscriptions]);

  useEffect(() => {
    navigate(
      `/admin/subscriptions?limit=${limit ? limit : 10}&page=${
        page ? page : 1
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      //   sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      //   sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      //   sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      //   sort: true,
    },
    {
      dataField: "role",
      text: "Role",
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   className: "text-center",
    //   style: { width: "127px" },
    // },
  ];

  // const onSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchParam(value);
  //   setPage(1);
  // };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandRegisteredUser id={row?.id} />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar
            label={"Subscriptions"}
            businessNameData={false}
            globalSearch={false}
          />
          <div className="content-details">
            {/* <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => debounce(onSearchChange(e), 300)}
                    type="text"
                    value={searchParam}
                    placeholder="Search Subscription"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  variant="primary"
                  //   onClick={() => navigate("/add-client")}
                >
                  Add New
                </Button>
              </div>
            </div> */}
            <div className="custom-table">
              {subscriptionLoading && <Spin />}
              <BootStrapTable
                keyField="id"
                remote
                data={subscriptions}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
                expandRow={expandRow}
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsForAdmin;
