import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";

// import { API, get } from "../../config";
// import { getBusinessDetail } from "../Settings/Business/components/BusinessHelper";
// import Spin from "./Spin";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const UploadFile = ({
  businessId,
  isLoading,
  handleUpload,
  setFiles,
  files,
  handleCancel,
  acceptFileFormat,
  ErrorMessage
}) => {
  // const { Dragger } = Upload;

  // const props = {
  //   name: "file",
  //   multiple: isMultiple,
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   accept: acceptFileFormat
  //     ? acceptFileFormat
  //     : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  //   beforeUpload: () => {
  //     return false;
  //   },
  //   onChange(info) {
  //     handleFileChange(info);
  //   },
  // };

  // const [loading, setLoading] = useState(false);
  // const [clientId, setClientData] = useState();
  // const [businessLoading, setBusinessLoading] = useState();
  // const [businessList, setBusinessList] = useState();

  // const [selectedOptions, setSelectedOptions] = useState([]);

  // const fetchBusiness = useCallback(async () => {
  //   try {
  //     setBusinessLoading(true);
  //     const { data } = await get(
  //       `${API.GET_BUSINESS}?limit=-1&client_id=${clientId ? clientId : ""}`
  //     );
  //     setBusinessLoading(false);
  //     const businessListing = data.data.data;
  //     const finalBusinessList =
  //       businessListing &&
  //       businessListing?.length > 0 &&
  //       businessListing?.map((item, i) => {
  //         const list = {
  //           id: i,
  //           label: item?.business_name,
  //           value: item?.id,
  //         };
  //         return list;
  //       });
  //     setBusinessList([{ label: "All", value: "*" }, finalBusinessList]);
  //     return data.data;
  //   } catch (error) {
  //     setBusinessLoading(false);
  //     setBusinessList([]);
  //     if (error?.response?.data?.errors) {
  //       Object.keys(error?.response?.data?.errors).forEach((key) => {
  //         toast.error(error?.response?.data?.errors[key][0]);
  //       });
  //       setLoading(false);
  //     } else {
  //       toast.error(error?.message);
  //       setLoading(false);
  //     }
  //   }
  // }, [clientId]);

  // useEffect(() => {
  //   setSelectedOptions(businessList);
  // }, [businessList]);

  // const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
  //   if (value && value.some((o) => o.value === "*")) {
  //     return `${placeholderButtonLabel}: All`;
  //   } else {
  //     return `${placeholderButtonLabel}: ${value?.length} selected`;
  //   }
  // };

  // const onChange = (value, event) => {
  //   if (event.action === "select-option" && event.option.value === "*") {
  //     this.setState(this.businessList);
  //   } else if (
  //     event.action === "deselect-option" &&
  //     event.option.value === "*"
  //   ) {
  //     this.setState([]);
  //   } else if (event.action === "deselect-option") {
  //     this.setState(value.filter((o) => o.value !== "*"));
  //   } else if (value.length === this.businessList.length - 1) {
  //     this.setState(this.businessList);
  //   } else {
  //     this.setState(value);
  //   }
  // };

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       setLoading(true);
  //       const data = await getBusinessDetail(businessId);
  //       setClientData(data?.client_id);
  //       setLoading(false);
  //     } catch (e) {
  //       setClientData(null);
  //     }
  //   }
  //   fetchData();
  // }, [businessId]);

  // useEffect(() => {
  //   if (clientId) {
  //     fetchBusiness();
  //   }
  // }, [fetchBusiness, clientId]);

  return (
    <div className="upload-div">
      <div className="modal-body">
        {/* {(loading || businessLoading) && <Spin />} */}
        {/* <ReactMultiSelectCheckboxes
          options={businessList}
          placeholderButtonLabel="Account"
          getDropdownButtonLabel={getDropdownButtonLabel}
          value={selectedOptions}
          onChange={onChange}
          setState={setSelectedOptions}
        /> */}
        <Form>
          <div className="upload-file">
            <FormControl
              accept={acceptFileFormat}
              type="file"
              title=""
              name="chart_of_account"
              className="form-control"
              id="chart_of_account"
              onChange={(e) => {
                // e.currentTarget.files[0]?.type === "application/pdf" ||
                // e.currentTarget.files[0]?.type === "image/jpeg" ||
                // e.currentTarget.files[0]?.type === "text/jpg" ||
                // e.currentTarget.files[0]?.type === "image/png" ||
                // e.currentTarget.files[0]?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                // e.currentTarget.files[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||  
                // e.currentTarget.files[0]?.type === "application/vnd.ms-excel"
                acceptFileFormat.split(", ").includes(e.currentTarget.files[0]?.type)
                  ? setFiles(e.currentTarget.files[0])
                  : toast.error(`Please upload ${ErrorMessage} only.`) &&
                    setFiles();
              }}
            />
            <span>
              <p className="ant-upload-drag-icon">
                <FontAwesomeIcon title="" icon={faUpload} size="3x" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </span>
          </div>
          <div>
            <p>{files ? files?.name : ""}</p>
          </div>
        </Form>
        {/* <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <FontAwesomeIcon icon={faUpload} size="3x" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger> */}
      </div>
      <div className="modal-footer">
        <Button
          variant="primary"
          disabled={files ? false : true}
          onClick={() => handleUpload()}
        >
          {isLoading ? "Loading..." : "Upload"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => handleCancel()}
          //   className="cancel-btn"
        >
          Cancel
        </Button>
        {/* </div> */}
      </div>
    </div>
  );
};

export default UploadFile;
