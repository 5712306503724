import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EditUser from "../EditUser";
import UserDelete from "./UserDelete";
// import UserDetailPopup from "./UserDetailPopup";
// import { Link } from "react-router-dom";

const UserActionButton = ({ id, userModal, clientId }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showUser, setShowUser] = useState(false);

  // const toggleShowModal = () => {
  //   setShowView(!showView);
  // };

  const toggleUserModal = () => {
    showUser && userModal();

    setShowUser(!showUser);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  return (
    <>
      <span>
        {/* <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#clientDetail"
        /> */}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp; */}
        {/* <Link to={{ pathname: "/edit-client?id=" + id }}> */}
        <FontAwesomeIcon icon={faEdit} size="1x" onClick={toggleUserModal} />
        {/* </Link> */}
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteClient"
        />
      </span>

      {showDelete && (
        <UserDelete
          userId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={userModal}
          showDelete={showDelete}
          clientId={clientId}
        />
      )}

      {showUser && (
        <EditUser
          userId={id}
          showUser={showUser}
          toggleUserModal={toggleUserModal}
          clientId={clientId}
        />
      )}

      {/* {showDelete && (
        <ClientDelete
          clientId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )} */}

      {/* {showView && (
        <UserDetailPopup
          showView={showView}
          userId={id}
          toggleShowModal={toggleShowModal}
        />
      )} */}
    </>
  );
};

export default UserActionButton;
