import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import Spin from '../../../../common/Spin';
import { API, get } from '../../../../../config';
import { toast } from 'react-toastify';
import { formatName, formatNumber } from '../../../../common/Misc';

const CashReceiptDetailPopup = ({ cash_receiptId, toggleShowModal, showView }) => {
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [receiptData, setReceiptData] = useState();

  const fetchReceipts = useCallback(async () => {
    try {
      setReceiptLoading(true);
      const { data } = await get(`${API.GET_CASH_RECEIPT}/${cash_receiptId}`);

      setReceiptLoading(false);
      const receiptData = data.data;
      setReceiptData(receiptData);
      return data.data;
    } catch (e) {
      const { data } = e;
      setReceiptLoading(false);
      setReceiptData([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [cash_receiptId]);

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Cash Receipt Details
      </Modal.Header>
      <Modal.Body>
        {receiptLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content">
              <div className="modal-content-details">
                <p>
                  <strong>Transaction Date:</strong>{' '}
                  {receiptData?.transactionDate ? receiptData?.transactionDate : '-'}
                </p>
                <p>
                  <strong>Amount:</strong>{' '}
                  <span className='rag-green' style={{marginLeft: "0px"}}>{receiptData?.amount ? `$${formatNumber(receiptData?.amount)}` : '-'}</span>
                </p>

                <p>
                  <strong>Account:</strong>{' '}
                  {receiptData?.chart_of_account ? `${receiptData?.chart_of_account?.code?.length < 4 ? padWithLeadingZeros(Number(receiptData?.chart_of_account?.code),4) : receiptData?.chart_of_account?.code}${receiptData?.chart_of_account?.sub_code ? `.${receiptData?.chart_of_account?.sub_code}` : ''} - ` + receiptData?.chart_of_account?.name : '-'}
                </p>
                <p>
                  <strong>GST Type:</strong>{' '}
                  {receiptData?.gst === 0 ? "GST Excluded" : receiptData?.gst === 1 ? "GST 10%" : receiptData?.gst === 2 ? "BAS Excluded" : "GST on Imports"}
                </p>
                <p>
                  <strong>Contact Name:</strong>{' '}
                  {receiptData?.contact ? formatName(receiptData?.contact?.first_name, receiptData?.contact?.last_name) : '-'}
                </p>
                <p>
                  <strong>Description:</strong>{' '}
                  {receiptData?.description ? receiptData?.description : '-'}
                </p>
                <p>
                  <strong>Job:</strong>{' '}
                  {receiptData?.business_job ? receiptData?.business_job?.code : '-'}
                </p>
                <p>
                  <strong>Task:</strong>{' '}
                  {receiptData?.business_task ? receiptData?.business_task?.code : '-'}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CashReceiptDetailPopup;
