import React, { useRef, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';
// import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import Spin from '../../../common/Spin';
import { API, fileUpload, 
  // get 
} from '../../../../config';
import { AddParagrahSchema } from '../../../common/Validation';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import { useCallback } from 'react';
// import { useEffect } from 'react';

const AddParagrah = ({ businessId, showAddParagrah, toggleAddParagrahModal, handleAdd }) => {
  const formValues = useRef();
  const [editorState, setEditorState] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [jobLoading, setJobLoading] = useState(false);
  // const [jobOptions, setJobOptions] = useState([]);
  const handleEditorChange = (state, setFieldValue) => {
    if(convertToRaw(state.getCurrentContent()).blocks.length === 1 && convertToRaw(state.getCurrentContent()).blocks[0].text === ''){
      setFieldValue('description', '')
    } else {
      setFieldValue('description', state ? state : '')
    }
    setEditorState(state);
  };

  const handleFormSubmit = async (value) => {
    let description = ''; 
    if (convertToRaw(editorState.getCurrentContent()).blocks.length === 1 && convertToRaw(editorState.getCurrentContent()).blocks[0].text === '') {
      description = ''
    } else {
      description = editorState ? draftToHtml(convertToRaw(editorState?.getCurrentContent())) : '';
    }
    const formData = new FormData();
    // value.business_job_id = value?.business_job_id
    //   ? value?.business_job_id?.value
    //   : "";
    // value.descriprion = description ? description : '';
    formData.append('client_business_id', businessId ? businessId : '');
    // formData.append('business_job_id', value?.business_job_id ? value?.business_job_id : '');
    formData.append('description', description ? description : '');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_STANDARD_PARAGRAH}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleAdd();
        toggleAddParagrahModal();
      }
      return data?.data;
    } catch (error) {
      setLoading(false);
      toggleAddParagrahModal();
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // const handleJobChange = useCallback(async () => {
  //   try {
  //     setJobLoading(true);
  //       get(
  //         `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
  //       ).then((response) => {
  //         const jobList =
  //           response &&
  //           response?.data?.data?.data?.length > 0 &&
  //           response?.data?.data?.data?.map((item) => {
  //             const list = {
  //               label:
  //                 item?.code +
  //                 (item?.description ? " (" + item?.description + ")" : ""),
  //               value: item?.id,
  //               tax: item?.tax,
  //             };
  //             return list;
  //           });
  //       setJobOptions(jobList ? jobList : []);
  //       setJobLoading(false);
  //     });
  //   } catch (e) {
  //     setJobLoading(false);
  //     toast.error(e?.data?.message);
  //   }
  // }, [businessId]);

  // useEffect(() => {
  //   handleJobChange();
  // }, [handleJobChange]);

  return (
    <>
      <Modal
        size="lg"
        show={showAddParagrah}
        onHide={toggleAddParagrahModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Standard Paragraph
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              business_job_id: '',
              description: ''
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddParagrahSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                    <div className="form-box">
                      {/* <div className="form-group full-width">
                        <label>
                          Job
                        </label>
                        <Select
                          name="business_job_id"
                          value={values?.business_job_id ? values?.business_job_id : ''}
                          onChange={(e) => {
                            setFieldValue(`business_job_id`, e ? e : '');
                          }}
                          onBlur={handleBlur}
                          options={jobOptions ? jobOptions : []}
                          isClearable={true}
                          placeholder="Select Job"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                          isLoading={jobLoading}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="business_job_id" />
                        </span>
                      </div> */}
                      <div className="form-group full-width paragrah-editor">
                        <label>
                          <span className="required">*</span>Description
                        </label>
                        <Editor
                          editorState={editorState}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: ['bold', 'italic', 'underline']
                            }
                          }}
                          onEditorStateChange={(state) => handleEditorChange(state,setFieldValue)}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          stripPastedStyles={true}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          toggleAddParagrahModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddParagrah;
