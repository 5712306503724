import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API, get, imageDefaultPrefixPath, metaTitle } from '../../../../config';
import BootStrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spin from '../../../common/Spin';
import Pagination from '../../../common/Pagination';
// import BackButton from '../../../../components/Form/BackButton';
import { checkIfFileExists, formatName, formatNumber } from '../../../common/Misc';
import { getBusinessData } from '../../components/BusinessHelper';
import QuoteActionButton from './components/QuoteActionButton';
import TitleBar from '../../../common/TitleBar';
// import InvoiceActionButton from "./components/InvoiceActionButton";

const Quotes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [quotesList, setQuotesList] = useState([]);
  const [quotesLoading, setQuotesLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [businessData, setBusinessData] = useState();
  const [loading, setLoading] = useState(false);
  const [lastQuoteNumber, setLastQuoteNumber] = useState();
  const [numberOption, setNumberOption] = useState();

  
  const fetchBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
  }, [businessId]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    window.document.title = `${metaTitle} | Quotes`
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

    const handleDelete = () => {
      fetchQuotes();
    };

    function padWithLeadingZeros(num, totalLength) {
      return String(num).padStart(totalLength, '0');
    }

  const fetchQuotes = useCallback(async () => {
    try {
      setQuotesLoading(true);
      const { data } = await get(
        `${API.GET_QUOTES}?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${sortField ? sortField : ''}&sort_order=${
          sortOrder ? sortOrder : ''
        }&client_business_id=${businessId ? businessId : ''}`
      );

      setQuotesLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const quotesLists = data.data.data;
      setQuotesList(quotesLists);
      let lastNumber = data?.last_quote_number ? String(data?.last_quote_number)?.split("Q")[1] : ''
      setLastQuoteNumber(lastNumber ? `Q${String(padWithLeadingZeros(Number(lastNumber) + 1, 6))}` : 'Q000001');
      setNumberOption(data?.number_option);
      return data.data;
    } catch (e) {
      const { data } = e;
      setQuotesLoading(false);
      setQuotesList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, sortOrder, sortField, businessId]);

    const quotesGenerator = (quantity) => {
      const items = [];
      for (let i = 0; i < quantity; i++) {
        items.push({
          key: quotesList[i]?.id,
          quote_number: quotesList[i]?.quote_number ? quotesList[i]?.quote_number : '',
          quote_to: formatName(
            quotesList[i]?.contact?.first_name,
            quotesList[i]?.contact?.last_name
          ),
          email_to: quotesList[i]?.email_to ? quotesList[i]?.email_to : '',
          prepared_by: quotesList[i]?.prepared_by ? quotesList[i]?.prepared_by : '',
          date: quotesList[i]?.date ? quotesList[i]?.date : '',
          due_date: quotesList[i]?.due_date ? quotesList[i]?.due_date : '',
          total_payable_amount: quotesList[i]?.total_payable_amount ? `$${formatNumber(quotesList[i]?.total_payable_amount)}` : '$0.00',
          action: (
            <QuoteActionButton
              id={quotesList[i]?.id}
              handleDelete={handleDelete}
              status={quotesList[i]?.status}
              send={quotesList[i]?.email_sent}
              email={quotesList[i]?.email_to}
              businessData={businessData}
              numberOption={numberOption}
              file={quotesList[i]?.quote_file[0]}
            />
          ),
        });
      }
      return items;
    };
    const quotes = quotesGenerator(quotesList?.length);

  const columns = [
    {
      dataField: 'date',
      text: 'Date'
    },
    {
      dataField: 'due_date',
      text: 'Due Date'
    },
    {
      dataField: 'quote_number',
      text: 'Quote Number'
    },
    {
      dataField: 'quote_to',
      text: 'Quote To'
    },
    {
      dataField: 'email_to',
      text: 'Email To'
    },
    {
      dataField: 'total_payable_amount',
      text: 'Quote Total'
    },
    // {
    //   dataField: 'prepared_by',
    //   text: 'Prepared By'
    // },
    {
      dataField: 'action',
      text: 'Action',
      style: { width: "127px" },
    }
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${sortField ? sortField : ''}&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  useEffect(() => {
    fetchQuotes();
  }, [fetchQuotes]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          {/* <h1 className="page-title">
            <BackButton />
            Quotes
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1> */}
          <TitleBar
            label={"Quotes"}
            BusinessName={BusinessName}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() => navigate('/business/' + businessId + '/add-quote', {
                    state: { lastQuoteNumber: lastQuoteNumber, numberOption: numberOption }
                  })}
                >
                  Add New
                </Button>
                {/* <Button variant="primary">Import</Button> */}
              </div>
            </div>
            <div className="custom-table">
              {(quotesLoading || loading) && <Spin />}
              <BootStrapTable
                keyField="key"
                remote
                data={quotes}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
                // expandRow={expandRow}
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotes;
