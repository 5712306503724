import React, { useEffect, useRef, useState } from "react";
import { API, fileUpload, get } from "../../../config";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Form from "./components/Form";
import { Formik } from "formik";
import { AddTaskSchema } from "../../common/Validation";
import Spin from "../../common/Spin";

const EditTask = ({
  taskId,
  toggleShowModal,
  businessId,
  fetchTasks,
  showView,
}) => {
  const [loading, setLoading] = useState(false);
  const [taskData, setTaskData] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleSubmit = async (value) => {
    const formData = new FormData();

    formData.append("client_business_id", businessId);
    formData.append("code", value?.code);
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );
    formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.TASKS_ADD}/${taskId}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleShowModal();
        fetchTasks();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await get(`${API.TASKS_DETAILS}/` + taskId);
        setTaskData(data.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setTaskData(null);
      }
    }
    fetchData();
  }, [taskId]);

  return (
    <>
      <Modal
        size="lg"
        show={showView}
        onHide={toggleShowModal}
        dialogClassName="modal-50w small-popup task-modal review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Edit Task/Milestone
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              code: taskData?.code ? taskData.code : "",
              description: taskData?.description ? taskData.description : "",
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddTaskSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleShowModal={toggleShowModal}
                    fetchTasks={fetchTasks}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTask;
