import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API, fileUpload, get } from '../../../config';
import { formatName } from '../../common/Misc';
import Pagination from '../../common/Pagination';
import Spin from '../../common/Spin';
import { DateRangePicker } from 'rsuite';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const ArchivedTransactions = ({ setBalanceInfo, valueRange, setValueRange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [archivedTransactionData, setArchivedTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [deletedItem, setDeletedItem] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;
  const { afterToday } = DateRangePicker;

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const fetchArchivedTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_LIST}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&deleted=1&client_business_id=${businessId ? businessId : ''}&from=${
          valueRange ? moment(valueRange[0]).format('DD-MM-YYYY') : ''
        }&to=${valueRange ? moment(valueRange[1]).format('DD-MM-YYYY') : ''}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      let accountActiveList = [];
      if (data?.accounts) {
        accountActiveList = data?.accounts?.filter((i) => i?.accountStatus === 1);
      }
      setBalanceInfo(accountActiveList);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setArchivedTransactionData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    navigate(
      `/business/${businessId}/reconcile-transactions?tab=audit-trail&childTab=archived-transactions&limit=${
        limit ? limit : 10
      }&page=${page ? page : 1}`
    );
  }, [businessId, limit, page, navigate, setBalanceInfo, valueRange]);

  const archivedTransactionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: archivedTransactionData[i]?.id,
        date: archivedTransactionData[i]?.date
          ? moment(archivedTransactionData[i]?.date, 'DD-MM-YYYY').format('DD-MM-YYYY')
          : '-',
        description: archivedTransactionData[i]?.description
          ? archivedTransactionData[i]?.description
          : '-',
        credit:
          archivedTransactionData[i]?.baseType === 1 ? (
            <span className="rag-green">
              $
              {archivedTransactionData[i]?.amount?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          ) : (
            '-'
          ),
        debit:
          archivedTransactionData[i]?.baseType === 0 ? (
            <span className="rag-red">
              $
              {archivedTransactionData[i]?.amount?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          ) : (
            '-'
          ),
        bank_transaction_source: archivedTransactionData[i]?.source
          ? archivedTransactionData[i]?.source
          : '-',
        archived_by: archivedTransactionData[i]?.user
          ? formatName(
              archivedTransactionData[i]?.user?.first_name,
              archivedTransactionData[i]?.user?.last_name
            )
          : '-',
        action: (
          <div style={{ textAlign: 'center' }}>
            <span>
              <FontAwesomeIcon
                onClick={() => toggleDeleteModal(archivedTransactionData[i]?.id)}
                icon={faTrash}
                size="1x"
              />
            </span>
          </div>
        )
      });
    }
    return items;
  };

  const archivedTransactions = archivedTransactionGenerator(archivedTransactionData?.length);

  useEffect(() => {
    fetchArchivedTransactions();
  }, [fetchArchivedTransactions]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      style: { width: '8%' }
      // sort: true,
    },
    {
      dataField: 'description',
      text: 'Description'
      // sort: true,
    },
    {
      dataField: 'credit',
      text: 'Credit'
      // sort: true,
    },
    {
      dataField: 'debit',
      text: 'Debit'
      // sort: true,
    },
    {
      dataField: 'archived_by',
      text: 'Archived By'
      // sort: true,
    },
    {
      dataField: 'bank_transaction_source',
      text: 'Bank Transaction Source'
    },
    {
      dataField: 'action',
      text: 'Action'
    }
  ];

  const handleDateRange = (e) => {
    setValueRange(e);
    setPage(1);
  };

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  }

  const dateFilterRange = [
    {
      label: 'This Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    }
  ];

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    onSelectAll: (row, isSelect) => {
      if (row) {
        const ids =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect?.map((item) => {
            return item?.key;
          });
        setSelectedRowIds(selectedRowIds ? selectedRowIds.concat(ids) : ids);
      } else {
        const removableData =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect.map(function (data) {
            return data?.key;
          });
        setSelectedRowIds(
          selectedRowIds ? selectedRowIds.filter((item) => !removableData.includes(item)) : []
        );
      }
    },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowIds((keys) => [...keys, row?.key]);
      } else {
        setSelectedRowIds(arrayRemove(selectedRowIds, row?.key));
      }
    }
  };

  const toggleDeleteModal = (id) => {
    setShowDelete(!showDelete);
    setDeletedItem(id);
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  };

  const handleDeleteTransaction = async () => {
    if (deletedItem) {
      const formData = new FormData();
      formData.append(`ids[0]`, deletedItem);
      formData.append('client_business_id', businessId);
      await fileUpload(`${API.PERMANETALY_DELETE_BANK_STATEMENT}`, formData)
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.errors?.myna_error) {
            toast.error(response?.data?.errors?.myna_error[0]);
          }
          if (response?.status === 200) {
            fetchArchivedTransactions();
            setTimeout(() => {
              setDeletedItem('');
              setShowDelete(!showDelete);
            }, 700);
          }
        })
        .catch((e) => {
          const { data } = e.response;
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          toast.error(e?.data?.message);
        });
    }
  };

  const handleDeleteAll = async () => {
    const formData = new FormData();
    if (selectedRowIds?.length > 0) {
      for (let i = 0; i < selectedRowIds?.length; i++) {
        formData.append(`ids[${i}]`, selectedRowIds[i]);
      }
    }
    formData.append('client_business_id', businessId);
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.PERMANETALY_DELETE_BANK_STATEMENT}`, formData);
      setLoading(false);
      if (data) {
        setSelectedRowIds([]);
        toast.success(data?.message);
        toggleDeleteAllModal();
        fetchArchivedTransactions();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="table-top-btn archived-transactions-date-range">
        <Form>
          <DateRangePicker
            placeholder="Select Date Range"
            onChange={(e) => handleDateRange(e)}
            format="dd-MM-yyyy"
            disabledDate={afterToday()}
            placement="bottomEnd"
            value={valueRange}
            ranges={dateFilterRange}
            locale={{ ok: 'Apply' }}
          />
        </Form>
        <Button
          variant="primary"
          onClick={toggleDeleteAllModal}
          style={{
            padding: '10px 15px',
            marginLeft: '15px'
          }}
        >
          {selectedRowIds?.length > 0 ? 'Delete Selected' : 'Delete All'}
        </Button>
      </div>
      <div className="cms-page audit-statement">
        {loading && <Spin />}
        <BootstrapTable
          keyField="key"
          remote
          data={archivedTransactions}
          columns={columns}
          selectRow={selectRow}
          // onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={Number(page)}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>
      {showDeleteAll && (
        <Modal
          size="lg"
          show={showDeleteAll}
          onHide={toggleDeleteAllModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header>Delete all Bank Transactions</Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              <p>
                Are you sure you want to delete {selectedRowIds?.length === 0 ? 'all' : 'selected'}{' '}
                bank transactions permanently?
              </p>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteAllModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleDeleteAll();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {
        <Modal
          show={showDelete && deletedItem}
          onHide={() => toggleDeleteModal('')}
          size="lg"
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header>Bank Transaction</Modal.Header>
          <div className="modal-body" style={{ textAlign: 'center' }}>
            <p>Are your sure you want to delete this transaction permanently?</p>
          </div>
          <div className="modal-footer">
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => toggleDeleteModal('')}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={handleDeleteTransaction}
            >
              OK
            </Button>
          </div>
        </Modal>
      }
    </>
  );
};

export default ArchivedTransactions;
