// import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
// import { toast } from "react-toastify";
import AcceptInvitation from "./AcceptInvitation";
import RejectInvitation from "./RejectInvitation";

const InvitationsActionButton = ({ id, handleInvitations, status }) => {
  const [showAcceptInvitation, setShowAcceptInvitation] = useState(false);
  const [showRejectInvitation, setShowRejectInvitation] = useState(false);
  const invitationStatus = parseInt(status);

  const toggleShowAcceptInvitationModal = () => {
    setShowAcceptInvitation(!showAcceptInvitation);
  };

  const toggleShowRejectInvitationModal = () => {
    setShowRejectInvitation(!showRejectInvitation);
  };

  // const handleAccepted = () => {
  //   toast.warning("This invitation is already accepted.");
  // };

  // const handleRejected = () => {
  //   toast.warning("This invitation is already rejected.");
  // };

  return (
    <>
      <span>
        {invitationStatus === 1 ? (
          <Button disabled>Accept</Button>
        ) : (
          <Button onClick={toggleShowAcceptInvitationModal}>Accept</Button>
        )}{" "}
        {invitationStatus === 2 ? (
          <Button disabled>Reject</Button>
        ) : (
          <Button onClick={toggleShowRejectInvitationModal}>Reject</Button>
        )}
        {/* {invitationStatus === 1 ? (
          <FontAwesomeIcon
            icon={faThumbsUp}
            onClick={handleAccepted}
            size="1x"
          />
        ) : (
          <FontAwesomeIcon
            onClick={toggleShowAcceptInvitationModal}
            icon={faThumbsUp}
            size="1x"
          />
        )} */}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp; */}
        {/* {invitationStatus === 2 ? (
          <FontAwesomeIcon
            icon={faThumbsDown}
            onClick={handleRejected}
            size="1x"
          />
        ) : (
          <FontAwesomeIcon
            onClick={toggleShowRejectInvitationModal}
            icon={faThumbsDown}
            size="1x"
          />
        )} */}
        {showAcceptInvitation && (
          <AcceptInvitation
            id={id}
            handleInvitations={handleInvitations}
            showAcceptInvitation={showAcceptInvitation}
            toggleShowAcceptInvitationModal={toggleShowAcceptInvitationModal}
          />
        )}
        {showRejectInvitation && (
          <RejectInvitation
            id={id}
            handleInvitations={handleInvitations}
            showRejectInvitation={showRejectInvitation}
            toggleShowRejectInvitationModal={toggleShowRejectInvitationModal}
          />
        )}
      </span>
    </>
  );
};

export default InvitationsActionButton;
