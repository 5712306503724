import React, { useEffect, useState } from "react";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatName,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getSupplierPopupDetail } from "./SupplierHelper";
import { Modal } from "react-bootstrap";

const SupplierDetailPopup = ({ supplierId, toggleShowModal, showView }) => {
  const [supplierDetail, setSupplierDetail] = useState(null);
  const [supplierLoading, setSupplierLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const supplierDetailData = await getSupplierPopupDetail(supplierId, true);
      setSupplierDetail(supplierDetailData);
      setSupplierLoading(false);
    }
    fetchData();
  }, [supplierId, setSupplierDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Supplier Details
      </Modal.Header>
      <Modal.Body>
        {supplierLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {supplierDetail?.profile_pic === null ||
                supplierDetail?.profile_pic === "" ||
                supplierDetail?.profile_pic === undefined ? (
                  <span>{supplierDetail?.first_name ? supplierDetail?.first_name?.charAt(0) : supplierDetail?.last_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${supplierDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {formatName(
                    supplierDetail?.first_name,
                    supplierDetail?.last_name
                  )}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Name:</strong>{" "}
                  {supplierDetail?.first_name
                    ? supplierDetail?.first_name
                    : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong>{" "}
                  {supplierDetail?.last_name ? supplierDetail?.last_name : "-"}
                </p>
                <p>
                  <strong>Display Name:</strong>{" "}
                  {supplierDetail?.display_name ? supplierDetail?.display_name : "-"}
                </p>
                <p>
                  <strong>Email Address:</strong>{" "}
                  {supplierDetail?.email ? supplierDetail?.email : "-"}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {supplierDetail?.phone
                    ? formatPhone(supplierDetail?.phone)
                    : "-"}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {supplierDetail?.mobile
                    ? formatMobile(supplierDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {supplierDetail?.business_name
                    ? supplierDetail?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {supplierDetail?.abn ? formatABN(supplierDetail?.abn) : "-"}
                </p>
                <p>
                  <strong>Entity Type:</strong>{" "}
                  {supplierDetail?.entity_type
                    ? supplierDetail?.entity_type
                    : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    supplierDetail?.street,
                    supplierDetail?.suburb?.name,
                    supplierDetail?.state?.name,
                    supplierDetail?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    supplierDetail?.shipping_street,
                    supplierDetail?.shipping_suburb?.name,
                    supplierDetail?.shipping_state?.name,
                    supplierDetail?.shipping_postal_code
                  )}
                </p>
                <p>
                  <strong>Ref. Number:</strong>{" "}
                  {supplierDetail?.ref_number
                    ? supplierDetail?.ref_number
                    : "-"}
                </p>
                <p>
                  <strong>BPay Ref. Number:</strong>{" "}
                  {supplierDetail?.bpay_ref_number
                    ? supplierDetail?.bpay_ref_number
                    : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SupplierDetailPopup;
