import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loginimg from "../../assets/images/Loginimg";
import Sitelogo from "../../components/Header/Logo";
import { Formik, ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useStateList, useSuburbList } from "./FirmHelper";
import {
  FirmSignupAccountingFirmSchema,
  FirmSignupSchema,
} from "../common/Validation";
import { API, fileUpload, IV, SECRETKEY } from "../../config";
import { toast } from "react-toastify";
import Spin from "../common/Spin";
import { formatName } from "../common/Misc";

const FirmSignup = () => {
  const navigate = useNavigate();
  // const tfa = localStorage.getItem("sme-auth-tfa");
  const userId = localStorage.getItem("sme-userId");
  const EmailVarification = localStorage.getItem("sme-EmailVarification");
  const hasToken = localStorage.getItem("sme-auth-token");
  const clientId = localStorage.getItem("sme-clientId");
  const [authentication] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultEmail = localStorage.getItem("sme-invite-accountingFirmEmail");

  const blankData = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
    business_name: "",
    business_email: defaultEmail ? defaultEmail : "",
    street: "",
    state_id: "",
    suburb_id: "",
    postal_code: "",
    abn: ""
  };

  const [defaultData, setDefaultData] = useState(blankData);

  const [activeTab, setActiveTab] = useState("Accounting Firm");

  const [eye1, seteye1] = useState(true);
  const [password1, setpassword1] = useState("password");
  const [eye2, seteye2] = useState(true);
  const [password2, setpassword2] = useState("password");

  const Eye1 = () => {
    if (password1 === "password") {
      setpassword1("text");
      seteye1(false);
    } else {
      setpassword1("password");
      seteye1(true);
    }
  };

  const Eye2 = () => {
    if (password2 === "password") {
      setpassword2("text");
      seteye2(false);
    } else {
      setpassword2("password");
      seteye2(true);
    }
  };

  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === defaultData?.state_id
  );

  const [suburb, setSuburb] = useState([]);
  const [suburbValue, setSuburbValue] = useState({});
  const [stateId] = useState();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
  } = useSuburbList(stateId);
  useEffect(() => {
    const suburbListing1 = suburbList?.map((item) => {
      const { name: label, id: value, ...rest } = item;
      return { label, value, ...rest };
    });
    setSuburb(suburbListing1);
    const suburbVal = suburbListing1?.find(
      (item) =>
        item?.value === (defaultData?.suburb_id ? defaultData?.suburb_id : "")
    );
    setSuburbValue(suburbVal);
  }, [suburbList, defaultData]);

  var CryptoJS = require("crypto-js");

  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  const handleFormSubmit = async (value, { resetForm }) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.state_id = value?.state_id ? value?.state_id?.value : "";
    value.suburb_id = value?.suburb_id ? value?.suburb_id?.value : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, '') : '';

    const encPassword = CryptoJS.AES.encrypt(value?.password, fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const finalEncryptedPassword = encPassword.ciphertext.toString(
      CryptoJS.enc.Base64
    );

    const encConfirmPassword = CryptoJS.AES.encrypt(
      value?.password_confirmation,
      fkey,
      {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const finalEncryptedConfirmPassword =
      encConfirmPassword.ciphertext.toString(CryptoJS.enc.Base64);

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "password",
      finalEncryptedPassword ? finalEncryptedPassword : ""
    );
    formData.append(
      "password_confirmation",
      finalEncryptedConfirmPassword ? finalEncryptedConfirmPassword : ""
    );

    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "business_email",
      value?.business_email ? value?.business_email : ""
    );
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append("abn", value?.abn ? value?.abn : "");

    formData.append(
      "user_type",
      activeTab === "Accounting Firm"
        ? "accounting_firm"
        : activeTab === "SME Client"
        ? "client"
        : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(API.FIRM_SIGNUP, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      if (data?.status === 200 && data?.message) {
        setDefaultData(blankData);
        toast.success(data?.message);
        resetForm();
        localStorage.setItem(
          "sme-FullName",
          formatName(data?.data?.first_name, data?.data?.last_name)
        );
        localStorage.setItem(
          "sme-auth-email",
          data?.data?.email ? data?.data?.email : ""
        );

        localStorage.setItem("sme-userId", data?.data?.id ? data?.data?.id : "");

        // navigate(`/subscription`);
      }
    } catch (error) {
      setDefaultData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    EmailVarification &&
      userId &&
      hasToken &&
      (clientId ? navigate("/businesses") : navigate("/clients"));
  }, [EmailVarification, userId, clientId, hasToken, navigate]);

  return (
    <div className="login-pages firm-login">
      {!authentication ? (
        <>
          {loading && <Spin />}
          <div className="img-block">
            <div className="login-img">
              <Loginimg />
            </div>
          </div>
          <div className="login-form-block" style={{ flex: "1 0" }}>
            <div className="login-form firm-form">
              <Sitelogo />

              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li
                    className={activeTab === "Accounting Firm" ? "active" : ""}
                  >
                    <Link key={1}>
                      Accounting Firm
                    </Link>
                  </li>
                  <li className={activeTab === "SME Client" ? "active" : ""}>
                    <Link key={2}>
                      SME Client
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="content-details">
                <section>
                  {activeTab === "SME Client" && (
                    <Formik
                      initialValues={{
                        first_name: defaultData?.first_name,
                        last_name: defaultData?.last_name,
                        phone: defaultData?.phone,
                        email: defaultData?.email,
                        password: defaultData?.password,
                        password_confirmation:
                          defaultData?.password_confirmation,
                        business_name: defaultData?.business_name,
                        business_email: defaultData?.business_email,
                        street: defaultData?.street,
                        state_id: state,
                        suburb_id: suburbValue,
                        postal_code: defaultData?.postal_code,
                        abn: defaultData?.abn
                      }}
                      enableReinitialize
                      validationSchema={FirmSignupSchema}
                      validateOnBlur={true}
                      validateOnChange={true}
                      onSubmit={(value, { resetForm }) => {
                        handleFormSubmit(value, { resetForm });
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          noValidate
                          className="entry-section"
                        >
                          <small>
                            You are registering as a small business(SME).
                          </small>
                          <div className="entry-card">
                            <h3>Personal Details</h3>
                            <div className="form-box">
                              {" "}
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>First Name
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  value={
                                    values?.first_name ? values?.first_name : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="first_name" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Last Name
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  value={
                                    values?.last_name ? values?.last_name : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="last_name" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Phone No.
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone"
                                  value={values?.phone ? values?.phone : ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  mask="+61 9 9999 9999"
                                  maskChar={null}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="phone" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Email (For
                                  Login)
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  value={values?.email ? values?.email : ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="email" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Password
                                </label>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type={password1}
                                    name="password"
                                    value={
                                      values?.password ? values?.password : ""
                                    }
                                    autoComplete="off"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <i
                                    onClick={Eye1}
                                    style={{
                                      position: "absolute",
                                      right: "20px",
                                      cursor: "pointer",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      marginTop: "-2px",
                                    }}
                                    className={`icon ${
                                      eye1 ? "icon-eye-close" : "icon-eye-open"
                                    }`}
                                  ></i>
                                </div>
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="password" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Re-type
                                  Password
                                </label>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type={password2}
                                    name="password_confirmation"
                                    value={
                                      values?.password_confirmation
                                        ? values?.password_confirmation
                                        : ""
                                    }
                                    autoComplete="off"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <i
                                    onClick={Eye2}
                                    style={{
                                      position: "absolute",
                                      right: "20px",
                                      cursor: "pointer",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      marginTop: "-2px",
                                    }}
                                    className={`icon ${
                                      eye2 ? "icon-eye-close" : "icon-eye-open"
                                    }`}
                                  ></i>
                                </div>
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="password_confirmation" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="entry-card">
                            <h3>Business Details</h3>
                            <div className="form-box">
                              {" "}
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Business
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="business_name"
                                  value={
                                    values?.business_name
                                      ? values?.business_name
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="business_name" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Business
                                  Email
                                </label>
                                <input
                                  type="text"
                                  name="business_email"
                                  value={
                                    values?.business_email
                                      ? values?.business_email
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="business_email" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Street
                                </label>
                                <input
                                  type="text"
                                  name="street"
                                  value={values?.street ? values?.street : ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="street" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Select
                                  State
                                </label>
                                <Select
                                  type="text"
                                  name="state_id"
                                  value={
                                    values?.state_id ? values?.state_id : ""
                                  }
                                  // value={
                                  //   typeof values?.state_id === "object"
                                  //     ? values?.state_id
                                  //     : typeof values?.state_id === "string"
                                  //     ? stateListing?.find(
                                  //         (item) =>
                                  //           item?.value === values?.state_id
                                  //       )
                                  //     : ""
                                  // }
                                  onChange={(e) => {
                                    setFieldValue(`state_id`, e ? e : "");
                                    setFieldValue(`suburb_id`, "");
                                    e && handleStateSelect(e.value);
                                    // handleChange(e);
                                  }}
                                  classNamePrefix="selectbox"
                                  options={stateListing}
                                  isLoading={
                                    stateLoading ? stateLoading : false
                                  }
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="state_id" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Select
                                  Suburb
                                </label>
                                <Select
                                  type="text"
                                  name="suburb_id"
                                  value={
                                    values?.suburb_id ? values?.suburb_id : ""
                                  }
                                  // value={
                                  //   typeof values?.suburb_id === "object"
                                  //     ? values?.suburb_id
                                  //     : typeof values?.suburb_id === "string"
                                  //     ? suburb?.find(
                                  //         (item) =>
                                  //           item?.value === values?.suburb_id
                                  //       )
                                  //     : ""
                                  // }
                                  classNamePrefix="selectbox"
                                  options={suburb}
                                  onChange={(e) => {
                                    setFieldValue(`suburb_id`, e ? e : "");
                                    // handleChange(e);
                                  }}
                                  isLoading={
                                    suburbLoading ? suburbLoading : false
                                  }
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="suburb_id" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Post Code
                                </label>
                                <InputMask
                                  type="text"
                                  name="postal_code"
                                  value={
                                    values?.postal_code
                                      ? values?.postal_code
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                  mask="9999"
                                  maskChar={null}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="postal_code" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>ABN
                                </label>
                                <InputMask
                                  mask="99 999 999 999"
                                  name="abn"
                                  type="text"
                                  value={
                                    values?.abn
                                      ? values?.abn
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                  maskChar={null}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="abn" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="action-box">
                            <p>
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  setIsAgree(e.target.checked);
                                }}
                              ></input>{" "}
                              I agree to the{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href= "https://smecashbook.com.au/terms-conditions/"
                                style={{ borderBottom: "1px dotted #FFF" , textDecoration: "none"}}
                              >
                                {" "}
                                Terms & Condition
                              </a>
                            </p>
                            <p>
                              Already have an account?{" "}
                              <Link
                                to={{ pathname: "/" }}
                                style={{ borderBottom: "1px dotted #FFF" }}
                                // className="btn-nolink"
                              >
                                Sign In
                              </Link>
                            </p>
                            <div>
                              <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!isAgree}
                              >
                                Create <FontAwesomeIcon icon={faChevronRight} />
                              </Button>
                            </div>
                            <p
                              style={{
                                width: "100%",
                                color: "#FFF",
                                fontSize: "14px",
                              }}
                            >
                              If you face any difficulties in the signup
                              process, please contact our{" "}
                              <a
                                href={true}
                                // href="https://trusteasy.com.au/contact-us/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ borderBottom: "1px dotted #FFF" }}
                              >
                                support team
                              </a>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  )}

                  {activeTab === "Accounting Firm" && (
                    <Formik
                      initialValues={{
                        first_name: defaultData?.first_name,
                        last_name: defaultData?.last_name,
                        phone: defaultData?.phone,
                        email: defaultData?.email,
                        password: defaultData?.password,
                        password_confirmation:
                          defaultData?.password_confirmation,
                        business_name: defaultData?.business_name,
                        business_email: defaultData?.business_email,
                        street: defaultData?.street,
                        state_id: state,
                        suburb_id: suburbValue,
                        postal_code: defaultData?.postal_code,
                        abn: defaultData?.abn
                      }}
                      enableReinitialize
                      validationSchema={FirmSignupAccountingFirmSchema}
                      validateOnBlur={true}
                      validateOnChange={true}
                      onSubmit={(value, { resetForm }) => {
                        handleFormSubmit(value, { resetForm });
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          noValidate
                          className="entry-section"
                          autoComplete="off"
                        >
                          <small>
                            You are registering as an Accounting Firm.
                          </small>
                          <div className="entry-card">
                            <h3>Personal Details</h3>
                            <div className="form-box">
                              {" "}
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>First Name
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  value={
                                    values?.first_name ? values?.first_name : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="first_name" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Last Name
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  value={
                                    values?.last_name ? values?.last_name : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="last_name" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Phone No.
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone"
                                  value={values?.phone ? values?.phone : ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  mask="+61 9 9999 9999"
                                  maskChar={null}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="phone" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Email (For
                                  Login)
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  value={values?.email ? values?.email : ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="email" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Password
                                </label>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type={password1}
                                    name="password"
                                    value={
                                      values?.password ? values?.password : ""
                                    }
                                    autoComplete="off"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <i
                                    onClick={Eye1}
                                    style={{
                                      position: "absolute",
                                      right: "20px",
                                      cursor: "pointer",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      marginTop: "-2px",
                                    }}
                                    className={`icon ${
                                      eye1 ? "icon-eye-close" : "icon-eye-open"
                                    }`}
                                  ></i>
                                </div>
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="password" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Re-type
                                  Password
                                </label>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type={password2}
                                    name="password_confirmation"
                                    value={
                                      values?.password_confirmation
                                        ? values?.password_confirmation
                                        : ""
                                    }
                                    autoComplete="off"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <i
                                    onClick={Eye2}
                                    style={{
                                      position: "absolute",
                                      right: "20px",
                                      cursor: "pointer",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      marginTop: "-2px",
                                    }}
                                    className={`icon ${
                                      eye2 ? "icon-eye-close" : "icon-eye-open"
                                    }`}
                                  ></i>
                                </div>
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="password_confirmation" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="entry-card">
                            <h3>Accounting Firm Details</h3>
                            <div className="form-box">
                              {" "}
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Name of the
                                  Practice
                                </label>
                                <input
                                  type="text"
                                  name="business_name"
                                  value={
                                    values?.business_name
                                      ? values?.business_name
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="business_name" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Business
                                  Email
                                </label>
                                <input
                                  type="text"
                                  name="business_email"
                                  value={
                                    values?.business_email
                                      ? values?.business_email
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="business_email" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Street
                                </label>
                                <input
                                  type="text"
                                  name="street"
                                  value={values?.street ? values?.street : ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="street" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Select
                                  State
                                </label>
                                <Select
                                  type="text"
                                  name="state_id"
                                  value={
                                    values?.state_id ? values?.state_id : ""
                                  }
                                  // value={
                                  //   typeof values?.state_id === "object"
                                  //     ? values?.state_id
                                  //     : typeof values?.state_id === "string"
                                  //     ? stateListing?.find(
                                  //         (item) =>
                                  //           item?.value === values?.state_id
                                  //       )
                                  //     : ""
                                  // }
                                  onChange={(e) => {
                                    setFieldValue(`state_id`, e ? e : "");
                                    setFieldValue(`suburb_id`, "");
                                    e && handleStateSelect(e.value);
                                    // handleChange(e);
                                  }}
                                  classNamePrefix="selectbox"
                                  options={stateListing}
                                  isLoading={
                                    stateLoading ? stateLoading : false
                                  }
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="state_id" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Select
                                  Suburb
                                </label>
                                <Select
                                  type="text"
                                  name="suburb_id"
                                  value={
                                    values?.suburb_id ? values?.suburb_id : ""
                                  }
                                  // value={
                                  //   typeof values?.suburb_id === "object"
                                  //     ? values?.suburb_id
                                  //     : typeof values?.suburb_id === "string"
                                  //     ? suburb?.find(
                                  //         (item) =>
                                  //           item?.value === values?.suburb_id
                                  //       )
                                  //     : ""
                                  // }
                                  classNamePrefix="selectbox"
                                  options={suburb}
                                  onChange={(e) => {
                                    setFieldValue(`suburb_id`, e ? e : "");
                                    // handleChange(e);
                                  }}
                                  isLoading={
                                    suburbLoading ? suburbLoading : false
                                  }
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="suburb_id" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>Post Code
                                </label>
                                <InputMask
                                  type="text"
                                  name="postal_code"
                                  value={
                                    values?.postal_code
                                      ? values?.postal_code
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                  mask="9999"
                                  maskChar={null}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="postal_code" />
                                </span>
                              </div>
                              <div className="form-group half-width">
                                <label>
                                  <span className="required">*</span>ABN
                                </label>
                                <InputMask
                                  mask="99 999 999 999"
                                  name="abn"
                                  type="text"
                                  value={
                                    values?.abn
                                      ? values?.abn
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onKeyPress={(e) => {
                                    e.which === 13 && e.preventDefault();
                                  }}
                                  maskChar={null}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <ErrorMessage name="abn" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="action-box">
                            <p>
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  setIsAgree(e.target.checked);
                                }}
                              ></input>{" "}
                              I agree to the{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href= "https://smecashbook.com.au/terms-conditions/"
                                style={{ borderBottom: "1px dotted #FFF" , textDecoration: "none"}}
                              >
                                {" "}
                                Terms & Condition
                              </a>
                            </p>
                            <p>
                              Already have an account?{" "}
                              <Link
                                to={{ pathname: "/" }}
                                style={{ borderBottom: "1px dotted #FFF" }}
                                // className="btn-nolink"
                              >
                                Sign In
                              </Link>
                            </p>
                            <div>
                              <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!isAgree}
                              >
                                Create <FontAwesomeIcon icon={faChevronRight} />
                              </Button>
                            </div>
                            <p
                              style={{
                                width: "100%",
                                color: "#FFF",
                                fontSize: "14px",
                              }}
                            >
                              If you face any difficulties in the signup
                              process, please contact our{" "}
                              <a
                                href={true}
                                // href="https://trusteasy.com.au/contact-us/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ borderBottom: "1px dotted #FFF" }}
                              >
                                support team
                              </a>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  )}
                </section>
              </div>
            </div>
          </div>
        </>
      ) : (
        {
          /* <Authentication /> */
        }
      )}
      {/* {hasToken && tfa === "2" ? <Navigate to={"/clients"} /> : <></>} */}
    </div>
  );
};

export default FirmSignup;
