import { API, get } from "../../../../config";

export const getAccountDetail = async (accountingId) => {
  try {
    const { data } = await get(`${API.ACCOUNT_DETAILS}/` + accountingId);
    return data.data;
  } catch (e) {
    return null;
  }
};
