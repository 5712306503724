import {
  faUndo,
  faUpload,
  faDownload,
  faScrewdriverWrench,
  // faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import React, { useState } from "react";
import ReconcileItemUndo from "./ReconcileItemUndo";
import UploadFile from "../../../common/UploadFile";
import { Button, Modal } from "react-bootstrap";
import { API, fileUpload } from "../../../../config";
import { imageDefaultPrefixPath } from "../../../../config";
import CustomTooltip from "../../../common/CustomTooltip";
import Spin from "../../../common/Spin";
import { ErrorMessage, Formik } from "formik";
import Select from 'react-select';
import AddNewAccountModal from "../../CashFlow/components/AddNewAccountModal";
import { ReallocteItemSchema } from "../../../common/Validation";
import CreatableSelect from "react-select/creatable";
import AddNewContactModal from "../../CashFlow/components/AddNewContactModal";

const ReconcileItemActionButton = ({
  id,
  handleUndo,
  businessId,
  type,
  reconcileItemList,
  reconcileItemExpandList,
  setIsImportLoading,
  loading,
  bank_reconciliation,
  chart_of_accountId,
  accountLoading,
  accountData,
  getAccounts,
  allAccountValue,
  allContactList,
  loadingContactList,
  handleContactList
}) => {
  const [showUndo, setShowUndo] = useState(false);
  const [showFix, setShowFix] = useState(false);

  const [files, setFiles] = useState([]);
  const [showImport, setShowImport] = useState(false);

  const [fixLoading, setFixLoading] = useState(false);

  const [addNewAccount, setAddNewAccount] = useState(false);
  const [newAccount, setNewAccount] = useState();

  const [newAccountName, setNewAccountName] = useState("");

  const [addNewContact, setAddNewContact] = useState(false);
  const [newContact, setNewContact] = useState();
  const [newContactLastName, setNewContactLastName] = useState("");

  const [showContactExpand, setShowContactExpand] = useState(false);

  // const [showView, setShowView] = useState(false);

  const toggleShowModal = () => {
    handleUndo();
    setShowUndo(false);
  };

  const toggleUndoModal = () => {
    setShowUndo(!showUndo);
  };

  const toggleFixModal = () => {
    if(!showFix){
      getAccounts()
    }
    setShowFix(!showFix);
    setShowContactExpand(false);
    setNewContact("");
  };


  const handleOpenModal = () => {
    setShowImport(!showImport);
  };

  const handleClosemodal = () => {
    setShowImport(!showImport);
  };

  const handleImportClose = () => {
    setShowImport(!showImport);
    setIsImportLoading(false);
    setFiles([]);
  };

  // console.log('itemType: ', itemType);
  // getAccounts(itemType)

  const handleUpload = async () => {
    if (files) {
      setIsImportLoading(true);
      const formData = new FormData();
      // files.forEach((file) => {
      if (files !== undefined) {
        formData.append("comment", "Test");
        formData.append("invoice", files);
        formData.append("_method", "put");
      }
      // });
      const config = { headers: { "Content-Type": "multipart/form-data" } };

      await fileUpload(
        API.UPLOAD_INVOICE_BANK_TRANSACTION + "/" + id,
        formData,
        config
      )
        .then((response) => {
          if (response.status === 200) {
            setIsImportLoading(false);
            toast.success(response.data.message);
            handleImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsImportLoading(false);
          toast.error(data?.message);
          toast.error(
            data.errors &&
              data.errors.customer_file &&
              data.errors.customer_file[0]
          );
          toast.error(
            data.errors && data.errors.myna_error && data.errors.myna_error[0]
          );
          var error = {};
          error["myna_error"] =
            "There is some issue with request, please try after some time.";
        });
    }
  };

  const invoice = reconcileItemList
    .filter((item) => item.id === id)
    .map((data) => data.invoice)
    .pop();

  const handleDownload = async (id) => {
    try {
      setIsImportLoading(true);

      var a = document.createElement("a");

      a.href = imageDefaultPrefixPath + invoice;

      a.setAttribute("download", invoice);

      // a.setAttribute("target", "_blank");

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      setIsImportLoading(false);
      // }
    } catch (e) {
      setIsImportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleReconcileItemFix = async (value) => {

    const formData = new FormData();
    value.account_id = value?.account_id ? value?.account_id?.value : '';
    value.gst = value?.gst ? value?.gst?.value :  '';

    value.contact_id = value?.contact_id ? value?.contact_id?.value : "";

    formData.append('client_business_id', businessId);
    formData.append('chart_of_account_id', value?.account_id ? value?.account_id : '');
    formData.append('contact_id', value?.contact_id ? value?.contact_id : '');
    formData.append('gst', value?.gst);

    try {
      setFixLoading(true);
      const { data } = await fileUpload(`${API.BANK_RECONCILED_TRANSACTION_REALLOCATE}/${id}`, formData);
      setFixLoading(false);
      if (data) {
        toast.success(data?.message);
        handleUndo();
        toggleFixModal();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setFixLoading(false);
      } else {
        toast.error(error?.message);
        setFixLoading(false);
      }
    }
  };

  const GSTOptions = [
    { label: "GST 10%", value: 1 },
    { label: "GST Excluded", value: 0 },
    { label: "BAS Excluded", value: 2 },
    { label: "GST on Imports", value: 3 },
  ];

  const handleAddNewAccount = () => {
    setAddNewAccount(!addNewAccount);
  };

  const toggleContactExpand = () => {
    setShowContactExpand(!showContactExpand)
  }

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  return (
    <>
      <span>
        <CustomTooltip
          text={"Unallocate"}
          children={
            <FontAwesomeIcon
              onClick={toggleUndoModal}
              icon={faUndo}
              // title="Undo"
              size="1x"
              data-toggle="modal"
              data-target="#undoReconcileItem"
            />
          }
        />
      </span>
      {bank_reconciliation.length === 1 &&
        <span>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          <span>
            <CustomTooltip
              text={"Reallocate"}
              children={
                <FontAwesomeIcon
                  onClick={toggleFixModal}
                  icon={faScrewdriverWrench}
                  size="1x"
                  data-toggle="modal"
                />
              }
            />
          </span>
        </span>
      }
      {type === 0 ? (
        <>
          {/* {!invoice ? ( */}
          <>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <span>
            <CustomTooltip
              text={"Upload Invoice"}
              children={
                <FontAwesomeIcon
                  onClick={handleOpenModal}
                  icon={faUpload}
                  // title="Upload Invoice"
                  size="1x"
                  data-toggle="modal"
                  // data-target="#undoReconcileItem"
                />
              }
            />
            </span>
          </>
          {/* ) : null} */}
          {invoice ? (
            <>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <span>
              <CustomTooltip
                text={"Download Invoice"}
                children={
                  <FontAwesomeIcon
                    onClick={() => handleDownload()}
                    icon={faDownload}
                    // title="Download Invoice"
                    size="1x"
                    data-toggle="modal"
                    // data-target="#undoReconcileItem"
                  />
                }
              />
              </span>
            </>
          ) : null}
        </>
      ) : (
        ""
      )}
      {showUndo && (
        <ReconcileItemUndo
          itemId={id}
          toggleUndoModal={toggleUndoModal}
          handleUndo={toggleShowModal}
          showUndo={showUndo}
          businessId={businessId}
        />
      )}

      <Modal
        size="lg"
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Upload Invoice
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <UploadFile
              isMultiple={true}
              isLoading={loading}
              setFiles={setFiles}
              files={files}
              handleUpload={handleUpload}
              handleCancel={handleClosemodal}
              // handleFileChange={handleFileChange}
              acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg, text/jpg, image/png"
              ErrorMessage="xls, xlsx, docx, pdf, jpg, jpeg and png"
            />
          </div>
        </Modal.Body>
      </Modal>
      {showFix && (
        <Modal
        size="lg"
        show={showFix}
        onHide={toggleFixModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section reconcile-fix-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Reallocate Item
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              from_account_id : allAccountValue ? allAccountValue.find((i) => i.value === chart_of_accountId) : '',
              account_id: newAccount ? newAccount : '',
              gst: newAccount ? GSTOptions.find((item) => item.value === allAccountValue.find((i) => i.value === newAccount.value)?.tax) : '',
              contact_id : newContact ? newContact : ''
            }}
            onSubmit={handleReconcileItemFix}
            enableReinitialize
            // onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={ReallocteItemSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(accountLoading || fixLoading) && <Spin />}
                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                    <div className="form-box">
                      <div className="form-group full-width">
                        <label>
                          From
                        </label>
                        <Select
                          name="from_account_id"
                          value={values?.from_account_id ? values?.from_account_id : ''}
                          isDisabled
                          onBlur={handleBlur}
                          options={accountData}
                          isLoading={accountLoading}
                          placeholder="Select Account"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="from_account_id" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>To
                        </label>
                        <CreatableSelect
                          formatCreateLabel={(userInput) => (
                            <>
                              <strong>+ Add New Account </strong>'{`${userInput}`}'
                            </>
                          )}
                          name="account_id"
                          value={values?.account_id ? values?.account_id : newAccount ? newAccount : ''}
                          onChange={(e) => {
                            if (e?.value === 'new' || e?.__isNew__ === true) {
                              if(e?.__isNew__ === true){
                                setNewAccountName(e?.label);
                              } else {
                                setNewAccountName("")
                              }
                              setAddNewAccount(true);
                            } else {
                              setFieldValue(`account_id`, e ? e : '');
                              setNewAccount(e);
                              setFieldValue(`gst`, e ? GSTOptions?.find((i) => i.value === e.tax) : '')
                            }
                          }}
                          onBlur={handleBlur}
                          options={accountData}
                          isLoading={accountLoading}
                          placeholder="Select Account"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="account_id" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>GST Type
                        </label>
                        <Select
                          name="gst"
                          value={values?.gst ? values?.gst : ''}
                          onChange={(e) => {
                            setFieldValue('gst', e ? e : '');
                          }}
                          onBlur={handleBlur}
                          options={GSTOptions}
                          placeholder="Select GST"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="gst" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                      <div className="expand-options" style={{ textAlign: "right" }}>
                        <span onClick={() => toggleContactExpand()}>
                          {showContactExpand ? <b><i className="fa fas fa-chevron-down"></i></b> : <b><i className="fa fas fa-chevron-left"></i></b>}
                        </span>
                      </div>

                      {showContactExpand &&
                        <div className="form-group full-width">
                          <label>
                          Contact Name
                          </label>
                          <CreatableSelect
                            formatCreateLabel={(userInput) => (
                              <>
                                <strong>+ Add New Contact </strong>'{`${userInput}`}'
                              </>
                            )}
                            // type="select"
                            name="contact_id"
                            value={
                              values?.contact_id ? values?.contact_id : ""
                            }
                            onChange={(e) => {
                              if (e?.value === 'new' || e?.__isNew__ === true) {
                                if(e?.__isNew__ === true){
                                  setNewContactLastName(e?.label);
                                } else {
                                  setNewContactLastName("")
                                }
                                setAddNewContact(true);
                              } else {
                                setFieldValue(`contact_id`, e ? e : '');
                                setNewContact(e);
                              }
                            }}
                            onBlur={handleBlur}
                            options={allContactList}
                            isClearable={true}
                            placeholder="Select Contact Name"
                            classNamePrefix="selectbox"
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "grey",
                              },
                            })}
                            isLoading={loadingContactList}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="contact_id" />
                          </span>
                        </div>
                      }
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        onClick={() => {
                          toggleFixModal();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </Button>
                    </div>

                    {addNewAccount && (
                      <AddNewAccountModal
                        addNewAccount={addNewAccount}
                        getAccounts={getAccounts}
                        handleAddNewAccount={handleAddNewAccount}
                        businessId={businessId}
                        setNewAccount={setNewAccount}
                        setFieldValue1={setFieldValue}
                        newAccountName={newAccountName}
                      />
                    )}

                    {addNewContact && (
                      <AddNewContactModal
                        addNewContact={addNewContact}
                        handleAddNewContact={handleAddNewContact}
                        handleContactList={handleContactList}
                        businessId={businessId}
                        setNewContact={setNewContact}
                        setFieldValue1={setFieldValue}
                        newContactLastName={newContactLastName}
                      />
                    )}
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      )}
    </>
  );
};

export default ReconcileItemActionButton;
