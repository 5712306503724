import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sitelogo from "../../components/Header/Logo";
import { API, get, IV, post, SECRETKEY } from "../../config";
import Spin from "../common/Spin";
import { LoginSchema } from "../common/Validation";
import SlideToggle from "react-slide-toggle";
import { useEffect } from "react";
import wFavicon from "../../assets/images/myna-white-favicon.png";

const Login = (props) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({ error: "" });
  const [loading, setLoading] = useState(false);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const clientId = localStorage.getItem("sme-clientId");
  // localStorage.setItem("sme-userId", "a4c528d4-bb09-4acc-9ce2-7a1b04bcf883");

  const userId = localStorage.getItem("sme-userId");
  const EmailVarification = localStorage.getItem("sme-EmailVarification");
  const hasToken = localStorage.getItem("sme-auth-token");
  const defaultEmail = localStorage.getItem("sme-invite-accountingFirmEmail");
  const tokenExpiredEmail = localStorage.getItem("sme-token-expired-email");
  const DefaultURL = localStorage.getItem("sme-DefaultURL");
  const invited = localStorage.getItem("sme-client-invited");

  var CryptoJS = require("crypto-js");

  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);
  var currentdate = new Date(); 

  const handleFormSubmit = async (values) => {
    try {
      setMessage({});
      if (values?.email?.trim() === "" || values?.password?.trim() === "") {
        setMessage({
          error: "Enter valid email and password",
        });
      } else {
        setLoading(true);

        const encPassword = CryptoJS.AES.encrypt(values?.password, fkey, {
          iv: fiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const finalEncryptedPassword = encPassword.ciphertext.toString(
          CryptoJS.enc.Base64
        );
        const finalValue = { ...values, password: finalEncryptedPassword };

        const { data } = await post(API.LOGIN, finalValue);
        if (data?.status === 200 && data.access_token !== undefined) {
          const token = data.access_token;
          const refreshToken = data.refresh_token;
          props.authenticate(token, values.email, data['2fa'], refreshToken);
          if (data['2fa'] === -1) {
            if (data?.role !== 'Admin') {
              try {
                let subdata = await get(API.GET_SUBSCRIPTION);
                if (subdata) {
                  localStorage.setItem('sme-subscription', 1);
                }
              } catch (error) {
                localStorage.setItem('sme-subscription', 0);                
              }
            }
            if(data?.role === 'Client' || data?.role === 'Client User'){
              localStorage.setItem('sme-subscription', 1);
            }
            const userDetails = await get(API.GET_USER_PROFILE);
            const { first_name, last_name, profile_pic, id } =
              userDetails?.data?.data;
            localStorage.setItem("sme-Name", first_name ? first_name : last_name);
            localStorage.setItem("sme-ProfilePhoto", profile_pic);
            localStorage.setItem("sme-role", data.role);
            localStorage.setItem("sme-userId", id);
            localStorage.setItem("sme-EmailVarification", 1);
            localStorage.setItem("sme-auth-login-time", currentdate.getTime());
            (data.role === "Client" || data.role === "Client User") &&
              localStorage.setItem("sme-clientId", data?.client_id);

            if (data?.role !== "Client" && data?.role !== "Client User" && data?.role !== "Admin") {
              const accountingFirmData = await get(API.ACCOUNTING_FIRM_SETTINGS);
              localStorage.setItem(
                "sme-AccountingFirmLogo",
                accountingFirmData?.data?.data?.accountingFirm?.logo
                  ? accountingFirmData?.data?.data?.accountingFirm?.logo
                  : ""
              );
            }


            if(invited) {
              navigate("/user-settings?tab=change-password")
            } else {
              tokenExpiredEmail === values?.email
              ? navigate(DefaultURL)
              : parseInt(localStorage.getItem('sme-subscription')) === 0 
              ? navigate("/general-settings?tab=subscriptions")
              : (data.role === "Client" || data.role === "Client User")
              ? navigate("/businesses")
              : data.role === "Admin"
              ? navigate("/admin/dashboard")
              : navigate("/clients");
            }

            window.setTimeout(function () {
              localStorage.removeItem("sme-DefaultURL");
              localStorage.removeItem("sme-token-expired-email");
            }, 9000);
            setLoading(false);
          } else {
            if (data['2fa'] !== null && data['2fa'] !== 0) {
              localStorage.setItem('sme-role', data.role);
              (data.role === "Client" || data.role === "Client User") &&
              localStorage.setItem("sme-clientId", data?.client_id);
              navigate('/2fa', { replace: true });
            } else {
              localStorage.setItem('sme-role', data.role);
              (data.role === "Client" || data.role === "Client User") &&
              localStorage.setItem("sme-clientId", data?.client_id);
              navigate('/2fa/register', { replace: true });
            }
          }

        }
      }
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      setMessage({
        error:
          errorMessage ||
          "There is some issue with request, please try after some time.",
      });
      setLoading(false);
    }
  };

  // const handleFinish = async (values) => {
  //   try {
  //     setMessage({});
  //     if (values.email.trim() === "" || values.password.trim() === "") {
  //       setMessage({
  //         error: "Enter valid email and password",
  //       });
  //     } else {
  //       // setLoading(true);
  //       const { data } = await post(API.LOGIN, values);

  //       if (data.status === 200 && data.access_token !== undefined) {
  //         const token = data.access_token;
  //         props.authenticate(token, values.email, data["2fa"]);
  //         setLoading(false);
  //         if (data["2fa"] === -1) {
  //           const userDetails = await get(API.GET_USER_PROFILE);
  //           const { first_name, profile_pic } = userDetails.data;
  //           localStorage.setItem("sme-Name", first_name);
  //           localStorage.setItem("sme-ProfilePhoto", profile_pic);
  //           localStorage.setItem("sme-role", data.role);
  //           if (data["role"] === 1) {
  //             navigate("/admin/dashboard", { replace: true });
  //           } else {
  //             navigate("/dashboard?field=name&type=asc&filter=weekly", {
  //               replace: true,
  //             });
  //           }
  //         } else {
  //           if (data["2fa"] !== null && data["2fa"] !== 0) {
  //             navigate("/2fa", { replace: true });
  //           } else {
  //             navigate("/2fa/register", { replace: true });
  //           }
  //         }
  //       } else if (!data.access_token) {
  //         setMessage({
  //           error: "Username or password not recognised, please try again.",
  //         });
  //         setLoading(false);
  //       } else {
  //         setMessage({ error: data.errors?.myna_error[0] });
  //         setLoading(false);
  //       }

  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     const errorMessage = e.response?.data?.errors?.myna_error[0];
  //     setMessage({
  //       error:
  //         errorMessage ||
  //         "There is some issue with request, please try after some time.",
  //     });
  //     setLoading(false);
  //   }
  // };
  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  useEffect(() => {
    EmailVarification === "1" &&
      hasToken &&
      userId &&
      (clientId ? navigate("/businesses") : navigate("/clients"));
  }, [navigate, EmailVarification, userId, hasToken, clientId]);

  return (
    <>
      <div className="login-screen">
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" /> SME
              CashBook acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          {message.error && <p className="text-danger">{message.error}</p>}
          <Formik
            initialValues={{
              email: defaultEmail ? defaultEmail : "",
              password: "",
            }}
            enableReinitialize
            validationSchema={LoginSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                  <div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        id="yourUsername"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        value={values.email}
                        required
                      />
                    </div>
                    <span className="text-danger">{errors?.email}</span>
                  </div>

                  <div style={{ position: "relative" }}>
                    <input
                      type={password}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      value={values.password}
                      id="yourPassword"
                      required
                    />
                    <i
                      onClick={Eye}
                      style={{
                        position: "absolute",
                        right: "20px",
                        cursor: "pointer",
                        top: "50%",
                        transform: "translateY(-50%)",
                        marginTop: "-2px",
                      }}
                      className={`icon ${
                        eye ? "icon-eye-close" : "icon-eye-open"
                      }`}
                    ></i>
                    <span className="text-danger">{errors?.password}</span>
                  </div>

                  {loading && <Spin />}
                  <div>
                    <button className="button lg-btn" type="submit">
                      Login
                    </button>
                  </div>
                  <div>
                    <p>
                      <button
                        type="button"
                        className="btn-nolink"
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot Password?
                      </button>
                    </p>
                    <p>
                      Not registered yet?{" "}
                      <a
                        href={`/signup`}
                        rel="noreferrer"
                        className="btn-nolink"
                        // target="_blank"
                      >
                        Signup Now
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a href="/#" rel="noreferrer">
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="https://smecashbook.com.au/terms-conditions/" rel="noreferrer" target="_blank">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a href="/#" rel="noreferrer" target="_blank">
                Privacy Policy
              </a>
            </p>
          </div>
          <SlideToggle>
            {({ toggle, setCollapsibleElement }) => (
              <div className="my-collapsible">
                <i
                  className="fa fa-info my-collapsible__toggle"
                  onClick={toggle}
                ></i>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                >
                  <div className="my-collapsible__content-inner">
                    <p style={{ fontSize: "12px" }}>
                      <img
                        className="small-white-favicon"
                        src={wFavicon}
                        alt=""
                      />{" "}
                      SME CashBook acknowledges the Traditional Custodians of
                      country throughout Australia and their connections to
                      land, sea and community. We pay our respect to their
                      Elders past and present and extend that respect to all
                      Aboriginal and Torres Strait Islander peoples today.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        </div>
      </div>
    </>
  );
};
export default Login;
