import { convertToRaw } from 'draft-js';
import { ErrorMessage } from 'formik';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

const EmailSettings = ({ values, handleChange, handleBlur, setFieldValue, setEditorNonIndividualState, setEditorState, editorState, editorNonIndividualState }) => {

  const handleEditorChange = (state) => {
    if(convertToRaw(state.getCurrentContent()).blocks.length === 1 && convertToRaw(state.getCurrentContent()).blocks[0].text === ''){
      setFieldValue('body_individual', '')
    } else {
      setFieldValue('body_individual', state ? state : '')
    }
    setEditorState(state);
  };

  const handleEditorNonIndividualChange = (state) => {
    if(convertToRaw(state.getCurrentContent()).blocks.length === 1 && convertToRaw(state.getCurrentContent()).blocks[0].text === ''){
      setFieldValue('body_nonindividual', '')
    } else {
      setFieldValue('body_nonindividual', state ? state : '')
    }
    setEditorNonIndividualState(state)
  }
  return (
    <div className="form-group settings-box">
      <div className="email-option-body">
        <div className="settings-heading">Email Settings</div>
        <div className="email-box-body">
          <div className="settings-heading">Individual</div>
          <div className='email-form-box'>
            <div className="form-group full-width">
              <label>
                Subject
              </label>
              <input
                type="text"
                name="subject_individual"
                value={values?.subject_individual ? values?.subject_individual : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="subject_individual" />
              </span>
            </div>
            <div className="form-group full-width">
              <label>
                Email Body
              </label>
              <Editor
                editorState={editorState}
                toolbar={{
                  options: ['inline', 'list', 'textAlign', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  }
                }}
                name="body_individual"
                onEditorStateChange={(state) => handleEditorChange(state)}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                stripPastedStyles={true}
              />
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="body_individual" />
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="email-box-body">
          <div className="settings-heading">Non Individual</div>
          <div className='email-form-box'>
            <div className="form-group full-width">
              <label>
                Subject
              </label>
              <input
                type="text"
                name="subject_nonindividual"
                value={values?.subject_nonindividual ? values?.subject_nonindividual : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="subject_nonindividual" />
              </span>
            </div>
            <div className="form-group full-width">
              <label>
                Email Body
              </label>
              <Editor
                editorState={editorNonIndividualState}
                toolbar={{
                  options: ['inline', 'list', 'textAlign', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  }
                }}
                name="body_nonindividual"
                onEditorStateChange={(state) => handleEditorNonIndividualChange(state)}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                stripPastedStyles={true}
              />
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="body_nonindividual" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
