import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import Select from 'react-select';
import moment from 'moment';
import { DatePicker } from 'rsuite';
import AddNewContactModal from "../../components/AddNewContactModal";
import AddNewAccountModal from '../../components/AddNewAccountModal';
import AddNewJobModal from "../../components/AddNewJobModal";
import AddNewTaskModal from "../../components/AddNewTaskModal";
import CreatableSelect from "react-select/creatable";
import { useState } from 'react';

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleShowModal,
  accountLoading,
  accountData,
  allContactList,
  handleContactList,
  loadingContactList,
  allContactValue,
  jobOptions,
  jobLoading,
  taskOptions,
  taskLoading,
  GSTOptions,
  handleAddNewContact,
  setAddNewContact,
  addNewContact,
  businessId,
  setNewContact,
  newContact,
  setNewAccount,
  newAccount,
  handleAddNewAccount,
  setAddNewAccount,
  addNewAccount,
  getAccounts,
  handleAddNewJob,
  setAddNewJob,
  addNewJob,
  setNewJob,
  newJob,
  handleJobChange,
  handleAddNewTask,
  setAddNewTask,
  addNewTask,
  setNewTask,
  newTask,
  handleTaskChange,
  setNewTransactionDate,
  newTransactionDate,
  setNewDescription,
  newDescription,
  setNewGst,
  newGst,
  setNewAmount,
  newAmount
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleShowModal: PropTypes.any
  };

  const [newAccountName, setNewAccountName] = useState("");
  const [newContactLastName, setNewContactLastName] = useState("");
  const [newJobCode, setNewJobCode] = useState("");
  const [newTaskCode, setNewTaskCode] = useState("");

  return (
    <>
      <form onSubmit={handleSubmit} noValidate className="entry-section">
        <div className="form-box">
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Transaction Date
            </label>
            <DatePicker
              onChange={(e) => {setFieldValue('transactionDate', e); setNewTransactionDate(e)}}
              value={values?.transactionDate ? values?.transactionDate : newTransactionDate ? newTransactionDate : ''}
              cleanable={false}
              format="dd-MM-yyyy"
              placeholder="Select Transaction Date"
              disabledDate={(date) => moment(date).isAfter(moment())}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="transactionDate" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Amount
            </label>
            <input
              type="text"
              name="amount"
              value={values?.amount ? values?.amount : newAmount ? newAmount : ''}
              onChange={(e) => {
                setFieldValue('amount', e.target.value);
                setNewAmount(e.target.value)
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              className="amount-color-input rag-green"
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="amount" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Account
            </label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Account </strong>'{`${userInput}`}'
                </>
              )}
              name="account_id"
              value={values?.account_id ? values?.account_id : newAccount ? newAccount : ''}
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewAccountName(e?.label);
                  } else {
                    setNewAccountName("")
                  }
                  setAddNewAccount(true);
                } else {
                  setFieldValue(`account_id`, e ? e : '');
                  setNewAccount(e);
                  setNewGst(GSTOptions?.find((i) => i.value === e.tax))
                }
              }}
              onBlur={handleBlur}
              options={accountData ? accountData : [{ label: <strong>+ Add New Contact</strong>, value: "new" }]}
              isLoading={accountLoading}
              placeholder="Select Account"
              classNamePrefix="selectbox"
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'grey'
                }
              })}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="account_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>GST Type
            </label>
            <Select
              name="gst"
              value={values?.gst ? values?.gst : newGst ? newGst : ''}
              onChange={(e) => {
                setFieldValue('gst', e ? e : '');
                setNewGst(e);
              }}
              onBlur={handleBlur}
              options={GSTOptions}
              placeholder="Select GST"
              classNamePrefix="selectbox"
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'grey'
                }
              })}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="gst" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Contact Name</label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Contact </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="contact_id"
              value={values?.contact_id ? values?.contact_id : newContact? newContact : ''}
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewContactLastName(e?.label);
                  } else {
                    setNewContactLastName("")
                  }
                  setAddNewContact(true);
                } else {
                  setFieldValue(`contact_id`, e ? e : '');
                  setNewContact(e);
                }
              }}
              onBlur={handleBlur}
              options={allContactList}
              isClearable={true}
              placeholder="Select Contact Name"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'grey'
                }
              })}
              isLoading={loadingContactList}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="contact_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Description
            </label>
            <input
              type="text"
              name="description"
              value={values?.description ? values?.description : newDescription ? newDescription : ''}
              onChange={(e) => {
                setFieldValue('description', e.target.value);
                setNewDescription(e.target.value);
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="description" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Job</label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Job </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="business_job_id"
              value={values?.business_job_id ? values?.business_job_id : newJob ? newJob : ''}
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewJobCode(e?.label);
                  } else {
                    setNewJobCode("");
                  }
                  setAddNewJob(true);
                } else {
                  setFieldValue(`business_job_id`, e ? e : '');
                  setNewJob(e);
                }
              }}
              onBlur={handleBlur}
              options={jobOptions ? jobOptions : []}
              isClearable={true}
              placeholder="Select Job"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'grey'
                }
              })}
              isLoading={jobLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="business_job_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Task</label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Task </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="business_task_id"
              value={values?.business_task_id ? values?.business_task_id : newTask ? newTask : ''}
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewTaskCode(e?.label);
                  } else {
                    setNewTaskCode("");
                  }
                  setAddNewTask(true);
                } else {
                  setFieldValue(`business_task_id`, e ? e : '');
                  setNewTask(e);
                }
              }}
              onBlur={handleBlur}
              options={taskOptions ? taskOptions : []}
              placeholder="Select Task"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'grey'
                }
              })}
              isClearable={true}
              isLoading={taskLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
              <ErrorMessage name="business_task_id" />
            </span>
          </div>
        </div>
        <div className="modal-footer">
          <Button type="submit">Save</Button>
          <Button
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              toggleShowModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </form>

      {addNewContact && (
        <AddNewContactModal
          addNewContact={addNewContact}
          handleAddNewContact={handleAddNewContact}
          handleContactList={handleContactList}
          businessId={businessId}
          setNewContact={setNewContact}
          setFieldValue1={setFieldValue}
          newContactLastName={newContactLastName}
        />
      )}
      
      {addNewAccount && (
        <AddNewAccountModal
          addNewAccount={addNewAccount}
          getAccounts={getAccounts}
          handleAddNewAccount={handleAddNewAccount}
          businessId={businessId}
          setNewAccount={setNewAccount}
          setFieldValue1={setFieldValue}
          newAccountName={newAccountName}
        />
      )}

      {addNewJob && (
        <AddNewJobModal
          addNewJob={addNewJob}
          handleJobChange={handleJobChange}
          handleAddNewJob={handleAddNewJob}
          businessId={businessId}
          setNewJob={setNewJob}
          setFieldValue={setFieldValue}
          newJobCode={newJobCode}
        />
      )}

      {addNewTask && (
        <AddNewTaskModal
          addNewTask={addNewTask}
          handleTaskChange={handleTaskChange}
          handleAddNewTask={handleAddNewTask}
          businessId={businessId}
          setNewTask={setNewTask}
          setFieldValue={setFieldValue}
          newTaskCode={newTaskCode}
        />
      )}
    </>
  );
};

export default Form;
