import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import BootStrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spin from "../../common/Spin";
import Pagination from "../../common/Pagination";
import DiscountActionButton from "./components/DiscountActionButton";
import AddDiscountCode from "./components/AddDiscountCode";
import { API, get } from "../../../config";
import TitleBar from "../../common/TitleBar";

const DiscountCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [discountList, setDiscountsList] = useState([]);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const [showAddDiscount, setShowAddDiscount] = useState(false);

  const handleDelete = () => {
    fetchDiscounts();
  };

  const handleEdit = () => {
    fetchDiscounts();
  };

  const fetchDiscounts = useCallback(async () => {
    try {
      setDiscountLoading(true);
      const { data } = await get(`${API.GET_DISCOUNT_CODE}?client_business_id=${businessId ? businessId : ""}&limit=${limit ? limit : 10}&page=${page ? page : 1}`);
      setDiscountLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const discountsList = data.data.data;
      setDiscountsList(discountsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setDiscountLoading(false);
      setDiscountsList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, businessId]);

  useEffect(() => {
    fetchDiscounts();
    window.document.title = "SME CashBook | Discount Codes"
  }, [fetchDiscounts]);

  const discountsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: discountList[i]?.id,
        code: discountList[i]?.code ? discountList[i]?.code : "",
        description: discountList[i]?.description ? discountList[i]?.description : "",
        discount: discountList[i]?.discount ? discountList[i]?.discount_type === 0 ? `$${discountList[i]?.discount}` : `${discountList[i]?.discount}%` : "",
        action: (
          <DiscountActionButton
            id={discountList[i]?.id}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        ),
      });
    }
    return items;
  };

  const discounts = discountsGenerator(discountList?.length);

  const columns = [
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "discount",
      text: "Discount",
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type) => {
    if (type === "sort") {
      setPage(1);
    }
  };

  useEffect(() => {
    navigate(
      `?tab=discount-code&limit=${limit ? limit : 10}&page=${page ? page : 1}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, page]);

  useEffect(() => {
    fetchDiscounts();
  }, [fetchDiscounts]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleAdd = () => {
    fetchDiscounts();
  };

  const toggleAddDiscountModal = () => {
    setShowAddDiscount(!showAddDiscount);
  };

  return (
    <>
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"Discount Codes"}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={toggleAddDiscountModal}
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="custom-table">
              {discountLoading && <Spin />}
              <BootStrapTable
                keyField="key"
                remote
                data={discounts}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {showAddDiscount && (
        <AddDiscountCode
          businessId={businessId}
          showAddDiscount={showAddDiscount}
          toggleAddDiscountModal={toggleAddDiscountModal}
          handleAdd={handleAdd}
        />
      )}
    </>
  );
};

export default DiscountCode;
