import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import StripePaymentModal from '../../components/Modals/StripePaymentModal';
import { API, get } from '../../config';
import './firm.module.css';
import Spin from '../common/Spin';
import { useLocation, useNavigate } from 'react-router-dom';

const stripeTestPromise = loadStripe(API.STRIPE_PUBLISHABLE_KEY);

const Subscription = ({ subscriptionDetail, afterLogin = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tabName = query.get('tab');

  const [isLoading, setIsLoading] = useState(true);
  const [selectsubscription, selectsubscriptionplan] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [defaultPromoCode, setDefaultPromoCode] = useState('');

  const userId = localStorage.getItem('sme-userId');
  const EmailVarification = localStorage.getItem('sme-EmailVarification');
  const isToken = localStorage.getItem('sme-auth-token');

  const [setsubscription, setsubscriptionlist] = useState([]);

  const [selectedPeriodPlan, setSelectedPeriodPlan] = useState('month');

  const setsubscriptiondata = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await get(API.SUBSCRIPTION);

      let array = [
        { type: 'month', plan: [] },
        { type: 'year', plan: [] }
      ];
      data.data.data.map((item) => {
        if (item?.prices?.data && item?.prices?.data?.length > 0) {
          item?.prices?.data?.map((pricePlan) => {
            if (pricePlan?.recurring?.interval === 'month' && pricePlan?.active === true) {
              const nameObj = { name: item?.name, unitLabel: item?.unit_label };
              array[0].plan.push({ ...pricePlan, ...nameObj, priority: item?.metadata?.Priority });
            }
            if (pricePlan?.recurring?.interval === 'year' && pricePlan?.active === true) {
              const nameObj = { name: item?.name, unitLabel: item?.unit_label };
              array[1].plan.push({ ...pricePlan, ...nameObj, priority: item?.metadata?.Priority });
            }
            return 0;
          });
        }
        return 0;
      });
      array[0]?.plan?.sort((a, b) => Number(a.priority) - Number(b.priority));
      array[1]?.plan?.sort((a, b) => Number(a.priority) - Number(b.priority));
      setSelectedPeriodPlan(array[0]);
      setsubscriptionlist(array);

      setPromoCode(data.coupons);
      data.coupons &&
        data.coupons?.length > 0 &&
        data.coupons?.map((el) => {
          el?.id === 'FIRST50' && setDefaultPromoCode(el?.id);
          return 0;
        });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const ProceedPayment = async (selectedPlan) => {
    if (selectedPlan) {
      selectsubscriptionplan(selectedPlan);
      setShowPaymentModal(true);
    } else {
      toast.error('Please select subscription plan');
    }
  };

  useEffect(() => {
    setsubscriptiondata();
  }, [setsubscriptiondata]);

  const handleStripePaymentmodalclose = () => {
    setShowPaymentModal(false);
  };

  useEffect(() => {
    if (afterLogin) {
      navigate(`?tab=subscriptions&childTab=more-packages`, {
        replace: true
      });
    }
  }, [afterLogin, navigate]);

  useEffect(() => {
    (!userId || EmailVarification !== '1') && navigate('/');
  }, [userId, EmailVarification, navigate]);

  return (
    <>
      <div
        className={
          tabName === 'subscriptions'
            ? 'container-fluid subscription-plan'
            : 'container-fluid subscription-plan'
        }
        style={{ overflow: 'auto', paddingBottom: '30px' }}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <div className="container p-5">
            <div className="row custom-tabs pricetabs">
              {tabName === 'subscriptions' || isToken ? (
                <> </>
              ) : (
                <>
                  <h2
                    style={{
                      textAlign: 'center',
                      margin: '0 0 15px',
                      fontWeight: '700',
                      color: '#FFF'
                    }}
                  >
                    The Best Cash Book Solution at the Best Price!
                  </h2>
                  <p
                    style={{
                      textAlign: 'center',
                      margin: '0 0 30px',
                      fontWeight: '700',
                      color: '#FFF'
                    }}
                  ></p>
                </>
              )}

              <div className="pricetab-block">
                <div className="price-tabs-block">
                  {setsubscription &&
                    setsubscription.length > 0 &&
                    setsubscription.map((el) => {
                      return (
                        <>
                          <h6
                            className={`${el.type === 'month' ? 'monthly-tab' : 'yearly-tab'} ${
                              selectedPeriodPlan.type === el.type ? 'active' : ''
                            }`}
                            onClick={() => setSelectedPeriodPlan(el)}
                          >
                            {el.type === 'month' ? 'Monthly' : 'Yearly'}
                          </h6>
                        </>
                      );
                    })}
                </div>
                <div className="pricetab-table">
                  <div
                    className={
                      selectedPeriodPlan.type === 'month' ? 'monthly active' : 'yearly active'
                    }
                  >
                    {selectedPeriodPlan?.plan &&
                      selectedPeriodPlan?.plan?.length > 0 &&
                      selectedPeriodPlan?.plan?.map((data) => {
                        return (
                          <>
                            {data?.recurring?.interval === selectedPeriodPlan?.type}
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '25px',
                              flexDirection: 'column'
                            }}>
                              <div className="col-lg-6 col-md-12 mb-4 price-tabs">
                                <div className="card-body">
                                  <div className="text-center">
                                    <h5
                                      className="card-title"
                                      style={{
                                        textTransform: 'uppercase',
                                        fontSize: '20px',
                                        fontWeight: '700'
                                      }}
                                    >
                                      {data?.name ? data?.name : ''}
                                    </h5>
                                    <h2
                                      className="h2"
                                      // style={{ margin: "0" }}
                                    >
                                      <sup>$</sup>
                                      <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={
                                          data?.unit_amount
                                            ? (data?.unit_amount / 100).toFixed(2)
                                            : ''
                                        }
                                        decimalSeparator="."
                                        displayType="text"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={true}
                                      />
                                      <sub>
                                        <small>
                                          {selectedPeriodPlan.type === 'month'
                                            ? data?.tax_behavior === 'exclusive'
                                              ? '/pm Ex. GST'
                                              : '/pm Inc. GST'
                                            : data?.tax_behavior === 'exclusive'
                                            ? '/pa Ex. GST'
                                            : '/pa Inc. GST'}
                                        </small>
                                      </sub>
                                    </h2>
                                    <p
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        opacity: '0.7',
                                        margin: 0,
                                        padding: '10px'
                                      }}
                                    >
                                      Per {data?.unitLabel ? data?.unitLabel : ''}
                                    </p>

                                    <button
                                      onClick={() => ProceedPayment(data)}
                                      className={`btn ${
                                        data.id === selectsubscription ? 'btn-disable' : ''
                                      }`}
                                      // disabled={
                                      //   subscriptionDetail?.plan_time &&
                                      //   subscriptionDetail?.plan_time === data.metadata.type &&
                                      //   subscriptionDetail?.status !== 'canceled'
                                      //     ? true
                                      //     : false
                                      // }
                                    >
                                      {subscriptionDetail?.plan_time &&
                                      subscriptionDetail?.plan_time === data.metadata.type &&
                                      subscriptionDetail?.status !== 'canceled'
                                        ? 'Update Plan'
                                        : 'Get Started'}
                                    </button>
                                  </div>
                                  <div className="text-center">
                                    <ul className="list-group list-group-flush h-100 ">
                                      {data.metadata.track_transaction &&
                                        data.metadata.track_transaction === '1' && (
                                          <li className="list-group-item">
                                            Track revenue & expenses
                                          </li>
                                        )}
                                      {data.metadata.connect_bank &&
                                        data.metadata.connect_bank > 0 && (
                                          <li className="list-group-item">
                                            Automatic bank feeds*
                                            {/* {`Connect banks (up to ${data.metadata.connect_bank})`} */}
                                          </li>
                                        )}
                                      {data.metadata.reports && data.metadata.reports === '1' && (
                                        <li className="list-group-item">Insights & reports</li>
                                      )}
                                      {data.metadata.cash_flow &&
                                        data.metadata.cash_flow === '1' && (
                                          <li className="list-group-item">
                                            Upload chart of accounts
                                          </li>
                                        )}
                                      {data.metadata.contacts && data.metadata.contacts === '1' && (
                                        <li className="list-group-item">
                                          Manage suppliers, customers & contractors
                                        </li>
                                      )}
                                      {data.metadata.users && data.metadata.users > 0 && (
                                        <li className="list-group-item">
                                          {`Manage users (up to ${data.metadata.users})`}
                                        </li>
                                      )}
                                      {data.metadata.gst_report_bas &&
                                        data.metadata.gst_report_bas === '1' && (
                                          <li className="list-group-item">{`GST Report for BAS`}</li>
                                        )}
                                      {data.metadata.invite_firm &&
                                        data.metadata.invite_firm === '1' && (
                                          <li className="list-group-item">
                                            {`Invite Accounting Firm`}
                                          </li>
                                        )}
                                      {data.recurring.trial_period_days &&
                                        data.recurring.trial_period_days > 0 && (
                                          <li className="list-group-item">
                                            {`${data.recurring.trial_period_days} days free trial`}
                                          </li>
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <small>*{data?.metadata?.monthly_transactions} Transactions per month</small>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                {tabName === 'subscriptions' || isToken ? (
                  <></>
                ) : (
                  <div>
                    {/* <Button
                      className="reset"
                      onClick={() => {
                        navigate(`/`);
                      }}
                    >
                      Beta client skip for now
                    </Button> */}
                  </div>
                )}
              </div>

              {showPaymentModal && (
                <Elements stripe={stripeTestPromise}>
                  <StripePaymentModal
                    showModal={showPaymentModal}
                    handleModalClose={handleStripePaymentmodalclose}
                    id={userId}
                    planDetail={selectsubscription}
                    promoCode={promoCode}
                    defaultPromocode={defaultPromoCode}
                    planType={selectedPeriodPlan?.type}
                    subscriptionDetail={subscriptionDetail}
                  />
                </Elements>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Subscription;
