import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CancelButton from "../../../../components/Form/CancleButton";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { useShippingSuburbList, useSuburbList } from "./SupplierHelper";
import { entityTypes } from "../../../../config";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  stateListing,
  stateLoading,
  suburbListing,
  shippingSuburbListing,
  suburbLoadingE,
  suburbLoading2E,
  erSuburbLoading,
  erSuburbLoading2,
  ContactTypeListing
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    suburbListing: PropTypes.any,
    shippingSuburbListing: PropTypes.any,
    suburbLoadingE: PropTypes.any,
    suburbLoading2E: PropTypes.any,
    erSuburbLoading: PropTypes.any,
    erSuburbLoading2: PropTypes.any,
  };

  const [isChecked, setIsChecked] = useState(false);

  // Suburb Listing for Postal Address
  const [suburb, setSuburb] = useState([]);
  useEffect(() => {
    setSuburb(suburbListing ? suburbListing : []);
  }, [suburbListing]);
  const [stateId] = useState();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
  } = useSuburbList(stateId);
  useEffect(() => {
    const suburbListing1 = suburbList?.map((item) => {
      const { name: label, id: value, ...rest } = item;
      return { label, value, ...rest };
    });
    setSuburb(suburbListing1);
  }, [suburbList]);

  // Suburb Listing for Street Address
  const [shippingSuburb, setShippingSuburb] = useState([]);
  useEffect(() => {
    setShippingSuburb(shippingSuburbListing ? shippingSuburbListing : []);
  }, [shippingSuburbListing]);
  const [shippingStateId] = useState();
  const {
    shippingSuburbLoading,
    shippingSuburbList = [],
    handleStateSelect1,
  } = useShippingSuburbList(shippingStateId);
  useEffect(() => {
    const shippingSuburbListing = shippingSuburbList?.map((item) => {
      const { name: label, id: value, ...rest } = item;
      return { label, value, ...rest };
    });
    setShippingSuburb(shippingSuburbListing);
  }, [shippingSuburbList]);

  useEffect(() => {
    isChecked === true && setFieldValue("shipping_street", values?.street);
    isChecked === true && setFieldValue("shipping_state_id", values?.state_id);
    isChecked === true &&
      setFieldValue("shipping_suburb_id", values?.suburb_id);
    isChecked === true &&
      setFieldValue("shipping_postal_code", values?.postal_code);
  }, [
    isChecked,
    setFieldValue,
    values?.postal_code,
    values?.street,
    values?.state_id,
    values?.suburb_id,
  ]);

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card add-business-client">
        <h3>Personal Details</h3>
        <div className="form-box">
          <div class="form-box-half">
            <div className="form-group half-width">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                value={values?.first_name ? values?.first_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="first_name" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>
                <span className="required">*</span>Last Name Or Trading Name
              </label>
              <input
                type="text"
                name="last_name"
                value={values?.last_name ? values?.last_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="last_name" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Display Name</label>
              <input
                type="text"
                name="display_name"
                value={values?.display_name ? values?.display_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="display_name" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Phone No.</label>
              <InputMask
                type="text"
                name="phone"
                value={values?.phone ? values?.phone : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="+61 9 9999 9999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="phone" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Mobile No.</label>
              <InputMask
                type="text"
                name="mobile"
                value={values?.mobile ? values?.mobile : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="+61 999 999 999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="mobile" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={values?.email ? values?.email : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="email" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Business Name</label>
              <input
                type="text"
                name="business_name"
                value={values?.business_name ? values?.business_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="business_name" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>ABN</label>
              <InputMask
                type="text"
                name="abn"
                value={values?.abn ? values?.abn : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="99 999 999 999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="abn" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Entity Type</label>
              <Select
                name="entity_type"
                value={values?.entity_type ? values?.entity_type : ""}
                onChange={(e) => {
                  setFieldValue(`entity_type`, e ? e : "");
                  // handleChange(e);
                }}
                onBlur={handleBlur}
                options={entityTypes}
                placeholder="Select Entity Type"
                classNamePrefix="selectbox"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="entity_type" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>Ref. Number</label>
              <input
                type="text"
                name="ref_number"
                value={values?.ref_number ? values?.ref_number : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="ref_number" />
              </span>
            </div>
            <div className="form-group half-width">
              <label>BPay Ref. Number</label>
              <InputMask
                type="text"
                name="bpay_ref_number"
                value={values?.bpay_ref_number ? values?.bpay_ref_number : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="bpay_ref_number" />
              </span>
            </div>
            {ContactTypeListing &&
              <div className="form-group half-width">
                <label>
                  Contact Type
                </label>
                <Select
                  name="contact_type"
                  value={
                    typeof values?.contact_type === "object"
                      ? values?.contact_type
                      : typeof values?.contact_type === "number"
                      ? ContactTypeListing?.find(
                          (item) => item?.value === parseInt(values?.contact_type)
                        )
                      : ""
                  }
                  onChange={(e) => {
                    setFieldValue(`contact_type`, e ? e : "");
                  }}
                  onBlur={handleBlur}
                  options={ContactTypeListing}
                  placeholder="Select Contact Type"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="contact_type" />
                </span>
              </div>
            }
          </div>
          <div className="form-box-half">
            <div className="address-box">
              <h5>Postal Address</h5>
              <div className="form-group half-width">
                <label>Address</label>
                <input
                  type="text"
                  name="street"
                  value={values?.street ? values?.street : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="street" />
                </span>
              </div>
              <div className="form-group half-width">
                <label>Select State</label>
                <Select
                  name="state_id"
                  value={values?.state_id ? values?.state_id : ""}
                  onChange={(e) => {
                    setFieldValue(`state_id`, e ? e : "");
                    setFieldValue(`suburb_id`, "");
                    e && handleStateSelect(e.value);
                    // handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={stateListing}
                  placeholder="Select State"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  isClearable
                  isLoading={stateLoading}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="state_id" />
                </span>
              </div>
              <div className="form-group half-width">
                <label>Select Suburb</label>

                <Select
                  name="suburb_id"
                  value={values?.suburb_id ? values?.suburb_id : ""}
                  onChange={(e) => {
                    setFieldValue(`suburb_id`, e ? e : "");
                    // handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={suburb}
                  placeholder="Select Suburb"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  isClearable
                  isLoading={suburbLoading || suburbLoadingE || erSuburbLoading}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="suburb_id" />
                </span>
              </div>
              <div className="form-group half-width">
                <label>Postal Code</label>
                <InputMask
                  type="text"
                  name="postal_code"
                  value={values?.postal_code ? values?.postal_code : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mask="9999"
                  maskChar={null}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="postal_code" />
                </span>
              </div>
            </div>
            <div className="address-box">
              <h5>Street Address</h5>
              <div className="form-group" style={{ width: "100%", margin: 0 }}>
                <label
                  className="form-lable"
                  style={{
                    width: "auto",
                    display: "flex",
                    flexFlow: "wrap",
                    gap: "5px",
                    fontWeight: "bold",
                    maxWidth: "fit-content",
                  }}
                >
                  <input
                    type="checkbox"
                    name="same_address"
                    checked={isChecked}
                    onBlur={handleBlur}
                    onClick={(e) => {
                      setIsChecked(!isChecked);
                    }}
                    onChange={handleChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                    value="10"
                    style={{ width: "auto" }}
                  />{" "}
                  Same as Postal Address
                </label>
              </div>

              <div className="form-group half-width">
                <label>Address</label>
                <input
                  type="text"
                  name="shipping_street"
                  value={values?.shipping_street ? values?.shipping_street : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  disabled={isChecked === true ? true : false}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_street" />
                </span>
              </div>
              <div className="form-group half-width">
                <label>Select State</label>
                <Select
                  name="shipping_state_id"
                  value={
                    values?.shipping_state_id ? values?.shipping_state_id : ""
                  }
                  onChange={(e) => {
                    setFieldValue(`shipping_state_id`, e ? e : "");
                    setFieldValue(`shipping_suburb_id`, "");
                    e && handleStateSelect1(e.value);
                    // handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={stateListing}
                  placeholder="Select State"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  isClearable
                  isLoading={stateLoading}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  isDisabled={isChecked === true ? true : false}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_state_id" />
                </span>
              </div>
              <div className="form-group half-width">
                <label>Select Suburb</label>

                <Select
                  name="shipping_suburb_id"
                  value={
                    values?.shipping_suburb_id ? values?.shipping_suburb_id : ""
                  }
                  onChange={(e) => {
                    setFieldValue(`shipping_suburb_id`, e ? e : "");
                    // handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={shippingSuburb}
                  placeholder="Select Suburb"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  isClearable
                  isLoading={
                    shippingSuburbLoading || suburbLoading2E || erSuburbLoading2
                  }
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  isDisabled={isChecked === true ? true : false}
                />

                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_suburb_id" />
                </span>
              </div>
              <div className="form-group half-width">
                <label>Postal Code</label>
                <InputMask
                  type="text"
                  name="shipping_postal_code"
                  value={
                    values?.shipping_postal_code
                      ? values?.shipping_postal_code
                      : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isChecked === true ? true : false}
                  mask="9999"
                  maskChar={null}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="shipping_postal_code" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="action-box">
        <button type="submit">Save</button>
        <CancelButton />
      </div>
    </form>
  );
};

export default Form;
