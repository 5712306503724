import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, get } from "../../../config";
import Spin from "../../common/Spin";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatNumber } from "../../common/Misc";
import { DateRangePicker } from "rsuite";
import TitleBar from "../../common/TitleBar";

const currentDate = new Date();

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1)
  );
}

const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];


const GeneralLedgerReport = ({dateRangeReport, setDateRangeReport}) => {
  const params = useParams();
  // const { afterToday } = DateRangePicker;
  const businessId = params?.business_id;

  const [generalLedgerLoading, setGeneralLedgerLoading] = useState(false);
  const [generalLedgerData, setGeneralLedgerData] = useState([]);
  // const [exportGeneralLedgerReportLoading, setExportGeneralLedgerReportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [dateValue, setDateValue] = useState(dateRangeReport ? dateRangeReport : defaultDateRange);
  const [startDate, setStartDate] = useState(
    dateRangeReport ? moment(dateRangeReport[0]).format("DD-MM-YYYY") : (defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : "")
  );
  const [endDate, setEndDate] = useState(
    dateRangeReport ? moment(dateRangeReport[1]).format("DD-MM-YYYY") : (defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : "")
  );

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }

  // let quarterCurrentRange = [];
  // if(quarter === 1){
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate)]
  // } else if(quarter === 2){
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate)]
  // } else if(quarter === 3){
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate)]
  // } else {
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate)]
  // }

  const dateFilterRange = [
    {
      label: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    // {
    //   label: 'Month To Date',
    //   value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate)],
    //   placement: 'bottom',
    //   appearance: 'default',
    //   closeOverlay: false
    // },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    // {
    //   label: 'Quarter To Date',
    //   value: quarterCurrentRange,
    //   placement: 'bottom',
    //   appearance: 'default',
    //   closeOverlay: false
    // },
    {
      label: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    // {
    //   label: 'Financial Year To Date',
    //   value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate)],
    //   placement: 'bottom',
    //   appearance: 'default',
    //   closeOverlay: false
    // },
    {
      label: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
  ]

  const fetchGeneralLedgerData = useCallback(async () => {
    if (businessId) {
      try {
        setGeneralLedgerLoading(true);
        const { data } = await get(
          `${API.GENERAL_LEDGER_REPORT}?start_date=${startDate ? startDate : ""}&end_date=${
            endDate ? endDate : ""
          }&client_business_id=${
            businessId ? businessId : ""
          }`
        );

        setGeneralLedgerLoading(false);
        setGeneralLedgerData(data?.data);
      } catch (error) {
        const { data } = error.response;
        setGeneralLedgerLoading(false);
        setGeneralLedgerData([]);
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      }
    }
  }, [businessId, startDate, endDate]);

  const generalLedgerDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      if(generalLedgerData[i]?.reports.length !== 0){
        items.push({
          key: generalLedgerData[i]?.id ? `${generalLedgerData[i]?.id}-name` : "",
          date: (<div className="general-ledger-report-heading">
            {generalLedgerData[i]?.name
          ? generalLedgerData[i]?.name
          : ""}
          </div>),
          source: (<div className="report_blank_data"></div>),
          contact: (<div className="report_blank_data"></div>),
          description: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data"></div>),
          credit: (<div className="report_blank_data"></div>),
          gst_amount: (<div className="report_blank_data"></div>),
          net_amount: (<div className="report_blank_data"></div>),
          // gst_rate: (<div className="report_blank_data"></div>),
          // gst_rate_name: (<div className="report_blank_data"></div>),
        })
      }
      generalLedgerData[i]?.reports?.sort((a, b) => {
        a = a?.date.split('-');
        b = b?.date.split('-');
        return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
      })
      for ( let j = 0; j < generalLedgerData[i]?.reports.length; j++) {
      items.push({
        key: generalLedgerData[i]?.id ? `${generalLedgerData[i]?.id}-item-${j}` : "",
        date: generalLedgerData[i]?.reports[j]?.date
          ? moment(
            generalLedgerData[i]?.reports[j]?.date,
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY")
          : "-",
        name: generalLedgerData[i]?.name
        ? generalLedgerData[i]?.name
        : "",
        
        source: generalLedgerData[i]?.reports[j]?.source
          ? generalLedgerData[i]?.reports[j]?.source
          : "",

        description: generalLedgerData[i]?.reports[j]?.description
          ? generalLedgerData[i]?.reports[j]?.description
          : "",

        contact: generalLedgerData[i]?.reports[j]?.contact
          ? generalLedgerData[i]?.reports[j]?.contact
          : "",
        
        debit: (generalLedgerData[i]?.reports[j]?.transaction_type === 0
          ? `$${formatNumber(generalLedgerData[i]?.reports[j]?.debit_amount)}`
          : "-"),
        
        credit: (generalLedgerData[i]?.reports[j]?.transaction_type === 1
          ? `$${formatNumber(generalLedgerData[i]?.reports[j]?.credit_amount)}`
          : "-"),
      
        gst_amount: generalLedgerData[i]?.reports[j]?.gst_amount
          ? `$${formatNumber(generalLedgerData[i]?.reports[j]?.gst_amount)}`
          : "-",
        
        net_amount: generalLedgerData[i]?.reports[j]?.net_amount
          ? `$${formatNumber(generalLedgerData[i]?.reports[j]?.net_amount)}`
          : "-",
        
        // gst_rate: (generalLedgerData[i]?.reports[j]?.gst_type === "1"
        //   ? generalLedgerData[i]?.reports[j]?.gst_rate
        //   : `0.00%`),

        // gst_rate_name: (generalLedgerData[i]?.reports[j]?.gst_type === "1"
        //   ? generalLedgerData[i]?.reports[j]?.gst_rate_name
        //   : "No GST"),
        
      });
      }
      if(generalLedgerData[i]?.reports.length !== 0){
        items.push({
          key: generalLedgerData[i]?.id ? `${generalLedgerData[i]?.id}-total` : "",
          date: (<div className="general-ledger-report-heading general-ledger-report-total-body report_blank_data">
            <div> <span className="general-ledger-report-heading-total">Total</span> <span>{generalLedgerData[i]?.name
          ? generalLedgerData[i]?.name
          : ""}</span> </div>
          </div>),
          net_amount: (<div className="report_blank_data report-total">{generalLedgerData[i]?.net_total ? `$${formatNumber(generalLedgerData[i]?.net_total)}`: ''}</div>),
          contact: (<div className="report_blank_data"></div>),
          source: (<div className="report_blank_data"></div>),
          description: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data report-total">{generalLedgerData[i]?.debit_total ? `$${formatNumber(generalLedgerData[i]?.debit_total)}` : ''}</div>),
          credit: (<div className="report_blank_data report-total">{generalLedgerData[i]?.credit_total ? `$${formatNumber(generalLedgerData[i]?.credit_total)}` : ''}</div>),
          gst_amount: (<div className="report_blank_data report-total">{generalLedgerData[i]?.gst_total ? `$${formatNumber(generalLedgerData[i]?.gst_total)}` : ''}</div>),
          // gst_rate: (<div className="report_blank_data"></div>),
          // gst_rate_name: (<div className="report_blank_data"></div>),
        }) 
      }
      if(generalLedgerData[i]?.reports.length !== 0){
        let netMovement = generalLedgerData[i]?.net_total;
        items.push({
          key: generalLedgerData[i]?.id ? `${generalLedgerData[i]?.id}-net-movement` : "",
          date: (<div className="general-ledger-report-heading general-ledger-report-total-body report_blank_data">
            <div> <span className="general-ledger-report-heading-total">Net</span> <span>Movement</span> </div>
          </div>),
          net_amount: (<div className=""></div>),
          description: (<div className="report_blank_data"></div>),
          contact: (<div className="report_blank_data"></div>),
          source: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data report-total">{generalLedgerData[i]?.debit_total > generalLedgerData[i]?.credit_total ? `$${formatNumber(Math.abs(netMovement))}` : ''}</div>),
          credit: (<div className="report_blank_data report-total">{generalLedgerData[i]?.debit_total < generalLedgerData[i]?.credit_total ? `$${formatNumber(Math.abs(netMovement))}` : ''}</div>),
          gst_amount: (<div className=""></div>),
          // gst_rate: (<div className="report_blank_data"></div>),
          // gst_rate_name: (<div className="report_blank_data"></div>),
        }) 
      }
      if(generalLedgerData[i]?.reports.length !== 0 && i < (generalLedgerData?.length - 1)){
        items.push({
          key: generalLedgerData[i]?.id ? `${generalLedgerData[i]?.id}-blank-row` : "",
          date: (<div className="report_blank_data"></div>),
          net_amount: (<div className="report_blank_data"></div>),
          description: (<div className="report_blank_data"></div>),
          contact: (<div className="report_blank_data"></div>),
          source: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data"></div>),
          credit: (<div className="report_blank_data"></div>),
          gst_amount: (<div className="report_blank_data"></div>),
          // gst_rate: (<div className="report_blank_data"></div>),
          // gst_rate_name: (<div className="report_blank_data"></div>),
        }) 
      }
    }
    return items;
  };
  const generalLedgerReportData = generalLedgerDataGenerator(generalLedgerData?.length);

  useEffect(() => {
    fetchGeneralLedgerData();
  }, [fetchGeneralLedgerData]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.date?.props?.className === "general-ledger-report-heading general-ledger-report-total-body report_blank_data" ? {"colSpan" : "4"} :
        row?.date?.props?.className === "general-ledger-report-heading" ? {"colSpan" : "8"} : row?.date?.props?.className === "report_blank_data" ? {"colSpan" : "8"} :""
      ),
      headerStyle: () => {
        return { width: '8%' };
      }
    },
    {
      dataField: "source",
      text: "Source",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.source?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '12%' };
      }
    },
    {
      dataField: "contact",
      text: "Contact",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.contact?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '10%' };
      }
    },
    {
      dataField: "description",
      text: "Description",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.description?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '34%' };
      }
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.debit?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.credit?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    {
      dataField: "gst_amount",
      text: "GST",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.gst_amount?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    {
      dataField: "net_amount",
      text: "Net Amount",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.net_amount?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    // {
    //   dataField: "gst_rate",
    //   text: "GST Rate",
    //   align: "right",
    //   // sort: true,
    // },
    // {
    //   dataField: "gst_rate_name",
    //   text: "GST Rate Name",
    //   align: "right",
    //   // sort: true,
    // },
  ];

  const handleExportReport = async (format) => {
    try {
      setExportLoading(true);
      const { data } = await get(
        `${API.GENERAL_LEDGER_REPORT}?start_date=${startDate ? startDate : ""}&end_date=${
          endDate ? endDate : ""
        }&export=1&format=${format}&client_business_id=${businessId}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "trial_balance_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  // const handleExportGeneralLedgerReport = async (format) => {
  //   try {
  //     setExportGeneralLedgerReportLoading(true);
  //     const { data } = await get(
  //       `${API.LEDGER_ENTRY_REPORT}?start_month=${startDate ? startDate : ""}&end_month=${
  //         endDate ? endDate : ""
  //       }&export=1&format=${format}&client_business_id=${businessId}`
  //     );
  //     const export_report_path = data?.data?.file;
  //     if (export_report_path) {
  //       var a = document.createElement("a");
  //       a.href = export_report_path;
  //       var file = export_report_path.split("/");
  //       a.setAttribute(
  //         "download",
  //         file[file.length - 1] || "trial_balance_report.pdf"
  //       );
  //       a.setAttribute("target", "_blank");
  //       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //       a.click();
  //       a.remove();
  //       setExportGeneralLedgerReportLoading(false);
  //     }
  //   } catch (e) {
  //     setExportGeneralLedgerReportLoading(false);
  //     const errors = e.response?.data?.errors;
  //     Object.keys(errors).forEach((key) => {
  //       toast.error(errors[key][0]);
  //     });
  //   }
  // };

  const handleDateRange = (e) => {
    setDateValue(e);
    setDateRangeReport(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"General Ledger Report"}
          />
          <div className="content-details">
            {/* <Spin spinning={loading} tip="Loading..."> */}
            <div className="table-top-btn report-header-row">
              <div className="report-name report-period-name">
                <span> General Ledger 
                {startDate && endDate && (` For the period from ${startDate} to ${endDate}`)}
                </span>
              </div>
              <div className="table-btn">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportLoading
                      ? "Loading…"
                      : "Export GL"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleExportReport("pdf")}>
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleExportReport("xlsx")}>
                      Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportGeneralLedgerReportLoading ? "Loading…" : "Export Ledger Transactions"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleExportGeneralLedgerReport("pdf")}>
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleExportGeneralLedgerReport("xlsx")}>
                      Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
              <div
                className="summary-filter"
                style={{
                  padding: "0",
                  border: "none",
                  marginTop: "0px",
                  marginLeft: 20,
                }}
              >
                <Form>
                  <DateRangePicker
                    placeholder="Select Date Range"
                    cleanable={false}
                    onChange={(e) => handleDateRange(e)}
                    format="dd-MM-yyyy"
                    value={dateValue}
                    placement="bottomEnd"
                    ranges={dateFilterRange}
                    locale={{ok: "Apply"}}
                    // shouldDisableDate={afterToday()}
                  />
                </Form>
              </div>
            </div>
            
            <div className="">
              {generalLedgerLoading && <Spin />}
              <BootstrapTable
                keyField="key"
                remote
                data={generalLedgerReportData}
                columns={columns}
                noDataIndication="No Data Found"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralLedgerReport;
