import { ErrorMessage, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import BackButton from '../../../../components/Form/BackButton';
import { getBusinessData } from '../../components/BusinessHelper';
import { checkIfFileExists, formatABN, formatName, formatNumber } from '../../../common/Misc';
import { API, fileUpload, get, imageDefaultPrefixPath, metaTitle } from '../../../../config';
import ReactInputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { DatePicker } from 'rsuite';
import CreatableSelect from 'react-select/creatable';
import AddNewContactModal from '../../CashFlow/components/AddNewContactModal';
import { Button, Modal } from 'react-bootstrap';
import Spin from '../../../common/Spin';
import TitleBar from '../../../common/TitleBar';

const AddReceipt = () => {
  // const today = new Date();
  const location = useLocation();
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const lastReceiptNumber = location?.state?.lastReceiptNumber;
  const numberOption = location?.state?.numberOption;
  const [businessData, setBusinessData] = useState();
  const [receiptNumber, setReceiptNumber] = useState(
    lastReceiptNumber ? lastReceiptNumber : 'R000001'
  );
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [loadingInvoiceList, setLoadingInvoiceList] = useState(false);
  const [paymentTypelist, setPaymentTypelist] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentDefault, setPaymentDefault] = useState();
  // const [date, setDate] = useState(today);

  const [loadingClientList, setLoadingClientList] = useState(false);
  const [allContactList, setAllContactList] = useState();
  const [addNewContact, setAddNewContact] = useState(false);
  const [newContact, setNewContact] = useState();
  const [newContactLastName, setNewContactLastName] = useState('');
  const [loadingUserList, setLoadingUserList] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [loadingTemplateList, setLoadingTemplateList] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [showSend, setShowSend] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [finalValues, setFinalValues] = useState();
  const navigate = useNavigate();

  const toggleSendModal = (values) => {
    setShowSend(!showSend);
    setFinalValues(values);
  };

  const togglePreviewModal = (values) => {
    setShowPreview(!showPreview);
    setFinalValues(values);
  };

  const toggleDraftModal = (values) => {
    setShowDraft(!showDraft);
    setFinalValues(values);
  };

  const fetchBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
  }, [businessId]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  const clientID = businessData?.client_id
    ? businessData?.client_id
    : localStorage.getItem('sme-clientId');

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  useEffect(() => {
    window.document.title = `${metaTitle} | Add Receipts`;
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const modeList = [
    {
      label: 'Contact',
      value: 0
    },
    {
      label: 'Invoice',
      value: 1
    }
  ];

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const defaultEntryData = [
    {
      id: 1,
      receipt_number: lastReceiptNumber ? lastReceiptNumber : 'R000001',
      invoice_id: '',
      invoice: '',
      invoice_number: '',
      payment_type: '',
      invoice_total: '',
      amount_paid: '',
      amount_due: '',
      payment_date: new Date(),
      received_by: ''
    }
  ];
  const [finalData, setFinalData] = useState(defaultEntryData);
  const newEntryData = {
    id: finalData?.slice(-1)[0]?.id + 1,
    receipt_number:
      'R' +
      padWithLeadingZeros(Number(finalData?.slice(-1)?.[0]?.receipt_number?.split('R')[1]) + 1, 6),
    invoice_id: '',
    invoice: '',
    invoice_number: '',
    payment_type: '',
    invoice_total: '',
    amount_paid: '',
    amount_due: '',
    payment_date: new Date(),
    received_by: ''
  };
  const handleAddEntry = (data, index, mode, invoice_id) => {
    if (data[index].amount_paid) {
      if (mode === 1 && invoice_id) {
        const newEntryData = {
          id: finalData.length + 1,
          invoice_id: invoice_id,
          invoice: invoiceOptions?.find((m) => m.value === invoice_id),
          receipt_number:
            'R' +
            padWithLeadingZeros(
              Number(finalData?.slice(-1)?.[0]?.receipt_number?.split('R')[1]) + 1,
              6
            ),
          invoice_number: data[index].invoice_number,
          payment_type: '',
          invoice_total: data[index].invoice_total,
          amount_paid: '',
          amount_due: Number(
            Number(data[index].amount_due) - Number(data[index].amount_paid)
          ).toFixed(2),
          payment_date: new Date(),
          received_by: ''
        };
        setFinalData([...data, newEntryData]);
      } else {
        setFinalData([...data, newEntryData]);
      }
    } else {
      toast.warn('Before Adding new item add the amount paid value.');
    }
  };

  const handleDeleteEntry = (data, index) => {
    let dataDeletedInvoiceId = data[index]?.invoice_id;
    data.splice(index, 1);
    let invoiceItemData = data?.filter((i) => i.invoice_id === dataDeletedInvoiceId);
    for (let k = 0; k < invoiceItemData?.length; k++) {
      for (let j = 0; j < data?.length; j++) {
        if (
          data[j]?.invoice_id === invoiceItemData[k]?.invoice_id &&
          data[j]?.id === invoiceItemData[k]?.id
        ) {
          if (k === 0) {
            data[j].amount_due = Number(invoiceItemData[k]?.amount_due);
          } else {
            data[j].amount_due =
              Number(invoiceItemData[k - 1]?.amount_due) -
              Number(invoiceItemData[k - 1]?.amount_paid);
          }
        }
      }
    }

    for (let i = 0; i < data?.length; i++) {
      if (i === 0) {
        data[i].receipt_number = lastReceiptNumber ? lastReceiptNumber : 'R000001';
      } else {
        data[i].receipt_number =
          'R' + padWithLeadingZeros(Number(data[i - 1]?.receipt_number?.split('R')[1]) + 1, 6);
      }
    }
    setFinalData([...data]);
  };

  const handleInvoiceList = useCallback(async () => {
    try {
      setLoadingInvoiceList(true);
      get(`${API.GET_INVOICES}?client_business_id=${businessId}&limit=-1`).then((response) => {
        let invoicesList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.invoice_number +
                (item?.contact
                  ? ` (${formatName(item?.contact?.first_name, item?.contact?.last_name)})`
                  : ''),
              value: item?.id,
              ...item
            };
            return list;
          });

        invoicesList = invoicesList && invoicesList?.filter((i) => i.total_amount_due > 0);
        setInvoiceOptions(invoicesList ? invoicesList : []);
        setLoadingInvoiceList(false);
      });
    } catch (e) {
      setLoadingInvoiceList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleNewInvoiceSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        let sameInvoiceNumberDatas = finalData?.filter((x) => x.invoice_id === val?.value);
        if (sameInvoiceNumberDatas?.length > 0) {
          let amount_paid = 0;
          for (let j = 0; j < sameInvoiceNumberDatas?.length; j++) {
            amount_paid = amount_paid + sameInvoiceNumberDatas[j].amount_paid;
          }
          return {
            ...obj,
            invoice_id: val?.value,
            invoice: val,
            invoice_date: val?.date,
            invoice_number: val?.invoice_number,
            invoice_total: val?.total_payable_amount,
            amount_due: Number(Number(val?.total_amount_due) - Number(amount_paid)).toFixed(2)
          };
        } else {
          return {
            ...obj,
            invoice_id: val?.value,
            invoice: val,
            invoice_date: val?.date,
            invoice_number: val?.invoice_number,
            invoice_total: val?.total_payable_amount,
            amount_due: val?.total_amount_due
          };
        }
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleNewPaymentSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, payment_type: val?.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleAmountPaid = (e, index, finalData) => {
    let value = e.target.value;
    const newArr = finalData.map((obj, i) => {
      let amount_due = obj.amount_due;
      if (i === index) {
        if (value > Number(amount_due) || String(amount_due)?.includes('-')) {
          toast.error('amount paid should not be greater than still owning.');
          return {
            ...obj,
            amount_paid: ''
          };
        } else {
          return {
            ...obj,
            amount_paid: value ? Number(value) : ''
          };
        }
      }
      return obj;
    });
    setFinalData(newArr);
  };

  useEffect(() => {
    handleInvoiceList();
  }, [handleInvoiceList]);

  const fetchPaymentOptionData = useCallback(async () => {
    try {
      setLoading(true);
      await get(`${API.GET_INVOICE_SETTINGS}?client_business_id=${businessId}`).then((response) => {
        const keys = Object.keys(response?.data?.data?.payment_options);
        const templatesList =
          response &&
          response?.data?.data?.payment_options &&
          keys?.map((item) => {
            if (String(response?.data?.data?.payment_options[item]) === '1') {
              const list = {
                label:
                  item === 'gift_card'
                    ? 'Gift Card'
                    : item === 'ffr'
                    ? 'Fee From Refund'
                    : item.charAt(0).toUpperCase() + item.slice(1),
                value: item
              };
              return list;
            }
            return null;
          });

        setPaymentTypelist(templatesList ? templatesList?.filter((i) => i !== null) : []);
        setPaymentDefault(response?.data?.data?.payment_type_default);
        setLoading(false);
      });
    } catch (e) {
      setPaymentTypelist([]);
      setLoading(false);
    }
  }, [businessId]);

  useEffect(() => {
    fetchPaymentOptionData();
  }, [fetchPaymentOptionData]);

  const handleClientList = useCallback(async () => {
    try {
      setLoadingClientList(true);
      get(
        `${API.GET_INVOICE_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data?.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && {
            label: <div className="list-subHeading">Contractors</div>,
            options: contractorList
          },
          supplierList && {
            label: <div className="list-subHeading">Suppliers</div>,
            options: supplierList
          },
          customerList && {
            label: <div className="list-subHeading">Customers</div>,
            options: customerList
          },
          { label: <strong>+ Add New Contact</strong>, value: 'new' }
        ];

        setAllContactList(allContactListing ? allContactListing : []);

        setLoading(false);
        setLoadingClientList(false);
      });
    } catch (e) {
      setLoadingClientList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  useEffect(() => {
    handleClientList();
  }, [handleClientList]);

  const handleNewClientList = useCallback(async () => {
    try {
      setLoadingClientList(true);
      get(
        `${API.GET_INVOICE_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && {
            label: <div className="list-subHeading">Contractors</div>,
            options: contractorList
          },
          supplierList && {
            label: <div className="list-subHeading">Suppliers</div>,
            options: supplierList
          },
          customerList && {
            label: <div className="list-subHeading">Customers</div>,
            options: customerList
          },
          { label: <strong>+ Add New Contact</strong>, value: 'new' }
        ];

        setAllContactList(allContactListing ? allContactListing : []);
        setLoading(false);
        setLoadingClientList(false);
      });
    } catch (e) {
      setLoadingClientList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleInvoiceSelection = async (e) => {
    try {
      setLoadingInvoiceList(true);
      get(
        `${API.CONTACT_INVOICE}?client_business_id=${businessId}&limit=-1&contact_id=${e?.value}`
      ).then((response) => {
        const invoicesList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.invoice_number +
                (item?.contact
                  ? ` (${formatName(item?.contact?.first_name, item?.contact?.last_name)})`
                  : ''),
              value: item?.id,
              ...item
            };
            return list;
          });
        setInvoiceOptions(invoicesList ? invoicesList : []);
        setLoadingInvoiceList(false);
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoadingInvoiceList(false);
      } else {
        toast.error(error?.message);
        setLoadingInvoiceList(false);
      }
    }
  };

  const handleUserList = useCallback(async () => {
    try {
      setLoadingUserList(true);
      get(
        `${clientID ? API.GET_CLIENT_USER : API.GET_USER}?client_business_id=${businessId}${
          clientID ? `&client_id=${clientID}` : ''
        }`
      ).then((response) => {
        if (clientID) {
          const officesList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const name = formatName(item?.first_name, item?.last_name);
              const id = item?.id;
              const items = { name, id };
              const { name: label, id: value, ...rest } = items;
              return { label, value, ...rest };
            });
          setUserOptions(officesList ? officesList : []);
        } else {
          const officesList =
            response &&
            response?.data?.data?.users?.data?.length > 0 &&
            response?.data?.data?.users?.data?.map((item) => {
              const name = formatName(item?.first_name, item?.last_name);
              const id = item?.id;
              const items = { name, id };
              const { name: label, id: value, ...rest } = items;
              return { label, value, ...rest };
            });
          setUserOptions(officesList ? officesList : []);
        }
        setLoadingUserList(false);
      });
    } catch (e) {
      setLoadingUserList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [clientID, businessId]);

  useEffect(() => {
    handleUserList();
  }, [handleUserList]);

  const handelNewDateSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, payment_date: val };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleNewReceiptedSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, received_by: val?.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const saveDisabled = (values) => {
    if (values && values?.length >= 1) {
      const checkData = values?.map((item, index) => {
        if (item?.invoice_id && item?.payment_date && item?.received_by) {
          return false;
        } else {
          return true;
        }
      });
      return checkData?.find((item) => item === true) ? true : false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (value) => {
    const formData = new FormData();
    formData.append('client_business_id', businessId);
    formData.append('template_id', value?.template ? value?.template?.value : '');
    formData.append('receipt_number', value?.receipt_number ? value?.receipt_number : '');
    formData.append('send', 0);

    finalData?.forEach((item, index) => {
      if (item) {
        formData.append(
          `invoice_payments[${index}][receipt_number]`,
          item?.receipt_number ? item?.receipt_number : ''
        );
        formData.append(`invoice_payments[${index}][is_draft]`, showSend ? 1 : 0);
        formData.append(
          `invoice_payments[${index}][invoice_id]`,
          item?.invoice_id ? item?.invoice_id : ''
        );
        formData.append(
          `invoice_payments[${index}][payment_type]`,
          item?.payment_type
            ? item?.payment_type
            : paymentTypelist && paymentTypelist.find((i) => i.value === paymentDefault)?.value
        );
        formData.append(
          `invoice_payments[${index}][payment_amount]`,
          item?.amount_paid ? item?.amount_paid : ''
        );
        formData.append(
          `invoice_payments[${index}][amount_due]`,
          item?.amount_due ? item?.amount_due : ''
        );
        formData.append(
          `invoice_payments[${index}][payment_date]`,
          item?.payment_date
            ? String(item?.payment_date)?.includes('-')
              ? item?.payment_date
              : `${moment(item?.payment_date).format('DD/MM/YYYY')?.split('/')?.join('-')}`
            : ''
        );
        formData.append(
          `invoice_payments[${index}][received_by]`,
          item?.received_by ? item?.received_by : ''
        );
      }
    });

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.RECEIPTS_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate(-1);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleSendEmailReceipt = async (value, send) => {
    const formData = new FormData();
    formData.append('client_business_id', businessId);
    formData.append('template_id', value?.template ? value?.template?.value : '');
    formData.append('receipt_number', value?.receipt_number ? value?.receipt_number : '');
    if (send) {
      formData.append('send', send);
    }

    finalData?.forEach((item, index) => {
      if (item) {
        formData.append(
          `invoice_payments[${index}][receipt_number]`,
          item?.receipt_number ? item?.receipt_number : ''
        );
        formData.append(`invoice_payments[${index}][is_draft]`, showSend ? 1 : 0);
        formData.append(
          `invoice_payments[${index}][invoice_id]`,
          item?.invoice_id ? item?.invoice_id : ''
        );
        formData.append(
          `invoice_payments[${index}][payment_type]`,
          item?.payment_type
            ? item?.payment_type
            : paymentTypelist && paymentTypelist.find((i) => i.value === paymentDefault)?.value
        );
        formData.append(
          `invoice_payments[${index}][payment_amount]`,
          item?.amount_paid ? item?.amount_paid : ''
        );
        formData.append(
          `invoice_payments[${index}][amount_due]`,
          item?.amount_due ? item?.amount_due : ''
        );
        formData.append(
          `invoice_payments[${index}][payment_date]`,
          item?.payment_date
            ? String(item?.payment_date)?.includes('-')
              ? item?.payment_date
              : `${moment(item?.payment_date).format('DD/MM/YYYY')?.split('/')?.join('-')}`
            : ''
        );
        formData.append(
          `invoice_payments[${index}][received_by]`,
          item?.received_by ? item?.received_by : ''
        );
      }
    });

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.RECEIPTS_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate(-1);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleTemplateList = useCallback(async () => {
    try {
      setLoadingTemplateList(true);
      get(`${API.INVOICE_SETTINGS}?client_business_id=${businessId}&limit=-1&type=receipt`).then(
        (response) => {
          const templatesList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const list = {
                label: item?.settings?.name,
                value: item?.id,
                settings: item?.settings,
                is_default: item?.is_default
              };
              return list;
            });
          setTemplateOptions(templatesList ? templatesList : []);
          setLoadingTemplateList(false);
        }
      );
    } catch (e) {
      setLoadingTemplateList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  useEffect(() => {
    handleTemplateList();
  }, [handleTemplateList]);

  const termData = (invoice) => {
    var date1 = invoice?.date?.includes('-')
      ? new Date(
          String(invoice?.date)?.split('-')[2],
          String(invoice?.date)?.split('-')[1] - 1,
          String(invoice?.date)?.split('-')[0]
        )
      : '';
    var date2 = invoice?.due_date?.includes('-')
      ? new Date(
          String(invoice?.due_date)?.split('-')[2],
          String(invoice?.due_date)?.split('-')[1] - 1,
          String(invoice?.due_date)?.split('-')[0]
        )
      : '';
    var Difference_In_Time = date2?.getTime() - date1?.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
  };

  return (
    <>
      <Formik
        initialValues={{
          receipt_number: receiptNumber,
          contact_id: newContact ? newContact : '',
          // email_to: '',
          invoice_global_id: '',
          mode: modeList[0],
          template: templateOptions ? templateOptions?.find((i) => i.is_default === 1) : ''
          // payment_date: moment(today).format('DD-MM-YYYY')
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        // validationSchema={}
      >
        {({ values, setErrors, errors, handleChange, handleSubmit, setFieldValue, handleBlur }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="cms-page">
                <div className="page-content-block">
                  <div className="full-content-block">
                    {/* <h1 className="page-title">
                      <BackButton />
                      Add Receipt
                      <div className="page-title-right">
                        {BusinessLogo && (
                          <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />
                        )}{' '}
                        {BusinessName}
                      </div>
                    </h1> */}
                    <TitleBar label={'Add Receipt'} BusinessName={BusinessName} />
                    <div className="content-details" style={{ padding: '0px' }}>
                      <div className="content-block" style={{ width: 'auto', margin: '15px' }}>
                        <div className="content-details-header">
                          <h6>Basic Detail</h6>
                        </div>
                        <div className="invoice-detail-body">
                          <div className="form-group row">
                            {finalData.length === 1 && (
                              <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                                <div className="">
                                  <span className="invoice-title">
                                    <span className="required">*</span>Receipt Number :
                                  </span>
                                  <div className="half-width">
                                    <ReactInputMask
                                      type="text"
                                      mask="R999999"
                                      maskChar={null}
                                      className="number-disable"
                                      placeholder="Enter Receipt Number"
                                      onChange={(e) => {
                                        setFieldValue(`receipt_number`, e?.target?.value);
                                        setReceiptNumber(e?.target?.value);
                                      }}
                                      onBlur={(e) => {
                                        let numberData = e?.target?.value?.split('R')[1];
                                        if (numberData?.length < 6) {
                                          let newNumber = padWithLeadingZeros(
                                            Number(numberData),
                                            6
                                          );
                                          setFieldValue(`receipt_number`, `R${newNumber}`);
                                          setReceiptNumber(`R${newNumber}`);
                                        } else {
                                          setFieldValue(`receipt_number`, `R${numberData}`);
                                          setReceiptNumber(`R${numberData}`);
                                        }
                                      }}
                                      disabled={Number(numberOption) === 1 ? true : false}
                                      value={values?.receipt_number ? values?.receipt_number : ''}
                                      name="receipt_number"
                                    />
                                    <span
                                      className="text-danger"
                                      style={{ color: 'red', fontSize: '20px' }}
                                    >
                                      <ErrorMessage name="receipt_number" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                              <div className="">
                                <span className="invoice-title">
                                  <span className="required">*</span>Email To :
                                </span>
                                <div className="half-width">
                                  <input
                                    type="text"
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                    name="email_to"
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    <ErrorMessage name="email_to" />
                                  </span>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                              <div className="">
                                <span className="invoice-title">
                                  <span className="required">*</span>Find By:
                                </span>
                                <div className="half-width">
                                  <Select
                                    name="mode"
                                    options={modeList ? modeList : []}
                                    onChange={(e) => {
                                      setFieldValue(`mode`, e ? e : '');
                                      if (e?.value === 1) {
                                        handleInvoiceList();
                                      }
                                    }}
                                    value={values?.mode ? values?.mode : ''}
                                    placeholder="Select Mode"
                                    classNamePrefix="selectbox"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: 'grey'
                                      }
                                    })}
                                    additional={{
                                      page: 1
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    <ErrorMessage name="mode" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            {values?.mode?.value === 1 ? (
                              <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                                <div className="">
                                  <span className="invoice-title">
                                    <span className="required">*</span>Invoice:
                                  </span>
                                  <div className="half-width">
                                    <div className="form-group">
                                      <Select
                                        placeholder="Select Invoice"
                                        isLoading={loadingInvoiceList}
                                        classNamePrefix="selectbox"
                                        options={invoiceOptions}
                                        onChange={(id) => {
                                          setFieldValue('invoice_global_id', id?.value);
                                          let amount_paid = 0;
                                          for (let m = 0; m < finalData?.length; m++) {
                                            finalData[m].invoice_id = id?.value;
                                            finalData[m].invoice = id;
                                            finalData[m].invoice_number = id?.invoice_number;
                                            finalData[m].invoice_total = id?.total_payable_amount;
                                            finalData[m].amount_due = Number(
                                              Number(
                                                Number(id?.total_amount_due) - Number(amount_paid)
                                              ).toFixed(2)
                                            );
                                            amount_paid = amount_paid + finalData[m].amount_paid;
                                          }
                                        }}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                            primary: 'grey'
                                          }
                                        })}
                                        isClearable={true}
                                        value={
                                          values?.invoice_global_id &&
                                          invoiceOptions?.length > 0 &&
                                          invoiceOptions?.find(
                                            (val) => val?.value === values?.invoice_global_id
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                                <div className="">
                                  <span className="invoice-title">
                                    <span className="required">*</span>Contact :
                                  </span>
                                  <div className="half-width">
                                    <CreatableSelect
                                      name="invoice_to"
                                      formatCreateLabel={(userInput) => (
                                        <>
                                          <strong>+ Add New Contact </strong>'{`${userInput}`}'
                                        </>
                                      )}
                                      options={allContactList ? allContactList : []}
                                      isLoading={loadingClientList}
                                      onChange={(e) => {
                                        if (e?.value === 'new' || e?.__isNew__ === true) {
                                          if (e?.__isNew__ === true) {
                                            setNewContactLastName(e?.label);
                                          } else {
                                            setNewContactLastName('');
                                          }
                                          setAddNewContact(true);
                                        } else {
                                          setFieldValue(`contact_id`, e ? e : '');
                                          setNewContact(e);
                                          handleInvoiceSelection(e);
                                        }
                                      }}
                                      value={values?.contact_id ? values?.contact_id : ''}
                                      placeholder="Select Contact"
                                      classNamePrefix="selectbox"
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary: 'grey'
                                        }
                                      })}
                                      additional={{
                                        page: 1
                                      }}
                                    />
                                    <span
                                      className="text-danger"
                                      style={{ color: 'red', fontSize: '20px' }}
                                    >
                                      <ErrorMessage name="contact_id" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                              <div className="">
                                <span className="invoice-title">
                                  <span className="required">*</span>Layout :
                                </span>
                                <div className="">
                                  <span>
                                    <Select
                                      name="template"
                                      style={{ width: '100%' }}
                                      options={templateOptions ? templateOptions : []}
                                      onChange={(e) => {
                                        setFieldValue(`template`, e ? e : '');
                                      }}
                                      isLoading={loadingTemplateList}
                                      value={values?.template ? values?.template : ''}
                                      placeholder="Select Layout"
                                      classNamePrefix="selectbox"
                                      onBlur={handleBlur}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary: 'grey'
                                        }
                                      })}
                                    />
                                  </span>
                                  <span
                                    className="text-danger"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    <ErrorMessage name="template" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-with-footer">
                            <div className="invoice-table custom-table tab-table">
                              {/* {loading && <Spin />} */}
                              <div className="react-bootstrap-table">
                                <table className="table table-bordered receipt-table">
                                  <thead>
                                    <tr>
                                      {finalData?.length > 1 && <th>Receipt No.</th>}
                                      <th>Invoice</th>
                                      <th>Invoice No.</th>
                                      <th>Payment Type</th>
                                      <th>Payment Date</th>
                                      <th>Received By</th>
                                      <th>Invoice Total</th>
                                      <th>Still Outstanding</th>
                                      <th>Amount Paid</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {finalData &&
                                      finalData?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            {finalData?.length > 1 && (
                                              <td style={{ width: '10%' }}>
                                                <div className="form-group">
                                                  <ReactInputMask
                                                    type="text"
                                                    mask="R999999"
                                                    maskChar={null}
                                                    className="number-disable"
                                                    placeholder="Enter Receipt Number"
                                                    disabled={true}
                                                    value={
                                                      item?.receipt_number
                                                        ? item?.receipt_number
                                                        : ''
                                                    }
                                                    name="receipt_number"
                                                  />
                                                </div>
                                              </td>
                                            )}
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <Select
                                                  placeholder="Select Invoice"
                                                  isLoading={loadingInvoiceList}
                                                  classNamePrefix="selectbox"
                                                  options={invoiceOptions}
                                                  onChange={(id) => {
                                                    handleNewInvoiceSelection(id, index, finalData);
                                                  }}
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: 'grey'
                                                    }
                                                  })}
                                                  isClearable={
                                                    values?.mode?.value === 1 ? false : true
                                                  }
                                                  isDisabled={
                                                    values?.mode?.value === 1 ? true : false
                                                  }
                                                  value={
                                                    item?.invoice_id &&
                                                    invoiceOptions?.length > 0 &&
                                                    invoiceOptions?.find(
                                                      (val) => val?.value === item?.invoice_id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  name="invoice_number"
                                                  placeholder="Invoice Number"
                                                  readOnly
                                                  disabled={true}
                                                  value={
                                                    item?.invoice_number ? item?.invoice_number : ''
                                                  }
                                                  style={{
                                                    border: '1px solid #d9d9d9',
                                                    padding: '5px 15px',
                                                    fontSize: '14px'
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <Select
                                                  name="payment_type"
                                                  onChange={(id) => {
                                                    handleNewPaymentSelection(id, index, finalData);
                                                  }}
                                                  value={
                                                    item?.payment_type
                                                      ? paymentTypelist?.length > 0 &&
                                                        paymentTypelist?.find(
                                                          (val) => val?.value === item?.payment_type
                                                        )
                                                      : paymentTypelist &&
                                                        paymentTypelist.find(
                                                          (i) => i.value === paymentDefault
                                                        )
                                                  }
                                                  options={paymentTypelist}
                                                  isLoading={loading}
                                                  placeholder="Select Payment Type"
                                                  classNamePrefix="selectbox"
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '5%' }}>
                                              <div className="invoice-date-body">
                                                <DatePicker
                                                  cleanable={false}
                                                  onChange={(e) =>
                                                    handelNewDateSelection(
                                                      moment(e, 'DD-MM-YYYY').format('DD-MM-YYYY'),
                                                      index,
                                                      finalData
                                                    )
                                                  }
                                                  format="dd-MM-yyyy"
                                                  value={
                                                    item?.payment_date !== null
                                                      ? String(item?.payment_date)?.includes('-')
                                                        ? new Date(
                                                            String(item?.payment_date)?.split(
                                                              '-'
                                                            )[2],
                                                            String(item?.payment_date)?.split(
                                                              '-'
                                                            )[1] - 1,
                                                            String(item?.payment_date)?.split(
                                                              '-'
                                                            )[0]
                                                          )
                                                        : new Date(item?.payment_date)
                                                      : new Date()
                                                  }
                                                  placement="autoVerticalStart"
                                                  disabledDate={(date) =>
                                                    moment(date).isAfter(moment())
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <Select
                                                  placeholder="Select User"
                                                  isLoading={loadingUserList}
                                                  classNamePrefix="selectbox"
                                                  options={userOptions}
                                                  onChange={(id) => {
                                                    handleNewReceiptedSelection(
                                                      id,
                                                      index,
                                                      finalData
                                                    );
                                                  }}
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: 'grey'
                                                    }
                                                  })}
                                                  value={
                                                    item?.received_by &&
                                                    userOptions?.length > 0 &&
                                                    userOptions?.find(
                                                      (val) => val?.value === item?.received_by
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  name="invoice_total"
                                                  placeholder="Invoice Total"
                                                  readOnly
                                                  disabled={true}
                                                  value={
                                                    item?.invoice_total
                                                      ? `${formatNumber(
                                                          Number(item?.invoice_total)
                                                        )}`
                                                      : ''
                                                  }
                                                  style={{
                                                    border: '1px solid #d9d9d9',
                                                    padding: '5px 15px',
                                                    fontSize: '14px'
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  name="amount_due"
                                                  placeholder="Amount Due"
                                                  readOnly
                                                  disabled={true}
                                                  value={
                                                    item?.amount_due
                                                      ? `${formatNumber(Number(item?.amount_due))}`
                                                      : ''
                                                  }
                                                  style={{
                                                    border: '1px solid #d9d9d9',
                                                    padding: '5px 15px',
                                                    fontSize: '14px'
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                              <div className="form-group">
                                                <input
                                                  type="number"
                                                  name="amount_paid"
                                                  placeholder="Enter Amount"
                                                  onChange={(e) => {
                                                    handleAmountPaid(e, index, finalData);
                                                  }}
                                                  step=".01"
                                                  value={item?.amount_paid ? item?.amount_paid : ''}
                                                  onBlur={handleBlur}
                                                  rules={[
                                                    {
                                                      pattern: new RegExp(/^\d*\.?\d*$/),
                                                      message: 'Enter Valid Unit Price'
                                                    }
                                                  ]}
                                                  style={{
                                                    border: '1px solid #d9d9d9',
                                                    padding: '5px 15px',
                                                    fontSize: '14px'
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td style={{ textAlign: 'center', width: '100px' }}>
                                              {index + 1 === finalData?.length ? (
                                                <>
                                                  {(Number(item?.amount_due) !==
                                                    Number(item?.amount_paid) ||
                                                    (item?.amount_due === '' &&
                                                      item?.amount_paid === '')) && (
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        handleAddEntry(
                                                          finalData,
                                                          index,
                                                          values?.mode?.value,
                                                          values?.invoice_global_id
                                                        );
                                                      }}
                                                      icon={faAdd}
                                                      size="1x"
                                                    />
                                                  )}
                                                  {index > 0 && (
                                                    <>
                                                      {(Number(item?.amount_due) !==
                                                        Number(item?.amount_paid) ||
                                                        (item?.amount_due === '' &&
                                                          item?.amount_paid === '')) && (
                                                        <>
                                                          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                                        </>
                                                      )}
                                                      <FontAwesomeIcon
                                                        onClick={() => {
                                                          handleDeleteEntry(finalData, index);
                                                        }}
                                                        icon={faTrash}
                                                        size="1x"
                                                      />
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <FontAwesomeIcon
                                                  onClick={() => {
                                                    handleDeleteEntry(finalData, index);
                                                  }}
                                                  icon={faTrash}
                                                  size="1x"
                                                />
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="invoice-save">
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ marginRight: '10px' }}
                              onClick={() => togglePreviewModal(values)}
                              disabled={saveDisabled(finalData)}
                            >
                              Preview
                            </Button>
                            {/* <Button
                              variant="contained"
                              color="secondary"
                              style={{ marginRight: '10px' }}
                              onClick={() => toggleDraftModal(values)}
                              disabled={saveDisabled(finalData)}
                            >
                              Save as Draft
                            </Button> */}
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ marginRight: '10px' }}
                              onClick={() => toggleSendModal(values)}
                              disabled={saveDisabled(finalData)}
                            >
                              POST
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {addNewContact && (
                <AddNewContactModal
                  addNewContact={addNewContact}
                  handleAddNewContact={handleAddNewContact}
                  handleContactList={handleNewClientList}
                  businessId={businessId}
                  setNewContact={setNewContact}
                  // setFieldValue1={setFieldValue}
                  newContactLastName={newContactLastName}
                />
              )}
              {showSend && (
                <Modal
                  size="lg"
                  show={showSend}
                  onHide={toggleSendModal}
                  dialogClassName="modal-50w small-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  className="business-section"
                  centered
                >
                  <Modal.Header className="mb-0" closeButton>
                    Send Receipt
                  </Modal.Header>
                  <Modal.Body>
                    {loading && <Spin />}
                    <div className="modal-body">Do you want to send receipt via email?</div>
                    <div className="modal-footer">
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => {
                          handleSendEmailReceipt(finalValues, 1);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        onClick={() => {
                          handleSubmit(finalValues);
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {showDraft && (
                <Modal
                  size="lg"
                  show={showDraft}
                  onHide={toggleDraftModal}
                  dialogClassName="modal-50w small-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  className="business-section"
                  centered
                >
                  <Modal.Header className="mb-0" closeButton>
                    Draft Receipt
                  </Modal.Header>
                  <Modal.Body>
                    {loading && <Spin />}
                    <div className="modal-body">Do you want to draft receipt?</div>
                    <div className="modal-footer">
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => {
                          handleSubmit(finalValues);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        onClick={() => {
                          toggleDraftModal();
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {showPreview && (
                <Modal
                  size="lg"
                  show={showPreview}
                  onHide={togglePreviewModal}
                  dialogClassName="modal-50w larg-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  className="business-section"
                  centered
                >
                  <Modal.Header className="mb-0" closeButton>
                    View Receipt
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      padding: '15px'
                    }}
                  >
                    {loading ? (
                      <Spin />
                    ) : (
                      finalData?.map((item, index) => (
                        <>
                          <div className="receipt-sequence">
                            {index !== 0 && <hr style={{ borderTop: '2px solid black' }} />}
                            <span
                              style={{
                                padding: '5px',
                                border: '1px solid #ccc',
                                borderRadius: '6px'
                              }}
                            >
                              Receipt - {item?.receipt_number}
                            </span>
                          </div>
                          {finalValues?.template?.settings?.receipt_style === 0 ? (
                            <>
                              <div id="print-invoice">
                                <div
                                  className="boxed-invoice"
                                  style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
                                >
                                  <table
                                    style={{
                                      width: '100%',
                                      fontFamily: 'Poppins',
                                      fontSize: '12px'
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '50%' }}>
                                          {BusinessLogo && (
                                            <img
                                              src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                              alt=""
                                              style={{
                                                maxWidth: '95px',
                                                maxHeight: '80px',
                                                marginRight: '5px'
                                              }}
                                            />
                                          )}{' '}
                                          {BusinessName}
                                        </td>
                                        <td style={{ textAlign: 'end', width: '50%' }}>
                                          <p>
                                            {BusinessName}
                                            {item?.invoice?.office ? (
                                              <>
                                                <br />
                                                {item?.invoice?.office?.street
                                                  ? item?.invoice?.office?.street
                                                  : ''}
                                                <br />
                                                {item?.invoice?.office?.suburb
                                                  ? item?.invoice?.office?.suburb?.name
                                                  : ''}
                                                {item?.invoice?.office?.suburb && ' '}
                                                {item?.invoice?.office?.state
                                                  ? item?.invoice?.office?.state?.short_name
                                                    ? item?.invoice?.office?.state?.short_name
                                                    : item?.invoice?.office?.state?.name
                                                  : ''}
                                                {item?.invoice?.office?.state && ' '}
                                                {item?.invoice?.office?.postal_code
                                                  ? item?.invoice?.office?.postal_code
                                                  : ''}
                                              </>
                                            ) : (
                                              <>
                                                <br />
                                                {businessData?.street ? businessData?.street : ''}
                                                <br />
                                                {businessData?.suburb
                                                  ? businessData?.suburb?.name
                                                  : ''}
                                                {businessData?.suburb && ' '}
                                                {businessData?.state
                                                  ? businessData?.state?.short_name
                                                    ? businessData?.state?.short_name
                                                    : businessData?.state?.name
                                                  : ''}
                                                {businessData?.state && ' '}
                                                {businessData?.postal_code
                                                  ? businessData?.postal_code
                                                  : ''}
                                              </>
                                            )}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <hr
                                    style={{
                                      margin: 0,
                                      marginTop: '8px',
                                      marginBottom: '25px',
                                      borderTop: '1px solid black',
                                      width: '100%'
                                    }}
                                  />
                                  <table
                                    style={{
                                      width: '100%',
                                      fontFamily: 'Poppins',
                                      fontSize: '12px'
                                    }}
                                  >
                                    <tbody>
                                      <tr style={{ display: 'flex', gap: '15px' }}>
                                        <td
                                          style={{
                                            border: '1px solid black',
                                            padding: '15px',
                                            height: '100px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-evenly',
                                            width: '65%'
                                          }}
                                        >
                                          {finalValues?.template?.settings?.client_option
                                            ?.non_individual ? (
                                            <div>
                                              {finalValues?.template?.settings?.client_option
                                                ?.alternateName
                                                ? item?.invoice?.contact &&
                                                  item?.invoice?.contact?.display_name
                                                  ? item?.invoice?.contact?.display_name
                                                  : formatName(
                                                      item?.invoice?.contact?.first_name,
                                                      item?.invoice?.contact?.last_name
                                                    )
                                                : item?.invoice?.contact?.business_name
                                                ? item?.invoice?.contact?.business_name
                                                : formatName(
                                                    item?.invoice?.contact?.first_name,
                                                    item?.invoice?.contact?.last_name
                                                  )}
                                              <>
                                                {item?.invoice?.contact && (
                                                  <>
                                                    <p style={{ margin: '0px' }}>
                                                      {item?.invoice?.contact?.shipping_street
                                                        ? item?.invoice?.contact?.shipping_street
                                                        : item?.invoice?.contact?.street
                                                        ? item?.invoice?.contact?.street
                                                        : ''}
                                                    </p>
                                                    <p style={{ margin: '0px' }}>
                                                      {item?.invoice?.contact?.shipping_suburb
                                                        ? item?.invoice?.contact?.shipping_suburb
                                                            ?.name
                                                        : item?.invoice?.contact?.suburb
                                                        ? item?.invoice?.contact?.suburb?.name
                                                        : ''}
                                                      {(item?.invoice?.contact?.suburb ||
                                                        item?.invoice?.contact
                                                          ?.shipping_suburb) && ' '}
                                                      {item?.invoice?.contact?.shipping_state
                                                        ? item?.invoice?.contact?.shipping_state
                                                            ?.short_name
                                                          ? item?.invoice?.contact?.shipping_state
                                                              ?.short_name
                                                          : item?.invoice?.contact?.shipping_state
                                                              ?.name
                                                        : item?.invoice?.contact?.state
                                                        ? item?.invoice?.contact?.state?.short_name
                                                          ? item?.invoice?.contact?.state
                                                              ?.short_name
                                                          : item?.invoice?.contact?.state?.name
                                                        : ''}
                                                      {(item?.invoice?.contact?.state ||
                                                        item?.invoice?.contact?.shipping_state) && (
                                                        ' '
                                                      )}
                                                      {item?.invoice?.contact?.shipping_postal_code
                                                        ? item?.invoice?.contact
                                                            ?.shipping_postal_code
                                                        : item?.invoice?.contact?.postal_code
                                                        ? item?.invoice?.contact?.postal_code
                                                        : ''}
                                                    </p>
                                                    {/* <p>{item?.invoice?.contact}</p> */}
                                                  </>
                                                )}
                                              </>
                                            </div>
                                          ) : (
                                            <div>
                                              {finalValues?.template?.settings?.client_option
                                                ?.alternateName ? (
                                                item?.invoice?.contact &&
                                                item?.invoice?.contact?.display_name ? (
                                                  item?.invoice?.contact?.display_name
                                                ) : item?.invoice?.contact?.first_name ? (
                                                  <p style={{ margin: '0px' }}>
                                                    {item?.invoice?.contact?.first_name +
                                                      ' ' +
                                                      item?.invoice?.contact?.last_name}
                                                  </p>
                                                ) : (
                                                  <p style={{ margin: '0px' }}>
                                                    {item?.invoice?.contact?.last_name}
                                                  </p>
                                                )
                                              ) : item?.invoice?.contact &&
                                                item?.invoice?.contact?.first_name ? (
                                                <p style={{ margin: '0px' }}>
                                                  {item?.invoice?.contact?.first_name +
                                                    ' ' +
                                                    item?.invoice?.contact?.last_name}
                                                </p>
                                              ) : (
                                                <p style={{ margin: '0px' }}>
                                                  {item?.invoice?.contact?.last_name}
                                                </p>
                                              )}
                                              <>
                                                {item?.invoice?.contact && (
                                                  <>
                                                    <p style={{ margin: '0px' }}>
                                                      {item?.invoice?.contact?.street
                                                        ? item?.invoice?.contact?.street
                                                        : item?.invoice?.contact?.shipping_street
                                                        ? item?.invoice?.contact?.shipping_street
                                                        : ''}
                                                    </p>
                                                    <p style={{ margin: '0px' }}>
                                                      {item?.invoice?.contact?.suburb
                                                        ? item?.invoice?.contact?.suburb?.name
                                                        : item?.invoice?.contact?.shipping_suburb
                                                        ? item?.invoice?.contact?.shipping_suburb
                                                            ?.name
                                                        : ''}
                                                      {(item?.invoice?.contact?.suburb ||
                                                        item?.invoice?.contact
                                                          ?.shipping_suburb) && ' '}
                                                      {item?.invoice?.contact?.state
                                                        ? item?.invoice?.contact?.state?.short_name
                                                          ? item?.invoice?.contact?.state
                                                              ?.short_name
                                                          : item?.invoice?.contact?.state?.name
                                                        : item?.invoice?.contact?.shipping_state
                                                        ? item?.invoice?.contact?.shipping_state
                                                            ?.name
                                                        : ''}
                                                      {(item?.invoice?.contact?.state ||
                                                        item?.invoice?.contact?.shipping_state) && (
                                                        ' '
                                                      )}
                                                      {item?.invoice?.contact?.postal_code
                                                        ? item?.invoice?.contact?.postal_code
                                                        : item?.invoice?.contact
                                                            ?.shipping_postal_code
                                                        ? item?.invoice?.contact
                                                            ?.shipping_postal_code
                                                        : ''}
                                                    </p>
                                                    {/* <p>{item?.invoice?.contact}</p> */}
                                                  </>
                                                )}
                                              </>
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            border: '1px solid black',
                                            padding: '15px',
                                            textAlign: 'center',
                                            height: '100px',
                                            width: '35%'
                                          }}
                                        >
                                          <h6 style={{ margin: '0px', fontSize: '16px' }}>
                                            RECEIPT
                                          </h6>
                                          {/* <p
                                        style={{
                                          fontWeight: 'bold',
                                          margin: '0px',
                                          marginBottom: '9px',
                                          marginTop: '7px'
                                        }}
                                      >
                                        {item?.receipt_number}
                                      </p> */}
                                          <p
                                            style={{
                                              fontWeight: 'bold',
                                              margin: '0px',
                                              marginBottom: '9px',
                                              marginTop: '7px'
                                            }}
                                          >
                                            ABN: {businessData?.abn ? formatABN(businessData?.abn) : ''}
                                          </p>
                                          <p style={{ margin: '0px', fontWeight: 'bold' }}>
                                            Receipt Date:{item?.payment_date
                                              ? String(item?.payment_date)?.includes('-')
                                                ? item?.payment_date
                                                : `${moment(item?.payment_date)
                                                    .format('DD/MM/YYYY')
                                                    ?.split('/')
                                                    ?.join('-')}`
                                              : ''}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    style={{
                                      width: '100%',
                                      marginTop: '20px',
                                      border: '1px solid black',
                                      fontFamily: 'Poppins',
                                      borderSpacing: '0px'
                                    }}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          style={
                                            finalValues?.template?.settings?.secondary_color
                                              ? {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                }
                                              : {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background:
                                                    finalValues?.template?.settings?.primary_color
                                                }
                                          }
                                        >
                                          Invoice Date
                                        </th>
                                        <th
                                          style={
                                            finalValues?.template?.settings?.secondary_color
                                              ? {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                }
                                              : {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background:
                                                    finalValues?.template?.settings?.primary_color
                                                }
                                          }
                                        >
                                          Reference
                                        </th>
                                        <th
                                          style={
                                            finalValues?.template?.settings?.secondary_color
                                              ? {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                }
                                              : {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background:
                                                    finalValues?.template?.settings?.primary_color
                                                }
                                          }
                                        >
                                          Payment Type
                                        </th>
                                        <th
                                          style={
                                            finalValues?.template?.settings?.secondary_color
                                              ? {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                }
                                              : {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background:
                                                    finalValues?.template?.settings?.primary_color
                                                }
                                          }
                                        >
                                          Invoice Total
                                        </th>
                                        <th
                                          style={
                                            finalValues?.template?.settings?.secondary_color
                                              ? {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                }
                                              : {
                                                  padding: '8px',
                                                  borderRight: '1px solid black',
                                                  borderBottom: '1px solid black',
                                                  textAlign: 'inherit',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background:
                                                    finalValues?.template?.settings?.primary_color
                                                }
                                          }
                                        >
                                          Amount Paid
                                        </th>
                                        <th
                                          style={
                                            finalValues?.template?.settings?.secondary_color
                                              ? {
                                                  padding: '8px',
                                                  borderBottom: '1px solid black',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background: `transparent
                    linear-gradient(
                      180deg,
                      ${finalValues?.template?.settings?.secondary_color} 0%,
                      ${finalValues?.template?.settings?.primary_color} 100%
                    )
                    0% 0% no-repeat padding-box`
                                                }
                                              : {
                                                  padding: '8px',
                                                  borderBottom: '1px solid black',
                                                  color:
                                                    finalValues?.template?.settings?.font_color,
                                                  background:
                                                    finalValues?.template?.settings?.primary_color
                                                }
                                          }
                                        >
                                          Still Outstanding
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black'
                                          }}
                                        >
                                          {item?.invoice?.date}
                                        </td>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderBottom: '1px solid black',
                                            borderRight: '1px solid black'
                                          }}
                                        >
                                          {item?.invoice?.invoice_number}
                                        </td>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderBottom: '1px solid black',
                                            borderRight: '1px solid black'
                                          }}
                                        >
                                          {item?.payment_type
                                            ? item?.payment_type === 'gift_card'
                                              ? 'Gift Card'
                                              : item?.payment_type === 'ffr'
                                              ? 'Fee From Refund'
                                              : item?.payment_type.charAt(0).toUpperCase() +
                                                item?.payment_type.slice(1)
                                            : paymentTypelist.find(
                                                (i) => i.value === paymentDefault
                                              )?.label}
                                        </td>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderBottom: '1px solid black',
                                            borderRight: '1px solid black'
                                          }}
                                        >
                                          {item?.invoice?.total_payable_amount
                                            ? item?.invoice?.total_payable_amount.toFixed(2)
                                            : '0.00'}
                                        </td>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderBottom: '1px solid black',
                                            borderRight: '1px solid black'
                                          }}
                                        >
                                          {item?.amount_paid
                                            ? Number(item?.amount_paid).toFixed(2)
                                            : '0.00'}
                                        </td>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderBottom: '1px solid black'
                                          }}
                                        >
                                          {item?.amount_due
                                            ? Number(
                                                Number(item?.amount_due) - Number(item?.amount_paid)
                                              ).toFixed(2)
                                            : '0.00'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            height: '37px'
                                          }}
                                          colSpan={4}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {finalValues?.template?.settings?.sub_total_option
                                              ?.term ? (
                                              <p style={{ margin: '0px' }}>
                                                <span>
                                                  Terms:{' '}
                                                  {`${
                                                    String(item?.invoice?.date)?.includes('-')
                                                      ? termData(item?.invoice).toFixed(0) < 0
                                                        ? 0
                                                        : termData(item?.invoice).toFixed(0) - 1
                                                      : termData(item?.invoice).toFixed(0) < 0
                                                      ? 0
                                                      : termData(item?.invoice).toFixed(0)
                                                  } days`}
                                                </span>
                                              </p>
                                            ) : (
                                              ''
                                            )}
                                            {finalValues?.template?.settings?.sub_total_option
                                              ?.dueDate ? (
                                              <p style={{ margin: '0px' }}>
                                                <span>
                                                  Sent Date:{' '}
                                                  {item?.email_date ? item?.email_date : ''}
                                                </span>
                                              </p>
                                            ) : (
                                              ''
                                            )}
                                            {/* {values?.receipt_detail_option?.includes('Amounts') ? (
                                    <p style={{ margin: '0px' }}>
                                      <span>Amount Outstanding: $</span>
                                    </p>
                                  ) : (
                                    <></>
                                  )} */}
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            fontWeight: 'bold'
                                          }}
                                        >
                                          $
                                          {item?.amount_paid
                                            ? Number(item?.amount_paid).toFixed(2)
                                            : '0.00'}
                                        </td>
                                        <td style={{ padding: '8px', fontWeight: 'bold' }}>
                                          $
                                          {item?.amount_due
                                            ? Number(item?.amount_due).toFixed(2)
                                            : '0.00'}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            fontStyle: 'italic',
                                            textAlign: 'center'
                                          }}
                                        >
                                          {/* <p style={{ margin: '20px 0px 10px 0px' }}>
                                  Please detach the portion below and forward with your payment
                                </p> */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div id="print-invoice">
                                <div id="boxed-invoice">
                                  <table
                                    style={{
                                      width: '100%',
                                      fontFamily: 'Poppins',
                                      padding: '15px',
                                      fontSize: '12px'
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                                            <tbody>
                                              <tr>
                                                <td colSpan={3} style={{ textAlign: 'right' }}>
                                                  {BusinessLogo && (
                                                    <img
                                                      src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                                      alt=""
                                                      style={{
                                                        maxWidth: '95px',
                                                        maxHeight: '80px',
                                                        marginRight: '5px'
                                                      }}
                                                    />
                                                  )}{' '}
                                                  {BusinessName}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ width: '30%' }}>
                                                  <h2
                                                    style={{
                                                      fontSize: '20px',
                                                      paddingBottom: '12px'
                                                    }}
                                                  >
                                                    RECEIPT
                                                  </h2>
                                                  {finalValues?.template?.settings?.client_option
                                                    ?.non_individual ? (
                                                    <div>
                                                      {finalValues?.template?.settings
                                                        ?.client_option?.alternateName ? (
                                                        item?.invoice?.contact &&
                                                        item?.invoice?.contact?.display_name ? (
                                                          item?.invoice?.contact?.display_name
                                                        ) : item?.invoice?.contact?.first_name ? (
                                                          <p style={{ margin: '0px' }}>
                                                            {item?.invoice?.contact?.first_name +
                                                              ' ' +
                                                              item?.invoice?.contact?.last_name}
                                                          </p>
                                                        ) : (
                                                          <p style={{ margin: '0px' }}>
                                                            {item?.invoice?.contact?.last_name}
                                                          </p>
                                                        )
                                                      ) : item?.invoice?.contact?.business_name ? (
                                                        item?.invoice?.contact?.business_name
                                                      ) : item?.invoice?.contact?.first_name ? (
                                                        <p style={{ margin: '0px' }}>
                                                          {item?.invoice?.contact?.first_name +
                                                            ' ' +
                                                            item?.invoice?.contact?.last_name}
                                                        </p>
                                                      ) : (
                                                        <p style={{ margin: '0px' }}>
                                                          {item?.invoice?.contact?.last_name}
                                                        </p>
                                                      )}
                                                      <>
                                                        {item?.invoice?.contact && (
                                                          <>
                                                            <p style={{ margin: '0px' }}>
                                                              {item?.invoice?.contact
                                                                ?.shipping_street
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_street
                                                                : item?.invoice?.contact?.street
                                                                ? item?.invoice?.contact?.street
                                                                : ''}
                                                            </p>
                                                            <p style={{ margin: '0px' }}>
                                                              {item?.invoice?.contact
                                                                ?.shipping_suburb
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_suburb?.name
                                                                : item?.invoice?.contact?.suburb
                                                                ? item?.invoice?.contact?.suburb
                                                                    ?.name
                                                                : ''}
                                                              {(item?.invoice?.contact?.suburb ||
                                                                item?.invoice?.contact
                                                                  ?.shipping_suburb) && <br />}
                                                              {item?.invoice?.contact
                                                                ?.shipping_state
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_state?.short_name
                                                                  ? item?.invoice?.contact
                                                                      ?.shipping_state?.short_name
                                                                  : item?.invoice?.contact
                                                                      ?.shipping_state?.name
                                                                : item?.invoice?.contact?.state
                                                                ? item?.invoice?.contact?.state
                                                                    ?.short_name
                                                                  ? item?.invoice?.contact?.state
                                                                      ?.short_name
                                                                  : item?.invoice?.contact?.state
                                                                      ?.name
                                                                : ''}
                                                              {(item?.invoice?.contact?.state ||
                                                                item?.invoice?.contact
                                                                  ?.shipping_state) && <br />}
                                                              {item?.invoice?.contact
                                                                ?.shipping_postal_code
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_postal_code
                                                                : item?.invoice?.contact
                                                                    ?.postal_code
                                                                ? item?.invoice?.contact
                                                                    ?.postal_code
                                                                : ''}
                                                            </p>
                                                            {/* <p>{item?.invoice?.contact}</p> */}
                                                          </>
                                                        )}
                                                      </>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      {finalValues?.template?.settings
                                                        ?.client_option?.alternateName ? (
                                                        item?.invoice?.contact &&
                                                        item?.invoice?.contact?.display_name ? (
                                                          item?.invoice?.contact?.display_name
                                                        ) : item?.invoice?.contact?.first_name ? (
                                                          <p style={{ margin: '0px' }}>
                                                            {item?.invoice?.contact?.first_name +
                                                              ' ' +
                                                              item?.invoice?.contact?.last_name}
                                                          </p>
                                                        ) : (
                                                          <p style={{ margin: '0px' }}>
                                                            {item?.invoice?.contact?.last_name}
                                                          </p>
                                                        )
                                                      ) : item?.invoice?.contact &&
                                                        item?.invoice?.contact?.first_name ? (
                                                        <p style={{ margin: '0px' }}>
                                                          {item?.invoice?.contact?.first_name +
                                                            ' ' +
                                                            item?.invoice?.contact?.last_name}
                                                        </p>
                                                      ) : (
                                                        <p style={{ margin: '0px' }}>
                                                          {item?.invoice?.contact?.last_name}
                                                        </p>
                                                      )}
                                                      <>
                                                        {item?.invoice?.contact && (
                                                          <>
                                                            <p style={{ margin: '0px' }}>
                                                              {item?.invoice?.contact?.street
                                                                ? item?.invoice?.contact?.street
                                                                : item?.invoice?.contact
                                                                    ?.shipping_street
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_street
                                                                : ''}
                                                            </p>
                                                            <p style={{ margin: '0px' }}>
                                                              {item?.invoice?.contact?.suburb
                                                                ? item?.invoice?.contact?.suburb
                                                                    ?.name
                                                                : item?.invoice?.contact
                                                                    ?.shipping_suburb
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_suburb?.name
                                                                : ''}
                                                              {(item?.invoice?.contact?.suburb ||
                                                                item?.invoice?.contact
                                                                  ?.shipping_suburb) && <br />}
                                                              {item?.invoice?.contact?.state
                                                                ? item?.invoice?.contact?.state
                                                                    ?.short_name
                                                                  ? item?.invoice?.contact?.state
                                                                      ?.short_name
                                                                  : item?.invoice?.contact?.state
                                                                      ?.name
                                                                : item?.invoice?.contact
                                                                    ?.shipping_state
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_state?.name
                                                                : ''}
                                                              {(item?.invoice?.contact?.state ||
                                                                item?.invoice?.contact
                                                                  ?.shipping_state) && <br />}
                                                              {item?.invoice?.contact?.postal_code
                                                                ? item?.invoice?.contact
                                                                    ?.postal_code
                                                                : item?.invoice?.contact
                                                                    ?.shipping_postal_code
                                                                ? item?.invoice?.contact
                                                                    ?.shipping_postal_code
                                                                : ''}
                                                            </p>
                                                            {/* <p>{item?.invoice?.contact}</p> */}
                                                          </>
                                                        )}
                                                      </>
                                                    </div>
                                                  )}
                                                </td>
                                                <td style={{ width: '25%' }}></td>
                                                <td style={{ width: '45%', textAlign: 'right' }}>
                                                  <table
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    style={{ width: '100%' }}
                                                  >
                                                    <tr>
                                                      <td
                                                        style={{
                                                          verticalAlign: 'top',
                                                          textAlign: 'left',
                                                          paddingRight: '15px'
                                                        }}
                                                      >
                                                        <p>
                                                          <strong>Payment Date</strong>
                                                          <br />
                                                          {item?.payment_date
                                                            ? String(item?.payment_date)?.includes(
                                                                '-'
                                                              )
                                                              ? item?.payment_date
                                                              : `${moment(item?.payment_date)
                                                                  .format('DD/MM/YYYY')
                                                                  ?.split('/')
                                                                  ?.join('-')}`
                                                            : ''}
                                                        </p>
                                                        {finalValues?.template?.settings
                                                          ?.sub_total_option?.dueDate ? (
                                                          <p>
                                                            <strong>Sent Date</strong>
                                                            <br />
                                                            {item?.email_date
                                                              ? item?.email_date
                                                              : ''}
                                                          </p>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <p>
                                                          <strong>ABN</strong>
                                                          <br />
                                                          {businessData?.abn
                                                            ? formatABN(businessData?.abn)
                                                            : ''}
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: 'left',
                                                          verticalAlign: 'top'
                                                        }}
                                                      >
                                                        <div>
                                                          {BusinessName}
                                                          {item?.invoice?.office ? (
                                                            <>
                                                              <br />
                                                              {item?.invoice?.office?.street
                                                                ? item?.invoice?.office?.street
                                                                : ''}
                                                              <br />
                                                              {item?.invoice?.office?.suburb
                                                                ? item?.invoice?.office?.suburb
                                                                    ?.name
                                                                : ''}
                                                              {item?.invoice?.office?.suburb && ' '}
                                                              {item?.invoice?.office?.state
                                                                ? item?.invoice?.office?.state
                                                                    ?.short_name
                                                                  ? item?.invoice?.office?.state
                                                                      ?.short_name
                                                                  : item?.invoice?.office?.state
                                                                      ?.name
                                                                : ''}
                                                              {item?.invoice?.office?.state && ' '}
                                                              {item?.invoice?.office?.postal_code
                                                                ? item?.invoice?.office?.postal_code
                                                                : ''}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <br />
                                                              {businessData?.street
                                                                ? businessData?.street
                                                                : ''}
                                                              <br />
                                                              {businessData?.suburb
                                                                ? businessData?.suburb?.name
                                                                : ''}
                                                              {businessData?.suburb && ' '}
                                                              {businessData?.state
                                                                ? businessData?.state?.short_name
                                                                  ? businessData?.state?.short_name
                                                                  : businessData?.state?.name
                                                                : ''}
                                                              {businessData?.state && ' '}
                                                              {businessData?.postal_code
                                                                ? businessData?.postal_code
                                                                : ''}
                                                            </>
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          {finalValues?.template?.settings?.quote_detail_option
                                            ?.amount ? (
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              style={{
                                                width: '100%',
                                                fontFamily: 'Poppins',
                                                marginTop: '55px'
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{ padding: '5px', textAlign: 'left' }}
                                                  ></td>
                                                  <td
                                                    style={{ padding: '5px', textAlign: 'right' }}
                                                  ></td>
                                                  <td
                                                    style={{ padding: '5px', textAlign: 'right' }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      padding: '5px',
                                                      borderTop: '1px solid #000',
                                                      textAlign: 'right',
                                                      fontWeight: 'bold',
                                                      borderBottom: '1px solid #000',
                                                      width: '18%'
                                                    }}
                                                  >
                                                    Total AUD paid
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: '5px',
                                                      borderTop: '1px solid #000',
                                                      textAlign: 'right',
                                                      fontWeight: 'bold',
                                                      borderBottom: '1px solid #000',
                                                      width: '18%'
                                                    }}
                                                  >
                                                    $
                                                    {/* {item?.invoice?.total_paid_amount
                                                  ? item?.invoice?.total_paid_amount.toFixed(2)
                                                  : '0.00'} */}
                                                    {item?.amount_paid
                                                      ? Number(item?.amount_paid).toFixed(2)
                                                      : '0.00'}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          ) : (
                                            <></>
                                          )}

                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            style={{
                                              width: '100%',
                                              fontFamily: 'Poppins',
                                              marginTop: '55px'
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={
                                                    finalValues?.template?.settings?.secondary_color
                                                      ? {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                          textAlign: 'left'
                                                        }
                                                      : {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background:
                                                            finalValues?.template?.settings
                                                              ?.primary_color,
                                                          textAlign: 'left'
                                                        }
                                                  }
                                                >
                                                  Invoice Date
                                                </th>
                                                <th
                                                  style={
                                                    finalValues?.template?.settings?.secondary_color
                                                      ? {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                          textAlign: 'right'
                                                        }
                                                      : {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background:
                                                            finalValues?.template?.settings
                                                              ?.primary_color,
                                                          textAlign: 'right'
                                                        }
                                                  }
                                                >
                                                  Reference
                                                </th>
                                                <th
                                                  style={
                                                    finalValues?.template?.settings?.secondary_color
                                                      ? {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                          textAlign: 'right'
                                                        }
                                                      : {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background:
                                                            finalValues?.template?.settings
                                                              ?.primary_color,
                                                          textAlign: 'right'
                                                        }
                                                  }
                                                >
                                                  Payment Type
                                                </th>
                                                <th
                                                  style={
                                                    finalValues?.template?.settings?.secondary_color
                                                      ? {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                          textAlign: 'right'
                                                        }
                                                      : {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background:
                                                            finalValues?.template?.settings
                                                              ?.primary_color,
                                                          textAlign: 'right'
                                                        }
                                                  }
                                                >
                                                  Invoice Total
                                                </th>
                                                <th
                                                  style={
                                                    finalValues?.template?.settings?.secondary_color
                                                      ? {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                          textAlign: 'right'
                                                        }
                                                      : {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background:
                                                            finalValues?.template?.settings
                                                              ?.primary_color,
                                                          textAlign: 'right'
                                                        }
                                                  }
                                                >
                                                  Amount Paid
                                                </th>
                                                <th
                                                  style={
                                                    finalValues?.template?.settings?.secondary_color
                                                      ? {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background: `transparent
                        linear-gradient(
                          180deg,
                          ${finalValues?.template?.settings?.secondary_color} 0%,
                          ${finalValues?.template?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                          textAlign: 'right'
                                                        }
                                                      : {
                                                          padding: '5px',
                                                          borderBottom: '1px solid #000',
                                                          color:
                                                            finalValues?.template?.settings
                                                              ?.font_color,
                                                          background:
                                                            finalValues?.template?.settings
                                                              ?.primary_color,
                                                          textAlign: 'right'
                                                        }
                                                  }
                                                >
                                                  Still Outstanding
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    textAlign: 'left',
                                                    borderBottom: '1px solid #ced4da'
                                                  }}
                                                >
                                                  {item?.invoice?.date}
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderBottom: '1px solid #ced4da',
                                                    textAlign: 'right'
                                                  }}
                                                >
                                                  {item?.invoice?.invoice_number}
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderBottom: '1px solid #ced4da',
                                                    textAlign: 'right'
                                                  }}
                                                >
                                                  {item?.payment_type
                                                    ? item?.payment_type === 'gift_card'
                                                      ? 'Gift Card'
                                                      : item?.payment_type === 'ffr'
                                                      ? 'Fee From Refund'
                                                      : item?.payment_type.charAt(0).toUpperCase() +
                                                        item?.payment_type.slice(1)
                                                    : paymentTypelist.find(
                                                        (i) => i.value === paymentDefault
                                                      )?.label}
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderBottom: '1px solid #ced4da',
                                                    textAlign: 'right'
                                                  }}
                                                >
                                                  {item?.invoice?.total_payable_amount
                                                    ? item?.invoice?.total_payable_amount.toFixed(2)
                                                    : '0.00'}
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderBottom: '1px solid #ced4da',
                                                    textAlign: 'right'
                                                  }}
                                                >
                                                  {item?.amount_paid
                                                    ? Number(item?.amount_paid).toFixed(2)
                                                    : '0.00'}
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderBottom: '1px solid #ced4da',
                                                    textAlign: 'right'
                                                  }}
                                                >
                                                  {item?.amount_due
                                                    ? Number(
                                                        Number(item?.amount_due) -
                                                          Number(item?.amount_paid)
                                                      ).toFixed(2)
                                                    : '0.00'}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{ padding: '5px', textAlign: 'left' }}
                                                ></td>
                                                <td
                                                  style={{ padding: '5px', textAlign: 'right' }}
                                                ></td>
                                                <td
                                                  style={{ padding: '5px', textAlign: 'right' }}
                                                ></td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderTop: '1px solid #000',
                                                    textAlign: 'right',
                                                    fontWeight: 'bold',
                                                    borderBottom: '1px solid #000'
                                                  }}
                                                >
                                                  Total AUD
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderTop: '1px solid #000',
                                                    textAlign: 'right',
                                                    fontWeight: 'bold',
                                                    borderBottom: '1px solid #000'
                                                  }}
                                                >
                                                  {item?.amount_paid
                                                    ? Number(item?.amount_paid).toFixed(2)
                                                    : '0.00'}
                                                </td>
                                                <td
                                                  style={{
                                                    padding: '5px',
                                                    borderTop: '1px solid #000',
                                                    textAlign: 'right',
                                                    fontWeight: 'bold',
                                                    borderBottom: '1px solid #000'
                                                  }}
                                                >
                                                  {item?.amount_due
                                                    ? Number(item?.amount_due).toFixed(2)
                                                    : '0.00'}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))
                    )}
                  </Modal.Body>
                </Modal>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddReceipt;
