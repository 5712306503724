import { faCheckCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CustomTooltip from "../../../common/CustomTooltip";

const ExpandedRowForReconcileTransaction = ({
  data,
  accountOptions,
  allAccountValue,
  jobOptions,
  taskOptions,
  handleAccountSelection,
  GSTOptions,
  handleGSTSelection,
  allContactList,
  loadingContactList,
  handleContactNameSelection,
  allContactValue,
  handleDescription,
  handleBlurDescription,
  handleAmount,
  handleBlurAmount,
  splitAccountVal,
  splitJobVal,
  splitTaskVal,
  handleSplitAddAccount,
  setSplitDescription,
  splitGstVal,
  setSplitGst,
  setSplit1Amount,
  setSplit2Amount,
  setSplitAccountId,
  setItemId,
  handleSplitOkDisable,
  handleSubmitSplit,
  split2Amount,
  split1Amount,
  handleReconcileDisable,
  handleSplitDisable,
  splitIcon,
  itemId,
  isUnderReviewed,
  selectedItems,
  handleCheckBox,
  splitDescription,
  setSplitGstVal,
  splitGst,
  id,
  dataForList,
  handleSplitAddJob,
  handleSplitAddTask,
  handleJobSelection,
  handleTaskSelection,
  clearSplitJobSelection,
  clearSplitTaskSelection,
  clearJobSelection,
  clearTaskSelection,
  newData,
  setData,
}) => {
  const listViewDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: data?.id,

        check: "",
        date: "",
        description: "",
        debit: "",
        credit: "",
        review_notes: "",

        // check: (
        //   <div className="reconcile-item-list-data refund-checkbox">
        //     <Form.Check
        //       type="checkbox"
        //       disabled={
        //         isUnderReviewed === true
        //           ? false
        //           : handleReconcileDisable(data?.bank_transaction_data)
        //       }
        //       checked={
        //         isUnderReviewed === true
        //           ? selectedItems.indexOf(data?.bank_transaction_data.id) >= 0
        //           : handleReconcileDisable(data?.bank_transaction_data) ===
        //             false
        //           ? selectedItems.indexOf(data?.bank_transaction_data.id) >= 0
        //           : false
        //       }
        //       onChange={() => {
        //         handleCheckBox(data?.bank_transaction_data.id);
        //       }}
        //     ></Form.Check>
        //   </div>
        // ),
        // date: data?.bank_transaction_data?.transactionDate
        //   ? moment(
        //       data?.bank_transaction_data?.transactionDate,
        //       "DD-MM-YYYY"
        //     ).format("DD-MM-YYYY")
        //   : "",
        // description: data?.bank_transaction_data?.description
        //   ? data?.bank_transaction_data?.description
        //   : "",
        // debit:
        //   data?.bank_transaction_data?.baseType === 1
        //     ? ""
        //     : `${data?.bank_transaction_data?.amount.toLocaleString("en-US", {
        //         minimumFractionDigits: 2,
        //         maximumFractionDigits: 2,
        //       })}`,
        // credit:
        //   data?.bank_transaction_data?.baseType === 1
        //     ? `${data?.bank_transaction_data?.amount.toLocaleString("en-US", {
        //         minimumFractionDigits: 2,
        //         maximumFractionDigits: 2,
        //       })}`
        //     : "",
        account_gst: (
        <div className="reconcilation-column-group-input">
        
          <div className="reconcilation-column-account-gst">
          {data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          data?.bank_transaction_data?.bank_reconciliation[1]
            ?.chart_of_account_id ? (
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Account </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Account"
              loading={accountOptions.length === 0 ? true : false}
              onChange={(id) =>
                handleAccountSelection(
                  id,
                  data?.bank_transaction_data?.bank_reconciliation[1].id,
                  data?.bank_transaction_data?.bank_reconciliation[1],
                  data
                )
              }
              value={
                allAccountValue?.find(
                  (account) =>
                    account?.value ===
                    data?.bank_transaction_data?.bank_reconciliation[1]
                      ?.chart_of_account_id
                )
                  ? allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account_id
                    )
                  : ""
              }
              classNamePrefix="chartofselect"
              options={
                [...accountOptions?.map((element) => {
                    return({
                      label: element?.label,
                      options: element?.options
                    })                                                        
                  })
                  , { label: <strong>+ Add New Account</strong>, value: "new" }
                ]
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          ) : (
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Account </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Account"
              loading={accountOptions.length === 0 ? true : false}
              onChange={(id) => {
                handleSplitAddAccount(id);
              }}
              value={
                allAccountValue?.find(
                  (account) => account?.value === splitAccountVal
                )
                  ? allAccountValue?.find(
                      (account) => account?.value === splitAccountVal
                    )
                  : ""
              }
              classNamePrefix="chartofselect"
              options={ 
                [...accountOptions?.map((element) => {
                    return({
                      label: element?.label,
                      options: element?.options 
                    })                                                        
                  })
                  , { label: <strong>+ Add New Account</strong>, value: "new" }
                ]
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          )}
          </div>
          <div className="reconcilation-column-account-gst">
            {data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          (data?.bank_transaction_data?.bank_reconciliation[1]?.gst ||
            data?.bank_transaction_data?.bank_reconciliation[1]
              ?.chart_of_account_id) ? (
            <Select
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select GST"
              loading={GSTOptions.length === 0 ? true : false}
              value={
                GSTOptions?.find(
                  (val) =>
                    val?.value ===
                    parseInt(
                      data?.bank_transaction_data?.bank_reconciliation[1]?.gst
                    )
                )
                  ? GSTOptions?.find(
                      (val) =>
                        val?.value ===
                        parseInt(
                          data?.bank_transaction_data?.bank_reconciliation[1]
                            ?.gst
                        )
                    )
                  : allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account_id
                    )?.tax === 1
                  ? GSTOptions?.find((val) => val?.value === 1)
                  : allAccountValue?.find(
                    (account) =>
                      account?.value ===
                      data?.bank_transaction_data?.bank_reconciliation[1]
                        ?.chart_of_account_id
                    )?.tax === 2
                  ? GSTOptions?.find((val) => val?.value === 2)
                  : allAccountValue?.find(
                    (account) =>
                      account?.value ===
                      data?.bank_transaction_data?.bank_reconciliation[1]
                        ?.chart_of_account_id
                    )?.tax === 3
                  ? GSTOptions?.find((val) => val?.value === 3)
                  : allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account_id
                    )?.tax === 0
                  ? GSTOptions?.find((val) => val?.value === 0)
                  : ""
              }
              onChange={(id) =>
                handleGSTSelection(
                  id?.value,
                  data?.bank_transaction_data?.bank_reconciliation[1].id
                )
              }
              classNamePrefix="chartofselect"
              options={GSTOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          ) : (
            <Select
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select GST"
              loading={GSTOptions.length === 0 ? true : false}
              value={
                GSTOptions?.find((gst) => gst?.value === parseInt(splitGstVal))
                  ? GSTOptions?.find(
                      (gst) => gst?.value === parseInt(splitGstVal)
                    )
                  : ""
              }
              onChange={(id) => {
                setSplitGst(id?.value);
                setSplitGstVal(id?.value);
              }}
              classNamePrefix="chartofselect"
              options={GSTOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          )}
          </div>
        </div>),
        gst:
          data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          (data?.bank_transaction_data?.bank_reconciliation[1]?.gst ||
            data?.bank_transaction_data?.bank_reconciliation[1]
              ?.chart_of_account_id) ? (
            <Select
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select GST"
              loading={GSTOptions.length === 0 ? true : false}
              value={
                GSTOptions?.find(
                  (val) =>
                    val?.value ===
                    parseInt(
                      data?.bank_transaction_data?.bank_reconciliation[1]?.gst
                    )
                )
                  ? GSTOptions?.find(
                      (val) =>
                        val?.value ===
                        parseInt(
                          data?.bank_transaction_data?.bank_reconciliation[1]
                            ?.gst
                        )
                    )
                  : allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account_id
                    )?.tax === 1
                  ? GSTOptions?.find((val) => val?.value === 1)
                  : allAccountValue?.find(
                    (account) =>
                      account?.value ===
                      data?.bank_transaction_data?.bank_reconciliation[1]
                        ?.chart_of_account_id
                    )?.tax === 2
                  ? GSTOptions?.find((val) => val?.value === 2)
                  : allAccountValue?.find(
                    (account) =>
                      account?.value ===
                      data?.bank_transaction_data?.bank_reconciliation[1]
                        ?.chart_of_account_id
                    )?.tax === 3
                  ? GSTOptions?.find((val) => val?.value === 3)
                  : allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account_id
                    )?.tax === 0
                  ? GSTOptions?.find((val) => val?.value === 0)
                  : ""
              }
              onChange={(id) =>
                handleGSTSelection(
                  id?.value,
                  data?.bank_transaction_data?.bank_reconciliation[1].id
                )
              }
              classNamePrefix="chartofselect"
              options={GSTOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          ) : (
            <Select
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select GST"
              loading={GSTOptions.length === 0 ? true : false}
              value={
                GSTOptions?.find((gst) => gst?.value === parseInt(splitGstVal))
                  ? GSTOptions?.find(
                      (gst) => gst?.value === parseInt(splitGstVal)
                    )
                  : ""
              }
              onChange={(id) => {
                setSplitGst(id?.value);
                setSplitGstVal(id?.value);
              }}
              classNamePrefix="chartofselect"
              options={GSTOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          ),
        additional:(
          <div className="reconcilation-column-group-input">
              <Fragment>
                {
                  data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
                  data?.bank_transaction_data?.bank_reconciliation[1]?.job_id ? (
                    <div className="reconcilation-clear-job-task-body">
                    <CreatableSelect
                      formatCreateLabel={(userInput) => (
                        <>
                          <strong>+ Add New Job </strong>'{`${userInput}`}'
                        </>
                      )}
                      style={{
                        width: "50%",
                        margin: "0",
                      }}
                      placeholder="Select Job"
                      loading={jobOptions.length === 0 ? true : false}
                      onChange={(id) =>
                        //TODO: handleJobSelection()
                        handleJobSelection(
                          id,
                          data?.bank_transaction_data?.bank_reconciliation[1]?.id,
                          data?.bank_transaction_data?.bank_reconciliation[1],
                          data?.bank_transaction_data
                        )
                      }
                      value={
                        jobOptions?.find(
                          (job) => job?.value === data?.bank_transaction_data?.bank_reconciliation[1]?.job_id
                        )
                          ? jobOptions?.find(
                              (job) => job?.value === data?.bank_transaction_data?.bank_reconciliation[1]?.job_id
                            )
                          : ""
                      }
                      classNamePrefix="chartofselect"
                      options={jobOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: "grey",
                        },
                      })}
                      className="selectpicker"
                    />
                    {data?.bank_transaction_data?.bank_reconciliation[1]?.job && <FontAwesomeIcon 
                      className="clear-button-selection"
                      icon={faXmark}
                      size="1x"
                      onClick={() => clearJobSelection('',data?.bank_transaction_data?.bank_reconciliation[1]?.id)}
                    />}
                    </div>
                  ) : (
                    <div className="reconcilation-clear-job-task-body">
                    <CreatableSelect
                      formatCreateLabel={(userInput) => (
                        <>
                          <strong>+ Add New Job </strong>'{`${userInput}`}'
                        </>
                      )}
                      style={{
                        width: "50%",
                        margin: "0",
                      }}
                      placeholder="Select Job"
                      loading={jobOptions.length === 0 ? true : false}
                      onChange={(id) => {
                        handleSplitAddJob(id)
                      }}
                      value={
                        jobOptions?.find((job) => job?.value === splitJobVal)
                          ? jobOptions?.find((job) => job?.value === splitJobVal)
                          : ""
                      }
                      classNamePrefix="chartofselect"
                      options={jobOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: "grey",
                        },
                      })}
                      className="selectpicker"
                    />
                    {splitJobVal &&<FontAwesomeIcon 
                      className="clear-button-selection"
                      icon={faXmark}
                      size="1x"
                      onClick={() => clearSplitJobSelection()}
                    />}
                    </div>
                  )
                }
              </Fragment>
              <Fragment>
                {
                   data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
                   data?.bank_transaction_data?.bank_reconciliation[1]?.task_id ? (
                    <div className="reconcilation-clear-job-task-body">
                     <CreatableSelect
                       formatCreateLabel={(userInput) => (
                         <>
                           <strong>+ Add New Task </strong>'{`${userInput}`}'
                         </>
                       )}
                       style={{
                         width: "50%",
                         margin: "0",
                       }}
                       placeholder="Select Task"
                       loading={taskOptions.length === 0 ? true : false}
                       onChange={(id) =>
                         //TODO: handleTaskSelection()
                         handleTaskSelection(
                           id,
                           data?.bank_transaction_data?.bank_reconciliation[1]?.id,
                           data?.bank_transaction_data?.bank_reconciliation[1],
                           data?.bank_transaction_data
                         )
                       }
                       value={
                         taskOptions?.find(
                           (task) => task?.value === data?.bank_transaction_data?.bank_reconciliation[1]?.task_id
                         )
                           ? taskOptions?.find(
                               (task) => task?.value === data?.bank_transaction_data?.bank_reconciliation[1]?.task_id
                             )
                           : ""
                       }
                       classNamePrefix="chartofselect"
                       options={taskOptions}
                       theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                           ...theme.colors,
                           primary: "grey",
                         },
                       })}
                       className="selectpicker"
                     />
                     {data?.bank_transaction_data?.bank_reconciliation[1]?.task && <FontAwesomeIcon 
                      className="clear-button-selection"
                      icon={faXmark}
                      size="1x"
                      onClick={() => clearTaskSelection('',data?.bank_transaction_data?.bank_reconciliation[1]?.id)}
                    />}
                    </div>
                   ) : (
                    <div className="reconcilation-clear-job-task-body">
                     <CreatableSelect
                       formatCreateLabel={(userInput) => (
                         <>
                           <strong>+ Add New Task </strong>'{`${userInput}`}'
                         </>
                       )}
                       style={{
                         width: "50%",
                         margin: "0",
                       }}
                       placeholder="Select Task"
                       loading={taskOptions.length === 0 ? true : false}
                       onChange={(id) => {
                         handleSplitAddTask(id);
                       }}
                       value={
                         taskOptions?.find((task) => task?.value === splitTaskVal)
                           ? taskOptions?.find((task) => task?.value === splitTaskVal)
                           : ""
                       }
                       classNamePrefix="chartofselect"
                       options={taskOptions}
                       theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                           ...theme.colors,
                           primary: "grey",
                         },
                       })}
                       className="selectpicker"
                     />
                     {splitTaskVal && <FontAwesomeIcon 
                        className="clear-button-selection"
                        icon={faXmark}
                        size="1x"
                        onClick={() => clearSplitTaskSelection()}
                      />}
                    </div>
                   )
                }
              </Fragment>
          </div>
        ),
        // job:
        //   data?.job_data?.jobs?.length === 2 &&
        //   data?.job_data?.jobs[0]?.job_id ? (
        //     <CreatableSelect
        //       formatCreateLabel={(userInput) => (
        //         <>
        //           <strong>+ Add New Job </strong>'{`${userInput}`}'
        //         </>
        //       )}
        //       style={{
        //         width: "50%",
        //         margin: "0",
        //       }}
        //       placeholder="Select Job"
        //       loading={jobOptions.length === 0 ? true : false}
        //       onChange={(id) =>
        //         //TODO: handleJobSelection()
        //         handleAccountSelection(
        //           id,
        //           data?.job_data?.jobs[1].id,
        //           data?.job_data?.jobs[1],
        //           data
        //         )
        //       }
        //       value={
        //         jobOptions?.find(
        //           (job) => job?.value === data?.job_data?.jobs[1]?.job_id
        //         )
        //           ? jobOptions?.find(
        //               (job) => job?.value === data?.job_data?.jobs[1]?.job_id
        //             )
        //           : ""
        //       }
        //       classNamePrefix="chartofselect"
        //       options={jobOptions}
        //       theme={(theme) => ({
        //         ...theme,
        //         borderRadius: 0,
        //         colors: {
        //           ...theme.colors,
        //           primary: "grey",
        //         },
        //       })}
        //     />
        //   ) : (
        //     <CreatableSelect
        //       formatCreateLabel={(userInput) => (
        //         <>
        //           <strong>+ Add New Job </strong>'{`${userInput}`}'
        //         </>
        //       )}
        //       style={{
        //         width: "50%",
        //         margin: "0",
        //       }}
        //       placeholder="Select Job"
        //       loading={jobOptions.length === 0 ? true : false}
        //       onChange={(id) => {
        //         // handleSplitAddAccount(id);
        //         // TODO: handle splitADDJob(id)
        //         console.log(id);
        //       }}
        //       value={
        //         jobOptions?.find((job) => job?.value === splitJobVal)
        //           ? jobOptions?.find((job) => job?.value === splitJobVal)
        //           : ""
        //       }
        //       classNamePrefix="chartofselect"
        //       options={jobOptions}
        //       theme={(theme) => ({
        //         ...theme,
        //         borderRadius: 0,
        //         colors: {
        //           ...theme.colors,
        //           primary: "grey",
        //         },
        //       })}
        //     />
        //   ),
        // task:
        //   data?.task_data?.tasks?.length === 2 &&
        //   data?.task_data?.tasks[0]?.task_id ? (
        //     <CreatableSelect
        //       formatCreateLabel={(userInput) => (
        //         <>
        //           <strong>+ Add New Task </strong>'{`${userInput}`}'
        //         </>
        //       )}
        //       style={{
        //         width: "50%",
        //         margin: "0",
        //       }}
        //       placeholder="Select Task"
        //       loading={taskOptions.length === 0 ? true : false}
        //       onChange={(id) =>
        //         //TODO: handleTaskSelection()
        //         handleAccountSelection(
        //           id,
        //           data?.task_data?.tasks[1].id,
        //           data?.task_data?.tasks[1],
        //           data
        //         )
        //       }
        //       value={
        //         taskOptions?.find(
        //           (task) => task?.value === data?.task_data?.tasks[1]?.task_id
        //         )
        //           ? taskOptions?.find(
        //               (task) => task?.value === data?.task_data?.tasks[1]?.task_id
        //             )
        //           : ""
        //       }
        //       classNamePrefix="chartofselect"
        //       options={taskOptions}
        //       theme={(theme) => ({
        //         ...theme,
        //         borderRadius: 0,
        //         colors: {
        //           ...theme.colors,
        //           primary: "grey",
        //         },
        //       })}
        //     />
        //   ) : (
        //     <CreatableSelect
        //       formatCreateLabel={(userInput) => (
        //         <>
        //           <strong>+ Add New Task </strong>'{`${userInput}`}'
        //         </>
        //       )}
        //       style={{
        //         width: "50%",
        //         margin: "0",
        //       }}
        //       placeholder="Select Task"
        //       loading={taskOptions.length === 0 ? true : false}
        //       onChange={(id) => {
        //         // handleSplitAddAccount(id);
        //         // TODO: handle splitADDTask(id)
        //         console.log(id);
        //         handleSplitAddJob(id);
        //       }}
        //       value={
        //         taskOptions?.find((task) => task?.value === splitTaskVal)
        //           ? taskOptions?.find((task) => task?.value === splitTaskVal)
        //           : ""
        //       }
        //       classNamePrefix="chartofselect"
        //       options={taskOptions}
        //       theme={(theme) => ({
        //         ...theme,
        //         borderRadius: 0,
        //         colors: {
        //           ...theme.colors,
        //           primary: "grey",
        //         },
        //       })}
        //     />
        //   ),
        contact_name:
          data?.bank_transaction_data?.bank_reconciliation?.length !== 2 &&
          data?.bank_transaction_data?.bank_reconciliation[1]?.contact_id ? (
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Contact </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Contact"
              loading={
                allContactList.length === 0 || loadingContactList === true
                  ? true
                  : false
              }
              onChange={(id) =>
                handleContactNameSelection(
                  id,
                  data?.bank_transaction_data?.bank_reconciliation[1].id,
                  data?.bank_transaction_data?.bank_reconciliation[1],
                  data?.baseType,
                  data
                )
              }
              value={
                allContactValue?.find(
                  (contact) =>
                    contact?.value ===
                    data?.bank_transaction_data?.bank_reconciliation[1]
                      ?.contact_id
                )
                  ? allContactValue?.find(
                      (contact) =>
                        contact?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.contact_id
                    )
                  : ""
              }
              classNamePrefix="chartofselect"
              options={allContactList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          ) : (
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Contact </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Contact"
              loading={
                allContactList.length === 0 || loadingContactList === true
                  ? true
                  : false
              }
              onChange={(id) =>
                handleContactNameSelection(
                  id,
                  data?.bank_transaction_data?.bank_reconciliation[0].id,
                  data?.bank_transaction_data?.bank_reconciliation[0],
                  data?.bank_transaction_data?.baseType,
                  data?.bank_transaction_data
                )
              }
              value={
                allContactValue?.find(
                  (contact) =>
                    contact?.value ===
                    data?.bank_transaction_data?.bank_reconciliation[0]
                      ?.contact_id
                )
                  ? allContactValue?.find(
                      (contact) =>
                        contact?.value ===
                        data?.bank_transaction_data?.bank_reconciliation[0]
                          ?.contact_id
                    )
                  : ""
              }
              classNamePrefix="chartofselect"
              options={allContactList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          ),
        comment:
          data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          data?.bank_transaction_data?.bank_reconciliation[1].description ? (
            <Form.Control
              required
              type="text"
              id={data?.bank_transaction_data?.bank_reconciliation[1].id}
              placeholder="Add Description"
              value={
                data?.bank_transaction_data?.bank_reconciliation[1].description
                  ? data?.bank_transaction_data?.bank_reconciliation[1]
                      .description
                  : ""
              }
              onChange={(e) => {
                handleDescription(e, data?.bank_transaction_data);
              }}
              onBlur={() =>
                handleBlurDescription(
                  data?.bank_transaction_data?.bank_reconciliation[1].id,
                  data?.bank_transaction_data?.bank_reconciliation[1],
                  data,
                  data?.bank_transaction_data?.bank_reconciliation[1]
                    ?.chart_of_account_id
                )
              }
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ) : (
            <Form.Control
              required
              type="text"
              placeholder="Add Description"
              onChange={(e) => {
                setSplitDescription(e.target.value);
              }}
              value={splitDescription ? splitDescription : ""}
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ),
        amount:
          data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          data?.bank_transaction_data?.bank_reconciliation[1].amount ? (
            <Form.Control
              required
              type="text"
              id={data?.bank_transaction_data?.bank_reconciliation[1].id}
              placeholder="Add Amount"
              value={
                data?.bank_transaction_data?.bank_reconciliation[1].amount
                  ? data?.bank_transaction_data?.bank_reconciliation[1].amount
                  : ""
              }
              onChange={(e) => {
                handleAmount(e, data?.bank_transaction_data);
              }}
              onBlur={() =>
                handleBlurAmount(
                  data?.bank_transaction_data?.bank_reconciliation[1].id,
                  data?.bank_transaction_data?.bank_reconciliation[1],
                  data?.bank_transaction_data,
                  data?.bank_transaction_data?.bank_reconciliation[1]
                    ?.chart_of_account_id
                )
              }
              rules={[
                {
                  pattern: new RegExp(/^([0-9]+$)([.]?)/),
                  message: "Amount can contain numbers & dot(.)",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ) : (
            <Form.Control
              required
              type="text"
              placeholder="Add Amount"
              id={data?.bank_transaction_data?.bank_reconciliation[0]?.id}
              value={split2Amount ? split2Amount : ""}
              // value={newData?.data[i]?.split2Amount}
              // value={split2Amount}

              // onChange={(e) => {
              //   setSplit2Amount(
              //     /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
              //       ? (parseFloat(e.target.value)
              //           ? parseFloat(e.target.value)
              //           : 0) > parseFloat(data?.bank_transaction_data?.amount)
              //         ? data?.bank_transaction_data?.amount
              //         : e.target.value
              //         ? e.target.value
              //         : 0
              //       : split2Amount
              //   );

              //   setSplit1Amount(
              //     /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
              //       ? (parseFloat(e.target.value)
              //           ? parseFloat(e.target.value)
              //           : 0) > parseFloat(data?.bank_transaction_data?.amount)
              //         ? 0.0
              //         : (
              //             parseFloat(data?.bank_transaction_data?.amount) -
              //             (parseFloat(e.target.value)
              //               ? parseFloat(e.target.value)
              //               : 0)
              //           ).toFixed(2)
              //       : split1Amount
              //   );
              // }}

              onChange={(e) => {
                const { id } = e.target;

                let newListData = [...newData.data];

                let index = newListData.findIndex(
                  (el) =>
                    el?.bank_reconciliation[0]?.id === id ||
                    el?.bank_reconciliation[1]?.id === id
                );

                setSplit2Amount(
                  /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                    ? (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0) > parseFloat(data?.bank_transaction_data?.amount)
                      ? data?.bank_transaction_data?.amount
                      : e.target.value
                      ? e.target.value
                      : 0
                    : split2Amount
                );

                setSplit1Amount(
                  /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                    ? (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0) > parseFloat(data?.bank_transaction_data?.amount)
                      ? 0.0
                      : (
                          parseFloat(data?.bank_transaction_data?.amount) -
                          (parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0)
                        ).toFixed(2)
                    : split1Amount
                );

                // if (index > -1) {
                newListData[index] = {
                  ...newListData[index],
                  split2Amount:
                    /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                      ? (parseFloat(e.target.value)
                          ? parseFloat(e.target.value)
                          : 0) > parseFloat(data?.bank_transaction_data?.amount)
                        ? data?.bank_transaction_data?.amount
                        : e.target.value
                        ? e.target.value
                        : 0
                      : split2Amount,
                  split1Amount:
                    /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                      ? (parseFloat(e.target.value)
                          ? parseFloat(e.target.value)
                          : 0) > parseFloat(data?.bank_transaction_data?.amount)
                        ? 0.0
                        : (
                            parseFloat(data?.bank_transaction_data?.amount) -
                            (parseFloat(e.target.value)
                              ? parseFloat(e.target.value)
                              : 0)
                          ).toFixed(2)
                      : split1Amount,
                };
                setData({ ...data, ...{ data: newListData } });
                // }
              }}
              rules={[
                {
                  pattern: new RegExp(/^([0-9]+$)([.]?)/),
                  message: "Amount can contain numbers & dot(.)",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ),
        action:
          data?.bank_transaction_data?.bank_reconciliation?.length === 2 ? (
            ""
          ) : (
            <>
            <CustomTooltip
              text={"Save"}
              children={
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  // title="Save"
                  onClick={() => {
                    handleSubmitSplit(
                      data?.bank_transaction_data?.id,
                      data?.bank_transaction_data?.bank_reconciliation[0]?.id,
                      data?.bank_transaction_data?.bank_reconciliation[0]
                        ?.contact_id,
                      data?.bank_transaction_data?.bank_reconciliation[0]
                        ?.description,
                      data?.bank_transaction_data?.bank_reconciliation[0]
                        ?.chart_of_account_id
                    );
                  }}
                  disabled={handleSplitOkDisable(data?.bank_transaction_data)}
                >
                  Ok
                </FontAwesomeIcon>
              }
            />
            </>
          ),
      });
    }
    return items;
  };

  const listViewData = listViewDataGenerator(1);

  const columns = isUnderReviewed
    ? [
        {
          dataField: "check",
          text: "",
          style: { width: "2%" },
          headerClasses: "table-header",
        },
        {
          dataField: "date",
          text: "Date",
          style: { width: "7.7%" },
          headerClasses: "table-header",
        },
        {
          dataField: "description",
          text: "Description",
          style: { width: "9.8%" },
          headerClasses: "table-header",
        },
        {
          dataField: "debit",
          text: "Debit",
          style: { width: "5.85%" },
          headerClasses: "table-header",
        },
        {
          dataField: "credit",
          text: "Credit",
          style: { width: "5.85%" },
          headerClasses: "table-header",
        },
        {
          dataField: "review_notes",
          text: "Review Notes",
          style: { width: "13%" },
          headerClasses: "table-header",
        },
        {
          dataField: "account_gst",
          text: "Account",
          style: { width: "12.7%" },
          headerClasses: "table-header",
        },
        // {
        //   dataField: "gst",
        //   text: "Gst",
        //   style: { width: "12%" },
        //   headerClasses: "table-header",
        // },
        {
          dataField: "contact_name",
          text: "Contact Name",
          style: { width: "14.35%" },
          headerClasses: "table-header",
        },
        {
          dataField: "comment",
          text: "Comment",
          style: { width: "14.4%" },
          headerClasses: "table-header",
        },
        {
          dataField: "amount",
          text: "Amount",
          style: { width: "7.85%" },
          headerClasses: "table-header",
        },
        {
          dataField: "additional",
          text: 'Additional',
          style: {width: "12.55%"},
          headerClasses: "table-header",
        },
        {
          dataField: "action",
          text: "Action",
          style: { width: "2%" },
          headerClasses: "table-header",
        },
      ]
    : [
        {
          dataField: "check",
          text: "",
          style: { width: "2%" },
          headerClasses: "table-header",
        },
        {
          dataField: "date",
          text: "Date",
          style: { width: "7.7%" },
          headerClasses: "table-header",
        },
        {
          dataField: "description",
          text: "Description",
          style: { width: "9.8%" },
          headerClasses: "table-header",
        },
        {
          dataField: "debit",
          text: "Debit",
          style: { width: "5.85%" },
          headerClasses: "table-header",
        },
        {
          dataField: "credit",
          text: "Credit",
          style: { width: "5.85%" },
          headerClasses: "table-header",
        },
        {
          dataField: "account_gst",
          text: "Account",
          style: { width: "12.7%" },
          headerClasses: "table-header",
        },
        // {
        //   dataField: "gst",
        //   text: "Gst",
        //   style: { width: "12%" },
        //   headerClasses: "table-header",
        // },
        {
          dataField: "contact_name",
          text: "Contact Name",
          style: { width: "14.35%" },
          headerClasses: "table-header",
        },
        {
          dataField: "comment",
          text: "Comment",
          style: { width: "14.4%" },
          headerClasses: "table-header",
        },
        {
          dataField: "amount",
          text: "Amount",
          style: { width: "7.85%" },
          headerClasses: "table-header",
        },
        {
          dataField: "additional",
          text: 'Additional',
          style: {width: "12.55%"},
          headerClasses: "table-header",
        },
        {
          dataField: "action",
          text: "Action",
          style: { textAlign: "center" },
          headerClasses: "table-header",
        },
      ];

  // const expandRow = {
  //   renderer: (row) => <div></div>,
  //   onlyOneExpanding: true,
  //   showExpandColumn: true,
  //   expandByColumnOnly: true,
  //   expandColumnPosition: "right",
  //   expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
  //     return (
  //       <div className="table-action-btn">
  //         <b>
  //           <span
  //             variant="secondary"
  //             className="reconcile-item-list-split"
  //             size="sm"
  //             title="Split"
  //           ></span>
  //         </b>
  //       </div>
  //     );
  //   },
  // };

  return (
    <>
      <BootstrapTable
        keyField="key"
        remote
        data={listViewData}
        columns={columns}
        // expandRow={expandRow}
        // onTableChange={handleTableChange}
        noDataIndication="No Data Found"
      />
    </>
  );
};

export default ExpandedRowForReconcileTransaction;
