import React, {useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { metaTitle } from "../../../config";
import CashReceipts from "./CashReceipt/CashReceipts";
import CashPayments from "./CashPayments/CashPayments";
import TitleBar from "../../common/TitleBar";

const tabsKey = {
  "cash-payment": 1,
  "cash-receipt": 2
};

const CashFlowTabs = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") ? query.get("tab") : "cash-payment";

  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Cash Payments"
      : "Cash Receipts"
  );

  useEffect(() => {
    window.document.title = `${metaTitle} | ${activeTab}`
  })
  
  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={activeTab}
            />
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li
                    className={activeTab === "Cash Payments" ? "active" : ""}
                  >
                    <Link
                      key={1}
                    >
                      Cash Payments
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab === "Cash Receipts" ? "active" : ""
                    }
                  >
                    <Link
                      key={2}
                    >
                      Cash Receipts
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Cash Receipts" && (
                  <CashReceipts />
                )}
                {activeTab === "Cash Payments" && (
                  <CashPayments />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashFlowTabs;
