import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, fileUpload, get } from "../../../config";
import Spin from "../../common/Spin";
// import { MonthRangePicker } from "@semcore/date-picker";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatNumber } from "../../common/Misc";
import { DateRangePicker } from "rsuite";
import ChartForPLReport from "./components/ChartForPLReport";
// import ExpandedPLReport from "./components/ExpandedPLReport";
import { MultiSelect } from 'primereact/multiselect';
import ExpandedPLReport from "./components/ExpandedPLReport";
import TitleBar from "../../common/TitleBar";
// import "primereact/resources/themes/lara-light-indigo/theme.css"; 

const currentDate = new Date();
// const pastSixMonthDate = new Date(
//   new Date().getFullYear(),
//   new Date().getMonth() - 6,
//   new Date().getDate()
// );
const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1)
  );
}

const PLReport = ({dateRangeReport, setDateRangeReport}) => {
  const params = useParams();
  // const { afterToday } = DateRangePicker;
  const businessId = params?.business_id;
 
  const [plLoading, setPLLoading] = useState(false);
  const [plData, setPLData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const [selectedJobs, setSelectedJobs] = useState(null);
  const [jobOptions, setJobOptions] = useState([]);

  const [selectedTasks, setSelectedTasks] = useState(null);
  const [taskOptions, setTaskOptions] = useState([]);

  const filterJobInputRef = useRef();
  const filterTaskInputRef = useRef();
  const plTableRef = useRef();

  // const [valueRange, setValueRange] = useState(pastFinancialdate);


  const [dateValue, setDateValue] = useState(dateRangeReport ? dateRangeReport : defaultDateRange);
  const [startDate, setStartDate] = useState(
    dateRangeReport ? moment(dateRangeReport[0]).format("DD-MM-YYYY") : (defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : "")
  );
  const [endDate, setEndDate] = useState(
    dateRangeReport ? moment(dateRangeReport[1]).format("DD-MM-YYYY") : (defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : "")
  );

  const [expChartData, setExpChartData] = useState([]);
  const defaultPiChartData = [{ value: 100, name: "No Data", total: 0 }];


  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }
  
  const dateFilterRange = [
    {
      label: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
  ]

  const fetchPLData = useCallback(async (jobs, tasks) => {
    const formData = new FormData();
    if(jobs?.length > 0){
      for(let i=0; i<jobs?.length; i++){
        if(jobs[i] !== null && jobs[i] !== 'null' && jobs[i] !== ''){
          formData.append(`job_ids[${i}]`, jobs[i])
        } else {
          formData.append(`job_ids[${i}]`, '');
          formData.append(`job_ids[${i + 1}]`, null);
        }
      }
    }
    if(tasks?.length > 0){
      for(let j=0; j<tasks?.length; j++){
        if(tasks[j] !== null && tasks[j] !== 'null' && tasks[j] !== ''){
          formData.append(`task_ids[${j}]`, tasks[j])
        } else {
          formData.append(`task_ids[${j}]`, '');
          formData.append(`task_ids[${j + 1}]`, null);
        }
      }
    }
    formData.append("client_business_id", businessId);
    formData.append('start_date', startDate ? startDate : "")
    formData.append('end_date', endDate ? endDate : "")
    formData.append('export', 0);
    if (businessId) {
      try {
        setPLLoading(true);
        const { data } = await fileUpload(`${API.PROFIT_AND_LOSS_REPORT}`, formData);
        let totalRevenue = 0;
        let ReportRevenueData = [];
        if (data?.data?.revenues && data?.data?.revenues?.length > 0) {
          ReportRevenueData = data?.data?.revenues?.map((item) => {
          // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
          let obj = {
            ...item
          };
          totalRevenue += Number(item?.amount);
          return obj;
          });
        }
        let TotalRevenueData = {
          total_Revenue_name: "Total Revenues",
          total_revenue: totalRevenue
        }
        let totalExpense = 0;
        let ReportExpenseData = [];
        if (data?.data?.expenses && data?.data?.expenses?.length > 0) {
          ReportExpenseData = data?.data?.expenses?.map((item) => {
          // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
          let obj = {
            ...item
          };
          totalExpense += Number(item?.amount);
          return obj;
          });
        }
        let TotalExpenseData = {
          total_Expense_name: "Total Expenses",
          total_expense: totalExpense
        }
        let totalAmount = Math.abs(totalRevenue).toFixed(2) - Math.abs(totalExpense).toFixed(2)
        let totalData = {
          total_name: "Profit from Ordinary Activities",
          total_amount: totalAmount,
        } 
        setPLLoading(false);
        setPLData({ revenues: [].concat(ReportRevenueData), totalRevenueData: TotalRevenueData, expenses: [].concat(ReportExpenseData), totalExpenseData: TotalExpenseData, totalData: totalData});
      } catch (error) {
        const {data} = error.response;
        setPLLoading(false);
        setPLData([]);
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      }
    }
    // eslint-disable-next-line
  }, [businessId, startDate, endDate]);

  const [expRevChartData, setExpRevChartData] = useState([]);
  
  const fetchBankReconcileData = useCallback(async (jobs, tasks) => {
    const formData = new FormData();
    if(jobs?.length > 0){
      for(let i=0; i<jobs?.length; i++){
        if(jobs[i] !== null && jobs[i] !== 'null' && jobs[i] !== ''){
          formData.append(`job_ids[${i}]`, jobs[i])
        } else {
          formData.append(`job_ids[${i}]`, '');
          formData.append(`job_ids[${i + 1}]`, null);
        }
      }
    }
    if(tasks?.length > 0){
      for(let j=0; j<tasks?.length; j++){
        if(tasks[j] !== null && tasks[j] !== 'null' && tasks[j] !== ''){
          formData.append(`task_ids[${j}]`, tasks[j])
        } else {
          formData.append(`task_ids[${j}]`, '');
          formData.append(`task_ids[${j + 1}]`, null);
        }
      }
    }
    formData.append("client_business_id", businessId);
    formData.append('from_month', startDate ? `${startDate?.split('-')[1]}-${startDate?.split('-')[2]}` : '');
    formData.append('to_month', endDate ? `${endDate?.split('-')[1]}-${endDate?.split('-')[2]}` : '');

    if (businessId) {
      try {
        setPLLoading(true);
        const { data } = await fileUpload(`${API.GET_BANK_CONCILEDATA}`, formData);
        const expRevData = data?.data?.exRevChart ? data?.data?.exRevChart : [];
        setExpRevChartData(expRevData);
        const expData =
          data?.data?.expendituresChart &&
          data?.data?.expendituresChart?.length > 0
            ? data?.data?.expendituresChart?.map((item) => {
                const { percentage: value, ...rest } = item;
                return { value, ...rest };
              })
            : [];
        if (expData) {
          // const finalData = expData?.map((item) => (item?.value === 0 ? 0 : 1));
          // setExpChartData(
          //   removeDuplicates(finalData)?.length === 1 &&
          //     removeDuplicates(finalData)[0] === 0
          //     ? defaultPiChartData
          //     : expData.filter((item) => parseInt(item.value) !== 0)
          // );
          setExpChartData(expData);
        } else {
          setExpChartData(defaultPiChartData);
        }
        setPLLoading(false);
        return data.data;
      } catch (e) {
        setPLLoading(false);
        const { data } = e;
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      }
    }
    // eslint-disable-next-line
  }, [businessId, startDate, endDate]);

  const PLDataGenerator = (quantity) => {
    const items = [];

    if(quantity !== 0){
      
      items.push({
        key: `Revenues`,
        account: <div className="pl-report-type-heading">Revenues</div>,
        amount: (<div className="report_blank_data"></div>),
      })
  
      for (let i = 0; i < plData?.revenues?.length; i++) {
        if(plData?.revenues[i]?.amount !== 0){
          items.push({
            key: `item-revenue-[${i}]`,
            id: plData?.revenues[i]?.chart_account_id,
            account: plData?.revenues[i]?.chart_account_name,
            amount: plData?.revenues[i]?.amount ? formatNumber(Math.abs(plData?.revenues[i]?.amount)) : '0.00',
          });
        }
      }
  
      items.push({
        key: `totalRevenue`,
        account: <div className="pl-report-type-heading pl-total-amount">
          {plData?.totalRevenueData?.total_Revenue_name}
        </div>,
        amount: <div className="pl-report-type-heading pl-total-amount">
        {plData?.totalRevenueData?.total_revenue ? formatNumber(Math.abs(plData?.totalRevenueData?.total_revenue)) : '0.00'}
      </div>,
      })
  
      items.push({
        key: `blank-row`,
        account: <div className="pl-report-type-heading"></div>,
        amount: (<div className="report_blank_data"></div>),
      })
  
      items.push({
        key: `Expenses`,
        account: <div className="pl-report-type-heading">Expenses</div>,
        amount: (<div className="report_blank_data"></div>),
      })
      
      for (let i = 0; i < plData?.expenses?.length; i++) {
        if(plData?.expenses[i]?.amount !== 0){
          items.push({
            key: `item-expense-[${i}]`,
            id: plData?.expenses[i]?.chart_account_id,
            account: plData?.expenses[i]?.chart_account_name,
            amount: plData?.expenses[i]?.amount ? formatNumber(Math.abs(plData?.expenses[i]?.amount)) : '0.00',
          });
        }   
      }
      
      items.push({
        key: `totalExpense`,
        account: <div className="pl-report-type-heading pl-total-amount">
          {plData?.totalExpenseData?.total_Expense_name}
        </div>,
        amount: <div className="pl-report-type-heading pl-total-amount">
        {plData?.totalExpenseData?.total_expense ? formatNumber(Math.abs(plData?.totalExpenseData?.total_expense)) : '0.00'}
      </div>,
      })
  
      items.push({
        key: `totalData`,
        account: <div className="pl-report-type-heading pl-total-amount">
          {plData?.totalData?.total_name}
        </div>,
        amount: <div className="pl-report-type-heading pl-total-amount">
        {plData?.totalData?.total_amount ? plData?.totalData?.total_amount < 0 ? "(" + formatNumber(Math.abs(plData?.totalData?.total_amount)) + ")" : formatNumber(plData?.totalData?.total_amount) : "0.00"}
      </div>,
      })
  
    }
     
    return items;
  };

  const PLReportData = PLDataGenerator(plData?.length);

  useEffect(() => {
    fetchPLData(selectedJobs, selectedTasks);
    // eslint-disable-next-line
  }, [fetchPLData]);

  useEffect(() => {
    fetchBankReconcileData(selectedJobs, selectedTasks);
    // eslint-disable-next-line
  }, [fetchBankReconcileData]);

  const columns = [
    {
      dataField: "account",
      text: "",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.account?.props?.className === "pl-report-type-heading" && {"colSpan" : "2"}
      ),
    },
    {
      dataField: "amount",
      text: "Amount Ex.GST($)",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.amount?.props?.className === "report_blank_data" && {hidden : true}
      ),
    },
  ];

  const handleExportReport = async (format, jobs, tasks) => {
    const formData = new FormData();
    formData.append("client_business_id", businessId);
    formData.append('start_date', startDate ? startDate : "")
    formData.append('end_date', endDate ? endDate : "")
    formData.append('export', 1);
    formData.append('format', format)
    if(jobs?.length > 0){
      for(let i=0; i<jobs?.length; i++){
        if(jobs[i] !== null && jobs[i] !== 'null' && jobs[i] !== ''){
          formData.append(`job_ids[${i}]`, jobs[i])
        } else {
          formData.append(`job_ids[${i}]`, '');
          formData.append(`job_ids[${i + 1}]`, null);
        }
      }
    }
    if(tasks?.length > 0){
      for(let j=0; j<tasks?.length; j++){
        if(tasks[j] !== null && tasks[j] !== 'null' && tasks[j] !== ''){
          formData.append(`task_ids[${j}]`, tasks[j])
        } else {
          formData.append(`task_ids[${j}]`, '');
          formData.append(`task_ids[${j + 1}]`, null);
        }
      }
    }
    try {
      setExportLoading(true);
      const { data } = await fileUpload(`${API.PROFIT_AND_LOSS_REPORT}`, formData);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "trial_balance_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleDateRange = (e) => {
    setDateValue(e);
    setDateRangeReport(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandedPLReport 
          id={row?.id}
          businessId={businessId}
          startDate={ startDate }
          endDate={ endDate }
          selectedJobs={selectedJobs}
          selectedTasks={selectedTasks}
        />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: ['Revenues', 'totalRevenue' ,'Expenses', 'totalExpense', 'totalData', 'blank-row'],
    expandColumnRenderer: ({ expanded, rowKey }) => {

      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      if(
        rowKey === 'Revenues' || 
        rowKey === 'totalRevenue' || 
        rowKey === 'Expenses' || 
        rowKey === 'totalExpense' || 
        rowKey === 'totalData'|| 
        rowKey === 'blank-row'
      ){
        return (
          <div className="not-expaned-cell-pl-report" />
        )
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  }

  const handleJobList = useCallback(async () => {
    try {
      setPLLoading(true);
        get(
          `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
        ).then((response) => {
          const jobList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const list = {
                label:
                  item?.code +
                  (item?.description ? " (" + item?.description + ")" : ""),
                value: item?.id
              };
              return list;
            });
        setJobOptions(jobList ? [...jobList, {label: 'Unassigned' , value: ''}] : [{label: 'Unassigned' , value: ''}]);
        setPLLoading(false);
      });
    } catch (e) {
      setPLLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  useEffect(() => {
    handleJobList();
  }, [handleJobList]);

  const handleTaskList = useCallback(async () => {
    try {
      setPLLoading(true);
        get(
          `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
        ).then((response) => {
          const taskList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const list = {
                label:
                  item?.code +
                  (item?.description ? " (" + item?.description + ")" : ""),
                value: item?.id
              };
              return list;
            });
        setTaskOptions(taskList ? [...taskList, {label: 'Unassigned' , value: ''}] : [{label: 'Unassigned' , value: ''}]);
        setPLLoading(false);
      });
    } catch (e) {
      setPLLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  useEffect(() => {
    handleTaskList();
  }, [handleTaskList]);

  const handleJobChange = (e) => {
    setSelectedJobs(e.value)
  }

  const handleTaskChange = (e) => {
    setSelectedTasks(e.value)
  }

  const handleApplyFilter = () => {
    plTableRef.current.rowExpandContext.state.expanded = [];
    filterJobInputRef.current.hide();
    filterTaskInputRef.current.hide();
    fetchPLData(selectedJobs, selectedTasks);
    fetchBankReconcileData(selectedJobs, selectedTasks);
  }

  const myJobResetFunction = () => {
    plTableRef.current.rowExpandContext.state.expanded = [];
    setSelectedJobs([]);
    filterJobInputRef && filterJobInputRef.current.focus();
    filterJobInputRef.current.hide();
    fetchPLData([], selectedTasks);
    fetchBankReconcileData([], selectedTasks);
  }

  const myTaskResetFunction = () => {
    plTableRef.current.rowExpandContext.state.expanded = [];
    setSelectedTasks([]);
    filterTaskInputRef && filterTaskInputRef.current.focus();
    filterTaskInputRef.current.hide();
    fetchPLData(selectedJobs, []);
    fetchBankReconcileData(selectedJobs, []);
  }

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"P&L Report"}
          />
          <div className="pl-report-body row">
          <div className="content-details pl-report-details">
            <div className="table-top-btn report-header-row row">
            <div className="report-name">
                <span> Profit and Loss Report</span>
              </div>
              <div className="table-btn">
                <div className="multiSelect-body-pl-report">
                  <div className="multiSelect-pl-report">
                    <label>Job: </label>
                    <MultiSelect value={selectedJobs} onChange={(e) => {handleJobChange(e)}} options={jobOptions} optionLabel="label" 
                    filter placeholder="Select Job" maxSelectedLabels={0} 
                    ref={filterJobInputRef}
                    selectedItemsLabel={`${selectedJobs?.length} Job(s) Selected`}
                    onHide={() => {
                      fetchPLData(selectedJobs, selectedTasks);
                      fetchBankReconcileData(selectedJobs, selectedTasks);
                    }}
                    panelFooterTemplate={
                      <>
                      <hr />
                      <Button
                      className="multiSelect-button"
                      onClick={() => {
                        handleApplyFilter();
                      }}
                      >
                        Apply
                      </Button>
                      <Button
                      className="multiSelect-button"
                      onClick={() => {
                        myJobResetFunction()
                      }}
                      >
                        Reset
                      </Button>
                      </>
                    }
                    />
                  </div>
                  <div className="multiSelect-pl-report">
                    <label>Task: </label>
                    <MultiSelect value={selectedTasks} onChange={(e) => {handleTaskChange(e)}} options={taskOptions} optionLabel="label" 
                    filter placeholder="Select Task" maxSelectedLabels={0}
                    ref={filterTaskInputRef}
                    selectedItemsLabel={`${selectedTasks?.length} Task(s) Selected`}
                    onHide={() => {
                      fetchPLData(selectedJobs, selectedTasks);
                      fetchBankReconcileData(selectedJobs, selectedTasks);
                    }}
                    panelFooterTemplate={
                      <>
                      <hr />
                    <Button
                      className="multiSelect-button"
                      onClick={() => {
                        handleApplyFilter();
                      }}
                      >
                        Apply
                      </Button>
                      <Button
                      className="multiSelect-button"
                      onClick={() => {
                        myTaskResetFunction()
                      }}
                      >
                        Reset
                      </Button>
                      </>
                    }
                    />
                  </div>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportLoading ? "Loading…" : "Export P&L"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={() => handleExportReport("csv")}>
                      CSV
                    </Dropdown.Item> */}
                    <Dropdown.Item onClick={() => handleExportReport("pdf", selectedJobs, selectedTasks)}>
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleExportReport("xlsx", selectedJobs, selectedTasks)}>
                      Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="summary-filter"
                style={{
                  padding: "0",
                  border: "none",
                  marginTop: "0px",
                  marginLeft: "20px"
                }}
              >
                <Form>
                  <DateRangePicker
                    placeholder="Select Date Range"
                    cleanable={false}
                    onChange={(e) => handleDateRange(e)}
                    format="dd-MM-yyyy"
                    value={dateValue}
                    placement="bottomEnd"
                    ranges={dateFilterRange}
                    locale={{ok: "Apply"}}
                    // shouldDisableDate={afterToday()}
                  />
                </Form>
              </div>
            </div>
            <div className="report-header-row">
              <div className="report-name">
                {startDate && endDate && (
                  <div className="report-period-name">
                    <span>For the period from {startDate} to {endDate} </span> 
                  </div>
                )}
              </div>
            </div>
            <div className="row">
            <div className="col-md-7">
              <div className="pl-report-table">
                {plLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  ref={plTableRef}
                  data={PLReportData}
                  columns={columns}
                  expandRow={expandRow}
                  noDataIndication="No Data Found"
                />
              </div>
            </div>
            <div className="col-md-5">
              <ChartForPLReport startDate={startDate} endDate={endDate} setPLLoading={setPLLoading} selectedJobs={selectedJobs} selectedTasks={selectedTasks} expRevChartData={expRevChartData} setExpRevChartData={setExpRevChartData} expChartData={expChartData} setExpChartData={setExpChartData}/>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PLReport;
