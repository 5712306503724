import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API, get } from "../../../config";
import Pagination from "../../common/Pagination";
import { formatName } from "../../common/Misc";
import Spin from "../../common/Spin";
import { debounce } from "lodash";
import InvitationsActionButton from "./InvitationsActionButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCheckCircle,
//   faXmarkCircle,
// } from "@fortawesome/free-solid-svg-icons";

const Invitation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invitationsList, setInvitationsList] = useState();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const fetchInvitations = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_INVITATIONS}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}`
      );
      setLoading(false);
      setLimit(data?.data?.invitations?.per_page);
      setPage(data?.data?.invitations?.current_page);
      setTotal(data?.data?.invitations?.total);
      setFrom(data?.data?.invitations?.from);
      setTo(data?.data?.invitations?.to);
      const invitationList = data?.data?.invitations?.data;
      setInvitationsList(invitationList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setLoading(false);
      setInvitationsList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, searchParam]);

  const handleInvitations = () => {
    fetchInvitations();
  };

  const invitationGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: invitationsList[i]?.id,
        invitation_id: invitationsList[i]?.id,
        invited_by: invitationsList[i]?.client?.client_details ? (
          <Link
            to={{
              pathname: `/client-dashboard/${invitationsList[i]?.client?.id}`,
            }}
          >
            {formatName(
              invitationsList[i]?.client?.client_details?.first_name,
              invitationsList[i]?.client?.client_details?.last_name
            )}
          </Link>
        ) : (
          ""
        ),
        invite_for: invitationsList[i]?.business?.business_name ? (
          parseInt(invitationsList[i]?.status) === 1 ? (
            <Link
              to={{
                pathname: `/business/${invitationsList[i]?.business?.id}`,
              }}
            >
              {invitationsList[i]?.business?.business_name}
            </Link>
          ) : (
            invitationsList[i]?.business?.business_name
          )
        ) : (
          ""
        ),
        email: invitationsList[i]?.email ? invitationsList[i]?.email : "",
        status: invitationsList[i]?.status
          ? (parseInt(invitationsList[i]?.status) === 1 &&
              // <FontAwesomeIcon
              //   icon={faCheckCircle}
              //   size="1x"
              //   title="Accepted"
              //   color="green"
              // />
              "Accepted") ||
            (parseInt(invitationsList[i]?.status) === 2 &&
              // <FontAwesomeIcon
              //   icon={faXmarkCircle}
              //   size="1x"
              //   title="Rejected"
              //   color="red"
              // />
              "Rejected") ||
            (parseInt(invitationsList[i]?.status) === 0 && "")
          : "",
        action: (
          <InvitationsActionButton
            id={invitationsList[i]?.id}
            handleInvitations={handleInvitations}
            status={invitationsList[i]?.status}
          />
        ),
      });
    }
    return items;
  };
  const invitations = invitationGenerator(invitationsList?.length);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  useEffect(() => {
    navigate(
      `/user-settings?tab=invitations-from-client?limit=${
        limit ? limit : 10
      }&page=${page ? page : 1}&search=${searchParam ? searchParam : ""}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page]);

  const columns = [
    {
      dataField: "invited_by",
      text: "Invited By",
      // sort: true,
    },
    {
      dataField: "invite_for",
      text: "Invite For",
      // sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      // headerAlign: "center",
      align: "center",
      className: "text-center",
      style: { width: "127px" },
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );
  
  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="content-details">
        <div className="table-top-btn">
          <div className="search-section">
            <Form>
              <FormControl
                onChange={handleSearchChange}
                type="text"
                value={searchParamData}
                placeholder="Search invitation"
              />
              {searchParamData 
                  ? <Button className="btn-close" onClick={() => {
                    setSearchParamData("")
                    setSearchParam("")
                  }}
                  >x</Button> 
                  : <Button className="fa fa-search">Search</Button>}
            </Form>
          </div>
        </div>
        <div className="custom-table">
          {loading && <Spin />}
          <BootstrapTable
            keyField="key"
            remote
            data={invitations}
            columns={columns}
            // onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default Invitation;
