import React from 'react';
import { useEffect } from 'react';
import { imageDefaultPrefixPath } from '../../../../../config';
import { checkIfFileExists } from '../../../../common/Misc';
import { useParams } from 'react-router-dom';
import { getBusinessData } from '../../../components/BusinessHelper';
import { useState } from 'react';

const QuotePreview = ({ type, values }) => {
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  return (
    <>
      {type === '0' ? (
        <>
          <div id="boxed-invoice">
            <div
              className="boxed-invoice"
              style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
            >
              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%' }}>
                      {BusinessLogo && (
                        <img
                          src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                          alt=""
                          style={{
                            maxWidth: '95px',
                            maxHeight: '80px',
                            marginRight: '5px'
                          }}
                        />
                      )}{' '}
                      {BusinessName}
                    </td>
                    <td style={{ textAlign: 'end', width: '50%' }}>
                      <p style={{ marginBottom: '0px' }}>##BUSINESS_STREET##</p>
                      <p style={{ margin: '0px' }}>
                        ##BUSINESS_SUBURB## ##BUSINESS_STATE## ##BUSINESS_POSTALCODE##
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr
                style={{
                  margin: 0,
                  marginTop: '8px',
                  marginBottom: '25px',
                  borderTop: '1px solid black',
                  width: '100%'
                }}
              />
              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                <tbody>
                  <tr style={{ display: 'flex', gap: '15px' }}>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        height: '115px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        width: '65%'
                      }}
                    >
                      {values?.client_option?.includes('Contact') ? (
                        <div>
                          {values?.client_option?.includes('AlternateName')
                            ? '##DISPLAY_NAME##'
                            : '##CONTACT_BUSINESS_NAME##'}
                          <>
                            <p style={{ margin: '0px' }}>##CONTACT_BUSINESS_STREET##</p>
                            <p style={{ margin: '0px' }}>
                              ##CONTACT_BUSINESS_SUBURB## ##CONTACT_BUSINESS_STATE##
                              ##CONTACT_BUSINESS_POSTALCODE##
                            </p>
                            {/* <p>{contactDetails}</p> */}
                          </>
                        </div>
                      ) : (
                        <div>
                          {values?.client_option?.includes('AlternateName')
                            ? '##DISPLAY_NAME##'
                            : '##CONTACT_NAME##'}
                          <>
                            <p style={{ margin: '0px' }}>##CONTACT_STREET##</p>
                            <p style={{ margin: '0px' }}>
                              ##CONTACT_SUBURB## ##CONTACT_STATE## ##CONTACT_POSTALCODE##
                            </p>
                            {/* <p>{contactDetails}</p> */}
                          </>
                        </div>
                      )}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                        height: '115px',
                        width: '35%'
                      }}
                    >
                      <h6 style={{ margin: '0px', fontSize: '16px' }}>QUOTE</h6>
                      <p
                        style={{
                          fontWeight: 'bold',
                          margin: '0px',
                          marginBottom: '9px',
                          marginTop: '7px'
                        }}
                      >
                        ##QUOTE_NUMBER##
                      </p>
                      <p style={{ margin: '0px' }}>##QUOTE_DATE##</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{
                  width: '100%',
                  marginTop: '20px',
                  border: '1px solid black',
                  fontFamily: 'Poppins',
                  borderSpacing: '0px'
                }}
                cellSpacing={0}
                cellPadding={0}
              >
                <thead>
                  <tr>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '50%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              textAlign: 'inherit',
                              color: values?.font_color,
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '50%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              textAlign: 'inherit',
                              color: values?.font_color,
                              background: values?.primary_color
                            }
                      }
                    >
                      Description
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Quantity
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Unit Price
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      GST
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Discount
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              padding: '8px',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              padding: '8px',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black'
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: '<p>TEST</p>' }}></span>
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $500.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $500
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={2}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $500.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $500.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={5}
                    >
                      Total Amount
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}
                    >
                      $500.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={5}
                    >
                      Total Discount
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}
                    >
                      $00.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        height: '37px'
                      }}
                      colSpan={6}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontWeight: 'bold'
                        }}
                      >
                        {values?.sub_total_option?.includes('Term') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Terms: </span>
                          </p>
                        ) : (
                          ''
                        )}
                        {values?.sub_total_option?.includes('ExpiryDate') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Expiry Date: </span>
                          </p>
                        ) : (
                          ''
                        )}
                        {values?.quote_detail_option?.includes('Amounts') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Amount Outstanding: $500.00</span>
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontStyle: 'italic',
                        textAlign: 'center'
                      }}
                    >
                      {/* <p style={{ margin: '20px 0px 10px 0px' }}>
                        Please detach the portion below and forward with your payment
                      </p> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="letter-invoice">
            <table
              style={{ width: '100%', fontFamily: 'Poppins', padding: '15px', fontSize: '12px' }}
            >
              <tbody>
                <tr>
                  <td>
                    <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ textAlign: 'right' }}>
                            {BusinessLogo && (
                              <img
                                src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                alt=""
                                style={{
                                  maxWidth: '95px',
                                  maxHeight: '80px',
                                  marginRight: '5px'
                                }}
                              />
                            )}{' '}
                            {BusinessName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '30%' }}>
                            <h2 style={{ fontSize: '20px', paddingBottom: '12px' }}>QUOTE</h2>
                            {values?.client_option?.includes('Contact') ? (
                              <>
                                {values?.client_option?.includes('AlternateName') ? (
                                  <p style={{ margin: '0px' }}>##DISPLAY_NAME##</p>
                                ) : (
                                  <p style={{ margin: '0px' }}>##CONTACT_BUSINESS_NAME##</p>
                                )}
                                <p style={{ margin: '0px' }}>##CONTACT_BUSINESS_STREET##</p>
                                <p style={{ margin: '0px' }}>
                                  ##CONTACT_BUSINESS_SUBURB## ##CONTACT_BUSINESS_STATE##
                                  ##CONTACT_BUSINESS_POSTALCODE##
                                </p>
                              </>
                            ) : (
                              <>
                                {values?.client_option?.includes('AlternateName') ? (
                                  <p style={{ margin: '0px' }}>##DISPLAY_NAME##</p>
                                ) : (
                                  <p style={{ margin: '0px' }}>##CONTACT_NAME##</p>
                                )}
                                <p style={{ margin: '0px' }}>##CONTACT_STREET##</p>
                                <p style={{ margin: '0px' }}>
                                  ##CONTACT_SUBURB## ##CONTACT_STATE## ##CONTACT_POSTALCODE##
                                </p>
                              </>
                            )}
                          </td>
                          <td style={{ width: '25%' }}></td>
                          <td style={{ width: '45%', textAlign: 'right' }}>
                            <table cellpadding="0" cellspacing="0" style={{ width: '100%' }}>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: 'top',
                                    textAlign: 'left',
                                    paddingRight: '15px'
                                  }}
                                >
                                  <p>
                                    <strong>Date</strong>
                                    <br />
                                    ##QUOTE_DATE##
                                  </p>
                                  {values?.sub_total_option?.includes('ExpiryDate') ? (
                                    <p>
                                      <strong>Expiry</strong>
                                      <br />
                                      ##QUOTE_EXPIRY##
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                  <p>
                                    <strong>Quote Number</strong>
                                    <br />
                                    ##QUOTE_NUMBER##
                                  </p>
                                </td>
                                <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                  <div>
                                    ##BUSINESS_NAME##
                                    <p style={{ margin: '0px' }}>##BUSINESS_STREET##</p>
                                    <p style={{ margin: '0px' }}>
                                      ##BUSINESS_SUBURB## ##BUSINESS_STATE## ##BUSINESS_POSTALCODE##
                                    </p>
                                    {/* <p>{contactDetails}</p> */}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      style={{ width: '100%', fontFamily: 'Poppins', marginTop: '55px' }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'left',
                                    width: '43%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'left',
                                    width: '43%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Description
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '5%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '5%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Quantity
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Unit Price
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            GST
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Discount
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Amount AUD
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: '5px',
                              textAlign: 'left',
                              borderBottom: '1px solid #ced4da'
                            }}
                          >
                            {/* <span
                                  dangerouslySetInnerHTML={{ __html: item?.description }}
                                ></span> */}
                            Development work - developer onsite per day
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            1
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            200.00
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            NO GST
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            0.00
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            200
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>Subtotal</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>200.00</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>TOTAL GST 10%</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>00.00</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>TOTAL Amount</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>200.00</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>TOTAL Discount</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>00.00</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td
                            style={{
                              padding: '5px',
                              borderTop: '1px solid #000',
                              textAlign: 'right',
                              fontWeight: 'bold',
                              borderBottom: '1px solid #000'
                            }}
                          >
                            TOTAL AUD
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderTop: '1px solid #000',
                              textAlign: 'right',
                              fontWeight: 'bold',
                              borderBottom: '1px solid #000'
                            }}
                          >
                            200.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default QuotePreview;
