import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Spin from "../../common/Spin";
import { toast } from "react-toastify";
import Form from "./components/Form";
import { API, fileUpload, get } from "../../../config";
import { AddChartOfAccountSchema } from "../../common/Validation";
import { Modal } from "react-bootstrap";

const AddChartOfAccounting = ({
  toggleAccountModal,
  businessId,
  fetchAccountData,
  showAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const [accountData, setAccountData] = useState();

  const [accountTpesLoading, setAccountTpesLoading] = useState();
  const [accountTypesList, setAccountTypesList] = useState();

  const fetchAccountTypes = useCallback(async () => {
    try {
      setAccountTpesLoading(true);
      const { data } = await get(`${API.GET_ACCOUNT_TYPES}`);
      setAccountTpesLoading(false);
      const accountTypesList = data.data;
      setAccountTypesList(accountTypesList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setAccountTpesLoading(false);
      setAccountTypesList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, []);

  const accuntingList = accountTypesList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const accountTypeValue = accuntingList?.find(
    (item) => item?.value === accountData?.account_type_id
  );

  const typeList = [
    { label: "Debit", value: "0" },
    { label: "Credit", value: "1" },
  ];
  const typeValue = typeList?.find(
    (item) => parseInt(item?.value) === accountData?.type
  );

  const taxList = [
    { label: "GST 10%", value: 1 },
    { label: "GST Excluded", value: 0 },
    { label: "BAS Excluded", value: 2 },
    { label: "GST on Imports", value: 3 },
  ];
  const taxValue = taxList?.find(
    (item) => parseInt(item?.value) === accountData?.tax
  );

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.account_type_id = value?.account_type_id?.value
      ? value?.account_type_id?.value
      : "";
    value.type = value?.type?.value ? parseInt(value?.type?.value) : "";
    value.tax = value?.tax ? parseInt(value?.tax?.value) : "";
    value.code = value?.code ? value?.code : "";
    value.sub_code = value?.sub_code ? value?.sub_code : "";

    formData.append("client_business_id", businessId);
    formData.append("account_type_id", value?.account_type_id);
    formData.append("type", value?.type);
    formData.append("tax", value?.tax);
    formData.append("code", value?.code);
    formData.append("sub_code", value?.sub_code);
    formData.append("name", value?.name ? value?.name : "");
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ACCOUNT_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleAccountModal();
        fetchAccountData();
      }
      return data?.data;
    } catch (error) {
      setAccountData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showAccount}
        onHide={toggleAccountModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Add Chart of Accounts
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              account_type_id: accountTypeValue,
              type: typeValue,
              tax: taxValue,
              name: accountData?.name,
              code: accountData?.code,
              sub_code: accountData?.sub_code,
              description: accountData?.description,
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddChartOfAccountSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleAccountModal={toggleAccountModal}
                    accuntingList={accuntingList}
                    accountTpesLoading={accountTpesLoading}
                    typeList={typeList}
                    taxList={taxList}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddChartOfAccounting;
