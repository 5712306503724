import React from 'react';
import { ErrorMessage, Field } from 'formik';
import InputMask from 'react-input-mask';
import BPAYLOGO from '../../../../../assets/images/bpay-logo.svg';
import PayID from '../../../../../assets/images/PayID_logo.svg';

const InvoiceFooterSettings = ({ values, handleChange, handleBlur, paymentTypes }) => {
  return (
    <div className="invoice-layout-detail-setings">
      <div className="main-setting-heading">Payment Advice Settings</div>
      {/* <div className="row">
        <div className="col-md-12">
          <div className="settings-box payment-option-box">
            <div id="advice-radio-group" className="settings-heading">
              Display Remittance Advice
            </div>
            <div role="group" aria-labelledby="advice-radio-group" className="client-option-body">
              <label>
                <Field type="radio" name="advice_option" value="FirstPage" />
                First Page Only
              </label>
              <label>
                <Field type="radio" name="advice_option" value="LastPage" />
                Last Page Only
              </label>
              <label>
                <Field type="radio" name="advice_option" value="AllPages" />
                All Pages
              </label>
              <label>
                <Field type="radio" name="advice_option" value="NonePage" />
                None
              </label>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <div className="settings-box payment-option-box">
            <div id="checkbox-group" className="settings-heading">
              Display
            </div>
            <div role="group" aria-labelledby="checkbox-group" className="client-option-body">
              {/* <label>
                <Field type="checkbox" name="footer_display_option" value="AlternateName" />
                Display Alternate Name
              </label>
              <label>
                <Field type="checkbox" name="footer_display_option" value="Contact" />
                Display Contact for Non-individual
              </label>
              <label>
                <Field type="checkbox" name="footer_display_option" value="InvoiceDate" />
                Display Invoice Date
              </label> */}
              <label>
                <Field type="checkbox" name="footer_display_option" value="CardDetails" />
                Display Credit Card Details
              </label>
              <label>
                <Field type="checkbox" name="footer_display_option" value="Amex" />
                Display Diners/AMEX
              </label>
              <label>
                <Field type="checkbox" name="footer_display_option" value="BpayDetails" />
                Display BPAY Details
              </label>
              <label>
                <Field type="checkbox" name="footer_display_option" value="PayIDDetails" />
                Display PayID Details
              </label>
              <label>
                <Field type="checkbox" name="footer_display_option" value="CustomerRef" />
                Display Customer Ref.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {values?.footer_display_option?.includes('BpayDetails') && (
          <div
            className={`${
              values?.footer_display_option.includes('PayIDDetails') ? 'col-md-6' : 'col-md-12'
            }`}
          >
            <div className="settings-box payment-option-box">
              <div id="direct-bank-group" className="settings-heading">
                <img src={BPAYLOGO} alt="b-pay-logo" height={30} /> <span>Settings</span>
              </div>
              <div>
                <div className="form-group" style={{ marginBottom: '15px', marginTop: '10px' }}>
                  <label>BPay Code</label>
                  <input
                    type="text"
                    name="bpayCode"
                    value={values?.bpayCode ? values?.bpayCode : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="bpayCode" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {values?.footer_display_option?.includes('PayIDDetails') && (
          <div
            className={`${
              values?.footer_display_option.includes('BpayDetails') ? 'col-md-6' : 'col-md-12'
            }`}
          >
            <div className="settings-box payment-option-box">
              <div id="direct-bank-group" className="settings-heading">
                <img src={PayID} alt="b-pay-logo" height={30} /> <span>Settings</span>
              </div>
              <div>
                <div className="form-group" style={{ marginBottom: '15px' }}>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={values?.email ? values?.email : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="email" />
                  </span>
                </div>
                <div className="form-group" style={{ marginBottom: '15px' }}>
                  <label>Mobile</label>
                  <InputMask
                    type="text"
                    name="mobile"
                    value={values?.mobile ? values?.mobile : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    mask="+61 999 999 999"
                    maskChar={null}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="mobile" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="settings-box payment-option-box">
            <div id="direct-bank-group" className="settings-heading">
              Direct/Bank Deposit
            </div>
            <div role="group" aria-labelledby="direct-bank-group" className="client-option-body">
              <label>
                <Field type="checkbox" name="direct_bank_option" value="DirectDebit" />
                Display Direct Debit Details
              </label>
              {/* <label>
                <Field type="checkbox" name="direct_bank_option" value="OurRef" />
                Display Show Ref code as Our Ref
              </label> */}
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="direct_bank_option" />
              </span>
            </div>
            <div>
              <div className="form-group" style={{ marginBottom: '15px' }}>
                <label>Account Name</label>
                <input
                  type="text"
                  name="accountName"
                  value={values?.accountName ? values?.accountName : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="accountName" />
                </span>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>BSB</label>
                  <InputMask
                    type="text"
                    name="bsb"
                    value={values?.bsb ? values?.bsb : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    mask="999-999"
                    maskChar={null}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="bsb" />
                  </span>
                </div>
                <div className="form-group col-md-6">
                  <label>Account Number.</label>
                  <input
                    type="text"
                    name="accountNumber"
                    value={values?.accountNumber ? values?.accountNumber : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="accountNumber" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="settings-box payment-option-box">
            <div id="direct-bank-group" className="settings-heading">
              Footer Text
            </div>
            <div>
              <div className="form-group">
                <input
                  type="text"
                  name="footerText"
                  value={values?.footerText ? values?.footerText : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="footerText" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFooterSettings;
