import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BootStrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spin from '../../../../common/Spin';
import Pagination from '../../../../common/Pagination';
import { API, get } from '../../../../../config';
import { formatNumber } from '../../../../common/Misc';
import CreditNoteActionButton from './CreditNoteActionButton';
import { getBusinessData } from '../../../components/BusinessHelper';
// import CreditNoteActionButton from './components/CreditNoteActionButton';

const CreditNote = ({ id }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [creditNoteList, setCreditNoteList] = useState([]);
  const [creditNoteLoading, setCreditNoteLoading] = useState(false);
  const [businessData, setBusinessData] = useState();
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;

  const fetchBusinessData = useCallback(async () => {
    try {
      setCreditNoteLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setCreditNoteLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
    // eslint-disable-next-line
  }, [businessId, BusinessName, BusinessLogo]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  const fetchCreditNotes = useCallback(async () => {
    try {
      setCreditNoteLoading(true);
      const { data } = await get(
        `${API.GET_CREDIT_NOTE}?client_business_id=${
          businessId ? businessId : ''
        }&invoice_id=${id}&limit=${limit ? limit : 10}&page=${page ? page : 1}`
      );
      setCreditNoteLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const creditNotesList = data.data.data;
      setCreditNoteList(creditNotesList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setCreditNoteLoading(false);
      setCreditNoteList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, businessId, id]);

  const handleDelete = () => {
    fetchCreditNotes();
  };

  const creditNotesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: creditNoteList[i]?.id,
        credit_number: creditNoteList[i]?.credit_number ? creditNoteList[i]?.credit_number : '',
        credit_date: creditNoteList[i]?.credit_date ? creditNoteList[i]?.credit_date : '',
        invoice_number: creditNoteList[i]?.invoice?.invoice_number
          ? creditNoteList[i]?.invoice?.invoice_number
          : '',
        invoice_total: creditNoteList[i]?.invoice?.total_payable_amount
          ? `$${formatNumber(Number(creditNoteList[i]?.invoice?.total_payable_amount))}`
          : '$0.00',
        invoice_amount_due: creditNoteList[i]?.invoice_amount_due
          ? `$${formatNumber(Number(creditNoteList[i]?.invoice_amount_due))}`
          : '$0.00',
        credit_amount: creditNoteList[i]?.credit_amount
          ? `$${formatNumber(Number(creditNoteList[i]?.credit_amount))}`
          : '$0.00',
        action: (
          <CreditNoteActionButton
            id={creditNoteList[i]?.id}
            send={creditNoteList[i]?.email_sent}
            email={creditNoteList[i]?.invoice?.email_to}
            handleDelete={handleDelete}
            businessData={businessData}
          />
        )
      });
    }
    return items;
  };

  const creditNotes = creditNotesGenerator(creditNoteList?.length);

  const columns = [
    {
      dataField: 'credit_number',
      text: 'Credit Number'
    },
    {
      dataField: 'credit_date',
      text: 'Credit Date'
    },
    {
      dataField: 'invoice_number',
      text: 'Invoice Number'
    },
    {
      dataField: 'invoice_total',
      text: 'Invoice Total'
    },
    {
      dataField: 'invoice_amount_due',
      text: 'Invoice Amount Due'
    },
    {
      dataField: 'credit_amount',
      text: 'Credit Amount'
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      style: { width: '127px' }
    }
  ];

  const handleTableChange = (type) => {
    if (type === 'sort') {
      setPage(1);
    }
  };

  useEffect(() => {
    navigate(`?limit=${limit ? limit : 10}&page=${page ? page : 1}`, {
      replace: true
    });
  }, [navigate, limit, page]);

  useEffect(() => {
    fetchCreditNotes();
    // window.document.title = 'SME CashBook | Credit Notes';
  }, [fetchCreditNotes]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <div className="content-details" style={{ padding: '5px' }}>
              <div className="table-top-btn">
                <div className="table-btn">
                  {/* <Button variant="primary" onClick={}>
                    Add New
                  </Button> */}
                </div>
              </div>
              <div className="custom-table standard-creditNote-table">
                {creditNoteLoading && <Spin />}
                <BootStrapTable
                  keyField="key"
                  remote
                  data={creditNotes}
                  columns={columns}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditNote;
