import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import BackButton from '../../../../../components/Form/BackButton';
import {
  checkIfFileExists,
  formatName,
  formatNumber,
  formatedAddress,
  pastFinancialdate
} from '../../../../common/Misc';
import moment from 'moment';
import { ErrorMessage, Formik } from 'formik';
import Select from 'react-select';
import { API, fileUpload, get, imageDefaultPrefixPath, metaTitle } from '../../../../../config';
import { getBusinessData } from '../../../components/BusinessHelper';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Modal, Pagination } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DatePicker } from 'rsuite';
import Spin from '../../../../common/Spin';

import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
// import { printComponent } from '../../../../common/print';
import CreatableSelect from 'react-select/creatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faAdd } from '@fortawesome/free-solid-svg-icons';
import { AddQuoteSchema } from '../../../../common/Validation';
import ReactInputMask from 'react-input-mask';
import AddNewContactModal from '../../../CashFlow/components/AddNewContactModal';
import AddNewJobModal from '../../../CashFlow/components/AddNewJobModal';
import AddNewTaskModal from '../../../CashFlow/components/AddNewTaskModal';
import CustomTooltip from '../../../../common/CustomTooltip';
import DiscountActionButton from '../../../DiscountCode/components/DiscountActionButton';
import AddDiscountCode from '../../../DiscountCode/components/AddDiscountCode';
import ParagrahActionButton from '../../../StandardParagrah/components/ParagrahActionButton';
import AddParagrah from '../../../StandardParagrah/components/AddParagrah';
import TitleBar from '../../../../common/TitleBar';
// import { AsyncPaginate } from 'react-select-async-paginate';

const AddQuote = () => {
  const today = new Date();
  const defaultDuedate = new Date(moment(today, 'DD-MM-YYYY').add('days', 14));
  const params = useParams();
  const location = useLocation();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const lastQuoteNumber = location?.state?.lastQuoteNumber;
  const numberOption = location?.state?.numberOption;

  const GlobalGSTTypeList = [
    {
      label: 'GST Excluded',
      value: 0
    },
    {
      label: 'GST Included',
      value: 1
    }
  ];

  const [globalGSTType, setGlobalGSTType] = useState(GlobalGSTTypeList[0]?.value);

  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);

  const [showInvoice, setShowInvoice] = useState(false);
  const [loadingJobList, setLoadingJobList] = useState(false);
  const [loadingTaskList, setLoadingTaskList] = useState(false);
  const [jobOptions, setJobOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [loadingOfficeList, setLoadingOfficeList] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);
  // const [clientOptions, setClientOptions] = useState([]);

  const [loadingUserList, setLoadingUserList] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [amountExcGST, setAmuntExcGST] = useState(0);
  const [totalGST, setTotalGST] = useState(0.0);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [dueDate, setDueDate] = useState(defaultDuedate);
  const [paymentTerm, setPaymentTerm] = useState();
  const [date, setDate] = useState(today);
  const [terms, setTerms] = useState(14);
  const [contactDetails, setContactDetails] = useState();
  const [businessData, setBusinessData] = useState();

  const [loadingTemplateList, setLoadingTemplateList] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const navigate = useNavigate();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [showParagraph, setShowParagraph] = useState(false);
  const [paragrahList, setParagrahList] = useState([]);
  const [paragrahLoading, setParagrahLoading] = useState(false);
  const [paragraphIndex, setParagraphIndex] = useState();
  const [paragraphFinalData, setParagraphFinalData] = useState();

  const [showDiscount, setShowDiscount] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [discountId, setDiscountCodeId] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [discountList, setDiscountsList] = useState([]);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [finalValues, setFinalValues] = useState();

  const [showSend, setShowSend] = useState(false);
  const [quoteNumber, setQuoteNumber] = useState(lastQuoteNumber);

  const [previewData, setPreviewData] = useState(null);
  const [previewHeaderData, setPreviewHeaderData] = useState(null);
  const [previewDataSettings, setPreviewDataSettings] = useState(1);

  const [loadingClientList, setLoadingClientList] = useState(false);
  const [allContactList, setAllContactList] = useState();
  const [office, setOffice] = useState();
  const [templateSaveDisable, setTemplateSaveDisable] = useState(false);

  const [addNewContact, setAddNewContact] = useState(false);
  const [newContact, setNewContact] = useState();
  const [newContactLastName, setNewContactLastName] = useState('');

  const [addNewJob, setAddNewJob] = useState(false);
  const [addNewTask, setAddNewTask] = useState(false);
  const [index, setIndex] = useState();

  const [newJob, setNewJob] = useState();
  const [newTask, setNewTask] = useState();

  const [newJobCode, setNewJobCode] = useState('');
  const [newTaskCode, setNewTaskCode] = useState('');
  const [discountIndex, setDiscountIndex] = useState(null);
  const [showAddDiscount, setShowAddDiscount] = useState(false);
  const [layoutwarnMessage, setLayoutWarnMessage] = useState();

  const [showAddParagrah, setShowAddParagrah] = useState(false);
  const [email, setEmail] = useState();

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  const handleAddNewJob = () => {
    setAddNewJob(!addNewJob);
  };

  const handleAddNewTask = () => {
    setAddNewTask(!addNewTask);
  };

  const clientId = businessData?.client_id
    ? businessData?.client_id
    : localStorage.getItem('sme-clientId');

  const [clientID, setClientID] = useState(clientId);

  const fetchPaymentOptionData = useCallback(async () => {
    try {
      setLoading(true);
      await get(`${API.GET_QUOTE_SETTINGS}?client_business_id=${businessId}`).then((response) => {
        let term = response?.data?.data?.payment_terms
          ? Number(response?.data?.data?.payment_terms)
          : 14;
        setPaymentTerm(term);
        setTerms(term);
        setDueDate(new Date(moment(today, 'DD-MM-YYYY').add('days', term)));
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [businessId]);

  useEffect(() => {
    fetchPaymentOptionData();
  }, [fetchPaymentOptionData]);

  const fetchBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setClientID(data?.client_id);
      setLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
  }, [businessId]);

  useEffect(() => {
    var date1 = String(date)?.includes('-')
      ? new Date(
          String(date)?.split('-')[2],
          String(date)?.split('-')[1] - 1,
          String(date)?.split('-')[0]
        )
      : date;
    var date2 = String(dueDate)?.includes('-')
      ? new Date(
          String(dueDate)?.split('-')[2],
          String(dueDate)?.split('-')[1] - 1,
          String(dueDate)?.split('-')[0]
        )
      : dueDate?._d
      ? dueDate?._d
      : dueDate;
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setTerms(Difference_In_Days);
    // eslint-disable-next-line
  }, [dueDate, date]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    window.document.title = `${metaTitle} | Add Quote`;
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const GSTOptions = [
    { label: 'GST 10%', value: 1 },
    { label: 'GST Excluded', value: 0 },
    { label: 'BAS Excluded', value: 2 },
    { label: 'GST on Imports', value: 3 }
  ];

  const toggleInvoiceModal = () => {
    setShowInvoice(!showInvoice);
  };

  const current_financial_year = pastFinancialdate[0].getUTCFullYear();

  const defaultEntryData = [
    {
      id: 1,
      job_id: '',
      task_id: '',
      description: '',
      editorState: null,
      quantity: '1',
      discount_id: '',
      discount: '',
      discount_code: '',
      discount_type: '',
      discount_amount: '',
      unit_price: '',
      financial_year: current_financial_year,
      gst_type: GSTOptions[0],
      amount: '0.00',
      gst: '0.00',
      amount_inc_GST: '0.00'
    }
  ];

  const [finalData, setFinalData] = useState(defaultEntryData);

  const newEntryData = {
    id: finalData.length + 1,
    job_id: '',
    task_id: '',
    description: '',
    editorState: null,
    quantity: '1',
    discount_id: '',
    discount: '',
    discount_code: '',
    discount_type: '',
    discount_amount: '',
    unit_price: '',
    financial_year: current_financial_year,
    gst_type: GSTOptions[0],
    amount: '0.00',
    gst: '0.00',
    amount_inc_GST: '0.00'
  };

  const handleAddEntry = (data) => {
    setLoading(false);
    setFinalData([...data, newEntryData]);
  };

  const handleDeleteEntry = (data, index) => {
    data.splice(index, 1);
    setFinalData([...data]);
  };

  const handleTemplateList = useCallback(async () => {
    try {
      setLoadingTemplateList(true);
      get(`${API.INVOICE_SETTINGS}?client_business_id=${businessId}&limit=-1&type=quote`).then(
        (response) => {
          const templatesList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const list = {
                label: item?.settings?.name,
                value: item?.id,
                settings: item?.settings,
                is_default: item?.is_default
              };
              return list;
            });
          setTemplateOptions(templatesList ? templatesList : []);
          if (templatesList) {
            setPreviewDataSettings(templatesList.find((i) => i.is_default === 1)?.settings);
            setTemplateSaveDisable(false);
            setLayoutWarnMessage();
          } else {
            setLayoutWarnMessage('Add Quote, Invoice and Receipt template first');
            setTemplateSaveDisable(true);
          }
          setLoadingTemplateList(false);
          handleJobList();
        }
      );
      let { data } = await get(
        `${API.INVOICE_SETTINGS}?client_business_id=${businessId}&limit=-1&type=invoice`
      );
      if (data.data.data?.length === 0) {
        setLayoutWarnMessage('Add Invoice and Receipt template first');
        setTemplateSaveDisable(true);
      } else {
        setLayoutWarnMessage();
        setTemplateSaveDisable(false);
      }
    } catch (e) {
      setLoadingTemplateList(false);
      toast.error(e?.data?.message);
      handleJobList();
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleJobList = useCallback(async () => {
    try {
      setLoadingJobList(true);
      get(
        `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const jobsList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id,
              tax: item?.tax
            };
            return list;
          });
        setJobOptions(
          jobsList
            ? [...jobsList, { label: <strong>+ Add New Job</strong>, value: 'new' }]
            : [{ label: <strong>+ Add New Job</strong>, value: 'new' }]
        );
        setLoadingJobList(false);
        handleTaskList();
      });
    } catch (e) {
      setLoadingJobList(false);
      toast.error(e?.data?.message);
      handleTaskList();
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleTaskList = useCallback(async () => {
    try {
      setLoadingTaskList(true);
      get(
        `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const tasksList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id,
              tax: item?.tax
            };
            return list;
          });
        setTaskOptions(
          tasksList
            ? [...tasksList, { label: <strong>+ Add New Task</strong>, value: 'new' }]
            : [{ label: <strong>+ Add New Task</strong>, value: 'new' }]
        );
        setLoadingTaskList(false);
        handleClientList();
      });
    } catch (e) {
      setLoadingTaskList(false);
      toast.error(e?.data?.message);
      handleClientList();
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleOfficeList = useCallback(async () => {
    try {
      setLoadingOfficeList(true);
      get(
        `${API.GET_OFFICE}?client_business_id=${businessId}${
          clientID ? `&client_id=${clientID}` : ''
        }`
      ).then((response) => {
        const officesList =
          response &&
          response?.data?.data?.offices?.data?.length > 0 &&
          response?.data?.data?.offices?.data?.map((item) => {
            const name = formatedAddress(
              item?.street,
              item?.suburb?.name,
              item?.state?.short_name,
              item?.postal_code
            );
            const id = item?.id;
            const items = { name, id, ...item };
            const { name: label, id: value, ...rest } = items;
            return { label, value, ...rest };
          });
        setOfficeOptions(officesList ? officesList : []);
        setLoadingOfficeList(false);
      });
    } catch (e) {
      setLoadingOfficeList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [clientID, businessId]);

  useEffect(() => {
    handleOfficeList();
  }, [handleOfficeList]);

  const handleClientList = useCallback(async () => {
    try {
      setLoadingClientList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                  email: item?.email
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                  email: item?.email
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                  email: item?.email
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && {
            label: <div className="list-subHeading">Contractors</div>,
            options: contractorList
          },
          supplierList && {
            label: <div className="list-subHeading">Suppliers</div>,
            options: supplierList
          },
          customerList && {
            label: <div className="list-subHeading">Customers</div>,
            options: customerList
          },
          { label: <strong>+ Add New Contact</strong>, value: 'new' }
        ];

        setAllContactList(allContactListing ? allContactListing : []);

        setLoading(false);
        setLoadingClientList(false);
        // handleUserList();
      });
    } catch (e) {
      setLoadingClientList(false);
      toast.error(e?.data?.message);
      // handleUserList();
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleNewClientList = useCallback(async () => {
    try {
      setLoadingClientList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                  email: item?.email
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                  email: item?.email
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                  email: item?.email
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && {
            label: <div className="list-subHeading">Contractors</div>,
            options: contractorList
          },
          supplierList && {
            label: <div className="list-subHeading">Suppliers</div>,
            options: supplierList
          },
          customerList && {
            label: <div className="list-subHeading">Customers</div>,
            options: customerList
          },
          { label: <strong>+ Add New Contact</strong>, value: 'new' }
        ];

        setAllContactList(allContactListing ? allContactListing : []);
        setLoading(false);
        setLoadingClientList(false);
      });
    } catch (e) {
      setLoadingClientList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleNewJobList = useCallback(async () => {
    try {
      setLoadingJobList(true);
      get(
        `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const jobsList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id,
              tax: item?.tax
            };
            return list;
          });
        setJobOptions(
          jobsList
            ? [...jobsList, { label: <strong>+ Add New Job</strong>, value: 'new' }]
            : [{ label: <strong>+ Add New Job</strong>, value: 'new' }]
        );
        setLoadingJobList(false);
      });
    } catch (e) {
      setLoadingJobList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleNewTaskList = useCallback(async () => {
    try {
      setLoadingTaskList(true);
      get(
        `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const tasksList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id,
              tax: item?.tax
            };
            return list;
          });
        setTaskOptions(
          tasksList
            ? [...tasksList, { label: <strong>+ Add New Task</strong>, value: 'new' }]
            : [{ label: <strong>+ Add New Task</strong>, value: 'new' }]
        );
        setLoadingTaskList(false);
      });
    } catch (e) {
      setLoadingTaskList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const handleUserList = useCallback(async () => {
    try {
      setLoadingUserList(true);
      get(
        `${clientID ? API.GET_CLIENT_USER : API.GET_USER}?client_business_id=${businessId}${
          clientID ? `&client_id=${clientID}` : ''
        }`
      ).then((response) => {
        if (clientID) {
          const officesList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const name = formatName(item?.first_name, item?.last_name);
              const id = item?.id;
              const items = { name, id };
              const { name: label, id: value, ...rest } = items;
              return { label, value, ...rest };
            });
          setUserOptions(officesList ? officesList : []);
        } else {
          const officesList =
            response &&
            response?.data?.data?.users?.data?.length > 0 &&
            response?.data?.data?.users?.data?.map((item) => {
              const name = formatName(item?.first_name, item?.last_name);
              const id = item?.id;
              const items = { name, id };
              const { name: label, id: value, ...rest } = items;
              return { label, value, ...rest };
            });
          setUserOptions(officesList ? officesList : []);
        }
        setLoadingUserList(false);
      });
    } catch (e) {
      setLoadingUserList(false);
      toast.error(e?.data?.message);
    }
    // eslint-disable-next-line
  }, [clientID, businessId]);

  useEffect(() => {
    handleUserList();
  }, [handleUserList]);

  useEffect(() => {
    handleTemplateList();
  }, [handleTemplateList]);

  const toggleSendModal = (values) => {
    setShowSend(!showSend);
    setFinalValues(values);
  };

  const handleSubmit = async (value) => {
    const formData = new FormData();
    formData.append('date', typeof date != 'string' ? moment(today).format('DD-MM-YYYY') : date);
    formData.append(
      'due_date',
      typeof dueDate != 'string' ? moment(dueDate).format('DD-MM-YYYY') : dueDate
    );
    formData.append('email_to', value?.email_to ? value?.email_to : '');
    formData.append('contact_id', value?.invoice_to ? value?.invoice_to?.value : '');
    formData.append('office_id', value?.office_id ? value?.office_id?.value : '');
    formData.append('template_id', value?.template ? value?.template?.value : '');
    formData.append('quote_number', value?.quote_number ? value?.quote_number : '');
    formData.append('gst_type', value?.gst_type ? value?.gst_type?.value : '');
    // formData.append("prepared_by", value?.prepared_by ? value?.prepared_by?.value : '');
    // formData.append('status', 0);
    formData.append('prepared_by', value?.prepared_by ? value?.prepared_by?.value : '');
    formData.append('client_business_id', businessId);
    formData.append('send', 0);
    finalData?.forEach((item, index) => {
      if (item) {
        formData.append(`quote_items[${index}][job_id]`, item?.job_id ? item?.job_id : '');
        formData.append(`quote_items[${index}][task_id]`, item?.task_id ? item?.task_id : '');
        formData.append(
          `quote_items[${index}][description]`,
          item?.description ? item?.description : ''
        );
        formData.append(
          `quote_items[${index}][unit_price]`,
          item?.unit_price ? item?.unit_price : ''
        );
        formData.append(`quote_items[${index}][quantity]`, item?.quantity ? item?.quantity : '');
        formData.append(`quote_items[${index}][gst]`, item?.gst_type ? item?.gst_type?.value : '');
        formData.append(
          `quote_items[${index}][discount_id]`,
          item?.discount_id ? item?.discount_id : ''
        );
        formData.append(`quote_items[${index}][discount]`, item?.discount ? item?.discount : '');
        formData.append(
          `quote_items[${index}][discount_code]`,
          item?.discount_code ? item?.discount_code : ''
        );
        formData.append(
          `quote_items[${index}][discount_type]`,
          item?.discount_type === 0 ? 0 : item?.discount_type === 1 ? 1 : ''
        );
        formData.append(
          `quote_items[${index}][discount_amount]`,
          item?.discount_amount ? item?.discount_amount : ''
        );
        formData.append(
          `quote_items[${index}][financial_year]`,
          item?.financial_year ? item?.financial_year : ''
        );
        formData.append(`quote_items[${index}][net_amount]`, item?.amount ? item?.amount : '');
        formData.append(`quote_items[${index}][gst_amount]`, item?.gst ? item?.gst : '');
        formData.append(
          `quote_items[${index}][amount]`,
          item?.amount_inc_GST ? item?.amount_inc_GST : ''
        );
      }
    });
    formData.append('discount_id', discountId ? discountId : '');
    formData.append('discount', discountCode ? discountCode?.discount : '');
    formData.append('discount_code', discountCode ? discountCode?.code : '');
    formData.append('discount_type', discountCode ? discountCode?.discount_type : '');
    formData.append('discount_amount', discountAmount ? discountAmount.toFixed(2) : '0.00');
    formData.append('total_amount', amountExcGST ? amountExcGST.toFixed(2) : '0.00');
    formData.append('total_gst_amount', totalGST ? totalGST.toFixed(2) : '0.00');
    formData.append(
      'total_net_amount',
      amountExcGST || totalGST ? (amountExcGST + totalGST).toFixed(2) : '0.00'
    );
    formData.append('total_payable_amount', totalAmount ? totalAmount.toFixed(2) : '0.00');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.QUOTES_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate(-1);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleSendEmailQuote = async (value, send) => {
    const formData = new FormData();
    formData.append('date', typeof date != 'string' ? moment(today).format('DD-MM-YYYY') : date);
    formData.append('email_to', value?.email_to ? value?.email_to : '');
    formData.append(
      'due_date',
      typeof dueDate != 'string' ? moment(dueDate).format('DD-MM-YYYY') : dueDate
    );
    formData.append('contact_id', value?.invoice_to ? value?.invoice_to?.value : '');
    formData.append('office_id', value?.office_id ? value?.office_id?.value : '');
    formData.append('template_id', value?.template ? value?.template?.value : '');
    formData.append('quote_number', value?.quote_number ? value?.quote_number : '');
    formData.append('gst_type', value?.gst_type ? value?.gst_type?.value : '');
    formData.append('prepared_by', value?.prepared_by ? value?.prepared_by?.value : '');
    formData.append('client_business_id', businessId);
    // formData.append("prepared_by", value?.prepared_by ? value?.prepared_by?.value : '');
    if (send) {
      formData.append('send', send);
    }
    finalData?.forEach((item, index) => {
      if (item) {
        formData.append(`quote_items[${index}][job_id]`, item?.job_id ? item?.job_id : '');
        formData.append(`quote_items[${index}][task_id]`, item?.task_id ? item?.task_id : '');
        formData.append(
          `quote_items[${index}][description]`,
          item?.description ? item?.description : ''
        );
        formData.append(
          `quote_items[${index}][unit_price]`,
          item?.unit_price ? item?.unit_price : ''
        );
        formData.append(`quote_items[${index}][quantity]`, item?.quantity ? item?.quantity : '');
        formData.append(`quote_items[${index}][gst]`, item?.gst_type ? item?.gst_type?.value : '');
        formData.append(
          `quote_items[${index}][discount_id]`,
          item?.discount_id ? item?.discount_id : ''
        );
        formData.append(`quote_items[${index}][discount]`, item?.discount ? item?.discount : '');
        formData.append(
          `quote_items[${index}][discount_code]`,
          item?.discount_code ? item?.discount_code : ''
        );
        formData.append(
          `quote_items[${index}][discount_type]`,
          item?.discount_type === 0 ? 0 : item?.discount_type === 1 ? 1 : ''
        );
        formData.append(
          `quote_items[${index}][discount_amount]`,
          item?.discount_amount ? item?.discount_amount : ''
        );
        formData.append(
          `quote_items[${index}][financial_year]`,
          item?.financial_year ? item?.financial_year : ''
        );
        formData.append(`quote_items[${index}][net_amount]`, item?.amount ? item?.amount : '');
        formData.append(`quote_items[${index}][gst_amount]`, item?.gst ? item?.gst : '');
        formData.append(
          `quote_items[${index}][amount]`,
          item?.amount_inc_GST ? item?.amount_inc_GST : ''
        );
      }
    });
    formData.append('discount_id', discountId ? discountId : '');
    formData.append('discount', discountCode ? discountCode?.discount : '');
    formData.append('discount_code', discountCode ? discountCode?.code : '');
    formData.append('discount_type', discountCode ? discountCode?.discount_type : '');
    // formData.append('status', 0);
    formData.append('discount_amount', discountAmount ? discountAmount.toFixed(2) : '0.00');
    formData.append('total_amount', amountExcGST ? amountExcGST.toFixed(2) : '0.00');
    formData.append('total_gst_amount', totalGST ? totalGST.toFixed(2) : '0.00');
    // formData.append('total_net_amount', totalAmount ? totalAmount.toFixed(2) : '0.00');
    formData.append(
      'total_net_amount',
      amountExcGST || totalGST ? (amountExcGST + totalGST).toFixed(2) : '0.00'
    );
    formData.append('total_payable_amount', totalAmount ? totalAmount.toFixed(2) : '0.00');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.QUOTES_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate(-1);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleNewJobSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, job_id: val?.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleNewTaskSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, task_id: val?.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleNewGSTSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        let amount_q = Number(obj.quantity) * Number(obj.unit_price);
        if (String(val.value) === '1') {
          let gst_price =
            globalGSTType === 1
              ? Number(amount_q) - Number(amount_q) / 1.1
              : Number(amount_q).toFixed(2) * 1.1 - Number(amount_q).toFixed(2);
          let amount_inc = globalGSTType === 1 ? Number(amount_q) : Number(amount_q) + gst_price;
          return {
            ...obj,
            amount: (globalGSTType === 1
              ? Number(obj.amount) - Number(gst_price)
              : Number(obj.amount)
            ).toFixed(2),
            gst_type: val,
            gst: String(gst_price.toFixed(2)),
            amount_inc_GST: obj?.discount_id
              ? obj.discount_type
                ? ((Number(amount_inc) * obj?.discount) / 100).toFixed(2)
                : (Number(amount_inc) - obj?.discount).toFixed(2)
              : String(amount_inc.toFixed(2)),
            discount_amount: obj?.discount_id
              ? obj?.discount_type === 1
                ? ((Number(amount_inc) * obj?.discount) / 100).toFixed(2)
                : obj?.discount
              : ''
          };
        } else {
          return {
            ...obj,
            amount: amount_q,
            gst_type: val,
            gst: '0.00',
            amount_inc_GST: obj?.discount_id
              ? obj.discount_type
                ? ((Number(amount_q) * obj?.discount) / 100).toFixed(2)
                : (Number(amount_q) - obj?.discount).toFixed(2)
              : String(Number(amount_q).toFixed(2)),
            discount_amount: obj?.discount_id
              ? obj?.discount_type === 1
                ? ((Number(amount_q) * obj?.discount) / 100).toFixed(2)
                : obj?.discount
              : ''
          };
        }
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleFinancial = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, financial_year: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleQuantity = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      let qunatity_no = e.target.value;
      if (i === index) {
        let unit_price = obj.unit_price;
        let amount_q = Number(qunatity_no) * Number(unit_price);
        let gstType = obj.gst_type;
        if (gstType.value === 1) {
          let gst_price =
            globalGSTType === 1
              ? Number(amount_q) - Number(amount_q) / 1.1
              : Number(amount_q).toFixed(2) * 1.1 - Number(amount_q).toFixed(2);
          let amount_inc = globalGSTType === 1 ? Number(amount_q) : amount_q + gst_price;
          return {
            ...obj,
            quantity: e.target.value,
            unit_price: unit_price,
            amount: unit_price
              ? String(
                  Number(
                    globalGSTType === 1 ? Number(amount_q) - Number(gst_price) : Number(amount_q)
                  ).toFixed(2)
                )
              : '0.00',
            gst: String(gst_price.toFixed(2)),
            amount_inc_GST: obj?.discount_id
              ? obj.discount_type
                ? ((Number(amount_inc) * obj?.discount) / 100).toFixed(2)
                : (Number(amount_inc) - obj?.discount).toFixed(2)
              : String(amount_inc.toFixed(2)),
            discount_amount: obj?.discount_id
              ? obj.discount_type === 1
                ? ((Number(amount_inc) * obj?.discount) / 100).toFixed(2)
                : obj.discount
              : ''
            // discount_amount: obj?.discount_id
            //   ? (Number(e.target.value) * obj?.discount).toFixed(2)
            //   : ''
          };
        } else {
          return {
            ...obj,
            quantity: e.target.value,
            unit_price: unit_price,
            amount: unit_price ? String(amount_q.toFixed(2)) : '0.00',
            gst: '0.00',
            amount_inc_GST: amount_q
              ? obj?.discount_id
                ? obj.discount_type
                  ? ((Number(amount_q) * obj?.discount) / 100).toFixed(2)
                  : (Number(amount_q) - obj?.discount).toFixed(2)
                : String(amount_q.toFixed(2))
              : '0.00',
            discount_amount: obj?.discount_id
              ? obj.discount_type === 1
                ? ((Number(amount_q) * obj?.discount) / 100).toFixed(2)
                : obj.discount
              : ''
            // discount_amount: obj?.discount_id
            //   ? (Number(e.target.value) * obj?.discount).toFixed(2)
            //   : ''
          };
        }
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleUnitPrice = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      let unit = e.target.value;
      if (i === index) {
        let quantity_no = obj.quantity;

        let amount_q = Number(quantity_no) * Number(unit);
        let gstType = obj.gst_type;
        if (gstType.value === 1) {
          let gst_price =
            globalGSTType === 1
              ? Number(amount_q) - Number(amount_q) / 1.1
              : Number(amount_q).toFixed(2) * 1.1 - Number(amount_q).toFixed(2);
          let amount_inc = globalGSTType === 1 ? Number(amount_q) : amount_q + gst_price;
          return {
            ...obj,
            amount: String(
              Number(
                globalGSTType === 1 ? Number(amount_q) - Number(gst_price) : Number(amount_q)
              ).toFixed(2)
            ),
            unit_price: e.target.value,
            gst: String(gst_price.toFixed(2)),
            amount_inc_GST: obj?.discount_id
              ? obj.discount_type
                ? ((Number(amount_inc) * obj?.discount) / 100).toFixed(2)
                : (Number(amount_inc) - obj?.discount).toFixed(2)
              : String(amount_inc.toFixed(2)),
            discount_amount: obj?.discount_id
              ? obj.discount_type === 1
                ? ((Number(amount_inc) * obj?.discount) / 100).toFixed(2)
                : obj.discount
              : ''
          };
        } else {
          return {
            ...obj,
            amount: String(amount_q.toFixed(2)),
            unit_price: e.target.value,
            gst: '0.00',
            amount_inc_GST: obj?.discount_id
              ? obj.discount_type
                ? ((Number(amount_q) * obj?.discount) / 100).toFixed(2)
                : (Number(amount_q) - obj?.discount).toFixed(2)
              : String(amount_q.toFixed(2)),
            discount_amount: obj?.discount_id
              ? obj.discount_type === 1
                ? ((Number(amount_q) * obj?.discount) / 100).toFixed(2)
                : obj.discount
              : ''
          };
        }
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleTotal = (finalData) => {
    setTotalGST(0);
    setAmuntExcGST(0);
    setTotalAmount(0);
    setDiscountAmount(0);
    let tempTotal_ex_amount = 0;
    let tempTotal_gst = 0;
    let tempTotal_amount = 0;
    let tempTotal_discount = 0;
    finalData?.forEach((item, index) => {
      if (item) {
        setTotalAmount(item.amount_inc_GST);
        tempTotal_ex_amount = tempTotal_ex_amount + Number(item?.amount);
        tempTotal_gst = tempTotal_gst + Number(item?.gst);
        tempTotal_amount = tempTotal_amount + Number(item?.amount_inc_GST);
      }
    });
    if (finalData?.find((i) => i.discount_id !== '') !== undefined) {
      finalData?.forEach((item, index) => {
        if (item) {
          tempTotal_discount = tempTotal_discount + Number(item?.discount_amount);
        }
      });
    } else {
      if (tempTotal_amount !== 0) {
        if (discountCode?.discount) {
          tempTotal_discount =
            discountCode?.discount_type === 0
              ? discountCode?.discount
              : (tempTotal_amount * discountCode?.discount) / 100;
          discountCode?.discount_type === 0
            ? (tempTotal_amount = tempTotal_amount - discountCode?.discount)
            : (tempTotal_amount =
                tempTotal_amount - ((tempTotal_amount * discountCode?.discount) / 100).toFixed(2));
        }
      }
    }

    setAmuntExcGST(tempTotal_ex_amount);
    setTotalGST(tempTotal_gst);
    setTotalAmount(tempTotal_amount);
    setDiscountAmount(tempTotal_discount);
  };

  const handleContactSelection = async (e) => {
    try {
      setLoading(true);
      const { data } = await get(`${API.CONTACT_DETAILS}/${e?.value}`);
      setContactDetails(data?.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const fetchDiscounts = useCallback(async () => {
    try {
      setDiscountLoading(true);
      const { data } = await get(
        `${API.GET_DISCOUNT_CODE}?client_business_id=${businessId ? businessId : ''}&limit=${
          limit ? limit : 10
        }&page=${page ? page : 1}`
      );
      setDiscountLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const discountsList = data.data.data;
      setDiscountsList(discountsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setDiscountLoading(false);
      setDiscountsList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, businessId]);

  // useEffect(() => {
  //   fetchDiscounts();
  // }, [fetchDiscounts]);

  const handlFetchDiscount = () => {
    fetchDiscounts();
  };

  const discountsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: discountList[i]?.id,
        id: discountList[i]?.id,
        code: discountList[i]?.code ? discountList[i]?.code : '',
        description: discountList[i]?.description ? discountList[i]?.description : '',
        discount: discountList[i]?.discount ? discountList[i]?.discount : 0,
        discount_type: discountList[i]?.discount_type,
        discount_format: discountList[i]?.discount
          ? discountList[i]?.discount_type === 0
            ? `$${discountList[i]?.discount}`
            : `${discountList[i]?.discount}%`
          : '',
        action: (
          <DiscountActionButton
            id={discountList[i]?.id}
            handleDelete={handlFetchDiscount}
            handleEdit={handlFetchDiscount}
          />
        )
      });
    }
    return items;
  };

  const togglePreviewDiscount = () => {
    setDiscountIndex(null);
    if (!showDiscount) {
      fetchDiscounts();
    }
    setShowDiscount(!showDiscount);
  };

  const togglePreviewDiscountItem = (index) => {
    if (!showDiscount) {
      fetchDiscounts();
      setDiscountIndex(index);
    } else {
      setDiscountIndex(null);
    }
    setShowDiscount(!showDiscount);
  };

  const toggleAddDiscountModal = () => {
    setShowAddDiscount(!showAddDiscount);
  };

  const discounts = discountsGenerator(discountList?.length);

  const rowDiscountEvents = {
    onClick: (e, row, rowIndex) => {
      let tempCode = row;
      if (discountIndex !== null) {
        finalData[discountIndex].discount_code = row?.code ? row?.code : '';
        finalData[discountIndex].discount = row?.discount ? row?.discount : '';
        finalData[discountIndex].discount_type = row?.discount_type ? row?.discount_type : 0;
        finalData[discountIndex].discount_id = row?.id ? row?.id : '';
        // finalData[discountIndex].discount_amount = row?.discount_type
        //   ? ((Number(finalData[discountIndex]?.amount_inc_GST) * row?.discount) / 100).toFixed(2)
        //   : (Number(finalData[discountIndex]?.amount_inc_GST) - row?.discount).toFixed(2);
        finalData[discountIndex].amount_inc_GST =
          Number(finalData[discountIndex].amount) + Number(finalData[discountIndex].gst);
        finalData[discountIndex].discount_amount = row?.discount
          ? row?.discount_type
            ? ((Number(finalData[discountIndex]?.amount_inc_GST) * row?.discount) / 100).toFixed(2)
            : row?.discount
          : 0;

        finalData[discountIndex].amount_inc_GST = row?.discount_type
          ? (
              Number(finalData[discountIndex]?.amount_inc_GST) -
              (Number(finalData[discountIndex]?.amount_inc_GST) * Number(row?.discount)) / 100
            ).toFixed(2)
          : (Number(finalData[discountIndex]?.amount_inc_GST) - row?.discount).toFixed(2);
        togglePreviewDiscountItem();
      } else {
        setDiscountCode(tempCode);
        setDiscountCodeId(row?.id);
        togglePreviewDiscount();
      }
    }
  };

  const columns = [
    {
      dataField: 'code',
      text: 'Code'
    },
    {
      dataField: 'description',
      text: 'Description'
    },
    {
      dataField: 'discount_format',
      text: 'Discount'
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      style: { width: '127px' }
    }
  ];

  useEffect(() => {
    handleTotal(finalData);
    // eslint-disable-next-line
  }, [finalData, discountCode, discountIndex, globalGSTType]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleDiscountClear = () => {
    setDiscountCode({
      code: ''
    });
    setDiscountCodeId('');
  };

  const handleParagrahDescription = (item, index, finalData) => {
    const contentBlock = htmlToDraft(item);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const value = EditorState.createWithContent(contentState) || null;
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: item, editorState: value };
      }
      return obj;
    });
    setFinalData(newArr);
    toggleStandardParagraph();
  };

  const handleEditorChange = async (state, index, finalData) => {
    // if (
    //   convertToRaw(state.getCurrentContent()).blocks.length === 1 &&
    //   convertToRaw(state.getCurrentContent()).blocks[0].text === ''
    // ) {
    //   const newArr = finalData.map((obj, i) => {
    //     if (i === index) {
    //       return {
    //         ...obj,
    //         description: i.description ? i.description + '' : '',
    //         editorState: null
    //       };
    //     }
    //     return obj;
    //   });
    //   setFinalData(newArr);
    // } else {
    let html = draftToHtml(convertToRaw(state.getCurrentContent()));
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: html, editorState: state };
      }
      return obj;
    });
    setFinalData(newArr);
    // }
  };

  const fetchParagrahs = useCallback(async () => {
    try {
      setParagrahLoading(true);
      const { data } = await get(
        `${API.GET_STANDARD_PARAGRAH}?client_business_id=${businessId ? businessId : ''}&limit=${
          limit ? limit : 10
        }&page=${page ? page : 1}`
      );
      setParagrahLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const paragrahsList = data.data.data;
      setParagrahList(paragrahsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setParagrahLoading(false);
      setParagrahList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, businessId]);

  const handleParagraphFetch = () => {
    fetchParagrahs();
  };

  const toggleAddParagrahModal = () => {
    setShowAddParagrah(!showAddParagrah);
  };

  const paragraphsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: paragrahList[i]?.id,
        business_job_id:
          // <div
          //   style={{ cursor: 'pointer' }}
          //   onClick={() =>
          //     handleParagrahDescription(
          //       paragrahList[i]?.description,
          //       paragraphIndex,
          //       paragraphFinalData
          //     )
          //   }
          // >
          // {
          paragrahList[i]?.business_job?.code ? paragrahList[i]?.business_job?.code : '-',
        // }
        // </div>
        description: paragrahList[i]?.description ? paragrahList[i]?.description : '',
        description_format: (
          <div
            className="standard-paragrah-list-desc"
            onClick={() =>
              handleParagrahDescription(
                paragrahList[i]?.description,
                paragraphIndex,
                paragraphFinalData
              )
            }
            dangerouslySetInnerHTML={{
              __html: paragrahList[i]?.description
            }}
          ></div>
        ),
        action: (
          <ParagrahActionButton
            id={paragrahList[i]?.id}
            handleDelete={handleParagraphFetch}
            handleEdit={handleParagraphFetch}
          />
        )
      });
    }
    return items;
  };

  const rowParagrahEvents = {
    onClick: (e, row, rowIndex) => {
      handleParagrahDescription(row?.description, paragraphIndex, paragraphFinalData);
    }
  };

  const paragraphs = paragraphsGenerator(paragrahList?.length);

  const paragraphColumns = [
    {
      dataField: 'business_job_id',
      text: 'Job'
    },
    {
      dataField: 'description_format',
      text: 'Description'
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      style: { width: '127px' }
    }
  ];

  const toggleStandardParagraph = (index, finalData) => {
    setParagraphFinalData(finalData);
    setParagraphIndex(index);
    if (!showParagraph) {
      fetchParagrahs();
    }
    setShowParagraph(!showParagraph);
  };

  // useEffect(() => {
  //   fetchParagrahs();
  // }, [fetchParagrahs]);

  // function printFile() {
  //   return printComponent('print-invoice');
  // }

  const previewDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: previewData[i]?.id,
        job_id: previewData[i]?.job_id?.label ? previewData[i]?.job_id?.label : '',
        task_id: previewData[i]?.task_id?.label ? previewData[i]?.task_id?.label : '',
        description: previewData[i]?.description ? previewData[i]?.description : '',
        financial_year: previewData[i]?.financial_year ? previewData[i]?.financial_year : '',
        quantity: previewData[i]?.quantity ? previewData[i]?.quantity : '',
        unit_price: previewData[i]?.unit_price
          ? `$${formatNumber(Number(previewData[i]?.unit_price))}`
          : '$0.00',
        gst_type: previewData[i]?.gst_type?.label ? previewData[i]?.gst_type?.label : '',
        discount_amount: previewData[i]?.discount_amount
          ? `$${formatNumber(Number(previewData[i]?.discount_amount))}`
          : '$0.00',
        amount: previewData[i]?.amount
          ? `$${formatNumber(Number(previewData[i]?.amount))}`
          : '$0.00',
        gst: previewData[i]?.gst ? `$${formatNumber(Number(previewData[i]?.gst))}` : '$0.00',
        amount_inc_GST: previewData[i]?.amount_inc_GST
          ? `$${formatNumber(Number(previewData[i]?.amount_inc_GST))}`
          : '$0.00'
      });
    }
    return items;
  };

  const previewDatas = previewDataGenerator(previewData?.length);

  const handleTemplateSelection = (e) => {
    setPreviewDataSettings(e?.settings);
  };

  const saveDisabled = (values) => {
    if (values && values?.length >= 1) {
      const checkData = values?.map((item, index) => {
        if (item?.description && item?.unit_price && item?.quantity && item?.financial_year) {
          return false;
        } else {
          return true;
        }
      });
      return checkData?.find((item) => item === true) || templateSaveDisable ? true : false;
    } else {
      return true;
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          {/* <h1 className="page-title">
            <BackButton />
            Add Quote
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1> */}
          <TitleBar label={'Add Quote'} BusinessName={BusinessName} />
          <div className="content-details" style={{ padding: '0px' }}>
            <Formik
              initialValues={{
                quote_number: quoteNumber,
                invoice_to: newContact ? newContact : '',
                email_to: email ? email : '',
                prepared_by: '',
                office_id: '',
                gst_type: GlobalGSTTypeList[0],
                template: templateOptions ? templateOptions?.find((i) => i.is_default === 1) : '',
                date: moment(today).format('DD-MM-YYYY'),
                dueDate: moment(dueDate).format('DD-MM-YYYY')
              }}
              enableReinitialize
              onSubmit={handleSubmit}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={AddQuoteSchema}
            >
              {({
                values,
                setErrors,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                handleBlur
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="content-block" style={{ width: 'auto', margin: '15px' }}>
                      <div className="content-details-header">
                        <h6>Basic Detail</h6>
                      </div>
                      <div className="invoice-detail-body">
                        <div className="form-group row">
                          <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">
                                <span className="required">*</span>Quote Number :
                              </span>
                              <div className="half-width">
                                <ReactInputMask
                                  type="text"
                                  mask="Q999999"
                                  maskChar={null}
                                  className="number-disable"
                                  placeholder="Enter Quote Number"
                                  onChange={(e) => {
                                    setFieldValue(`quote_number`, e?.target?.value);
                                    setQuoteNumber(e?.target?.value);
                                  }}
                                  onBlur={(e) => {
                                    let numberData = e?.target?.value?.split('Q')[1];
                                    if (numberData?.length < 6) {
                                      let newNumber = padWithLeadingZeros(Number(numberData), 6);
                                      setFieldValue(`quote_number`, `Q${newNumber}`);
                                      setQuoteNumber(`Q${newNumber}`);
                                    } else {
                                      setFieldValue(`quote_number`, `Q${numberData}`);
                                      setQuoteNumber(`Q${numberData}`);
                                    }
                                  }}
                                  disabled={Number(numberOption) === 1 ? true : false}
                                  value={values?.quote_number ? values?.quote_number : ''}
                                  name="quote_number"
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="quote_number" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">
                                <span className="required">*</span>Quote To :
                              </span>
                              <div className="half-width">
                                <CreatableSelect
                                  name="invoice_to"
                                  formatCreateLabel={(userInput) => (
                                    <>
                                      <strong>+ Add New Contact </strong>'{`${userInput}`}'
                                    </>
                                  )}
                                  options={allContactList ? allContactList : []}
                                  isLoading={loadingClientList}
                                  onChange={(e) => {
                                    if (e?.value === 'new' || e?.__isNew__ === true) {
                                      if (e?.__isNew__ === true) {
                                        setNewContactLastName(e?.label);
                                      } else {
                                        setNewContactLastName('');
                                      }
                                      setAddNewContact(true);
                                    } else {
                                      setFieldValue(`invoice_to`, e ? e : '');
                                      setFieldValue(`email_to`, e?.email ? e?.email : '');
                                      setNewContact(e);
                                      setEmail(e?.email ? e?.email : '');
                                      handleContactSelection(e);
                                    }
                                    // setFieldValue(`invoice_to`, e ? e : '');
                                    // handleContactSelection(e);
                                  }}
                                  value={values?.invoice_to ? values?.invoice_to : ''}
                                  placeholder="Select Contact"
                                  classNamePrefix="selectbox"
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: 'grey'
                                    }
                                  })}
                                  additional={{
                                    page: 1
                                  }}
                                />
                                {/* <AsyncPaginate
                                name="invoice_to"
                                value={values?.invoice_to ? values?.invoice_to : ''}
                                loadOptions={loadContactOptions}
                                onChange={(e) => {
                                  setFieldValue(`invoice_to`, e ? e : '');
                                  handleContactSelection(e);
                                }}
                                placeholder="Select Contact"
                                classNamePrefix="selectbox"
                                additional={{
                                  page: 1,
                                }}
                              /> */}
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="invoice_to" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">
                                <span className="required">*</span>Email To :
                              </span>
                              <div className="half-width">
                                <input
                                  type="text"
                                  placeholder="Enter Email"
                                  onChange={(e) => {
                                    setFieldValue(`email_to`, e.target.value);
                                    setEmail(e.target.value);
                                  }}
                                  name="email_to"
                                  value={values?.email_to ? values?.email_to : ''}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="email_to" />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-2 col-md-6" style={{ marginBottom: "15px" }}>
                            <div className=''>
                            <span className="invoice-title"><span className="required">*</span>Prepared By :</span>
                            <div className="half-width">
                              <Select
                                name="prepared_by"
                                options={userOptions ? userOptions : []}
                                onChange={(e) => {
                                  setFieldValue(`prepared_by`, e ? e : '');
                                  handleContactSelection(e);
                                }}
                                value={values?.prepared_by ? values?.prepared_by : ''}
                                isLoading={
                                  userOptions.length === 0 || loadingUserList ? true : false
                                }
                                placeholder="Select User"
                                classNamePrefix="selectbox"
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: 'grey'
                                  }
                                })}
                              />
                              <span
                                className="text-danger"
                                style={{ color: "red", fontSize: "20px" }}
                              >
                                <ErrorMessage name="prepared_by" />
                              </span>
                            </div>
                            </div>
                          </div> */}
                          <div className="col-lg-3 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">
                                {/* <span className="required">*</span> */}
                                Office :
                              </span>
                              <div className="">
                                <span>
                                  <Select
                                    name="office_id"
                                    isLoading={loadingOfficeList}
                                    style={{ width: '100%' }}
                                    options={officeOptions ? officeOptions : []}
                                    onChange={(e) => {
                                      setFieldValue(`office_id`, e ? e : '');
                                      setOffice(e);
                                    }}
                                    value={values?.office_id ? values?.office_id : ''}
                                    placeholder="Select Office"
                                    classNamePrefix="selectbox"
                                    onBlur={handleBlur}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: 'grey'
                                      }
                                    })}
                                  />
                                </span>
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="office_id" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">
                                <span className="required">*</span>Layout :
                              </span>
                              <div className="">
                                <span>
                                  <Select
                                    name="template"
                                    style={{ width: '100%' }}
                                    options={templateOptions ? templateOptions : []}
                                    onChange={(e) => {
                                      setFieldValue(`template`, e ? e : '');
                                      handleTemplateSelection(e);
                                    }}
                                    // isLoading={
                                    //   templateOptions.length === 0 || loadingTemplateList
                                    //     ? true
                                    //     : false
                                    // }
                                    isLoading={loadingTemplateList}
                                    value={values?.template ? values?.template : ''}
                                    placeholder="Select Layout"
                                    classNamePrefix="selectbox"
                                    onBlur={handleBlur}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: 'grey'
                                      }
                                    })}
                                  />
                                </span>
                                {layoutwarnMessage && (
                                  <span
                                    className="text-danger"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {layoutwarnMessage}
                                  </span>
                                )}
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="template" />
                                </span>
                                <div
                                  className="invoice-preview-template-button"
                                  onClick={() => {
                                    setPreviewData(finalData);
                                    setPreviewHeaderData(values);
                                    toggleInvoiceModal();
                                  }}
                                >
                                  <i className="fa fa-eye invoice-preview-eye"></i>
                                  <span className="invoice-preview-template">Preview</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                          {/* <div className="row invoice-body form-group"> */}
                          <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">GST :</span>
                              <div className="half-width">
                                <Select
                                  name="gst_type"
                                  options={GlobalGSTTypeList ? GlobalGSTTypeList : []}
                                  // isLoading={loadingClientList}
                                  onChange={(e, clearAction) => {
                                    setFieldValue(`gst_type`, e ? e : '');
                                    setGlobalGSTType(e?.value);
                                    if (finalData) {
                                      for (let m = 0; m < finalData?.length; m++) {
                                        let amountQty =
                                          Number(finalData[m].quantity) *
                                          Number(finalData[m].unit_price);
                                        if (e?.value === 1) {
                                          if (finalData[m].gst !== '0.00') {
                                            finalData[m].gst = Number(
                                              Number(amountQty) - Number(amountQty) / 1.1
                                            ).toFixed(2);
                                          }
                                          // let amountInc =
                                          //   Number(amountQty) +
                                          //   Number(Number(amountQty) - Number(amountQty) / 1.1);
                                          let amountInc = Number(amountQty);
                                          finalData[m].amount =
                                            Number(amountQty) -
                                            Number(Number(amountQty) - Number(amountQty) / 1.1);
                                          finalData[m].discount_amount = finalData[m]?.discount
                                            ? finalData[m]?.discount_type
                                              ? (
                                                  (Number(amountInc) * finalData[m]?.discount) /
                                                  100
                                                ).toFixed(2)
                                              : finalData[m]?.discount
                                            : 0;
                                          if (finalData[m].amount_inc_GST !== '0.00') {
                                            finalData[m].amount_inc_GST = finalData[m].discount_id
                                              ? finalData[m].discount_type
                                                ? (
                                                    (Number(amountInc) * finalData[m].discount) /
                                                    100
                                                  ).toFixed(2)
                                                : (
                                                    Number(amountInc) - finalData[m].discount
                                                  ).toFixed(2)
                                              : String(amountInc.toFixed(2));
                                          }
                                        }
                                        if (e?.value === 0) {
                                          if (finalData[m].gst !== '0.00') {
                                            finalData[m].gst = Number(
                                              Number(amountQty) * 1.1 - Number(amountQty)
                                            ).toFixed(2);
                                          }
                                          finalData[m].amount = Number(amountQty);
                                          let amountInc =
                                            Number(amountQty) +
                                            Number(Number(amountQty) * 1.1 - Number(amountQty));

                                          finalData[m].discount_amount = finalData[m]?.discount
                                            ? finalData[m]?.discount_type
                                              ? (
                                                  (Number(amountInc) * finalData[m]?.discount) /
                                                  100
                                                ).toFixed(2)
                                              : finalData[m]?.discount
                                            : 0;
                                          if (finalData[m].amount_inc_GST !== '0.00') {
                                            finalData[m].amount_inc_GST = finalData[m].discount_id
                                              ? finalData[m].discount_type
                                                ? (
                                                    (Number(amountInc) * finalData[m].discount) /
                                                    100
                                                  ).toFixed(2)
                                                : (
                                                    Number(amountInc) - finalData[m].discount
                                                  ).toFixed(2)
                                              : String(amountInc.toFixed(2));
                                          }
                                        }
                                      }
                                    }
                                  }}
                                  value={values?.gst_type ? values?.gst_type : ''}
                                  placeholder="Select GST"
                                  classNamePrefix="selectbox"
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: 'grey'
                                    }
                                  })}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="gst_type" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="invoice-date-body">
                              <span className="invoice-title">
                                <span className="required">*</span>Date :{' '}
                              </span>
                              <DatePicker
                                onChange={(e) => {
                                  setFieldValue(
                                    'date',
                                    moment(e, 'DD-MM-YYYY').format('DD-MM-YYYY')
                                  );
                                  setDate(moment(e, 'DD-MM-YYYY').format('DD-MM-YYYY'));
                                  setFieldValue(
                                    'dueDate',
                                    moment(e, 'DD-MM-YYYY')
                                      .add('days', paymentTerm ? paymentTerm : 14)
                                      .format('DD-MM-YYYY')
                                  );
                                  setDueDate(
                                    moment(e, 'DD-MM-YYYY')
                                      .add('days', paymentTerm ? paymentTerm : 14)
                                      .format('DD-MM-YYYY')
                                  );
                                }}
                                value={
                                  String(date)?.includes('-')
                                    ? new Date(
                                        String(date)?.split('-')[2],
                                        String(date)?.split('-')[1] - 1,
                                        String(date)?.split('-')[0]
                                      )
                                    : new Date(date)
                                }
                                name="date"
                                cleanable={false}
                                defaultValue={today}
                                format="dd-MM-yyyy"
                                disabledDate={(date) => moment(date).isAfter(moment())}
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="invoice-date-body">
                              <span className="invoice-title">
                                <span className="required">*</span>Due Date :{' '}
                              </span>
                              <DatePicker
                                cleanable={false}
                                // value={dueDate && new Date(dueDate)}
                                onChange={(e) => {
                                  setDueDate(moment(e, 'DD-MM-YYYY').format('DD-MM-YYYY'));
                                  setFieldValue(
                                    'dueDate',
                                    moment(e, 'DD-MM-YYYY').format('DD-MM-YYYY')
                                  );
                                }}
                                value={
                                  String(dueDate)?.includes('-')
                                    ? new Date(
                                        String(dueDate)?.split('-')[2],
                                        String(dueDate)?.split('-')[1] - 1,
                                        String(dueDate)?.split('-')[0]
                                      )
                                    : new Date(dueDate)
                                }
                                name="dueDate"
                                defaultValue={defaultDuedate}
                                format="dd-MM-yyyy"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6" style={{ marginBottom: '15px' }}>
                            <div className="">
                              <span className="invoice-title">Prepared By :</span>
                              <div className="half-width">
                                <Select
                                  name="prepared_by"
                                  options={userOptions ? userOptions : []}
                                  onChange={(e) => {
                                    setFieldValue(`prepared_by`, e ? e : '');
                                  }}
                                  value={values?.prepared_by ? values?.prepared_by : ''}
                                  isLoading={
                                    userOptions.length === 0 || loadingUserList ? true : false
                                  }
                                  placeholder="Select User"
                                  classNamePrefix="selectbox"
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: 'grey'
                                    }
                                  })}
                                />
                                <span
                                  className="text-danger"
                                  style={{ color: 'red', fontSize: '20px' }}
                                >
                                  <ErrorMessage name="prepared_by" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-with-footer">
                          <div
                            className="invoice-table custom-table tab-table"
                            style={{ marginTop: '15px' }}
                          >
                            {loadingTaskList && loadingJobList && <Spin />}
                            {loading && <Spin />}
                            <div className="react-bootstrap-table">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Job</th>
                                    <th>Task</th>
                                    <th>Description</th>
                                    <th>Financial Year</th>
                                    <th>Qty</th>
                                    <th>
                                      Unit Price
                                      {globalGSTType === 1 ? (
                                        <p className="global-gst-unit-price">(GST Included)</p>
                                      ) : globalGSTType === 0 ? (
                                        <p className="global-gst-unit-price">(GST Excluded)</p>
                                      ) : (
                                        <></>
                                      )}
                                    </th>
                                    <th>GST Type</th>
                                    <th style={{ textAlign: 'right' }}>Amount</th>
                                    <th style={{ textAlign: 'right' }}>GST</th>
                                    <th style={{ textAlign: 'right' }}>Discount</th>
                                    <th style={{ textAlign: 'right' }}>Amount Inc. GST</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {finalData &&
                                    finalData?.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <div className="form-group">
                                              <CreatableSelect
                                                formatCreateLabel={(userInput) => (
                                                  <>
                                                    <strong>+ Add New Job </strong>'{`${userInput}`}
                                                    '
                                                  </>
                                                )}
                                                placeholder="Select Job"
                                                // loading={jobOptions.length === 0 ? true : false}
                                                isLoading={loadingJobList}
                                                classNamePrefix="selectbox"
                                                options={jobOptions}
                                                onChange={(id) => {
                                                  if (
                                                    id?.value === 'new' ||
                                                    id?.__isNew__ === true
                                                  ) {
                                                    if (id?.__isNew__ === true) {
                                                      setNewJobCode(id?.label);
                                                    } else {
                                                      setNewJobCode('');
                                                    }
                                                    setIndex(index);
                                                    setAddNewJob(true);
                                                  } else {
                                                    handleNewJobSelection(id, index, finalData);
                                                    // setNewJob(id);
                                                  }
                                                }}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,
                                                    primary: 'grey'
                                                  }
                                                })}
                                                isClearable={true}
                                                // value={
                                                //   item?.job_id &&
                                                //   jobOptions?.length > 0 &&
                                                //   jobOptions?.find(
                                                //     (val) => val?.id === item?.job_id
                                                //   ) &&
                                                //   item?.job_id
                                                // }
                                                value={
                                                  item?.job_id &&
                                                  jobOptions?.length > 0 &&
                                                  jobOptions?.find(
                                                    (val) => val?.value === item?.job_id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-group">
                                              <CreatableSelect
                                                formatCreateLabel={(userInput) => (
                                                  <>
                                                    <strong>+ Add New Task </strong>'
                                                    {`${userInput}`}'
                                                  </>
                                                )}
                                                placeholder="Select Task"
                                                // loading={taskOptions.length === 0 ? true : false}
                                                isLoading={loadingTaskList}
                                                classNamePrefix="selectbox"
                                                options={taskOptions}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,
                                                    primary: 'grey'
                                                  }
                                                })}
                                                isClearable={true}
                                                onChange={(id) => {
                                                  if (
                                                    id?.value === 'new' ||
                                                    id?.__isNew__ === true
                                                  ) {
                                                    if (id?.__isNew__ === true) {
                                                      setNewTaskCode(id?.label);
                                                    } else {
                                                      setNewTaskCode('');
                                                    }
                                                    setIndex(index);
                                                    setAddNewTask(true);
                                                  } else {
                                                    handleNewTaskSelection(id, index, finalData);
                                                    setNewTask(id);
                                                  }
                                                  // handleNewTaskSelection(id, index, finalData);
                                                }}
                                                // value={
                                                //   item?.task_id &&
                                                //   taskOptions?.length > 0 &&
                                                //   taskOptions?.find(
                                                //     (val) => val?.id === item?.task_id
                                                //   ) &&
                                                //   item?.task_id
                                                // }
                                                value={
                                                  item?.task_id &&
                                                  taskOptions?.length > 0 &&
                                                  taskOptions?.find(
                                                    (val) => val?.value === item?.task_id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-group invoice-description">
                                              {/* <textarea
                                            required
                                            type="text"
                                            name="description"
                                            placeholder="Add Description"
                                            onChange={(e) => {
                                              handleDescription(e, index, finalData);
                                            }}
                                            value={ item?.description ? item?.description.replace(/<[^>]+>/g, '') : ''}
                                            onBlur={handleBlur}
                                            rules={[
                                              {
                                                pattern:
                                                  new RegExp(
                                                    /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                  ),
                                                message:
                                                  "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                              },
                                            ]}
                                            style={{
                                              border: "1px solid #d9d9d9",
                                              padding: "5px 15px",
                                              fontSize: "14px",
                                              borderRadius: "10px",
                                            }}
                                          /> */}
                                              <Editor
                                                toolbarHidden
                                                editorState={
                                                  item?.editorState ? item.editorState : null
                                                }
                                                onEditorStateChange={(state) =>
                                                  handleEditorChange(state, index, finalData)
                                                }
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                stripPastedStyles={true}
                                              />
                                              <span
                                                className="invoice-paragraph"
                                                onClick={() =>
                                                  toggleStandardParagraph(index, finalData)
                                                }
                                              >
                                                <i className="fa fa-ellipsis-v"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td style={{ width: '7%' }}>
                                            <div className="form-group">
                                              <input
                                                type="number"
                                                name="financial_year"
                                                placeholder="Add Year"
                                                onChange={(e) => {
                                                  handleFinancial(e, index, finalData);
                                                }}
                                                value={
                                                  item?.financial_year ? item?.financial_year : ''
                                                }
                                                onBlur={handleBlur}
                                                rules={[
                                                  {
                                                    pattern: new RegExp(/^\d*\.?\d*$/),
                                                    message: 'Enter Valid Financial Year'
                                                  }
                                                ]}
                                                style={{
                                                  border: '1px solid #d9d9d9',
                                                  padding: '5px 15px',
                                                  fontSize: '14px'
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'right', width: '6%' }}>
                                            <div className="form-group">
                                              <input
                                                type="number"
                                                name="quantity"
                                                placeholder="Add Quantity"
                                                onChange={(e) => {
                                                  handleQuantity(e, index, finalData);
                                                }}
                                                value={item?.quantity ? item?.quantity : ''}
                                                onBlur={handleBlur}
                                                rules={[
                                                  {
                                                    pattern: new RegExp(/^\d*\.?\d*$/),
                                                    message: 'Enter Valid Qunatity'
                                                  }
                                                ]}
                                                style={{
                                                  border: '1px solid #d9d9d9',
                                                  padding: '5px 15px',
                                                  fontSize: '14px'
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'right', width: '8%' }}>
                                            <div className="form-group">
                                              <input
                                                type="number"
                                                name="unit_price"
                                                placeholder="Add Price"
                                                onChange={(e) => {
                                                  handleUnitPrice(e, index, finalData);
                                                }}
                                                step=".01"
                                                value={item?.unit_price ? item?.unit_price : ''}
                                                onBlur={handleBlur}
                                                rules={[
                                                  {
                                                    pattern: new RegExp(/^\d*\.?\d*$/),
                                                    message: 'Enter Valid Unit Price'
                                                  }
                                                ]}
                                                style={{
                                                  border: '1px solid #d9d9d9',
                                                  padding: '5px 15px',
                                                  fontSize: '14px'
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td style={{ width: '9%' }}>
                                            <div className="form-group">
                                              <CreatableSelect
                                                placeholder="Select GST"
                                                loading={GSTOptions.length === 0 ? true : false}
                                                classNamePrefix="selectbox"
                                                options={GSTOptions}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,
                                                    primary: 'grey'
                                                  }
                                                })}
                                                onChange={(id) => {
                                                  handleNewGSTSelection(id, index, finalData);
                                                }}
                                                styles={{
                                                  width: '85%'
                                                }}
                                                defaultValue={GSTOptions[0].value}
                                                value={
                                                  item?.gst_type &&
                                                  GSTOptions?.length > 0 &&
                                                  GSTOptions?.find(
                                                    (val) => val?.value === item?.gst_type.value
                                                  ) &&
                                                  item?.gst_type
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'right', width: '8%' }}>
                                            <div
                                              style={{ textAlign: 'right' }}
                                              className="form-group"
                                            >
                                              {item?.amount
                                                ? `$${formatNumber(Number(item?.amount))}`
                                                : '$0'}
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'right', width: '8%' }}>
                                            <div>
                                              {/* {gstType === 1 ? amount ? `$${(amount * 0.1).toFixed(2)}` : "-" : "-"} */}
                                              {item?.gst
                                                ? `$${formatNumber(Number(item?.gst))}`
                                                : '$0'}
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'right', width: '10%' }}>
                                            <div className="form-group">
                                              {item?.discount_amount
                                                ? `$${formatNumber(Number(item?.discount_amount))}`
                                                : '$0.00'}
                                              {!discountId && (
                                                <CustomTooltip
                                                  text={
                                                    item?.discount_id
                                                      ? item?.discount_type === 0
                                                        ? `$${formatNumber(Number(item?.discount))}`
                                                        : `${item?.discount}%`
                                                      : 'Discount'
                                                  }
                                                  children={
                                                    <span
                                                      className="view-discount"
                                                      onClick={() => {
                                                        if (item?.discount_id) {
                                                          const newArr = finalData.map((obj, i) => {
                                                            if (i === index) {
                                                              return {
                                                                ...obj,
                                                                discount_id: '',
                                                                discount: '',
                                                                discount_code: '',
                                                                discount_type: '',
                                                                discount_amount: '',
                                                                amount_inc_GST:
                                                                  Number(obj.amount) +
                                                                  Number(obj.gst)
                                                              };
                                                            }
                                                            return obj;
                                                          });
                                                          setFinalData(newArr);
                                                        } else {
                                                          togglePreviewDiscountItem(index);
                                                        }
                                                      }}
                                                    >
                                                      {item?.discount_id ? (
                                                        <i className="fa fa-times"></i>
                                                      ) : (
                                                        <i className="fas fa-ellipsis-h"></i>
                                                      )}
                                                    </span>
                                                  }
                                                />
                                              )}
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'right', width: '12%' }}>
                                            <div>
                                              {item?.amount_inc_GST
                                                ? `$${formatNumber(Number(item?.amount_inc_GST))}`
                                                : '$0'}
                                            </div>
                                          </td>
                                          <td style={{ textAlign: 'center' }}>
                                            {index + 1 === finalData?.length ? (
                                              <>
                                                <FontAwesomeIcon
                                                  onClick={() => {
                                                    handleAddEntry(finalData, index);
                                                  }}
                                                  icon={faAdd}
                                                  size="1x"
                                                />
                                                {index > 0 && (
                                                  <>
                                                    <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        handleDeleteEntry(finalData, index);
                                                      }}
                                                      icon={faTrash}
                                                      size="1x"
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <FontAwesomeIcon
                                                onClick={() => {
                                                  handleDeleteEntry(finalData, index);
                                                }}
                                                icon={faTrash}
                                                size="1x"
                                              />
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="invoice-discount-body">
                            <div className="invoice-discount-form">
                              <div className="invoice-discount-input form-group">
                                <div className="invoice-discount-body">
                                  <span className="invoice-title">Discount</span>
                                </div>
                                <div className="invoice-discount-area">
                                  <input
                                    type="text"
                                    name="discount"
                                    value={
                                      totalAmount ? (discountCode ? discountCode?.code : '') : ''
                                    }
                                    placeholder="Enter Discount Value"
                                    style={{ width: 'fit-content' }}
                                  />
                                  {totalAmount && discountCode?.code ? (
                                    <span
                                      className="menu-icon"
                                      onClick={() => handleDiscountClear()}
                                    >
                                      <i className="fa fa-times"></i>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {finalData?.find((i) => i.discount_id !== '') !== undefined ? (
                                    <></>
                                  ) : (
                                    <>
                                      <span
                                        className="view-discount"
                                        onClick={() => togglePreviewDiscount()}
                                      >
                                        <i className="fas fa-ellipsis-h"></i>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              {/* <button type="button" className="btn btn-primary invoice-apply-btn" disabled={totalAmount && discountCode?.code ? true : false} onClick={() => {
                                  applyDiscount(discountSearch)
                                }}>{totalAmount ? (discountCode?.code ? "Applied" : "Apply") : 'Apply'}</button> */}
                            </div>
                            <div>
                              <div className="invoice-gst-title">
                                <span className="invoice-data-space">Amount Ex. GST: </span>
                                <span>
                                  ${amountExcGST ? formatNumber(Number(amountExcGST)) : '0.00'}
                                </span>
                              </div>
                              <div className="invoice-gst-title">
                                <span className="invoice-data-space">GST: </span>
                                <span>${totalGST ? formatNumber(Number(totalGST)) : '0.00'}</span>
                              </div>
                              <div className="invoice-payable-title">
                                <span className="invoice-data-space">Total Amount: </span>
                                <span>
                                  $
                                  {amountExcGST || totalGST
                                    ? formatNumber(Number(amountExcGST + totalGST))
                                    : '0.00'}
                                </span>
                              </div>
                              {discountAmount ? (
                                <div className="invoice-discount">
                                  <span className="invoice-data-space">Discount(GST Inc.): </span>
                                  <span>
                                    $
                                    {discountAmount ? formatNumber(Number(discountAmount)) : '0.00'}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="invoice-payable-title">
                                <span className="invoice-data-space">Total Payable: </span>
                                <span>
                                  ${totalAmount ? formatNumber(Number(totalAmount)) : '0.00'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-save">
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: '10px' }}
                        onClick={() => toggleSendModal(values)}
                        disabled={
                          saveDisabled(finalData) || !values?.invoice_to || !values?.email_to
                        }
                      >
                        POST
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleSendEmailQuote(values, 1)} 
                        disabled={saveDisabled(finalData)} >
                          Save & Send
                      </Button> */}
                    </div>
                  </form>
                );
              }}
            </Formik>
            {showInvoice && (
              <Modal
                size="lg"
                show={showInvoice}
                onHide={toggleInvoiceModal}
                dialogClassName="larg-popup review-popup small-review invoice-modal"
                aria-labelledby="contained-modal-title-vcenter"
                className={`business-section`}
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  QUOTE
                </Modal.Header>
                <Modal.Body>
                  <form>
                    {parseInt(previewDataSettings?.quote_style) === 0 ? (
                      <>
                        <div id="print-invoice">
                          <div
                            className="boxed-invoice"
                            style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
                          >
                            <table
                              style={{ width: '100%', fontSize: '12px', fontFamily: 'Poppins' }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ width: '50%' }}>
                                    {BusinessLogo && (
                                      <img
                                        src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                        alt=""
                                        // style={{ height: '100px', width: 'auto' }}
                                        style={{
                                          maxWidth: '95px',
                                          maxHeight: '80px',
                                          marginRight: '5px'
                                        }}
                                      />
                                    )}{' '}
                                    {BusinessName}
                                  </td>
                                  <td style={{ textAlign: 'end', width: '50%' }}>
                                    <p>
                                      {BusinessName}
                                      {office ? (
                                        <>
                                          <br />
                                          {office?.street ? office?.street : ''}
                                          <br />
                                          {office?.suburb ? office?.suburb?.name : ''}
                                          {office?.suburb && ' '}
                                          {office?.state
                                            ? office?.state?.short_name
                                              ? office?.state?.short_name
                                              : office?.state?.name
                                            : ''}
                                          {office?.state && ' '}
                                          {office?.postal_code ? office?.postal_code : ''}
                                        </>
                                      ) : (
                                        <>
                                          <br />
                                          {businessData?.street ? businessData?.street : ''}
                                          <br />
                                          {businessData?.suburb ? businessData?.suburb?.name : ''}
                                          {businessData?.suburb && ' '}
                                          {businessData?.state
                                            ? businessData?.state?.short_name
                                              ? businessData?.state?.short_name
                                              : businessData?.state?.name
                                            : ''}
                                          {businessData?.state && ' '}
                                          {businessData?.postal_code
                                            ? businessData?.postal_code
                                            : ''}
                                        </>
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr
                              style={{
                                margin: 0,
                                marginTop: '8px',
                                marginBottom: '25px',
                                borderTop: '1px solid black',
                                width: '100%'
                              }}
                            />
                            <table
                              style={{ width: '100%', fontSize: '12px', fontFamily: 'Poppins' }}
                            >
                              <tbody>
                                <tr style={{ display: 'flex', gap: '15px' }}>
                                  <td
                                    style={{
                                      border: '1px solid black',
                                      padding: '15px',
                                      height: '115px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-evenly',
                                      width: '65%'
                                    }}
                                  >
                                    {previewDataSettings?.client_option?.non_individual ? (
                                      <div>
                                        {previewDataSettings?.client_option?.alternateName
                                          ? contactDetails && contactDetails?.display_name
                                            ? contactDetails?.display_name
                                            : formatName(
                                                contactDetails?.first_name,
                                                contactDetails?.last_name
                                              )
                                          : contactDetails?.business_name
                                          ? contactDetails?.business_name
                                          : formatName(
                                              contactDetails?.first_name,
                                              contactDetails?.last_name
                                            )}
                                        <>
                                          {contactDetails && (
                                            <>
                                              <p style={{ margin: '0px' }}>
                                                {contactDetails?.shipping_street
                                                  ? contactDetails?.shipping_street
                                                  : contactDetails?.street
                                                  ? contactDetails?.street
                                                  : ''}
                                              </p>
                                              <p style={{ margin: '0px' }}>
                                                {contactDetails?.shipping_suburb
                                                  ? contactDetails?.shipping_suburb?.name
                                                  : contactDetails?.suburb
                                                  ? contactDetails?.suburb?.name
                                                  : ''}
                                                {(contactDetails?.suburb ||
                                                  contactDetails?.shipping_suburb) &&
                                                  ' '}
                                                {contactDetails?.shipping_state
                                                  ? contactDetails?.shipping_state?.short_name
                                                    ? contactDetails?.shipping_state?.short_name
                                                    : contactDetails?.shipping_state?.name
                                                  : contactDetails?.state
                                                  ? contactDetails?.state?.short_name
                                                    ? contactDetails?.state?.short_name
                                                    : contactDetails?.state?.name
                                                  : ''}
                                                {(contactDetails?.state ||
                                                  contactDetails?.shipping_state) &&
                                                  ' '}
                                                {contactDetails?.shipping_postal_code
                                                  ? contactDetails?.shipping_postal_code
                                                  : contactDetails?.postal_code
                                                  ? contactDetails?.postal_code
                                                  : ''}
                                              </p>
                                              {/* <p>{contactDetails}</p> */}
                                            </>
                                          )}
                                        </>
                                      </div>
                                    ) : (
                                      <div>
                                        {previewDataSettings?.client_option?.alternateName ? (
                                          contactDetails && contactDetails?.display_name ? (
                                            contactDetails?.display_name
                                          ) : contactDetails?.first_name ? (
                                            <p style={{ margin: '0px' }}>
                                              {contactDetails?.first_name +
                                                ' ' +
                                                contactDetails?.last_name}
                                            </p>
                                          ) : (
                                            ''
                                          )
                                        ) : contactDetails && contactDetails?.first_name ? (
                                          <p style={{ margin: '0px' }}>
                                            {contactDetails?.first_name +
                                              ' ' +
                                              contactDetails?.last_name}
                                          </p>
                                        ) : (
                                          <p style={{ margin: '0px' }}>
                                            {contactDetails?.last_name}
                                          </p>
                                        )}
                                        <>
                                          {contactDetails && (
                                            <>
                                              <p style={{ margin: '0px' }}>
                                                {contactDetails?.street
                                                  ? contactDetails?.street
                                                  : contactDetails?.shipping_street
                                                  ? contactDetails?.shipping_street
                                                  : ''}
                                              </p>
                                              <p style={{ margin: '0px' }}>
                                                {contactDetails?.suburb
                                                  ? contactDetails?.suburb?.name
                                                  : contactDetails?.shipping_suburb
                                                  ? contactDetails?.shipping_suburb?.name
                                                  : ''}
                                                {(contactDetails?.suburb ||
                                                  contactDetails?.shipping_suburb) &&
                                                  ' '}
                                                {contactDetails?.state
                                                  ? contactDetails?.state?.short_name
                                                    ? contactDetails?.state?.short_name
                                                    : contactDetails?.state?.name
                                                  : contactDetails?.shipping_state
                                                  ? contactDetails?.shipping_state?.name
                                                  : ''}
                                                {(contactDetails?.state ||
                                                  contactDetails?.shipping_state) &&
                                                  ' '}
                                                {contactDetails?.postal_code
                                                  ? contactDetails?.postal_code
                                                  : contactDetails?.shipping_postal_code
                                                  ? contactDetails?.shipping_postal_code
                                                  : ''}
                                              </p>
                                              {/* <p>{contactDetails}</p> */}
                                            </>
                                          )}
                                        </>
                                      </div>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      border: '1px solid black',
                                      padding: '15px',
                                      textAlign: 'center',
                                      height: '115px',
                                      width: '35%'
                                    }}
                                  >
                                    <h6 style={{ margin: '0px', fontSize: '16px' }}>QUOTE</h6>
                                    <p
                                      style={{
                                        fontWeight: 'bold',
                                        margin: '0px',
                                        marginBottom: '9px',
                                        marginTop: '7px'
                                      }}
                                    >
                                      Quote No: {quoteNumber}
                                    </p>
                                    <p style={{ margin: '0px' }}>
                                      Quote Date: {previewHeaderData?.date}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              style={{
                                width: '100%',
                                marginTop: '20px',
                                border: '1px solid black',
                                fontFamily: 'Poppins',
                                borderSpacing: '0px',
                                fontSize: '12px'
                              }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={
                                      previewDataSettings?.secondary_color
                                        ? {
                                            width: '50%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            textAlign: 'inherit',
                                            color: previewDataSettings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            width: '50%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            textAlign: 'inherit',
                                            color: previewDataSettings?.font_color,
                                            background: previewDataSettings?.primary_color
                                          }
                                    }
                                  >
                                    Description
                                  </th>
                                  <th
                                    style={
                                      previewDataSettings?.secondary_color
                                        ? {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: `transparent
                              linear-gradient(
                                180deg,
                                ${previewDataSettings?.secondary_color} 0%,
                                ${previewDataSettings?.primary_color} 100%
                              )
                              0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: previewDataSettings?.primary_color
                                          }
                                    }
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    style={
                                      previewDataSettings?.secondary_color
                                        ? {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: `transparent
                              linear-gradient(
                                180deg,
                                ${previewDataSettings?.secondary_color} 0%,
                                ${previewDataSettings?.primary_color} 100%
                              )
                              0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: previewDataSettings?.primary_color
                                          }
                                    }
                                  >
                                    Unit Price
                                  </th>
                                  <th
                                    style={
                                      previewDataSettings?.secondary_color
                                        ? {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: `transparent
                                linear-gradient(
                                  180deg,
                                  ${previewDataSettings?.secondary_color} 0%,
                                  ${previewDataSettings?.primary_color} 100%
                                )
                                0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: previewDataSettings?.primary_color
                                          }
                                    }
                                  >
                                    GST
                                  </th>
                                  <th
                                    style={
                                      previewDataSettings?.secondary_color
                                        ? {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: `transparent
                                linear-gradient(
                                  180deg,
                                  ${previewDataSettings?.secondary_color} 0%,
                                  ${previewDataSettings?.primary_color} 100%
                                )
                                0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            width: '10%',
                                            padding: '8px',
                                            borderRight: '1px solid black',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: previewDataSettings?.primary_color
                                          }
                                    }
                                  >
                                    Discount
                                  </th>
                                  <th
                                    style={
                                      previewDataSettings?.secondary_color
                                        ? {
                                            padding: '8px',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: `transparent
                                linear-gradient(
                                  180deg,
                                  ${previewDataSettings?.secondary_color} 0%,
                                  ${previewDataSettings?.primary_color} 100%
                                )
                                0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '8px',
                                            borderBottom: '1px solid black',
                                            color: previewDataSettings?.font_color,
                                            textAlign: 'right',
                                            background: previewDataSettings?.primary_color
                                          }
                                    }
                                  >
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {previewDatas.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          padding: '8px',
                                          borderRight: '1px solid black',
                                          borderBottom: '1px solid black'
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{ __html: item?.description }}
                                        ></span>
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          borderBottom: '1px solid black',
                                          borderRight: '1px solid black',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.quantity}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          borderBottom: '1px solid black',
                                          borderRight: '1px solid black',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.unit_price ? item?.unit_price : '$0.00'}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          borderBottom: '1px solid black',
                                          borderRight: '1px solid black',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.gst ? item?.gst : '$0.00'}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          borderBottom: '1px solid black',
                                          borderRight: '1px solid black',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.discount_amount ? item?.discount_amount : '$0.00'}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          borderBottom: '1px solid black',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.amount_inc_GST ? item?.amount_inc_GST : '$0.00'}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderRight: '1px solid black',
                                      borderBottom: '1px solid black',
                                      fontWeight: 'bold'
                                    }}
                                    colSpan={2}
                                  >
                                    Subtotal
                                  </td>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderBottom: '1px solid black',
                                      borderRight: '1px solid black',
                                      fontWeight: 'bold',
                                      textAlign: 'right'
                                    }}
                                  >
                                    $
                                    {globalGSTType === 1
                                      ? formatNumber(Number(Number(amountExcGST + totalGST)))
                                      : amountExcGST
                                      ? formatNumber(Number(amountExcGST))
                                      : '0.00'}
                                  </td>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderBottom: '1px solid black',
                                      borderRight: '1px solid black',
                                      fontWeight: 'bold',
                                      textAlign: 'right'
                                    }}
                                  >
                                    ${totalGST ? formatNumber(Number(totalGST)) : '0.00'}
                                  </td>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderBottom: '1px solid black',
                                      borderRight: '1px solid black',
                                      fontWeight: 'bold',
                                      textAlign: 'right'
                                    }}
                                  >
                                    $
                                    {discountId
                                      ? '0.00'
                                      : discountAmount
                                      ? formatNumber(Number(discountAmount))
                                      : '0.00'}
                                  </td>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderBottom: '1px solid black',
                                      fontWeight: 'bold',
                                      textAlign: 'right'
                                    }}
                                  >
                                    $
                                    {discountId
                                      ? formatNumber(Number(amountExcGST + totalGST))
                                      : totalAmount
                                      ? formatNumber(Number(totalAmount))
                                      : '0.00'}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderRight: '1px solid black',
                                      borderBottom: '1px solid black',
                                      fontWeight: 'bold'
                                    }}
                                    colSpan={5}
                                  >
                                    Gross Amount
                                  </td>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderBottom: '1px solid black',
                                      textAlign: 'right',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    $
                                    {amountExcGST || totalGST
                                      ? formatNumber(Number(amountExcGST + totalGST))
                                      : '0.00'}
                                  </td>
                                </tr>
                                {discountAmount ? (
                                  <tr>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderRight: '1px solid black',
                                        borderBottom: '1px solid black',
                                        fontWeight: 'bold'
                                      }}
                                      colSpan={5}
                                    >
                                      Discount
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderBottom: '1px solid black',
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      $
                                      {discountAmount
                                        ? formatNumber(Number(discountAmount))
                                        : '0.00'}
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                                <tr>
                                  <td
                                    style={{
                                      padding: '8px',
                                      borderRight: '1px solid black',
                                      height: '37px'
                                    }}
                                    colSpan={6}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      {previewDataSettings?.sub_total_option?.term ? (
                                        <p style={{ margin: '0px' }}>
                                          <span>
                                            Terms:{' '}
                                            {terms
                                              ? `${
                                                  String(date)?.includes('-')
                                                    ? terms.toFixed(0) < 0
                                                      ? 0
                                                      : terms.toFixed(0)
                                                    : terms.toFixed(0) < 0
                                                    ? 0
                                                    : terms.toFixed(0)
                                                } days`
                                              : ''}
                                          </span>
                                        </p>
                                      ) : (
                                        ''
                                      )}
                                      {previewDataSettings?.sub_total_option?.expiryDate ? (
                                        <p style={{ margin: '0px' }}>
                                          <span>Expiry Date: {previewHeaderData?.dueDate}</span>
                                        </p>
                                      ) : (
                                        ''
                                      )}
                                      {previewDataSettings?.quote_detail_option?.amount ? (
                                        <p style={{ margin: '0px' }}>
                                          <span>
                                            Amount Outstanding: $
                                            {totalAmount
                                              ? formatNumber(Number(totalAmount))
                                              : '0.00'}
                                          </span>
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div style={{ textAlign: 'right', marginTop: 10 }}>
                          <button
                            type="button"
                            className="btn btn-primary invoice-btn"
                            onClick={printFile}
                          >
                            PRINT
                          </button>
                        </div> */}
                      </>
                    ) : (
                      <>
                        <div id="print-invoice">
                          <div
                            className="boxed-invoice"
                            style={{ fontSize: '12px', fontFamily: 'Poppins' }}
                          >
                            <table
                              style={{
                                width: '100%',
                                fontFamily: 'Poppins',
                                padding: '15px',
                                fontSize: '12px'
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      style={{
                                        width: '100%',
                                        fontSize: '12px',
                                        fontFamily: 'Poppins'
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td colSpan={3} style={{ textAlign: 'right' }}>
                                            {BusinessLogo && (
                                              <img
                                                src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                                alt=""
                                                // style={{ height: '100px', width: 'auto' }}
                                                style={{
                                                  maxWidth: '95px',
                                                  maxHeight: '80px',
                                                  marginRight: '5px'
                                                }}
                                              />
                                            )}{' '}
                                            {BusinessName}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ width: '30%' }}>
                                            <h2 style={{ fontSize: '20px', paddingBottom: '12px' }}>
                                              QUOTE
                                            </h2>
                                            {previewDataSettings?.client_option?.non_individual ? (
                                              <div>
                                                {previewDataSettings?.client_option
                                                  ?.alternateName ? (
                                                  contactDetails && contactDetails?.display_name ? (
                                                    contactDetails?.display_name
                                                  ) : contactDetails?.first_name ? (
                                                    <p style={{ margin: '0px' }}>
                                                      {contactDetails?.first_name +
                                                        ' ' +
                                                        contactDetails?.last_name}
                                                    </p>
                                                  ) : (
                                                    contactDetails?.last_name
                                                  )
                                                ) : contactDetails?.business_name ? (
                                                  contactDetails?.business_name
                                                ) : contactDetails?.first_name ? (
                                                  <p style={{ margin: '0px' }}>
                                                    {contactDetails?.first_name +
                                                      ' ' +
                                                      contactDetails?.last_name}
                                                  </p>
                                                ) : (
                                                  contactDetails?.last_name
                                                )}
                                                <>
                                                  {contactDetails && (
                                                    <>
                                                      <p style={{ margin: '0px' }}>
                                                        {contactDetails?.shipping_street
                                                          ? contactDetails?.shipping_street
                                                          : contactDetails?.street
                                                          ? contactDetails?.street
                                                          : ''}
                                                      </p>
                                                      <p style={{ margin: '0px' }}>
                                                        {contactDetails?.shipping_suburb
                                                          ? contactDetails?.shipping_suburb?.name
                                                          : contactDetails?.suburb
                                                          ? contactDetails?.suburb?.name
                                                          : ''}
                                                        {(contactDetails?.suburb ||
                                                          contactDetails?.shipping_suburb) && (
                                                          <br />
                                                        )}
                                                        {contactDetails?.shipping_state
                                                          ? contactDetails?.shipping_state
                                                              ?.short_name
                                                            ? contactDetails?.shipping_state
                                                                ?.short_name
                                                            : contactDetails?.shipping_state?.name
                                                          : contactDetails?.state
                                                          ? contactDetails?.state?.short_name
                                                            ? contactDetails?.state?.short_name
                                                            : contactDetails?.state?.name
                                                          : ''}
                                                        {(contactDetails?.state ||
                                                          contactDetails?.shipping_state) && <br />}
                                                        {contactDetails?.shipping_postal_code
                                                          ? contactDetails?.shipping_postal_code
                                                          : contactDetails?.postal_code
                                                          ? contactDetails?.postal_code
                                                          : ''}
                                                      </p>
                                                      {/* <p>{contactDetails}</p> */}
                                                    </>
                                                  )}
                                                </>
                                              </div>
                                            ) : (
                                              <div>
                                                {previewDataSettings?.client_option
                                                  ?.alternateName ? (
                                                  contactDetails && contactDetails?.display_name ? (
                                                    contactDetails?.display_name
                                                  ) : contactDetails?.first_name ? (
                                                    <p style={{ margin: '0px' }}>
                                                      {contactDetails?.first_name +
                                                        ' ' +
                                                        contactDetails?.last_name}
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )
                                                ) : contactDetails && contactDetails?.first_name ? (
                                                  <p style={{ margin: '0px' }}>
                                                    {contactDetails?.first_name +
                                                      ' ' +
                                                      contactDetails?.last_name}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                                <>
                                                  {contactDetails && (
                                                    <>
                                                      <p style={{ margin: '0px' }}>
                                                        {contactDetails?.street
                                                          ? contactDetails?.street
                                                          : contactDetails?.shipping_street
                                                          ? contactDetails?.shipping_street
                                                          : ''}
                                                      </p>
                                                      <p style={{ margin: '0px' }}>
                                                        {contactDetails?.suburb
                                                          ? contactDetails?.suburb?.name
                                                          : contactDetails?.shipping_suburb
                                                          ? contactDetails?.shipping_suburb?.name
                                                          : ''}
                                                        {(contactDetails?.suburb ||
                                                          contactDetails?.shipping_suburb) && (
                                                          <br />
                                                        )}
                                                        {contactDetails?.state
                                                          ? contactDetails?.state?.short_name
                                                            ? contactDetails?.state?.short_name
                                                            : contactDetails?.state?.name
                                                          : contactDetails?.shipping_state
                                                          ? contactDetails?.shipping_state?.name
                                                          : ''}
                                                        {(contactDetails?.state ||
                                                          contactDetails?.shipping_state) && <br />}
                                                        {contactDetails?.postal_code
                                                          ? contactDetails?.postal_code
                                                          : contactDetails?.shipping_postal_code
                                                          ? contactDetails?.shipping_postal_code
                                                          : ''}
                                                      </p>
                                                      {/* <p>{contactDetails}</p> */}
                                                    </>
                                                  )}
                                                </>
                                              </div>
                                            )}
                                          </td>
                                          <td style={{ width: '25%' }}></td>
                                          <td style={{ width: '45%', textAlign: 'right' }}>
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              style={{ width: '100%', fontSize: '12px' }}
                                            >
                                              <tr>
                                                <td
                                                  style={{
                                                    verticalAlign: 'top',
                                                    textAlign: 'left',
                                                    paddingRight: '15px'
                                                  }}
                                                >
                                                  <p>
                                                    <strong>Date</strong>
                                                    <br />
                                                    {previewHeaderData.date}
                                                  </p>
                                                  {previewDataSettings?.sub_total_option
                                                    ?.expiryDate ? (
                                                    <p>
                                                      <strong>Expiry</strong>
                                                      <br />
                                                      {previewHeaderData.dueDate}
                                                    </p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <p>
                                                    <strong>Quote Number</strong>
                                                    <br />
                                                    {quoteNumber}
                                                  </p>
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: 'left',
                                                    verticalAlign: 'top'
                                                  }}
                                                >
                                                  <div>
                                                    {BusinessName}
                                                    {office ? (
                                                      <>
                                                        <br />
                                                        {office?.street ? office?.street : ''}
                                                        <br />
                                                        {office?.suburb ? office?.suburb?.name : ''}
                                                        {office?.suburb && ' '}
                                                        {office?.state
                                                          ? office?.state?.short_name
                                                            ? office?.state?.short_name
                                                            : office?.state?.name
                                                          : ''}
                                                        {office?.state && ' '}
                                                        {office?.postal_code
                                                          ? office?.postal_code
                                                          : ''}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <br />
                                                        {businessData?.street
                                                          ? businessData?.street
                                                          : ''}
                                                        <br />
                                                        {businessData?.suburb
                                                          ? businessData?.suburb?.name
                                                          : ''}
                                                        {businessData?.suburb && ' '}
                                                        {businessData?.state
                                                          ? businessData?.state?.short_name
                                                            ? businessData?.state?.short_name
                                                            : businessData?.state?.name
                                                          : ''}
                                                        {businessData?.state && ' '}
                                                        {businessData?.postal_code
                                                          ? businessData?.postal_code
                                                          : ''}
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      style={{
                                        width: '100%',
                                        fontFamily: 'Poppins',
                                        marginTop: '55px',
                                        fontSize: '12px'
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={
                                              previewDataSettings?.secondary_color
                                                ? {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'left',
                                                    width: '43%',
                                                    color: previewDataSettings?.font_color,
                                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                  }
                                                : {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'left',
                                                    width: '43%',
                                                    color: previewDataSettings?.font_color,
                                                    background: previewDataSettings?.primary_color
                                                  }
                                            }
                                          >
                                            Description
                                          </th>
                                          <th
                                            style={
                                              previewDataSettings?.secondary_color
                                                ? {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '5%',
                                                    color: previewDataSettings?.font_color,
                                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                  }
                                                : {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '5%',
                                                    color: previewDataSettings?.font_color,
                                                    background: previewDataSettings?.primary_color
                                                  }
                                            }
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            style={
                                              previewDataSettings?.secondary_color
                                                ? {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                  }
                                                : {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: previewDataSettings?.primary_color
                                                  }
                                            }
                                          >
                                            Unit Price
                                          </th>
                                          <th
                                            style={
                                              previewDataSettings?.secondary_color
                                                ? {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                  }
                                                : {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: previewDataSettings?.primary_color
                                                  }
                                            }
                                          >
                                            GST
                                          </th>
                                          <th
                                            style={
                                              previewDataSettings?.secondary_color
                                                ? {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                  }
                                                : {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: previewDataSettings?.primary_color
                                                  }
                                            }
                                          >
                                            Discount
                                          </th>
                                          <th
                                            style={
                                              previewDataSettings?.secondary_color
                                                ? {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${previewDataSettings?.secondary_color} 0%,
                          ${previewDataSettings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                                  }
                                                : {
                                                    padding: '5px',
                                                    borderBottom: '1px solid #000',
                                                    textAlign: 'right',
                                                    width: '13%',
                                                    color: previewDataSettings?.font_color,
                                                    background: previewDataSettings?.primary_color
                                                  }
                                            }
                                          >
                                            Amount AUD
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {previewDatas.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  textAlign: 'left',
                                                  borderBottom: '1px solid #ced4da'
                                                }}
                                              >
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: item?.description
                                                  }}
                                                ></span>
                                              </td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderBottom: '1px solid #ced4da',
                                                  textAlign: 'right'
                                                }}
                                              >
                                                {item?.quantity}
                                              </td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderBottom: '1px solid #ced4da',
                                                  textAlign: 'right'
                                                }}
                                              >
                                                {item?.unit_price}
                                              </td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderBottom: '1px solid #ced4da',
                                                  textAlign: 'right'
                                                }}
                                              >
                                                {item?.gst_type}
                                              </td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderBottom: '1px solid #ced4da',
                                                  textAlign: 'right'
                                                }}
                                              >
                                                {item?.discount_amount}
                                              </td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderBottom: '1px solid #ced4da',
                                                  textAlign: 'right'
                                                }}
                                              >
                                                {item?.amount}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                        <tr>
                                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            Subtotal
                                          </td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            $
                                            {amountExcGST
                                              ? formatNumber(Number(amountExcGST))
                                              : '0.00'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            TOTAL GST 10%
                                          </td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            ${totalGST ? formatNumber(Number(totalGST)) : '0.00'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            TOTAL Amount
                                          </td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            $
                                            {amountExcGST || totalGST
                                              ? formatNumber(Number(amountExcGST + totalGST))
                                              : '0.00'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            TOTAL Discount
                                          </td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}>
                                            $
                                            {discountAmount
                                              ? formatNumber(Number(discountAmount))
                                              : '0.00'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                          <td
                                            style={{
                                              padding: '5px',
                                              borderTop: '1px solid #000',
                                              textAlign: 'right',
                                              fontWeight: 'bold',
                                              borderBottom: '1px solid #000'
                                            }}
                                          >
                                            TOTAL AUD
                                          </td>
                                          <td
                                            style={{
                                              padding: '5px',
                                              borderTop: '1px solid #000',
                                              textAlign: 'right',
                                              fontWeight: 'bold',
                                              borderBottom: '1px solid #000'
                                            }}
                                          >
                                            $
                                            {totalAmount
                                              ? formatNumber(Number(totalAmount))
                                              : '0.00'}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div style={{ textAlign: 'right', marginTop: 10 }}>
                          <button
                            type="button"
                            className="btn btn-primary invoice-btn"
                            onClick={printFile}
                          >
                            PRINT
                          </button>
                        </div> */}
                      </>
                    )}
                  </form>
                </Modal.Body>
              </Modal>
            )}
            {showDiscount && (
              <Modal
                size="lg"
                show={showDiscount}
                onHide={togglePreviewDiscount}
                dialogClassName="larg-popup review-popup small-review invoice-modal"
                aria-labelledby="contained-modal-title-vcenter"
                className="business-section"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Discount Codes
                </Modal.Header>
                <Modal.Body>
                  {discountLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <div className="content-details" style={{ paddingTop: '30px' }}>
                        <div className="table-top-btn">
                          <div className="table-btn">
                            <Button variant="primary" onClick={toggleAddDiscountModal}>
                              Add New
                            </Button>
                          </div>
                        </div>
                        <BootstrapTable
                          keyField="key"
                          remote
                          data={discounts}
                          columns={columns}
                          rowEvents={rowDiscountEvents}
                          noDataIndication="No Data Found"
                        />

                        <Pagination
                          total={total}
                          limit={parseInt(limit)}
                          currentPage={page}
                          updateLimit={handleUpdateLimit}
                          updatePage={handleChangePage}
                          from={from}
                          to={to}
                        />
                      </div>
                    </>
                  )}
                </Modal.Body>
              </Modal>
            )}
            {showAddDiscount && (
              <AddDiscountCode
                businessId={businessId}
                showAddDiscount={showAddDiscount}
                toggleAddDiscountModal={toggleAddDiscountModal}
                handleAdd={handlFetchDiscount}
              />
            )}

            {showParagraph && (
              <Modal
                size="lg"
                show={showParagraph}
                onHide={toggleStandardParagraph}
                dialogClassName="larg-popup review-popup small-review invoice-modal standard-paragrah-modal"
                aria-labelledby="contained-modal-title-vcenter"
                className="business-section"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Standard Paragraph
                </Modal.Header>
                <Modal.Body>
                  {paragrahLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <div className="content-details" style={{ paddingTop: '30px' }}>
                        <div className="table-top-btn">
                          <div className="table-btn">
                            <Button variant="primary" onClick={toggleAddParagrahModal}>
                              Add New
                            </Button>
                          </div>
                        </div>
                        <BootstrapTable
                          keyField="key"
                          remote
                          data={paragraphs}
                          columns={paragraphColumns}
                          rowEvents={rowParagrahEvents}
                          noDataIndication="No Data Found"
                        />

                        <Pagination
                          total={total}
                          limit={parseInt(limit)}
                          currentPage={page}
                          updateLimit={handleUpdateLimit}
                          updatePage={handleChangePage}
                          from={from}
                          to={to}
                        />
                      </div>
                    </>
                  )}
                </Modal.Body>
              </Modal>
            )}
            {showAddParagrah && (
              <AddParagrah
                businessId={businessId}
                showAddParagrah={showAddParagrah}
                toggleAddParagrahModal={toggleAddParagrahModal}
                handleAdd={handleParagraphFetch}
              />
            )}

            {showSend && (
              <Modal
                size="lg"
                show={showSend}
                onHide={toggleSendModal}
                dialogClassName="modal-50w small-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                className="business-section"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Send Quote
                </Modal.Header>
                <Modal.Body>
                  {loading && <Spin />}
                  <div className="modal-body">
                    Do you want to send quote to this {finalValues?.email_to}?
                  </div>
                  <div className="modal-footer">
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      onClick={() => {
                        handleSendEmailQuote(finalValues, 1);
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      type="Button"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleSubmit(finalValues);
                      }}
                    >
                      No
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {addNewContact && (
              <AddNewContactModal
                addNewContact={addNewContact}
                handleAddNewContact={handleAddNewContact}
                handleContactList={handleNewClientList}
                businessId={businessId}
                setNewContact={setNewContact}
                // setFieldValue1={setFieldValue}
                newContactLastName={newContactLastName}
              />
            )}
            {addNewJob && (
              <AddNewJobModal
                addNewJob={addNewJob}
                handleJobChange={handleNewJobList}
                handleAddNewJob={handleAddNewJob}
                businessId={businessId}
                setNewJob={setNewJob}
                newJob={newJob}
                // setFieldValue={setFieldValue}
                newJobCode={newJobCode}
                finalData={finalData}
                setFinalData={setFinalData}
                index={index}
              />
            )}
            {addNewTask && (
              <AddNewTaskModal
                addNewTask={addNewTask}
                handleTaskChange={handleNewTaskList}
                handleAddNewTask={handleAddNewTask}
                businessId={businessId}
                setNewTask={setNewTask}
                newTask={newTask}
                // setFieldValue={setFieldValue}
                newTaskCode={newTaskCode}
                finalData={finalData}
                setFinalData={setFinalData}
                index={index}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuote;
