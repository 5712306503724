import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import Spin from '../../../../common/Spin';
import { API, get } from '../../../../../config';
import { toast } from 'react-toastify';
import { formatName, formatNumber } from '../../../../common/Misc';

const CashPaymentDetailPopup = ({ cash_paymentId, toggleShowModal, showView }) => {
  const [paymentLoading, setpaymentLoading] = useState(false);
  const [paymentData, setPaymentData] = useState();

  const fetchPayments = useCallback(async () => {
    try {
      setpaymentLoading(true);
      const { data } = await get(`${API.GET_CASH_PAYMENT}/${cash_paymentId}`);

      setpaymentLoading(false);
      const paymentData = data.data;
      setPaymentData(paymentData);
      return data.data;
    } catch (e) {
      const { data } = e;
      setpaymentLoading(false);
      setPaymentData([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [cash_paymentId]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Cash Payment Details
      </Modal.Header>
      <Modal.Body>
        {paymentLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content">
              <div className="modal-content-details">
                <p>
                  <strong>Transaction Date:</strong>{' '}
                  {paymentData?.transactionDate ? paymentData?.transactionDate : '-'}
                </p>
                <p>
                  <strong>Amount:</strong>{' '}
                  <span className='rag-red' style={{marginLeft: "0px"}}>{paymentData?.amount ? `$${formatNumber(paymentData?.amount)}` : '-'}</span>
                </p>

                <p>
                  <strong>Account:</strong>{' '}
                  {paymentData?.chart_of_account ? `${paymentData?.chart_of_account?.code?.length < 4 ? padWithLeadingZeros(Number(paymentData?.chart_of_account?.code),4) : paymentData?.chart_of_account?.code}${paymentData?.chart_of_account?.sub_code ? `.${paymentData?.chart_of_account?.sub_code}` : ''} - ` + paymentData?.chart_of_account?.name : '-'}
                </p>
                <p>
                  <strong>GST Type:</strong>{' '}
                  {paymentData?.gst === 0 ? "GST Excluded" : paymentData?.gst === 1 ? "GST 10%" : paymentData?.gst === 2 ? "BAS Excluded" : "GST on Imports"}
                </p>
                <p>
                  <strong>Contact Name:</strong>{' '}
                  {paymentData?.contact ? formatName(paymentData?.contact?.first_name, paymentData?.contact?.last_name) : '-'}
                </p>
                <p>
                  <strong>Description:</strong>{' '}
                  {paymentData?.description ? paymentData?.description : '-'}
                </p>
                <p>
                  <strong>Job:</strong>{' '}
                  {paymentData?.business_job ? paymentData?.business_job?.code : '-'}
                </p>
                <p>
                  <strong>Task:</strong>{' '}
                  {paymentData?.business_task ? paymentData?.business_task?.code : '-'}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CashPaymentDetailPopup;
