import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddBackupEmail from '../../components/Authentication/AddBackupEmail';
import ConfirmBackupEmail from '../../components/Authentication/ConfirmBackupEmail';
import RegisterAuthentication from '../../components/Authentication/RegisterAuthentication';
import ConfirmAuthentication from '../../components/Authentication/ConfirmAuthentication';
import '../../components/Authentication/authentication.css';
import { API, get, post } from '../../config';

const { Step } = Steps;

const TwoFactorRegister = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const [enterCode, setEnterCode] = useState(false);
  const [enterEmail, setEnterEmail] = useState(false);
  const [qrImage, setQrImage] = useState(null);
  const [secretKey, setSecretKey] = useState(null);
  const [backupEmail, setBackupEmail] = useState('');
  const invited = localStorage.getItem("sme-client-invited");

  var currentdate = new Date(); 

  useEffect(() => {
    post(API.GOOGLE_2FA_REGISTER)
      .then((response) => {
        setQrImage(response.data.QR_Image);
        setSecretKey(response.data.secret);
      })
      .catch(() => {});
  }, []);

  const verifyAuthentication = () => {
    setCurrent(current + 1);
  };

  const submitEmail = () => {
    setEnterEmail(true);
  };

  const verifyEmail = async () => {
    localStorage.setItem('sme-auth-tfa', 2);
    if (!localStorage.getItem('sme-Name')) {
      let userData = await get(API.GET_USER_PROFILE);
      if (userData) {
        const { first_name, profile_pic, last_name, id } = userData.data.data;

        localStorage.setItem("sme-Name", first_name ? first_name : last_name);
        localStorage.setItem("sme-ProfilePhoto", profile_pic);
        localStorage.setItem("sme-userId", id);
        localStorage.setItem("sme-EmailVarification", 1);
        localStorage.setItem("sme-auth-login-time", currentdate.getTime());

        if(invited) {
          navigate("/user-settings?tab=change-password")
        } else {
          navigate('/user-settings?tab=my-profile', { replace: true });
        }
      } else {
        var error = {};

        error['sme_error'] = 'There is some issue with request, please try after some time.';
      }
    } else {
      if (searchParams.get('page') === 'back') {
        localStorage.setItem('sme-auth-tfa', 2);
        navigate(-1);
      } else {
        if(invited) {
          navigate("/user-settings?tab=change-password")
        } else {
          navigate('/user-settings?tab=my-profile', { replace: true });
        }
      }
    }
  };

  const contentStepOne = () => {
    return (
      <>
        {!enterCode ? (
          <RegisterAuthentication qrImage={qrImage} setEnterCode={setEnterCode} secretKey={secretKey}/>
        ) : (
          <>
            <ConfirmAuthentication
              submitButton={verifyAuthentication}
              verifyUrl={API.GOOGLE_2FA_VERIFY}
            />
            <Link
              onClick={() => {
                setEnterEmail(false);
                setEnterCode(false);
              }}
            >
              I didn&apos;t receive a code please resend
            </Link>
          </>
        )}
      </>
    );
  };

  const contentStepTwo = () => {
    return (
      <>
        {!enterEmail ? (
          <AddBackupEmail
            backupEmail={backupEmail}
            setBackupEmail={setBackupEmail}
            submitEmail={submitEmail}
          />
        ) : (
          <>
            <ConfirmBackupEmail
              backupEmail={backupEmail}
              submitButton={verifyEmail}
              verifyUrl={API.VERIFY_BACKUP_EMAIL}
            />
            <Link
              onClick={() => {
                setEnterEmail(false);
              }}
            >
              I didn&apos;t receive a code please resend
            </Link>
          </>
        )}
      </>
    );
  };

  const steps = [
    {
      title: 'Set up app',
      content: contentStepOne()
    },
    {
      title: 'Add a backup',
      content: contentStepTwo()
    }
  ];

  if (!qrImage) return null;
  return (
    <div className='tfa-content'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

export default TwoFactorRegister;
