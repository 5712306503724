import { MonthRangePicker } from "@semcore/date-picker";
import moment from "moment";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, get, metaTitle } from "../../../config";
import Summary from "./Summary";
import GstTransaction from "./GstTransactions";
import TransactionDetails from "./TransactionDetails";
import TitleBar from "../../common/TitleBar";

const today = new Date();
var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(
    new Date(today.getFullYear(), today.getMonth() + i, 1)
  );
}
const currentMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  1
);
const defaultMonthRange = [currentMonth, currentMonth];
// const lastMonth = new Date(
//   new Date().getFullYear(),
//   new Date().getMonth() - 1,
//   1
// );
// const periods = [{ children: "LAST MONTH", value: [lastMonth, lastMonth] }];
const tabsKey = {
  "bas-summary": 1,
  "gst-transactions": 2,
  "transaction-details": 3,
};

const Bas = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "summary";
  const startMonth = query.get("start_month")
    ? new Date(
        moment(query.get("start_month"), "MM-YYYY").format("YYYY"),
        moment(query.get("start_month"), "MM-YYYY").format("MM") - 1,
        1
      )
    : "";
  const endMonth = query.get("end_month")
    ? new Date(
        moment(query.get("end_month"), "MM-YYYY").format("YYYY"),
        moment(query.get("end_month"), "MM-YYYY").format("MM") - 1,
        1
      )
    : "";
  const defaultActiveKey = tabsKey[selectedTab.replace(/\?.*/, "")];
  const businessId = params?.business_id;
  const businessName = localStorage.getItem("sme-businessName");

  const [loading, setLoading] = useState(false);

  const [valueRange, setValueRange] = useState(
    startMonth && endMonth ? [startMonth, endMonth] : defaultMonthRange
  );
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 3
      ? "transaction-details"
      : parseInt(defaultActiveKey) === 2
      ? "gst-transactions"
      : "bas-summary"
  );

  useEffect(() => {
    navigate(
      `/business/${businessId}/bas?tab=${activeTab}&start_month=${
        valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
      }&end_month=${valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""}`,
      {
        replace: true,
      }
    );
  }, [businessId, activeTab, navigate, valueRange]);

  useEffect(() => {
    if(activeTab === "transaction-details"){
      window.document.title = `${metaTitle} | Transaction Details`
    }
    if(activeTab === "gst-transactions"){
      window.document.title = `${metaTitle} | GST Transactions`
    }
    if(activeTab === "bas-summary"){
      window.document.title = `${metaTitle} | BAS Summary`
    }
  },[activeTab])

  
  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }

  const dateFilterRange = [
    {
      children: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)]
    },
    {
      children: 'This Quarter',
      value: quarterRange
    },
    {
      children: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)]
    },
    {
      children: 'Last Quarter',
      value: perviousQuarterRange
    },
  ]

  const handleMonthChange = (date) => {
    parseInt(moment(date[0]).format("YYYY")) ===
      parseInt(moment(date[1]).format("YYYY")) &&
    (parseInt(moment(date[0]).format("MM")) ===
      parseInt(moment(date[1]).format("MM")) ||
      (parseInt(moment(date[0]).format("MM")) === 1 &&
        parseInt(moment(date[1]).format("MM")) === 3) ||
      (parseInt(moment(date[0]).format("MM")) === 4 &&
        parseInt(moment(date[1]).format("MM")) === 6) ||
      (parseInt(moment(date[0]).format("MM")) === 7 &&
        parseInt(moment(date[1]).format("MM")) === 9) ||
      (parseInt(moment(date[0]).format("MM")) === 10 &&
        parseInt(moment(date[1]).format("MM")) === 12))
      ? setValueRange(date)
      : toast.error("Please select a single month or quarter.") &&
        setValueRange([currentMonth, currentMonth]);
  };

  const fetchSummaryReport = useCallback(
    async (format) => {
      if (activeTab === "bas-summary" && format) {
        try {
          setLoading(true);
          const { data } = await get(
            `${API.SUMMARY_REPORT}?client_business_id=${
              businessId ? businessId : ""
            }&start_month=${
              valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
            }&end_month=${
              valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""
            }&format=${format}&export=1`
          );
          const export_report_path = data?.data?.file;
          if (export_report_path) {
            var a = document.createElement("a");
            a.href = export_report_path;
            var file = export_report_path.split("/");
            a.setAttribute(
              "download",
              file[file.length - 1] || "bas_summary_report.pdf"
            );
            a.setAttribute("target", "_blank");
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
            setLoading(false);
          }
          setLoading(false);

          return data;
        } catch (e) {
          setLoading(false);
          const errors = e.response?.data?.errors;
          Object.keys(errors).forEach((key) => {
            toast.error(errors[key][0]);
          });
        }
      }
      if (activeTab === "gst-transactions" && format) {
        try {
          setLoading(true);
          const { data } = await get(
            `${API.GST_TRANSACTION}?client_business_id=${
              businessId ? businessId : ""
            }&start_month=${
              valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
            }&end_month=${
              valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""
            }&format=${format}&export=1`
          );
          const export_report_path = data?.data?.file;
          if (export_report_path) {
            var b = document.createElement("a");
            b.href = export_report_path;
            var file1 = export_report_path.split("/");
            b.setAttribute(
              "download",
              file1[file1.length - 1] || "gst_transactions_report.pdf"
            );
            b.setAttribute("target", "_blank");
            document.body.appendChild(b); // we need to append the element to the dom -> otherwise it will not work in firefox
            b.click();
            b.remove();
            setLoading(false);
          }
          setLoading(false);
          return data;
        } catch (e) {
          setLoading(false);

          const errors = e.response?.data?.errors;
          Object.keys(errors).forEach((key) => {
            toast.error(errors[key][0]);
          });
        }
      }
      if (activeTab === "transaction-details" && format) {
        try {
          setLoading(true);
          const { data } = await get(
            `${API.GST_TRANSACTION_DETAILS}?client_business_id=${
              businessId ? businessId : ""
            }&start_month=${
              valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
            }&end_month=${
              valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""
            }&format=${format}&export=1`
          );
          const export_report_path = data?.data?.file;
          if (export_report_path) {
            var c = document.createElement("a");
            c.href = export_report_path;
            var file2 = export_report_path.split("/");
            c.setAttribute(
              "download",
              file2[file2.length - 1] || "transaction_details_report.pdf"
            );
            c.setAttribute("target", "_blank");
            document.body.appendChild(c); // we need to append the element to the dom -> otherwise it will not work in firefox
            c.click();
            c.remove();
            setLoading(false);
          }
          setLoading(false);

          return data;
        } catch (e) {
          setLoading(false);
          const errors = e.response?.data?.errors;
          Object.keys(errors).forEach((key) => {
            toast.error(errors[key][0]);
          });
        }
      }
    },
    [businessId, valueRange, activeTab]
  );

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={activeTab === "bas-summary"
              ? "BAS Summary"
              : activeTab === "transaction-details"
              ? "Transaction Details"
              : "GST Transactions"}
            />
            <div className="content-details bas-content">
              {/* <Spin spinning={loading} tip="Loading..."> */}
              {/* <div className="table-top-btn trial-btn">
                <div className="table-btn">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary">
                      {loading ? "Loading..." : "Export"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => fetchSummaryReport("pdf")}>
                        PDF
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => fetchSummaryReport("xlsx")}>
                        Excel
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}
              <div
                className="summary-filter table-top-btn "
                style={{
                  marginBottom: "-30px",
                  padding: "0",
                  border: "none",
                  marginTop: "-10px",
                  background: "none"
                }}
                // onClick={() => setDatePickerVisible(true)}
              >
                <div className="bas-export-btn table-btn">
                <Dropdown>
                  <Dropdown.Toggle variant="primary">
                    {loading ? "Loading..." : "Export"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => fetchSummaryReport("pdf")}>
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => fetchSummaryReport("xlsx")}>
                      Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </div>
                <MonthRangePicker
                  value={valueRange}
                  onChange={(date) => {
                    handleMonthChange(date);
                  }}
                  className="datefilter"
                  periods={dateFilterRange}
                  unclearable={true}
                  // disabled={disabledFutureDate ? disabledFutureDate : []}
                />
              </div>
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(
                      event?.target?.innerText.replace(/ /g, "-").toLowerCase()
                    );
                  }}
                >
                  <li className={activeTab === "bas-summary" ? "active" : ""}>
                    <Link
                      key={1}
                    >
                      BAS Summary
                    </Link>
                  </li>
                  <li
                    className={activeTab === "gst-transactions" ? "active" : ""}
                  >
                    <Link
                      key={2}
                    >
                      GST Transactions
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab === "transaction-details" ? "active" : ""
                    }
                  >
                    <Link
                      key={3}
                    >
                      Transaction Details
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="custom-table tab-table">
                {activeTab === "bas-summary" && (
                  <Summary
                    businessId={businessId}
                    valueRange={valueRange}
                    businessName={businessName}
                  />
                )}
                {activeTab === "gst-transactions" && (
                  <GstTransaction
                    businessId={businessId}
                    valueRange={valueRange}
                  />
                )}
                {activeTab === "transaction-details" && (
                  <TransactionDetails
                    businessId={businessId}
                    valueRange={valueRange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Bas;
