import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
const Congratulation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {}, []);
  const token = localStorage.getItem('sme-auth-token');

  const redirectLogin = () => {
    if(token){
      navigate("/user-settings?tab=subscriptions",  { replace: true })
    } else {
      navigate("/");
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="congrats-block">
          <h3>Congratulations</h3>
          <p>{location?.state?.message ? location?.state?.message : ""}</p>
          <Button className="btn-primary btn" onClick={redirectLogin}>
            {token ? 'Check your plan' : 'Login'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
