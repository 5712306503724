import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Modal, Form, Button } from "react-bootstrap";
import {
  CardElement,
  AuBankAccountElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { StripePaymentModalSchema } from "../../pages/common/Validation";
import Spin from "../../pages/common/Spin";
import { API, fileUpload, get } from "../../config";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StripePaymentModal = (props) => {
  let {
    showModal,
    handleModalClose,
    id,
    planDetail,
    promoCode,
    planType,
    subscriptionDetail
    // defaultPromocode,
  } = props;
  const navigate = useNavigate();
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const tabName = query.get("tab");

  const stripe = useStripe();
  const elements = useElements();
  const [defaultPromoCode, setDefaultPromoCode] = useState("");
  const [promocodebtn, setPromocodeBtn] = useState(false);
  const [promoCodeSection, setPromoCodeSection] = useState(false);
  const [amontObj, setAmontObj] = useState({});
  const [bankActive, setBankActive] = useState(false);
  const [loadnig, setLoading] = useState(false);

  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [selectedList, setSelectedPayment] = useState('');
  const [exist, setExist] = useState(false);
  const [cardActive, setCardActive] = useState(true);
  const perPlan = planDetail?.unit_amount / 100;

  const sendPayment = async (values, error, paymentMethodId = null) => {
    if (paymentMethodId) {
      setLoading(true);
      values.user_id = id;
      values.price_id = planDetail.id;
      values.payment_method_id = paymentMethodId;
      values.coupon_code_id = defaultPromoCode && promocodebtn ? defaultPromoCode : "";
      values.quantity = values?.quantity ? values?.quantity : "";

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });

      try {
        let response = await fileUpload(API.FIRM_SUBSCRIPTION, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (response.data.status === 200) {
          handleModalClose();
          setLoading(false);
          navigate('/congratulations', {
            state: { data: response.data.data, message: response.data.message}
          });
          localStorage.setItem('sme-subscription', 1);
        }
      } catch (e) {
        toast.error(e.response?.data?.errors?.myna_error[0][0]);
        handleModalClose();
        setLoading(false);
      }
    } else {
      toast.error(error.message);
    }
  };

  const updatePayment = async (values, error, paymentMethodId = null, subscriptionID = null) => {
    if (paymentMethodId) {
      setLoading(true);
      values.user_id = id;
      values.price_id = planDetail.id;
      values.payment_method_id = paymentMethodId;
      values.coupon_code_id = defaultPromoCode && promocodebtn ? defaultPromoCode : "";
      values.quantity = values?.quantity ? values?.quantity : "";
      if(subscriptionID){
        values.subscription_id = subscriptionID
      }
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });

      try {;
        setLoading(false);
        let response = await fileUpload(API.UPDATE_SUBSCRIPTION, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (response.data.status === 200) {
          handleModalClose();
          setLoading(false);
          navigate('/congratulations', {
            state: { data: response.data.data, message: response.data.message}
          });
          localStorage.setItem('sme-subscription', 1);
        }
      } catch (e) {
        toast.error(e.response?.data?.errors?.myna_error[0][0]);
        handleModalClose();
        setLoading(false);
      }
    } else {
      toast.error(error.message);
    }
  };

  const handleFinish = async (values) => {
    if (selectedList && subscriptionDetail) {
      let type = selectedList.includes('card_');
      if (type) {
        sendPayment(values, {}, selectedList);
      } else {
        sendPayment(values, {}, selectedList);
      }
    } else {
      const { error, paymentMethod } =
        cardActive === true
          ? await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement(CardElement),
              billing_details: {
                name: values?.name ? values?.name : "",
              }
            })
          : await stripe.createPaymentMethod({
              type: 'au_becs_debit',
              au_becs_debit: elements.getElement(AuBankAccountElement),
              billing_details: {
                name: values?.name ? values?.name : "",
                email: values?.email ? values?.email : "",
              }
            });
      sendPayment(values, error, paymentMethod?.id ? paymentMethod?.id : null);
    }
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement)
    // });
  };

  const updateSubscriptionFinish = async (values) => {
    if (selectedList && subscriptionDetail) {
      let type = selectedList.includes('card_');
      if (type) {
        updatePayment(values, {}, selectedList, subscriptionDetail?.subscription_id);
      } else {
        updatePayment(values, {}, selectedList, subscriptionDetail?.subscription_id);
      }
    } else {
      const { error, paymentMethod } =
        cardActive === true
          ? await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement(CardElement),
              billing_details: {
                name: values?.name ? values?.name : "",
              }
            })
          : await stripe.createPaymentMethod({
              type: 'au_becs_debit',
              au_becs_debit: elements.getElement(AuBankAccountElement),
              billing_details: {
                name: values?.name ? values?.name : "",
                email: values?.email ? values?.email : "",
              }
            });
      updatePayment(values, error, paymentMethod?.id ? paymentMethod?.id : null, subscriptionDetail?.subscription_id);
    }
  }

  const validatePromoCode = (couponCode, isDefault = false) => {
    for (let item of promoCode) {
      if (item.id === couponCode) {
        if (
          item.applies_to.products.find(
            (item) => String(item) === String(planDetail.product)
          )
        ) {
          if (!isDefault) toast.success("Coupon code applied successfully");
          setDefaultPromoCode(item?.id);
          setPromocodeBtn(true);
          if (item?.percent_off) {
            setAmontObj({
              ...amontObj,
              percent_off: item?.percent_off,
              amount_off: item?.amount_off,
              payableAmount: (
                ((planDetail?.unit_amount / 100) * (100 - item?.percent_off)) /
                100
              ).toFixed(2),
            });
          } else {
            setAmontObj({
              ...amontObj,
            });
          }

          return;
        }
      }
    }

    if (!isDefault) {
      setAmontObj({
        ...amontObj,
        amount_off: 0,
        percent_off: 0,
        payableAmount: (planDetail?.unit_amount / 100).toFixed(2),
      });
      toast.error("Coupon code is not valid");
    }
  };

  const handleCanclePromocode = () => {
    setPromoCodeSection(false);
    setDefaultPromoCode();
    setPromocodeBtn(false);
    setAmontObj({
      ...amontObj,
      amount_off: 0,
      payableAmount: (planDetail?.unit_amount / 100).toFixed(2),
    });
  };

  const handleCouponChange = (e) => {
    setPromocodeBtn(false);
    setDefaultPromoCode(e.target.value);
  };

  const handleApplyClick = () => {
    validatePromoCode(defaultPromoCode);
  };

  const getpaymentMethodList = async () => {
    try {
      setLoading(true);
      const { data } = await get(API.GET_PAYMENTMETHOD_LIST);
      setLoading(false);
      if (data) {
        setPaymentMethodList(data.data);
      } else {
        setPaymentMethodList([]);
      }
    } catch (error) {
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    exist && getpaymentMethodList();
    // eslint-disable-next-line
  }, []);

  const onhandlePaymentMethod = (e) => {
    if (e.target.checked) {
      setExist(true);
      setCardActive(false);
      setBankActive(false);
    } else {
      setExist(false);
      setSelectedPayment('');
      setCardActive(true);
      setBankActive(false);
    }
  };

  const handleOnchange = (id) => {
    setSelectedPayment(id);
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-50w larg-popup review-popup small-review payment-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Payment Details
      </Modal.Header>
      <Modal.Body>
        <div className="content-details">
          <Formik
            initialValues={{
              quantity: 10,
              name: "",
              email: "",
              bank: bankActive,
            }}
            enableReinitialize
            onSubmit={subscriptionDetail && subscriptionDetail?.status !== 'canceled' ? updateSubscriptionFinish : handleFinish}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={!exist && StripePaymentModalSchema}
          >
            {({ values, handleSubmit, handleChange, handleBlur }) => {
              return (
                <Form
                  name="stripeform"
                  layout="vertical"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  {loadnig && <Spin />}
                  <div className="row">
                    <div className="col-md-12 payment-details">
                      <p>
                        Qty{" "}
                        <NumberFormat
                          name="quantity"
                          allowNegative={false}
                          decimalScale={0}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values?.quantity}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        ></NumberFormat>{" "}
                        Billed {planType === "month" ? "monthly" : "yearly"}{" "}
                        <span>${perPlan.toFixed(2)} each</span>
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="quantity" />
                        </span>
                      </p>

                      <div className="promo-field">
                        {promoCodeSection === false ? (
                          <a
                            href
                            className="promocode"
                            onClick={() => {
                              setPromoCodeSection(true);
                            }}
                          >
                            Have A Promocode
                          </a>
                        ) : (
                          <Form
                            label="Coupon Code"
                            name="couponCode"
                            className="half-width"
                          >
                            <input
                              type="text"
                              placeholder="Coupon Code"
                              value={defaultPromoCode}
                              onChange={handleCouponChange}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <Button
                              className="btn"
                              disabled={promocodebtn}
                              onClick={handleApplyClick}
                            >
                              {promocodebtn ? "Applied" : "Apply"}
                            </Button>
                            <Button
                              className="btn cancel-btn"
                              onClick={() => {
                                handleCanclePromocode(false);
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </Form>
                        )}
                      </div>

                      <div className="promo-features" style={{ width: "100%" }}>
                        <p>
                          <strong>Subtotal:</strong> $
                          {planDetail?.tax_behavior === "exclusive"
                            ? (values?.quantity * perPlan).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : (
                                values?.quantity * perPlan -
                                ((values?.quantity * perPlan) / 1.1) * 0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </p>
                        <p>
                          <strong>GST:</strong>{" "}
                          {planDetail?.tax_behavior === "exclusive"
                            ? `$${(
                                values?.quantity *
                                perPlan *
                                0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : `$${(
                                ((values?.quantity * perPlan) / 1.1) *
                                0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                        </p>
                        <p>
                          <strong>Total Discount:</strong>
                          {planDetail?.tax_behavior === "exclusive"
                            ? (values?.quantity * perPlan).toFixed(2) -
                              (
                                values?.quantity * amontObj?.payableAmount
                              ).toFixed(2)
                              ? "-$" +
                                (
                                  ((values?.quantity * perPlan).toFixed(2) -
                                    (
                                      values?.quantity * amontObj?.payableAmount
                                    ).toFixed(2)) *
                                  1.1
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "$0.00"
                            : (values?.quantity * perPlan).toFixed(2) -
                              (
                                values?.quantity * amontObj?.payableAmount
                              ).toFixed(2)
                            ? "-$" +
                              (
                                (values?.quantity * perPlan).toFixed(2) -
                                values?.quantity * amontObj?.payableAmount
                              )
                                .toFixed(2)
                                .toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                            : "$0.00"}
                        </p>
                        <p>
                          <strong>Total Net Amount:</strong> $
                          {planDetail?.tax_behavior === "exclusive"
                            ? values?.quantity * Number(amontObj?.payableAmount)
                              ? amontObj?.payableAmount && amontObj?.amount_off !== 0
                                ? ((values?.quantity * perPlan * 1.1) - (values?.quantity * Number(amontObj?.payableAmount) * 1.1)).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : 
                                (
                                    values?.quantity *
                                    Number(amontObj?.payableAmount) *
                                    1.1
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : amontObj?.payableAmount === "0.00" && amontObj?.amount_off !== 0
                                ? "0.00"
                                : 
                                (
                                  values?.quantity *
                                  perPlan *
                                  1.1
                                  ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                            : values?.quantity * Number(amontObj?.payableAmount)
                              ? amontObj?.payableAmount && amontObj?.amount_off !== 0
                                ? ((values?.quantity * perPlan) - (values?.quantity * Number(amontObj?.payableAmount))).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                :(
                                    values?.quantity * Number(amontObj?.payableAmount)
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : amontObj?.payableAmount === "0.00" && amontObj?.amount_off !== 0
                                ? "0.00"
                                : 
                                (values?.quantity * perPlan).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          {}
                        </p>
                      </div>
                    </div>
                    {subscriptionDetail && <div className="col-md-12" style={{
                      borderTop: "#CCC 1px solid",
                      padding: "12px"
                    }}>
                      <input
                        type="checkbox"
                        className="mr-5"
                        onClick={(e) => onhandlePaymentMethod(e)}
                        checked={exist}
                      />{' '}
                      Use Existing Payments Method
                    </div>}
                    <div className="col-md-12 payment-cards">
                    {!exist ?
                      <div className="table-top-btn">
                        <div>
                          <input
                            type="radio"
                            // name="card"
                            checked={cardActive}
                            onChange={(e) => {
                              handleChange(e);
                              setCardActive(true);
                              setBankActive(false);
                            }}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                          />
                          <label
                            className="form-check-label"
                            onClick={(e) => {
                              handleChange(e);
                              setCardActive(true);
                              setBankActive(false);
                            }}
                          >
                            Card
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            // name="AU_BECS_Direct_Debit"
                            checked={bankActive}
                            onChange={(e) => {
                              handleChange(e);
                              setCardActive(false);
                              setBankActive(true);
                            }}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                          />
                          <label
                            className="form-check-label"
                            onClick={(e) => {
                              handleChange(e);
                              setCardActive(false);
                              setBankActive(true);
                            }}
                          >
                            AU BECS Direct Debit
                          </label>
                        </div>
                      </div> 
                    : ""  
                    }
                      {cardActive === true && !exist && (
                        <div className="payment-cardtabs">
                          <p>
                            <label>Card Details </label>
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                          </p>
                          <p>
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              value={values?.name}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <span
                              className="text-danger"
                              style={{ color: "red", fontSize: "20px" }}
                            >
                              <ErrorMessage name="name" />
                            </span>
                          </p>
                        </div>
                      )}

                      {exist ? (
                        paymentMethodList?.length > 0 ? 
                        <div className="col-12">
                          <ul className="list-group">
                            {paymentMethodList &&
                              paymentMethodList?.map((item) => {
                                let expiredclass = '';
                                if (item?.type === 'card') {
                                  expiredclass = moment().isBefore(moment(item.expiry, 'MM/YYYY'))
                                    ? ''
                                    : 'expired';
                                }
                                return (
                                  <>
                                    <li
                                      className={
                                        'list-group-item stripe-radio-btn ' +
                                        expiredclass
                                      }
                                      style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        className="mt-1 mr-1"
                                        name="card"
                                        id={item?.id}
                                        onClick={() => handleOnchange(item?.id)}
                                      />
                                      <div className="ms-0 me-auto">
                                        <p>
                                          XXXX XXXX XXXX {item?.last4}{' '}
                                          {item?.type === 'card' && (
                                            <span className="card-brand"> ({item.brand})</span>
                                          )}
                                        </p>
                                            
                                        {item.type === 'au_becs_debit' ? (
                                          <>
                                            <strong>BSB : </strong>
                                            {item?.bsb}
                                          </>
                                        ) : (
                                          <>
                                            <strong>Card Exp. : </strong>
                                            {item?.expiry}
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                          </ul>
                        </div> : <>No payment method exists</>
                        ) : (
                        ''
                      )}

                      {bankActive === true && !exist && (
                        <div className="payment-cardtabs">
                          <p>
                            <label>Account Details</label>
                            <AuBankAccountElement />
                          </p>
                          <p>
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              value={values?.name}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <span
                              className="text-danger"
                              style={{ color: "red", fontSize: "20px" }}
                            >
                              <ErrorMessage name="name" />
                            </span>
                          </p>
                          <p>
                            <label>Email</label>
                            <input
                              type="email"
                              name="email"
                              value={values?.email}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <span
                              className="text-danger"
                              style={{ color: "red", fontSize: "20px" }}
                            >
                              <ErrorMessage name="email" />
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="action-box">
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StripePaymentModal;
