import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Button } from "react-bootstrap";
import { useSuburbList } from "./BusinessHelper";
import { API, entityTypes, get, imageDefaultPrefixPath } from "../../../../config";
import ImageCropModal from "../../../common/ImageCropModal";
import { MultiSelect } from "primereact/multiselect";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { formatName, formatedAddress } from "../../../common/Misc";
import Spin from "../../../common/Spin";
import AddUser from "../../../Users/AddUser";
import AddOffice from "../../../Offices/AddOffice";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleBusinessModal,
  stateListing,
  stateLoading,
  suburbListing,
  suburbLoadingE,
  headClientInput,
  // officeListing,
  // officeLoading,
  setSelectedOffices,
  selectedOffices,
  // userListing,
  // userLoading,
  clientId,
  setSelectedUsers,
  selectedUsers
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleBusinessModal: PropTypes.any,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    suburbListing: PropTypes.any,
    suburbLoadingE: PropTypes.any,
  };

  const params = useParams();
  const clientIDParams = params?.client_id;
  const clientID = localStorage.getItem('sme-clientId')
  ? localStorage.getItem('sme-clientId')
  : clientIDParams;

  // Suburb Listing for Postal Address
  const [suburb, setSuburb] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const profilePic = values?.logo ? values?.logo : "";

  const [selectedFile, setSelectedFile] = useState(profilePic);
  const imageInputRef = React.useRef();

  useEffect(() => {
    setSuburb(suburbListing ? suburbListing : []);
  }, [suburbListing]);
  const [stateId] = useState();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
  } = useSuburbList(stateId);
  useEffect(() => {
    const suburbListing1 = suburbList?.map((item) => {
      const { name: label, id: value, ...rest } = item;
      return { label, value, ...rest };
    });
    setSuburb(suburbListing1);
  }, [suburbList]);

  useEffect(() => {
    values?.logo &&
      typeof values?.logo === "string" &&
      setSelectedFile(values?.logo);
  }, [values]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  const handleRemoveIcon = () => {
    setSelectedFile("");
    imageInputRef.current.value = "";
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleAccountNumberChange = (e) => {
    if(values?.accountNumber.length < 9 && values?.accountNumber && Number(values?.accountNumber) !== 0){
      setFieldValue('accountNumber', padWithLeadingZeros(values?.accountNumber, 9))
    }
    if(Number(values?.accountNumber) === 0){
      setFieldValue('accountNumber', "")
    }
  }

  const [showUser, setShowUser] = useState(false);
  const [userList, setUserList] = useState([]);
  const [newUserLoading, setNewUserLoading] = useState(false);

  const fetchUsers = useCallback(async (clientId) => {
    try {
      setNewUserLoading(true);
      const { data } = await get(
        `${API.GET_CLIENT_USER}?page=1&limit=-1${
          clientID ? `&client_id=${clientID}` : clientId ? `&client_id=${clientId}` : ''
        }`
      );
      setNewUserLoading(false);
      const userListing = data.data.data?.map((item) => {
        const name = formatName(item?.first_name, item?.last_name);
        const id = item?.client_user?.id;
        const items = { name, id };
        const { name: label, id: value, ...rest } = items;
        return { label, value, ...rest };
      });
      setUserList(userListing);
    } catch (e) {
      setUserList([]);
      setNewUserLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const [showOffice, setShowOffice] = useState(false);
  const [officeList, setOfficeList] = useState([]);
  const [newOfficeLoading, setNewOfficeLoading] = useState(false);

  const fetchOffices = useCallback(async (clientId) => {
    try {
      setNewOfficeLoading(true);
      const { data } = await get(
        `${API.GET_OFFICE_LIST}?page=1&limit=-1${
          clientID ? `&client_id=${clientID}` : clientId ? `&client_id=${clientId}` : ''
        }`
      );
      setNewOfficeLoading(false);
      const officeListing = data.data.offices.data?.map((item) => {
        const name = formatedAddress(
          item?.street,
          item?.suburb?.name,
          item?.state?.short_name,
          item?.postal_code
        );
        const id = item?.id;
        const items = { name, id };
        const { name: label, id: value, ...rest } = items;
        return { label, value, ...rest };
      });
      setOfficeList(officeListing);
    } catch (e) {
      setOfficeList([]);
      setNewOfficeLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleOfficeChange = (e, setFieldValue) => {
    setFieldValue('office_ids', e.value)
    setSelectedOffices(e.value)
  }

  const filterOfficeInputRef = useRef();

  const handleOfficeApplyFilter =  () => {
    filterOfficeInputRef.current.hide();
  }

  const officeResetFunction = () => {
    setSelectedOffices([]);
    filterOfficeInputRef && filterOfficeInputRef.current.focus();
    filterOfficeInputRef.current.hide();
  }

  const filterUserInputRef = useRef();

  const handleUserApplyFilter =  () => {
    filterUserInputRef.current.hide();
  }

  const userResetFunction = () => {
    setSelectedUsers([]);
    filterUserInputRef && filterUserInputRef.current.focus();
    filterUserInputRef.current.hide();
  }

  const handleUserChange = (e, setFieldValue) => {
    setFieldValue('user_ids', e.value)
    setSelectedUsers(e.value)
  }

  const toggleAddUserModal = () => {
    showUser && fetchUsers(clientID ? clientID : clientId);
    if (!showUser) {
      filterUserInputRef && filterUserInputRef.current.focus();
      filterUserInputRef.current.hide();
    }
    setShowUser(!showUser);
  };

  const toggleAddOfficeModal = () => {
    showOffice && fetchOffices(clientID ? clientID : clientId);
    if (!showOffice) {
      filterOfficeInputRef && filterOfficeInputRef.current.focus();
      filterOfficeInputRef.current.hide();
    }
    setShowOffice(!showOffice);
  };

  useEffect(() => {
    if (clientId) {
      fetchOffices(clientId);
      fetchUsers(clientId);
    }
  }, [clientId, fetchUsers, fetchOffices]);

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      {(newUserLoading || newOfficeLoading) && <Spin />}
      <div className="modal-content-details justify-center">
        <div className="profile full">
          <div className="form-group half-width profile-photo">
            <input
              accept="image/*"
              type="file"
              name="logo"
              className="form-control"
              id="file"
              ref={imageInputRef}
              onChange={(e) => {
                e.currentTarget.files[0] && setShowModal(true);
                // setFieldValue(`logo`, e.currentTarget.files[0]);
                setSelectedFile(URL.createObjectURL(e.currentTarget.files[0]));
                // handleChange(e);
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            {selectedFile &&
              (selectedFile === `${imageDefaultPrefixPath}null` ? (
                <></>
              ) : selectedFile === `${imageDefaultPrefixPath}undefined` ? (
                <></>
              ) : selectedFile === `${imageDefaultPrefixPath}` ? (
                <></>
              ) : (
                <div className="previewImage d-flex">
                  {/* {setFieldValue("logo", selectedFile)} */}

                  <img
                    className="display-image"
                    src={selectedFile}
                    alt="selectedFile"
                  />
                  <span
                    className="remove-image"
                    onClick={() => {
                      handleRemoveIcon(setFieldValue);
                      setFieldValue("logo", null);
                    }}
                  >
                    Remove Logo
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                </div>
              ))}

            {!selectedFile && <span className="guide_leb">Add your Logo</span>}
            {/* <span className="guide_leb">Add your Logo</span> */}
          </div>
          {/* <span>{values?.business_name?.charAt(0)}</span>

          <h5>{values?.business_name ? values?.business_name : ""}</h5> */}
        </div>
      </div>

      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Business Name
          </label>
          <input
            type="text"
            name="business_name"
            value={values?.business_name ? values?.business_name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="business_name" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Email</label>
          <input
            type="text"
            name="email"
            value={values?.email ? values?.email : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="email" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Phone No.</label>
          <InputMask
            type="text"
            name="phone"
            value={values?.phone ? values?.phone : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="+61 9 9999 9999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="phone" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Mobile No.</label>
          <InputMask
            type="text"
            name="mobile"
            value={values?.mobile ? values?.mobile : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="+61 999 999 999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="mobile" />
          </span>
        </div>

        <div className="form-group half-width">
          <label>ABN</label>
          <InputMask
            type="text"
            name="abn"
            value={values?.abn ? values?.abn : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="99 999 999 999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="abn" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Entity Type</label>
          <Select
            name="entity_type"
            value={values?.entity_type ? values?.entity_type : ""}
            onChange={(e) => {
              setFieldValue(`entity_type`, e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={entityTypes}
            placeholder="Select Entity Type"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="entity_type" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            Ref. Number
          </label>
          <input
            type="text"
            name="ref_number"
            value={values?.ref_number ? values?.ref_number : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="ref_number" />
          </span>
        </div>
        <div className="form-group half-width client-check-input">
          <label>
            &nbsp;
            </label>
            <div className="client-checkbox-body">
            <input type="checkbox" id="head_client" name="head_client" ref={headClientInput} value="1" onChange={(e) => setFieldValue('head_client', e.target.checked)} />
            Head Client
            </div>
        </div>

        <div className="form-group half-width">
          <label>
            Office
          </label>
            <MultiSelect
              value={selectedOffices}
              selectedItemsLabel={`${selectedOffices?.length} Office(s) Selected`}
              name="office_ids"
              onChange={(e) => {
                handleOfficeChange(e, setFieldValue);
              }}
              options={officeList?.length > 0 ? officeList : []}
              optionLabel="label"
              // filter
              placeholder="Select Office"
              maxSelectedLabels={0}
              ref={filterOfficeInputRef}
              panelFooterTemplate={
                <>
                  <hr/>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      handleOfficeApplyFilter();
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      officeResetFunction();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      toggleAddOfficeModal();
                    }}
                  >
                    Add
                  </Button>
                </>
              }
            />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="office_ids" />
          </span>
        </div>

        <div className="form-group half-width">
          <label>
            User
          </label>
            <MultiSelect
              value={selectedUsers}
              selectedItemsLabel={`${selectedUsers?.length} User(s) Selected`}
              name="user_ids"
              onChange={(e) => {
                handleUserChange(e, setFieldValue);
              }}
              options={userList?.length > 0 ? userList : []}
              optionLabel="label"
              // filter
              placeholder="Select User"
              maxSelectedLabels={0}
              ref={filterUserInputRef}
              panelFooterTemplate={
                <>
                  <hr/>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      handleUserApplyFilter();
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      userResetFunction();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      toggleAddUserModal();
                    }}
                  >
                    Add
                  </Button>
                </>
              }
            />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="office_ids" />
          </span>
        </div>
        
        <div className="address-box">
          <h5>Address</h5>
          <div className="form-group half-width">
            <label>Address</label>
            <input
              type="text"
              name="street"
              value={values?.street ? values?.street : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="street" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Select State</label>
            <Select
              name="state_id"
              value={values?.state_id ? values?.state_id : ""}
              onChange={(e) => {
                setFieldValue(`state_id`, e ? e : "");
                setFieldValue(`suburb_id`, "");
                e && handleStateSelect(e.value);
                // handleChange(e);
              }}
              onBlur={handleBlur}
              options={stateListing}
              placeholder="Select State"
              classNamePrefix="selectbox"
              isClearable
              isLoading={stateLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />

            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="state_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Select Suburb</label>

            <Select
              name="suburb_id"
              value={values?.suburb_id ? values?.suburb_id : ""}
              onChange={(e) => {
                setFieldValue(`suburb_id`, e ? e : "");
                // handleChange(e);
              }}
              onBlur={handleBlur}
              options={suburb}
              placeholder="Select Suburb"
              classNamePrefix="selectbox"
              isClearable
              isLoading={suburbLoading || suburbLoadingE}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />

            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="suburb_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Postal Code</label>
            <InputMask
              type="text"
              name="postal_code"
              value={values?.postal_code ? values?.postal_code : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="postal_code" />
            </span>
          </div>
        </div>

        <div className="address-box">
          <h5>Account Details</h5>
          <div className="form-group half-width">
            <label>Account Name</label>
            <input
              type="text"
              name="accountName"
              value={values?.accountName ? values?.accountName : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="accountName" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>BSB</label>
            <InputMask
              type="text"
              name="bsb"
              value={values?.bsb ? values?.bsb : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="999-999"
              placeholder="123-456"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="bsb" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Account Number</label>
            <InputMask
              type="text"
              name="accountNumber"
              value={values?.accountNumber ? values?.accountNumber : ""}
              onChange={handleChange}
              mask="999999999"
              maskChar={null}
              placeholder="123456789"
              onBlur={handleAccountNumberChange}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="accountNumber" />
            </span>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="reset"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleBusinessModal();
          }}
        >
          Cancel
        </Button>
      </div>
      {showModal && (
        <ImageCropModal
          selectedFile={selectedFile}
          setShowModal={setShowModal}
          setSelectedFile={setSelectedFile}
          showModal={showModal}
          setFieldValue={setFieldValue}
        />
      )}
      {showUser && (
        <AddUser
          toggleUserModal={toggleAddUserModal}
          // userModal={userModal}
          showUser={showUser}
          clientId={clientID ? clientID : clientId}
          businessSettings={true}
          clientAdd={true}
        />
      )}
      {showOffice && (
        <AddOffice
          toggleOfficeModal={toggleAddOfficeModal}
          // userModal={userModal}
          showOffice={showOffice}
          clientId={clientID ? clientID : clientId}
          businessSettings={true}
          clientAdd={true}
        />
      )}
    </form>
  );
};

export default Form;
