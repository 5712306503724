import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, fileUpload, get } from "../../../../config";
import Pagination from "../../../common/Pagination";
import Spin from "../../../common/Spin";
import { Button, Modal } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import AddNewAccountModal from "../../CashFlow/components/AddNewAccountModal";
import Select from "react-select";
import moment from "moment";
import { TrailReallocteItemSchema } from "../../../common/Validation";
import CreatableSelect from "react-select/creatable";

const ExpandedTrialBalance = ({ id, businessId, startMonth, endMonth, code, getClientsReportData, valueRange, baseType, is_bank_reconciliation, is_cash_transaction }) => {
  const [trialLoading, setTrialLoading] = useState(false);
  const [trialData, setTrialData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState('transactionDate');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [showReallocate, setShowReallocate]= useState(false);

  const [loading, setLoading] = useState(false);

  const [addNewToAccount, setAddNewToAccount] = useState(false);
  const [newToAccount, setNewToAccount] = useState();

  const [accountLoading, setAccountLoading] = useState(false);
  const [accountData, setAccountData] = useState();
  const [allAccountValue, setAllAccountValue] = useState([]);
  const [newAccountName, setNewAccountName] = useState("");

  const fetchTrialBalanceData = useCallback(async () => {
    try {
      if (businessId && id) {
        setTrialLoading(true);
        const { data } = await get(
          `${
            API.GET_TRIAL_BALANCE_ENTRIES_DATA
          }?start_month=${startMonth}&end_month=${endMonth}&export=0&limit=${
            limit ? limit : 10
          }&page=${
            page ? page : 1
          }&chart_of_account_id=${id}&client_business_id=${
            businessId ? businessId : ""
          }&sort_column=${sortField ? sortField : ""}&sort_order=${
            sortOrder ? sortOrder : ""
          }&is_bank_reconciliation=${is_bank_reconciliation}&is_cash_transaction=${is_cash_transaction}`
        );

        setTrialLoading(false);
        setLimit(data?.data?.reports?.per_page);
        setPage(data?.data?.reports?.current_page);
        setTotal(data?.data?.reports?.total);
        setFrom(data?.data?.reports?.from);
        setTo(data?.data?.reports?.to);
        setTrialData(data?.data?.reports?.data);
      }
    } catch (e) {
      const { data } = e;
      setTrialLoading(false);
      setTrialData([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [businessId, id, startMonth, endMonth, limit, page, sortOrder, sortField, is_bank_reconciliation, is_cash_transaction]);

  const trialBalanceDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: trialData[i]?.id,
        transaction_id: trialData[i]?.bank_transaction?.transaction_id,
        account: trialData[i]?.code ? `${trialData[i]?.code?.length < 4 ? padWithLeadingZeros(Number(trialData[i]?.code), 4) : trialData[i]?.code}${trialData[i]?.sub_code ? `.${trialData[i]?.sub_code}` : ''}` : "-",
        transactionDate: trialData[i]?.transactionDate ? trialData[i]?.transactionDate?.split("-")[2]+"-"+trialData[i]?.transactionDate?.split("-")[1]+"-"+trialData[i]?.transactionDate?.split("-")[0] : '',
        description: trialData[i]?.description,
        source: trialData[i]?.source ? trialData[i]?.source.charAt(0).toUpperCase() + trialData[i]?.source.slice(1) : "-",
        tax_code: trialData[i]?.gst === 0 ? "GST Excluded" : trialData[i]?.gst === 1 ? "GST 10%" : trialData[i]?.gst === 2 ? "BAS Excluded" : "GST on Imports",
        credit:
          (trialData[i]?.type === 1 && code !== "2000" && code !== "2050") ? (
            <span>
              ${(code === "3380" && is_bank_reconciliation === 0) ?  (trialData[i]?.gst_amount ? trialData[i]?.gst_amount?.toFixed(2) : trialData[i]?.net_amount ? trialData[i]?.net_amount?.toFixed(2) : "0.00") : trialData[i]?.net_amount ? trialData[i]?.net_amount?.toFixed(2) : "0.00"}
            </span>
          ) : (trialData[i]?.type === 0 && ( code === "2000" || code === "2050") && is_bank_reconciliation === 0) ? (trialData[i]?.amount ? `$${trialData[i]?.amount?.toFixed(2)}` : "$0.00") : (
            "-"
          ),
        debit:
          (trialData[i]?.type === 0 && code !== "2000" && code !== "2050") ? (
            <span>
              ${(code === "3380" && is_bank_reconciliation === 0) ?  (trialData[i]?.gst_amount ? trialData[i]?.gst_amount?.toFixed(2) : trialData[i]?.net_amount ? trialData[i]?.net_amount?.toFixed(2) : "0.00") : trialData[i]?.net_amount ? trialData[i]?.net_amount?.toFixed(2) : "0.00"}
            </span>
          ) : (trialData[i]?.type === 1 && (code === "2000" || code === "2050") && is_bank_reconciliation === 0) ? (trialData[i]?.amount ? `$${trialData[i]?.amount?.toFixed(2)}` : "$0.00") : (
            "-"
          ),
      });
    }
    return items;
  };
  const trialBalanceData = trialBalanceDataGenerator(trialData?.length);

  const columns = [
    // {
    //   dataField: "transaction_id",
    //   text: "Transaction ID",
    // },
    {
      dataField: "transactionDate",
      text: "Date",
      sort: true,
      // sortFunc: (a, b, order) => {
      //   let old = a.split("-");
      //   let newDate = b.split("-");
      //   if (order === "asc") {
      //     return new Date(`${old[1]}-${old[0]}-${old[2]}`).getTime() - new Date(`${newDate[1]}-${newDate[0]}-${newDate[2]}`).getTime();
      //   } else if (order === "desc") {
      //     return new Date(`${newDate[1]}-${newDate[0]}-${newDate[2]}`).getTime() - new Date(`${old[1]}-${old[0]}-${old[2]}`).getTime();
      //   }
      // }
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "account",
      text: "Account",
    }, 
    {
      dataField: "tax_code",
      text: "Tax Code",
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      headerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      headerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "source",
      text: "Source",
    }
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchTrialBalanceData();
  }, [fetchTrialBalanceData]);

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }
  
  const getAccounts = useCallback(async () => {
    try {
      setAccountLoading(true);
      get(
        `${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`
        ).then((response) => {
          setAccountLoading(false);
          var revenue =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "fada83c9-4769-49b9-ab0e-fcd8c43de30e"
                  );
                })
              : [];
  
          const revenueList =
            revenue && revenue?.length > 0
              ? revenue?.map((item, index) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];
  
            var expenses =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fad90f00-6651-4c51-a29a-78398fa226fd"
                    );
                  })
                : [];
    
            const expensesList =
              expenses && expenses?.length > 0
                ? expenses?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var currentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "3e5a94ef-b819-47c3-9955-d47da29e2ab0"
                    );
                  })
                : [];
    
            const currentLiabilitiesList =
              currentLiabilities && currentLiabilities?.length > 0
                ? currentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fcb7e129-7666-42ae-8c3d-11d016bbe340"
                    );
                  })
                : [];
        
            const nonCurrentLiabilitiesList =
              nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
                ? nonCurrentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
            
            var currentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "994d6d38-497b-465d-9f57-3bdc2d70594a"
                    );
                  })
                : [];
          
            const currentAssetsList =
              currentAssets && currentAssets?.length > 0
                ? currentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "4051efd3-9fb8-4adb-b0c7-7e565a078ac1"
                    );
                  })
                : [];
            
            const nonCurrentAssetsList =
              nonCurrentAssets && nonCurrentAssets?.length > 0
                ? nonCurrentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var equity =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "520f936e-3017-4574-a9ab-6c9e68b591e4"
                    );
                  })
                : [];
              
            const equityList =
              equity && equity?.length > 0
                ? equity?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
              const allAccountListing = [
              revenueList && { label: <div className="list-subHeading">Revenue</div>, options: revenueList },
              expensesList && { label: <div className="list-subHeading">Expenses</div>, options: expensesList },
              currentLiabilitiesList && { label: <div className="list-subHeading">Current Liabilities</div>, options: currentLiabilitiesList },
              nonCurrentLiabilitiesList && { label: <div className="list-subHeading">Non Current Liabilities</div>, options: nonCurrentLiabilitiesList },
              currentAssetsList && { label: <div className="list-subHeading">Current Assets</div>, options: currentAssetsList},
              nonCurrentAssetsList && { label: <div className="list-subHeading">Non Current Assets</div>, options: nonCurrentAssetsList},
              equityList && { label: <div className="list-subHeading">Equity</div>, options: equityList},
              { label: <strong>+ Add New Account</strong>, value: "new" }
            ];
  
            setAccountData(allAccountListing)
            setAllAccountValue([
              ...revenueList,
              ...expensesList,
              ...currentLiabilitiesList,
              ...nonCurrentLiabilitiesList,
              ...currentAssetsList,
              ...nonCurrentAssetsList,
              ...equityList
            ])
        });
    } catch (e) {
      setAccountLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const toggleReallocate = () => {
    setShowReallocate(!showReallocate)
  }

  const handleAddNewToAccount = () => {
    setAddNewToAccount(!addNewToAccount);
  };

  const handleFormSubmit = async (values) => {

    const formData = new FormData();
    values.chart_of_account_id = values.chart_of_account_id ? values.chart_of_account_id?.value : ''
    values.gst = values?.gst ? values?.gst?.value :  '';
    
    formData.append("client_business_id", businessId);
    formData.append("chart_of_account_id", values.chart_of_account_id);
    formData.append("start_month", moment(valueRange[0]).format("MM-YYYY") ? moment(valueRange[0]).format("MM-YYYY") : "")
    formData.append("end_month",  moment(valueRange[1]).format("MM-YYYY") ? moment(valueRange[1]).format("MM-YYYY") : "")
    formData.append('gst', values?.gst);

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BANK_RECONCILED_TRANSACTION_BULK_REALLOCATE}/${id}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleReallocate();
        getClientsReportData();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const GSTOptions = [
    { label: "GST 10%", value: 1 },
    { label: "GST Excluded", value: 0 },
    { label: "BAS Excluded", value: 2 },
    { label: "GST on Imports", value: 3 },
  ];
  
  return (
    <>
      <div className="content-details">
        <div className="custom-table trial-balance-expand-table">
          {(trialLoading || loading) && <Spin />}
          <BootstrapTable
            keyField="key"
            remote
            onTableChange={handleTableChange}
            data={trialBalanceData}
            columns={columns}
            noDataIndication="No Data Found"
            // defaultSorted={defaultSorted}
          />
          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
            reallocate={true}
            code={code}
            toggleReallocate={toggleReallocate}
          />
        </div>
        {showReallocate && (
        <Modal
        size="lg"
        show={showReallocate}
        onHide={toggleReallocate}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section reconcile-fix-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Reallocate
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              from_account_id: id ? allAccountValue?.find((i) => i.value === id) :  '',
              chart_of_account_id: newToAccount ? newToAccount : '',
              gst: newToAccount ? GSTOptions.find((item) => item.value === allAccountValue.find((i) => i.value === newToAccount.value)?.tax) : '',
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={TrailReallocteItemSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(accountLoading) && <Spin />}
                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                    <div className="form-box">
                      <div className="form-group full-width">
                        <label>
                          From
                        </label>
                        <Select
                          name="from_account_id"
                          value={values?.from_account_id ? values?.from_account_id : ''}
                          isDisabled
                          onBlur={handleBlur}
                          options={accountData}
                          isLoading={accountLoading}
                          placeholder="Select Account"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="from_account_id" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>To
                        </label>
                        <CreatableSelect
                          formatCreateLabel={(userInput) => (
                            <>
                              <strong>+ Add New Account </strong>'{`${userInput}`}'
                            </>
                          )}
                          name="chart_of_account_id"
                          value={values?.chart_of_account_id ? values?.chart_of_account_id : newToAccount ? newToAccount : ''}
                          onChange={(e) => {
                            if (e?.value === 'new' || e?.__isNew__ === true) {
                              if(e?.__isNew__ === true){
                                setNewAccountName(e?.label);
                              } else {
                                setNewAccountName("")
                              }
                              setAddNewToAccount(true);
                            } else {
                              setFieldValue(`chart_of_account_id`, e ? e : '');
                              setFieldValue(`gst`, e ? GSTOptions?.find((i) => i.value === e.tax) : '')
                              setNewToAccount(e);
                            }
                          }}
                          onBlur={handleBlur}
                          options={accountData}
                          isLoading={accountLoading}
                          placeholder="Select Account"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="chart_of_account_id" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>GST Type
                        </label>
                        <Select
                          name="gst"
                          value={values?.gst ? values?.gst : ''}
                          onChange={(e) => {
                            setFieldValue('gst', e ? e : '');
                          }}
                          onBlur={handleBlur}
                          options={GSTOptions}
                          placeholder="Select GST"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="gst" />
                        </span>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        onClick={() => {
                          toggleReallocate();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </Button>
                    </div>
                    {addNewToAccount && (
                      <AddNewAccountModal
                        addNewAccount={addNewToAccount}
                        getAccounts={getAccounts}
                        handleAddNewAccount={handleAddNewToAccount}
                        businessId={businessId}
                        setNewAccount={setNewToAccount}
                        setFieldValue1={setFieldValue}
                        newAccountName={newAccountName}
                      />
                    )}
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
        </Modal>
        )}
      </div>
    </>
  );
};

export default ExpandedTrialBalance;
