import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, FormControl } from 'react-bootstrap';
import Spin from '../../../common/Spin';
import Pagination from '../../../common/Pagination';
import { debounce } from 'lodash';
import { API, get } from '../../../../config';
import { toast } from 'react-toastify';
import AddCashReceipts from './AddCashReceipts';
import CashReceiptActionButton from './components/CashReceiptActionButton';
import { formatName, formatNumber } from '../../../common/Misc';
import Workinprogrees from '../../../../components/Workinprogrees';

const CashReceipts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const businessId = params?.business_id;
  const [searchParam, setSearchParam] = useState(search || '');
  const [searchParamData, setSearchParamData] = useState(search || '');
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [receiptList, setReceiptList] = useState([]);
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [showAddReceipt, setShowAddReceipt] = useState(false);

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if (nextValue?.length >= 2 || nextValue?.length === 0) {
      debouncedSave(nextValue);
    }
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleDelete = () => {
    fetchReceipts();
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const columns = [
    {
      text: 'Date',
      dataField: 'date',
      sort: true,
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    {
      text: 'Account',
      dataField: 'account',
      headerStyle: () => {
        return { width: '12%' };
      }
    },
    {
      text: 'Description',
      dataField: 'description',
      headerStyle: () => {
        return { width: '15%' };
      }
    },
    {
      text: 'GST',
      dataField: 'gst',
      headerStyle: () => {
        return { width: '6%' };
      }
    },
    {
      text: 'Conatct Name',
      dataField: 'contact_name',
      headerStyle: () => {
        return { width: '11%' };
      }
    },
    
    {
      text: 'Job',
      dataField: 'business_job_id',
      headerStyle: () => {
        return { width: '5%' };
      }
    },
    {
      text: 'Task',
      dataField: 'business_task_id',
      headerStyle: () => {
        return { width: '5%' };
      }
    },
    {
      text: 'Net Amount',
      dataField: 'net_amount',
      headerStyle: () => {
        return { width: '11%' };
      }
    },
    {
      text: 'GST Amount',
      dataField: 'gst_amount',
      headerStyle: () => {
        return { width: '11%' };
      }
    },
    {
      text: 'Total Amount',
      dataField: 'amount',
      headerStyle: () => {
        return { width: '11%' };
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      style: { width: '127px' }
    }
  ];

  const fetchReceipts = useCallback(async () => {
    try {
      setReceiptLoading(true);
      const { data } = await get(
        `${API.GET_CASH_RECEIPT}?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${
          sortField ? sortField : ''
        }&sort_order=${sortOrder ? sortOrder : ''}&search=${
          searchParam ? searchParam : ''
        }&client_business_id=${businessId ? businessId : ''}`
      );

      setReceiptLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const receiptList = data.data.data;
      setReceiptList(receiptList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setReceiptLoading(false);
      setReceiptList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, searchParam, sortField, sortOrder, businessId]);

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const paymentGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: receiptList[i]?.id,
        id: receiptList[i]?.id ? receiptList[i]?.id : '',
        date: receiptList[i]?.transactionDate ? receiptList[i]?.transactionDate : '-',
        account: receiptList[i]?.chart_of_account ? `${receiptList[i]?.chart_of_account?.code?.length < 4 ? padWithLeadingZeros(Number(receiptList[i]?.chart_of_account?.code),4) : receiptList[i]?.chart_of_account?.code}${receiptList[i]?.chart_of_account?.sub_code ? `.${receiptList[i]?.chart_of_account?.sub_code}` : ''} - ` + receiptList[i]?.chart_of_account?.name : '-',
        gst: receiptList[i]?.gst === 0 ? "GST Excluded" : receiptList[i]?.gst === 1 ? "GST 10%" : receiptList[i]?.gst === 2 ? "BAS Excluded" : "GST on Imports",
        contact_name: receiptList[i]?.contact ? formatName(receiptList[i]?.contact?.first_name, receiptList[i]?.contact?.last_name) : "-",
        description: receiptList[i]?.description ? receiptList[i]?.description : '-',
        amount: receiptList[i]?.amount ? `$${formatNumber(receiptList[i]?.amount)}` : '-',
        business_job_id: receiptList[i]?.business_job ? receiptList[i]?.business_job?.code : '-',
        business_task_id: receiptList[i]?.business_task ? receiptList[i]?.business_task?.code : '-',
        gst_amount: receiptList[i]?.gst_amount ? `$${formatNumber(receiptList[i]?.gst_amount)}` : '-',
        net_amount: receiptList[i]?.net_amount ? `$${formatNumber(receiptList[i]?.net_amount)}` : '-',
        action: (
          <CashReceiptActionButton
            id={receiptList[i]?.id}
            handleDelete={handleDelete}
            fetchReceipts={fetchReceipts}
          />
        )
      });
    }
    return items;
  };

  const receipts = paymentGenerator(receiptList?.length);

  const toggleShowModal = () => {
    setShowAddReceipt(!showAddReceipt);
  };

  useEffect(() => {
    navigate(
      `?tab=cash-receipt&limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ''
      }&sort_column=${sortField ? sortField : ''}&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Cash Receipts"
                    />
                    {searchParamData ? (
                      <Button
                        className="btn-close"
                        onClick={() => {
                          setSearchParamData('');
                          setSearchParam('');
                        }}
                      >
                        x
                      </Button>
                    ) : (
                      <Button className="fa fa-search">Search</Button>
                    )}
                  </Form>
                </div>
                <div className="table-btn">
                  <Button variant="primary" onClick={toggleShowModal}>
                    Enter Receipt
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {receiptLoading && <Spin />}
                <BootstrapTable
                  keyField="code"
                  remote
                  data={receipts}
                  onTableChange={handleTableChange}
                  columns={columns}
                  noDataIndication="No Data Found"
                />

                {receipts.length !== 0 ? (
                  <Pagination
                    total={total}
                    limit={parseInt(limit)}
                    currentPage={page}
                    updateLimit={handleUpdateLimit}
                    updatePage={handleChangePage}
                    from={from}
                    to={to}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <Workinprogrees />
      </div>
      {showAddReceipt && (
        <AddCashReceipts
          businessId={businessId}
          toggleShowModal={toggleShowModal}
          fetchReceipts={fetchReceipts}
          showAddReceipt={showAddReceipt}
        />
      )}
    </>
  );
};

export default CashReceipts;
