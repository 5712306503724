import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import "../../assets/css/style.css";

const ClientHeaderNav = () => {
  const params = useParams();
  const clientId = params?.client_id;
  const role = localStorage.getItem("sme-role");

  return (
    <ul className="header-nav">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link
                to={{
                  pathname: "/clients",
                }}
              >
                Home
              </Link>
            </li>

            {/* <li className="nav-item active">
              <Link
                to={{
                  pathname: "/client/" + clientId,
                }}
              >
                Dashboard
              </Link>
            </li> */}

            <li className="nav-item active">
              <Link
                to={{
                  pathname: `/client/${clientId}/businesses`,
                }}
              >
                Businesses
              </Link>
            </li>

            <li className="nav-item active">
              <Link
                to={{
                  pathname: `/client/${clientId}/users`,
                }}
              >
                Users
              </Link>
            </li>

            <li className="nav-item active">
              <Link
                to={{
                  pathname: `/client/${clientId}/offices`,
                }}
              >
                Offices
              </Link>
            </li>
            
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Settings
              </a>

              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                {role !== 'Client' && <li>
                  <Dropdown.Item as={Link} to={`/user-settings?tab=invitations-from-client`}>
                    Invitations From Client
                  </Dropdown.Item>
                </li>}
                <li>
                  <Dropdown.Item as={Link} to={`/general-settings`}>
                    General Settings
                  </Dropdown.Item>
                </li>
              </ul>
            </li>
            
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Help
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to="https://smecashbook.com.au/terms-conditions/" target="_blank">
                  <a
                    href="https://smecashbook.com.au/terms-conditions/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: '100%', display: 'block' }}
                  >
                    Terms of Use
                  </a>
                  </Dropdown.Item>
                </li>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </ul>
  );
};

export default ClientHeaderNav;
