import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { API, get, imageDefaultPrefixPath } from '../../../../../config';
import { checkIfFileExists, formatName, formatNumber } from '../../../../common/Misc';
import { useParams } from 'react-router-dom';
import { getBusinessData } from '../../../components/BusinessHelper';
import { useState } from 'react';
import { printComponent } from '../../../../common/print';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spin from '../../../../common/Spin';

const ViewQuote = ({ id, values, businessData, toggleShowModal, showView }) => {
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [terms, setTerms] = useState(14);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  function printFile() {
    return printComponent('print-invoice');
  }

  const fetchQuotePreviewData = useCallback(async () => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await get(`${API.QUOTES_DETAILS}/${id}`);
        setData(data?.data);
        var date1 = data?.data?.date?.includes('-')
          ? new Date(
              String(data?.data?.date)?.split('-')[2],
              String(data?.data?.date)?.split('-')[1] - 1,
              String(data?.data?.date)?.split('-')[0]
            )
          : '';
        var date2 = data?.data?.due_date?.includes('-')
          ? new Date(
              String(data?.data?.due_date)?.split('-')[2],
              String(data?.data?.due_date)?.split('-')[1] - 1,
              String(data?.data?.due_date)?.split('-')[0]
            )
          : '';
        var Difference_In_Time = date2?.getTime() - date1?.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        setTerms(Difference_In_Days);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [id]);

  useEffect(() => {
    fetchQuotePreviewData();
  }, [fetchQuotePreviewData]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        View Quote
      </Modal.Header>
      <Modal.Body
        style={{
          padding: '15px'
        }}
      >
        {loading ? (
          <Spin />
        ) : (
          <>
            {data?.template_setting?.settings?.quote_style === 0 ? (
              <>
                <div id="print-invoice">
                  <div
                    className="boxed-invoice"
                    style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
                  >
                    <table style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%' }}>
                            {BusinessLogo && (
                              <img
                                src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                alt=""
                                style={{
                                  maxWidth: '95px',
                                  maxHeight: '80px',
                                  marginRight: '5px'
                                }}
                              />
                            )}{' '}
                            {BusinessName}
                          </td>
                          <td style={{ textAlign: 'end', width: '50%' }}>
                            <p>
                              {BusinessName}
                              {data?.office ? (
                                <>
                                  <br />
                                  {data?.office?.street ? data?.office?.street : ''}
                                  <br />
                                  {data?.office?.suburb ? data?.office?.suburb?.name : ''}
                                  {data?.office?.suburb && ' '}
                                  {data?.office?.state
                                    ? data?.office?.state?.short_name
                                      ? data?.office?.state?.short_name
                                      : data?.office?.state?.name
                                    : ''}
                                  {data?.office?.state && ' '}
                                  {data?.office?.postal_code ? data?.office?.postal_code : ''}
                                </>
                              ) : (
                                <>
                                  <br />
                                  {businessData?.street ? businessData?.street : ''}
                                  <br />
                                  {businessData?.suburb ? businessData?.suburb?.name : ''}
                                  {businessData?.suburb && ' '}
                                  {businessData?.state
                                    ? businessData?.state?.short_name
                                      ? businessData?.state?.short_name
                                      : businessData?.state?.name
                                    : ''}
                                  {businessData?.state && ' '}
                                  {businessData?.postal_code ? businessData?.postal_code : ''}
                                </>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr
                      style={{
                        margin: 0,
                        marginTop: '8px',
                        marginBottom: '25px',
                        borderTop: '1px solid black',
                        width: '100%'
                      }}
                    />
                    <table style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}>
                      <tbody>
                        <tr style={{ display: 'flex', gap: '15px' }}>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '15px',
                              height: '100px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-evenly',
                              width: '65%'
                            }}
                          >
                            {data?.template_setting?.settings?.client_option?.non_individual ? (
                              <div>
                                {data?.template_setting?.settings?.client_option?.alternateName
                                  ? data?.contact && data?.contact?.display_name
                                    ? data?.contact?.display_name
                                    : formatName(
                                        data?.contact?.first_name,
                                        data?.contact?.last_name
                                      )
                                  : data?.contact?.business_name
                                  ? data?.contact?.business_name
                                  : formatName(data?.contact?.first_name, data?.contact?.last_name)}
                                <>
                                  {data?.contact && (
                                    <>
                                      <p style={{ margin: '0px' }}>
                                        {data?.contact?.shipping_street
                                          ? data?.contact?.shipping_street
                                          : data?.contact?.street
                                          ? data?.contact?.street
                                          : ''}
                                      </p>
                                      <p style={{ margin: '0px' }}>
                                        {data?.contact?.shipping_suburb
                                          ? data?.contact?.shipping_suburb?.name
                                          : data?.contact?.suburb
                                          ? data?.contact?.suburb?.name
                                          : ''}
                                        {(data?.contact?.suburb ||
                                          data?.contact?.shipping_suburb) &&
                                          ' '}
                                        {data?.contact?.shipping_state
                                          ? data?.contact?.shipping_state?.short_name
                                            ? data?.contact?.shipping_state?.short_name
                                            : data?.contact?.shipping_state?.name
                                          : data?.contact?.state
                                          ? data?.contact?.state?.short_name
                                            ? data?.contact?.state?.short_name
                                            : data?.contact?.state?.name
                                          : ''}
                                        {(data?.contact?.state || data?.contact?.shipping_state) &&
                                          ' '}
                                        {data?.contact?.shipping_postal_code
                                          ? data?.contact?.shipping_postal_code
                                          : data?.contact?.postal_code
                                          ? data?.contact?.postal_code
                                          : ''}
                                      </p>
                                      {/* <p>{data?.contact}</p> */}
                                    </>
                                  )}
                                </>
                              </div>
                            ) : (
                              <div>
                                {data?.template_setting?.settings?.client_option?.alternateName ? (
                                  data?.contact && data?.contact?.display_name ? (
                                    data?.contact?.display_name
                                  ) : data?.contact?.first_name ? (
                                    <p style={{ margin: '0px' }}>
                                      {data?.contact?.first_name + ' ' + data?.contact?.last_name}
                                    </p>
                                  ) : (
                                    <p style={{ margin: '0px' }}>{data?.contact?.last_name}</p>
                                  )
                                ) : data?.contact && data?.contact?.first_name ? (
                                  <p style={{ margin: '0px' }}>
                                    {data?.contact?.first_name + ' ' + data?.contact?.last_name}
                                  </p>
                                ) : (
                                  <p style={{ margin: '0px' }}>{data?.contact?.last_name}</p>
                                )}
                                <>
                                  {data?.contact && (
                                    <>
                                      <p style={{ margin: '0px' }}>
                                        {data?.contact?.street
                                          ? data?.contact?.street
                                          : data?.contact?.shipping_street
                                          ? data?.contact?.shipping_street
                                          : ''}
                                      </p>
                                      <p style={{ margin: '0px' }}>
                                        {data?.contact?.suburb
                                          ? data?.contact?.suburb?.name
                                          : data?.contact?.shipping_suburb
                                          ? data?.contact?.shipping_suburb?.name
                                          : ''}
                                        {(data?.contact?.suburb ||
                                          data?.contact?.shipping_suburb) &&
                                          ' '}
                                        {data?.contact?.state
                                          ? data?.contact?.state?.short_name
                                            ? data?.contact?.state?.short_name
                                            : data?.contact?.state?.name
                                          : data?.contact?.shipping_state
                                          ? data?.contact?.shipping_state?.name
                                          : ''}
                                        {(data?.contact?.state || data?.contact?.shipping_state) &&
                                          ' '}
                                        {data?.contact?.postal_code
                                          ? data?.contact?.postal_code
                                          : data?.contact?.shipping_postal_code
                                          ? data?.contact?.shipping_postal_code
                                          : ''}
                                      </p>
                                      {/* <p>{data?.contact}</p> */}
                                    </>
                                  )}
                                </>
                              </div>
                            )}
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '15px',
                              textAlign: 'center',
                              height: '100px',
                              width: '35%'
                            }}
                          >
                            <h6 style={{ margin: '0px', fontSize: '16px' }}>QUOTE</h6>
                            <p
                              style={{
                                fontWeight: 'bold',
                                margin: '0px',
                                marginBottom: '9px',
                                marginTop: '7px'
                              }}
                            >
                              Quote Number: {data?.quote_number}
                            </p>
                            <p style={{ margin: '0px' }}>Quote Date: {data?.date}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        border: '1px solid black',
                        fontFamily: 'Poppins',
                        borderSpacing: '0px',
                        fontSize: '12px'
                      }}
                      cellSpacing={0}
                      cellPadding={0}
                    >
                      <thead>
                        <tr>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    width: '50%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    width: '50%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Description
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: `transparent
                              linear-gradient(
                                180deg,
                                ${data?.template_setting?.settings?.secondary_color} 0%,
                                ${data?.template_setting?.settings?.primary_color} 100%
                              )
                              0% 0% no-repeat padding-box`
                                  }
                                : {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Quantity
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: `transparent
                              linear-gradient(
                                180deg,
                                ${data?.template_setting?.settings?.secondary_color} 0%,
                                ${data?.template_setting?.settings?.primary_color} 100%
                              )
                              0% 0% no-repeat padding-box`
                                  }
                                : {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Unit Price
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: `transparent
                                linear-gradient(
                                  180deg,
                                  ${data?.template_setting?.settings?.secondary_color} 0%,
                                  ${data?.template_setting?.settings?.primary_color} 100%
                                )
                                0% 0% no-repeat padding-box`
                                  }
                                : {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            GST
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: `transparent
                                linear-gradient(
                                  180deg,
                                  ${data?.template_setting?.settings?.secondary_color} 0%,
                                  ${data?.template_setting?.settings?.primary_color} 100%
                                )
                                0% 0% no-repeat padding-box`
                                  }
                                : {
                                    width: '10%',
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Discount
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: `transparent
                                linear-gradient(
                                  180deg,
                                  ${data?.template_setting?.settings?.secondary_color} 0%,
                                  ${data?.template_setting?.settings?.primary_color} 100%
                                )
                                0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    textAlign: 'right',
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.quote_items.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: '8px',
                                  borderRight: '1px solid black',
                                  borderBottom: '1px solid black'
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{ __html: item?.description }}
                                ></span>
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  borderBottom: '1px solid black',
                                  borderRight: '1px solid black',
                                  textAlign: 'right'
                                }}
                              >
                                {item?.quantity}
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  borderBottom: '1px solid black',
                                  borderRight: '1px solid black',
                                  textAlign: 'right'
                                }}
                              >
                                $
                                {item?.unit_price ? formatNumber(Number(item?.unit_price)) : '0.00'}
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  borderBottom: '1px solid black',
                                  borderRight: '1px solid black',
                                  textAlign: 'right'
                                }}
                              >
                                $
                                {item?.gst_amount ? formatNumber(Number(item?.gst_amount)) : '0.00'}
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  borderBottom: '1px solid black',
                                  borderRight: '1px solid black',
                                  textAlign: 'right'
                                }}
                              >
                                $
                                {item?.discount_amount
                                  ? formatNumber(Number(item?.discount_amount))
                                  : '0.00'}
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  borderBottom: '1px solid black',
                                  textAlign: 'right'
                                }}
                              >
                                ${item?.amount ? formatNumber(Number(item?.amount)) : '0.00'}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td
                            style={{
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              fontWeight: 'bold'
                            }}
                            colSpan={2}
                          >
                            Subtotal
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black',
                              fontWeight: 'bold',
                              textAlign: 'right'
                            }}
                          >
                            $
                            {data?.total_amount ? formatNumber(Number(data?.total_amount)) : '0.00'}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              fontWeight: 'bold',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              textAlign: 'right'
                            }}
                          >
                            $
                            {data?.total_gst_amount
                              ? formatNumber(Number(data?.total_gst_amount))
                              : '0.00'}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black',
                              fontWeight: 'bold',
                              textAlign: 'right'
                            }}
                          >
                            $
                            {data?.discount_id
                              ? '0.00'
                              : data?.discount_amount
                              ? formatNumber(Number(data?.discount_amount))
                              : '0.00'}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              fontWeight: 'bold',
                              textAlign: 'right'
                            }}
                          >
                            $
                            {data?.discount_id
                              ? formatNumber(Number(data?.total_amount + data?.total_gst_amount) -
                              Number(data?.discount_amount ? data?.discount_amount : 0))
                              : data?.total_net_amount
                              ? formatNumber(Number(data?.total_net_amount) -
                              Number(data?.discount_amount ? data?.discount_amount : 0))
                              : '0.00'}
                          </td>
                          {/* <td
                            style={{
                              padding: '8px',
                              fontWeight: 'bold',
                              borderBottom: '1px solid black',
                              textAlign: 'right'
                            }}
                          >
                            $
                            {data?.total_amount
                              ? data?.total_gst_amount
                                ? (data?.total_gst_amount + data?.total_amount).toFixed(2)
                                : data?.total_amount.toFixed(2)
                              : '0.00'}
                          </td> */}
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            Gross Amount
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              textAlign: 'right',
                              fontWeight: 'bold'
                            }}
                          >
                            $
                            {data?.total_amount
                              ? data?.total_gst_amount
                                ? formatNumber(Number(data?.total_gst_amount + data?.total_amount))
                                : formatNumber(Number(data?.total_amount))
                              : '0.00'}
                          </td>
                        </tr>
                        {data?.discount_amount ? (
                          <tr>
                            <td
                              style={{
                                padding: '8px',
                                borderRight: '1px solid black',
                                borderBottom: '1px solid black',
                                fontWeight: 'bold'
                              }}
                              colSpan={5}
                            >
                              Discount
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                fontWeight: 'bold',
                                borderBottom: '1px solid black',
                                textAlign: 'right'
                              }}
                            >
                              $
                              {data?.discount_amount
                                ? formatNumber(Number(data?.discount_amount))
                                : '0.00'}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td
                            style={{
                              padding: '8px',
                              height: '37px'
                            }}
                            colSpan={6}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontWeight: 'bold'
                              }}
                            >
                              {data?.template_setting?.settings?.sub_total_option?.term ? (
                                <p style={{ margin: '0px' }}>
                                  <span>
                                    Terms:{' '}
                                    {terms
                                      ? `${
                                          String(data?.date)?.includes('-')
                                            ? terms.toFixed(0) < 0
                                              ? 0
                                              : terms.toFixed(0)
                                            : terms.toFixed(0) < 0
                                            ? 0
                                            : terms.toFixed(0)
                                        } days`
                                      : ''}
                                  </span>
                                </p>
                              ) : (
                                ''
                              )}
                              {data?.template_setting?.settings?.sub_total_option?.expiryDate ? (
                                <p style={{ margin: '0px' }}>
                                  <span>Expiry Date: {data?.due_date}</span>
                                </p>
                              ) : (
                                ''
                              )}
                              {data?.template_setting?.settings?.quote_detail_option?.amount ? (
                                <p style={{ margin: '0px' }}>
                                  <span>
                                    Amount Outstanding: $
                                    {data?.total_payable_amount
                                      ? formatNumber(Number(data?.total_payable_amount))
                                      : '0.00'}
                                  </span>
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontStyle: 'italic',
                              textAlign: 'center'
                            }}
                          >
                            {/* <p style={{ margin: '20px 0px 10px 0px' }}>
                        Please detach the portion below and forward with your payment
                      </p> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                  <button type="button" className="btn btn-primary invoice-btn" onClick={printFile}>
                    PRINT
                  </button>
                </div>
              </>
            ) : (
              <>
                <div id="print-invoice">
                  <div
                    className="boxed-invoice"
                    style={{ fontSize: '12px', fontFamily: 'Poppins' }}
                  >
                    <table
                      style={{
                        width: '100%',
                        fontFamily: 'Poppins',
                        padding: '15px',
                        fontSize: '12px'
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <table
                              style={{
                                width: '100%',
                                fontFamily: 'Poppins',
                                fontSize: '12px'
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td colSpan={3} style={{ textAlign: 'right' }}>
                                    {BusinessLogo && (
                                      <img
                                        src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                        alt=""
                                        style={{
                                          maxWidth: '95px',
                                          maxHeight: '80px',
                                          marginRight: '5px'
                                        }}
                                      />
                                    )}{' '}
                                    {BusinessName}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: '30%' }}>
                                    <h2 style={{ fontSize: '20px', paddingBottom: '12px' }}>
                                      QUOTE
                                    </h2>
                                    {data?.template_setting?.settings?.client_option
                                      ?.non_individual ? (
                                      <div>
                                        {data?.template_setting?.settings?.client_option
                                          ?.alternateName ? (
                                          data?.contact && data?.contact?.display_name ? (
                                            data?.contact?.display_name
                                          ) : data?.contact?.first_name ? (
                                            <p style={{ margin: '0px' }}>
                                              {data?.contact?.first_name +
                                                ' ' +
                                                data?.contact?.last_name}
                                            </p>
                                          ) : (
                                            <p style={{ margin: '0px' }}>
                                              {data?.contact?.last_name}
                                            </p>
                                          )
                                        ) : data?.contact?.business_name ? (
                                          data?.contact?.business_name
                                        ) : data?.contact?.first_name ? (
                                          <p style={{ margin: '0px' }}>
                                            {data?.contact?.first_name +
                                              ' ' +
                                              data?.contact?.last_name}
                                          </p>
                                        ) : (
                                          <p style={{ margin: '0px' }}>
                                            {data?.contact?.last_name}
                                          </p>
                                        )}
                                        <>
                                          {data?.contact && (
                                            <>
                                              <p style={{ margin: '0px' }}>
                                                {data?.contact?.shipping_street
                                                  ? data?.contact?.shipping_street
                                                  : data?.contact?.street
                                                  ? data?.contact?.street
                                                  : ''}
                                              </p>
                                              <p style={{ margin: '0px' }}>
                                                {data?.contact?.shipping_suburb
                                                  ? data?.contact?.shipping_suburb?.name
                                                  : data?.contact?.suburb
                                                  ? data?.contact?.suburb?.name
                                                  : ''}
                                                {(data?.contact?.suburb ||
                                                  data?.contact?.shipping_suburb) && <br />}
                                                {data?.contact?.shipping_state
                                                  ? data?.contact?.shipping_state?.short_name
                                                    ? data?.contact?.shipping_state?.short_name
                                                    : data?.contact?.shipping_state?.name
                                                  : data?.contact?.state
                                                  ? data?.contact?.state?.short_name
                                                    ? data?.contact?.state?.short_name
                                                    : data?.contact?.state?.name
                                                  : ''}
                                                {(data?.contact?.state ||
                                                  data?.contact?.shipping_state) && <br />}
                                                {data?.contact?.shipping_postal_code
                                                  ? data?.contact?.shipping_postal_code
                                                  : data?.contact?.postal_code
                                                  ? data?.contact?.postal_code
                                                  : ''}
                                              </p>
                                              {/* <p>{data?.contact}</p> */}
                                            </>
                                          )}
                                        </>
                                      </div>
                                    ) : (
                                      <div>
                                        {data?.template_setting?.settings?.client_option
                                          ?.alternateName ? (
                                          data?.contact && data?.contact?.display_name ? (
                                            data?.contact?.display_name
                                          ) : data?.contact?.first_name ? (
                                            <p style={{ margin: '0px' }}>
                                              {data?.contact?.first_name +
                                                ' ' +
                                                data?.contact?.last_name}
                                            </p>
                                          ) : (
                                            <p style={{ margin: '0px' }}>
                                              {data?.contact?.last_name}
                                            </p>
                                          )
                                        ) : data?.contact && data?.contact?.first_name ? (
                                          <p style={{ margin: '0px' }}>
                                            {data?.contact?.first_name +
                                              ' ' +
                                              data?.contact?.last_name}
                                          </p>
                                        ) : (
                                          <p style={{ margin: '0px' }}>
                                            {data?.contact?.last_name}
                                          </p>
                                        )}
                                        <>
                                          {data?.contact && (
                                            <>
                                              <p style={{ margin: '0px' }}>
                                                {data?.contact?.street
                                                  ? data?.contact?.street
                                                  : data?.contact?.shipping_street
                                                  ? data?.contact?.shipping_street
                                                  : ''}
                                              </p>
                                              <p style={{ margin: '0px' }}>
                                                {data?.contact?.suburb
                                                  ? data?.contact?.suburb?.name
                                                  : data?.contact?.shipping_suburb
                                                  ? data?.contact?.shipping_suburb?.name
                                                  : ''}
                                                {(data?.contact?.suburb ||
                                                  data?.contact?.shipping_suburb) && <br />}
                                                {data?.contact?.state
                                                  ? data?.contact?.state?.short_name
                                                    ? data?.contact?.state?.short_name
                                                    : data?.contact?.state?.name
                                                  : data?.contact?.shipping_state
                                                  ? data?.contact?.shipping_state?.name
                                                  : ''}
                                                {(data?.contact?.state ||
                                                  data?.contact?.shipping_state) && <br />}
                                                {data?.contact?.postal_code
                                                  ? data?.contact?.postal_code
                                                  : data?.contact?.shipping_postal_code
                                                  ? data?.contact?.shipping_postal_code
                                                  : ''}
                                              </p>
                                              {/* <p>{data?.contact}</p> */}
                                            </>
                                          )}
                                        </>
                                      </div>
                                    )}
                                  </td>
                                  <td style={{ width: '25%' }}></td>
                                  <td style={{ width: '45%', textAlign: 'right' }}>
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      style={{ width: '100%', fontSize: '12px' }}
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            verticalAlign: 'top',
                                            textAlign: 'left',
                                            paddingRight: '15px'
                                          }}
                                        >
                                          <p>
                                            <strong>Date</strong>
                                            <br />
                                            {data?.date}
                                          </p>
                                          {data?.template_setting?.settings?.sub_total_option
                                            ?.expiryDate ? (
                                            <p>
                                              <strong>Expiry</strong>
                                              <br />
                                              {data?.due_date}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                          <p>
                                            <strong>Quote Number</strong>
                                            <br />
                                            {data?.quote_number}
                                          </p>
                                        </td>
                                        <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                          <div>
                                            {BusinessName}
                                            {data?.office ? (
                                              <>
                                                <br />
                                                {data?.office?.street ? data?.office?.street : ''}
                                                <br />
                                                {data?.office?.suburb
                                                  ? data?.office?.suburb?.name
                                                  : ''}
                                                {data?.office?.suburb && ' '}
                                                {data?.office?.state
                                                  ? data?.office?.state?.short_name
                                                    ? data?.office?.state?.short_name
                                                    : data?.office?.state?.name
                                                  : ''}
                                                {data?.office?.state && ' '}
                                                {data?.office?.postal_code
                                                  ? data?.office?.postal_code
                                                  : ''}
                                              </>
                                            ) : (
                                              <>
                                                <br />
                                                {businessData?.street ? businessData?.street : ''}
                                                <br />
                                                {businessData?.suburb
                                                  ? businessData?.suburb?.name
                                                  : ''}
                                                {businessData?.suburb && ' '}
                                                {businessData?.state
                                                  ? businessData?.state?.short_name
                                                    ? businessData?.state?.short_name
                                                    : businessData?.state?.name
                                                  : ''}
                                                {businessData?.state && ' '}
                                                {businessData?.postal_code
                                                  ? businessData?.postal_code
                                                  : ''}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style={{
                                width: '100%',
                                fontFamily: 'Poppins',
                                marginTop: '55px',
                                fontSize: '12px'
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'left',
                                            width: '43%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'left',
                                            width: '43%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color
                                          }
                                    }
                                  >
                                    Description
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '5%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '5%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color
                                          }
                                    }
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color
                                          }
                                    }
                                  >
                                    Unit Price
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color
                                          }
                                    }
                                  >
                                    GST
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color
                                          }
                                    }
                                  >
                                    Discount
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            textAlign: 'right',
                                            width: '13%',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color
                                          }
                                    }
                                  >
                                    Amount AUD
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.quote_items.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          padding: '5px',
                                          textAlign: 'left',
                                          borderBottom: '1px solid #ced4da'
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: item?.description
                                          }}
                                        ></span>
                                      </td>
                                      <td
                                        style={{
                                          padding: '5px',
                                          borderBottom: '1px solid #ced4da',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.quantity}
                                      </td>
                                      <td
                                        style={{
                                          padding: '5px',
                                          borderBottom: '1px solid #ced4da',
                                          textAlign: 'right'
                                        }}
                                      >
                                        $
                                        {item?.unit_price
                                          ? formatNumber(Number(item?.unit_price))
                                          : '0.00'}
                                      </td>
                                      <td
                                        style={{
                                          padding: '5px',
                                          borderBottom: '1px solid #ced4da',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {item?.gst === 1
                                          ? 'GST 10%'
                                          : item?.gst === 2
                                          ? 'BAS Excluded'
                                          : item?.gst === 3
                                          ? 'GST on Imports'
                                          : 'GST Excluded'}
                                      </td>
                                      <td
                                        style={{
                                          padding: '5px',
                                          borderBottom: '1px solid #ced4da',
                                          textAlign: 'right'
                                        }}
                                      >
                                        ${formatNumber(Number(item?.discount_amount))}
                                      </td>
                                      <td
                                        style={{
                                          padding: '5px',
                                          borderBottom: '1px solid #ced4da',
                                          textAlign: 'right'
                                        }}
                                      >
                                        $
                                        {item?.amount ? formatNumber(Number(item?.amount)) : '0.00'}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>Subtotal</td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    $
                                    {data?.total_amount
                                      ? formatNumber(Number(data?.total_amount))
                                      : '0.00'}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    TOTAL GST 10%
                                  </td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    $
                                    {data?.total_gst_amount
                                      ? formatNumber(Number(data?.total_gst_amount))
                                      : '0.00'}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    TOTAL Amount
                                  </td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    $
                                    {data?.total_amount
                                      ? data?.total_gst_amount
                                        ? formatNumber(
                                            Number(data?.total_gst_amount + data?.total_amount)
                                          )
                                        : formatNumber(Number(data?.total_amount))
                                      : '0.00'}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    TOTAL Discount
                                  </td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}>
                                    $
                                    {data?.discount_amount
                                      ? formatNumber(Number(data?.discount_amount))
                                      : '0.00'}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderTop: '1px solid #000',
                                      textAlign: 'right',
                                      fontWeight: 'bold',
                                      borderBottom: '1px solid #000'
                                    }}
                                  >
                                    TOTAL AUD
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderTop: '1px solid #000',
                                      textAlign: 'right',
                                      fontWeight: 'bold',
                                      borderBottom: '1px solid #000'
                                    }}
                                  >
                                    $
                                    {data?.total_payable_amount
                                      ? formatNumber(Number(data?.total_payable_amount))
                                      : '0.00'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                  <button type="button" className="btn btn-primary invoice-btn" onClick={printFile}>
                    PRINT
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewQuote;
