import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EditBusiness from "../EditBusiness";
// import BusinessDelete from "./BusinessDelete";
import BusinessDetailPopup from "./BusinessDetailPopup";
import { Button } from "react-bootstrap";

const BusinessActionButton = ({
  id,
  handleDelete,
  clientId,
  businessModal,
  setBusinessList,
}) => {
  // const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleBusinessModal = () => {
    showBusiness && businessModal();

    setShowBusiness(!showBusiness);
  };

  // const toggleDeleteModal = () => {
  //   setShowDelete(!showDelete);
  // };
  return (
    <>
      <span>
        <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#businessDetail"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
          onClick={toggleBusinessModal}
          data-toggle="modal"
          data-target="#business"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <Button
        className="launch-payroll"
        onClick={() => {
          window.open("https://keypay.yourpayroll.com.au", "_blank");
        }}
        >
          Launch Payroll
        </Button>
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteBusiness"
        /> */}
      </span>

      {/* {showDelete && (
        <BusinessDelete
          businessId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )} */}

      {showView && (
        <BusinessDetailPopup
          businessId={id}
          toggleShowModal={toggleShowModal}
          showView={showView}
        />
      )}

      {showBusiness && (
        <EditBusiness
          businessId={id}
          showBusiness={showBusiness}
          clientId={clientId}
          toggleBusinessModal={toggleBusinessModal}
          setBusinessList={setBusinessList}
        />
      )}
    </>
  );
};

export default BusinessActionButton;
