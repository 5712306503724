import { Button, Modal } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
// import BasiqLogo from "../../../../assets/images/basiq_logo.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { faEdit, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // disconnectProviderBank,
  // createUserBasiq,
  // disconnectBasiq,
  // getBusinessDetail,
  getProviderBankDetail,
  // getUserBasiq,
  getYodleeAccessToken
} from './components/IntegrationsHelper';
// import { BasiqConnectModal } from "./components/BasiqConnectModal";
// import { toast } from "react-toastify";
import Spin from '../../../common/Spin';
import { YodleeConnectModal } from './components/YodleeConnetctModal';
import {
  yodleeFastLink,
  yodleeConfigName,
  yodleeDefaultImg,
  fileUpload,
  API
} from '../../../../config';
// import { toast } from "react-toastify";
import DisconnectBank from './components/DisconnectBankModal';
import { toast } from 'react-toastify';
import { getClientDetail } from '../../../Settings/clients/components/ClientHelper';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import Salesforce from '../../../../assets/images/salesforce-logo.png';
import Airwallex from '../../../../assets/images/airwallex_square.png'

const Integrations = ({ clientID }) => {
  // const [isBasiqConnect, setIsBasiqConnect] = useState(false);
  // const [isBasiqLoading, setIsBasiqLoading] = useState(false);
  // const [basiqUser, setBasiqUser] = useState({});
  // const [isOpenBasiqModal, setIsOpenBasiqModal] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;
  const role = localStorage.getItem('sme-role');

  const [openYodlee, setOpenYodlee] = useState(false);
  const [openYodleeLoading, setOpenYodleeLoading] = useState(false);
  const [fastLinkData, setFastLinkData] = useState({});
  const [isYodleeConnect, setIsYodleeConnect] = useState(false);
  // const isConnected = integrationData?.connection_status;

  const [showRequest, setShowRequest] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const navigate = useNavigate();

  const toggleRequestModal = () => {
    setShowRequest(!showRequest);
  };

  // Fetching of Client Details
  const fetchClientData = useCallback(async (clientId) => {
    try {
      const clientDetailData = await getClientDetail(clientId);
      setClientEmail(clientDetailData?.email);
    } catch (e) {
      const { data } = e;
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, []);

  useEffect(() => {
    role !== 'Client' && role !== 'Admin' && clientID && fetchClientData(clientID);
    // eslint-disable-next-line
  }, [fetchClientData, clientID]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       setIsBasiqLoading(true);
  //       const data = await getBusinessDetail(businessId);
  //       setIsBasiqConnect(data?.is_basiq_connected);
  //       setIsBasiqLoading(false);
  //     } catch (e) {
  //       setIsBasiqConnect(null);
  //     }
  //   }
  //   navigate("?tab=integrations");
  //   fetchData();
  // }, [businessId, navigate]);

  // const handleBasiqConnection = async () => {
  //   if (isBasiqConnect) {
  //     setIsBasiqLoading(true);
  //     setIsOpenBasiqModal(false);
  //     const disconnect = await disconnectBasiq();
  //     if (disconnect.status === 200) {
  //       toast.success(disconnect.message);
  //     }
  //     setIsBasiqLoading(false);
  //     setIsBasiqConnect(false);
  //   } else {
  //     try {
  //       setIsBasiqLoading(true);
  //       const userDetails = await getUserBasiq(businessId);
  //       if (userDetails?.id) {
  //         setBasiqUser({
  //           userId: userDetails?.id,
  //           token: userDetails?.access_token,
  //         });
  //       } else {
  //         const createdUser = await createUserBasiq(businessId);
  //         setBasiqUser({
  //           userId: createdUser?.id,
  //           token: createdUser?.access_token,
  //         });
  //       }
  //       setIsBasiqLoading(false);
  //       setIsOpenBasiqModal(true);
  //     } catch (error) {
  //       setBasiqUser({});
  //       setIsBasiqLoading(false);
  //     }
  //   }
  // };

  // const handleStripemodalclose = () => {
  //   setIsOpenBasiqModal(false);
  // };

  const config = {
    params: { configName: '' }
    // onSuccess: function (data) {
    //   console.log("onSuccess:");
    //   console.log(data);
    // },
    // onError: function (data) {
    //   console.log("onError:");
    //   console.log(data);
    // },
    // onClose: function (data) {
    //   console.log("onClose:");
    //   console.log(data);
    // },
    // onEvent: function (data) {
    //   console.log("onEvent:");
    //   console.log(data);
    // },
  };

  const handleYodleeControle = async () => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(businessId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params.configName = yodleeConfigName;
      setOpenYodleeLoading(false);
      setOpenYodlee(true);
      setFastLinkData(config);
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  const handleYodleeEditControl = async (providerID) => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(businessId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: 'edit',
        providerAccountId: providerID,
        configName: yodleeConfigName
      };
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeRefreshControl = async (providerID) => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(businessId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: 'refresh',
        providerAccountId: providerID,
        configName: yodleeConfigName
      };
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  //Providers(Bank)
  const [providerBankLoading, setLoading] = useState(false);
  const [providerBankData, setProviderBankData] = useState([]);
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       setLoading(true);
  //       const data = await getProviderBankDetail(businessId);
  //       setProviderBankData(data?.data);
  //       setLoading(false);
  //     } catch (e) {
  //       console.log(e);
  //       setLoading(false);
  //     }
  //   }
  //   navigate("?tab=integrations");
  //   fetchData();
  // }, [businessId, navigate]);

  const [disconnectModal, setDisconnectModal] = useState(false);
  const [providerId, setProviderId] = useState();

  const toggleDisconnectModal = () => {
    setDisconnectModal(!disconnectModal);
  };

  const getProviderBankData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getProviderBankDetail(businessId);
      setProviderBankData(data?.data);
      const yodleeStatus = data?.data[0]?.providerAccountId;
      setIsYodleeConnect(yodleeStatus);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    navigate('?tab=integrations');
  }, [businessId, navigate]);

  const handleProviderBank = () => {
    getProviderBankData();
    setDisconnectModal(false);
  };

  const handleClientConnectRequest = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('client_business_id', businessId);

      const response = await fileUpload(`${API.REQUEST_CLIENT_TO_CONNECT}`, formData);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        getProviderBankData();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    getProviderBankData();
  }, [getProviderBankData]);

  // const handleBankDisconnect = async (providerId) => {
  //   if (providerId) {
  //     setLoading(true);
  //     const disconnect = await disconnectProviderBank(businessId, providerId);
  //     setLoading(false);
  //     if (disconnect.status === 200) {
  //       toast.success(disconnect?.message);
  //       getProviderBankData();
  //     }
  //   }
  // };

  const updateProviders = async (value, id) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('client_business_id', businessId);
      formData.append('transactionStartDate', value);
      formData.append('_method', 'put');
      const data = await fileUpload(`${API.GET_PROVIDERS_BANK}/${id}`, formData);
      getProviderBankData();
      setLoading(false);
      return data?.data;
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="integration-card">
        {/* <h3 style={{ marginTop: "0" }}>CRM &amp; Other Systems</h3> */}
        {providerBankLoading && <Spin />}
        <h3>Automatic Bank Feeds into SME Cashbook</h3>
        {/* <div className="card">
          <div className="card-body">
            <div className="crm-img">
              <img className="card-img-top" src={BasiqLogo} alt="" />
            </div>
            <Button
              loading={isBasiqLoading && <Spin />}
              onClick={handleBasiqConnection}
            >
              {isBasiqConnect ? "Disconnect" : "Connect"}
            </Button>
          </div>
        </div> */}
        {providerBankData &&
          providerBankData?.length > 0 &&
          providerBankData?.map((item) => {
            return (
              <div className="card" key={item?.id}>
                <div className="card-body">
                  <div className="crm-img">
                    <img className="card-img-top" src={item?.logo} alt="" />
                  </div>
                  {isYodleeConnect ? (
                    <>
                      <div className="mb-2 yodlee card-icons">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="ml-2"
                          onClick={async () => {
                            handleYodleeEditControl(item?.providerAccountId);
                          }}
                        />
                        <Button
                          data-toggle="modal"
                          data-target="#disconnectBank"
                          onClick={() => {
                            if (isYodleeConnect) {
                              setDisconnectModal(true);
                              setProviderId(item?.id);
                            } else {
                              handleYodleeControle();
                            }
                          }}
                        >
                          {isYodleeConnect ? 'Disconnect' : 'Connect'}
                        </Button>
                        <FontAwesomeIcon
                          icon={faRefresh}
                          onClick={() => {
                            handleYodleeRefreshControl(item?.providerAccountId);
                          }}
                        />
                      </div>
                      <div className="yodlee-startTransaction-date">
                        <p>Transaction Start Date</p>
                        <DatePicker
                          placeholder="Select Date"
                          format="dd-MM-yyyy"
                          value={
                            item?.transactionStartDate
                              ? new Date(moment(item.transactionStartDate, 'DD-MM-YYYY'))
                              : ''
                          }
                          onChange={(e) =>
                            updateProviders(moment(e, 'DD-MM-YYYY').format('DD-MM-YYYY'), item?.id)
                          }
                          cleanable={false}
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })}
        <div className="card">
          <div className="card-body">
            <div className="crm-img">
              <img className="card-img-top" src={yodleeDefaultImg} alt="" />
            </div>
            {openYodleeLoading && <Spin />}
            {window.fastlink.close()}
            <Button id="btn-fastlink" onClick={handleYodleeControle}>
              Connect a Bank Account
            </Button>
            {role && role !== 'Client' && role !== 'Admin' && (
              <>
                <span style={{ margin: '10px', display: 'block' }}>OR</span>
                <Button id="btn-fastlink" onClick={toggleRequestModal}>
                  Request a Client to Connect
                </Button>
              </>
            )}
          </div>
        </div>

        <h3>CRM &amp; Other Systems</h3>
        <div className="card">
          <div className="card-body">
            <div className="crm-img">
              <img className="card-img-top" src={Salesforce} alt="" />
            </div>
            <p>Access Practice Manager built on Salesforce</p>
            <Button
              data-toggle="modal"
              data-target="#disconnectBank"
              onClick={() => {
                // handleConnection()
                toast.warn('Funtionality is under development')
              }}
            >
              {/* {isConnected ? 'Disconnect' : 'Connect'} */}
              Connect
            </Button>
          </div>
        </div>

        <h3>Payment Integration</h3>
        <div className="card">
          <div className="card-body">
            <div className="crm-img">
              <img className="card-img-top" src={Airwallex} alt="" />
            </div>
            <p>Airwallex</p>
            <Button
              data-toggle="modal"
              data-target="#disconnectBank"
              onClick={() => {
                // handleConnection()
                toast.warn('Funtionality is under development')
              }}
            >
              {/* {isConnected ? 'Disconnect' : 'Connect'} */}
              Connect
            </Button>
          </div>
        </div>

        <Modal
          size="lg"
          show={openYodlee}
          onHide={handleYodleeModal}
          dialogClassName="modal-50w medium-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Auto Bank Feeds Account Integration
          </Modal.Header>
          <Modal.Body>
            <YodleeConnectModal
              handleYodleeModal={handleYodleeModal}
              fastLinkData={fastLinkData}
              businessId={businessId}
              handleProviderBank={handleProviderBank}
              setOpenYodleeLoading={setLoading}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={showRequest}
          onHide={toggleRequestModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Request a Client to Connect
          </Modal.Header>
          <Modal.Body>
            {providerBankLoading && <Spin />}
            <div className="modal-body">Invitation will be sent to {clientEmail}.</div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleRequestModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => handleClientConnectRequest()}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* <Modal
          size="lg"
          show={isOpenBasiqModal}
          onHide={handleStripemodalclose}
          dialogClassName="modal-50w medium-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Auto Bank Feeds Account Integration
          </Modal.Header>
          <Modal.Body>
            <BasiqConnectModal
              handleStripemodalclose={handleStripemodalclose}
              basiqUser={basiqUser}
            />
          </Modal.Body>
        </Modal> */}

        {disconnectModal && (
          <DisconnectBank
            businessId={businessId}
            providerId={providerId}
            toggleDisconnectModal={toggleDisconnectModal}
            handleDisconnect={handleProviderBank}
            setProviderBankLoading={setLoading}
            disconnectModal={disconnectModal}
          />
        )}
      </div>
    </>
  );
};

export default Integrations;
