import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, FormControl } from "react-bootstrap";
import Spin from "../../common/Spin";
import Pagination from "../../common/Pagination";
import { debounce } from "lodash";
import { API, get, metaTitle } from "../../../config";
import { toast } from "react-toastify";
import TaskActionButton from "./components/TaskActionButton";
import AddTask from "./AddTask";
import TitleBar from "../../common/TitleBar";

const Tasks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const businessId = params?.business_id;
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [tasksList, setTasksList] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [showAddTask, setShowAddTask] = useState(false);

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );

  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleDelete = () => {
    fetchTasks();
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const columns = [
    {
      text: "Code",
      dataField: "code",
      sort: true,
    },
    {
      text: "Description",
      dataField: "description",
      width: "50%",
      sort: true,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const fetchTasks = useCallback(async () => {
    try {
      setTasksLoading(true);
      const { data } = await get(
        `${API.GET_TASKS}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }&search=${searchParam ? searchParam : ""}&client_business_id=${
          businessId ? businessId : ""
        }`
      );

      setTasksLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const tasksList = data.data.data;
      setTasksList(tasksList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setTasksLoading(false);
      setTasksList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, searchParam, sortField, sortOrder, businessId]);

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    fetchTasks();
    window.document.title = `${metaTitle} | Tasks`
  }, [fetchTasks]);

  const suppliersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: tasksList[i]?.id,
        id: tasksList[i]?.id ? tasksList[i]?.id : "",
        code: tasksList[i]?.code,
        description: tasksList[i]?.description,
        status: tasksList[i]?.status,
        action: (
          <TaskActionButton
            id={tasksList[i]?.id}
            handleDelete={handleDelete}
            fetchTasks={fetchTasks}
          />
        ),
      });
    }
    return items;
  };

  const tasks = suppliersGenerator(tasksList?.length);

  const toggleShowModal = () => {
    setShowAddTask(!showAddTask);
  };

  useEffect(() => {
    navigate(
      `?tab=job-task&childTab=task-settings&limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={"Tasks/Milestones"}
            />
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Task"
                    />
                    {searchParamData 
                    ? <Button className="btn-close" onClick={() => {
                      setSearchParamData("")
                      setSearchParam("")
                    }}
                    >x</Button> 
                    : <Button className="fa fa-search">Search</Button>}
                  </Form>
                </div>
                <div className="table-btn">
                  <Button variant="primary" onClick={toggleShowModal}>
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {tasksLoading && <Spin />}

                <BootstrapTable
                  keyField="id"
                  remote
                  data={tasks}
                  columns={columns}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />

                {tasks.length !== 0 ? (
                  <Pagination
                    total={total}
                    limit={parseInt(limit)}
                    currentPage={page}
                    updateLimit={handleUpdateLimit}
                    updatePage={handleChangePage}
                    from={from}
                    to={to}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddTask && (
        <AddTask
          businessId={businessId}
          toggleShowModal={toggleShowModal}
          fetchTasks={fetchTasks}
          showAddTask={showAddTask}
        />
      )}
    </>
  );
};

export default Tasks;
