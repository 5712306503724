import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, fileUpload, get, imageDefaultPrefixPath, metaTitle } from '../../config';
import { checkIfFileExists, formatName, pastFinancialdate, removeDuplicates } from '../common/Misc';
import Spin from '../common/Spin';
import Select from 'react-select';
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { MonthRangePicker } from '@semcore/date-picker';
import { Button } from 'react-bootstrap';
import InviteAccountingFirmModal from './ClientRole/components/InviteAccountingFirmModal';
import { getBusinessDetail } from '../Settings/Business/components/BusinessHelper';
import { Fragment } from 'react';
import { useRef } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import TitleBar from '../common/TitleBar';

const today = new Date();

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(new Date(today.getFullYear(), today.getMonth() + i, 1));
}

const defaultPiChartData = [{ value: 100, name: 'No Data', total: 0 }];

const BusinessDashBoard = () => {
  const [loading, setLoading] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [contactData, setContactData] = useState({});
  const [contractorData, setContractorData] = useState({});
  const [supplierData, setSupplierData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [clientLoading, setClientLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [valueRange, setValueRange] = useState(pastFinancialdate);

  const [accountingFirmId, setAccountingFirmId] = useState();

  const params = useParams();
  const businessID = params?.business_id;
  const [businessId, setBusinessId] = useState(businessID);
  const [clientValue, setClientValue] = useState();
  const [businessValue, setBusinessValue] = useState();
  const navigate = useNavigate();
  const role = localStorage.getItem('sme-role');
  const businessName = localStorage.getItem('sme-businessName');
  const BusinessLogo = localStorage.getItem('sme-businessLogo');
  // const [BusinessName, setBusinessName] = useState(businessName);
  const [businessLogo, setBusinessLogo] = useState(BusinessLogo);

  const [expRevChartData, setExpRevChartData] = useState([]);
  const [expChartData, setExpChartData] = useState([]);
  const [revChartData, setRevChartData] = useState([]);

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedMainJobs, setSelectedMainJobs] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedMainTasks, setSelectedMainTasks] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [accountId, setAccountId] = useState('');
  const [accountOptions, setAccountOptions] = useState([]);

  const filterJobInputRef = useRef();
  const filterTaskInputRef = useRef();

  // useEffect(() => {
  //   !businessName &&
  //     getBusinessData(businessId, setBusinessName, setBusinessLogo);
  // }, [businessName, businessId]);

  const fetchBusinessData = useCallback(async () => {
    try {
      const data = await getBusinessDetail(businessId);
      setAccountingFirmId(data?.accounting_firm_id ? data?.accounting_firm_id : '');
    } catch (e) {
      setAccountingFirmId(null);
    }
  }, [businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const fetchClients = useCallback(async () => {
    try {
      setClientLoading(true);
      const { data } = await get(`${API.GET_CLIENTS}?limit=-1`);
      setClientLoading(false);
      const clientListing =
        data &&
        data?.data?.data?.length > 0 &&
        data?.data?.data?.map((item) => {
          const list = {
            label: formatName(item?.first_name, item?.last_name),
            value: item?.client?.id
          };
          return list;
        });
      setClientsList(clientListing ? clientListing : []);
      return data.data;
    } catch (e) {
      const { data } = e;
      setClientLoading(false);
      setClientsList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, []);

  const fetchDashboardDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_BUSINESS_DASHBOARD}?client_business_id=${businessId}`);
      const businessListing =
        data &&
        data?.data['businesses']?.length > 0 &&
        data?.data['businesses']?.map((item) => {
          const list = {
            label: item?.business_name,
            value: item?.id,
            logo: item?.logo
          };
          return list;
        });
      const businessName = businessListing?.find((item) => item?.value === businessId);

      localStorage.setItem('sme-businessLogo', businessName?.logo ? businessName?.logo : '');
      localStorage.setItem('sme-businessName', businessName?.label);
      setBusinessData(businessListing ? businessListing : []);
      setContactData(data?.data['contacts']);
      setContractorData(data?.data['contractors']);
      setSupplierData(data?.data['suppliers']);
      setCustomerData(data?.data['customers']);
      setClientValue(data?.data['client']?.client?.id);
      setLoading(false);
    } catch (e) {
      const { data } = e;
      setLoading(false);
      setBusinessData([]);
      setContactData({});
      setContractorData({});
      setSupplierData({});
      setCustomerData({});
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [businessId]);

  const handleClientChange = useCallback(async (id) => {
    setClientValue(id);
    try {
      setBusinessLoading(true);
      const { data } = await get(`${API.GET_BUSINESS}?limit=-1&client_id=${id}`);
      setBusinessLoading(false);
      const businessListing =
        data &&
        data?.data?.data?.length > 0 &&
        data?.data?.data?.map((item) => {
          const list = { label: item?.business_name, value: item?.id };
          return list;
        });
      setBusinessList(businessListing ? businessListing : []);
      return data.data;
    } catch (e) {
      const { data } = e;
      setBusinessLoading(false);
      setBusinessList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, []);

  const handleJobList = useCallback(async () => {
    try {
      setLoading(true);
      get(
        `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const jobList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id
            };
            return list;
          });
        setJobOptions(
          jobList
            ? [...jobList, { label: 'Unassigned', value: '' }]
            : [{ label: 'Unassigned', value: '' }]
        );
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }, [businessId]);

  useEffect(() => {
    handleJobList();
  }, [handleJobList]);

  const handleTaskList = useCallback(async () => {
    try {
      setLoading(true);
      get(
        `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        const taskList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: item?.code + (item?.description ? ' (' + item?.description + ')' : ''),
              value: item?.id
            };
            return list;
          });
        setTaskOptions(
          taskList
            ? [...taskList, { label: 'Unassigned', value: '' }]
            : [{ label: 'Unassigned', value: '' }]
        );
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }, [businessId]);

  useEffect(() => {
    handleTaskList();
  }, [handleTaskList]);

  const handleJobChange = (e) => {
    setSelectedJobs(e.value);
  };

  const handleTaskChange = (e) => {
    setSelectedTasks(e.value);
  };

  const handleApplyFilter = () => {
    setSelectedMainJobs(selectedJobs);
    setSelectedMainTasks(selectedTasks);
    filterJobInputRef.current.hide();
    filterTaskInputRef.current.hide();
  };

  const myJobResetFunction = () => {
    setSelectedJobs([]);
    setSelectedMainJobs([]);
    filterJobInputRef && filterJobInputRef.current.focus();
  };

  const myTaskResetFunction = () => {
    setSelectedTasks([]);
    setSelectedMainTasks([]);
    filterTaskInputRef && filterTaskInputRef.current.focus();
  };

  const fetchBankReconcileData = useCallback(async () => {
    const formData = new FormData();
    if (selectedMainJobs?.length > 0) {
      for (let i = 0; i < selectedMainJobs?.length; i++) {
        if (
          selectedMainJobs[i] !== null &&
          selectedMainJobs[i] !== 'null' &&
          selectedMainJobs[i] !== ''
        ) {
          formData.append(`job_ids[${i}]`, selectedMainJobs[i]);
        } else {
          formData.append(`job_ids[${i}]`, '');
          formData.append(`job_ids[${i + 1}]`, null);
        }
      }
    }
    if (selectedMainTasks?.length > 0) {
      for (let j = 0; j < selectedMainTasks?.length; j++) {
        if (
          selectedMainTasks[j] !== null &&
          selectedMainTasks[j] !== 'null' &&
          selectedMainTasks[j] !== ''
        ) {
          formData.append(`task_ids[${j}]`, selectedMainTasks[j]);
        } else {
          formData.append(`task_ids[${j}]`, '');
          formData.append(`task_ids[${j + 1}]`, null);
        }
      }
    }
    formData.append('client_business_id', businessId ? businessId : '');
    formData.append(
      'from_month',
      moment(valueRange[0]).format('MM-YYYY') ? moment(valueRange[0]).format('MM-YYYY') : ''
    );
    formData.append(
      'to_month',
      moment(valueRange[1]).format('MM-YYYY') ? moment(valueRange[1]).format('MM-YYYY') : ''
    );

    if (businessId) {
      try {
        setLoading(true);
        const { data } = await fileUpload(`${API.GET_BANK_CONCILEDATA}`, formData);
        setLoading(false);

        const expRevData = data?.data?.exRevChart ? data?.data?.exRevChart : [];
        setExpRevChartData(expRevData);

        const expData =
          data?.data?.expendituresChart && data?.data?.expendituresChart?.length > 0
            ? data?.data?.expendituresChart?.map((item) => {
                const { percentage: value, ...rest } = item;
                return { value, ...rest };
              })
            : [];

        if (expData) {
          // const finalData = expData?.map((item) => (item?.value === 0 ? 0 : 1));
          // setExpChartData(
          //   removeDuplicates(finalData)?.length === 1 &&
          //     removeDuplicates(finalData)[0] === 0
          //     ? defaultPiChartData
          //     : expData.filter((item) => parseInt(item.value) !== 0)
          // );
          setExpChartData(expData);
        } else {
          setExpChartData(defaultPiChartData);
        }

        // setExpChartData(expData);

        const revData =
          data?.data?.revenueChart && data?.data?.revenueChart?.length > 0
            ? data?.data?.revenueChart?.map((item) => {
                const { percentage: value, ...rest } = item;
                return { value, ...rest };
              })
            : [];

        if (revData) {
          const finalData = revData?.map((item) => (item?.value === 0 ? 0 : 1));
          setRevChartData(
            removeDuplicates(finalData)?.length === 1 && removeDuplicates(finalData)[0] === 0
              ? defaultPiChartData
              : revData.filter((item) => parseInt(item.value) !== 0)
          );
        } else {
          setRevChartData(defaultPiChartData);
        }

        // setRevChartData(revData);

        return data.data;
      } catch (e) {
        setLoading(false);
        const { data } = e;
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    }
    // eslint-disable-next-line
  }, [businessId, valueRange, selectedMainJobs, selectedMainTasks]);

  useEffect(() => {
    setBusinessList(businessData);
  }, [businessData]);

  useEffect(() => {
    navigate(`/business/${businessId}`);
    setBusinessValue(businessId);
  }, [businessId, navigate]);

  useEffect(() => {
    fetchDashboardDetails();
  }, [fetchDashboardDetails]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    fetchBankReconcileData();
  }, [fetchBankReconcileData]);

  useEffect(() => {
    role && (role === 'Client' || role === 'Client User') && fetchBusinessData();
    window.document.title = `${metaTitle} | Business Dashboard`;
  }, [fetchBusinessData, role]);

  const COLORS = [
    '#1F78B4',
    '#1B9BDF',
    '#034262',
    '#C7DAE3',
    '#A6CEE3',
    '#B2DF8A',
    '#d3f7b2',
    '#2b6a8a',
    '#6a9aba',
    '#0f71a3'
  ];

  const CustomTooltipForExp = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc'
          }}
        >
          <label>
            {payload && payload?.length > 0
              ? `${payload[0].name} ${
                  payload[0].name === 'No Data' ? '' : `(${payload[0].value}%)`
                }`
              : ''}
          </label>
          <br />
          <label>
            {payload && payload?.length > 0
              ? `$${payload[0].payload.total.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`
              : ''}
          </label>
        </div>
      );
    }

    return null;
  };

  // const RADIAN = Math.PI / 180;

  // const renderExpCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <>
  //       {percent ? (
  //         <>
  //           <text
  //             x={x}
  //             y={y}
  //             fill="white"
  //             textAnchor={x > cx ? "start" : "end"}
  //             dominantBaseline="central"
  //           >
  //             <tspan>
  //               {expChartData[index]?.value
  //                 ? expChartData[index]?.value + "%"
  //                 : ""}
  //             </tspan>
  //           </text>
  //           {/* <text
  //             x={x}
  //             y={y + 15}
  //             fill="white"
  //             textAnchor={x > cx ? "start" : "end"}
  //             dominantBaseline="central"
  //           >
  //             <tspan>
  //               {expChartData[index]?.total
  //                 ? "$" +
  //                   expChartData[index]?.total.toLocaleString("en-US", {
  //                     minimumFractionDigits: 2,
  //                     maximumFractionDigits: 2,
  //                   })
  //                 : ""}
  //             </tspan>
  //           </text> */}
  //         </>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };

  const CustomExpRevTooltip = ({ active, payload, label }) => {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#ffff',
          padding: '5px',
          border: '1px solid #cccc'
        }}
      >
        <label>{payload && payload?.length > 0 ? `${payload[0]?.payload?.month}` : ''}</label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `Revenue : $${payload[0]?.payload?.Revenue?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            : ''}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `Expenses : $${payload[0]?.payload?.Expenses?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            : ''}
        </label>
      </div>
    );
  };

  const CustomTooltipForRev = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc'
          }}
        >
          <label>
            {payload && payload?.length > 0
              ? `${payload[0].name} ${
                  payload[0].name === 'No Data' ? '' : `(${payload[0].value}%)`
                }`
              : ''}
          </label>
          <br />
          <label>
            {payload && payload?.length > 0
              ? `$${payload[0].payload.total.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`
              : ''}
          </label>
        </div>
      );
    }

    return null;
  };

  // const renderRevCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <>
  //       {percent ? (
  //         <>
  //           <text
  //             x={x}
  //             y={y}
  //             fill="white"
  //             textAnchor={x > cx ? "start" : "end"}
  //             dominantBaseline="central"
  //           >
  //             <tspan>
  //               {revChartData[index]?.value
  //                 ? revChartData[index]?.value + "%"
  //                 : ""}
  //             </tspan>
  //           </text>
  //           {/* <text
  //             x={x}
  //             y={y + 15}
  //             fill="white"
  //             textAnchor={x > cx ? "start" : "end"}
  //             dominantBaseline="central"
  //           >
  //             <tspan>
  //               {revChartData[index]?.total
  //                 ? "$" +
  //                   revChartData[index]?.total.toLocaleString("en-US", {
  //                     minimumFractionDigits: 2,
  //                     maximumFractionDigits: 2,
  //                   })
  //                 : ""}
  //             </tspan>
  //           </text> */}
  //         </>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };

  const totalRev =
    revChartData && revChartData?.length > 0
      ? revChartData?.map((item) => {
          return parseFloat(item?.total);
        })
      : [];
  const totalRevenue =
    totalRev && totalRev?.length > 0 ? totalRev.reduce((partialSum, a) => partialSum + a, 0) : 0;

  const totalExp =
    expChartData && expChartData?.length > 0
      ? expChartData?.map((item) => {
          return parseFloat(item?.total);
        })
      : [];
  const totalExpenditures =
    totalExp && totalExp?.length > 0 ? totalExp.reduce((partialSum, a) => partialSum + a, 0) : 0;
  const [showInviteAccountingFirm, setShowInviteAccountingFirm] = useState(false);

  const handleInviteAccountingFirm = () => {
    setShowInviteAccountingFirm(!showInviteAccountingFirm);
  };

  const monthRangeDisplay =
    moment(valueRange[0]).format('yyyy') === moment(valueRange[1]).format('yyyy')
      ? moment(valueRange[0]).format('MMM') === moment(valueRange[1]).format('MMM')
        ? `${moment(valueRange[0]).format('MMM')}, ${moment(valueRange[0]).format('yyyy')}`
        : `${moment(valueRange[0]).format('MMM')}-${moment(valueRange[1]).format('MMM')}, ${moment(
            valueRange[0]
          ).format('yyyy')}`
      : `${moment(valueRange[0]).format('MMM')} ${moment(valueRange[0]).format('yyyy')} - ${moment(
          valueRange[1]
        ).format('MMM')} ${moment(valueRange[1]).format('yyyy')}`;

  const fetchChartData = useCallback(async () => {
    if (businessId) {
      try {
        setBusinessLoading(true);
        const { data } = await get(
          `${API.BUSINESS_DETAILS}/${businessId}?start_date=${
            valueRange ? moment(valueRange[0]).format('DD-MM-YYYY') : ''
          }
          &end_date=${
            valueRange
              ? moment(
                  new Date(valueRange[1]?.getFullYear(), valueRange[1]?.getMonth() + 1, 0)
                ).format('DD-MM-YYYY')
              : ''
          }${accountId ? '&yodlee_account_id=' + accountId : ''}`
        );
        const accountList =
          data?.yodlee_accounts?.length > 0 &&
          data?.yodlee_accounts?.map((item) => {
            const list = {
              label:
                item?.accountName +
                (item?.accountNumber
                  ? '-' +
                    item?.accountNumber +
                    (item?.accountStatus === 0 ? '(Inactive)' : '(Active)')
                  : ''),
              value: item?.id
            };
            return list;
          });
        setAccountOptions(
          accountList
            ? [{ label: 'ALL', value: '' }, ...accountList]
            : [{ label: 'ALL', value: '' }]
        );
        setBusinessLoading(false);
        setChartData(data?.data?.reconciliation_status);
      } catch (error) {
        const { data } = error.response;
        setBusinessLoading(false);
        setChartData(null);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    }
  }, [businessId, valueRange, accountId]);

  useEffect(() => {
    fetchChartData();
  }, [fetchChartData]);

  const CustomTooltip = ({ active, payload, label }) => {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#ffff',
          padding: '5px',
          border: '1px solid #cccc',
          position: 'relative',
          zIndex: 1
        }}
      >
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0 ? `${payload[0]?.payload?.month}` : ''}
        </label>
        <br />
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0 ? `Reconciled : ${payload[0]?.payload?.reconciled}` : ''}
        </label>
        <br />
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0
            ? `Not Reconciled : ${payload[0]?.payload?.not_reconciled}`
            : ''}
        </label>
        <br />
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0
            ? `Total Transactions : ${payload[0]?.payload?.total_transactions}`
            : ''}
        </label>
      </div>
    );
  };

  let ledgendsReconcilationStatus = [
    { key: 'reconciled', label: 'Reconciled', color: '#79AC78' },
    { key: 'not_reconciled', label: 'Not Reconciled', color: '#C70039' }
  ];

  const renderLegend = (props) => {
    return (
      <ul
        style={{
          // display: 'flex',
          // gap: '5px',
          // justifyContent: 'center',
          // fontSize: '15px',

          padding: '0px',
          margin: '0px',
          textAlign: 'center'
        }}
      >
        {ledgendsReconcilationStatus?.map((item, index) => (
          <li
            style={{
              display: 'inline-block',
              marginRight: '10px'
            }}
            key={index}
            onClick={() => selectBar(props?.payload[index])}
            onMouseEnter={() => handleLegendMouseEnter(props?.payload[index])}
            onMouseOut={() => handleLegendMouseLeave(props?.payload[index])}
            className={props?.payload[index]?.dataKey}
          >
            <FontAwesomeIcon
              icon={faSquare}
              style={{
                color: barProps[item?.key] === true ? '#ccc' : item?.color
              }}
            ></FontAwesomeIcon>{' '}
            {item?.label}
          </li>
        ))}

        {/* <li
          style={{
            display: 'inline-block',
            marginRight: '10px'
          }}
        >
          <FontAwesomeIcon
            icon={faSquare}
            style={{
              color: '#C70039'
            }}
          ></FontAwesomeIcon>{' '}
          Not Reconciled
        </li> */}
      </ul>
    );
  };

  const handleAccountChange = (item) => {
    setChartData([]);
    setAccountId(item?.value);
    fetchChartData();
  };

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  }

  const dateFilterRange = [
    {
      children: 'This Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      ]
    },
    {
      children: 'This Quarter',
      value: quarterRange
    },
    {
      children: 'This Financial Year',
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0)
      ]
    },
    {
      children: 'Last Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
      ]
    },
    {
      children: 'Last Quarter',
      value: perviousQuarterRange
    },
    {
      children: 'Last Financial Year',
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0)
      ]
    }
  ];

  const [barProps, setBarProps] = useState(
    ledgendsReconcilationStatus.reduce(
      (a, { key }) => {
        a[key] = false;
        return a;
      },
      { hover: null }
    )
  );

  const selectBar = (e) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null
    });
  };

  const handleLegendMouseEnter = (e) => {
    if (!barProps[e.dataKey]) {
      setBarProps({ ...barProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeave = (e) => {
    setBarProps({ ...barProps, hover: null });
  };

  const [barExpanseRevProps, setBarExpanseRevProps] = useState(
    expRevChartData.reduce(
      (a, { key }) => {
        a[key] = false;
        return a;
      },
      { hover: null }
    )
  );

  const selectBarExpanseRev = (e) => {
    setBarExpanseRevProps({
      ...barExpanseRevProps,
      [e.dataKey]: !barExpanseRevProps[e.dataKey],
      hover: null
    });
  };

  const handleLegendMouseEnterExpanseRev = (e) => {
    if (!barExpanseRevProps[e.dataKey]) {
      setBarExpanseRevProps({ ...barExpanseRevProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeaveExpanseRev = (e) => {
    setBarExpanseRevProps({ ...barExpanseRevProps, hover: null });
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          {/* <div className="page-title">
            {role && (role === "Client" || role === "Client User") ? (
              <>
                <Select
                  name="business_id"
                  onChange={(e) => {
                    setBusinessId(e?.value);
                    setBusinessValue(e?.value);
                    setSelectedJobs([]);
                    setSelectedTasks([]);
                    setSelectedMainJobs([]);
                    setSelectedMainTasks([]);
                    filterJobInputRef && filterJobInputRef.current.focus();
                    filterTaskInputRef && filterTaskInputRef.current.focus();
                  }}
                  value={businessList?.find(
                    (item) => item?.value === businessValue
                  )}
                  options={businessList}
                  placeholder="Select Business"
                  classNamePrefix="selectbox"
                  isLoading={businessLoading || loading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />{" "}
                <Button
                  className="small headerbtn"
                  onClick={() => {
                    handleInviteAccountingFirm();
                  }}
                  disabled={accountingFirmId ? true : false}
                >
                  Invite Accounting Firm
                </Button>
              </>
            ) : (
              <>
                <Select
                  name="client_id"
                  onChange={(e) => {
                    handleClientChange(e?.value);
                  }}
                  value={clientsList?.find(
                    (item) => item?.value === clientValue
                  )}
                  options={clientsList}
                  placeholder="Select Client"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  isLoading={clientLoading || loading}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <Select
                  name="business_id"
                  onChange={(e) => {
                    setBusinessId(e?.value);
                    setBusinessValue(e?.value);
                    setSelectedJobs([]);
                    setSelectedMainJobs([]);
                    setSelectedTasks([]);
                    setSelectedMainTasks([]);
                    filterJobInputRef && filterJobInputRef.current.focus();
                    filterTaskInputRef && filterTaskInputRef.current.focus();
                  }}
                  value={businessList?.find(
                    (item) => item?.value === businessValue
                  )}
                  options={businessList}
                  placeholder="Select Business"
                  classNamePrefix="selectbox"
                  isLoading={businessLoading || loading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
              </>
            )}
            <div className="page-title-right">
              {businessLogo && (
                <img src={`${imageDefaultPrefixPath}${businessLogo}`} alt="" />
              )}{" "}
              {businessName}
            </div>
          </div> */}
          <TitleBar
            children={
              role && role === 'Client' ? (
                <>
                  <Select
                    name="business_id"
                    onChange={(e) => {
                      setBusinessId(e?.value);
                      setBusinessValue(e?.value);
                      setSelectedJobs([]);
                      setSelectedTasks([]);
                      setSelectedMainJobs([]);
                      setSelectedMainTasks([]);
                      filterJobInputRef && filterJobInputRef.current.focus();
                      filterTaskInputRef && filterTaskInputRef.current.focus();
                    }}
                    value={businessList?.find((item) => item?.value === businessValue)}
                    options={businessList}
                    placeholder="Select Business"
                    classNamePrefix="selectbox"
                    isLoading={businessLoading || loading}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'grey'
                      }
                    })}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />{' '}
                  <Button
                    className="small headerbtn"
                    onClick={() => {
                      handleInviteAccountingFirm();
                    }}
                    disabled={accountingFirmId ? true : false}
                  >
                    Invite Accounting Firm
                  </Button>
                </>
              ) : (
                <>
                  <Select
                    name="client_id"
                    onChange={(e) => {
                      handleClientChange(e?.value);
                    }}
                    value={clientsList?.find((item) => item?.value === clientValue)}
                    options={clientsList}
                    placeholder="Select Client"
                    classNamePrefix="selectbox"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'grey'
                      }
                    })}
                    isLoading={clientLoading || loading}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <Select
                    name="business_id"
                    onChange={(e) => {
                      setBusinessId(e?.value);
                      setBusinessValue(e?.value);
                      setSelectedJobs([]);
                      setSelectedMainJobs([]);
                      setSelectedTasks([]);
                      setSelectedMainTasks([]);
                      filterJobInputRef && filterJobInputRef.current.focus();
                      filterTaskInputRef && filterTaskInputRef.current.focus();
                    }}
                    value={businessList?.find((item) => item?.value === businessValue)}
                    options={businessList}
                    placeholder="Select Business"
                    classNamePrefix="selectbox"
                    isLoading={businessLoading || loading}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'grey'
                      }
                    })}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                </>
              )
            }
            label={''}
            back={false}
            businessNameData={false}
            businessDashBoard={
              <div className="page-title-right">
                {businessLogo && <img src={`${imageDefaultPrefixPath}${businessLogo}`} alt="" />}{' '}
                {businessName}
              </div>
            }
          />
          <div className="dashboard-container">
            {loading && <Spin />}

            <div className="row">
              <div className="card col-md-12 col-12">
                <>
                  <div className="summary-filter">
                    <MonthRangePicker
                      value={valueRange}
                      onChange={(date) => {
                        setValueRange(date);
                      }}
                      unclearable={true}
                      periods={dateFilterRange}
                      className="datefilter"
                      disabled={disabledFutureDate}
                    />
                  </div>
                  <div className="multiSelect-pl-report multiSelect-dashboard">
                    <label>Job: </label>
                    <MultiSelect
                      value={selectedJobs}
                      onChange={(e) => {
                        handleJobChange(e);
                      }}
                      options={jobOptions}
                      optionLabel="label"
                      filter
                      placeholder="Select Job"
                      maxSelectedLabels={0}
                      selectedItemsLabel={`${selectedJobs?.length} Job(s) Selected`}
                      ref={filterJobInputRef}
                      onHide={() => {
                        setSelectedMainJobs(selectedJobs);
                      }}
                      panelFooterTemplate={
                        <>
                          <hr />
                          <Button
                            className="multiSelect-button"
                            onClick={() => {
                              handleApplyFilter();
                            }}
                          >
                            Apply
                          </Button>
                          <Button
                            className="multiSelect-button"
                            onClick={() => {
                              myJobResetFunction();
                            }}
                          >
                            Reset
                          </Button>
                        </>
                      }
                    />
                  </div>
                  <div className="multiSelect-pl-report multiSelect-dashboard">
                    <label>Task: </label>
                    <MultiSelect
                      value={selectedTasks}
                      onChange={(e) => {
                        handleTaskChange(e);
                      }}
                      options={taskOptions}
                      optionLabel="label"
                      filter
                      placeholder="Select Task"
                      maxSelectedLabels={0}
                      ref={filterTaskInputRef}
                      onHide={() => {
                        setSelectedMainTasks(selectedTasks);
                      }}
                      selectedItemsLabel={`${selectedTasks?.length} Task(s) Selected`}
                      panelFooterTemplate={
                        <>
                          <hr />
                          <Button
                            className="multiSelect-button"
                            onClick={() => {
                              handleApplyFilter();
                            }}
                          >
                            Apply
                          </Button>
                          <Button
                            className="multiSelect-button"
                            onClick={() => {
                              myTaskResetFunction();
                            }}
                          >
                            Reset
                          </Button>
                        </>
                      }
                    />
                  </div>
                  <div className="business-status-graph business-status-filter accountSelect-dashboard">
                    <label>Account: </label>
                    <div className="bank-account-dropdown">
                      <Select
                        style={{
                          width: '50%',
                          margin: '0'
                        }}
                        placeholder="Select Account"
                        defaultValue={{ label: 'ALL', value: '' }}
                        onChange={(item) => {
                          handleAccountChange(item);
                        }}
                        loading={businessLoading}
                        classNamePrefix="account_select_graph"
                        options={accountOptions}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: 'grey'
                          }
                        })}
                      />
                    </div>
                  </div>
                </>
              </div>
              <div className="col-md-12 active-cards">
                <div className="row">
                  <div
                    className="card col-md-3"
                    onClick={() => {
                      navigate(`/business/${businessId}/contractors`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Contractors</h6>
                          <h4>{contractorData?.count ? contractorData?.count : '0'}</h4>
                        </div>
                        <p className="active-date">
                          {'Last Added: '}
                          {contractorData?.last_added
                            ? moment(contractorData?.last_added, 'DD-MM-YYYY').format('DD MMM YYYY')
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-3"
                    onClick={() => {
                      navigate(`/business/${businessId}/customers`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Customers</h6>
                          <h4>{customerData?.count ? customerData?.count : '0'}</h4>
                        </div>
                        <p className="active-date">
                          {'Last Added: '}
                          {customerData?.last_added
                            ? moment(customerData?.last_added, 'DD-MM-YYYY').format('DD MMM YYYY')
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-3"
                    onClick={() => {
                      navigate(`/business/${businessId}/suppliers`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Suppliers</h6>
                          <h4>{supplierData?.count ? supplierData?.count : '0'}</h4>
                        </div>
                        <p className="active-date">
                          {'Last Added: '}
                          {supplierData?.last_added
                            ? moment(supplierData?.last_added, 'DD-MM-YYYY').format('DD MMM YYYY')
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-3"
                    onClick={() => {
                      navigate(`/business/${businessId}/all-contacts`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>All Contacts</h6>
                          <h4>{contactData?.count ? contactData?.count : '0'}</h4>
                        </div>
                        <p className="active-date">
                          {'Last Added: '}
                          {contactData?.last_added
                            ? moment(contactData?.last_added, 'DD-MM-YYYY').format('DD MMM YYYY')
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Revenue Vs Expenses</h5>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer width={'100%'} height={280} debounce={50}>
                        <BarChart
                          // width={800}
                          // height={400}
                          data={expRevChartData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 45
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="month"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                            // stroke="#8884d8"
                          />
                          <YAxis />
                          <Tooltip content={<CustomExpRevTooltip />} />
                          <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            wrapperStyle={{
                              top: 250
                            }}
                            onClick={selectBarExpanseRev}
                            onMouseEnter={handleLegendMouseEnterExpanseRev}
                            onMouseOut={handleLegendMouseLeaveExpanseRev}
                          />
                          <Bar
                            dataKey="Revenue"
                            barSize={20}
                            fill="#012F4E"
                            hide={barExpanseRevProps['Revenue'] === true}
                            fillOpacity={Number(
                              barExpanseRevProps.hover === 'Revenue' || !barExpanseRevProps.hover
                                ? 1
                                : 0.6
                            )}
                          />
                          <Bar
                            dataKey="Expenses"
                            barSize={20}
                            fill="#0DCAF0"
                            hide={barExpanseRevProps['Expenses'] === true}
                            fillOpacity={Number(
                              barExpanseRevProps.hover === 'Expenses' || !barExpanseRevProps.hover
                                ? 1
                                : 0.6
                            )}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Bank Reconciliation Status</h5>
                    </div>
                    <div className="business-status-graph">
                      <ResponsiveContainer width={'100%'} height={280} debounce={50}>
                        <BarChart
                          data={chartData}
                          margin={{
                            top: 5,
                            right: 20,
                            left: 20,
                            bottom: 45
                          }}
                          // barGap={180}
                          onClick={(data) => {
                            if (data?.activePayload) {
                              let dateString =
                                data?.activePayload[0]?.payload?.startDate?.split('-');
                              let form = moment(
                                new Date(dateString[2], dateString[1] - 1, 1)
                              ).format('DD-MM-YYYY');
                              if (data && data?.activePayload && data?.activePayload.length > 0) {
                                navigate(
                                  `/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`,
                                  { state: { form: form, accountStatusID: accountId } }
                                );
                                // return history.push(`/${data.activePayload[0].x.y.id}`);
                              }
                            }
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="month"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                            // tickSize={0}
                            // fontSize={10}
                            // stroke="#8884d8"
                          />
                          {/* <YAxis /> */}
                          <Tooltip
                            content={<CustomTooltip />}
                            style={{
                              zIndex: 1
                            }}
                          />
                          <Legend
                            layout="horizontal"
                            wrapperStyle={{
                              top: 250
                            }}
                            content={renderLegend}
                          />
                          {ledgendsReconcilationStatus.map((label, index) => (
                            <Bar
                              key={index}
                              dataKey={label.key}
                              fill={label.color}
                              stackId={'a'}
                              barSize={20}
                              hide={barProps[label.key] === true}
                              fillOpacity={Number(
                                barProps.hover === label.key || !barProps.hover ? 1 : 0.6
                              )}
                            />
                          ))}
                          {/* <Bar dataKey="reconciled" stackId="a" barSize={20} fill="#79AC78" />
                          <Bar dataKey="not_reconciled" stackId="a" barSize={20} fill="#C70039" /> */}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4 active-cards">
                <div className="row">
                  <div
                    className="card col-md-6"
                    onClick={() => {
                      navigate(`/business/${businessId}/contractors`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Contractors</h6>
                          <h4>
                            {contractorData?.count
                              ? contractorData?.count
                              : "0"}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {contractorData?.last_added
                            ? moment(
                                contractorData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    onClick={() => {
                      navigate(`/business/${businessId}/customers`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Customers</h6>
                          <h4>
                            {customerData?.count ? customerData?.count : "0"}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {customerData?.last_added
                            ? moment(
                                customerData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    onClick={() => {
                      navigate(`/business/${businessId}/suppliers`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Suppliers</h6>
                          <h4>
                            {supplierData?.count ? supplierData?.count : "0"}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {supplierData?.last_added
                            ? moment(
                                supplierData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    onClick={() => {
                      navigate(`/business/${businessId}/all-contacts`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>All Contacts</h6>
                          <h4>
                            {contactData?.count ? contactData?.count : "0"}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {contactData?.last_added
                            ? moment(
                                contactData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Revenue</h5>
                      <div style={{ marginLeft: 'auto' }}>
                        <strong>Period : </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer width={'73%'} height={400} debounce={50}>
                        <PieChart>
                          <Pie
                            data={revChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={1}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={({ name }) => (name === 'No Data' ? false : true)}
                            // label={renderRevCustomizedLabel}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                              name
                            }) => {
                              const RADIAN = Math.PI / 180;
                              // eslint-disable-next-line
                              const radius = 25 + innerRadius + (outerRadius - innerRadius);
                              // eslint-disable-next-line
                              const x = cx + radius * Math.cos(-midAngle * RADIAN);
                              // eslint-disable-next-line
                              const y = cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <>
                                  {name === 'No Data' ? (
                                    <></>
                                  ) : (
                                    <text
                                      x={x}
                                      y={y}
                                      key={`cell-${index}`}
                                      fill={'#000000'}
                                      textAnchor={x > cx ? 'start' : 'end'}
                                      dominantBaseline="central"
                                      // style={{f}}
                                    >
                                      {value ? `${value}%` : '0.00'}
                                    </text>
                                  )}
                                </>
                              );
                            }}
                          >
                            {revChartData.map((entry, index) => (
                              <Fragment key={index}>
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              </Fragment>
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForRev />} />
                        </PieChart>
                      </ResponsiveContainer>
                      <ul className="chart-legends">
                        {revChartData &&
                          revChartData?.length > 0 &&
                          revChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length]
                                  }}
                                ></FontAwesomeIcon>{' '}
                                {item?.name}
                              </p>
                            );
                          })}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: '-17px',
                        float: 'left',
                        width: '73%',
                        textAlign: 'center'
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {totalRevenue.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Expenditures</h5>
                      <div style={{ marginLeft: 'auto' }}>
                        <strong>Period : </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer width={'73%'} height={400} debounce={50}>
                        <PieChart>
                          <Pie
                            data={expChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={1}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={({ name }) => (name === 'No Data' ? false : true)}
                            // label={renderExpCustomizedLabel}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                              name
                            }) => {
                              const RADIAN = Math.PI / 180;
                              // eslint-disable-next-line
                              const radius = 25 + innerRadius + (outerRadius - innerRadius);
                              // eslint-disable-next-line
                              const x = cx + radius * Math.cos(-midAngle * RADIAN);
                              // eslint-disable-next-line
                              const y = cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <>
                                  {name === 'No Data' ? (
                                    <></>
                                  ) : (
                                    <text
                                      x={x}
                                      y={y}
                                      key={`cell-${index}`}
                                      fill={'#000000'}
                                      textAnchor={x > cx ? 'start' : 'end'}
                                      dominantBaseline="central"
                                      // style={{f}}
                                    >
                                      {value ? `${value}%` : '0.00'}
                                    </text>
                                  )}
                                </>
                              );
                            }}
                          >
                            {expChartData.map((entry, index) => (
                              <Fragment key={index}>
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              </Fragment>
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForExp />} />
                        </PieChart>
                      </ResponsiveContainer>

                      <ul className="chart-legends">
                        {expChartData &&
                          expChartData?.length > 0 &&
                          expChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length]
                                  }}
                                ></FontAwesomeIcon>{' '}
                                {item?.name}
                              </p>
                            );
                          })}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: '-17px',
                        float: 'left',
                        width: '73%',
                        textAlign: 'center'
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {totalExpenditures.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInviteAccountingFirm && (
        <InviteAccountingFirmModal
          showInviteAccountingFirm={showInviteAccountingFirm}
          handleInviteAccountingFirm={handleInviteAccountingFirm}
        />
      )}
    </div>
  );
};

export default BusinessDashBoard;
