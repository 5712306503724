import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API, fileUpload, get } from '../../../../config';
import BootStrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spin from '../../../common/Spin';
import Pagination from '../../../common/Pagination';
import QuoteLayoutActionButton from './QuoteLayoutActionButton';
import { formatName } from '../../../common/Misc';
// import InvoiceActionButton from "./components/InvoiceActionButton";

const QuoteLayouts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [invoicesList, setInvoicesList] = useState([]);
  const [invoiceLoading, setInvoicesLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;

  const handleDelete = () => {
    fetchQuoteLayouts();
  };

  const fetchQuoteLayouts = useCallback(async () => {
    try {
      setInvoicesLoading(true);
      const { data } = await get(
        `${API.INVOICE_SETTINGS}?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${
          sortField ? sortField : ''
        }&sort_order=${sortOrder ? sortOrder : ''}&client_business_id=${
          businessId ? businessId : ''
        }&type=quote`
      );

      setInvoicesLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const invoicesList = data.data.data;
      setInvoicesList(invoicesList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setInvoicesLoading(false);
      setInvoicesList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, sortOrder, sortField, businessId]);

  const handleDefault = async (e, id) => {
    const formData = new FormData();
    formData.append('is_default', e?.target?.checked ? '1' : '0');
    formData.append('_method', 'put');
    try {
      setInvoicesLoading(true);
      const { data } = await fileUpload(`${API.TEMPLATE_DEFAULT}/${id}`, formData);
      setInvoicesLoading(false);
      fetchQuoteLayouts();
      return data.data;
    } catch (e) {
      const { data } = e;
      setInvoicesLoading(false);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  };

  const invoicesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: invoicesList[i]?.id,
        template_name: invoicesList[i]?.settings?.name ? invoicesList[i]?.settings?.name : '-',
        template_style: parseInt(invoicesList[i]?.settings?.quote_style) === 0 ? 'Boxed' : 'Letter',
        created_date: invoicesList[i]?.created_at
          ? invoicesList[i]?.created_at?.split(" ")?.[0]
          : '-',
        created_by: invoicesList[i]?.created_by
          ? formatName(
              invoicesList[i]?.created_by?.first_name,
              invoicesList[i]?.created_by?.last_name
            )
          : '-',
        is_default: (
          <div className="head-client-check-box-list">
            <input
              type="radio"
              id="is_default"
              name="is_default"
              defaultChecked={invoicesList[i]?.is_default === 1 ? true : false}
              value="1"
              onChange={(e) => handleDefault(e, invoicesList[i]?.id)}
            />
          </div>
        ),
        action: <QuoteLayoutActionButton id={invoicesList[i]?.id} handleDelete={handleDelete} is_system_generated={invoicesList[i]?.is_system_generated}/>
      });
    }
    return items;
  };
  const invoices = invoicesGenerator(invoicesList?.length);

  const columns = [
    {
      dataField: 'template_name',
      text: 'Layout Name'
    },
    {
      dataField: 'template_style',
      text: 'Layout Style'
    },
    {
      dataField: 'created_date',
      text: 'Created Date'
    },
    {
      dataField: 'created_by',
      text: 'Created By'
    },
    {
      dataField: 'is_default',
      text: 'Default',
      style: { width: '127px' }
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      style: { width: '127px' }
    }
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  useEffect(() => {
    navigate(
      `?tab=quote&childTab=layout&limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${
        sortField ? sortField : ''
      }&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  useEffect(() => {
    fetchQuoteLayouts();
  }, [fetchQuoteLayouts]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <div
            className="content-details"
            style={{ padding: '0px', margin: '0px', background: 'none' }}
          >
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate('/business/' + businessId + '/template-settings/quote-template')
                  }
                >
                  Add New Layout
                </Button>
                {/* <Button variant="primary">Import</Button> */}
              </div>
            </div>
            <div className="custom-table">
              {invoiceLoading && <Spin />}
              <BootStrapTable
                keyField="key"
                remote
                data={invoices}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
                // expandRow={expandRow}
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteLayouts;
