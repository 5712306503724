import React, { useState } from 'react';
import { Col, ColorPicker, Divider, Row, theme } from 'antd';
import { generate, green, presetPalettes, red, cyan } from '@ant-design/colors';

export const UseColorPicker = (color, setFieldValue, field) => {
  const [colorHex, setColorHex] = useState('');
  const [formatHex, setFormatHex] = useState('hex');
  const hexString = React.useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );
  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
      label,
      colors
    }));
  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan
  });
  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider
        type="vertical"
        style={{
          height: 'auto'
        }}
      />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );
  const Picker = (
    <ColorPicker
      defaultValue={token.colorPrimary}
      styles={{
        popupOverlayInner: {
          width: 480
        }
      }}
      presets={presets}
      panelRender={customPanelRender}
      format={formatHex}
      value={colorHex ? colorHex : color}
      onChange={(e) => {
        setColorHex(e);
        setFieldValue(`${field}`, typeof e === 'string' ? e : e?.toHexString());
      }}
      onFormatChange={setFormatHex}
      allowClear
    />
  );
  return { Picker, hexString, setColorHex, colorHex };
};
