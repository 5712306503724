import React from "react";
import { useLocation } from "react-router-dom";
import Sitelogo from "../../../../components/Header/Logo";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { yodleeConfigName, yodleeFastLink } from "../../../../config";
import Spin from "../../../common/Spin";
import { getYodleeAccessToken } from "./components/IntegrationsHelper";
import { YodleeModalForConnectBank } from "./components/YodleeModalForConnectBank";
import { faBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TitleBar from "../../../common/TitleBar";

const ConnectBank = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const businessId = query.get("client_business_id");

  const [loading, setLoading] = useState(false);
  const [openYodlee, setOpenYodlee] = useState(false);
  const [fastLinkData, setFastLinkData] = useState(false);

  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  const handleYodleeControle = async (businessId) => {
    const config = {
      params: { configName: "" },
    };
    try {
      setLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(businessId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params.configName = yodleeConfigName;
      setLoading(false);
      setOpenYodlee(true);
      setFastLinkData(config);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="business-section">
        <div className="header-blocks">
          <header>
            <Sitelogo />
            {/* <HeaderNav /> */}
            {/* <UserProfile /> */}
          </header>
          <div className="header-bottom-block"></div>
        </div>

        <div className="cms-page">
          <div className="page-content-block">
            <div className="full-content-block">
              <TitleBar
                label={"Connect Bank"}
              />
              <div className="content-details connect-bank">
                <div class="bank-block">
                  <FontAwesomeIcon icon={faBank} size="5x" />
                  <Button
                    onClick={() => {
                      handleYodleeControle(businessId);
                    }}
                  >
                    Connect Bank
                  </Button>
                </div>
                {loading && <Spin />}
                {window.fastlink.close()}
                <Modal
                  size="lg"
                  show={openYodlee}
                  onHide={handleYodleeModal}
                  dialogClassName="modal-50w medium-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header className="mb-0" closeButton>
                    Auto Bank Feeds Account Integration
                  </Modal.Header>
                  <Modal.Body>
                    <YodleeModalForConnectBank
                      handleYodleeModal={handleYodleeModal}
                      fastLinkData={fastLinkData}
                      businessId={businessId}
                      setOpenYodleeLoading={setLoading}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectBank;
