import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { Button, Form, Modal } from 'react-bootstrap';
import { API, fileUpload } from '../../config';
import { formatNumber } from './Misc';
import _ from 'lodash';
import ContactDetailPopup from '../Business/AllContacts/components/ContactDetailPopup';

const { Option, OptGroup } = Select;

const SearchBar = ({ businessId, startDate, endDate }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [contactOptions, setContactOptions] = useState([]);
  const [transactionOptions, setTransactionOptions] = useState([]);
  const [reconciliationOptions, setReconciliationOptions] = useState([]);
  const [notReconciliationOptions, setNotReconciliationOptions] = useState([]);
  const [underReviewOptions, setUnderReviewOptions] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalReconciliations, setTotalReconciliations] = useState(0);
  const [totalNotReconciliations, setTotalNotReconciliations] = useState(0);
  const [totalUnderReview, setTotalUnderReview] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showView, setShowView] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const [showTransaction, setShowTransaction] = useState(false);

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleShowTransactionModal = () => {
    setShowTransaction(!showTransaction);
  };

  const setInitialOptions = () => {
    setContactOptions([]);
    setTotalContacts(0);
    setTransactionOptions([]);
    setTotalTransactions(0);
    setReconciliationOptions([]);
    setTotalReconciliations(0);
    setNotReconciliationOptions([]);
    setTotalNotReconciliations(0);
    setUnderReviewOptions([]);
    setTotalUnderReview(0);
  };

  const SearchApi = async (value) => {
    try {
      if (!value) {
        setInitialOptions();
        return;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append('client_business_id', businessId);
      formData.append('start_date', startDate ? startDate : '');
      formData.append('end_date', endDate ? endDate : '');
      formData.append('search', value ? value : '');

      const { data } = await fileUpload(`${API.GLOBAL_SEARCH}`, formData);
      if (data?.data) {
        const {
          data: { contacts, bankTransactions, bankReconciliations }
        } = data;
        setContactOptions(contacts?.data ? contacts?.data : []);
        setTotalContacts(Number(contacts?.total ? contacts?.total : 0));
        setTransactionOptions(bankTransactions?.data ? bankTransactions?.data : []);
        setTotalTransactions(Number(bankTransactions?.total ? bankTransactions?.total : 0));
        setReconciliationOptions(
          bankReconciliations?.reconciled ? bankReconciliations?.reconciled : []
        );
        setTotalReconciliations(
          Number(bankReconciliations?.reconciled_total ? bankReconciliations?.reconciled_total : 0)
        );

        setNotReconciliationOptions(
          bankReconciliations?.not_reconcile ? bankReconciliations?.not_reconcile : []
        );
        setTotalNotReconciliations(
          Number(
            bankReconciliations?.not_reconcile_total ? bankReconciliations?.not_reconcile_total : 0
          )
        );

        setUnderReviewOptions(
          bankReconciliations?.under_review_bank_reconcile
            ? bankReconciliations?.under_review_bank_reconcile
            : []
        );
        setTotalUnderReview(
          Number(
            bankReconciliations?.under_review_bank_reconcile_total
              ? bankReconciliations?.under_review_bank_reconcile_total
              : 0
          )
        );
      } else {
        setInitialOptions();
      }
      setIsLoading(false);
    } catch (e) {
      setInitialOptions();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    
    _.debounce((nextValue) => {
      SearchApi(nextValue);
    }, 300),
    [businessId, startDate, endDate]
  );

  const handleSearch = useCallback(
    async (value) => {
      setSearchValue(value);
      if (value) {
        if (value?.length >= 3) {
          debouncedSave(value);
        }
      } else {
        setInitialOptions();
      }
    },
    [debouncedSave]
  );

  const handleChange = (value) => {
    if (value === 'more') {
      localStorage.setItem('selectedMore', 'more');
      // setSearchValue(null);
      setSelectedOption(null);
      setInitialOptions();
      setTimeout(() => {
        navigate(`/business/${businessId}/all-contacts?page=1&search=${searchValue}`, {
          replace: true,
          state: { path: location.path }
        });
      }, 500);
    } else if (value === 'more-transactions') {
      localStorage.setItem('selectedMore', 'more-transactions');
      // setSearchValue(null);
      setSelectedOption(null);
      setInitialOptions();
      setTimeout(() => {
        navigate(
          `/business/${businessId}/reconcile-transactions?tab=bank-transactions&page=1&search=${searchValue}`,
          {
            replace: true,
            state: { startDate: startDate, endDate: endDate }
          }
        );
      }, 500);
    } else if (value === 'more-reconcilation') {
      localStorage.setItem('selectedMore', 'more-transactions');
      // setSearchValue(null);
      setSelectedOption(null);
      setInitialOptions();
      setTimeout(() => {
        navigate(
          `/business/${businessId}/reconcile-transactions?tab=reconcile-item&page=1&search=${searchValue}`,
          {
            replace: true,
            state: { startDate: startDate, endDate: endDate }
          }
        );
      }, 500);
    } else if (value === 'more-not-reconcilation') {
      localStorage.setItem('selectedMore', 'more-transactions');
      // setSearchValue(null);
      setSelectedOption(null);
      setInitialOptions();
      setTimeout(() => {
        navigate(
          `/business/${businessId}/reconcile-transactions?tab=reconcile-transactions&page=1&search=${searchValue}`,
          {
            replace: true,
            state: { startDate: startDate, endDate: endDate }
          }
        );
      }, 500);
    } else if (value === 'more-under-review') {
      localStorage.setItem('selectedMore', 'more-transactions');
      // setSearchValue(null);
      setSelectedOption(null);
      setInitialOptions();
      setTimeout(() => {
        navigate(
          `/business/${businessId}/reconcile-transactions?tab=under-review&page=1&search=${searchValue}`,
          {
            replace: true,
            state: { startDate: startDate, endDate: endDate }
          }
        );
      }, 500);
    } else if (typeof value === 'string') {
      localStorage.setItem('selectedId', value.split(' ')[1]);
      if (value.split(' ')[0] === 'contact') {
        // setTimeout(() => {
        //   navigate(`/business/${businessId}/all-contacts?page=1&search=${searchValue}`, {
        //     replace: true,
        //     state: { path: location.pathname }
        //   });
        // }, 500);
        toggleShowModal();
      }

      if (value.split(' ')[0] === 'reconcilation') {
        // setTimeout(() => {
        //   navigate(`/business/${businessId}/reconcile-transactions?tab=reconcile-item`, {
        //     state: { startDate: startDate, endDate: endDate }
        //   });
        // }, 500);
        let data = reconciliationOptions?.find((item) => item.id === value.split(' ')[1]);
        setTransactionData(data);
        toggleShowTransactionModal();
      }

      if (value.split(' ')[0] === 'not-reconcilation') {
        // setTimeout(() => {
        //   navigate(`/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`, {
        //     state: { startDate: startDate, endDate: endDate }
        //   });
        // }, 500);
        let data = notReconciliationOptions?.find((item) => item.id === value.split(' ')[1]);
        setTransactionData(data);
        toggleShowTransactionModal();
      }

      if (value.split(' ')[0] === 'under-review') {
        // setTimeout(() => {
        //   navigate(`/business/${businessId}/reconcile-transactions?tab=under-review`, {
        //     state: { startDate: startDate, endDate: endDate }
        //   });
        // }, 500);
        let data = underReviewOptions?.find((item) => item.id === value.split(' ')[1]);
        setTransactionData(data);
        toggleShowTransactionModal();
      }

      if (value.split(' ')[0] === 'transaction') {
        // setTimeout(() => {
        //   navigate(`/business/${businessId}/reconcile-transactions?tab=bank-transactions`, {
        //     state: { startDate: startDate, endDate: endDate }
        //   });
        // }, 500);
        let data = transactionOptions?.find((item) => item.id === value.split(' ')[1]);
        setTransactionData(data);
        toggleShowTransactionModal();
      }
    }
  };

  const isDataAvailable =
    contactOptions?.length > 0 ||
    transactionOptions?.length > 0 ||
    reconciliationOptions?.length > 0 ||
    notReconciliationOptions?.length > 0 ||
    underReviewOptions?.length > 0;

  return (
    <div className="searchbar">
      <Form>
        <Select
          placeholder="Global Search"
          showSearch
          onSearch={handleSearch}
          searchValue={searchValue}
          style={{ width: '330px' }}
          loading={isLoading}
          showArrow={false}
          filterOption={false}
          onChange={handleChange}
          value={selectedOption}
          dropdownStyle={isDataAvailable ? { maxHeight: '500px' } : {}}
        >
          {isDataAvailable ? (
            <>
              {contactOptions?.length > 0 && (
                <OptGroup label={`Contacts (${totalContacts})`}>
                  {contactOptions.map((contact) => (
                    <Option
                      className="optionlist"
                      key={contact?.id}
                      value={`contact ${contact?.id}`}
                    >
                      {contact?.first_name} {contact?.last_name}
                    </Option>
                  ))}
                  {totalContacts > 5 ? (
                    <Option className="viewmore" key="more" value="more">
                      View More
                    </Option>
                  ) : null}
                </OptGroup>
              )}
              {transactionOptions?.length > 0 && (
                <OptGroup label={`Bank Transaction (${totalTransactions})`}>
                  {transactionOptions.map((item) => (
                    <Option key={item?.id} value={`transaction ${item?.id}`}>
                      <span className="search-desc">{item?.description}</span>
                      <span
                        className={
                          item?.baseType
                            ? 'amount-credit search-source'
                            : 'amount-debit search-source'
                        }
                      >
                        ${formatNumber(Number(item?.amount))}
                      </span>
                    </Option>
                  ))}
                  {totalTransactions > 5 ? (
                    <Option className="viewmore" key="more-transactions" value="more-transactions">
                      View More
                    </Option>
                  ) : null}
                </OptGroup>
              )}
              {reconciliationOptions?.length > 0 && (
                <OptGroup label={`Bank Reconciled (${totalReconciliations})`}>
                  {reconciliationOptions.map((item) => (
                    <Option key={`${item?.id}-reconcile`} value={`reconcilation ${item?.id}`}>
                      <span className="search-desc">{item?.description}</span>
                      <span
                        className={
                          item?.baseType
                            ? 'amount-credit search-source'
                            : 'amount-debit search-source'
                        }
                      >
                        ${formatNumber(Number(item?.amount))}
                      </span>
                    </Option>
                  ))}
                  {totalReconciliations > 5 ? (
                    <Option className="viewmore" key="more-reconcilation" value="more-reconcilation">
                      View More
                    </Option>
                  ) : null}
                </OptGroup>
              )}
              {notReconciliationOptions?.length > 0 && (
                <OptGroup label={`Bank Not Reconciled (${totalNotReconciliations})`}>
                  {notReconciliationOptions.map((item) => (
                    <Option key={`${item?.id}-notReconcile`} value={`not-reconcilation ${item?.id}`}>
                      <span className="search-desc">{item?.description}</span>
                      <span
                        className={
                          item?.baseType
                            ? 'amount-credit search-source'
                            : 'amount-debit search-source'
                        }
                      >
                        ${formatNumber(Number(item?.amount))}
                      </span>
                    </Option>
                  ))}
                  {totalNotReconciliations > 5 ? (
                    <Option className="viewmore" key="more-not-reconcilation" value="more-not-reconcilation">
                      View More
                    </Option>
                  ) : null}
                </OptGroup>
              )}
              {underReviewOptions?.length > 0 && (
                <OptGroup label={`Under Review (${totalUnderReview})`}>
                  {underReviewOptions.map((item) => (
                    <Option key={`${item?.id}-underReview`} value={`under-review ${item?.id}`}>
                      <span className="search-desc">{item?.description}</span>
                      <span
                        className={
                          item?.baseType
                            ? 'amount-credit search-source'
                            : 'amount-debit search-source'
                        }
                      >
                        ${formatNumber(Number(item?.amount))}
                      </span>
                    </Option>
                  ))}
                  {totalUnderReview > 5 ? (
                    <Option className="viewmore" key="more-under-review" value="more-under-review">
                      View More
                    </Option>
                  ) : null}
                </OptGroup>
              )}
            </>
          ) : null}
        </Select>
        <Button className="fa fa-search">Search</Button>
      </Form>
      {showView && (
        <ContactDetailPopup
          contactId={localStorage.getItem('selectedId')}
          toggleShowModal={toggleShowModal}
          showView={showView}
        />
      )}
      {showTransaction && (
        <Modal
          size="lg"
          show={showTransaction}
          onHide={toggleShowTransactionModal}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            {transactionData?.bank_reconciliation?.[0]?.status === '0'
              ? 'Bank Reconcilations Transaction'
              : transactionData?.bank_reconciliation?.[0]?.status === '1'
              ? 'Bank Reconciled Transaction'
              : transactionData?.bank_reconciliation?.[0]?.status === '2'
              ? 'Under Review Bank Transaction'
              : 'Transaction Details'}
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="modal-content" style={{ paddingBottom: '0px' }}>
                {transactionData?.bank_reconciliation ? (
                  transactionData?.bank_reconciliation?.map((item, index) => (
                    <>
                      <h3>
                        {/* {transactionData?.bank_reconciliation?.[0]?.status === '0'
                            ? 'Bank Reconcilations Transaction'
                            : transactionData?.bank_reconciliation?.[0]?.status === '1'
                            ? 'Bank Reconciled Transaction'
                            : transactionData?.bank_reconciliation?.[0]?.status === '2'
                            ? 'Under Review Bank Transaction'
                            : 'Transaction Details'} */}
                        Transaction - {index + 1}
                      </h3>
                      <div className="modal-content-details">
                        <>
                          <p style={{ width: '33.3%' }}>
                            <strong>Date:</strong>{' '}
                            {transactionData?.date ? transactionData?.date : '-'}
                          </p>
                          <p style={{ width: '33.3%' }}>
                            <strong>Amount:</strong>{' '}
                            <span
                              className={transactionData?.baseType ? 'rag-green' : 'rag-red'}
                              style={{ marginLeft: '0px' }}
                            >
                              {item?.amount ? `$${formatNumber(item?.amount)}` : '-'}
                            </span>
                          </p>
                          <p style={{ width: '33.3%' }}>
                            <strong>Source:</strong>{' '}
                            {transactionData?.source ? transactionData?.source : '-'}
                          </p>
                          <p style={{ width: '100%' }}>
                            <strong>Description:</strong>{' '}
                            {item?.description ? item?.description : '-'}
                          </p>
                        </>
                      </div>
                    </>
                  ))
                ) : (
                  <div className="modal-content-details">
                    <p style={{ width: '33.3%' }}>
                      <strong>Date:</strong> {transactionData?.date ? transactionData?.date : '-'}
                    </p>
                    <p style={{ width: '33.3%' }}>
                      <strong>Amount:</strong>{' '}
                      <span
                        className={transactionData?.baseType ? 'rag-green' : 'rag-red'}
                        style={{ marginLeft: '0px' }}
                      >
                        {transactionData?.amount
                          ? `$${formatNumber(transactionData?.amount)}`
                          : '-'}
                      </span>
                    </p>
                    <p style={{ width: '33.3%' }}>
                      <strong>Source:</strong>{' '}
                      {transactionData?.source ? transactionData?.source : '-'}
                    </p>
                    <p style={{ width: '100%' }}>
                      <strong>Description:</strong>{' '}
                      {transactionData?.description ? transactionData?.description : '-'}
                    </p>
                  </div>
                )}
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default SearchBar;
