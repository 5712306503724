import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { imageDefaultPrefixPath, metaTitle } from "../../../config";
import { checkIfFileExists } from "../../common/Misc";
import { getBusinessData } from "../components/BusinessHelper";
import BusinessSettings from "./business-settings/BusinessSetting";
import Integrations from "./integrations/Integrations";
import TitleBar from "../../common/TitleBar";

const tabsKey = {
  "business-settings": 1,
  integrations: 2,
};

const Settings = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "business-settings";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 2 ? "Integrations" : "Business Settings"
  );
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem("sme-businessName");
  const businessLogo = localStorage.getItem("sme-businessLogo");
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [clientID, setClientID] = useState(null);

  const fetchBussinessData = async() => {
    const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
    setClientID(data?.client?.user_id)
  }

  useEffect(() => {
    fetchBussinessData();
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo("");
      }
    });
  }, [BusinessLogo, BusinessName]);

  useEffect(() => {
    window.document.title = `${metaTitle} | ${activeTab}`
  },[activeTab])

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={"Settings"}
            />
            <div className="content-details">
              {/* <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) => debounce(onSearchChange(e), 300)}
                      type="text"
                      value={searchParam}
                      placeholder="Search"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div> */}
              {/* <div className="table-btn">
                  <Button
                    variant="primary"
                    // onClick={toggleAccountModal}
                  >
                    New
                  </Button>
                </div> */}
              <div className="table-top-btn">
                <ul
                  defaultActiveKey={defaultActiveKey}
                  className="nav nav-tabs"
                  onClick={(event) => {
                    // setActiveKey(
                    //   event?.target["__reactFiber$ytws3iau74l"]?.key
                    // );
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li
                    className={
                      activeTab === "Business Settings" ? "active" : ""
                    }
                  >
                    <Link
                      key={1}
                    >
                      Business Settings
                    </Link>
                  </li>
                  <li className={activeTab === "Integrations" ? "active" : ""}>
                    <Link
                      key={2}
                    >
                      Integrations
                    </Link>
                  </li>
                  {/* {accountTpesLoading && <Spin />}
                  {accountTypesList &&
                    accountTypesList?.length > 0 &&
                    accountTypesList?.map((item) => {
                      return (
                        <li className={accountId === item?.id ? "active" : ""}>
                          <a
                            key={item?.id}
                            href
                            onClick={() => {
                              setAccountId(item?.id);
                              setAccountTab(
                                item?.name.toLowerCase().replace(/ /g, "-")
                              );
                            }}
                          >
                            {item?.name}
                          </a>
                        </li>
                      );
                    })} */}
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Integrations" && <Integrations clientID={clientID}/>}
                {activeTab === "Business Settings" && <BusinessSettings />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
