import React from "react";

const Spin = () => {
  return (
    <div>
      <div className="spinner-border full-width" role="status">
        <i className="spinner"></i>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spin;
