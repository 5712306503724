import React from "react";
import { useNavigate } from "react-router-dom";
// import ConfirmAuthentication from "../../components/Authentication/ConfirmAuthentication";
import SelectAuthenticate from "../../components/Authentication/SelectAuthenticate"
import "../../components/Authentication/authentication.css"
import { API } from "../../config";

const TwoFactorDisable = () => {
  const navigate = useNavigate()


  const confirmAuthentication = () => {
    localStorage.setItem("sme-auth-tfa", -1);
    navigate(-1);
  }

  return (
    <>
      <SelectAuthenticate
        confirmAuthentication={confirmAuthentication}
        emailVerify={API.DELETE_2FA}
        googleVerify={API.DELETE_2FA}
      />
    </>
  );
};

export default TwoFactorDisable;
