import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, get } from "../../../config";
import Spin from "../../common/Spin";

const GstTransaction = ({ businessId, valueRange }) => {
  const [loading, setLoading] = useState(false);
  const [gstRevenue, setGSTRevenue] = useState();
  const [gstFreeRevenue, setGSTFreeRevenue] = useState();
  const [gstExpenses, setGSTExpenses] = useState();
  const [basExcluded, setBASExcluded] = useState();
  const [gstFreeExpenses, setGSTFreeExpenses] = useState();
  const [sortField, setSortField] = useState('transaction_date');
  const [sortOrder, setSortOrder] = useState('desc');

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const fetchGSTTransaction = useCallback(async () => {
    if (businessId) {
      try {
        setLoading(true);
        const { data } = await get(
          `${API.GST_TRANSACTION}?client_business_id=${
            businessId ? businessId : ""
          }&start_month=${
            valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
          }&end_month=${
            valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""
          }&sort_column=${sortField ? sortField : ""}&sort_order=${
            sortOrder ? sortOrder : ""
          }`
        );
        setLoading(false);
        setGSTRevenue(data?.data?.gst_on_revenues);
        setGSTFreeRevenue(data?.data?.gst_free_revenues);
        setGSTExpenses(data?.data?.gst_on_expenses);
        setBASExcluded(data?.data?.bas_excluded);
        setGSTFreeExpenses(data?.data?.gst_free_expenses);
        return data.data;
      } catch (e) {
        setLoading(false);
        setGSTRevenue([]);
        setGSTFreeRevenue([]);
        setGSTExpenses([]);
        setBASExcluded([]);
        setGSTFreeExpenses([]);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  }, [businessId, valueRange, sortField, sortOrder]);

  const gstRevenueGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstRevenue[i]?.transaction_date
      ? gstRevenue[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstRevenue[i]?.id,
        transaction_date: dateData
          ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
          : "-",
        account_coa_name: `${gstRevenue[i]?.account_coa_name}${
          gstRevenue[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstRevenue[i]?.account_coa_code), 4) : " (" + gstRevenue[i]?.account_coa_code
          }${gstRevenue[i]?.account_coa_sub_code ? "." + gstRevenue[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstRevenue[i]?.reference
          ? gstRevenue[i]?.reference
          : "-",
        details: gstRevenue[i]?.details ? gstRevenue[i]?.details : "",
        gross: gstRevenue[i]?.amount
          ? gstRevenue[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstRevenue[i]?.gst_amount
          ? gstRevenue[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstRevenue[i]?.net_amount
          ? gstRevenue[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstRevenueData = gstRevenueGenerator(gstRevenue?.length);

  const gstFreeRevenueGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstFreeRevenue[i]?.transaction_date
      ? gstFreeRevenue[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstFreeRevenue[i]?.id,
        transaction_date: dateData
          ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
          : "-",
        account_coa_name: `${gstFreeRevenue[i]?.account_coa_name}${
          gstFreeRevenue[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstFreeRevenue[i]?.account_coa_code), 4) : " (" + gstFreeRevenue[i]?.account_coa_code
          }${gstFreeRevenue[i]?.account_coa_sub_code ? "." + gstFreeRevenue[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstFreeRevenue[i]?.reference
          ? gstFreeRevenue[i]?.reference
          : "-",
        details: gstFreeRevenue[i]?.details ? gstFreeRevenue[i]?.details : "",
        gross: gstFreeRevenue[i]?.amount
          ? gstFreeRevenue[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstFreeRevenue[i]?.gst_amount
          ? gstFreeRevenue[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstFreeRevenue[i]?.net_amount
          ? gstFreeRevenue[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstFreeRevenueData = gstFreeRevenueGenerator(gstFreeRevenue?.length);


  const gstExpensesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstExpenses[i]?.transaction_date
      ? gstExpenses[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstExpenses[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${gstExpenses[i]?.account_coa_name}${
          gstExpenses[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstExpenses[i]?.account_coa_code), 4) : " (" + gstExpenses[i]?.account_coa_code
          }${gstExpenses[i]?.account_coa_sub_code ? "." + gstExpenses[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstExpenses[i]?.reference
          ? gstExpenses[i]?.reference
          : "-",
        details: gstExpenses[i]?.details ? gstExpenses[i]?.details : "",
        gross: gstExpenses[i]?.amount
          ? gstExpenses[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstExpenses[i]?.gst_amount
          ? gstExpenses[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstExpenses[i]?.net_amount
          ? gstExpenses[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstExpensesData = gstExpensesGenerator(gstExpenses?.length);

  const gstFreeExpensesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstFreeExpenses[i]?.transaction_date
      ? gstFreeExpenses[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstFreeExpenses[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${gstFreeExpenses[i]?.account_coa_name}${
          gstFreeExpenses[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstFreeExpenses[i]?.account_coa_code), 4) : " (" + gstFreeExpenses[i]?.account_coa_code
          }${gstFreeExpenses[i]?.account_coa_sub_code ? "." + gstFreeExpenses[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstFreeExpenses[i]?.reference
          ? gstFreeExpenses[i]?.reference
          : "-",
        details: gstFreeExpenses[i]?.details ? gstFreeExpenses[i]?.details : "",
        gross: gstFreeExpenses[i]?.amount
          ? gstFreeExpenses[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstFreeExpenses[i]?.gst_amount
          ? gstFreeExpenses[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstFreeExpenses[i]?.net_amount
          ? gstFreeExpenses[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstFreeExpensesData = gstFreeExpensesGenerator(gstFreeExpenses?.length);

  const BASExcludedGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = basExcluded[i]?.transaction_date
      ? basExcluded[i]?.transaction_date?.split('-') : ''
      items.push({
        key: basExcluded[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${basExcluded[i]?.account_coa_name}${
          basExcluded[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(basExcluded[i]?.account_coa_code), 4) : " (" + basExcluded[i]?.account_coa_code
          }${basExcluded[i]?.account_coa_sub_code ? "." + basExcluded[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: basExcluded[i]?.reference
          ? basExcluded[i]?.reference
          : "-",
        details: basExcluded[i]?.details ? basExcluded[i]?.details : "",
        gross: basExcluded[i]?.amount
          ? basExcluded[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: basExcluded[i]?.gst_amount
          ? basExcluded[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: basExcluded[i]?.net_amount
          ? basExcluded[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const basExcludedData = BASExcludedGenerator(basExcluded?.length);

  useEffect(() => {
    fetchGSTTransaction();
  }, [fetchGSTTransaction]);

  const columnsForGstOnRevenue = [
    {
      dataField: "transaction_date",
      text: "Date",
      footer: "Total GST on Revenue",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "account_coa_name",
      text: "Account",
      footer: "",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "reference",
      text: "Reference",
      footer: "",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "details",
      text: "Details",
      footer: "",
      style: { width: "26%" },
    },
    {
      dataField: "gross",
      text: "Gross",
      align: "right",
      style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "gst",
      text: "GST",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "net",
      text: "NET",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
  ];

  const columnsForGstFreeRevenue = [
    {
      dataField: "transaction_date",
      text: "Date",
      footer: "Total GST Free Revenue",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "account_coa_name",
      text: "Account",
      footer: "",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "reference",
      text: "Reference",
      footer: "",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "details",
      text: "Details",
      footer: "",
      style: { width: "26%" },
    },
    {
      dataField: "gross",
      text: "Gross",
      align: "right",
      style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "gst",
      text: "GST",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "net",
      text: "NET",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
  ];

  const columnsForGstOnExpense = [
    {
      dataField: "transaction_date",
      text: "Date",
      footer: "Total GST on Expenses",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "account_coa_name",
      text: "Account",
      footer: "",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "reference",
      text: "Reference",
      footer: "",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "details",
      text: "Details",
      footer: "",
      style: { width: "26%" },
    },
    {
      dataField: "gross",
      text: "Gross",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "gst",
      text: "GST",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "net",
      text: "NET",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
  ];

  const columnsForGstFreeExpense = [
    {
      dataField: "transaction_date",
      text: "Date",
      footer: "Total GST Free Expenses",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "account_coa_name",
      text: "Account",
      footer: "",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "reference",
      text: "Reference",
      footer: "",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "details",
      text: "Details",
      footer: "",
      style: { width: "26%" },
    },
    {
      dataField: "gross",
      text: "Gross",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "gst",
      text: "GST",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "net",
      text: "NET",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
  ];

  const columnsForBasExcluded = [
    {
      dataField: "transaction_date",
      text: "Date",
      footer: "Total BAS Excluded",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "account_coa_name",
      text: "Account",
      footer: "",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "reference",
      text: "Reference",
      footer: "",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "details",
      text: "Details",
      footer: "",
      style: { width: "26%" },
    },
    {
      dataField: "gross",
      text: "Gross",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "gst",
      text: "GST",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
    {
      dataField: "net",
      text: "NET",
      align: "right",
      // style: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
      footerAlign: (column, colIndex) => "right",
      footer: (columnData) =>
        columnData.reduce(
          (acc, item) =>
            (
              parseFloat(acc.replace(/,/g, "")) +
              parseFloat(item.replace(/,/g, ""))
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          "0.00"
        ),
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  return (
    <>
      {loading && <Spin />}

      <div className="table-with-footer">
        <h6>GST on Revenue</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstRevenueData}
          columns={columnsForGstOnRevenue}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
          // bordered={false}
        />
      </div>

      <div className="table-with-footer">
        <h6>GST Free Revenue</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstFreeRevenueData}
          columns={columnsForGstFreeRevenue}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
          // bordered={false}
        />
      </div>

      <div className="table-with-footer">
        <h6>GST on Expenses</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstExpensesData}
          columns={columnsForGstOnExpense}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
          // bordered={false}
        />
      </div>

      <div className="table-with-footer">
        <h6>GST Free Expenses</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstFreeExpensesData}
          columns={columnsForGstFreeExpense}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
          // bordered={false}
        />
      </div>

      <div className="table-with-footer">
        <h6>BAS Excluded</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={basExcludedData}
          columns={columnsForBasExcluded}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
          // bordered={false}
        />
      </div>
    </>
  );
};

export default GstTransaction;
