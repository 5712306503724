import axios from "axios";
import { toast } from "react-toastify";

export const defaultBaseUrl = () => {
  return "https://api.smecashbook.com.au/";
  // return "https://a6b00797c84f-12018376576325490140.ngrok-free.app/client/sme-cashbook-api/";
};

const baseURL = defaultBaseUrl() + "api/v1";

//set config when creating the instance
const instance = axios.create({
  baseURL: baseURL,
});

//add a request interceptor

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("sme-auth-token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["timeout"] = 5000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.status === "Token Expired. Please try again.") {
        localStorage.setItem("sme-DefaultURL", window.location.pathname);
        localStorage.setItem(
          "sme-token-expired-email",
          localStorage.getItem("sme-auth-email")
        );
        localStorage.removeItem("sme-auth-token");
        // localStorage.removeItem("sme-auth-email");
        localStorage.removeItem("sme-EmailVarification");
        localStorage.removeItem("sme-userId");
        localStorage.removeItem("sme-ProfilePhoto");
        localStorage.removeItem("sme-Name");
        localStorage.removeItem("sme-role");
        localStorage.removeItem("sme-clientId");
        localStorage.removeItem("sme-AccountingFirmLogo");
        // localStorage.clear();
        window.location.href = "/";
      }
      if (response.data.status === 401) {
        localStorage.clear();
        // window.location.href = "/";
      }
    }
    return response;
  },

  function (error) {
    const originalRequest = error.config;

    const headers = {
      "Content-Type": "application/json",
    };
    // const token = localStorage.getItem("sme-auth-token");
    const token = localStorage.getItem('sme-refresh-token');
    headers["Authorization"] = "Bearer " + token;

    //token expired
    if (
      error.response.status === 419 &&
      originalRequest.url !== "/reset-password"
    ) {
      return axios
        .post(`${baseURL}/refresh`, "", { headers: headers })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("sme-auth-token", res.data.access_token);
            localStorage.setItem('sme-refresh-token', res.data.refresh_token);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setTimeout(() => {
            localStorage.setItem("sme-DefaultURL", window.location.pathname);
            localStorage.setItem(
              "sme-token-expired-email",
              localStorage.getItem("sme-auth-email")
            );
            localStorage.removeItem("sme-auth-token");
            // localStorage.removeItem("sme-auth-email");
            localStorage.removeItem("sme-EmailVarification");
            localStorage.removeItem("sme-userId");
            localStorage.removeItem("sme-ProfilePhoto");
            localStorage.removeItem("sme-Name");
            localStorage.removeItem("sme-role");
            localStorage.removeItem("sme-clientId");
            localStorage.removeItem("sme-AccountingFirmLogo");
            // localStorage.clear();
            window.location.href = "/";
          }, 2000);
        });
    }

    //if user unauthorised then redirect to login
    if (error.response.status === 401) {
      if (
        error?.response?.data?.errors?.myna_error[0] ===
        "Email or password may be wrong."
      ) {
        toast.error(error.response.data.errors.myna_error[0]);
        localStorage.clear();
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
    //if user has no permission then redirect to dashboard
    // if (error.response.status === 403) {
    //   window.location.href = "/";
    // }

    return Promise.reject(error);
  }
);

export default instance;
