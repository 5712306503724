import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { AddChartOfAccountSchema } from '../../common/Validation';
import Spin from '../../common/Spin';
import { API, fileUpload, get } from '../../../config';
const AddNewAccountJournalEntry = ({
  handleAddNewAccount,
  businessId,
  addNewAccount,
  getAccounts,
  newAccountName,
  newAccountCode,
  newAccountType,
  newAccountTransactionType,
  setExcelData,
  excelData,
  rowID,
  disableSelectedRow,
  setDisableSelectedRow,
  selectedRow,
  setSelectedRow
}) => {
  const [loading, setLoading] = useState(false);

  const [accountData, setAccountData] = useState();

  const [accountTpesLoading, setAccountTpesLoading] = useState();
  const [accountTypesList, setAccountTypesList] = useState();

  const fetchAccountTypes = useCallback(async () => {
    try {
      setAccountTpesLoading(true);
      const { data } = await get(`${API.GET_ACCOUNT_TYPES}`);
      setAccountTpesLoading(false);
      const accountTypesList = data.data;
      setAccountTypesList(accountTypesList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setAccountTpesLoading(false);
      setAccountTypesList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, []);

  const accuntingList = accountTypesList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  //   const accountTypeValue = accuntingList?.find(
  //     (item) => item?.value === accountData?.account_type_id
  //   );

  const typeList = [
    { label: 'Debit', value: '0' },
    { label: 'Credit', value: '1' }
  ];
  const typeValue = typeList?.find((item) => parseInt(item?.value) === accountData?.type);

  const taxList = [
    { label: 'GST 10%', value: 1 },
    { label: 'GST Excluded', value: 0 },
    { label: 'BAS Excluded', value: 2 },
    { label: 'GST on Imports', value: 3 }
  ];
  const taxValue = taxList?.find((item) => parseInt(item?.value) === accountData?.tax);

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.account_type_id = value?.account_type_id?.value ? value?.account_type_id?.value : '';
    value.type = value?.type?.value ? parseInt(value?.type?.value) : '';
    value.tax = value?.tax ? parseInt(value?.tax?.value) : '';
    value.code = value?.code ? value?.code : '';
    value.sub_code = value?.sub_code ? value?.sub_code : '';

    formData.append('client_business_id', businessId);
    formData.append('account_type_id', value?.account_type_id);
    formData.append('type', value?.type);
    formData.append('tax', value?.tax);
    formData.append('code', value?.code);
    formData.append('sub_code', value?.sub_code);
    formData.append('name', value?.name ? value?.name : '');
    formData.append('description', value?.description ? value?.description : '');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ACCOUNT_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        getAccounts();
        handleAddNewAccount();
        const getWithPromiseAll = async () => {
          let temp = await Promise.all(
            excelData.map(async (row) => {
              if (row?.key === rowID) {
                const newRow = { ...row };
                newRow['action'] = '';
                let disableItem = disableSelectedRow;
                const index = disableItem.indexOf(rowID);
                if (index > -1) {
                  // only splice array when item is found
                  disableItem.splice(index, 1); // 2nd parameter means remove one item only
                }
                let selectItem = selectedRow;
                selectItem.push(rowID);
                setSelectedRow(selectItem);
                setDisableSelectedRow(disableItem);
                newRow['chart_of_account_id'] = data?.data?.id;
                return newRow;
              }
              return row;
            })
          );
          return temp;
        };
        const result = await getWithPromiseAll();
        setExcelData(result);

        // let temp = {
        //   label:
        //     // eslint-disable-next-line
        //     data?.data?.code !== "2000" && ((`${data?.data?.code?.length < 4 ? padWithLeadingZeros(data?.data?.code, 4) : data?.data?.code}`) + (`${data?.data?.sub_code ? "." + data?.data?.sub_code : ""}`) + " - " + (`${data?.data?.name ? data?.data?.name : ""}`) + (`${data?.data?.account_type?.name ? " (" + data?.data?.account_type?.name + ")" : ""}`)),
        //   value: data?.data?.code !== "2000" && (data?.data?.id),
        //   tax: data?.data?.code !== "2000" && (data?.data?.tax),
        // };
      }
      return data?.data;
    } catch (error) {
      setAccountData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const [codeMask, setCodeMask] = useState('');

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleBlurCode = (e, setFieldValue) => {
    let value = e.target.value;
    let FinalValue = e.target.value;
    if (value.match('/')) {
      let tempValue = value.split('/');
      let firstNumber = padWithLeadingZeros(tempValue[0], 2);
      let secondNumber = padWithLeadingZeros(tempValue[1], 4);
      FinalValue = `${firstNumber}/${secondNumber}`;
      setCodeMask('99/9999');
    } else if (value.match('-')) {
      let tempValue = value.split('-');
      let firstNumber = padWithLeadingZeros(tempValue[0], 2);
      let secondNumber = padWithLeadingZeros(tempValue[1], 4);
      FinalValue = `${firstNumber}-${secondNumber}`;
      setCodeMask('99-9999');
    } else {
      if (value) {
        FinalValue = padWithLeadingZeros(value, 4);
      }
    }
    setFieldValue('code', FinalValue);
  };

  const handleSubCodeBlur = (e, setFieldValue) => {
    let value = e.target.value;
    let FinalValue = e.target.value;
    if (value.length === 1) {
      FinalValue = padWithLeadingZeros(value, 2);
    }
    setFieldValue('sub_code', FinalValue);
  };

  const handleCodeChange = (e, setFieldValue) => {
    let value = e.target.value;
    let FinalValue = e.target.value;
    if (value !== null && value !== '') {
      if (value.length > 4 || value.match('/') || value.match('-')) {
        if (value.match('/')) {
          if (value.length === 7 && value.match('/').index === 2) {
            setCodeMask('99/9999');
            FinalValue = e.target.value;
          }
          if (value.length > 5 && value.length < 7 && value.match('/').index === 1) {
            let tempValue = value.split('/');
            let firstNumber = padWithLeadingZeros(tempValue[0], 2);
            let secondNumber = padWithLeadingZeros(tempValue[1], 4);
            FinalValue = `${firstNumber}/${secondNumber}`;
            setCodeMask('99/9999');
          }
        } else if (value.match('-')) {
          if (value.length === 7 && value.match('-').index === 2) {
            setCodeMask('99-9999');
            FinalValue = e.target.value;
          }
          if (value.length > 5 && value.length < 7 && value.match('-').index === 1) {
            let tempValue = value.split('-');
            let firstNumber = padWithLeadingZeros(tempValue[0], 2);
            let secondNumber = padWithLeadingZeros(tempValue[1], 4);
            FinalValue = `${firstNumber}-${secondNumber}`;
            setCodeMask('99-9999');
          }
        } else {
          FinalValue = String(value).substring(0, 4);
        }
      } else if (value.length === 4 && !value.match('/') && !value.match('-')) {
        FinalValue = e.target.value;
      } else {
        setCodeMask('');
        FinalValue = e.target.value;
      }
    } else {
      FinalValue = e.target.value;
      setCodeMask('');
    }
    setFieldValue('code', FinalValue);
  };

  const handleCodePaste = (e, setFieldValue) => {
    setCodeMask('');
    setFieldValue('code', '');
  };

  return (
    <>
      <Modal
        size="lg"
        show={addNewAccount}
        onHide={handleAddNewAccount}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Add New Account
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              account_type_id: accuntingList?.find((m) => m.value === newAccountType?.id)
                ? accuntingList?.find((m) => m.value === newAccountType?.id)
                : '',
              type: typeList?.find((m) => m.value === String(newAccountTransactionType))
                ? typeList?.find((m) => m.value === String(newAccountTransactionType))
                : typeValue,
              tax: taxValue,
              name: newAccountName ? newAccountName : accountData?.name ? accountData?.name : '',
              code: newAccountCode ? newAccountCode : accountData?.code,
              sub_code: accountData?.sub_code,
              description: accountData?.description
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddChartOfAccountSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <>
                  {loading && <Spin />}

                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                    <div className="form-box">
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Account Type
                        </label>
                        <Select
                          // type="select"
                          name="account_type_id"
                          value={values?.account_type_id ? values?.account_type_id : ''}
                          onChange={(e) => {
                            setFieldValue(`account_type_id`, e ? e : '');
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={accuntingList}
                          placeholder="Select Account Type"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                          isLoading={accountTpesLoading}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="account_type_id" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Type
                        </label>
                        <Select
                          name="type"
                          // type="select"
                          value={values?.type ? values?.type : ''}
                          onChange={(e) => {
                            setFieldValue(`type`, e ? e : '');
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={typeList}
                          placeholder="Select Type"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="type" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Tax
                        </label>

                        <Select
                          name="tax"
                          // type="select"
                          value={values?.tax ? values?.tax : ''}
                          onChange={(e) => {
                            setFieldValue(`tax`, e ? e : '');
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={taxList}
                          placeholder="Select Tax"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'grey'
                            }
                          })}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />

                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="tax" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={values?.name ? values?.name : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="name" />
                        </span>
                      </div>

                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Code
                        </label>
                        <InputMask
                          type="text"
                          name="code"
                          value={values?.code ? values?.code : ''}
                          onChange={(e) => handleCodeChange(e, setFieldValue)}
                          onBlur={(e) => handleBlurCode(e, setFieldValue)}
                          mask={codeMask}
                          maskChar={null}
                          onPaste={(e) => handleCodePaste(e, setFieldValue)}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="code" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>Sub Code</label>
                        <InputMask
                          type="text"
                          name="sub_code"
                          value={values?.sub_code ? values?.sub_code : ''}
                          onChange={handleChange}
                          onBlur={(e) => handleSubCodeBlur(e, setFieldValue)}
                          mask="99"
                          maskChar={null}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="sub_code" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>Description</label>
                        <textarea
                          type="text"
                          name="description"
                          value={values?.description ? values?.description : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          style={{
                            borderRadius: '6px',
                            margin: '0 0 15px',
                            minHeight: '70px'
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          handleAddNewAccount();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewAccountJournalEntry;
