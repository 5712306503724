import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SubscriptionAfterLogin from '../user-setting/components/SubscriptionAfterLogin';
import FirmSetting from './FirmSetting/FirmSetting';
import { metaTitle } from '../../config';
import BackButton from '../../components/Form/BackButton';
// import Workinprogrees from "../../components/Workinprogrees";

const tabsKey = {
  'firm-settings': 1,
  subscriptions: 2
};

const GeneralSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab') || 'firm-settings';
  const role = localStorage.getItem('sme-role');
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 2 ? 'subscriptions' : 'firm-settings'
  );

  useEffect(() => {
    if (activeTab !== 'subscriptions') {
      navigate(`/general-settings?tab=${activeTab}`, {
        replace: true
      });
    }

    if (role === 'Client') {
      setActiveTab('subscriptions');
    }
  }, [activeTab, navigate, role]);

  useEffect(() => {
    if (activeTab === 'firm-settings') {
      window.document.title = `${metaTitle} | Firm Settings`;
    }
    if (activeTab === 'subscriptions') {
      window.document.title = `${metaTitle} | Subscriptions`;
    }
  }, [activeTab]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {activeTab === 'firm-settings'
                ? 'Firm Settings'
                : activeTab === 'subscriptions'
                ? 'Subscriptions'
                : 'General Settings'}
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText.replace(/ /g, '-').toLowerCase());
                  }}
                >
                  {role !== 'Client' && (
                    <li className={activeTab === 'firm-settings' ? 'active' : ''}>
                      <Link key={1}>Firm Settings</Link>
                    </li>
                  )}
                  {/* {role !== "Client" &&  */}
                  <li className={activeTab === 'subscriptions' ? 'active' : ''}>
                    <Link key={2}>Subscriptions</Link>
                  </li>
                  {/* } */}
                  {/* <li
                    className={activeTab === "change-password" ? "active" : ""}
                  >
                    <a key={2} href>
                      Change Password
                    </a>
                  </li> */}
                  {/* <li className={activeTab === "subscription" ? "active" : ""}>
                    <a key={3} href>
                      Subscription
                    </a>
                  </li>
                  <li className={activeTab === "invitations" ? "active" : ""}>
                    <a key={4} href>
                      Invitations
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === 'firm-settings' && role !== 'Client' && <FirmSetting />}
                {activeTab === 'subscriptions' && <SubscriptionAfterLogin />}
                {/* {role === "Client" && <Workinprogrees />} */}
                {/* {activeTab === "my-profile" && <MyProfile />}
                {activeTab === "change-password" && <ChangePassword />}
                {activeTab === "subscription" && <Subscription />}
                {activeTab === "invitations" && <Invitation />} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSetting;
