import React, { useRef, useState } from "react";
import { API, fileUpload } from "../../../config";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Form from "./components/Form";
import { Formik } from "formik";
import { AddJobSchema } from "../../common/Validation";
import Spin from "../../common/Spin";

const AddJob = ({ businessId, toggleShowModal, fetchJobs, showAddJob }) => {
  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleSubmit = async (value) => {
    const formData = new FormData();

    formData.append("client_business_id", businessId);
    formData.append("code", value?.code);
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.JOBS_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleShowModal();
        fetchJobs();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showAddJob}
        onHide={toggleShowModal}
        dialogClassName="modal-50w small-popup job-modal review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Add Job/Matter/Project
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              code: "",
              description: "",
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddJobSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleShowModal={toggleShowModal}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddJob;
