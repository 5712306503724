import { ErrorMessage } from 'formik';
import React from 'react';
import NumberFormat from 'react-number-format';

const PaymentTerms = ({values, handleChange, handleBlur}) => {
  return (
    <div className="form-group">
      <div className="settings-heading">Payment Terms</div>
      <div className="form-group">
        <NumberFormat
          type="text"
          decimalScale={0}
          name="payment_terms"
          value={values?.payment_terms ? values?.payment_terms : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e) => {
            e.which === 13 && e.preventDefault();
          }}
        />
        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
          <ErrorMessage name="payment_terms" />
        </span>
      </div>
    </div>
  );
};

export default PaymentTerms;
