import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { API, get } from '../../../config';
import Pagination from '../../common/Pagination';
import Spin from '../../common/Spin';
import { formatName, formatNumber } from '../../common/Misc';

const ExpandJournalEntry = ({ businessId, id }) => {
  const [expandJournalLoading, setExpandJournalLoading] = useState(false);
  const [expandJournalData, setExpandJournalData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const ExGSTClaculate = (value, tax) => {
    return tax === 0
      ? formatNumber(Number(value))
      : formatNumber(Number(Number(value) - (Number(value) / 1.1) * 0.1));
  };

  const fetchJournalExpandData = useCallback(async () => {
    if (businessId) {
      try {;
        setExpandJournalLoading(true);
        const data = await get(`${API.JOURNAL_ENTRIES}/${id}`);
        setExpandJournalLoading(false);
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setExpandJournalData(data?.data?.data?.journal_entry_details);
      } catch (e) {
        const { data } = e;
        setExpandJournalLoading(false);
        setExpandJournalData([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    }
    //eslint-disable-next-line
  }, [businessId, id, limit, page]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const journalDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: expandJournalData[i]?.id,
        transactionDate: expandJournalData[i]?.transactionDate
          ? expandJournalData[i]?.transactionDate
          : '',
        account: expandJournalData[i]?.chart_of_account
          ? (expandJournalData[i]?.chart_of_account?.code?.length < 4
              ? padWithLeadingZeros(Number(expandJournalData[i]?.chart_of_account?.code),4)
              : expandJournalData[i]?.chart_of_account?.code.toString()) +
            (expandJournalData[i]?.chart_of_account?.sub_code
              ? '.' + expandJournalData[i]?.chart_of_account?.sub_code.toString()
              : '') +
            ' - ' +
            expandJournalData[i]?.chart_of_account?.name
          : '',
        contact: expandJournalData[i]?.contact
          ? formatName(
              expandJournalData[i]?.contact?.first_name,
              expandJournalData[i]?.contact?.last_name
            )
          : '-',
        debit:
          expandJournalData[i]?.transaction_type === 0
            ? expandJournalData[i]?.amount
              ? '$' + formatNumber(Number(expandJournalData[i]?.amount))
              : '$0.00'
            : '-',
        credit:
          expandJournalData[i]?.transaction_type === 1
            ? expandJournalData[i]?.amount
              ? '$' + formatNumber(Number(expandJournalData[i]?.amount))
              : '$0.00'
            : '-',
        gst:
          expandJournalData[i]?.gst === 0
            ? 'GST Excluded'
            : expandJournalData[i]?.gst === 1
            ? 'GST 10%'
            : expandJournalData[i]?.gst === 2
            ? 'BAS Excluded'
            : 'GST on Imports',
        tax: expandJournalData[i]?.gst_amount
          ? '$' + formatNumber(Number(expandJournalData[i]?.gst_amount))
          : '-',
        ex_gst_amount: expandJournalData[i]?.amount
          ? '$' + ExGSTClaculate(expandJournalData[i]?.amount, expandJournalData[i]?.gst)
          : 0,
        description: expandJournalData[i]?.description ? expandJournalData[i]?.description : '-'
      });
    }
    return items;
  };
  const journalData = journalDataGenerator(expandJournalData?.length);

  const columns = [
    {
      dataField: 'account',
      text: 'Account',
      style: { width: '15%' }
    },
    {
      dataField: 'description',
      text: 'Description',
      style: { width: '15%' }
    },
    {
      dataField: 'gst',
      text: 'GST',
      style: { width: '10%' }
    },
    {
      dataField: 'debit',
      text: 'Debit',
      style: { width: '11%' }
    },
    {
      dataField: 'credit',
      text: 'Credit',
      style: { width: '11%' }
    },
    {
      dataField: 'tax',
      text: 'Tax',
      style: { width: '11%' }
    },
    {
      dataField: 'ex_gst_amount',
      text: 'Ex. GST Amount',
      style: { width: '11%' }
    },
    {
      dataField: 'contact',
      text: 'Contact ',
      style: { width: '15%' }
    }
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchJournalExpandData();
  }, [fetchJournalExpandData]);

  return (
    <>
      <div className="content-details">
        <div className="custom-table trial-balance-expand-table">
          {expandJournalLoading && <Spin />}
          <BootstrapTable
            keyField="key"
            data={journalData}
            columns={columns}
            noDataIndication="No Data Found"
          />
          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default ExpandJournalEntry;
