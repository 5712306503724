import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import { API, fileUpload, get } from "../../../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { debounce } from "lodash";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import { formatName } from "../../common/Misc";
import TitleBar from "../../common/TitleBar";

// const defaultDateRange = [
//   new Date(
//     new Date().getFullYear(),
//     new Date().getMonth() - 6,
//     new Date().getDate()
//   ),
//   new Date(),
// ];

const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

const ContactStatements = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { afterToday } = DateRangePicker;

  const businessId = params?.business_id;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [contactStaementData, setContactStaementData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [dateValue, setDateValue] = useState(defaultDateRange);
  const [startDate, setStartDate] = useState(
    defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const fetchContactItem = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=${
          limit ? limit : 10
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }&contact_report=1&from=${startDate ? startDate : ""}&to=${
          endDate ? endDate : ""
        }`
      );

      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setContactStaementData(data?.data?.data);

      setLoading(false);
    } catch (e) {
      const { data } = e;
      setLoading(false);
      setContactStaementData([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [
    businessId,
    limit,
    page,
    endDate,
    startDate,
    searchParam,
    sortField,
    sortOrder,
  ]);

  useEffect(() => {
    navigate(
      `/business/${businessId}/contact-statement-report?limit=${
        limit ? limit : 10
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }&from=${startDate ? startDate : ""}&to=${endDate ? endDate : ""}`
    );
  }, [
    navigate,
    businessId,
    limit,
    searchParam,
    sortField,
    sortOrder,
    page,
    startDate,
    endDate,
  ]);

  const contactStatementGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: contactStaementData[i]?.id ? contactStaementData[i]?.id : "",
        contact_id: contactStaementData[i]?.id
          ? contactStaementData[i]?.id
          : "-",
        name: formatName(
          contactStaementData[i]?.first_name,
          contactStaementData[i]?.last_name
        ),
        email: contactStaementData[i]?.email
          ? contactStaementData[i]?.email
          : "-",
        mobile: contactStaementData[i]?.mobile
          ? contactStaementData[i]?.mobile
          : "-",
        phone: contactStaementData[i]?.phone
          ? contactStaementData[i]?.phone
          : "-",
        closing_balance: contactStaementData[i]?.closing_balance
          ? contactStaementData[i]?.closing_balance
          : "-",
      });
    }
    return items;
  };

  const contactStatements = contactStatementGenerator(
    contactStaementData?.length
  );

  useEffect(() => {
    fetchContactItem();
  }, [fetchContactItem]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile Number",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "closing_balance",
      text: "Closing Balance",
    },
  ];

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelectAll: (row, isSelect) => {
      if (row) {
        const ids =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect?.map((item) => {
            return item?.contact_id;
          });

        setSelectedRowIds(selectedRowIds ? selectedRowIds.concat(ids) : ids);
      } else {
        const removableData =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect.map(function (data) {
            return data?.contact_id;
          });
        setSelectedRowIds(
          selectedRowIds
            ? selectedRowIds.filter((item) => !removableData.includes(item))
            : []
        );
      }
    },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowIds((keys) => [...keys, row?.contact_id]);
      } else {
        setSelectedRowIds(arrayRemove(selectedRowIds, row?.contact_id));
      }
    },
  };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );

  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleDateRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleExport = async () => {
    if (selectedRowIds?.length === 0) {
      toast.warning("Please select atleast one contact.");
    }
    if (selectedRowIds?.length > 0) {
      try {
        setExportLoading(true);
        const formData = new FormData();
        // formData.append("contact_report", 1);
        selectedRowIds?.forEach((id) => {
          if (id) {
            formData.append("contact_ids[]", id);
          }
        });

        const { data } = await fileUpload(
          `${
            API.CNTACT_STATEMENT_REPORT
          }?client_business_id=${businessId}&from=${
            startDate ? startDate : ""
          }&to=${endDate ? endDate : ""}&export=1`,
          formData,
          {}
        );
        const export_statement_path = data?.data?.file;

        if (export_statement_path) {
          var a = document.createElement("a");
          a.href = export_statement_path;
          var file = export_statement_path.split("/");
          a.setAttribute(
            "download",
            file[file.length - 1] || "contact_statement_report.pdf"
          );
          a.setAttribute("target", "_blank");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          setExportLoading(false);
        }
      } catch (e) {
        setExportLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"Contact's Statement Report"}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={handleSearchChange}
                    type="text"
                    value={searchParamData}
                    placeholder="Search Client"
                  />
                  {searchParamData 
                  ? <Button className="btn-close" onClick={() => {
                    setSearchParamData("")
                    setSearchParam("")
                  }}
                  >x</Button> 
                  : <Button className="fa fa-search">Search</Button>}
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  onClick={() => {
                    handleExport();
                  }}
                >
                  {exportLoading ? "Loading..." : "Export"}
                  {/* Export */}
                </Button>
              </div>
            </div>
            <div
              className="summary-filter"
              style={{
                padding: "0",
                marginTop: "0px",
                margin: "-15px 0 20px",
              }}
            >
              <Form>
                <DateRangePicker
                  placeholder="Select Date Range"
                  onChange={(e) => handleDateRange(e)}
                  format="dd-MM-yyyy"
                  value={dateValue}
                  placement="bottomEnd"
                  disabledDate={afterToday()}
                  // showOneCalendar
                />
              </Form>
            </div>
            <div className="custom-table">
              {loading && <Spin />}
              <BootstrapTable
                keyField="contact_id"
                remote
                data={contactStatements}
                columns={columns}
                selectRow={selectRow}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactStatements;
