import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sitelogo from "../components/Header/Logo";
import logo from "../assets/newimages/favicon.png";
import TitleBar from "./common/TitleBar";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div className="cms-page">
      <div className="page-content-block">
        {/* <Header /> */}
        <div className="header-blocks">
          <header>
            <Sitelogo />
            <div style={{ margin: "auto", marginRight: "15px" }}>
              <a href={`/`} style={{ marginRight: "15px", color: "white" }}>
                <FontAwesomeIcon icon={faUserCircle} />
                <span></span>&nbsp;&nbsp;Login
              </a>
              <Button
                onClick={() => {
                  navigate("/signup");
                }}
                style={{ backgroundColor: "white" }}
              >
                Get Started
              </Button>
            </div>
          </header>
          <div className="header-bottom-block"></div>
        </div>
        <div className="full-content-block">
          <TitleBar
            label={"Terms & Condition"}
            businessNameData={false}
            globalSearch={false}
          />
          <div className="content-details description-block">
            <h3>About the Website</h3>
            <ul className="custom-list">
              <li>
                Welcome to <img className="small-favicon" src={logo} alt="" />{" "}
                <a
                  href="http://smecashbook.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SMECashBook.com.au
                </a>{" "}
                (the ‘Website’). The Website allows you to use the software to
                manage statutory trust account and ancillary services (the
                ‘Services’).
              </li>
              <li>
                The Website is operated by Myna Australia Pty Ltd, ACN/ABN 54
                657 002 796 . Access to and use of the Website, or any of its
                associated Products or Services, is provided by Myna Australia
                Pty Ltd. Please read these terms and conditions (the ‘Terms’)
                carefully. By using, browsing and/or reading the Website, this
                signifies that you have read, understood and agree to be bound
                by the Terms. If you do not agree with the Terms, you must cease
                usage of the Website, or any of Services, immediately
              </li>
              <li>
                Myna Australia Pty Ltd reserves the right to review and change
                any of the Terms by updating this page at its sole discretion.
                When Myna Australia Pty Ltd updates the Terms, it will use
                reasonable endeavours to provide you with notice of updates to
                the Terms. Any changes to the Terms take immediate effect from
                the date of their publication. Before you continue, we recommend
                you keep a copy of the Terms for your records.
              </li>
              <li>
                <img className="small-favicon" src={logo} alt="" /> SMECashBook,{" "}
                <img className="small-favicon" src={logo} alt="" />{" "}
                <a
                  href="http://smecashbook.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SMECashBook.com.au
                </a>{" "}
                , We, Myna and Myna Australia Pty Ltd will be used
                interchangeably throughout and they all refer to the legal
                entity Myna Australia Pty Ltd, ACN/ABN 54 657 002 796. You imply
                any user you authorise, your business and anyone you provide
                access to visit and use{" "}
                <img className="small-favicon" src={logo} alt="" />{" "}
                <a
                  href="http://smecashbook.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SMECashBook.com.au
                </a>{" "}
              </li>
            </ul>
            <h3>Acceptance of the Terms</h3>
            <p>
              You accept the Terms by remaining on the Website. Where the option
              is available in the user interface, you may also accept the Terms
              by clicking to accept or agree to the Terms.
            </p>
            <h3>About the Service</h3>
            <ul>
              <li>
                <img className="small-favicon" src={logo} alt="" /> SMECashBook
                software from Myna Australia Pty Ltd is cloud based statutory
                trust management software that allows users to manage the trust
                account and provide ancillary services. It is the your
                responsibility to comply with the legislative requirements
                associated with the statutory trust account management and
                audit. Use of software does not imply meeting legislative
                requirements associated with managing the statutory or other
                trust account. Software allows creation of .aba file and it’s up
                to the user to verify the accuracy of the .aba file as Myna
                Australia Pty Ltd takes no responsibility for the loss caused by
                incorrect or corrupt .aba file. It’s the user’s responsibility
                to take the corrective action with the bank if any loss occurs
                due to incorrect .aba file created by the software.
              </li>
              <li>
                Subscription based accounts are currently offered on{" "}
                <img className="small-favicon" src={logo} alt="" />{" "}
                <a
                  href="http://smecashbook.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SMECashBook.com.au
                </a>{" "}
                . You acknowledge and agree that the accounts offered, as well
                as the account features may change from time to time, and may be
                governed by separate terms which apply specific to the account.
                Where special account-specific terms apply, you will be
                informed, and must accept those terms before you are given such
                an account. For the avoidance of doubt these Terms apply unless
                otherwise agreed or amended by account-specific terms. Failing
                to meet these Terms, Myna Australia Pty Ltd may delete all the
                data and settings permanently but not within 30 days of the
                subscription expiry. MYNA Australia Pty Ltd may charge for the
                catchup period upon return.
              </li>
              <li>
                If you are an accountant, financial adviser, Lawyer,
                Conveyancer, real estate agent or any other professional and you
                maintain the Statutory trust account for your client by
                establishing and maintaining a Subscription containing data, it
                is your responsibility to manage your clients and their data.{" "}
                <img className="small-favicon" src={logo} alt="" /> SMECashBook
                will have the ability to produce authority letters and receipts
                for you and verifying its contents and accuracy is your
                responsibility.
              </li>
              <li>
                Subscription options that we provide only includes email support
                as a primary method to resolve your query and{" "}
                <a
                  href="mailto:support@smecashbook.com.au"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@smecashbook.com.au{" "}
                </a>{" "}
                should be used for all the support queries including
                registration queries. From time to time, we may seek help from
                external contractors to provide best possible support. You agree
                to allow us and them access to your data to troubleshoot, or
                problem solve a defect.
              </li>
              <li>
                Some accounts may be governed by a separate Software Licensing
                Agreement with Myna Australia Pty Ltd, which may amend the terms
                of use. For the avoidance of doubt these Terms apply unless
                otherwise agreed or amended by the terms of an applicable
                Software Licensing Agreement.
              </li>
            </ul>
            <h3>Acceptable use of the Service</h3>
            <ul className="custom-list">
              <li>
                TrustEast software from Myna Australia Pty Ltd, its related
                features, and website must only be used lawfully. Myna Australia
                Pty Ltd reserves the right to suspend, cancel, or otherwise deny
                access to users and accounts who use the service:
                <ul className="custom-className">
                  To engage in any act that would disrupt the access,
                  availability, and security of{" "}
                  <img className="small-favicon" src={logo} alt="" />{" "}
                  SMECashBook software and other Myna Australia Pty Ltd
                  services, including but not limited to:
                  <p></p>
                  <li>
                    Tampering with, reverse-engineering, or hacking our servers.
                  </li>
                  <li>
                    Modifying, disabling, or compromising the performance
                    statutory trust account management software or other Myna
                    Australia Pty Ltd services.
                  </li>
                  <li>
                    Overwhelming, or attempting to overwhelm our infrastructure
                    by imposing an unreasonably large load on our systems that
                    consume extraordinary resources.
                  </li>
                  <li>
                    Compromising the integrity of our system, including probing,
                    scanning and testing the vulnerability of our system unless
                    expressly permitted by Myna Australia Pty Ltd.
                  </li>
                </ul>
              </li>
              <li>
                For any illegal purpose, or to violate any laws, including and
                without limitation to data, privacy, and export control laws.
              </li>
              <li>
                To stalk, harass or threaten users and any member of the public.
              </li>
              <li>
                To misrepresent or defraud any user or member of the public
                through phishing, spoofing, manipulating headers or other
                identifiers, impersonating anyone else, or falsely implying any
                sponsorship or association with Myna Australia Pty Ltd or any
                third party
              </li>
              <li>
                To access or search any part of the Service, or any other
                Service owned by Myna Australia Pty Ltd other than our publicly
                supported interface, or otherwise allowed for in an applicable
                Software Licensing Agreement.
              </li>
              <li>
                To post, upload, share, or otherwise circulate content in
                violation of statutory trust account management software’s
                content policy
              </li>
            </ul>
            <h3>Security and Data Privacy</h3>
            <p>
              Myna Australia Pty Ltd takes your privacy seriously and
              information provided through your use of the Website and/or
              Services are subject to Myna Australia Pty Ltd ’ s Privacy Policy,
              which is available on the Website. The Privacy Policy also
              addresses Myna Australia Pty Ltd ’ s processes, policies, and
              obligations in respect of statutory trust account management
              software security breaches.
            </p>
            <h3>Data Use</h3>
            <p>
              Myna Australia Pty Ltd collects, stores, and processes your data
              on <img className="small-favicon" src={logo} alt="" /> SMECashBook
              software platform. The data is used to provide Services to you, as
              well as to facilitate Myna Australia Pty Ltd ’ s business
              operations. The Privacy Policy outlined how your data is
              collected, stored, and processed by Myna Australia Pty Ltd. The
              Privacy Policy also addresses Myna Australia Pty Ltd’s processes,
              policies, and obligations in respect of data encryption and
              removal requests.
            </p>
            <h3>Subscription to use the Service and level of support</h3>
            <ul className="custom-links">
              <li>
                In order to access the Services, you must first purchase a
                subscription through the Website (the ‘Subscription’) and pay
                the applicable fee for the selected Subscription (the
                ‘Subscription Fee’). After purchasing a Subscription, you will
                be considered a member (‘Member’).
              </li>
              <li>
                In purchasing the Subscription, you acknowledge and agree that
                it is your responsibility to ensure that the Subscription you
                elect to purchase is suitable for your use.
              </li>
              <li>
                Before, during or after you have purchased the Subscription, you
                will then be required to register for an account or user through
                the Website before you can access the Services (the ‘ Account’).
                Multi Factor Authentication (MFA) is mandatory to use the
                service and unauthorised sharing of passwords and MFA code is
                strictly prohibited.
              </li>
              <li>
                As part of the registration process, or as part of your
                continued use of the Services, you may be required to provide
                personal information about your business and yourself (such as
                identification or contact details), including but not limited to
                Names, addresses, trust bank and personal bank account details,
                Tax Agent Number, Primary and secondary form of IDs, contact
                details and payment details.
              </li>
              <li>
                You warrant that any information you give to Myna Australia Pty
                Ltd in the course of completing the registration process will
                always be accurate, correct and up to date.
              </li>
              <li>
                Once you have completed the registration process, you will be a
                registered user of the Website and agree to be bound by the
                Terms (“User”). As a Member you will be granted immediate access
                to the Services from the time you have completed the
                registration process until the subscription period expires (the
                ‘Subscription Period’).
              </li>
              <li>
                You may not use the Services and may not accept the Terms if:
                <ul className="-links">
                  <li>
                    you are not of legal age to form a binding contract with
                    Myna Australia Pty Ltd; or
                  </li>
                  <li>you are not an authorised user</li>
                  <li>
                    you are a person barred from receiving the Services under
                    the laws of Australia or other countries including the
                    country in which you are resident or from which you use the
                    services.
                  </li>
                </ul>
              </li>
            </ul>
            <h3>Payments</h3>
            <ul className="custom-list">
              <li>
                Subject to the terms of any applicable Software License
                Agreement, the Subscription Fee may be paid by all payment
                methods available on the Website, and may change from time to
                time.
              </li>
              <li>
                Payments made in the course of your use of{" "}
                <img className="small-favicon" src={logo} alt="" /> SMECashBook
                software from Myna Australia Pty Ltd may be made using
                third-party applications and services not owned, operated, or
                otherwise controlled by Myna Australia Pty Ltd, for eg Stripe.
                You acknowledge and agree that Myna Australia Pty Ltd will not
                be liable for any losses or damage arising from the operations
                of third-party payment applications and services. You further
                acknowledge and warrant that you have read, understood and agree
                to be bound by the terms and conditions of the applicable
                third-party payment applications and services you choose to use
                as a payment method for statutory trust account management
                software services.
              </li>
              <li>
                You acknowledge and agree that where a request for the payment
                of the Subscription Fee is returned or denied, for whatever
                reason, by your financial institution or is unpaid by you for
                any other reason, then you are liable for any costs, including
                banking fees and charges, associated with the Subscription Fee.
              </li>
              <li>
                You agree and acknowledge that Myna Australia Pty Ltd can vary
                the Subscription Fee at any time and that the varied
                Subscription Fee will come into effect following the conclusion
                of the existing Subscription.
              </li>
            </ul>
            <h3>Refund Policy</h3>
            <p>
              Myna Australia Pty Ltd will only provide you with a refund of the
              Subscription Fee in the event they are unable to continue to
              provide the Services or if the manager of Myna Australia Pty Ltd
              makes a decision, at its absolute discretion, that it is
              reasonable to do so under the circumstances . Where this occurs,
              the refund will be in the proportional amount of the Subscription
              Fee that remains unused by the Member (the ‘Refund’).
            </p>

            <h3>Copyright and Intellectual Property</h3>
            <ul className="custom-list">
              <li>
                The Website, the Services and all of the related products of
                Myna Australia Pty Ltd are subject to copyright. The material on
                the Website is protected by copyright under the laws of
                Australia and through international treaties. Unless otherwise
                indicated, all rights (including copyright) in the Services and
                compilation of the Website (including but not limited to text,
                graphics, logos, button icons, video images, audio clips,
                Website, code, scripts, design elements and interactive
                features) or the Services are owned or controlled for these
                purposes, and are reserved by Myna Australia Pty Ltd or its
                contributors.
              </li>
              <li>
                All trademarks, service marks and trade names are owned,
                registered and/or licensed by Myna Australia Pty Ltd, who grants
                to you a worldwide, non-exclusive, royalty-free, revocable
                license whilst you are a User to:
                <ul className="custom-list">
                  <li>use the Website pursuant to the Terms;</li>
                  <li>
                    copy and store the Website and the material contained in the
                    Websitein your device’s cache memory; and
                  </li>
                  <li>
                    print pages from the Website for your own personal and
                    non-commercial use.
                  </li>
                </ul>
              </li>
              <li>
                Myna Australia Pty Ltd does not grant you any other rights
                whatsoever in relation to the Website or the Services. All other
                rights are expressly reserved by Myna Australia Pty Ltd.
              </li>
              <li>
                Myna Australia Pty Ltd retains all rights, title and interest in
                and to the Website and all related Services. Nothing you do on
                or in relation to the Website will transfer any:
                <ul className="custom-list">
                  <li>
                    business name, trading name, domain name, trade mark,
                    industrial design, patent, registered design or copyright,
                    or
                  </li>
                  <li>
                    a right to use or exploit a business name, trading name,
                    domain name, trade mark or industrial design, or
                  </li>
                  <li>
                    a thing, system or process that is the subject of a patent,
                    registered design or copyright (or an adaptation or
                    modification of such a thing, system or process), to you.
                  </li>
                </ul>
              </li>
              <li>
                You may not, without the prior written permission of Myna
                Australia Pty Ltd and the permission of any other relevant
                rights owners: broadcast, republish, up-load to a third party,
                transmit, post, distribute, show or play in public, adapt or
                change in any way the Services or third party Services for any
                purpose, unless otherwise provided by these Terms. This
                prohibition does not extend to materials on the Website, which
                are freely available for re-use or are in the public domain.
              </li>
            </ul>
            <h3>General Disclaimer</h3>
            <ul className="custom-list">
              <li>
                Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </li>
              <li>
                Subject to this clause, and to the extent permitted by law:
                <ul className="custom-list">
                  <li>
                    all terms, guarantees, warranties, representations or
                    conditions which are not expressly stated in the Terms are
                    excluded; and
                  </li>
                  <li>
                    Myna Australia Pty Ltd will not be liable for any special,
                    indirect or consequential loss or damage (unless such loss
                    or damage is reasonably foreseeable resulting from our
                    failure to meet an applicable Consumer Guarantee), loss of
                    profit or opportunity, or damage to goodwill arising out of
                    or in connection with the Services or these Terms (including
                    as a result of not being able to use the Services or the
                    late supply of the Services), whether at common law, under
                    contract, tort (including negligence), in equity, pursuant
                    to statute or otherwise.
                  </li>
                </ul>
              </li>
              <li>
                Use of the Website and the Services is at your own risk.
                Everything on the Website and the Services is provided to you
                “as is” and “as available” without warranty or condition of any
                kind. None of the affiliates, directors, officers, employees,
                agents, contributors and licensors of Myna Australia Pty Ltd
                make any express or implied representation or warranty about the
                Services or any products or Services (including the products or
                Services of Myna Australia Pty Ltd) referred to on the Website,
                includes (but is not restricted to) loss or damage you might
                suffer as a result of any of the following:
                <ul className="custom-list">
                  <li>
                    failure of performance, error, omission, interruption,
                    deletion, defect, failure to correct defects, delay in
                    operation or transmission, computer virus or other harmful
                    component, loss of data, communication line failure,
                    unlawful third party conduct, or theft, destruction,
                    alteration or unauthorised access to records;
                  </li>
                  <li>
                    the accuracy, suitability or currency of any information on
                    the Website, the Services, or any of its Services related
                    products (including third party material and advertisements
                    on the Website);
                  </li>
                  <li>
                    costs incurred as a result of you using the Website, the
                    Services or any of the products of Myna Australia Pty Ltd;
                    and
                  </li>
                  <li>
                    the Services or operation in respect to links which are
                    provided for your convenience.
                  </li>
                </ul>
              </li>
            </ul>
            <h3>Limitation of Liability</h3>
            <ul className="custom-list">
              <li>
                Myna Australia Pty Ltd’s total liability arising out of or in
                connection with the Services or these Terms, however arising,
                including under contract, tort (including negligence), in
                equity, under statute or otherwise, will not exceed the resupply
                of the Services to you.
              </li>
              <li>
                You expressly understand and agree that Myna Australia Pty Ltd,
                its affiliates, employees, agents, contributors and licensors
                shall not be liable to you for any direct, indirect, incidental,
                special consequential or exemplary damages which may be incurred
                by you, however caused and under any theory of liability. This
                shall include, but is not limited to, any loss of profit
                (whether incurred directly or indirectly), any loss of goodwill
                or business reputation and any other intangible loss.
              </li>
            </ul>
            <h3>Termination of Contract</h3>
            <ul className="custom-className">
              <li>
                The Terms will continue to apply until terminated by either you
                or by Myna Australia Pty Ltd as set out below.
              </li>
              <li>
                If you want to terminate the Terms, you may do so by:
                <ul className="custom-className">
                  <li>
                    not renewing the Subscription prior to the end of the
                    Subscription Period;
                  </li>
                  <li>
                    providing Myna Australia Pty Ltd with 30 days’ notice of
                    your intention to terminate; and
                  </li>
                  <li>
                    closing your accounts for all of the services which you use,
                    where Myna Australia Pty Ltd has made this option available
                    to you.
                  </li>
                </ul>
              </li>
              <li>
                Any notices pursuant to Clause 13.2 above should be sent, in
                writing, to Myna Australia Pty Ltd via the ‘Contact Us’ link on
                our homepage.
              </li>
              <li>
                Myna Australia Pty Ltd may at any time, terminate the Terms with
                you if:
                <ul className="custom-list">
                  <li>
                    you do not renew the Subscription at the end of the
                    Subscription Period;
                  </li>
                  <li>
                    you have breached any provision of the Terms or intend to
                    breach any provision;
                  </li>
                  <li>Myna Australia Pty Ltd is required to do so by law;</li>
                  <li>
                    the provision of the Services to you by Myna Australia Pty
                    Ltd is, in the opinion of Myna Australia Pty Ltd, no longer
                    commercially viable.
                  </li>
                </ul>
              </li>
              <li>
                Subject to local applicable laws, Myna Australia Pty Ltd
                reserves the right to discontinue or cancel your Subscription or
                Account at any time and may suspend or deny, in its sole
                discretion, your access to all or any portion of the Website or
                the Services without notice if you breach any provision of the
                Terms or any applicable law or if your conduct impacts Myna
                Australia Pty Ltd’s name or reputation or violates the rights of
                those of another party.
              </li>
            </ul>
            <h3>Indemnity</h3>
            <p>
              You agree to indemnify Myna Australia Pty Ltd, its affiliates,
              employees, agents, contributors, third party content providers and
              licensors from and against:
            </p>
            <ul className="custom-list">
              <li>
                all actions, suits, claims, demands, liabilities, costs,
                expenses, loss and damage (including legal fees on a full
                indemnity basis) incurred, suffered or arising out of or in
                connection with Your Content;
              </li>
              <li>
                any direct or indirect consequences of you accessing, using or
                transacting on the Website or attempts to do so; and/or
              </li>
              <li>any breach of the Terms.</li>
            </ul>
            <h3>Dispute Resolution</h3>
            <ul className="custom-list">
              <li>
                <h5>Compulsory:</h5>
                <p>
                  If a dispute arises out of or relates to the Terms, either
                  party may not commence any Tribunal or Court proceedings in
                  relation to the dispute, unless the following clauses have
                  been complied with (except where urgent interlocutory relief
                  is sought).
                </p>
              </li>
              <li>
                <h5>Notice:</h5>
                <p>
                  A party to the Terms claiming a dispute (‘Dispute’) has arisen
                  under the Terms, must give written notice to the other party
                  detailing the nature of the dispute, the desired outcome and
                  the action required to settle the Dispute.
                </p>
              </li>
              <li>
                <h5>Resolution:</h5>
                <ul className="custom-list">
                  <li>
                    On receipt of that notice (‘Notice’) by that other party,
                    the parties to the Terms (‘Parties’) must:
                  </li>
                  <li>
                    Within 30 days of the Notice endeavour in good faith to
                    resolve the Dispute expeditiously by negotiation or such
                    other means upon which they may mutually agree;
                  </li>
                  <li>
                    If for any reason whatsoever, 90 days after the date of the
                    Notice, the Dispute has not been resolved, the Parties must
                    either agree upon selection of a mediator or request that an
                    appropriate mediator be appointed by the President of the
                    NSW Fair Trading or his or her nominee;
                  </li>
                  <li>
                    The Parties are equally liable for the fees and reasonable
                    expenses of a mediator and the cost of the venue of the
                    mediation and without limiting the foregoing undertake to
                    pay any amounts requested by the mediator as a pre-condition
                    to the mediation commencing. The Parties must each pay their
                    own costs associated with the mediation;
                  </li>
                  <li>The mediation will be held in Sydney, NSW, Australia.</li>
                </ul>
              </li>
            </ul>
            <h3>Confidential</h3>
            <p>
              All communications concerning negotiations made by the Parties
              arising out of and in connection with this dispute resolution
              clause are confidential and to the extent possible, must be
              treated as “without prejudice” negotiations for the purpose of
              applicable laws of evidence.
            </p>
            <h3>Termination of Mediation</h3>
            <p>
              If 180 have elapsed after the start of a mediation of the Dispute
              and the Dispute has not been resolved, either party may ask the
              mediator to terminate the mediation and the mediator must do so.
            </p>
            <h3>Third Party Integration</h3>
            <p>
              &nbsp; &nbsp;By using Bank Feed functionality in the Service you
              agree to Myna Australia Pty Ltd, Basiq and Yodlee accessing third
              party banking sites designated by you, on your behalf, to retrieve
              information requested by you. By setting up a Bank Feed in{" "}
              <img className="small-favicon" src={logo} alt="" />{" "}
              <a
                href="http://smecashbook.com.au/"
                target="_blank"
                rel="noreferrer"
              >
                SMECashBook.com.au
              </a>{" "}
              or myna.com.au (Myna Australia Pty Ltd), you hereby authorise and
              permit Myna Australia Pty Ltd, Basiq and Yodlee to use information
              submitted by you, such as account passwords and users names, to
              configure your Bank Feed. You hereby grant Myna Australia Pty Ltd,
              Basiq and Yodlee a limited power of attorney with full power of
              substitution in all capacities, to access third party sites,
              retrieve information, and use your information, all as described
              above, with the power and authority to do and perform each act
              required to collect banking data, as though you were in person.
              You agree that when Myna Australia Pty Ltd, Basiq or Yodlee are
              accessing and retrieving information from third party sites, they
              are acting as your agent. You hereby agree that Myna Australia Pty
              Ltd, Basiq and Yodlee or any other third-party account providers
              shall be entitled to rely on the authorisation and power of
              attorney granted by you under this term.
            </p>
            <p>
              &nbsp; &nbsp;Myna Australia Pty Ltd is cloud-based software and
              allows integration with third parties using APIs. You are
              responsible to use right credentials and necessary information to
              use third party integrations. Accuracy of data coming via APIs can
              ’ t be guaranteed and it is your responsibility to verify such
              information. Various software provider have their own policies and
              it is your responsibility to abide to their policies to avail this
              integration service. Myna Australia Pty Ltd takes no
              responsibility for any data integrity, data loss or inefficiencies
              arising out of third party integration.{" "}
            </p>
            <h3>Venue and Jurisdiction</h3>
            <p>
              The Services offered by Myna Australia Pty Ltd is intended to be
              viewed by residents of Australia. In the event of any dispute
              arising out of or in relation to the Website, you agree that the
              exclusive venue for resolving any dispute shall be in the courts
              of New South Wales.
            </p>
            <h3>Governing Law</h3>
            <p>
              The Terms are governed by the laws of New South Wales. Any
              dispute, controversy, proceeding or claim of whatever nature
              arising out of or in any way relating to the Terms and the rights
              created hereby shall be governed, interpreted and construed by,
              under and pursuant to the laws of New South Wales, Australia
              without reference to conflict of law principles, notwithstanding
              mandatory rules. The validity of this governing law clause is not
              contested. The Terms shall be binding to the benefit of the
              parties hereto and their successors and assigns.
            </p>
            <h3>Independent Legal Advice</h3>
            <p>
              Both parties confirm and declare that the provisions of the Terms
              are fair and reasonable and both parties having taken the
              opportunity to obtain independent legal advice and declare the
              Terms are not against public policy on the grounds of inequality
              or bargaining power or general grounds of restraint of trade.
            </p>
            <h3>Severance</h3>
            <p>
              If any part of these Terms is found to be void or unenforceable by
              a Court of competent jurisdiction, that part shall be severed and
              the rest of the Terms shall remain in force.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
