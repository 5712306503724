import React from "react";
import moment from "moment";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, get } from "../../../config";
import Spin from "../../common/Spin";

const TransactionDetails = ({ valueRange, businessId }) => {
  const [loading, setLoading] = useState(false);
  const [totalSales, setTotalSales] = useState();
  const [gstOnRevenue, setGstOnRevenue] = useState();
  const [gstOnExpenses, setGstOnExpenses] = useState();
  const [gstFreeExpenses, setGSTFreeExpenses] = useState();
  const [totalGstOnRevenue, setTotalGstOnRevenue] = useState("0.00");
  const [totalGstOnExpenses, setTotalGstOnExpenses] = useState("0.00");
  const [sortField, setSortField] = useState('transaction_date');
  const [sortOrder, setSortOrder] = useState('desc');

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const fetchGSTTransaction = useCallback(async () => {
    if (businessId) {
      try {
        setLoading(true);
        const { data } = await get(
          `${API.GST_TRANSACTION_DETAILS}?client_business_id=${
            businessId ? businessId : ""
          }&start_month=${
            valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
          }&end_month=${
            valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""
          }&sort_column=${sortField ? sortField : ""}&sort_order=${
            sortOrder ? sortOrder : ""
          }`
        );
        setLoading(false);
        setTotalSales(data?.data?.total_sales);
        setGstOnRevenue(data?.data?.gst_on_revenue);
        setGstOnExpenses(data?.data?.gst_on_expenses);
        setGSTFreeExpenses(data?.data?.gst_free_expenses);

        return data.data;
      } catch (e) {
        setLoading(false);
        setTotalSales([]);
        setGstOnRevenue([]);
        setGstOnExpenses([]);
        setGSTFreeExpenses([]);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  }, [businessId, valueRange, sortField, sortOrder]);

  const totalSalesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = totalSales?.data[i]?.transaction_date
      ? totalSales?.data[i]?.transaction_date?.split('-') : ''
      items.push({
        key: totalSales?.data[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${totalSales?.data[i]?.account_coa_name}${
          totalSales?.data[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(totalSales?.data[i]?.account_coa_code), 4) : " (" + totalSales?.data[i]?.account_coa_code
          }${totalSales?.data[i]?.account_coa_sub_code ? "." + totalSales?.data[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: totalSales?.data[i]?.reference
          ? totalSales?.data[i]?.reference
          : "-",
        details: totalSales?.data[i]?.details ? totalSales?.data[i]?.details : "",
        gross: totalSales?.data[i]?.amount
          ? totalSales?.data[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: totalSales?.data[i]?.gst_amount
          ? totalSales?.data[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: totalSales?.data[i]?.net_amount
          ? totalSales?.data[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const totalSalesData = totalSalesGenerator(totalSales?.data?.length);

  const gstRevenueGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstOnRevenue?.data[i]?.transaction_date
      ? gstOnRevenue?.data[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstOnRevenue?.data[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${gstOnRevenue?.data[i]?.account_coa_name}${
          gstOnRevenue?.data[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstOnRevenue?.data[i]?.account_coa_code), 4) : " (" + gstOnRevenue?.data[i]?.account_coa_code
          }${gstOnRevenue?.data[i]?.account_coa_sub_code ? "." + gstOnRevenue?.data[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstOnRevenue?.data[i]?.reference
          ? gstOnRevenue?.data[i]?.reference
          : "-",
        details: gstOnRevenue?.data[i]?.details ? gstOnRevenue?.data[i]?.details : "",
        gross: gstOnRevenue?.data[i]?.amount
          ? gstOnRevenue?.data[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstOnRevenue?.data[i]?.gst_amount
          ? gstOnRevenue?.data[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstOnRevenue?.data[i]?.net_amount
          ? gstOnRevenue?.data[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstRevenueData = gstRevenueGenerator(gstOnRevenue?.data?.length);

  const gstExpensesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstOnExpenses?.data[i]?.transaction_date
      ? gstOnExpenses?.data[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstOnExpenses?.data[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${gstOnExpenses?.data[i]?.account_coa_name}${
          gstOnExpenses?.data[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstOnExpenses?.data[i]?.account_coa_code), 4) : " (" + gstOnExpenses?.data[i]?.account_coa_code
          }${gstOnExpenses?.data[i]?.account_coa_sub_code ? "." + gstOnExpenses?.data[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstOnExpenses?.data[i]?.reference
          ? gstOnExpenses?.data[i]?.reference
          : "-",
        details: gstOnExpenses?.data[i]?.details ? gstOnExpenses?.data[i]?.details : "",
        gross: gstOnExpenses?.data[i]?.amount
          ? gstOnExpenses?.data[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstOnExpenses?.data[i]?.gst_amount
          ? gstOnExpenses?.data[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstOnExpenses?.data[i]?.net_amount
          ? gstOnExpenses?.data[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstExpensesData = gstExpensesGenerator(gstOnExpenses?.data?.length);

  const gstFreeExpensesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let dateData = gstFreeExpenses[i]?.transaction_date
      ? gstFreeExpenses[i]?.transaction_date?.split('-') : ''
      items.push({
        key: gstFreeExpenses[i]?.id,
        transaction_date: dateData
        ? `${dateData[2]}-${dateData[1]}-${dateData[0]}`
        : "-",
        account_coa_name: `${gstFreeExpenses[i]?.account_coa_name}${
          gstFreeExpenses[i]?.account_coa_code?.length < 4
            ? " (" + padWithLeadingZeros(Number(gstFreeExpenses[i]?.account_coa_code), 4) : " (" + gstFreeExpenses[i]?.account_coa_code
          }${gstFreeExpenses[i]?.account_coa_sub_code ? "." + gstFreeExpenses[i]?.account_coa_sub_code + ")" : ")"
        }`,
        reference: gstFreeExpenses[i]?.reference
          ? gstFreeExpenses[i]?.reference
          : "-",
        details: gstFreeExpenses[i]?.details ? gstFreeExpenses[i]?.details : "",
        gross: gstFreeExpenses[i]?.amount
          ? gstFreeExpenses[i]?.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        gst: gstFreeExpenses[i]?.gst_amount
          ? gstFreeExpenses[i]?.gst_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        net: gstFreeExpenses[i]?.net_amount
          ? gstFreeExpenses[i]?.net_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
      });
    }
    return items;
  };

  const gstFreeExpensesData = gstFreeExpensesGenerator(gstFreeExpenses?.length);

  useEffect(() => {
    fetchGSTTransaction();
  }, [fetchGSTTransaction]);

  const columnsForGstOnRevenue = [
    {
      dataField: "transaction_date",
      text: "Date",
      sort: true,
      headerStyle: { width: "130px" },
    },
    {
      dataField: "account_coa_name",
      text: "Account",
      sort: true,
      headerStyle: { width: "25%" },
    },
    {
      dataField: "reference",
      text: "Reference",
      sort: true,
      headerStyle: { width: "18%" },
    },
    {
      dataField: "details",
      text: "Details",
      headerStyle: { width: "30%" },
    },
    {
      dataField: "gross",
      text: "Gross",
      align: "right",
      headerStyle: { width: "10%" },
      headerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "gst",
      text: "GST",
      align: "right",
      headerAlign: (column, colIndex) => "right",
    },
  ];

  useEffect(() => {
    let total = 0;
    gstRevenueData &&
      gstRevenueData?.length > 0 &&
      gstRevenueData?.map((item) => {
        total += item?.gst ? parseFloat(item?.gst?.replace(/,/g, "")) : 0;
        return 0;
      });
    setTotalGstOnRevenue(
      Math.round(total).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }, [gstRevenueData]);

  useEffect(() => {
    let total = 0;
    gstExpensesData &&
      gstExpensesData?.length > 0 &&
      gstExpensesData?.map((item) => {
        total += item?.gst ? parseFloat(item?.gst?.replace(/,/g, "")) : 0;
        return 0;
      });
    setTotalGstOnExpenses(
      Math.round(total).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }, [gstExpensesData]);

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };


  return (
    <>
      {loading && <Spin />}
      <div>
        <div className="row">
          <div className="col-md-6">
            <h5>
              <span className="bas-code">
                {totalSales?.code ? totalSales?.code : "G1"}
              </span>{" "}
              Total sales
            </h5>
          </div>
          <div className="col-md-6" style={{ textAlign: "right" }}>
            <h5>
              {totalSales?.amount
                ? totalSales?.amount.toFixed(2)
                : "0.00"}
            </h5>
          </div>
        </div>

        <h6>GST on Income</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={totalSalesData}
          columns={columnsForGstOnRevenue}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />
      </div>
      <hr style={{ marginTop: "30px" }}/>
      <div>
        <div className="row">
          <div className="col-md-6">
            <h5>
              <span className="bas-code">
                {gstOnRevenue?.code ? gstOnRevenue?.code : "1A"}
              </span>{" "}
              GST on sales
            </h5>
          </div>
          <div className="col-md-6" style={{ textAlign: "right" }}>
            <h5>{totalGstOnRevenue ? totalGstOnRevenue : "0.00"}</h5>
          </div>
        </div>

        <h6>GST on Income</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstRevenueData}
          columns={columnsForGstOnRevenue}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />
      </div>
      <hr style={{ marginTop: "30px" }}/>
      <div>
        <div className="row">
          <div className="col-md-6">
            <h5>
              <span className="bas-code">
                {gstOnExpenses?.code ? gstOnExpenses?.code : "1B"}
              </span>{" "}
              GST on Purchases
            </h5>
          </div>
          <div className="col-md-6" style={{ textAlign: "right" }}>
            <h5>{totalGstOnExpenses ? totalGstOnExpenses : "0.00"}</h5>
          </div>
        </div>

        <h6>GST on Expenses</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstExpensesData}
          columns={columnsForGstOnRevenue}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />
      </div>
      <div>
        <h6>GST Free Expenses</h6>
        <BootstrapTable
          keyField="key"
          remote
          data={gstFreeExpensesData}
          columns={columnsForGstOnRevenue}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />
      </div>
    </>
  );
};

export default TransactionDetails;
