import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { API, get } from '../../../../../config';
import { toast } from 'react-toastify';
import Spin from '../../../../common/Spin';
const AcceptInvoice = () => {
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const status = query.get("status");
  const navigate = useNavigate();

  const token = localStorage.getItem('sme-auth-token');
  const params = useParams();
  const businessId = params?.business_id;
  const invoiceId = params?.invoice_id;
  const status = params?.status
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const statusInvoice = useCallback(async () => {
    try {
      if (businessId) {
        setLoading(true);
        const { data } = await get(`${API.INVOICES_DETAILS}/${invoiceId}/accept/${status}`);
        toast.success(data?.message);
        setMessage(data?.message)
        setLoading(false);
      }
    } catch (e) {
      const { data } = e;
      setLoading(false);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    //eslint-disable-next-line
  }, [businessId, invoiceId]);
  useEffect(() => {
    statusInvoice();
  }, [statusInvoice]);

  const redirectLogin = () => {
    if (token) {
      navigate(`/business/${businessId}/invoices`, {replace: true});
    } else {
      navigate('/');
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="congrats-block">
          {loading ? (
            <Spin />
          ) : (
            <>
              {/* <h3>Congratulations</h3> */}
              {/* <p>{location?.state?.message ? location?.state?.message : ''}</p> */}
              <h3>{Number(status) === 1 ? "Accepted" : Number(status) === 2 ? "Rejected" : 'Something went wrong'}</h3>
              <p>{message}</p>
              <Button className="btn-primary btn" onClick={redirectLogin}>
                {token ? 'Invoices' : 'Login'}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceptInvoice;
