import { faUpload, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import BankTransactionDelete from "./BankTransactionDelete";
import { toast } from "react-toastify";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../../config";
import { Modal } from "react-bootstrap";
import UploadFile from "../../../common/UploadFile";
import CustomTooltip from "../../../common/CustomTooltip";

const BankTransactionActionButton = ({
  id,
  handleDelete,
  bankTransactionData,
  setIsImportLoading,
  loading,
  baseType,
  isReconciled
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [files, setFiles] = useState([]);
  const [showImport, setShowImport] = useState(false);
  // const [showView, setShowView] = useState(false);

  // const toggleShowModal = () => {
  //   setShowView(!showView);
  // };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleOpenModal = () => {
    setShowImport(true);
  };

  const handleClosemodal = () => {
    setShowImport(!showImport);
  };

  const handleImportClose = async () => {
    setFiles([]);
    setIsImportLoading(false);
    setShowImport(!showImport);
  };

  const handleUpload = async () => {
    if (files) {
      setIsImportLoading(true);
      const formData = new FormData();
      // files.forEach((file) => {
      if (files !== undefined) {
        formData.append("comment", "Test");
        formData.append("invoice", files);
        formData.append("_method", "put");
      }
      // });
      const config = { headers: { "Content-Type": "multipart/form-data" } };

      await fileUpload(
        API.UPLOAD_INVOICE_BANK_TRANSACTION + "/" + id,
        formData,
        config
      )
        .then((response) => {
          // console.log("response", response);
          if (response.status === 200) {
            setIsImportLoading(false);
            toast.success(response.data.message);
            handleImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsImportLoading(false);
          toast.error(data?.message);
          toast.error(
            data.errors &&
              data.errors.customer_file &&
              data.errors.customer_file[0]
          );
          toast.error(
            data.errors && data.errors.myna_error && data.errors.myna_error[0]
          );
          var error = {};
          error["myna_error"] =
            "There is some issue with request, please try after some time.";
        });
    }
  };

  const handleDownload = async (id) => {
    try {
      setIsImportLoading(true);

      var a = document.createElement("a");

      a.href = imageDefaultPrefixPath + invoice;

      a.setAttribute("download", invoice);

      // a.setAttribute("target", "_blank");

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      setIsImportLoading(false);
      // }
    } catch (e) {
      setIsImportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const invoice = bankTransactionData && bankTransactionData
    .filter((item) => item.id === id)
    .map((data) => data.invoice)
    .pop();

  return (
    <>
      {baseType === 0 &&
        <>
          <>
            {/* {!invoice ? ( */}

            <>
              <span>
              <CustomTooltip
                text={"Upload Invoice"}
                children={
                  <FontAwesomeIcon
                    onClick={handleOpenModal}
                    icon={faUpload}
                    // title="Upload Invoice"
                    size="1x"
                    data-toggle="modal"
                    // data-target="#undoReconcileItem"
                  />
                }
              />
              </span>
              {isReconciled !== 1 || invoice ?
                <>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                </>
                : null
              }
            </>
            {/* ) : null} */}
          </>
          {invoice ? (
            <>
            <span>
              <CustomTooltip
                text={"Download Invoice"}
                children={
                  <FontAwesomeIcon
                    onClick={() => handleDownload()}
                    icon={faDownload}
                    // title="Download Invoice"
                    size="1x"
                    data-toggle="modal"
                    // data-target="#undoReconcileItem"
                  />
                }
              />
            </span>
            {isReconciled !== 1 ?
            <>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            </>
            : null
            }
          </>
          ) : null}
        </>
       }
       
      {isReconciled !== 1 ? 
        <span>
          <CustomTooltip
            text={"Delete"}
            children={
              <FontAwesomeIcon
                onClick={toggleDeleteModal}
                icon={faTrash}
                size="1x"
                data-toggle="modal"
                data-target="#deleteTransaction"
              />
            }
          />
        </span> : null
      }

      {showDelete && (
        <BankTransactionDelete
          transactionId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}

      <Modal
        size="lg"
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Upload Invoice
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <UploadFile
              isMultiple={true}
              isLoading={loading}
              setFiles={setFiles}
              files={files}
              handleUpload={handleUpload}
              handleCancel={handleClosemodal}
              acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg, text/jpg, image/png"
              ErrorMessage="xls, xlsx, docx, pdf, jpg, jpeg and png"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BankTransactionActionButton;
