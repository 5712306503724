import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API, get, imageDefaultPrefixPath } from '../../../../config';
import BootStrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spin from '../../../common/Spin';
import Pagination from '../../../common/Pagination';
// import BackButton from '../../../../components/Form/BackButton';
import { checkIfFileExists, formatName, formatNumber } from '../../../common/Misc';
import { getBusinessData } from '../../components/BusinessHelper';
import InvoiceActionButton from './components/InvoiceActionButton';
import TitleBar from '../../../common/TitleBar';
// import InvoiceActionButton from "./components/InvoiceActionButton";

const Invoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [invoicesList, setInvoicesList] = useState([]);
  const [invoiceLoading, setInvoicesLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [businessData, setBusinessData] = useState();
  const [loading, setLoading] = useState(false);
  const [lastInvoiceNumber, setLastInvoiceNumber] = useState();
  const [lastCreditNumber, setLastCreditNumber] = useState();

  const [numberOption, setNumberOption] = useState();


  const fetchBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
  }, [businessId]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const handleDelete = () => {
    fetchInvoices();
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const fetchInvoices = useCallback(async () => {
    try {
      setInvoicesLoading(true);
      const { data } = await get(
        `${API.GET_INVOICES}?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${sortField ? sortField : ''}&sort_order=${
          sortOrder ? sortOrder : ''
        }&client_business_id=${businessId ? businessId : ''}`
      );

      setInvoicesLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const invoicesList = data.data.data;
      setInvoicesList(invoicesList);
      let lastNumber = data?.last_invoice_number ? String(data?.last_invoice_number)?.split("I")[1] : ''
      setLastInvoiceNumber(lastNumber ? `I${String(padWithLeadingZeros(Number(lastNumber) + 1, 6))}` : 'I000001');
      let lastCreditNoteNumber = data?.last_credit_number ? String(data?.last_credit_number)?.split("C")[1] : '';
      setLastCreditNumber(lastCreditNoteNumber ? `C${String(padWithLeadingZeros(Number(lastCreditNoteNumber) + 1, 6))}` : 'C000001');
      setNumberOption(data?.number_option);
      return data.data;
    } catch (e) {
      const { data } = e;
      setInvoicesLoading(false);
      setInvoicesList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, sortOrder, sortField, businessId]);

  const invoicesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: invoicesList[i]?.id,
        invoice_number: invoicesList[i]?.invoice_number ? invoicesList[i]?.invoice_number : '',
        invoice_to: formatName(
          invoicesList[i]?.contact?.first_name,
          invoicesList[i]?.contact?.last_name
        ),
        email_to: invoicesList[i]?.email_to ? invoicesList[i]?.email_to : '',
        prepared_by: invoicesList[i]?.prepared_by ? invoicesList[i]?.prepared_by : '',
        date: invoicesList[i]?.date ? invoicesList[i]?.date : '',
        due_date: invoicesList[i]?.due_date ? invoicesList[i]?.due_date : '',
        total_amount_due: invoicesList[i]?.total_amount_due ? `$${formatNumber(Number(invoicesList[i]?.total_amount_due))}` : '$0.00',
        total_payable_amount: invoicesList[i]?.total_payable_amount ? `$${formatNumber(Number(invoicesList[i]?.total_payable_amount))}` : '$0.00',
        source: invoicesList[i]?.quote_id !== null ? 'Quote' : 'Manual',
        action: (
          <InvoiceActionButton
            id={invoicesList[i]?.id}
            invoice={invoicesList[i]}
            handleDelete={handleDelete}s
            status={invoicesList[i]?.status}
            send={invoicesList[i]?.email_sent}
            email={invoicesList[i]?.email_to}
            is_finalized={invoicesList[i]?.is_finalized}
            businessData={businessData}
            numberOption={numberOption}
            fetchInvoices={fetchInvoices}
            lastCreditNumber={lastCreditNumber}
            file={invoicesList[i]?.invoice_file[0]}
            total_amount_due={invoicesList[i]?.total_amount_due ? Number(invoicesList[i]?.total_amount_due) : 0}
            invoice_number={invoicesList[i]?.invoice_number ? invoicesList[i]?.invoice_number : ''}
            invoice_total={invoicesList[i]?.total_payable_amount ? Number(invoicesList[i]?.total_payable_amount) : 0}
          />
        ),
      });
    }
    return items;
  };
        
  const invoices = invoicesGenerator(invoicesList?.length);

  const columns = [
    {
      dataField: 'date',
      text: 'Date'
    },
    {
      dataField: 'due_date',
      text: 'Due Date'
    },
    {
      dataField: 'invoice_number',
      text: 'Invoice Number'
    },
    {
      dataField: 'invoice_to',
      text: 'Invoice To'
    },
    {
      dataField: 'email_to',
      text: 'Email To'
    },
    {
      dataField: 'total_payable_amount',
      text: 'Payable Amount'
    },
    {
      dataField: 'total_amount_due',
      text: 'Amount Outstanding'
    },
    // {
    //   dataField: 'prepared_by',
    //   text: 'Prepared By'
    // }
    {
      dataField: 'source',
      text: 'Source'
    },
    {
      dataField: 'action',
      text: 'Action',
      style: { width: "127px" },
    }
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${sortField ? sortField : ''}&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          {/* <h1 className="page-title">
            <BackButton />
            Invoices
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1> */}
          <TitleBar 
            label={"Invoices"}
            BusinessName={BusinessName}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() => navigate('/business/' + businessId + '/add-invoice', {
                    state: { lastInvoiceNumber: lastInvoiceNumber, numberOption: numberOption }
                  })}
                >
                  Add New
                </Button>
                {/* <Button variant="primary">Import</Button> */}
              </div>
            </div>
            <div className="custom-table">
              {(invoiceLoading || loading) && <Spin />}
              <BootStrapTable
                keyField="key"
                remote
                data={invoices}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
                // expandRow={expandRow}
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
