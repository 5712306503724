import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { API, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import Spin from '../common/Spin';
import { useNavigate } from 'react-router-dom';

export default function CurrentSubscription({setActiveTab, isLoading, subscriptionDetail, getsubscription, afterLogin}) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);

  const handleRedirect = () => {
    setActiveTab("more-packages");
  }

  const cancelSubscription = async () => {
    setCancelSubscriptionLoading(true);
    const formData = new FormData();
    formData.append(['subscription_id'], subscriptionDetail.subscription_id);

    await fileUpload(API.CANCEL_SUBSCRIPTION, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        setCancelSubscriptionLoading(false);
        setShowModal(false);
        getsubscription();
        // localStorage.clear();
      })
      .catch(() => {
        setCancelSubscriptionLoading(false);
        toast.error('Something went wrong');
      });
    // localStorage.clear();
    // navigate('/');
  };

  useEffect(() => {
    if (afterLogin) {
      navigate(`?tab=subscriptions&childTab=current-subscription`, {
        replace: true
      });
    }
  }, [afterLogin, navigate]);


  return (
    <>
    {isLoading ? (
        <Spin />
      ) : (
      <div className='current-subscription'>
        <div className="entry-card full-card">
          <div className="form-box">
            <div className="address-box">
              {subscriptionDetail?.status === undefined ? 
              <>
              <h5>
                You have no Subscription
              </h5>
              </> :
              <>
              <h5 className='subscription-header'>
                Subscription Details
                <span className='subscription-status'>
                  Status:{' '}
                  <i
                      style={{
                        textTransform: 'capitalize',
                        background: '#0078C8',
                        fontSize: '16px',
                        borderRadius: '5px',
                        textShadow: '#000 1px 1px 1px',
                        color: '#FFF',
                        padding: '5px 10px',
                        fontWeight: 'normal',
                        fontStyle: 'normal'
                      }}
                    >
                      {subscriptionDetail?.status === 'trialing'
                        ? 'Free Trial'
                        : subscriptionDetail?.status}
                    </i>
                </span>
              </h5>
              <div className="row col-md-12">

                {/* cancel status */}
                {subscriptionDetail?.status === 'canceled' && (
                  <div className='subscription-cancel'>
                    <div className="col-md-4 subscription-lastday">
                      <p>
                        <label>Last day to access:</label> {subscriptionDetail?.next_payment_date}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <Button type="primary" className="btn-active" onClick={handleRedirect}>
                        Subscribe Now
                      </Button>
                    </div>
                  </div>
                )}
                

                {/* trailing status */}
                {subscriptionDetail?.status === 'trialing' && (
                  <>
                    <div className="col-md-4 ml-5">
                      <p>
                        <label>Plan: </label> {subscriptionDetail?.plan_type}
                      </p>
                      <p>
                        <label>Amount: </label> $ 
                        {subscriptionDetail?.current_plan_price || subscriptionDetail?.currency}{' '}
                        {subscriptionDetail?.plan_time !== ''
                          ? 'per ' + subscriptionDetail?.plan_time
                          : ''}
                      </p>
                      <p style={{ margin: '0' }}>
                        <label>Quantity: </label> {subscriptionDetail?.quantity}
                      </p>
                    </div>
                    <div className="col-md-4 ml-5">
                      <h6>Subscription Period</h6>
                      <div className='subcription-period'>
                        <p>
                          <label>Start Date: </label> {subscriptionDetail?.trial_start}
                        </p>
                        <p style={{ margin: '0' }}>
                          <label>End Date: </label> {subscriptionDetail?.trial_end}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 subscription-btn">
                      <p>
                        <label>Subscription Renewal Date:</label>{' '}
                          {subscriptionDetail?.next_payment_date}
                      </p>
                      <Button
                        size="small"
                        type="primary"
                        className="btn-active"
                        onClick={handleRedirect}
                      >
                        Change Plan
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        className="btn-cancel"
                        loading={cancelSubscriptionLoading}
                        onClick={() => setShowModal(true)}
                      >
                        Cancel Plan
                      </Button>
                    </div>
                  </>
                )}
                

                {/* active status */}
                {subscriptionDetail?.status === 'active' && (
                  <>
                    <div className="col-md-4 ml-5">
                      <p>
                        <label>Plan : </label> {subscriptionDetail?.plan_type}
                      </p>
                      <p>
                        <label>Amount : </label> $ 
                        {subscriptionDetail?.current_plan_price || subscriptionDetail?.currency}{' '}
                        {subscriptionDetail?.plan_time !== ''
                          ? 'per ' + subscriptionDetail?.plan_time
                          : ''}
                      </p>
                      <p>
                        <label>Quantity : </label> {subscriptionDetail?.quantity}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <label>Next Payment Date :</label>{' '}
                          {subscriptionDetail?.next_payment_date}
                      </p>
                      <Button
                        size="small"
                        type="primary"
                        className="btn-active"
                        onClick={handleRedirect}
                      >
                        Change Plan
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        className="btn-cancel"
                        loading={cancelSubscriptionLoading}
                        onClick={() => setShowModal(true)}
                      >
                        Cancel Plan
                      </Button>
                    </div>
                  </>
                )}
                
                {showModal && (
                  <Modal
                    size="lg"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    dialogClassName="modal-50w small-popup review-popup small-review"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header className="mb-0" closeButton>
                      Cancel Subscription
                    </Modal.Header>
                    <Modal.Body>
                      {cancelSubscriptionLoading && <Spin />}
                      <div className="modal-body">
                        Are you sure you want to cancel subscription?
                      </div>
                      <div className="modal-footer">
                        <Button
                          type="Button"
                          className="btn btn-secondary"
                          onClick={() => setShowModal(false)}
                        >
                          No
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          onClick={cancelSubscription}
                        >
                          Yes
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
              </div>
              </>
              }
              
            </div>
          </div>
        </div>
      </div>
      )
    }
    </>
  );
}
