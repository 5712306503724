import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { API, get, imageDefaultPrefixPath } from '../../../../config';
import { checkIfFileExists, formatABN, formatName } from '../../../common/Misc';
import { useParams } from 'react-router-dom';
import { getBusinessData } from '../../components/BusinessHelper';
import { useState } from 'react';
import { printComponent } from '../../../common/print';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spin from '../../../common/Spin';

const ViewReceipt = ({ id, values, businessData, toggleShowModal, showView }) => {
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [terms, setTerms] = useState(14);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  function printFile() {
    return printComponent('print-invoice');
  }

  const fetchReceiptPreviewData = useCallback(async () => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await get(`${API.RECEIPTS_DETAILS}/${id}`);
        setData(data?.data);
        var date1 = data?.data?.invoice?.date?.includes('-')
          ? new Date(
              String(data?.data?.invoice?.date)?.split('-')[2],
              String(data?.data?.invoice?.date)?.split('-')[1] - 1,
              String(data?.data?.invoice?.date)?.split('-')[0]
            )
          : '';
        var date2 = data?.data?.invoice?.due_date?.includes('-')
          ? new Date(
              String(data?.data?.invoice?.due_date)?.split('-')[2],
              String(data?.data?.invoice?.due_date)?.split('-')[1] - 1,
              String(data?.data?.invoice?.due_date)?.split('-')[0]
            )
          : '';
        var Difference_In_Time = date2?.getTime() - date1?.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        setTerms(Difference_In_Days);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [id]);

  useEffect(() => {
    fetchReceiptPreviewData();
  }, [fetchReceiptPreviewData]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        View Receipt
      </Modal.Header>
      <Modal.Body
        style={{
          padding: '15px'
        }}
      >
        {loading ? (
          <Spin />
        ) : (
          <>
            {data?.template_setting?.settings?.receipt_style === 0 ? (
              <>
                <div id="print-invoice">
                  <div
                    className="boxed-invoice"
                    style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
                  >
                    <table style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%' }}>
                            {BusinessLogo && (
                              <img
                                src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                alt=""
                                style={{
                                  maxWidth: '95px',
                                  maxHeight: '80px',
                                  marginRight: '5px'
                                }}
                              />
                            )}{' '}
                            {BusinessName}
                          </td>
                          <td style={{ textAlign: 'end', width: '50%' }}>
                            <p>
                              {BusinessName}
                              {data?.invoice?.office ? (
                                <>
                                  <br />
                                  {data?.invoice?.office?.street
                                    ? data?.invoice?.office?.street
                                    : ''}
                                  <br />
                                  {data?.invoice?.office?.suburb
                                    ? data?.invoice?.office?.suburb?.name
                                    : ''}
                                  {data?.invoice?.office?.suburb && ' '}
                                  {data?.invoice?.office?.state
                                    ? data?.invoice?.office?.state?.short_name
                                      ? data?.invoice?.office?.state?.short_name
                                      : data?.invoice?.office?.state?.name
                                    : ''}
                                  {data?.invoice?.office?.state && ' '}
                                  {data?.invoice?.office?.postal_code
                                    ? data?.invoice?.office?.postal_code
                                    : ''}
                                </>
                              ) : (
                                <>
                                  <br />
                                  {businessData?.street ? businessData?.street : ''}
                                  <br />
                                  {businessData?.suburb ? businessData?.suburb?.name : ''}
                                  {businessData?.suburb && ' '}
                                  {businessData?.state
                                    ? businessData?.state?.short_name
                                      ? businessData?.state?.short_name
                                      : businessData?.state?.name
                                    : ''}
                                  {businessData?.state && ' '}
                                  {businessData?.postal_code ? businessData?.postal_code : ''}
                                </>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr
                      style={{
                        margin: 0,
                        marginTop: '8px',
                        marginBottom: '25px',
                        borderTop: '1px solid black',
                        width: '100%'
                      }}
                    />
                    <table style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}>
                      <tbody>
                        <tr style={{ display: 'flex', gap: '15px' }}>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '15px',
                              height: '100px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-evenly',
                              width: '65%'
                            }}
                          >
                            {data?.template_setting?.settings?.client_option?.non_individual ? (
                              <div>
                                {data?.template_setting?.settings?.client_option?.alternateName
                                  ? data?.invoice?.contact && data?.invoice?.contact?.display_name
                                    ? data?.invoice?.contact?.display_name
                                    : formatName(
                                        data?.invoice?.contact?.first_name,
                                        data?.invoice?.contact?.last_name
                                      )
                                  : data?.invoice?.contact?.business_name
                                  ? data?.invoice?.contact?.business_name
                                  : formatName(
                                      data?.invoice?.contact?.first_name,
                                      data?.invoice?.contact?.last_name
                                    )}
                                <>
                                  {data?.invoice?.contact && (
                                    <>
                                      <p style={{ margin: '0px' }}>
                                        {data?.invoice?.contact?.shipping_street
                                          ? data?.invoice?.contact?.shipping_street
                                          : data?.invoice?.contact?.street
                                          ? data?.invoice?.contact?.street
                                          : ''}
                                      </p>
                                      <p style={{ margin: '0px' }}>
                                        {data?.invoice?.contact?.shipping_suburb
                                          ? data?.invoice?.contact?.shipping_suburb?.name
                                          : data?.invoice?.contact?.suburb
                                          ? data?.invoice?.contact?.suburb?.name
                                          : ''}
                                        {(data?.invoice?.contact?.suburb ||
                                          data?.invoice?.contact?.shipping_suburb) && ' '}
                                        {data?.invoice?.contact?.shipping_state
                                          ? data?.invoice?.contact?.shipping_state?.short_name
                                            ? data?.invoice?.contact?.shipping_state?.short_name
                                            : data?.invoice?.contact?.shipping_state?.name
                                          : data?.invoice?.contact?.state
                                          ? data?.invoice?.contact?.state?.short_name
                                            ? data?.invoice?.contact?.state?.short_name
                                            : data?.invoice?.contact?.state?.name
                                          : ''}
                                        {(data?.invoice?.contact?.state ||
                                          data?.invoice?.contact?.shipping_state) && ' '}
                                        {data?.invoice?.contact?.shipping_postal_code
                                          ? data?.invoice?.contact?.shipping_postal_code
                                          : data?.invoice?.contact?.postal_code
                                          ? data?.invoice?.contact?.postal_code
                                          : ''}
                                      </p>
                                      {/* <p>{data?.invoice?.contact}</p> */}
                                    </>
                                  )}
                                </>
                              </div>
                            ) : (
                              <div>
                                {data?.template_setting?.settings?.client_option?.alternateName ? (
                                  data?.invoice?.contact && data?.invoice?.contact?.display_name ? (
                                    data?.invoice?.contact?.display_name
                                  ) : data?.invoice?.contact?.first_name ? (
                                    <p style={{ margin: '0px' }}>
                                      {data?.invoice?.contact?.first_name +
                                        ' ' +
                                        data?.invoice?.contact?.last_name}
                                    </p>
                                  ) : (
                                    <p style={{ margin: '0px' }}>
                                      {data?.invoice?.contact?.last_name}
                                    </p>
                                  )
                                ) : data?.invoice?.contact && data?.invoice?.contact?.first_name ? (
                                  <p style={{ margin: '0px' }}>
                                    {data?.invoice?.contact?.first_name +
                                      ' ' +
                                      data?.invoice?.contact?.last_name}
                                  </p>
                                ) : (
                                  <p style={{ margin: '0px' }}>
                                    {data?.invoice?.contact?.last_name}
                                  </p>
                                )}
                                <>
                                  {data?.invoice?.contact && (
                                    <>
                                      <p style={{ margin: '0px' }}>
                                        {data?.invoice?.contact?.street
                                          ? data?.invoice?.contact?.street
                                          : data?.invoice?.contact?.shipping_street
                                          ? data?.invoice?.contact?.shipping_street
                                          : ''}
                                      </p>
                                      <p style={{ margin: '0px' }}>
                                        {data?.invoice?.contact?.suburb
                                          ? data?.invoice?.contact?.suburb?.name
                                          : data?.invoice?.contact?.shipping_suburb
                                          ? data?.invoice?.contact?.shipping_suburb?.name
                                          : ''}
                                        {(data?.invoice?.contact?.suburb ||
                                          data?.invoice?.contact?.shipping_suburb) && ' '}
                                        {data?.invoice?.contact?.state
                                          ? data?.invoice?.contact?.state?.short_name
                                            ? data?.invoice?.contact?.state?.short_name
                                            : data?.invoice?.contact?.state?.name
                                          : data?.invoice?.contact?.shipping_state
                                          ? data?.invoice?.contact?.shipping_state?.name
                                          : ''}
                                        {(data?.invoice?.contact?.state ||
                                          data?.invoice?.contact?.shipping_state) && ' '}
                                        {data?.invoice?.contact?.postal_code
                                          ? data?.invoice?.contact?.postal_code
                                          : data?.invoice?.contact?.shipping_postal_code
                                          ? data?.invoice?.contact?.shipping_postal_code
                                          : ''}
                                      </p>
                                      {/* <p>{data?.invoice?.contact}</p> */}
                                    </>
                                  )}
                                </>
                              </div>
                            )}
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '15px',
                              textAlign: 'center',
                              height: '100px',
                              width: '35%'
                            }}
                          >
                            <h6 style={{ margin: '0px', fontSize: '16px' }}>RECEIPT</h6>
                            {/* <p
                              style={{
                                fontWeight: 'bold',
                                margin: '0px',
                                marginBottom: '9px',
                                marginTop: '7px'
                              }}
                            >
                              {data?.receipt_number}
                            </p> */}
                            <p
                              style={{
                                fontWeight: 'bold',
                                margin: '0px',
                                marginBottom: '9px',
                                marginTop: '7px'
                              }}
                            >
                              ABN: {businessData?.abn ? formatABN(businessData?.abn) : ''}
                            </p>
                            <p style={{ margin: '0px', fontWeight: 'bold' }}>
                              Receipt Date: {data?.payment_date}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        border: '1px solid black',
                        fontFamily: 'Poppins',
                        borderSpacing: '0px'
                      }}
                      cellSpacing={0}
                      cellPadding={0}
                    >
                      <thead>
                        <tr>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Invoice Date
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Reference
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Payment Type
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Invoice Total
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderRight: '1px solid black',
                                    borderBottom: '1px solid black',
                                    textAlign: 'inherit',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Amount Paid
                          </th>
                          <th
                            style={
                              data?.template_setting?.settings?.secondary_color
                                ? {
                                    padding: '8px',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: `transparent
                    linear-gradient(
                      180deg,
                      ${data?.template_setting?.settings?.secondary_color} 0%,
                      ${data?.template_setting?.settings?.primary_color} 100%
                    )
                    0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '8px',
                                    borderBottom: '1px solid black',
                                    color: data?.template_setting?.settings?.font_color,
                                    background: data?.template_setting?.settings?.primary_color
                                  }
                            }
                          >
                            Still Owing
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black'
                            }}
                          >
                            {data?.invoice?.date}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black'
                            }}
                          >
                            {data?.invoice?.invoice_number}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black'
                            }}
                          >
                            {data?.payment_type === 'gift_card'
                              ? 'Gift Card'
                              : data?.payment_type === 'ffr'
                              ? 'Fee From Refund'
                              : data?.payment_type.charAt(0).toUpperCase() +
                                data?.payment_type.slice(1)}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black'
                            }}
                          >
                            {data?.invoice?.total_payable_amount
                              ? data?.invoice?.total_payable_amount.toFixed(2)
                              : '0.00'}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black'
                            }}
                          >
                            {data?.payment_amount ? data?.payment_amount.toFixed(2) : '0.00'}
                          </td>
                          <td style={{ padding: '8px', borderBottom: '1px solid black' }}>
                            {data?.amount_due ? data?.amount_due.toFixed(2) : '0.00'}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: '8px',
                              borderRight: '1px solid black',
                              height: '37px'
                            }}
                            colSpan={4}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontWeight: 'bold'
                              }}
                            >
                              {data?.template_setting?.settings?.sub_total_option?.term ? (
                                <p style={{ margin: '0px' }}>
                                  <span>
                                    Terms:{' '}
                                    {terms
                                      ? `${
                                          String(data?.invoice?.date)?.includes('-')
                                            ? terms.toFixed(0) < 0
                                              ? 0
                                              : terms.toFixed(0) - 1
                                            : terms.toFixed(0) < 0
                                            ? 0
                                            : terms.toFixed(0)
                                        } days`
                                      : ''}
                                  </span>
                                </p>
                              ) : (
                                ''
                              )}
                              {data?.template_setting?.settings?.sub_total_option?.dueDate ? (
                                <p style={{ margin: '0px' }}>
                                  <span>Sent Date: {data?.email_date ? data?.email_date : ''}</span>
                                </p>
                              ) : (
                                ''
                              )}
                              {/* {values?.receipt_detail_option?.includes('Amounts') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Amount Outstanding: $</span>
                          </p>
                        ) : (
                          <></>
                        )} */}
                            </div>
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              borderRight: '1px solid black',
                              fontWeight: 'bold'
                            }}
                          >
                            ${data?.payment_amount ? data?.payment_amount.toFixed(2) : '0.00'}
                          </td>
                          <td style={{ padding: '8px', fontWeight: 'bold' }}>
                            ${data?.amount_due ? data?.amount_due.toFixed(2) : '0.00'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontStyle: 'italic',
                              textAlign: 'center'
                            }}
                          >
                            {/* <p style={{ margin: '20px 0px 10px 0px' }}>
                        Please detach the portion below and forward with your payment
                      </p> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                  <button type="button" className="btn btn-primary invoice-btn" onClick={printFile}>
                    PRINT
                  </button>
                </div>
              </>
            ) : (
              <>
                <div id="print-invoice">
                  <div id="boxed-invoice">
                    <table
                      style={{
                        width: '100%',
                        fontFamily: 'Poppins',
                        padding: '15px',
                        fontSize: '12px'
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                              <tbody>
                                <tr>
                                  <td colSpan={3} style={{ textAlign: 'right' }}>
                                    {BusinessLogo && (
                                      <img
                                        src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                        alt=""
                                        style={{
                                          maxWidth: '95px',
                                          maxHeight: '80px',
                                          marginRight: '5px'
                                        }}
                                      />
                                    )}{' '}
                                    {BusinessName}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: '30%' }}>
                                    <h2 style={{ fontSize: '20px', paddingBottom: '12px' }}>
                                      RECEIPT
                                    </h2>
                                    {data?.template_setting?.settings?.client_option
                                      ?.non_individual ? (
                                      <div>
                                        {data?.template_setting?.settings?.client_option
                                          ?.alternateName ? (
                                          data?.invoice?.contact &&
                                          data?.invoice?.contact?.display_name ? (
                                            data?.invoice?.contact?.display_name
                                          ) : data?.invoice?.contact?.first_name ? (
                                            <p style={{ margin: '0px' }}>
                                              {data?.invoice?.contact?.first_name +
                                                ' ' +
                                                data?.invoice?.contact?.last_name}
                                            </p>
                                          ) : (
                                            <p style={{ margin: '0px' }}>
                                              {data?.invoice?.contact?.last_name}
                                            </p>
                                          )
                                        ) : data?.invoice?.contact?.business_name ? (
                                          data?.invoice?.contact?.business_name
                                        ) : data?.invoice?.contact?.first_name ? (
                                          <p style={{ margin: '0px' }}>
                                            {data?.invoice?.contact?.first_name +
                                              ' ' +
                                              data?.invoice?.contact?.last_name}
                                          </p>
                                        ) : (
                                          <p style={{ margin: '0px' }}>
                                            {data?.invoice?.contact?.last_name}
                                          </p>
                                        )}
                                        <>
                                          {data?.invoice?.contact && (
                                            <>
                                              <p style={{ margin: '0px' }}>
                                                {data?.invoice?.contact?.shipping_street
                                                  ? data?.invoice?.contact?.shipping_street
                                                  : data?.invoice?.contact?.street
                                                  ? data?.invoice?.contact?.street
                                                  : ''}
                                              </p>
                                              <p style={{ margin: '0px' }}>
                                                {data?.invoice?.contact?.shipping_suburb
                                                  ? data?.invoice?.contact?.shipping_suburb?.name
                                                  : data?.invoice?.contact?.suburb
                                                  ? data?.invoice?.contact?.suburb?.name
                                                  : ''}
                                                {(data?.invoice?.contact?.suburb ||
                                                  data?.invoice?.contact?.shipping_suburb) && (
                                                  <br />
                                                )}
                                                {data?.invoice?.contact?.shipping_state
                                                  ? data?.invoice?.contact?.shipping_state
                                                      ?.short_name
                                                    ? data?.invoice?.contact?.shipping_state
                                                        ?.short_name
                                                    : data?.invoice?.contact?.shipping_state?.name
                                                  : data?.invoice?.contact?.state
                                                  ? data?.invoice?.contact?.state?.short_name
                                                    ? data?.invoice?.contact?.state?.short_name
                                                    : data?.invoice?.contact?.state?.name
                                                  : ''}
                                                {(data?.invoice?.contact?.state ||
                                                  data?.invoice?.contact?.shipping_state) && <br />}
                                                {data?.invoice?.contact?.shipping_postal_code
                                                  ? data?.invoice?.contact?.shipping_postal_code
                                                  : data?.invoice?.contact?.postal_code
                                                  ? data?.invoice?.contact?.postal_code
                                                  : ''}
                                              </p>
                                              {/* <p>{data?.invoice?.contact}</p> */}
                                            </>
                                          )}
                                        </>
                                      </div>
                                    ) : (
                                      <div>
                                        {data?.template_setting?.settings?.client_option
                                          ?.alternateName ? (
                                          data?.invoice?.contact &&
                                          data?.invoice?.contact?.display_name ? (
                                            data?.invoice?.contact?.display_name
                                          ) : data?.invoice?.contact?.first_name ? (
                                            <p style={{ margin: '0px' }}>
                                              {data?.invoice?.contact?.first_name +
                                                ' ' +
                                                data?.invoice?.contact?.last_name}
                                            </p>
                                          ) : (
                                            <p style={{ margin: '0px' }}>
                                              {data?.invoice?.contact?.last_name}
                                            </p>
                                          )
                                        ) : data?.invoice?.contact &&
                                          data?.invoice?.contact?.first_name ? (
                                          <p style={{ margin: '0px' }}>
                                            {data?.invoice?.contact?.first_name +
                                              ' ' +
                                              data?.invoice?.contact?.last_name}
                                          </p>
                                        ) : (
                                          <p style={{ margin: '0px' }}>
                                            {data?.invoice?.contact?.last_name}
                                          </p>
                                        )}
                                        <>
                                          {data?.invoice?.contact && (
                                            <>
                                              <p style={{ margin: '0px' }}>
                                                {data?.invoice?.contact?.street
                                                  ? data?.invoice?.contact?.street
                                                  : data?.invoice?.contact?.shipping_street
                                                  ? data?.invoice?.contact?.shipping_street
                                                  : ''}
                                              </p>
                                              <p style={{ margin: '0px' }}>
                                                {data?.invoice?.contact?.suburb
                                                  ? data?.invoice?.contact?.suburb?.name
                                                  : data?.invoice?.contact?.shipping_suburb
                                                  ? data?.invoice?.contact?.shipping_suburb?.name
                                                  : ''}
                                                {(data?.invoice?.contact?.suburb ||
                                                  data?.invoice?.contact?.shipping_suburb) && (
                                                  <br />
                                                )}
                                                {data?.invoice?.contact?.state
                                                  ? data?.invoice?.contact?.state?.short_name
                                                    ? data?.invoice?.contact?.state?.short_name
                                                    : data?.invoice?.contact?.state?.name
                                                  : data?.invoice?.contact?.shipping_state
                                                  ? data?.invoice?.contact?.shipping_state?.name
                                                  : ''}
                                                {(data?.invoice?.contact?.state ||
                                                  data?.invoice?.contact?.shipping_state) && <br />}
                                                {data?.invoice?.contact?.postal_code
                                                  ? data?.invoice?.contact?.postal_code
                                                  : data?.invoice?.contact?.shipping_postal_code
                                                  ? data?.invoice?.contact?.shipping_postal_code
                                                  : ''}
                                              </p>
                                              {/* <p>{data?.invoice?.contact}</p> */}
                                            </>
                                          )}
                                        </>
                                      </div>
                                    )}
                                  </td>
                                  <td style={{ width: '25%' }}></td>
                                  <td style={{ width: '45%', textAlign: 'right' }}>
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      style={{ width: '100%' }}
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            verticalAlign: 'top',
                                            textAlign: 'left',
                                            paddingRight: '15px'
                                          }}
                                        >
                                          <p>
                                            <strong>Payment Date</strong>
                                            <br />
                                            {data?.payment_date}
                                          </p>
                                          {data?.template_setting?.settings?.sub_total_option
                                            ?.dueDate ? (
                                            <p>
                                              <strong>Sent Date</strong>
                                              <br />
                                              {data?.email_date ? data?.email_date : ''}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                          <p>
                                            <strong>ABN</strong>
                                            <br />
                                            {businessData?.abn ? formatABN(businessData?.abn) : ''}
                                          </p>
                                        </td>
                                        <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                          <div>
                                            {BusinessName}
                                            {data?.invoice?.office ? (
                                              <>
                                                <br />
                                                {data?.invoice?.office?.street
                                                  ? data?.invoice?.office?.street
                                                  : ''}
                                                <br />
                                                {data?.invoice?.office?.suburb
                                                  ? data?.invoice?.office?.suburb?.name
                                                  : ''}
                                                {data?.invoice?.office?.suburb && ' '}
                                                {data?.invoice?.office?.state
                                                  ? data?.invoice?.office?.state?.short_name
                                                    ? data?.invoice?.office?.state?.short_name
                                                    : data?.invoice?.office?.state?.name
                                                  : ''}
                                                {data?.invoice?.office?.state && ' '}
                                                {data?.invoice?.office?.postal_code
                                                  ? data?.invoice?.office?.postal_code
                                                  : ''}
                                              </>
                                            ) : (
                                              <>
                                                <br />
                                                {businessData?.street ? businessData?.street : ''}
                                                <br />
                                                {businessData?.suburb
                                                  ? businessData?.suburb?.name
                                                  : ''}
                                                {businessData?.suburb && ' '}
                                                {businessData?.state
                                                  ? businessData?.state?.short_name
                                                    ? businessData?.state?.short_name
                                                    : businessData?.state?.name
                                                  : ''}
                                                {businessData?.state && ' '}
                                                {businessData?.postal_code
                                                  ? businessData?.postal_code
                                                  : ''}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {data?.template_setting?.settings?.quote_detail_option?.amount ? (
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                style={{ width: '100%', fontFamily: 'Poppins', marginTop: '55px' }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                    <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                    <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                    <td
                                      style={{
                                        padding: '5px',
                                        borderTop: '1px solid #000',
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        borderBottom: '1px solid #000',
                                        width: '18%'
                                      }}
                                    >
                                      Total AUD paid
                                    </td>
                                    <td
                                      style={{
                                        padding: '5px',
                                        borderTop: '1px solid #000',
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        borderBottom: '1px solid #000',
                                        width: '18%'
                                      }}
                                    >
                                      $
                                      {/* {data?.invoice?.total_paid_amount
                                        ? data?.invoice?.total_paid_amount.toFixed(2)
                                        : '0.00'} */}
                                      {data?.payment_amount
                                        ? data?.payment_amount.toFixed(2)
                                        : '0.00'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            ) : (
                              <></>
                            )}

                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style={{ width: '100%', fontFamily: 'Poppins', marginTop: '55px' }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                            textAlign: 'left'
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color,
                                            textAlign: 'left'
                                          }
                                    }
                                  >
                                    Invoice Date
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                            textAlign: 'right'
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color,
                                            textAlign: 'right'
                                          }
                                    }
                                  >
                                    Reference
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                            textAlign: 'right'
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color,
                                            textAlign: 'right'
                                          }
                                    }
                                  >
                                    Payment Type
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                            textAlign: 'right'
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color,
                                            textAlign: 'right'
                                          }
                                    }
                                  >
                                    Invoice Total
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                            textAlign: 'right'
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color,
                                            textAlign: 'right'
                                          }
                                    }
                                  >
                                    Amount Paid
                                  </th>
                                  <th
                                    style={
                                      data?.template_setting?.settings?.secondary_color
                                        ? {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background: `transparent
                        linear-gradient(
                          180deg,
                          ${data?.template_setting?.settings?.secondary_color} 0%,
                          ${data?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                            textAlign: 'right'
                                          }
                                        : {
                                            padding: '5px',
                                            borderBottom: '1px solid #000',
                                            color: data?.template_setting?.settings?.font_color,
                                            background:
                                              data?.template_setting?.settings?.primary_color,
                                            textAlign: 'right'
                                          }
                                    }
                                  >
                                    Still Owing
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      padding: '5px',
                                      textAlign: 'left',
                                      borderBottom: '1px solid #ced4da'
                                    }}
                                  >
                                    {data?.invoice?.date}
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderBottom: '1px solid #ced4da',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {data?.invoice?.invoice_number}
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderBottom: '1px solid #ced4da',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {data?.payment_type === 'gift_card'
                                      ? 'Gift Card'
                                      : data?.payment_type === 'ffr'
                                      ? 'Fee From Refund'
                                      : data?.payment_type.charAt(0).toUpperCase() +
                                        data?.payment_type.slice(1)}
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderBottom: '1px solid #ced4da',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {data?.invoice?.total_payable_amount
                                      ? data?.invoice?.total_payable_amount.toFixed(2)
                                      : '0.00'}
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderBottom: '1px solid #ced4da',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {data?.payment_amount
                                      ? data?.payment_amount.toFixed(2)
                                      : '0.00'}
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderBottom: '1px solid #ced4da',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {data?.amount_due ? data?.amount_due.toFixed(2) : '0.00'}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderTop: '1px solid #000',
                                      textAlign: 'right',
                                      fontWeight: 'bold',
                                      borderBottom: '1px solid #000'
                                    }}
                                  >
                                    Total AUD
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderTop: '1px solid #000',
                                      textAlign: 'right',
                                      fontWeight: 'bold',
                                      borderBottom: '1px solid #000'
                                    }}
                                  >
                                    {data?.payment_amount
                                      ? data?.payment_amount.toFixed(2)
                                      : '0.00'}
                                  </td>
                                  <td
                                    style={{
                                      padding: '5px',
                                      borderTop: '1px solid #000',
                                      textAlign: 'right',
                                      fontWeight: 'bold',
                                      borderBottom: '1px solid #000'
                                    }}
                                  >
                                    {data?.amount_due ? data?.amount_due.toFixed(2) : '0.00'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                  <button type="button" className="btn btn-primary invoice-btn" onClick={printFile}>
                    PRINT
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewReceipt;
