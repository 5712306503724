import React, { useRef } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { Modal } from "react-bootstrap";
import { useBusinessList, useOfficeList, useRoleList } from "./components/UserHelper";
import { useState } from "react";
import Spin from "../common/Spin";
import { API, fileUpload } from "../../config";
import { toast } from "react-toastify";
import { AddUserSchema } from "../common/Validation";
import { formatedAddress } from "../common/Misc";
import { useParams } from "react-router-dom";

const AddUser = ({ toggleUserModal, showUser, clientId, businessSettings = false, clientAdd = false }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const params = useParams();

  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  //Office List
  const { officeLoading, officeList = [] } = useOfficeList(clientId ? clientId  : '');
  const officeListing = officeList?.map((item) => {
    const name = formatedAddress(item?.street, item?.suburb?.name, item?.state?.short_name, item?.postal_code);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  // const office = officeListing?.find(
  //   (item) =>
  //     item?.value ===
  //     (userData?.office_id ? userData?.office_id : userData?.staff?.office_id)
  // );

  const { roleLoading, roleList = [] } = useRoleList();
  const roleListing = roleList?.map((item) => {
    const name = item?.name;
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  const role = roleListing?.find((item) => item?.value === userData?.role_id);

  //Business List
  const { businessLoading, businessList = [] } = useBusinessList();
  const businessListing = businessList?.map((item) => {
    const name = item?.business_name;
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.role_id = value?.role_id?.value ? value?.role_id?.value : "";
    // value.office_id = value?.office_id?.value ? value?.office_id?.value : "";
    value.is_admin_user = value?.is_admin_user === true ? 1 : 0;

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append("role_id", value?.role_id ? value?.role_id : "");
    if(selectedOffices){
      if(selectedOffices.length > 0){
        for(let i = 0; i < selectedOffices.length; i++){
          formData.append(`office_ids[${i}]`, selectedOffices[i]);
        }
      }
    }
    if(selectedBusinesses){
      if(selectedBusinesses.length > 0){
        for(let i = 0; i < selectedBusinesses.length; i++){
          formData.append(`business_ids[${i}]`, selectedBusinesses[i]);
        }
      }
    }
    if(businessSettings && clientAdd === false) {
      const businessId = params?.business_id;
      formData.append(`business_ids[${0}]`, businessId);
    }
    // formData.append("office_id", value?.office_id ? value?.office_id : "");
    formData.append("is_admin_user", value?.is_admin_user);
    
    if(clientId){
      formData.append("client_id", clientId);
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(`${clientId ? API.ADD_CLIENT_USER : API.USER}`, formData);
      setLoading(false);
      if (data) {
        if(data.errorType === "info"){
          toast.warn(data?.message, {autoClose: 6000});
          setSelectedBusinesses([]);
          setSelectedOffices([])
        } else {
          toast.success(data?.message);
          setSelectedBusinesses([]);
          setSelectedOffices([])
        }
        toggleUserModal();
      }
      return data?.data;
    } catch (error) {
      setUserData(value);
      setSelectedBusinesses([]);
      setSelectedOffices([])
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showUser}
        onHide={toggleUserModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New User
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              first_name: userData?.first_name ? userData?.first_name : "",
              last_name: userData?.last_name ? userData?.last_name : "",
              email: userData?.email ? userData?.email : "",
              role_id: role,
              // office_id: office,
              office_ids: [],
              business_ids: [],
              is_admin_user: userData?.is_admin_user
                ? userData?.is_admin_user
                : userData?.staff?.is_admin_user
                ? userData?.staff?.is_admin_user === 1
                  ? true
                  : false
                : false,
            }}
            // enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddUserSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading || officeLoading || businessLoading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleUserModal={toggleUserModal}
                    officeListing={officeListing}
                    officeLoading={officeLoading}
                    businessLoading={businessLoading}
                    businessListing={businessListing}
                    roleListing={roleListing}
                    roleLoading={roleLoading}
                    selectedOffices={selectedOffices}
                    setSelectedOffices={setSelectedOffices}
                    selectedBusinesses={selectedBusinesses}
                    setSelectedBusinesses={setSelectedBusinesses}
                    clientId={clientId}
                    businessSettings={businessSettings}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUser;
