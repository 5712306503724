import { Field } from 'formik';
import React from 'react';
import Select from 'react-select';

const PaymentOptions = ({ values, setFieldValue }) => {
  const paymentTypeList = [
    ...values?.payment_option.map((item) => {
      return {
        label: item === 'Gift' ? 'Gift Card' : item === 'FeeFromRefund' ? 'Fee From Refund' : item,
        value:
          item === 'Card'
            ? 'card'
            : item === 'Cheque'
            ? 'cheque'
            : item === 'ffr'
            ? 'FeeFromRefund'
            : item === 'Gift'
            ? 'gift_card'
            : item === 'Amex'
            ? 'amex'
            : item === 'BPay'
            ? 'bpay'
            : item === 'PayID'
            ? 'payId'
            : ''
      };
    })
  ];
  return (
    <div className="settings-box payment-option-box">
      <div id="checkbox-group" className="settings-heading">
        Payment Type Options
      </div>
      <div role="group" aria-labelledby="checkbox-group" className="payment-option-body">
        {/* <label>
              <Field type="checkbox" name="payment_option" value="Note" />
              Note
            </label>
            <label>
              <Field type="checkbox" name="payment_option" value="Coin" />
              Coins
            </label> */}
        <label>
          <Field type="checkbox" name="payment_option" value="Card" />
          Card
        </label>
        <label>
          <Field type="checkbox" name="payment_option" value="Cheque" />
          Cheque
        </label>
        {/* <label>
              <Field type="checkbox" name="payment_option" value="EFT" />
              EFT
            </label> */}
        <label>
          <Field type="checkbox" name="payment_option" value="FeeFromRefund" />
          Fee From Refund
        </label>
        <label>
          <Field type="checkbox" name="payment_option" value="Gift" />
          Gift Card/Fully Discounted
        </label>
        <label>
          <Field type="checkbox" name="payment_option" value="Amex" />
          Amex
        </label>
        <label>
          <Field type="checkbox" name="payment_option" value="BPay" />
          BPay
        </label>
        <label>
          <Field type="checkbox" name="payment_option" value="PayID" />
          PayID
        </label>
      </div>
      <div className="form-group">
        <span className="invoice-title">Default Payment Type :</span>
        <Select
          name="payment_type_default"
          onChange={(e) => {
            setFieldValue(`payment_type_default`, e?.value);
          }}
          value={
            values?.payment_type_default &&
            paymentTypeList?.length > 0 &&
            paymentTypeList?.find((val) => val?.value === values?.payment_type_default)
          }
          options={paymentTypeList}
          placeholder="Select Default Payment Type"
          classNamePrefix="selectbox"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: 'grey'
            }
          })}
        />
      </div>
    </div>
  );
};

export default PaymentOptions;
