import { useCallback, useEffect, useState } from "react";
import { API, get } from "../../config";

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};

export const useSuburbList = () => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState) {
        setSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}?state=${selectedState}`
        );
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
      setSuburbLoading(false);
    }
  }, [selectedState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSuburbList([]);
      setSelectedState(value);
    },
    suburbLoading,
    setSuburbList,
    selectedState,
  };
};
