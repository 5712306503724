import React, { useCallback, useEffect, useState } from 'react';
import { API, fileUpload, get, imageDefaultPrefixPath } from '../../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../../components/Form/BackButton';
import { checkIfFileExists } from '../../../common/Misc';
import { getBusinessData } from '../../components/BusinessHelper';
import { Formik } from 'formik';
import QuotePreview from './components/QuotePreview';
import QuoteLayoutSettingsTab from './components/QuoteLayoutSettingsTab';
import { Button } from 'react-bootstrap';
import Spin from '../../../common/Spin';
import { toast } from 'react-toastify';

const QuoteLayoutSettings = () => {
  const pickerTypeList = [
    {
      label: 'Plain',
      value: 0
    },
    {
      label: 'Single',
      value: 1
    },
    {
      label: 'Multiple',
      value: 2
    }
  ];
  const params = useParams();
  const businessId = params?.business_id;
  const settingId = params?.id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [loading, setLoading] = useState(false);
  const [initalInvoiceSettings, setInitalInvoiceSettings] = useState();
  const [clientOptionInital, setClientOptionInital] = useState();
  const [invoiceDetailOptionInital, setInvoiceDetailOptionInital] = useState();
  const [subTotalOptionInital, setSubTotalOptionInital] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    let settings = {
      accountName: values?.accountName ? values?.accountName : '',
      accountNumber: values?.accountNumber ? values?.accountNumber : '',
      picker_type: values?.picker_type ? values?.picker_type?.value : pickerTypeList[0]?.value,
      primary_color: values?.primary_color ? values?.primary_color : '',
      secondary_color: values?.secondary_color ? values?.secondary_color : '',
      font_color: values?.font_color ? values?.font_color : '#000000',
      bpay_code: values?.bpayCode ? values?.bpayCode : '',
      bsb: values?.bsb ? values?.bsb?.split('-').join('') : '',
      gst_option: values?.gst_option ? parseInt(values?.gst_option) : '',
      quote_style: values?.quote_style ? parseInt(values?.quote_style) : '',
      payID_mobile: values?.mobile ? values?.mobile : '',
      name: values?.name ? values?.name : '',
      payID_email: values?.email ? values?.email : '',
      footerText: values?.footerText ? values?.footerText : '',
      sub_total_format_option: values?.sub_total_format_option
        ? parseInt(values?.sub_total_format_option)
        : '',
      client_option: {
        alternateName: values?.client_option?.includes('AlternateName') ? 1 : 0,
        customer_ref: values?.client_option?.includes('CustomerRef') ? 1 : 0,
        non_individual: values?.client_option?.includes('Contact') ? 1 : 0
      },
      quote_detail_option: {
        amount: values?.quote_detail_option?.includes('Amounts') ? 1 : 0
      },
      sub_total_option: {
        expiryDate: values?.sub_total_option?.includes('ExpiryDate') ? 1 : 0,
        term: values?.sub_total_option?.includes('Term') ? 1 : 0
      }
    };

    formData.append('client_business_id', businessId);
    formData.append('type', 'quote');
    formData.append('settings', JSON.stringify(settings));
    if (settingId) {
      formData.append('_method', 'put');
    }
    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.INVOICE_SETTINGS}${settingId ? '/' + settingId : ''}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        // navigate(
        //   `/business/${businessId}/template-settings?tab=quote&limit=10&page=1&sort_column=&sort_order=`
        // );
        navigate(`/business/${businessId}/invoicing/settings?tab=quote&childTab=layout`);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const getInvoiceTemplateSettings = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await get(`${API.INVOICE_SETTINGS}/${id}`);
      let intialData = data?.data?.settings;
      let clientOptions = [];
      if (intialData?.client_option?.alternateName) {
        clientOptions.push('AlternateName');
      }
      if (intialData?.client_option?.customer_ref) {
        clientOptions.push('CustomerRef');
      }
      if (intialData?.client_option?.non_individual) {
        clientOptions.push('Contact');
      }

      let invoiceDetailOption = [];
      if (intialData?.quote_detail_option?.amount) {
        invoiceDetailOption.push('Amounts');
      }

      let subTotalOption = [];
      if (intialData?.sub_total_option?.expiryDate) {
        subTotalOption.push('ExpiryDate');
      }
      if (intialData?.sub_total_option?.term) {
        subTotalOption.push('Term');
      }

      setInitalInvoiceSettings(data?.data?.settings);
      setClientOptionInital(clientOptions);
      setInvoiceDetailOptionInital(invoiceDetailOption);
      setSubTotalOptionInital(subTotalOption);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (settingId) {
      getInvoiceTemplateSettings(settingId);
    }
  }, [getInvoiceTemplateSettings, settingId]);

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Quote Layout Settings
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1>
          <div className="content-details invoice-layout-page">
            <div className="table-top-btn"></div>
            <div className="custom-table tab-table">
              <Formik
                initialValues={{
                  name: initalInvoiceSettings?.name ? initalInvoiceSettings?.name : '',
                  quote_style: initalInvoiceSettings?.quote_style === 1 ? '1' : '0',
                  client_option: clientOptionInital,
                  picker_type: initalInvoiceSettings?.picker_type ? pickerTypeList?.find((i) => i.value === initalInvoiceSettings?.picker_type) : pickerTypeList[0],
                  primary_color: initalInvoiceSettings?.primary_color ? initalInvoiceSettings?.primary_color : '#FFFFFF',
                  secondary_color: initalInvoiceSettings?.secondary_color ? initalInvoiceSettings?.secondary_color : '',
                  font_color: initalInvoiceSettings?.font_color ? initalInvoiceSettings?.font_color : '#000000',
                  quote_detail_option: invoiceDetailOptionInital
                    ? invoiceDetailOptionInital
                    : ['Amounts'],
                  gst_option: initalInvoiceSettings?.gst_option === 1 ? '1' : '0',
                  sub_total_format_option:
                    initalInvoiceSettings?.sub_total_format_option === 1 ? '1' : '0',
                  sub_total_option: subTotalOptionInital
                    ? subTotalOptionInital
                    : ['ExpiryDate', 'Term']
                }}
                enableReinitialize
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        className="entry-section invoice-settings-form"
                      >
                        <div className="form-box">
                          <div className="row">
                            <div className="col-md-6 form-detail-footer-body">
                              <QuoteLayoutSettingsTab
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                pickerTypeList={pickerTypeList}
                                setFieldValue={setFieldValue}
                              />
                              <div className="settings-button-body">
                                <Button type="submit">Save</Button>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="invoice-preview-layout-body">
                                <QuotePreview type={values?.quote_style} values={values} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteLayoutSettings;
