import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { UseColorPicker } from '../../../../common/CustomColorPicker';
import ReactSelect from 'react-select';

const ReceiptDetailsSetting = ({
  values,
  handleChange,
  handleBlur,
  pickerTypeList,
  setFieldValue
}) => {
  const Picker1 = UseColorPicker(values?.primary_color, setFieldValue, 'primary_color');
  const Picker2 = UseColorPicker(values?.secondary_color, setFieldValue, 'secondary_color');
  const FontPicker = UseColorPicker(values?.font_color, setFieldValue, 'font_color');

  return (
    <>
      <div className="main-setting-heading">Detail Settings</div>
      <div className="invoice-layout-detail-setings">
        <div className="row">
          <div
            className="col-md-6"
            style={{
              marginTop: '16px'
            }}
          >
            <div className="form-group settings-box">
              <div className="settings-heading">Layout Name</div>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={values?.name ? values?.name : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="name" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="form-group settings-box"
              style={{
                marginTop: '16px'
              }}
            >
              <div id="my-radio-group" className="settings-heading">
                Receipt Style
              </div>
              <div role="group" aria-labelledby="my-radio-group" className="number-option-body">
                <label>
                  <Field type="radio" name="receipt_style" value="0" />
                  Boxed Style
                </label>
                <label>
                  <Field type="radio" name="receipt_style" value="1" />
                  Letter Style
                </label>
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="receipt_style" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="form-group settings-box color-picker-box"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginTop: '16px'
              }}
            >
              <div id="my-radio-group" className="settings-heading">
                Color Style
              </div>
              <div className="row" style={{ display: 'flex', flexWrap: 'wrap', rowGap: '10px' }}>
                <div style={{ marginBottom: '10px' }} className="col-lg-3">
                  <label>Picker Type</label>
                  <ReactSelect
                    name="picker_type"
                    value={values?.picker_type ? values?.picker_type : ''}
                    onChange={(e) => {
                      setFieldValue(`picker_type`, e);
                      Picker2.setColorHex('');
                      setFieldValue(`secondary_color`, '');
                      if (e?.value === 0) {
                        Picker1.setColorHex('');
                        setFieldValue(`primary_color`, '');
                      }
                    }}
                    onBlur={handleBlur}
                    options={pickerTypeList}
                    placeholder="Select Type"
                    classNamePrefix="selectbox"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'grey'
                      }
                    })}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                </div>
                <div className={`setting-color-picker col-lg-3`}>
                  <label>
                    Font Color:{' '}
                    <span>{`(${FontPicker.hexString ? FontPicker.hexString : '#000000'})`}</span>
                  </label>
                  {FontPicker.Picker}
                </div>
                {values?.picker_type?.value !== 0 && (
                  <>
                    <div className={`setting-color-picker col-lg-3`}>
                      <label>
                        Primary Color:{' '}
                        <span>{`(${Picker1.hexString ? Picker1.hexString : '#ffffff'})`}</span>
                      </label>
                      {Picker1.Picker}
                    </div>
                    {values?.picker_type?.value === 2 && (
                      <div className="setting-color-picker col-lg-3">
                        <label>
                          Secondary Color:{' '}
                          <span>{`(${Picker2.hexString ? Picker2.hexString : '#ffffff00'})`}</span>
                        </label>
                        {Picker2.Picker}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="settings-box payment-option-box">
              <div id="checkbox-group" className="settings-heading">
                Client Options
              </div>
              <div role="group" aria-labelledby="checkbox-group" className="client-option-body">
                <label>
                  <Field type="checkbox" name="client_option" value="AlternateName" />
                  Display Alternate Name
                </label>
                {/* <label>
                <Field type="checkbox" name="client_option" value="ClientRef" />
                Display Client Ref.
              </label> */}
                <label>
                  <Field type="checkbox" name="client_option" value="Contact" />
                  Display Contact for Non-individual
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="settings-box payment-option-box">
              <div id="invoice-detail-group" className="settings-heading">
                Receipt Detail
              </div>
              <div
                role="group"
                aria-labelledby="invoice-detail-group"
                className="invoice-detail-option-body"
              >
                <label>
                  <Field type="checkbox" name="receipt_detail_option" value="Amounts" />
                  Display Amounts
                </label>
                {/* <label>
                <Field type="checkbox" name="receipt_detail_option" value="WIP" />
                Display WIP Details
              </label> */}
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="receipt_detail_option" />
                </span>
              </div>
              {/* <div>
              <div id="gst-group" className="settings-heading">
                Display GST
              </div>
              <div role="group" aria-labelledby="gst-group" className="number-option-body">
                <label>
                  <Field type="radio" name="gst_option" value="0" />
                  Inclusive
                </label>
                <label>
                  <Field type="radio" name="gst_option" value="1" />
                  Exclusive
                </label>
                <label>
                  <Field type="radio" name="gst_option" value="2" />
                  None
                </label>
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="gst_option" />
                </span>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="settings-box payment-option-box">
              <div id="sub-total-group" className="settings-heading">
                Sub Total
              </div>
              <div
                role="group"
                aria-labelledby="sub-total-group"
                className="invoice-detail-option-body"
              >
                <label>
                  <Field type="checkbox" name="sub_total_option" value="DueDate" />
                  Show Due Date
                </label>
                <label>
                  <Field type="checkbox" name="sub_total_option" value="Term" />
                  Show Term
                </label>
              </div>
              {/* <div>
              <div id="sub-total-format-group" className="settings-heading">
                Sub Total Format
              </div>
              <div role="group" aria-labelledby="sub-total-format-group" className="number-option-body">
                <label>
                  <Field type="radio" name="sub_total_format_option" value="0" />
                  Fees + GST = Total Payable
                </label>
                <label>
                  <Field type="radio" name="sub_total_format_option" value="1" />
                  Fees (including GST) with Description
                </label>
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="sub_total_format_option" />
                </span>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptDetailsSetting;
