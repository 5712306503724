import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
// import { useCallback } from 'react';
// import { useParams } from 'react-router-dom';
import { Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, PieChart, Pie, Cell,  } from 'recharts';
// import { API, fileUpload } from '../../../../config';
// import moment from 'moment';
// import { toast } from 'react-toastify';
// import { useEffect } from 'react';    

const ChartForPLReport = ({ startDate, endDate, setPLLoading, expRevChartData, expChartData}) => {
  // const params = useParams();
  // const businessID = params?.business_id;
  // const [expRevChartData, setExpRevChartData] = useState([]);
  // let startMonth = startDate ? `${startDate?.split('-')[1]}-${startDate?.split('-')[2]}` : ''
  // let endMonth = endDate ? `${endDate?.split('-')[1]}-${endDate?.split('-')[2]}` : ''
  const COLORS = ['#42275a', '#734b6d'];

  const ExpChartCOLORS = [
    "#42275a", 
    "#734b6d", 
    "#1F78B4",
    "#1B9BDF",
    "#034262",
    "#C7DAE3",
    "#A6CEE3",
    "#B2DF8A",
    "#d3f7b2",
    "#2b6a8a",
    "#6a9aba",
    "#0f71a3",
  ]

  const CustomExpRevTooltip = ({ active, payload, label }) => {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label>
          {payload && payload?.length > 0
            ? `${payload[0]?.payload?.month}`
            : ""}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `Revenue : $${payload[0]?.payload?.Revenue?.toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`
            : ""}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `Expenses : $${payload[0]?.payload?.Expenses?.toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`
            : ""}
        </label>
      </div>
    );
  };

  const CustomTooltipForExp = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>
            {payload && payload?.length > 0
              ? `${payload[0].name} (${payload[0].value}%)`
              : ""}
          </label>
          <br />
          <label>
            {payload && payload?.length > 0
              ? `$${payload[0].payload.total.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : ""}
          </label>
        </div>
      );
    }

    return null;
  };

  const totalExp =
    expChartData && expChartData?.length > 0
      ? expChartData?.map((item) => {
          return parseFloat(item?.total);
        })
      : [];
  const totalExpenditures =
    totalExp && totalExp?.length > 0
      ? totalExp.reduce((partialSum, a) => partialSum + a, 0)
      : 0;

  // const fetchBankReconcileData = useCallback(async () => {
  //   if (businessID) {
  //     try {
  //       setPLLoading(true);
  //       const { data } = await fileUpload(
  //         `${API.GET_BANK_CONCILEDATA}?client_business_id=${
  //           businessID ? businessID : ""
  //         }&from_month=${
  //           startMonth
  //             ? startMonth
  //             : ""
  //         }&to_month=${
  //           endMonth
  //             ? endMonth
  //             : ""
  //         }`
  //       );

  //       const expRevData = data?.data?.exRevChart ? data?.data?.exRevChart : [];
  //       setExpRevChartData(expRevData);
  //       setPLLoading(false);
  //       return data.data;
  //     } catch (e) {
  //       setPLLoading(false);
  //       const { data } = e;
  //       toast.error(
  //         data &&
  //           data.errors &&
  //           data.errors.myna_error &&
  //           data.errors.myna_error[0]
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [businessID, startMonth, endMonth]);

  return (
    <div className="pl-chart">
      <div className="card-content">
        <div className="card-body first-chart">
          <div className="card-header mb-4">
            <h5>Revenue vs Expense</h5>
          </div>
          <div className="coa-charts">
            <ResponsiveContainer
              width={"100%"}
              height={280}
              debounce={50}
            >
              <BarChart
                data={expRevChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 45,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  textAnchor="end"
                  sclaeToFit="true"
                  verticalAnchor="start"
                  interval={0}
                  angle="-40"
                />
                <YAxis />
                <Tooltip content={<CustomExpRevTooltip />} />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{
                    top: 250,
                  }}
                />
                <Bar dataKey="Revenue" barSize={20} fill={COLORS[0]} />
                <Bar dataKey="Expenses" barSize={20} fill={COLORS[1]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="card-content pl-exp-chart">
        <div className="card-body first-chart">
          <div className="mb-2">
            <h5>Expenditures</h5>
          </div>
          <div className="coa-charts">
            <ResponsiveContainer
              width={"70%"}
              height={200}
              debounce={50}
            >
              <PieChart>
                <Pie
                  data={expChartData}
                  isAnimationActive={true}
                  color="#000000"
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={1}
                  outerRadius={100}
                  fill="#8884d8"
                  labelLine={true}
                  // label={renderExpCustomizedLabel}
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    value,
                    index,
                  }) => {
                    const RADIAN = Math.PI / 180;
                    // eslint-disable-next-line
                    const radius =
                      25 + innerRadius + (outerRadius - innerRadius);
                    // eslint-disable-next-line
                    const x =
                      cx + radius * Math.cos(-midAngle * RADIAN);
                    // eslint-disable-next-line
                    const y =
                      cy + radius * Math.sin(-midAngle * RADIAN);
                    return (
                      <text
                        x={x}
                        y={y}
                        key={`cell-${index}`}
                        fill={"#000000"}
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                        // style={{f}}
                      >
                        {value ? `${value}%` : "0.00"}
                      </text>
                    );
                  }}
                >
                  {expChartData.map((entry, index) => (
                    <Fragment key={index}>
                      <Cell
                        key={`cell-${index}`}
                        fill={ExpChartCOLORS[index % ExpChartCOLORS.length]}
                      />
                    </Fragment>
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltipForExp />} />
              </PieChart>
            </ResponsiveContainer>
            <ul className="chart-legends">
              {expChartData &&
                expChartData?.length > 0 &&
                expChartData?.map((item, index) => {
                  return (
                    <p key={index}>
                      <FontAwesomeIcon
                        icon={faSquare}
                        style={{
                          color: ExpChartCOLORS[index % ExpChartCOLORS.length],
                        }}
                      ></FontAwesomeIcon>{" "}
                      {item?.name}
                    </p>
                  );
                })}
            </ul>
          </div>
          <div
          style={{
            marginTop: "20px",
            float: "left",
            width: "73%",
            textAlign: "center",
          }}>
            <p>
              <strong>Total : </strong>$
              {totalExpenditures.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>       
        </div>
      </div>
    </div>
  );
};

export default ChartForPLReport;
