import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import BootStrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spin from "../../../common/Spin";
import Pagination from "../../../common/Pagination";
import AddTemplate from "./components/AddTemplate";
import TitleBar from "../../../common/TitleBar";

const Template = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [templateList, setTemplatesList] = useState([]);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const [showAddTemplate, setShowAddTemplate] = useState(false);

  // const handleDelete = () => {
  //   fetchTemplates();
  // };

  // const handleEdit = () => {
  //   fetchTemplates();
  // };

  const fetchTemplates = useCallback(async () => {
    try {
      setTemplateLoading(true);
      // const { data } = await get(`${API.GET_DISCOUNT_CODE}?client_business_id=${businessId ? businessId : ""}&limit=${limit ? limit : 10}&page=${page ? page : 1}`);
      setTemplateLoading(false);
      // setLimit(data?.data?.per_page);
      // setPage(data?.data?.current_page);
      // setTotal(data?.data?.total);
      // setFrom(data?.data?.from);
      // setTo(data?.data?.to);
      // const discountsList = data.data.data;
      // setTemplatesList(discountsList);
      // return data.data;
      setTotal(total)
      setFrom(from)
      setTo(to)
    } catch (e) {
      const { data } = e;
      setTemplateLoading(false);
      setTemplatesList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [from, to, total]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const templatesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: templateList[i]?.id,
        template_type: templateList[i]?.code ? templateList[i]?.code : "",
        description: templateList[i]?.description ? templateList[i]?.description : "",
      });
    }
    return items;
  };

  const templates = templatesGenerator(templateList?.length);

  const columns = [
    {
      dataField: "template_type",
      text: "Template For",
    },
    {
      dataField: "description",
      text: "Description",
    },
  ];

  const handleTableChange = (type) => {
    if (type === "sort") {
      setPage(1);
    }
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : 10}&page=${page ? page : 1}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, page]);

//   useEffect(() => {
//     fetchDiscounts();
//   }, [fetchDiscounts]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

//   const handleAdd = () => {
//     fetchDiscounts();
//   };

  const toggleAddTemplateModal = () => {
    setShowAddTemplate(!showAddTemplate);
  };

  return (
    <>
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"Template Settings"}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={toggleAddTemplateModal}
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="custom-table">
              {templateLoading && <Spin />}
              <BootStrapTable
                keyField="key"
                remote
                data={templates}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {showAddTemplate && (
        <AddTemplate
          businessId={businessId}
          showAddTemplate={showAddTemplate}
          toggleAddTemplateModal={toggleAddTemplateModal}
          // handleAdd={handleAdd}
        />
      )}
    </>
  );
};

export default Template;
