import { ErrorMessage, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, fileUpload, imageDefaultPrefixPath } from '../../../../../config';
import { toast } from 'react-toastify';
import Spin from '../../../../common/Spin';
import { Button, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ReactInputMask from 'react-input-mask';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import { AddCreditSchema } from '../../../../common/Validation';
import { checkIfFileExists, formatABN, formatName, formatNumber } from '../../../../common/Misc';
import { getBusinessData } from '../../../components/BusinessHelper';

const AddCreditNote = ({
  id,
  toggleCreditNoteModal,
  lastCreditNumber,
  total_amount_due,
  invoice_number,
  invoice_total,
  fetchInvoices,
  invoice
}) => {
  const params = useParams();
  const businessId = params?.business_id;
  const today = new Date();
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [loading, setLoading] = useState(false);
  const [creditNumber, setCreditNumber] = useState(lastCreditNumber);
  const [creditDate, setCreditDate] = useState(today);
  const [showPreview, setShowPreview] = useState(false);
  const [businessData, setBusinessData] = useState();
  const [data, setData] = useState();

  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [terms, setTerms] = useState(14);

  const fetchBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
  }, [businessId]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    var date1 = invoice?.date?.includes('-')
      ? new Date(
          String(invoice?.date)?.split('-')[2],
          String(invoice?.date)?.split('-')[1] - 1,
          String(invoice?.date)?.split('-')[0]
        )
      : '';
    var date2 = invoice?.due_date?.includes('-')
      ? new Date(
          String(invoice?.due_date)?.split('-')[2],
          String(invoice?.due_date)?.split('-')[1] - 1,
          String(invoice?.due_date)?.split('-')[0]
        )
      : '';
    var Difference_In_Time = date2?.getTime() - date1?.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setTerms(Difference_In_Days);
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
    // eslint-disable-next-line
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }
  const toggleShowModal = () => {
    setShowPreview(!showPreview);
  };

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.discount_type = value?.discount_type?.value;
    formData.append('client_business_id', businessId ? businessId : '');
    formData.append('invoice_id', id ? id : '');
    formData.append('credit_number', value?.credit_number ? value?.credit_number : '');
    formData.append(
      'credit_date',
      value?.credit_date
        ? moment(value?.credit_date, 'DD-MM-YYYY').format('DD-MM-YYYY')
        : creditDate
        ? moment(creditDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
        : ''
    );
    formData.append(
      'credit_amount',
      value?.credit_amount ? value?.credit_amount.replace(/,/g, '') : ''
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_CREDIT_NOTE}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        fetchInvoices();
        toggleCreditNoteModal();
      }
      return data?.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };
  return (
    <Formik
      initialValues={{
        credit_number: creditNumber,
        credit_date: creditDate ? creditDate : new Date(),
        credit_amount: ''
      }}
      enableReinitialize
      onSubmit={handleFormSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={AddCreditSchema}
    >
      {({ values, setErrors, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <>
            {loading && <Spin />}
            <form
              onSubmit={handleSubmit}
              noValidate
              className="entry-section"
              style={{ textAlign: 'left' }}
            >
              <div className="form-box">
                <div
                  className="form-group full-width"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <h5>Invoice Number: {invoice_number}</h5>
                  <h5>Invoice Total ${formatNumber(invoice_total)}</h5>
                  <h5>Invoice Amount Due: ${formatNumber(total_amount_due)}</h5>
                </div>
                <div className="form-group tri-width">
                  <label>
                    <span className="required">*</span>Credit Note No.
                  </label>
                  <ReactInputMask
                    type="text"
                    mask="C999999"
                    maskChar={null}
                    className="number-disable"
                    placeholder="Enter Invoice Number"
                    onChange={(e) => {
                      setFieldValue(`credit_number`, e?.target?.value);
                      setCreditNumber(e?.target?.value);
                    }}
                    onBlur={(e) => {
                      let numberData = e?.target?.value?.split('C')[1];
                      if (numberData?.length < 6) {
                        let newNumber = padWithLeadingZeros(Number(numberData), 6);
                        setFieldValue(`credit_number`, `C${newNumber}`);
                        setCreditNumber(`C${newNumber}`);
                      } else {
                        setFieldValue(`credit_number`, `C${numberData}`);
                        setCreditNumber(`C${numberData}`);
                      }
                    }}
                    disabled={true}
                    value={values?.credit_number ? values?.credit_number : ''}
                    name="credit_number"
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="code" />
                  </span>
                </div>
                <div className="form-group tri-width">
                  <label>
                    <span className="required">*</span>Credit Date
                  </label>
                  <DatePicker
                    onChange={(e) => {
                      setFieldValue('credit_date', e);
                      setCreditDate(e);
                    }}
                    value={values?.credit_date ? values?.credit_date : creditDate ? creditDate : ''}
                    cleanable={false}
                    format="dd-MM-yyyy"
                    placeholder="Select Credit Date"
                    disabledDate={(date) => moment(date).isAfter(moment())}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="credit_date" />
                  </span>
                </div>
                <div className="form-group tri-width">
                  <label>
                    <span className="required">*</span>Credit Amount
                  </label>
                  <NumberFormat
                    type="text"
                    name="credit_amount"
                    decimalScale={2}
                    value={values?.credit_amount ? values?.credit_amount : ''}
                    onChange={handleChange}
                    onBlur={(e) => {
                      let amount = Number(e.target.value.replace(/,/g, ''));

                      if (amount > total_amount_due) {
                        toast.warn('Credit amount should always less then amount due');
                        setFieldValue('credit_amount', '');
                      } else {
                        setFieldValue('credit_amount', e.target.value);
                      }
                    }}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                  />
                  <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name="credit_amount" />
                  </span>
                </div>
              </div>

              <div className="modal-footer" style={{ paddingBottom: '0px' }}>
                <Button
                  onClick={() => {
                    setData(values);
                    toggleShowModal();
                  }}
                >
                  Preview
                </Button>
                <Button type="submit">Save</Button>
                <Button
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    toggleCreditNoteModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
            {showPreview && (
              <Modal
                size="lg"
                show={showPreview}
                onHide={toggleShowModal}
                dialogClassName="modal-50w larg-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                className="business-section"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Credite Note View
                </Modal.Header>
                <Modal.Body
                  style={{
                    padding: '15px'
                  }}
                >
                  {loading ? (
                    <Spin />
                  ) : (
                    <>
                      {invoice?.template_setting?.settings?.invoice_style === 0 ? (
                        <>
                          <div id="print-invoice">
                            <div
                              className="boxed-invoice"
                              style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
                            >
                              <table
                                style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ width: '50%' }}>
                                      {BusinessLogo && (
                                        <img
                                          src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                          alt=""
                                          style={{
                                            maxWidth: '95px',
                                            maxHeight: '80px',
                                            marginRight: '5px'
                                          }}
                                        />
                                      )}{' '}
                                      {BusinessName}
                                    </td>
                                    <td style={{ textAlign: 'end', width: '50%' }}>
                                      <p>
                                        {BusinessName}
                                        {invoice?.office ? (
                                          <>
                                            <br />
                                            {invoice?.office?.street ? invoice?.office?.street : ''}
                                            <br />
                                            {invoice?.office?.suburb
                                              ? invoice?.office?.suburb?.name
                                              : ''}
                                            {invoice?.office?.suburb && ' '}
                                            {invoice?.office?.state
                                              ? invoice?.office?.state?.short_name
                                                ? invoice?.office?.state?.short_name
                                                : invoice?.office?.state?.name
                                              : ''}
                                            {invoice?.office?.state && ' '}
                                            {invoice?.office?.postal_code
                                              ? invoice?.office?.postal_code
                                              : ''}
                                          </>
                                        ) : (
                                          <>
                                            <br />
                                            {businessData?.street ? businessData?.street : ''}
                                            <br />
                                            {businessData?.suburb ? businessData?.suburb?.name : ''}
                                            {businessData?.suburb && ' '}
                                            {businessData?.state
                                              ? businessData?.state?.short_name
                                                ? businessData?.state?.short_name
                                                : businessData?.state?.name
                                              : ''}
                                            {businessData?.state && ' '}
                                            {businessData?.postal_code
                                              ? businessData?.postal_code
                                              : ''}
                                          </>
                                        )}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr
                                style={{
                                  margin: 0,
                                  marginTop: '8px',
                                  marginBottom: '25px',
                                  borderTop: '1px solid black',
                                  width: '100%'
                                }}
                              />
                              <table
                                style={{ width: '100%', fontFamily: 'Poppins', fontSize: '12px' }}
                              >
                                <tbody>
                                  <tr style={{ display: 'flex', gap: '15px' }}>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '15px',
                                        height: '100px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-evenly',
                                        width: '65%'
                                      }}
                                    >
                                      {invoice?.template_setting?.settings?.client_option
                                        ?.non_individual ? (
                                        <div>
                                          {invoice?.template_setting?.settings?.client_option
                                            ?.alternateName
                                            ? invoice?.contact && invoice?.contact?.display_name
                                              ? invoice?.contact?.display_name
                                              : formatName(
                                                  invoice?.contact?.first_name,
                                                  invoice?.contact?.last_name
                                                )
                                            : invoice?.contact?.business_name
                                            ? invoice?.contact?.business_name
                                            : formatName(
                                                invoice?.contact?.first_name,
                                                invoice?.contact?.last_name
                                              )}
                                          <>
                                            {invoice?.contact && (
                                              <>
                                                <p style={{ margin: '0px' }}>
                                                  {invoice?.contact?.shipping_street
                                                    ? invoice?.contact?.shipping_street
                                                    : invoice?.contact?.street
                                                    ? invoice?.contact?.street
                                                    : ''}
                                                </p>
                                                <p style={{ margin: '0px' }}>
                                                  {invoice?.contact?.shipping_suburb
                                                    ? invoice?.contact?.shipping_suburb?.name
                                                    : invoice?.contact?.suburb
                                                    ? invoice?.contact?.suburb?.name
                                                    : ''}
                                                  {(invoice?.contact?.suburb ||
                                                    invoice?.contact?.shipping_suburb) && <br />}
                                                  {invoice?.contact?.shipping_state
                                                    ? invoice?.contact?.shipping_state?.short_name
                                                      ? invoice?.contact?.shipping_state?.short_name
                                                      : invoice?.contact?.shipping_state?.name
                                                    : invoice?.contact?.state
                                                    ? invoice?.contact?.state?.short_name
                                                      ? invoice?.contact?.state?.short_name
                                                      : invoice?.contact?.state?.name
                                                    : ''}
                                                  {(invoice?.contact?.state ||
                                                    invoice?.contact?.shipping_state) && <br />}
                                                  {invoice?.contact?.shipping_postal_code
                                                    ? invoice?.contact?.shipping_postal_code
                                                    : invoice?.contact?.postal_code
                                                    ? invoice?.contact?.postal_code
                                                    : ''}
                                                </p>
                                                {/* <p>{invoice?.contact}</p> */}
                                              </>
                                            )}
                                          </>
                                        </div>
                                      ) : (
                                        <div>
                                          {invoice?.template_setting?.settings?.client_option
                                            ?.alternateName ? (
                                            invoice?.contact && invoice?.contact?.display_name ? (
                                              invoice?.contact?.display_name
                                            ) : invoice?.contact?.first_name ? (
                                              <p style={{ margin: '0px' }}>
                                                {invoice?.contact?.first_name +
                                                  ' ' +
                                                  invoice?.contact?.last_name}
                                              </p>
                                            ) : (
                                              <p style={{ margin: '0px' }}>
                                                {invoice?.contact?.last_name}
                                              </p>
                                            )
                                          ) : invoice?.contact && invoice?.contact?.first_name ? (
                                            <p style={{ margin: '0px' }}>
                                              {invoice?.contact?.first_name +
                                                ' ' +
                                                invoice?.contact?.last_name}
                                            </p>
                                          ) : (
                                            <p style={{ margin: '0px' }}>
                                              {invoice?.contact?.last_name}
                                            </p>
                                          )}
                                          <>
                                            {invoice?.contact && (
                                              <>
                                                <p style={{ margin: '0px' }}>
                                                  {invoice?.contact?.street
                                                    ? invoice?.contact?.street
                                                    : invoice?.contact?.shipping_street
                                                    ? invoice?.contact?.shipping_street
                                                    : ''}
                                                </p>
                                                <p style={{ margin: '0px' }}>
                                                  {invoice?.contact?.suburb
                                                    ? invoice?.contact?.suburb?.name
                                                    : invoice?.contact?.shipping_suburb
                                                    ? invoice?.contact?.shipping_suburb?.name
                                                    : ''}
                                                  {(invoice?.contact?.suburb ||
                                                    invoice?.contact?.shipping_suburb) && <br />}
                                                  {invoice?.contact?.state
                                                    ? invoice?.contact?.state?.short_name
                                                      ? invoice?.contact?.state?.short_name
                                                      : invoice?.contact?.state?.name
                                                    : invoice?.contact?.shipping_state
                                                    ? invoice?.contact?.shipping_state?.name
                                                    : ''}
                                                  {(invoice?.contact?.state ||
                                                    invoice?.contact?.shipping_state) && <br />}
                                                  {invoice?.contact?.postal_code
                                                    ? invoice?.contact?.postal_code
                                                    : invoice?.contact?.shipping_postal_code
                                                    ? invoice?.contact?.shipping_postal_code
                                                    : ''}
                                                </p>
                                                {/* <p>{invoice?.contact}</p> */}
                                              </>
                                            )}
                                          </>
                                        </div>
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '15px',
                                        textAlign: 'center',
                                        height: '100px',
                                        width: '35%'
                                      }}
                                    >
                                      <h6 style={{ margin: '0px', fontSize: '16px' }}>
                                        Credit Note
                                      </h6>
                                      {/* <p
                              style={{
                                fontWeight: 'bold',
                                margin: '0px',
                                marginBottom: '9px',
                                marginTop: '7px'
                              }}
                            >
                              {data?.receipt_number}
                            </p> */}
                                      <p
                                        style={{
                                          fontWeight: 'bold',
                                          margin: '0px',
                                          marginBottom: '9px',
                                          marginTop: '7px'
                                        }}
                                      >
                                        {businessData?.abn ? formatABN(businessData?.abn) : ''}
                                      </p>
                                      <p style={{ margin: '0px' }}>
                                        {moment(data?.credit_date, 'DD-MM-YYYY').format(
                                          'DD-MM-YYYY'
                                        )}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                style={{
                                  width: '100%',
                                  marginTop: '20px',
                                  border: '1px solid black',
                                  fontFamily: 'Poppins',
                                  borderSpacing: '0px'
                                }}
                                cellSpacing={0}
                                cellPadding={0}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={
                                        invoice?.template_setting?.settings?.secondary_color
                                          ? {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                            }
                                          : {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background:
                                                invoice?.template_setting?.settings?.primary_color
                                            }
                                      }
                                    >
                                      Credit Date
                                    </th>
                                    <th
                                      style={
                                        invoice?.template_setting?.settings?.secondary_color
                                          ? {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                            }
                                          : {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background:
                                                invoice?.template_setting?.settings?.primary_color
                                            }
                                      }
                                    >
                                      Reference
                                    </th>
                                    <th
                                      style={
                                        invoice?.template_setting?.settings?.secondary_color
                                          ? {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                            }
                                          : {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background:
                                                invoice?.template_setting?.settings?.primary_color
                                            }
                                      }
                                    >
                                      Invoice Total
                                    </th>
                                    <th
                                      style={
                                        invoice?.template_setting?.settings?.secondary_color
                                          ? {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                            }
                                          : {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background:
                                                invoice?.template_setting?.settings?.primary_color
                                            }
                                      }
                                    >
                                      Invoice Amount Due
                                    </th>
                                    <th
                                      style={
                                        invoice?.template_setting?.settings?.secondary_color
                                          ? {
                                              padding: '8px',
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                            }
                                          : {
                                              padding: '8px',
                                              borderBottom: '1px solid black',
                                              textAlign: 'inherit',
                                              color:
                                                invoice?.template_setting?.settings?.font_color,
                                              background:
                                                invoice?.template_setting?.settings?.primary_color
                                            }
                                      }
                                    >
                                      Credit Note Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderRight: '1px solid black',
                                        borderBottom: '1px solid black'
                                      }}
                                    >
                                      {moment(data?.credit_date, 'DD-MM-YYYY').format('DD-MM-YYYY')}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderBottom: '1px solid black',
                                        borderRight: '1px solid black'
                                      }}
                                    >
                                      {invoice?.invoice_number}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderBottom: '1px solid black',
                                        textAlign: 'right',
                                        borderRight: '1px solid black'
                                      }}
                                    >
                                      ${invoice?.total_payable_amount
                                        ? formatNumber(Number(invoice?.total_payable_amount))
                                        : '0.00'}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderBottom: '1px solid black',
                                        textAlign: 'right',
                                        borderRight: '1px solid black'
                                      }}
                                    >
                                      ${total_amount_due
                                        ? formatNumber(Number(total_amount_due))
                                        : '0.00'}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        textAlign: 'right',
                                        borderBottom: '1px solid black'
                                      }}
                                    >
                                      ${data?.credit_amount
                                        ? formatNumber(Number(data?.credit_amount))
                                        : '0.00'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderRight: '1px solid black',
                                        height: '37px'
                                      }}
                                      colSpan={3}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        {invoice?.template_setting?.settings?.sub_total_option
                                          ?.term ? (
                                          <p style={{ margin: '0px' }}>
                                            <span>
                                              Terms:{' '}
                                              {terms
                                                ? `${
                                                    String(invoice?.date)?.includes('-')
                                                      ? terms.toFixed(0) < 0
                                                        ? 0
                                                        : terms.toFixed(0) - 1
                                                      : terms.toFixed(0) < 0
                                                      ? 0
                                                      : terms.toFixed(0)
                                                  } days`
                                                : ''}
                                            </span>
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                        {invoice?.template_setting?.settings?.sub_total_option
                                          ?.dueDate ? (
                                          <p style={{ margin: '0px' }}>
                                            <span>
                                              Sent Date: {data?.email_date ? data?.email_date : ''}
                                            </span>
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                        {/* {values?.receipt_detail_option?.includes('Amounts') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Amount Outstanding: $</span>
                          </p>
                        ) : (
                          <></>
                        )} */}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        borderRight: '1px solid black',
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      $
                                      {total_amount_due
                                        ? formatNumber(Number(total_amount_due))
                                        : '0.00'}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      $
                                      {data?.credit_amount
                                        ? formatNumber(Number(data?.credit_amount))
                                        : '0.00'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontStyle: 'italic',
                                        textAlign: 'center'
                                      }}
                                    >
                                      {/* <p style={{ margin: '20px 0px 10px 0px' }}>
                        Please detach the portion below and forward with your payment
                      </p> */}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div id="print-invoice">
                            <div id="boxed-invoice">
                              <table
                                style={{
                                  width: '100%',
                                  fontFamily: 'Poppins',
                                  padding: '15px',
                                  fontSize: '12px'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                                        <tbody>
                                          <tr>
                                            <td colSpan={3} style={{ textAlign: 'right' }}>
                                              {BusinessLogo && (
                                                <img
                                                  src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                                  alt=""
                                                  style={{
                                                    maxWidth: '95px',
                                                    maxHeight: '80px',
                                                    marginRight: '5px'
                                                  }}
                                                />
                                              )}{' '}
                                              {BusinessName}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: '30%' }}>
                                              <h2
                                                style={{ fontSize: '20px', paddingBottom: '12px' }}
                                              >
                                                Credit Note
                                              </h2>
                                              {invoice?.template_setting?.settings?.client_option
                                                ?.non_individual ? (
                                                <div>
                                                  {invoice?.template_setting?.settings
                                                    ?.client_option?.alternateName ? (
                                                    invoice?.contact &&
                                                    invoice?.contact?.display_name ? (
                                                      invoice?.contact?.display_name
                                                    ) : invoice?.contact?.first_name ? (
                                                      <p style={{ margin: '0px' }}>
                                                        {invoice?.contact?.first_name +
                                                          ' ' +
                                                          invoice?.contact?.last_name}
                                                      </p>
                                                    ) : (
                                                      <p style={{ margin: '0px' }}>
                                                        {invoice?.contact?.last_name}
                                                      </p>
                                                    )
                                                  ) : invoice?.contact?.business_name ? (
                                                    invoice?.contact?.business_name
                                                  ) : invoice?.contact?.first_name ? (
                                                    <p style={{ margin: '0px' }}>
                                                      {invoice?.contact?.first_name +
                                                        ' ' +
                                                        invoice?.contact?.last_name}
                                                    </p>
                                                  ) : (
                                                    <p style={{ margin: '0px' }}>
                                                      {invoice?.contact?.last_name}
                                                    </p>
                                                  )}
                                                  <>
                                                    {invoice?.contact && (
                                                      <>
                                                        <p style={{ margin: '0px' }}>
                                                          {invoice?.contact?.shipping_street
                                                            ? invoice?.contact?.shipping_street
                                                            : invoice?.contact?.street
                                                            ? invoice?.contact?.street
                                                            : ''}
                                                        </p>
                                                        <p style={{ margin: '0px' }}>
                                                          {invoice?.contact?.shipping_suburb
                                                            ? invoice?.contact?.shipping_suburb
                                                                ?.name
                                                            : invoice?.contact?.suburb
                                                            ? invoice?.contact?.suburb?.name
                                                            : ''}
                                                          {(invoice?.contact?.suburb ||
                                                            invoice?.contact?.shipping_suburb) && (
                                                            <br />
                                                          )}
                                                          {invoice?.contact?.shipping_state
                                                            ? invoice?.contact?.shipping_state
                                                                ?.short_name
                                                              ? invoice?.contact?.shipping_state
                                                                  ?.short_name
                                                              : invoice?.contact?.shipping_state
                                                                  ?.name
                                                            : invoice?.contact?.state
                                                            ? invoice?.contact?.state?.short_name
                                                              ? invoice?.contact?.state?.short_name
                                                              : invoice?.contact?.state?.name
                                                            : ''}
                                                          {(invoice?.contact?.state ||
                                                            invoice?.contact?.shipping_state) && (
                                                            <br />
                                                          )}
                                                          {invoice?.contact?.shipping_postal_code
                                                            ? invoice?.contact?.shipping_postal_code
                                                            : invoice?.contact?.postal_code
                                                            ? invoice?.contact?.postal_code
                                                            : ''}
                                                        </p>
                                                        {/* <p>{invoice?.contact}</p> */}
                                                      </>
                                                    )}
                                                  </>
                                                </div>
                                              ) : (
                                                <div>
                                                  {invoice?.template_setting?.settings
                                                    ?.client_option?.alternateName ? (
                                                    invoice?.contact &&
                                                    invoice?.contact?.display_name ? (
                                                      invoice?.contact?.display_name
                                                    ) : invoice?.contact?.first_name ? (
                                                      <p style={{ margin: '0px' }}>
                                                        {invoice?.contact?.first_name +
                                                          ' ' +
                                                          invoice?.contact?.last_name}
                                                      </p>
                                                    ) : (
                                                      <p style={{ margin: '0px' }}>
                                                        {invoice?.contact?.last_name}
                                                      </p>
                                                    )
                                                  ) : invoice?.contact &&
                                                    invoice?.contact?.first_name ? (
                                                    <p style={{ margin: '0px' }}>
                                                      {invoice?.contact?.first_name +
                                                        ' ' +
                                                        invoice?.contact?.last_name}
                                                    </p>
                                                  ) : (
                                                    <p style={{ margin: '0px' }}>
                                                      {invoice?.contact?.last_name}
                                                    </p>
                                                  )}
                                                  <>
                                                    {invoice?.contact && (
                                                      <>
                                                        <p style={{ margin: '0px' }}>
                                                          {invoice?.contact?.street
                                                            ? invoice?.contact?.street
                                                            : invoice?.contact?.shipping_street
                                                            ? invoice?.contact?.shipping_street
                                                            : ''}
                                                        </p>
                                                        <p style={{ margin: '0px' }}>
                                                          {invoice?.contact?.suburb
                                                            ? invoice?.contact?.suburb?.name
                                                            : invoice?.contact?.shipping_suburb
                                                            ? invoice?.contact?.shipping_suburb
                                                                ?.name
                                                            : ''}
                                                          {(invoice?.contact?.suburb ||
                                                            invoice?.contact?.shipping_suburb) && (
                                                            <br />
                                                          )}
                                                          {invoice?.contact?.state
                                                            ? invoice?.contact?.state?.short_name
                                                              ? invoice?.contact?.state?.short_name
                                                              : invoice?.contact?.state?.name
                                                            : invoice?.contact?.shipping_state
                                                            ? invoice?.contact?.shipping_state?.name
                                                            : ''}
                                                          {(invoice?.contact?.state ||
                                                            invoice?.contact?.shipping_state) && (
                                                            <br />
                                                          )}
                                                          {invoice?.contact?.postal_code
                                                            ? invoice?.contact?.postal_code
                                                            : invoice?.contact?.shipping_postal_code
                                                            ? invoice?.contact?.shipping_postal_code
                                                            : ''}
                                                        </p>
                                                        {/* <p>{invoice?.contact}</p> */}
                                                      </>
                                                    )}
                                                  </>
                                                </div>
                                              )}
                                            </td>
                                            <td style={{ width: '25%' }}></td>
                                            <td style={{ width: '45%', textAlign: 'right' }}>
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                style={{ width: '100%' }}
                                              >
                                                <tr>
                                                  <td
                                                    style={{
                                                      verticalAlign: 'top',
                                                      textAlign: 'left',
                                                      paddingRight: '15px'
                                                    }}
                                                  >
                                                    <p>
                                                      <strong>Credit Date</strong>
                                                      <br />
                                                      {moment(
                                                        data?.credit_date,
                                                        'DD-MM-YYYY'
                                                      ).format('DD-MM-YYYY')}
                                                    </p>
                                                    {invoice?.template_setting?.settings
                                                      ?.sub_total_option?.dueDate ? (
                                                      <p>
                                                        <strong>Sent Date</strong>
                                                        <br />
                                                        {data?.email_date ? data?.email_date : ''}
                                                      </p>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <p>
                                                      <strong>ABN</strong>
                                                      <br />
                                                      {businessData?.abn
                                                        ? formatABN(businessData?.abn)
                                                        : ''}
                                                    </p>
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'left',
                                                      verticalAlign: 'top'
                                                    }}
                                                  >
                                                    <div>
                                                      {BusinessName}
                                                      {invoice?.office ? (
                                                        <>
                                                          <br />
                                                          {invoice?.office?.street
                                                            ? invoice?.office?.street
                                                            : ''}
                                                          <br />
                                                          {invoice?.office?.suburb
                                                            ? invoice?.office?.suburb?.name
                                                            : ''}
                                                          {invoice?.office?.suburb && ' '}
                                                          {invoice?.office?.state
                                                            ? invoice?.office?.state?.short_name
                                                              ? invoice?.office?.state?.short_name
                                                              : invoice?.office?.state?.name
                                                            : ''}
                                                          {invoice?.office?.state && ' '}
                                                          {invoice?.office?.postal_code
                                                            ? invoice?.office?.postal_code
                                                            : ''}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <br />
                                                          {businessData?.street
                                                            ? businessData?.street
                                                            : ''}
                                                          <br />
                                                          {businessData?.suburb
                                                            ? businessData?.suburb?.name
                                                            : ''}
                                                          {businessData?.suburb && ' '}
                                                          {businessData?.state
                                                            ? businessData?.state?.short_name
                                                              ? businessData?.state?.short_name
                                                              : businessData?.state?.name
                                                            : ''}
                                                          {businessData?.state && ' '}
                                                          {businessData?.postal_code
                                                            ? businessData?.postal_code
                                                            : ''}
                                                        </>
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {invoice?.template_setting?.settings?.quote_detail_option
                                        ?.amount ? (
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          style={{
                                            width: '100%',
                                            fontFamily: 'Poppins',
                                            marginTop: '55px'
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{ padding: '5px', textAlign: 'left' }}
                                              ></td>
                                              <td
                                                style={{ padding: '5px', textAlign: 'right' }}
                                              ></td>
                                              <td
                                                style={{ padding: '5px', textAlign: 'right' }}
                                              ></td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderTop: '1px solid #000',
                                                  textAlign: 'right',
                                                  fontWeight: 'bold',
                                                  borderBottom: '1px solid #000',
                                                  width: '18%'
                                                }}
                                              >
                                                Total Credit Amount
                                              </td>
                                              <td
                                                style={{
                                                  padding: '5px',
                                                  borderTop: '1px solid #000',
                                                  textAlign: 'right',
                                                  fontWeight: 'bold',
                                                  borderBottom: '1px solid #000',
                                                  width: '18%'
                                                }}
                                              >
                                                $
                                                {/* {invoice?.total_paid_amount
                                        ? invoice?.total_paid_amount formatNumber(Number())
                                        : '0.00'} */}
                                                {data?.credit_amount
                                                  ? formatNumber(Number(data?.credit_amount))
                                                  : '0.00'}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ) : (
                                        <></>
                                      )}

                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        style={{
                                          width: '100%',
                                          fontFamily: 'Poppins',
                                          marginTop: '55px'
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              style={
                                                invoice?.template_setting?.settings?.secondary_color
                                                  ? {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                      textAlign: 'left'
                                                    }
                                                  : {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background:
                                                        invoice?.template_setting?.settings
                                                          ?.primary_color,
                                                      textAlign: 'left'
                                                    }
                                              }
                                            >
                                              Credit Date
                                            </th>
                                            <th
                                              style={
                                                invoice?.template_setting?.settings?.secondary_color
                                                  ? {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                      textAlign: 'right'
                                                    }
                                                  : {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background:
                                                        invoice?.template_setting?.settings
                                                          ?.primary_color,
                                                      textAlign: 'right'
                                                    }
                                              }
                                            >
                                              Reference
                                            </th>
                                            <th
                                              style={
                                                invoice?.template_setting?.settings?.secondary_color
                                                  ? {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                      textAlign: 'right'
                                                    }
                                                  : {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background:
                                                        invoice?.template_setting?.settings
                                                          ?.primary_color,
                                                      textAlign: 'right'
                                                    }
                                              }
                                            >
                                              Invoice Total
                                            </th>
                                            <th
                                              style={
                                                invoice?.template_setting?.settings?.secondary_color
                                                  ? {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                      textAlign: 'right'
                                                    }
                                                  : {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background:
                                                        invoice?.template_setting?.settings
                                                          ?.primary_color,
                                                      textAlign: 'right'
                                                    }
                                              }
                                            >
                                              Invoice Amount Due
                                            </th>
                                            <th
                                              style={
                                                invoice?.template_setting?.settings?.secondary_color
                                                  ? {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background: `transparent
                        linear-gradient(
                          180deg,
                          ${invoice?.template_setting?.settings?.secondary_color} 0%,
                          ${invoice?.template_setting?.settings?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`,
                                                      textAlign: 'right'
                                                    }
                                                  : {
                                                      padding: '5px',
                                                      borderBottom: '1px solid #000',
                                                      color:
                                                        invoice?.template_setting?.settings
                                                          ?.font_color,
                                                      background:
                                                        invoice?.template_setting?.settings
                                                          ?.primary_color,
                                                      textAlign: 'right'
                                                    }
                                              }
                                            >
                                              Credit Note Amount
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                padding: '5px',
                                                textAlign: 'left',
                                                borderBottom: '1px solid #ced4da'
                                              }}
                                            >
                                              {moment(data?.credit_date, 'DD-MM-YYYY').format(
                                                'DD-MM-YYYY'
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderBottom: '1px solid #ced4da',
                                                textAlign: 'right'
                                              }}
                                            >
                                              {invoice?.invoice_number}
                                            </td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderBottom: '1px solid #ced4da',
                                                textAlign: 'right'
                                              }}
                                            >
                                              ${invoice?.total_payable_amount
                                                ? formatNumber(
                                                    Number(invoice?.total_payable_amount)
                                                  )
                                                : '0.00'}
                                            </td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderBottom: '1px solid #ced4da',
                                                textAlign: 'right'
                                              }}
                                            >
                                              ${total_amount_due
                                                ? formatNumber(Number(total_amount_due))
                                                : '0.00'}
                                            </td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderBottom: '1px solid #ced4da',
                                                textAlign: 'right'
                                              }}
                                            >
                                              ${data?.credit_amount
                                                ? formatNumber(Number(data?.credit_amount))
                                                : '0.00'}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ padding: '5px', textAlign: 'left' }}></td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}></td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderTop: '1px solid #000',
                                                textAlign: 'right',
                                                fontWeight: 'bold',
                                                borderBottom: '1px solid #000'
                                              }}
                                            >
                                              Total Amount
                                            </td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderTop: '1px solid #000',
                                                textAlign: 'right',
                                                fontWeight: 'bold',
                                                borderBottom: '1px solid #000'
                                              }}
                                            >
                                              ${total_amount_due
                                                ? formatNumber(Number(total_amount_due))
                                                : '0.00'}
                                            </td>
                                            <td
                                              style={{
                                                padding: '5px',
                                                borderTop: '1px solid #000',
                                                textAlign: 'right',
                                                fontWeight: 'bold',
                                                borderBottom: '1px solid #000'
                                              }}
                                            >
                                              ${data?.credit_amount
                                                ? formatNumber(Number(data?.credit_amount))
                                                : '0.00'}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AddCreditNote;
