import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
// import Sitelogo from "../../components/Header/Logo";
import { API, fileUpload } from "../../config";
import { toast } from "react-toastify";
import SlideToggle from "react-slide-toggle";
import Spin from "../common/Spin";
import wFavicon from "../../assets/images/myna-white-favicon.png";
import logo from "../../assets/images/SMECashbook-white.png";

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get("email");
  const token = query.get("token");
  const invited = query.get("invited");
  const [loading, setLoading] = useState(true);
  const [onLoad, setOnLoad] = useState(false);
  const [role, setRole] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const EmailVarification = localStorage.getItem("sme-EmailVarification");
  // const accountingFirmEmail = localStorage.getItem("sme-accountingFirmEmail");
  // const clientBusinessId = localStorage.getItem("sme-clientBusinessId");
  // const clientId = localStorage.getItem("sme-clientId");


  const handleFormSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('email', email);
      formData.append('token', token);
      if(invited){
        formData.append('invited', invited)
      }

      const { data } = await fileUpload(API.EMAIL_VERIFICATION, formData);
      if (data.status === 200) {
        setLoading(false);
        setRole(data?.data?.role?.slug);
        toast.success(data?.message);
        setSuccessMessage(data?.message);
        // if (accountingFirmEmail) {
        //   const formData = new FormData();

        //   formData.append(
        //     "email",
        //     accountingFirmEmail ? accountingFirmEmail : ""
        //   );
        //   formData.append(
        //     "client_business_id",
        //     clientBusinessId ? clientBusinessId : ""
        //   );
        //   formData.append("client_id", clientId ? clientId : "");
        //   formData.append("accept", 1);
        //   try {
        //     setLoading(true);
        //     const { data } = await fileUpload(
        //       `${API.INVITE_ACCOUNTING_FIRM}`,
        //       formData
        //     );
        //     setLoading(false);
        //     if (data) {
        //       toast.success(data?.message);
        //       localStorage.setItem("sme-EmailVarification", 1);
        //     }
        //     return data?.data;
        //   } catch (error) {
        //     setLoading(false);
        //     if (error?.response?.data?.errors) {
        //       Object.keys(error?.response?.data?.errors).forEach((key) => {
        //         toast.error(error?.response?.data?.errors[key][0]);
        //       });
        //       setLoading(false);
        //     } else {
        //       toast.error(error?.message);
        //       setLoading(false);
        //     }
        //   }
        // } else {
        //   localStorage.setItem("sme-EmailVarification", 1);
        // }
        localStorage.setItem("sme-EmailVarification", 1);
        if(invited){
          localStorage.setItem("sme-client-invited", 1);
        }
      } else {
        toast.error(data.errors?.myna_error[0]);
      }
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, [email, token, invited]);

  useEffect(() => {
    onLoad && handleFormSubmit();
  }, [handleFormSubmit, onLoad]);

  useEffect(() => {
    if (EmailVarification === "1" && successMessage !== null) {
      window.setTimeout(function () {
        ((role === "accounting-firm" || role === "client") && invited)
        ? navigate("/")
        : (role === "accounting-firm" || role === "client"
          ? navigate("/subscription")
          : navigate("/"))
      }, 4000);
    }
  }, [navigate, EmailVarification, successMessage, role, invited]);

  return (
    <>
      <div className="login-screen">
        <div className="welcome-block">
          {/* <Sitelogo /> */}
          <div className="sitelogo">
            <img
              src={logo}
              alt="TrustEasy"
              style={{ maxWidth: "500px", filter: "brightness(100)" }}
            />
            <span>Beta</span>
          </div>
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" /> SME
              CashBook acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          {loading ? "Please wait we are verifying your email address." : ""}
          {loading && <Spin />}
          <Formik>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                {loading ? (
                  ""
                ) : (
                  <>
                    <div className="congrats-block">
                      <h3>Successfully verified</h3>
                      <p>Thank you for verifying your email address.</p>
                      <p>
                        Please wait, you will be redirected to the subscription
                        page.
                      </p>
                    </div>
                  </>
                )}

                {loading && <Spin />}
                {setOnLoad(true)}
              </form>
            )}
          </Formik>
          <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a href="/#" rel="noreferrer" target="_blank">
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="https://smecashbook.com.au/terms-conditions/" rel="noreferrer" target="_blank1">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a href="/#" rel="noreferrer" target="_blank2">
                Privacy Policy
              </a>
            </p>
          </div>
          <SlideToggle>
            {({ toggle, setCollapsibleElement }) => (
              <div className="my-collapsible">
                <i
                  className="fa fa-info my-collapsible__toggle"
                  onClick={toggle}
                ></i>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                >
                  <div className="my-collapsible__content-inner">
                    <p style={{ fontSize: "12px" }}>
                      <img
                        className="small-white-favicon"
                        src={wFavicon}
                        alt=""
                      />{" "}
                      SME CashBook acknowledges the Traditional Custodians of
                      country throughout Australia and their connections to
                      land, sea and community. We pay our respect to their
                      Elders past and present and extend that respect to all
                      Aboriginal and Torres Strait Islander peoples today.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        </div>
      </div>
    </>
  );
};
export default EmailVerification;
