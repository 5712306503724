import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { Button } from 'react-bootstrap';
import { useSuburbList } from './OfficeHelper';
import { MultiSelect } from 'primereact/multiselect';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleOfficeModal,
  stateListing,
  stateLoading,
  suburbListing,
  suburbLoadingE,
  erSuburbLoading,
  businessListing,
  businessLoading,
  selectedBusinesses,
  setSelectedBusinesses,
  userListing,
  userLoading,
  setSelectedUsers,
  selectedUsers,
  clientId,
  businessSettings
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleOfficeModal: PropTypes.any,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    suburbListing: PropTypes.any,
    suburbLoadingE: PropTypes.any,
    erSuburbLoading: PropTypes.any
  };

  // Suburb Listing for Postal Address
  const [suburb, setSuburb] = useState([]);
  useEffect(() => {
    setSuburb(suburbListing ? suburbListing : []);
  }, [suburbListing]);
  const [stateId] = useState();
  const { suburbLoading, suburbList = [], handleStateSelect } = useSuburbList(stateId);
  useEffect(() => {
    const suburbListing1 = suburbList?.map((item) => {
      const { name: label, id: value, ...rest } = item;
      return { label, value, ...rest };
    });
    setSuburb(suburbListing1);
  }, [suburbList]);

  const handleBusinessChange = (e, setFieldValue) => {
    setFieldValue('business_ids', e.value);
    setSelectedBusinesses(e.value);
  };

  const filterBusinessInputRef = useRef();

  const handleBusinessApplyFilter = () => {
    filterBusinessInputRef.current.hide();
  };

  const BusinessResetFunction = () => {
    setSelectedBusinesses([]);
    filterBusinessInputRef && filterBusinessInputRef.current.focus();
    filterBusinessInputRef.current.hide();
  };

  const filterUserInputRef = useRef();

  const handleUserApplyFilter = () => {
    filterUserInputRef.current.hide();
  };

  const userResetFunction = () => {
    setSelectedUsers([]);
    filterUserInputRef && filterUserInputRef.current.focus();
    filterUserInputRef.current.hide();
  };

  const handleUserChange = (e, setFieldValue) => {
    setFieldValue('user_ids', e.value);
    setSelectedUsers(e.value);
  };

  const params = useParams();
  const clientIDParams = params?.client_id;
  const clientID = localStorage.getItem('sme-clientId')
    ? localStorage.getItem('sme-clientId')
    : clientIDParams
    ? clientIDParams
    : clientId;

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Address
          </label>
          <input
            type="text"
            name="street"
            value={values?.street ? values?.street : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="street" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Select State
          </label>
          <Select
            name="state_id"
            value={values?.state_id ? values?.state_id : ''}
            onChange={(e) => {
              setFieldValue(`state_id`, e ? e : '');
              setFieldValue(`suburb_id`, '');
              e && handleStateSelect(e.value);
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={stateListing}
            placeholder="Select State"
            classNamePrefix="selectbox"
            isClearable
            isLoading={stateLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="state_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Select Suburb
          </label>

          <Select
            name="suburb_id"
            value={values?.suburb_id ? values?.suburb_id : ''}
            onChange={(e) => {
              setFieldValue(`suburb_id`, e ? e : '');
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={suburb}
            placeholder="Select Suburb"
            classNamePrefix="selectbox"
            isClearable
            isLoading={suburbLoading || suburbLoadingE || erSuburbLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="suburb_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Postal Code
          </label>
          <InputMask
            type="text"
            name="postal_code"
            value={values?.postal_code ? values?.postal_code : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="9999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="postal_code" />
          </span>
        </div>
        {clientID && (
          <>
            {!businessSettings && (
              <div className="form-group half-width">
                <label>Businesses</label>
                <MultiSelect
                  value={selectedBusinesses}
                  selectedItemsLabel={`${selectedBusinesses?.length} Businesses Selected`}
                  name="business_ids"
                  onChange={(e) => {
                    handleBusinessChange(e, setFieldValue);
                  }}
                  options={businessListing}
                  optionLabel="label"
                  // filter
                  placeholder="Select Businesses"
                  maxSelectedLabels={0}
                  ref={filterBusinessInputRef}
                  panelFooterTemplate={
                    <>
                      <hr/>
                      <Button
                        className="multiSelect-button"
                        onClick={() => {
                          handleBusinessApplyFilter();
                        }}
                      >
                        OK
                      </Button>
                      <Button
                        className="multiSelect-button"
                        onClick={() => {
                          BusinessResetFunction();
                        }}
                      >
                        Reset
                      </Button>
                    </>
                  }
                />
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="business_ids" />
                </span>
              </div>
            )}

            <div className="form-group half-width">
              <label>User</label>
              <MultiSelect
                value={selectedUsers}
                selectedItemsLabel={`${selectedUsers?.length} User(s) Selected`}
                name="user_ids"
                onChange={(e) => {
                  handleUserChange(e, setFieldValue);
                }}
                options={userListing}
                optionLabel="label"
                // filter
                placeholder="Select User"
                maxSelectedLabels={0}
                ref={filterUserInputRef}
                panelFooterTemplate={
                  <>
                    <hr/>
                    <Button
                      className="multiSelect-button"
                      onClick={() => {
                        handleUserApplyFilter();
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      className="multiSelect-button"
                      onClick={() => {
                        userResetFunction();
                      }}
                    >
                      Reset
                    </Button>
                  </>
                }
              />
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="office_ids" />
              </span>
            </div>
          </>
        )}
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="reset"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleOfficeModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
