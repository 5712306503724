import React, { useEffect, useState } from 'react';
import BackButton from '../../components/Form/BackButton';
import { getBusinessData } from '../Business/components/BusinessHelper';
import { checkIfFileExists } from './Misc';
import { useParams } from 'react-router-dom';
import { imageDefaultPrefixPath } from '../../config';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import SearchBar from './SearchBar';

const TitleBar = ({
  label,
  back = true,
  businessNameData = true,
  globalSearch = true,
  children,
  businessDashBoard
}) => {
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const params = useParams();
  const businessId = params?.business_id;
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  const today = new Date();
  const defaultDateRange =
    parseInt(moment(today).format('MM')) === 7
      ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
      : parseInt(moment(today).format('MM')) > 7
      ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
      : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

  // For the financial year
  // Oct-Dec = 1
  // Jan-Mar = 2
  // Apr-Jun = 3
  // Jul-Sep = 4
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let date = new Date();
  let quarter = getQuarter(date);
  let defaultMonthRange = [];
  if (quarter === 1) {
    defaultMonthRange = [new Date(date.getFullYear(), 9, 1), new Date(date.getFullYear(), 12, 0)];
  } else if (quarter === 2) {
    defaultMonthRange = [new Date(date.getFullYear(), 0, 1), new Date(date.getFullYear(), 3, 0)];
  } else if (quarter === 3) {
    defaultMonthRange = [new Date(date.getFullYear(), 3, 1), new Date(date.getFullYear(), 6, 0)];
  } else {
    defaultMonthRange = [new Date(date.getFullYear(), 6, 1), new Date(date.getFullYear(), 9, 0)];
  }

  const [dateValue, setDateValue] = useState(defaultMonthRange);
  const [startDate, setStartDate] = useState(
    defaultMonthRange
      ? moment(defaultMonthRange[0]).format('DD-MM-YYYY')
      : defaultDateRange
      ? moment(defaultDateRange[0]).format('DD-MM-YYYY')
      : ''
  );
  const [endDate, setEndDate] = useState(
    defaultMonthRange
      ? moment(defaultMonthRange[1]).format('DD-MM-YYYY')
      : defaultDateRange
      ? moment(defaultDateRange[1]).format('DD-MM-YYYY')
      : ''
  );

  // const fetchGlobalSearch = useCallback(async () => {
  //   try {
  //     setLoading(true);

  //     const formData = new FormData();
  //     formData.append('client_business_id', businessId);
  //     formData.append('start_date', startDate ? startDate : '');
  //     formData.append('end_date', endDate ? endDate : '');

  //     const { data } = await fileUpload(`${API.GLOBAL_SEARCH}`, formData);

  //     if (data?.status === 200) {
  //       // setLimit(data?.data?.per_page);
  //       // setPage(data?.data?.current_page);
  //       // setTotal(data?.data?.total);
  //       // setFrom(data?.data?.from);
  //       // setTo(data?.data?.to);
  //       console.log(data);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     const { data } = error.response;
  //     setLoading(false);
  //     toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
  //   }
  // }, [businessId, endDate, startDate]);

  // useEffect(() => {
  //   if (globalSearch) {
  //     fetchGlobalSearch();
  //   }
  // }, [fetchGlobalSearch, globalSearch]);

  useEffect(() => {
    if (businessNameData) {
      !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
    }
  }, [businessName, businessId, businessNameData]);

  useEffect(() => {
    if (businessNameData) {
      checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
        if (exists) {
          setBusinessLogo(BusinessLogo);
        } else {
          setBusinessLogo('');
        }
      });
    }
  }, [BusinessLogo, businessNameData]);

  //eslint-disable-next-line
  // const debouncedSave = useCallback(
  //   debounce((nextValue) => {
  //     setGlobalSearchParam(nextValue);
  //   }, 300),
  //   []
  // );

  // const handleSearchChange = (event) => {
  //   const { value: nextValue } = event.target;
  //   setGlobalSearchParamData(nextValue);
  //   if (nextValue?.length >= 2 || nextValue?.length === 0) {
  //     debouncedSave(nextValue);
  //   }
  // };

  // useEffect(() => {
  //   navigate(`&search=${globalSearchParam ? globalSearchParam : ''}`, {
  //     replace: true
  //   });
  // }, [navigate, globalSearchParam]);
  const handleDateRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format('DD-MM-YYYY') : '');
    setEndDate(e ? moment(e[1]).format('DD-MM-YYYY') : '');
  };

  let currentDate = new Date();
  let quarterDate = getQuarter(currentDate);
  let quarterRange = [];
  if (quarterDate === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarterDate === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else if (quarterDate === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  }

  let perviousQuarterRange = [];
  if (quarterDate === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  } else if (quarterDate === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarterDate === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  }

  const dateFilterRange = [
    {
      label: 'This Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    }
  ];

  return (
    <>
      <h1 className="page-title common-page-title">
        <div style={{ display: 'flex' }}>
          {back && <BackButton />}
          {label}
          {children}
        </div>
        {globalSearch && (
          <div className="content-details global-search">
            <div className="search-section">
              <DateRangePicker
                cleanable={false}
                placeholder="Select Date Range"
                onChange={(e) => handleDateRange(e)}
                format="dd-MM-yyyy"
                value={dateValue}
                placement="bottomEnd"
                ranges={dateFilterRange}
                locale={{ ok: 'Apply' }}
                // shouldDisableDate={afterToday()}
                // showOneCalendar
              />
              {/* <Form>
                <FormControl
                  onChange={handleSearchChange}
                  type="text"
                  value={globalSearchParamData}
                  placeholder="Search"
                />
                {globalSearchParamData ? (
                  <Button
                    className="btn-close"
                    onClick={() => {
                      setGlobalSearchParamData('');
                      setGlobalSearchParam('');
                    }}
                  >
                    x
                  </Button>
                ) : (
                  <Button className="fa fa-search">Search</Button>
                )}
              </Form> */}
              <SearchBar businessId={businessId} startDate={startDate} endDate={endDate}/>
            </div>
          </div>
        )}
        {businessNameData && (
          <div className="page-title-right">
            {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
            {BusinessName}
          </div>
        )}
        {businessDashBoard}
      </h1>
    </>
  );
};

export default TitleBar;
