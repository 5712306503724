import React, { useCallback, useEffect, useState } from 'react';
import { API, fileUpload, get, imageDefaultPrefixPath } from '../../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../../components/Form/BackButton';
import { checkIfFileExists } from '../../../common/Misc';
import { getBusinessData } from '../../components/BusinessHelper';
import { Formik } from 'formik';
import InvoicePreview from './components/InvoicePreview';
import InvoiceLayoutSettingsTab from './components/InvoiceLayoutSettingsTab';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spin from '../../../common/Spin';

const InvoiceLayoutSettings = () => {
  const pickerTypeList = [
    {
      label: 'Plain',
      value: 0
    },
    {
      label: 'Single',
      value: 1
    },
    {
      label: 'Multiple',
      value: 2
    }
  ];
  const params = useParams();
  const businessId = params?.business_id;
  const settingId = params?.id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState();
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [footer_display_option_initial, setFooter_display_option_initial] = useState([
    'BpayDetails',
    'Amex',
    'CardDetails',
    'PayIDDetails'
  ]);
  const [initalInvoiceSettings, setInitalInvoiceSettings] = useState();
  const [clientOptionInital, setClientOptionInital] = useState();
  const [invoiceDetailOptionInital, setInvoiceDetailOptionInital] = useState();
  const [subTotalOptionInital, setSubTotalOptionInital] = useState();
  const [footerDisplayOptionInital, setFooterDisplayOptionInital] = useState();
  const [directBankOptionInital, setDirectBankOptionInital] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const handleFormSubmit = async (values) => {
    const formData = new FormData();
    values.mobile = values?.mobile ? `${values?.mobile}`.replace(/ /g, '') : '';
    let settings = {
      accountName: values?.accountName ? values?.accountName : '',
      accountNumber: values?.accountNumber ? values?.accountNumber : '',
      picker_type: values?.picker_type ? values?.picker_type?.value : pickerTypeList[0]?.value,
      primary_color: values?.primary_color ? values?.primary_color : '',
      secondary_color: values?.secondary_color ? values?.secondary_color : '',
      font_color: values?.font_color ? values?.font_color : '#000000',
      bpay_code: values?.bpayCode ? values?.bpayCode : '',
      bsb: values?.bsb ? values?.bsb?.split('-').join('') : '',
      gst_option: values?.gst_option ? parseInt(values?.gst_option) : '',
      invoice_style: values?.invoice_style ? parseInt(values?.invoice_style) : '',
      payID_mobile: values?.mobile ? values?.mobile : '',
      name: values?.name ? values?.name : '',
      payID_email: values?.email ? values?.email : '',
      footerText: values?.footerText ? values?.footerText : '',
      sub_total_format_option: values?.sub_total_format_option
        ? parseInt(values?.sub_total_format_option)
        : '',
      client_option: {
        alternateName: values?.client_option?.includes('AlternateName') ? 1 : 0,
        customer_ref: values?.client_option?.includes('CustomerRef') ? 1 : 0,
        non_individual: values?.client_option?.includes('Contact') ? 1 : 0
      },
      invoice_detail_option: {
        amount: values?.invoice_detail_option?.includes('Amounts') ? 1 : 0
      },
      sub_total_option: {
        dueDate: values?.sub_total_option?.includes('DueDate') ? 1 : 0,
        term: values?.sub_total_option?.includes('Term') ? 1 : 0
      },
      footer_display_option: {
        bpay_details: values?.footer_display_option?.includes('BpayDetails') ? 1 : 0,
        amex: values?.footer_display_option?.includes('Amex') ? 1 : 0,
        card_details: values?.footer_display_option?.includes('CardDetails') ? 1 : 0,
        payID_details: values?.footer_display_option?.includes('PayIDDetails') ? 1 : 0,
        customer_ref: values?.footer_display_option?.includes('CustomerRef') ? 1 : 0,
        direct_debit: values?.direct_bank_option?.includes('DirectDebit') ? 1 : 0
      },
      direct_bank_option: {
        direct_debit: values?.direct_bank_option?.includes('DirectDebit') ? 1 : 0
      }
    };
    formData.append('client_business_id', businessId);
    formData.append('type', 'invoice');
    formData.append('settings', JSON.stringify(settings));
    if (settingId) {
      formData.append('_method', 'put');
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.INVOICE_SETTINGS}${settingId ? '/' + settingId : ''}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        // navigate(`/business/${businessId}/template-settings?tab=invoice&limit=10&page=1&sort_column=&sort_order=`)
        navigate(`/business/${businessId}/invoicing/settings?tab=invoice&childTab=layout`);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const getInvoiceSettings = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.INVOICE_SETTINGS}?client_business_id=${businessId}`);

      let payment_types = [];
      let footer_display = footer_display_option_initial;
      if (data?.data?.payment_options?.note === '1') {
        payment_types.push('Note');
      }
      if (data?.data?.payment_options?.coins === '1') {
        payment_types.push('Coin');
      }
      if (data?.data?.payment_options?.card === '1') {
        payment_types.push('Card');
        footer_display.push('CardDetails');
      }
      if (data?.data?.payment_options?.cheque === '1') {
        payment_types.push('Cheque');
      }
      if (data?.data?.payment_options?.eft === '1') {
        payment_types.push('EFT');
      }
      if (data?.data?.payment_options?.ffr === '1') {
        payment_types.push('FeeFromRefund');
      }
      if (data?.data?.payment_options?.gift_card === '1') {
        payment_types.push('Gift');
      }
      if (data?.data?.payment_options?.amex === '1') {
        payment_types.push('Amex');
        footer_display.push('Amex');
      }
      if (data?.data?.payment_options?.bpay === '1') {
        payment_types.push('BPay');
        footer_display.push('BpayDetails');
      }
      setSettingsData(data?.data);
      setPaymentTypes(payment_types);
      setLoading(false);
      setFooter_display_option_initial(footer_display);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [businessId]);

  useEffect(() => {
    getInvoiceSettings();
  }, [getInvoiceSettings]);

  const getInvoiceTemplateSettings = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await get(`${API.INVOICE_SETTINGS}/${id}`);
      let intialData = data?.data?.settings;
      let clientOptions = [];
      if (intialData?.client_option?.alternateName) {
        clientOptions.push('AlternateName');
      }
      if (intialData?.client_option?.customer_ref) {
        clientOptions.push('CustomerRef');
      }
      if (intialData?.client_option?.non_individual) {
        clientOptions.push('Contact');
      }

      let invoiceDetailOption = [];
      if (intialData?.invoice_detail_option?.amount) {
        invoiceDetailOption.push('Amounts');
      }

      let subTotalOption = [];
      if (intialData?.sub_total_option?.dueDate) {
        subTotalOption.push('DueDate');
      }
      if (intialData?.sub_total_option?.term) {
        subTotalOption.push('Term');
      }

      let footerDisplayOption = [];
      if (intialData?.footer_display_option?.bpay_details) {
        footerDisplayOption.push('BpayDetails');
      }
      if (intialData?.footer_display_option?.amex) {
        footerDisplayOption.push('Amex');
      }
      if (intialData?.footer_display_option?.card_details) {
        footerDisplayOption.push('CardDetails');
      }
      if (intialData?.footer_display_option?.payID_details) {
        footerDisplayOption.push('PayIDDetails');
      }
      if (intialData?.footer_display_option?.customer_ref) {
        footerDisplayOption.push('CustomerRef');
      }

      let directBankOption = [];
      if (intialData?.direct_bank_option?.direct_debit) {
        directBankOption.push('DirectDebit');
      }

      setInitalInvoiceSettings(data?.data?.settings);
      setClientOptionInital(clientOptions);
      setInvoiceDetailOptionInital(invoiceDetailOption);
      setSubTotalOptionInital(subTotalOption);
      setFooterDisplayOptionInital(footerDisplayOption);
      setDirectBankOptionInital(directBankOption);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (settingId) {
      getInvoiceTemplateSettings(settingId);
    }
  }, [getInvoiceTemplateSettings, settingId]);
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Invoice Layout Settings
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1>
          <div className="content-details invoice-layout-page">
            <div className="table-top-btn"></div>
            <div className="custom-table tab-table">
              <Formik
                initialValues={{
                  name: initalInvoiceSettings?.name ? initalInvoiceSettings?.name : '',
                  invoice_style: initalInvoiceSettings?.invoice_style === 1 ? '1' : '0',
                  client_option: clientOptionInital,
                  picker_type: initalInvoiceSettings?.picker_type ? pickerTypeList?.find((i) => i.value === initalInvoiceSettings?.picker_type) : pickerTypeList[0],
                  primary_color: initalInvoiceSettings?.primary_color ? initalInvoiceSettings?.primary_color : '#FFFFFF',
                  secondary_color: initalInvoiceSettings?.secondary_color ? initalInvoiceSettings?.secondary_color : '',
                  font_color: initalInvoiceSettings?.font_color ? initalInvoiceSettings?.font_color : '#000000',
                  invoice_detail_option: invoiceDetailOptionInital
                    ? invoiceDetailOptionInital
                    : ['Amounts'],
                  gst_option: initalInvoiceSettings?.gst_option === 1 ? '1' : '0',
                  sub_total_format_option:
                    initalInvoiceSettings?.sub_total_format_option === 1 ? '1' : '0',
                  sub_total_option: subTotalOptionInital
                    ? subTotalOptionInital
                    : ['DueDate', 'Term'],
                  footer_display_option: footerDisplayOptionInital
                    ? footerDisplayOptionInital
                    : ['BpayDetails', 'PayIDDetails', 'CardDetails'],
                  direct_bank_option: directBankOptionInital
                    ? directBankOptionInital
                    : ['DirectDebit'],
                  bpayCode: initalInvoiceSettings?.bpay_code
                    ? initalInvoiceSettings?.bpay_code
                    : '',
                  email: initalInvoiceSettings?.payID_email
                    ? initalInvoiceSettings?.payID_email
                    : '',
                  mobile: initalInvoiceSettings?.payID_mobile
                    ? initalInvoiceSettings?.payID_mobile
                    : '',
                  accountName: initalInvoiceSettings?.accountName
                    ? initalInvoiceSettings?.accountName
                    : '',
                  bsb: initalInvoiceSettings?.bsb ? initalInvoiceSettings?.bsb : '',
                  accountNumber: initalInvoiceSettings?.accountNumber
                    ? initalInvoiceSettings?.accountNumber
                    : '',
                  footerText: initalInvoiceSettings?.footerText
                    ? initalInvoiceSettings?.footerText
                    : '',
                  payment_option: paymentTypes ? paymentTypes : []
                }}
                enableReinitialize
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        className="entry-section invoice-settings-form"
                      >
                        <div className="form-box">
                          <div className="row">
                            <div className="col-md-6 form-detail-footer-body">
                              <InvoiceLayoutSettingsTab
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                settingsData={settingsData}
                                paymentTypes={paymentTypes}
                                pickerTypeList={pickerTypeList}
                                setFieldValue={setFieldValue}
                              />
                              <div className="settings-button-body">
                                <Button type="submit">Save</Button>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="invoice-preview-layout-body">
                                <InvoicePreview type={values?.invoice_style} values={values} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceLayoutSettings;
