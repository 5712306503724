import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UnsecuredPage from "./UnsecuredPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {
      window.location.hostname === "localhost" ?
        window.self === window.top ? <App /> : <UnsecuredPage />
      :
      <React.StrictMode>
        {window.self === window.top ? <App /> : <UnsecuredPage />}
      </React.StrictMode>
    }
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
