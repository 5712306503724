import * as Yup from "yup";

const PASSWORD_VALIDATOR =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

/**
 * Email Validation
 */
const email = Yup.string()
  .required("Email address is required.")
  .email("Please enter a valid email address. For eg. example@example.com")
  .nullable();

/**
 * First Name Validation
 */
const FIRSTNAME_VALIDATOR = /^[a-zA-Z\s]*$/;
const FIRSTNAME_MESSAGE = "First name can contain letters & spaces.";
const requiredFirstName = Yup.string()
  .required("First name is required.")
  .matches(FIRSTNAME_VALIDATOR, FIRSTNAME_MESSAGE)
  .nullable();
const firstName = Yup.string()
  .matches(FIRSTNAME_VALIDATOR, FIRSTNAME_MESSAGE)
  .nullable();

/**
 * Last Name Validation
 */
const LASTNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&]*$/;
const LASTNAME_MESSAGE =
  "Last name can contain letters, numbers, ampersand(&), dot(.), comma(,) & spaces.";
const requiredLastName = Yup.string()
  .required("Last name is required.")
  .matches(LASTNAME_VALIDATOR, LASTNAME_MESSAGE)
  .nullable();

/**
 * Business Name Validation
 */
const BUSINESSNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/;
const BUSINESSNAME_MESSAGE = "Business name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.";
const requiredBusinessName = Yup.string()
  .required("Business Name is required.")
  .max(200, "Maximum 200 Character")
  .matches(BUSINESSNAME_VALIDATOR, BUSINESSNAME_MESSAGE)
  .nullable();
const businessName = Yup.string()
  .max(200, "Maximum 200 Character")
  .matches(BUSINESSNAME_VALIDATOR, BUSINESSNAME_MESSAGE)
  .nullable();

/**
 * Account Name Validation
 */
const ACCOUNTNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/;
const ACCOUNTNAME_MESSAGE = "Account name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.";
const accountName = Yup.string()
  .max(200, "Maximum 200 Character")
  .matches(ACCOUNTNAME_VALIDATOR, ACCOUNTNAME_MESSAGE)
  .nullable();

/**
 * Ref Number Validation
*/
const REF_NUMBER_VALIDATOR = /^[a-zA-Z\d]*$/;
const REF_NUMBER_MESSAGE =
  "Ref. Number can contain letters, numbers.";

const refNumber = Yup.string()
  .max(10, "Maximum 10 Character")
  .matches(REF_NUMBER_VALIDATOR, REF_NUMBER_MESSAGE)
  .nullable();

/**
 * BPay Ref Number Validation
*/
const BPAY_REF_NUMBER_VALIDATOR = /^[\d]*$/;
const BPAY_REF_NUMBER_MESSAGE =
  "BPay Ref. Number can contain numbers.";

const bPayrefNumber = Yup.string()
  .max(12, "Maximum 12 digits")
  .matches(BPAY_REF_NUMBER_VALIDATOR, BPAY_REF_NUMBER_MESSAGE)
  .nullable();

  /**
 * Address Validation
 */
const ADDRESS_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/;
const ADDRESS_MESSAGE =
  "Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.";
const requiredAddress = Yup.string()
  .required("Address is required.")
  .max(200, "Maximum 200 Character")
  .matches(ADDRESS_VALIDATOR, ADDRESS_MESSAGE)
  .nullable();
const address = Yup.string()
  .max(200, "Maximum 200 Character")
  .matches(ADDRESS_VALIDATOR, ADDRESS_MESSAGE)
  .nullable();

/**
 * Chart Of Account Name Validation
 */
const CHARTOFACCOUNTNAME_VALIDATOR = /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/;
const CHARTOFACCOUNTNAME_MESSAGE =
  "Name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.";
const requiredChartOfAccountName = Yup.string()
  .required("Name is required.")
  .matches(CHARTOFACCOUNTNAME_VALIDATOR, CHARTOFACCOUNTNAME_MESSAGE)
  .nullable();

/**
 * DISCOUNT CODE Validation
 */
const DISCOUNTCODE_VALIDATOR = /^[a-zA-Z\S\d]*$/;
const DISCOUNTCODE_MESSAGE =
  "Code can contain letters and numbers";
const requiredDiscountCode = Yup.string()
  .required("Code is required.")
  .matches(DISCOUNTCODE_VALIDATOR, DISCOUNTCODE_MESSAGE)
  .nullable();

// const entityType = Yup.object().required("Entity type is required.").nullable();

export const LoginSchema = Yup.object().shape({
  email: email,
  password: Yup.string().required("Password is required.").nullable(),
});

export const FirmSignupSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  phone: Yup.string().required("Phone Number is required."),
  email: email,
  password: Yup.string()
    .required("Password is required.")
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  password_confirmation: Yup.string()
    .required("Re-type Password is required.")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
  business_name: requiredBusinessName,
  business_email: email,
  street: requiredAddress,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  postal_code: Yup.string().required("Postal Code is required."),
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").required("ABN is required.").nullable()
  abn: Yup.string().test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  }).required("ABN is required.").nullable()
});

export const FirmSignupAccountingFirmSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  phone: Yup.string().required("Phone Number is required."),
  email: email,
  password: Yup.string()
    .required("Password is required.")
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  password_confirmation: Yup.string()
    .required("Re-type Password is required.")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
  business_name: Yup.string()
    .required("Name of the Practice is required.")
    .max(200, "Maximum 200 Character")
    .matches(
      BUSINESSNAME_VALIDATOR,
      "Name of the Practice can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces."
    )
    .nullable(),
  business_email: email,
  street: requiredAddress,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  postal_code: Yup.string().required("Postal Code is required."),
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").required("ABN is required.").nullable()
  abn: Yup.string().test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  }).required("ABN is required.").nullable()
});

export const EmailVerificationSchema = Yup.object().shape({
  email: email,
});

export const StripePaymentModalSchema = Yup.object().shape({
  quantity: Yup.number()
    .required("Quantity is required.")
    .max(5000, "Maximum Quantity is 5000.")
    .min(1, "Minimum Quantity is 1.")
    .nullable(),
  name: Yup.string().required("Name is required.").nullable(),
  email: Yup.string().when("bank", {
    is: (val) => val && val === true,
    then: Yup.string()
      .required("Email address is required.")
      .email("Please enter a valid email address. For eg. example@example.com")
      .nullable(),
  }),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: email,
});

export const ResetPasswordSchema = Yup.object().shape({
  email: email,
  password: Yup.string()
    .required("New Password is required.")
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  password_confirmation: Yup.string()
    .required("Confirm Password is required.")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
});

export const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .required("Current Password is required.")
    .nullable(),
  new_password: Yup.string()
    .required("New Password is required.")
    .when("old_password", {
      is: (val) => !!val,
      then: Yup.string().notOneOf(
        [Yup.ref("old_password")],
        "Current and New Password should not be the same."
      ),
    })
    .matches(
      PASSWORD_VALIDATOR,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    )
    .nullable(),
  confirm_password: Yup.string()
    .required("Confirm Password is required.")
    .when("new_password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both password need to be same."
      ),
    })
    .nullable(),
});

export const MyProfileSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  email: email,
});

export const AddClientSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  email: email,
  // entity_type: entityType,
  business_name: requiredBusinessName,
  street: address,
  shipping_street: address,
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").required("ABN is required.").nullable()
  abn: Yup.string().test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  }).required("ABN is required.").nullable()
});

export const AddBusinessSchema = Yup.object().shape({
  business_name: requiredBusinessName,
  ref_number: refNumber,
  // abn: Yup.string().required("ABN is required.").nullable(),
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").nullable(),
  abn: Yup.string().notRequired().nullable()
  .test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  }),
  accountNumber: Yup.string().max(9, "Please enter vaild 9 digit Account Number").nullable(),
  bsb: Yup.string().notRequired().nullable()
  .test("len", "Please enter vaild 6 digit BSB", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/-/g, "").length; 
      return val_length_without_spaces === 6;
    } else {
      return true
    }
  }),
  accountName: accountName,
  street: address,
  // office_ids: Yup.array().min(1,"Office is required.").required("Office is required."),
  // user_ids: Yup.array().min(1,"User is required.").required("User is required."),
  // state_id: Yup.lazy((value) => {
  //   switch (typeof value) {
  //     case "object":
  //       return Yup.object().required("State is required.").nullable(); // schema for object
  //     case "string":
  //       return Yup.string().required("State is required.").nullable(); // schema for string
  //     default:
  //       return Yup.mixed().required("State is required.").nullable(); // here you can decide what is the default
  //   }
  // }),
});

export const AddContractorSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  ref_number: refNumber,
  bpay_ref_number: bPayrefNumber,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").nullable(),
  abn: Yup.string().notRequired().nullable()
  .test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  })
});

export const AddCustomerSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  ref_number: refNumber,
  bpay_ref_number: bPayrefNumber,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").nullable(),
  abn: Yup.string().notRequired().nullable()
  .test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  })
});

export const AddSupplierSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  ref_number: refNumber,
  bpay_ref_number: bPayrefNumber,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").nullable(),
  abn: Yup.string().notRequired().nullable()
  .test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  })
});

export const AddContactSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  business_name: businessName,
  ref_number: refNumber,
  bpay_ref_number: bPayrefNumber,
  // entity_type: entityType,
  // email: email,
  street: address,
  shipping_street: address,
  contact_type: Yup.object().required("Contact type is required.").nullable(),
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").nullable(),
  abn: Yup.string().notRequired().nullable()
  .test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  })
});

export const AddChartOfAccountSchema = Yup.object().shape({
  account_type_id: Yup.object()
    .required("Account type is required.")
    .nullable(),
  type: Yup.object().required("Type is required.").nullable(),
  tax: Yup.object().required("Tax is required.").nullable(),
  code: Yup.mixed().required("Code is required.").nullable(),
  name: requiredChartOfAccountName,
});

export const AddJobSchema = Yup.object().shape({
  code: Yup.string().required("Code is required."),
  description: Yup.string()
    .required("Description is required.")
    .max(150, "Maximum 150 Character"),
});

export const AddTaskSchema = Yup.object().shape({
  code: Yup.string().required("Code is required."),
  description: Yup.string()
    .required("Description is required.")
    .max(150, "Maximum 150 Character"),
});

export const AddSearchTermSchema = Yup.object().shape({
  chart_of_account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
  term: Yup.string().required("Term is required.").nullable(),
  contact_id: Yup.object().required("Contact name is required.").nullable(),
  // amount: Yup.number().typeError("Amount can contain Number.")
  // .when("amount_type", {
  //   is: (amount_type) => amount_type !== undefined,
  //   then: Yup.number().required('Amount is required').typeError("Amount can contain Number.")
  // }),
});

export const AddBankTransactionSchema = Yup.object().shape({
  account: Yup.object().required("Account is required.").nullable(),
  transactionDate: Yup.string()
    .required("Transaction Date is required.")
    .nullable(),
  amount: Yup.number()
    .required("Amount is required.")
    .typeError("Amount can contain Number.")
    .nullable(),
  baseType: Yup.object().required("Transaction Type is required.").nullable(),
  description: Yup.string()
    .required("Description is required.")
    .max(150, "Maximum 150 Character")
    .nullable(),
});

export const AddOfficeSchema = Yup.object().shape({
  street: requiredAddress,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  postal_code: Yup.string().required("Postal Code is required."),
  // business_ids: Yup.array().min(1,"Business is required.").required("Business is required."),
  // user_ids: Yup.array().min(1,"User is required.").required("User is required."),
});

export const AddUserSchema = Yup.object().shape({
  first_name: requiredFirstName,
  last_name: requiredLastName,
  email: email,
  role_id: Yup.object().required("Role is required.").nullable(),
  // office_id: Yup.object().required("Office is required.").nullable(),
  // office_ids: Yup.array().min(1,"Office is required.").required("Office is required."),
  // business_ids: Yup.array().min(1,"Business is required.").required("Business is required."),
});

export const InviteAccountingFirmSchema = Yup.object().shape({
  email: email,
});

export const FirmSettingSchema = Yup.object().shape({
  business_name: requiredBusinessName,
  state_id: Yup.object().required("State name is required.").nullable(),
  suburb_id: Yup.object().required("Suburb name is required.").nullable(),
  // tan: Yup.number()
  //   .typeError("Tax Agent Number can contain numbers.")
  //   .nullable(),
  tan: Yup.string()
    .matches(
      /^\d{0,8}$/,
      "Tax Agent Number can contain numbers and must be maximum 8 digit number."
    )
    .nullable(),
  // abn: Yup.string().min(14, "Please enter vaild 11 digit ABN").max(14, "Please enter vaild 11 digit ABN").required("ABN is required.").nullable()
  abn: Yup.string().test("len", "Please enter vaild 11 digit ABN", (val) => { 
    if(val !== undefined && val !== null){
      const val_length_without_spaces = val?.replace(/ /g, "").length; 
      return val_length_without_spaces === 11;
    } else {
      return true
    }
  }).required("ABN is required.").nullable()
});

export const AddContactForRecolocileTransactionSchema = Yup.object().shape({
  first_name: firstName,
  last_name: requiredLastName,
  contact_type: Yup.object().required("Contact type is required.").nullable(),
});

export const AddDiscountSchema = Yup.object().shape({
  code: requiredDiscountCode,
  description: Yup.string().required("Description is required."),
  discount: Yup.string().required("Discount is required."),
});

export const AddParagrahSchema = Yup.object().shape({
  // business_job_id: Yup.object().required("Job is required.").nullable(),
  description: Yup.object().required("Description is required.").nullable(),
});

export const AddCashPaymentSchema = Yup.object().shape({
  transactionDate: Yup.string()
  .required("Transaction Date is required.")
  .nullable(),
  account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
  gst: Yup.object()
    .required("GST Type is required.")
    .nullable(),
  description: Yup.string().required("Description is required.").nullable(),
  amount: Yup.number().required('Amount is required').typeError("Amount can contain Number.")
});

export const ReallocteItemSchema = Yup.object().shape({
  account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
  gst: Yup.object()
    .required("GST Type is required.")
    .nullable(),
});

export const TrailReallocteItemSchema = Yup.object().shape({
  chart_of_account_id: Yup.object()
    .required("Account name is required.")
    .nullable(),
  gst: Yup.object()
    .required("GST Type is required.")
    .nullable(),
});

export const AddQuoteSchema = Yup.object().shape({
  quote_number: Yup.string().required("Quote Number is required").nullable(),
  invoice_to: Yup.object().required("Invoice To is required.").nullable(),
  email_to: email,
  // prepared_by: Yup.object().required("Prepared By is required.").nullable(),
  office_id: Yup.object().required("Office is required.").nullable(),
  template: Yup.object().required("Template is required.").nullable(),
})

export const AddCreditSchema = Yup.object().shape({
  credit_date: Yup.string().required("Credit Date is required.").nullable(),
  credit_number: Yup.string().required("Credit Number is required.").nullable(),
  credit_amount: Yup.string().required('Credit Amount is required').nullable()
});
