import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatName,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getContractorPopupDetail } from "./ContractorHelper";

const ContractorDetailPopup = ({ contractorId, toggleShowModal, showView }) => {
  const [contractorDetail, setContractorDetail] = useState(null);
  const [contractorLoading, setContractorLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const contractorDetailData = await getContractorPopupDetail(
        contractorId,
        true
      );
      setContractorDetail(contractorDetailData);
      setContractorLoading(false);
    }
    fetchData();
  }, [contractorId, setContractorDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Contractor Details
      </Modal.Header>
      <Modal.Body>
        {contractorLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {contractorDetail?.profile_pic === null ||
                contractorDetail?.profile_pic === "" ||
                contractorDetail?.profile_pic === undefined ? (
                  <span>{contractorDetail?.first_name ? contractorDetail?.first_name?.charAt(0) : contractorDetail?.last_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${contractorDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {formatName(
                    contractorDetail?.first_name,
                    contractorDetail?.last_name
                  )}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Name:</strong>{" "}
                  {contractorDetail?.first_name
                    ? contractorDetail?.first_name
                    : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong>{" "}
                  {contractorDetail?.last_name
                    ? contractorDetail?.last_name
                    : "-"}
                </p>
                <p>
                  <strong>Display Name:</strong>{" "}
                  {contractorDetail?.display_name
                    ? contractorDetail?.display_name
                    : "-"}
                </p>
                <p>
                  <strong>Email Address:</strong>{" "}
                  {contractorDetail?.email ? contractorDetail?.email : "-"}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {contractorDetail?.phone
                    ? formatPhone(contractorDetail?.phone)
                    : "-"}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {contractorDetail?.mobile
                    ? formatMobile(contractorDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {contractorDetail?.business_name
                    ? contractorDetail?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {contractorDetail?.abn
                    ? formatABN(contractorDetail?.abn)
                    : "-"}
                </p>
                <p>
                  <strong>Entity Type:</strong>{" "}
                  {contractorDetail?.entity_type
                    ? contractorDetail?.entity_type
                    : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    contractorDetail?.street,
                    contractorDetail?.suburb?.name,
                    contractorDetail?.state?.name,
                    contractorDetail?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    contractorDetail?.shipping_street,
                    contractorDetail?.shipping_suburb?.name,
                    contractorDetail?.shipping_state?.name,
                    contractorDetail?.shipping_postal_code
                  )}
                </p>
                <p>
                  <strong>Ref. Number:</strong>{" "}
                  {contractorDetail?.ref_number
                    ? contractorDetail?.ref_number
                    : "-"}
                </p>
                <p>
                  <strong>BPay Ref. Number:</strong>{" "}
                  {contractorDetail?.bpay_ref_number
                    ? contractorDetail?.bpay_ref_number
                    : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContractorDetailPopup;
