import { Formik } from "formik";
import React, { useRef, useState } from "react";
import {
  API,
  entityTypes,
  fileUpload,
  metaTitle,
} from "../../../config";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "./components/Form";
import Spin from "../../common/Spin";
import { useStateList, useSuburbListE } from "./components/ContactHelper";
import { AddContactSchema } from "../../common/Validation";
import { useEffect } from "react";
import TitleBar from "../../common/TitleBar";

const AddContact = () => {
  const [contactData, setContactData] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const businessId = params?.business_id;
  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  useEffect(() => {
    window.document.title = `${metaTitle} | Add Contact`
  },[])

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";
    value.entity_type = value?.entity_type?.value;
    value.contact_type = value?.contact_type?.value;

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.value;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.value;

    formData.append("client_business_id", businessId);
    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("display_name", value?.display_name ? value?.display_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "ref_number",
      value?.ref_number ? value?.ref_number : ""
    );
    formData.append(
      "bpay_ref_number",
      value?.bpay_ref_number ? value?.bpay_ref_number : ""
    );
    formData.append(
      "entity_type",
      value?.entity_type ? value?.entity_type : ""
    );
    formData.append("abn", value?.abn ? value?.abn : "");
    formData.append("contact_type", value?.contact_type);

    // // formData.append("_method", "put");
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.CONTACT_Add}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate("/business/" + businessId + "/all-contacts");
      }
      return data?.data;
    } catch (error) {
      setContactData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const state = stateListing?.find(
    (item) => item?.value === contactData?.state_id
  );

  //ShippingState
  const shippingState = stateListing?.find(
    (item) => item?.value === contactData?.shipping_state_id
  );

  // Suburb
  const { suburbLoading, suburbList = [] } = useSuburbListE(
    contactData?.state_id
  );
  const suburbListing = suburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const suburb = suburbListing?.find(
    (item) => item?.value === contactData?.suburb_id
  );

  // ShippingSuburb
  const { suburbLoading2, shippingSuburbList = [] } = useSuburbListE(
    contactData?.shipping_state_id
  );
  const shippingSuburbListing = shippingSuburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const shippingSuburb = shippingSuburbListing?.find(
    (item) => item?.value === contactData?.shipping_suburb_id
  );

  const ContactTypeListing = [
    { label: "Customer", value: 1 },
    { label: "Supplier", value: 2 },
    { label: "Contractor", value: 3 },
  ];

  const contactType = ContactTypeListing?.find(
    (item) => item?.value === parseInt(contactData?.contact_type)
  );

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"New Contact"}
          />
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  first_name: contactData?.first_name,
                  last_name: contactData?.last_name,
                  display_name: contactData?.display_name,
                  phone: contactData?.phone,
                  mobile: contactData?.mobile,
                  email: contactData?.email,
                  business_name: contactData?.business_name,
                  ref_number: contactData?.ref_number,
                  bpay_ref_number: contactData?.bpay_ref_number,
                  abn: contactData?.abn,
                  entity_type: entityTypes?.find(
                    (item) => item?.value === contactData?.entity_type
                  ),
                  contact_type: contactType,

                  street: contactData?.street,
                  state_id: state,
                  suburb_id: suburb,
                  postal_code: contactData?.postal_code,

                  same_address: false,

                  shipping_street: contactData?.shipping_street,
                  shipping_state_id: shippingState,
                  shipping_suburb_id: shippingSuburb,
                  shipping_postal_code: contactData?.shipping_postal_code,
                }}
                enableReinitialize
                innerRef={formValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddContactSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                        erSuburbLoading={suburbLoading}
                        erSuburbLoading2={suburbLoading2}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContact;
