import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BootStrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spin from '../../common/Spin';
import Pagination from '../../common/Pagination';
import { API, get } from '../../../config';
import AddParagrah from './components/AddParagrah';
import ParagrahActionButton from './components/ParagrahActionButton';
import TitleBar from '../../common/TitleBar';

const StandardParagraph = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [paragrahList, setParagrahList] = useState([]);
  const [paragrahLoading, setParagrahLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;

  const [showAddParagrah, setShowAddParagrah] = useState(false);

  const handleDelete = () => {
    fetchParagrahs();
  };

  const handleEdit = () => {
    fetchParagrahs();
  };

  const fetchParagrahs = useCallback(async () => {
    try {
      setParagrahLoading(true);
      const { data } = await get(
        `${API.GET_STANDARD_PARAGRAH}?client_business_id=${businessId ? businessId : ''}&limit=${
          limit ? limit : 10
        }&page=${page ? page : 1}`
      );
      setParagrahLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const paragrahsList = data.data.data;
      setParagrahList(paragrahsList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setParagrahLoading(false);
      setParagrahList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, businessId]);

  const paragrahsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: paragrahList[i]?.id,
        business_job_id: paragrahList[i]?.business_job?.code
          ? paragrahList[i]?.business_job?.code
          : '-',
        description: (
          <div className='standard-paragrah-list-desc'
          dangerouslySetInnerHTML={{
            __html: paragrahList[i]?.description,
          }}
          >
          </div>
        ),
        action: (
          <ParagrahActionButton
            id={paragrahList[i]?.id}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        )
      });
    }
    return items;
  };

  const paragrahs = paragrahsGenerator(paragrahList?.length);

  const columns = [
    // {
    //   dataField: 'business_job_id',
    //   text: 'Job'
    // },
    {
      dataField: 'description',
      text: 'Description',
      style:{ width: "90%"}
    },
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      style: { width: '127px' }
    }
  ];

  const handleTableChange = (type) => {
    if (type === 'sort') {
      setPage(1);
    }
  };

  useEffect(() => {
    navigate(`?tab=standard-paragraph&limit=${limit ? limit : 10}&page=${page ? page : 1}`, {
      replace: true
    });
  }, [navigate, limit, page]);

  useEffect(() => {
    fetchParagrahs();
    window.document.title = "SME CashBook | Standard Paragraphs"
  }, [fetchParagrahs]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleAdd = () => {
    fetchParagrahs();
  };

  const toggleAddParagrahModal = () => {
    setShowAddParagrah(!showAddParagrah);
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={"Standard Paragraphs"}
            />
            <div className="content-details">
              <div className="table-top-btn">
                <div className="table-btn">
                  <Button variant="primary" onClick={toggleAddParagrahModal}>
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table standard-paragrah-table">
                {paragrahLoading && <Spin />}
                <BootStrapTable
                  keyField="key"
                  remote
                  data={paragrahs}
                  columns={columns}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddParagrah && (
        <AddParagrah
          businessId={businessId}
          showAddParagrah={showAddParagrah}
          toggleAddParagrahModal={toggleAddParagrahModal}
          handleAdd={handleAdd}
        />
      )}
    </>
  );
};

export default StandardParagraph;
