import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { API, get } from "../../config";
import Spin from "../common/Spin";

const InviteAccountingFirm = () => {
  // const navigate = useNavigate();
  const [loading] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const clientId = query.get("clientId");
  const clientBusinessId = query.get("clientBusinessId");
  const accountingFirmId = query.get("accountingFirmId");
  const accountingFirmEmail = query.get("accountingFirmEmail");
  const accept = query.get("accept");

  localStorage.setItem("sme-invite-clientId", clientId ? clientId : "");
  localStorage.setItem(
    "sme-invite-clientBusinessId",
    clientBusinessId ? clientBusinessId : ""
  );
  localStorage.setItem(
    "sme-invite-accountingFirmId",
    accountingFirmId ? accountingFirmId : ""
  );
  localStorage.setItem(
    "sme-invite-accountingFirmEmail",
    accountingFirmEmail ? accountingFirmEmail : ""
  );
  localStorage.setItem("sme-invite-accept", accept ? accept : "");
  const hasToken = localStorage.getItem("sme-auth-token");

  const logout = useCallback(() => {
    get(API.LOGOUT)
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("sme-auth-token");
          localStorage.removeItem("sme-auth-email");
          localStorage.removeItem("sme-EmailVarification");
          localStorage.removeItem("sme-userId");
          localStorage.removeItem("sme-ProfilePhoto");
          localStorage.removeItem("sme-Name");
          localStorage.removeItem("sme-role");
          localStorage.removeItem("sme-clientId");
          accountingFirmId
            ? (window.location.href = "/")
            : (window.location.href = "/signup");
          // window.location.href = "/";
        }
      })
      .catch(() => {
        var error = {};
        error["myna_error"] =
          "There is some issue with request, please try after some time.";
        this.setState({ errors: error });
      });
  }, [accountingFirmId]);

  useEffect(() => {
    hasToken
      ? logout()
      : accountingFirmId
      ? (window.location.href = "/")
      : (window.location.href = "/signup");
  }, [hasToken, logout, accountingFirmId]);

  return (
    <>
      {loading && <Spin />}
      {/* {hasToken && logout} */}
      <tetx>Please wait...</tetx>
    </>
  );
};

export default InviteAccountingFirm;
