import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import InvoiceDetailsSetting from './InvoiceDetailsSetting';
import InvoiceFooterSettings from './InvoiceFooterSettings';
const tabsKey = {
  'invoice-detail': 1,
  'invoice-footer': 2
};

const InvoiceLayoutSettingsTab = ({ values, handleChange, handleBlur, settingsData, paymentTypes, pickerTypeList, setFieldValue }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab') || 'invoice-detail';
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1 ? 'Detail' : 'Payment Advice'
  );

  return (
    <>
      <div className="table-top-btn">
        <ul
          className="nav nav-tabs"
          onClick={(event) => {
            setActiveTab(event?.target?.innerText);
          }}
        >
          <li className={activeTab === 'Detail' ? 'active' : ''}>
            <Link key={1}>Detail</Link>
          </li>
          <li className={activeTab === 'Payment Advice' ? 'active' : ''}>
            <Link key={2}>Payment Advice</Link>
          </li>
        </ul>
      </div>
      <div className="custom-table tab-table">
        {activeTab === 'Detail' && (
          <InvoiceDetailsSetting
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            pickerTypeList={pickerTypeList}
            setFieldValue={setFieldValue}
          />
        )}
        {activeTab === 'Payment Advice' && 
          <InvoiceFooterSettings 
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            paymentTypes={paymentTypes}
          />
        }
      </div>
    </>
  );
};
export default InvoiceLayoutSettingsTab;
