import React, { useRef } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import {
  getUserDetail,
  useBusinessList,
  useOfficeList,
  useRoleList,
} from "./components/UserHelper";
import { useEffect } from "react";
import Spin from "../common/Spin";
import { API, fileUpload } from "../../config";
import { toast } from "react-toastify";
import { AddUserSchema } from "../common/Validation";
import { formatedAddress } from "../common/Misc";

const EditUser = ({ userId, toggleUserModal, showUser, clientId }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [selectedOffices, setSelectedOffices] = useState();
  const [selectedBusinesses, setSelectedBusinesses] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.role_id = value?.role_id?.value ? value?.role_id?.value : "";
    // value.office_id = value?.office_id?.value ? value?.office_id?.value : "";
    value.is_admin_user = value?.is_admin_user === true ? 1 : 0;

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append("role_id", value?.role_id ? value?.role_id : "");
    // formData.append("office_id", value?.office_id ? value?.office_id : "");
    if(selectedOffices){
      if(selectedOffices.length > 0){
        for(let i = 0; i < selectedOffices.length; i++){
          formData.append(`office_ids[${i}]`, selectedOffices[i]);
        }
      }
    }
    if(selectedBusinesses){
      if(selectedBusinesses.length > 0){
        for(let i = 0; i < selectedBusinesses.length; i++){
          formData.append(`business_ids[${i}]`, selectedBusinesses[i]);
        }
      }
    }
    formData.append("is_admin_user", value?.is_admin_user);
    if(clientId){
      formData.append("client_id", clientId);
    }
    formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${clientId ? API.EDIT_CLIENT_USER : API.USER}/${userId}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleUserModal();
        setSelectedBusinesses([]);
        setSelectedOffices([])
      }
      return data?.data;
    } catch (error) {
      setUserData(value);
      setLoading(false);
      setSelectedBusinesses([]);
      setSelectedOffices([])
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const data = await getUserDetail(userId, clientId);
        setUserData(data);
        setSelectedBusinesses(data?.client_user?.business_ids)
        setSelectedOffices(data?.client_user?.office_ids)
        setLoading(false);
      } catch (e) {
        setUserData(null);
      }
    }
    fetchData();
  }, [userId, clientId]);

  //Role
  const { roleLoading, roleList = [] } = useRoleList();
  const roleListing = roleList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const role = roleListing?.find((item) => item?.value === userData?.role_id);

  // Office
  const { officeLoading, officeList = [] } = useOfficeList();
  const officeListing = officeList?.map((item) => {
    const name = formatedAddress(item?.street, item?.suburb?.name, item?.state?.short_name, item?.postal_code);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  const office = officeListing?.find(
    (item) =>
      item?.value ===
      (userData?.office_id ? userData?.office_id : userData?.staff?.office_id)
  );

  //Business List
  const { businessLoading, businessList = [] } = useBusinessList();
  const businessListing = businessList?.map((item) => {
    const name = item?.business_name;
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  return (
    <>
      <Modal
        size="lg"
        show={showUser}
        onHide={toggleUserModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          User Details
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              first_name: userData?.first_name ? userData?.first_name : "",
              last_name: userData?.last_name ? userData?.last_name : "",
              email: userData?.email ? userData?.email : "",
              role_id: role,
              office_id: office,
              is_admin_user: userData?.is_admin_user
                ? userData?.is_admin_user
                : userData?.staff?.is_admin_user
                ? userData?.staff?.is_admin_user === 1
                  ? true
                  : false
                : false,
              office_ids: selectedOffices,
              business_ids: selectedBusinesses
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddUserSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading || businessLoading || officeLoading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleUserModal={toggleUserModal}
                    roleListing={roleListing}
                    roleLoading={roleLoading}
                    businessLoading={businessLoading}
                    businessListing={businessListing}
                    officeListing={officeListing}
                    officeLoading={officeLoading}
                    setSelectedOffices={setSelectedOffices}
                    selectedOffices={selectedOffices}
                    selectedBusinesses={selectedBusinesses}
                    setSelectedBusinesses={setSelectedBusinesses}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUser;
