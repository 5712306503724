import React from "react";
// import HeaderNav from "./components/Header/HeaderNav";
// import "../public/css/style.css";
// import Sitelogo from "./components/Header/Logo";
// import UserProfile from "./components/Header/UserProfile";
import { Navigate, useParams } from "react-router-dom";
import Header from "./components/Header/Index";
import BusinessHeader from "./components/Header/BusinessIndex";
import ClientHeader from "./components/Header/ClientIndex";
import SubscriptionNotFound from "./pages/common/SubscriptionNotFound";

const ProtectedRoute = ({ notSubscription = false, component: Component, ...rest }) => {
  const params = useParams();
  const token = localStorage.getItem("sme-auth-token");
  const businessId = params?.business_id;
  const clientId = params?.client_id;
  // const [isVisible, setIsVisible] = useState(false);
  // const [selectedCustomer, setSelectedCustomer] = useState(null);
  const tfaCheck = localStorage.getItem('sme-auth-tfa');
  const role = localStorage.getItem("sme-role");
  let subscription = localStorage.getItem('sme-subscription');
  if (token) {
    if (clientId) {
      return (
        <>
          <div className="client-section">
            <ClientHeader />
            {(notSubscription === true || parseInt(subscription) === 1) ?
                  <Component {...rest} />
                : <SubscriptionNotFound />
                }
          </div>
        </>
      );
    } else {
      if(tfaCheck === '2' || tfaCheck === '-1') {
        if (businessId) {
          return (
            <>
              <div className="business-section">
                <BusinessHeader />
                {(notSubscription === true || parseInt(subscription) === 1) ?
                  <Component {...rest} />
                : <SubscriptionNotFound />
                }

              </div>
            </>
          );
        } else {
          if(role === "Client" || role === "Client User"){
            return (
              <>
                <div className="client-section">
                  <Header /> 
                  {(notSubscription === true || parseInt(subscription) === 1) ?
                  <Component {...rest} />
                : <SubscriptionNotFound />
                }
                </div>
              </>
            );
          }
          else if(role !== "Admin"){
            return (
              <>
                <Header /> 
                {(notSubscription === true || parseInt(subscription) === 1) ?
                <Component {...rest} />
                : <SubscriptionNotFound />
                }
              </>
            );
          }
          else {
            return (
              <>
                <Header />
                <Component {...rest} />
              </>
            );
          }
        }
      } else {
        return <Navigate to="/2fa" />;
      }
    }
  } else {
    return <Navigate to="/#" />;
  }
};

export default ProtectedRoute;
