import { Formik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import NumberGeneratorSettings from './components/NumberGeneratorSettings';
import PaymentOptions from './components/PaymentOptions';
// import EmailSettings from './components/EmailSettings';
// import { ContentState, EditorState, convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
import { API, fileUpload, get } from '../../../../config';
import { useCallback } from 'react';
// import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Spin from '../../../common/Spin';
import { useNavigate, useParams } from 'react-router-dom';
import PaymentTerms from './components/PaymentTerms';

const InvoiceSettings = () => {
  const params = useParams();
  const businessId = params?.business_id;
  // const [editorState, setEditorState] = useState(null);
  // const [editorNonIndividualState, setEditorNonIndividualState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState();
  const [paymentTypes, setPaymentTypes] = useState([]);
  const navigate = useNavigate();

  // const getBodyIndividual = (html) => {
  //   const contentBlock = htmlToDraft(html);
  //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //   const value = EditorState.createWithContent(contentState) || null;
  //   return value;
  // };

  // const getBodyNonIndividual = (html) => {
  //   const contentBlock = htmlToDraft(html);
  //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //   const value = EditorState.createWithContent(contentState) || null;
  //   return value;
  // };

  const getInvoiceSettings = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_INVOICE_SETTINGS}?client_business_id=${businessId}`);
      // const invoiceSettingsObj = data;
      // const html = invoiceSettingsObj?.data?.individual_email['body']
      //   ? invoiceSettingsObj?.data?.individual_email['body']
      //   : ``;
      // let body_individual = null;
      // if (html) {
      //   body_individual = getBodyIndividual(html);
      // }
      // const bodyNON = invoiceSettingsObj?.data?.non_individual_email['body']
      //   ? invoiceSettingsObj?.data?.non_individual_email['body']
      //   : ``;
      // let body_nonindividual = null;
      // if (bodyNON) {
      //   body_nonindividual = getBodyNonIndividual(bodyNON);
      // }
      // setEditorState(body_individual);
      // setEditorNonIndividualState(body_nonindividual);
      let payment_types = [];
      if (data?.data?.payment_options?.note === '1') {
        payment_types.push('Note');
      }
      if (data?.data?.payment_options?.coins === '1') {
        payment_types.push('Coin');
      }
      if (data?.data?.payment_options?.card === '1') {
        payment_types.push('Card');
      }
      if (data?.data?.payment_options?.cheque === '1') {
        payment_types.push('Cheque');
      }
      if (data?.data?.payment_options?.eft === '1') {
        payment_types.push('EFT');
      }
      if (data?.data?.payment_options?.ffr === '1') {
        payment_types.push('FeeFromRefund');
      }
      if (data?.data?.payment_options?.gift_card === '1') {
        payment_types.push('Gift');
      }
      if (data?.data?.payment_options?.amex === '1') {
        payment_types.push('Amex');
      }
      if (data?.data?.payment_options?.bpay === '1') {
        payment_types.push('BPay');
      }
      if (data?.data?.payment_options?.payId === '1') {
        payment_types.push('PayID');
      }
      setSettingsData(data?.data);
      setPaymentTypes(payment_types);
      setLoading(false);
    } catch (error) {
      // setEditorNonIndividualState(null);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }, [businessId]);

  useEffect(() => {
    getInvoiceSettings();
  }, [getInvoiceSettings]);

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    // let body_individual = '';
    // if (editorState) {
    //   if (
    //     convertToRaw(editorState?.getCurrentContent()).blocks.length === 1 &&
    //     convertToRaw(editorState?.getCurrentContent()).blocks[0].text === ''
    //   ) {
    //     body_individual = '';
    //   } else {
    //     body_individual = editorState
    //       ? draftToHtml(convertToRaw(editorState?.getCurrentContent()))
    //       : '';
    //   }
    // }

    // let body_nonindividual = '';
    // if (editorNonIndividualState) {
    //   if (
    //     convertToRaw(editorNonIndividualState?.getCurrentContent()).blocks.length === 1 &&
    //     convertToRaw(editorNonIndividualState?.getCurrentContent()).blocks[0].text === ''
    //   ) {
    //     body_nonindividual = '';
    //   } else {
    //     body_nonindividual = editorNonIndividualState
    //       ? draftToHtml(convertToRaw(editorNonIndividualState?.getCurrentContent()))
    //       : '';
    //   }
    // }

    formData.append('client_business_id', businessId);
    formData.append('number', values?.number_option);
    // formData.append('individual_email[subject]', values?.subject_individual);
    // formData.append('individual_email[body]', body_individual);
    // formData.append('non_individual_email[subject]', values?.subject_nonindividual);
    // formData.append('non_individual_email[body]', body_nonindividual);
    formData.append('payment_options[note]', values?.payment_option?.includes('Note') ? 1 : 0);
    formData.append('payment_options[coins]', values?.payment_option?.includes('Coin') ? 1 : 0);
    formData.append('payment_options[card]', values?.payment_option?.includes('Card') ? 1 : 0);
    formData.append('payment_options[cheque]', values?.payment_option?.includes('Cheque') ? 1 : 0);
    formData.append('payment_options[eft]', values?.payment_option?.includes('EFT') ? 1 : 0);
    formData.append(
      'payment_options[ffr]',
      values?.payment_option?.includes('FeeFromRefund') ? 1 : 0
    );
    formData.append('payment_options[gift_card]', values?.payment_option?.includes('Gift') ? 1 : 0);
    formData.append('payment_options[amex]', values?.payment_option?.includes('Amex') ? 1 : 0);
    formData.append('payment_options[bpay]', values?.payment_option?.includes('BPay') ? 1 : 0);
    formData.append('payment_options[payId]', values?.payment_option?.includes('PayID') ? 1 : 0);
    formData.append('payment_type_default', values?.payment_type_default);

    formData.append('payment_terms', values?.payment_terms ? values?.payment_terms : '14');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.GET_INVOICE_SETTINGS}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        getInvoiceSettings();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    navigate('?tab=invoice&childTab=global', {
      replace: true
    });
  }, [navigate]);
  return (
    <>
      {loading && <Spin />}
      <Formik
        initialValues={{
          // subject_individual: settingsData?.individual_email?.subject
          //   ? settingsData?.individual_email?.subject
          //   : '',
          // subject_nonindividual: settingsData?.non_individual_email?.subject
          //   ? settingsData?.non_individual_email?.subject
          //   : '',
          number_option:
            settingsData?.number !== null && settingsData?.number !== undefined
              ? String(settingsData?.number)
              : '1',
          payment_option: paymentTypes ? paymentTypes : '',
          payment_type_default: settingsData?.payment_type_default
            ? settingsData?.payment_type_default
            : '',
          payment_terms: settingsData?.payment_terms ? settingsData?.payment_terms : '14'
          // body_individual: '',
          // body_nonindividual: ''
        }}
        enableReinitialize
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        //   validationSchema={AddChartOfAccountSchema}
      >
        {({ values, setErrors, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <>
              {/* {loading && <Spin />} */}
              <form
                onSubmit={handleSubmit}
                noValidate
                className="entry-section invoice-settings-form"
              >
                <div className="form-box">
                  <div className="row">
                    {/* <div className="col-md-6">
                      <EmailSettings
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setEditorNonIndividualState={setEditorNonIndividualState}
                        setEditorState={setEditorState}
                        editorState={editorState}
                        editorNonIndividualState={editorNonIndividualState}
                      />
                    </div> */}
                    <div className="col-md-6">
                      <PaymentOptions values={values} setFieldValue={setFieldValue} />
                    </div>
                    <div className="col-md-6">
                      <div className="row settings-box">
                        <div className="col-md-8">
                          <NumberGeneratorSettings label="Invoice Number options" type="invoice" />
                        </div>
                        <div className="col-md-4">
                          <PaymentTerms
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                      <div className="settings-button-body">
                        <Button type="submit">Save</Button>
                        <Button data-dismiss="modal" aria-label="Close" onClick={() => {}}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default InvoiceSettings;
