import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "../../assets/css/style.css";

class HeaderNav extends React.Component {
  render() {
    const role = localStorage.getItem("sme-role");
    return (
      <ul className="header-nav">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {/* <li className="nav-item active">
                <Link
                  to={{
                    pathname: "/dashboard",
                  }}
                >
                  Home
                </Link>
              </li> */}
              <li className="nav-item active">
                {role && role === "Admin" && (
                  <Link
                    to={{
                      pathname: "/admin/dashboard",
                    }}
                  >
                    Dashboard
                  </Link>
                )}
              </li>
              <li className="nav-item active">
                {role && (role === "Client" || role === "Client User") ? (
                  <Link
                    to={{
                      pathname: "/businesses",
                    }}
                  >
                    Businesses
                  </Link>
                ) : (
                  role !== "Admin" && (
                    <Link
                      to={{
                        pathname: "/clients",
                      }}
                    >
                      Clients
                    </Link>
                  )
                )}
                {role && role === "Admin" && (
                  <Link
                    to={{
                      pathname: "/admin/subscriptions",
                    }}
                  >
                    Subscriptions
                  </Link>
                )}
              </li>
              {role && role === "Admin" && (
                <li className="nav-item active">
                  <Link
                    to={{
                      pathname: "/admin/registered-users",
                    }}
                  >
                    Registered Users
                  </Link>
                </li>
              )}

              {role && role !== 'Client' && role !== 'Client User' && role !== "Admin" && (
                <li className="nav-item active">
                  <Link
                    to={{
                      pathname: "/users",
                    }}
                  >
                    Users
                  </Link>
                </li>
              )}
              
              {role && (role === 'Client' || role === 'Client User') && role !== "Admin" && (
                <li className="nav-item active">
                  <Link
                    to={{
                      pathname: "/client-users"
                    }}
                  >
                    Users
                  </Link>
                </li>
              )}
              {role && role !== "Admin" && (
                <li className="nav-item active">
                  <Link
                    to={{
                      pathname: "/offices",
                    }}
                  >
                    Offices
                  </Link>
                </li>
              )}

              {role && role !== "Admin" ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Settings
                  </a>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {role !== 'Client' && <li>
                      <Dropdown.Item as={Link} to={`/user-settings?tab=invitations-from-client`}>
                        Invitations From Client
                      </Dropdown.Item>
                    </li>}
                    <li>
                      <Dropdown.Item as={Link} to={`/general-settings`}>
                        General Settings
                      </Dropdown.Item>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item active">
                  <Link
                  // to={{
                  //   pathname: "/",
                  // }}
                  >
                    Settings
                  </Link>
                </li>
              )}

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Settings
                </a>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Dropdown.Item
                      href={`/user-settings?tab=invitations-from-client`}
                    >
                      Invitations From Client
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item href={`/general-settings`}>
                      General Settings
                    </Dropdown.Item>
                  </li>
                </div>
              </li> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Help
                </a>

                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Dropdown.Item href="https://smecashbook.com.au/terms-conditions/">
                    <a
                      href="https://smecashbook.com.au/terms-conditions/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ width: '100%', display: 'block' }}
                    >
                      Terms of Use
                    </a>
                    </Dropdown.Item>
                  </li>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </ul>
    );
  }
}

export default HeaderNav;
