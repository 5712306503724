import React, { useState } from "react";
import { Steps } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import SelectAuthenticate from "../../components/Authentication/SelectAuthenticate"
import AddBackupEmail from "../../components/Authentication/AddBackupEmail";
import ConfirmBackupEmail from "../../components/Authentication/ConfirmBackupEmail";
import "../../components/Authentication/authentication.css"
import { API } from "../../config";

const { Step } = Steps;


const TwoFactorChange = () => {
  const navigate = useNavigate();
  const [ current, setCurrent ] = useState(0);
  const [ backupEmail, setBackupEmail ] = useState("");

  const confirmAuthentication = () => {
    setCurrent(current + 1);
  }

  const submitEmail = () => {
    setCurrent(current + 1);
  }

  const verifyEmail = async () => {
    // navigate(-1)
    navigate("/user-settings?tab=my-profile", { replace: true });
  }

  const contentStepOne = () => {
    return <SelectAuthenticate
      confirmAuthentication={confirmAuthentication}
      emailVerify={API.VERIFY_EMAIL_AUTHENTICATION}
      googleVerify={API.GOOGLE_2FA_VERIFY}
    />
  }

  const contentStepTwo = () => {
    return <AddBackupEmail
      backupEmail={backupEmail}
      setBackupEmail={setBackupEmail}
      submitEmail={submitEmail}
    />
  }

  const contentStepThree = () => {
    return (
      <>
        <ConfirmBackupEmail
          backupEmail={backupEmail}
          submitButton={verifyEmail}
          verifyUrl={API.VERIFY_BACKUP_EMAIL}
        />
        <Link onClick={() => { setCurrent(current - 1); }}>I don&apos;t have a code</Link>
      </>
    )
  }

  const steps = [
    {
      title: 'Authenticate',
      content: contentStepOne(),
    },
    {
      title: 'Add email',
      content: contentStepTwo(),
    },
    {
      title: 'Confirm email',
      content: contentStepThree(),
    }
  ];





  return (
    <>
    <div className='tfa-change-content'>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="change-steps-content">{steps[ current ].content}</div>
    </div>
    </>
  );
};

export default TwoFactorChange;
