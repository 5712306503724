import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import { formatMobile, formatName, formatPhone } from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getUserDetail } from "./TransactionsHelper";

const UserDetailPopup = ({ id, toggleShowModal, userModal }) => {
  const [userDetail, setUserDetail] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const userDetailData = await getUserDetail(id);
      setUserDetail(userDetailData);
      setUserLoading(false);
    }
    fetchData();
  }, [id, setUserDetail]);

  return (
    <Modal
      size="lg"
      show={userModal}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        User Details
      </Modal.Header>
      <Modal.Body>
        {userLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {userDetail?.profile_pic === null ||
                userDetail?.profile_pic === "" ? (
                  <span>{userDetail?.first_name ? userDetail?.first_name?.charAt(0) : userDetail?.last_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${userDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {formatName(userDetail?.first_name, userDetail?.last_name)}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>First Name:</strong>{" "}
                  {userDetail?.first_name ? userDetail?.first_name : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong> {userDetail?.last_name}
                </p>
                <p>
                  <strong>Email Address:</strong> {userDetail?.email}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {userDetail?.phone ? formatPhone(userDetail?.phone) : ""}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {userDetail?.mobile ? formatMobile(userDetail?.mobile) : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserDetailPopup;
