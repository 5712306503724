import { faBan, faCheckCircle, faEdit, faEye, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import ClientDelete from "./ClientDelete";
import ClientDetailPopup from "./ClientDetailPopup";
import CustomTooltip from "../../../common/CustomTooltip";
import { Button, Modal } from "react-bootstrap";
import { API, fileUpload } from "../../../../config";
import { toast } from "react-toastify";
import Spin from "../../../common/Spin";

const ClientActionButton = ({ id, handleDelete, email, status, name }) => {
  // const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showInviteClient, setShowInviteClient] = useState(false);
  const [clientInviteLoading, setClientInviteLoading] = useState(false);

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleInviteClientModal = () => {
    setShowInviteClient(!showInviteClient)
  }

  const handleResendInvite = async() => {
    const formData = new FormData();

    formData.append("client_id", id ? id : "");

    try {
      setClientInviteLoading(true);
      const { data } = await fileUpload(
        `${API.INVITE_CLIENT}`,
        formData
      );
      setClientInviteLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleInviteClientModal();
      }
      return data?.data;
    } catch (error) {
      setClientInviteLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setClientInviteLoading(false);
      } else {
        toast.error(error?.message);
        setClientInviteLoading(false);
      }
    }
  }

  const [showRevokeAccess, setShowRevokeAccess] = useState(false);
  const [revokeAccessLoading, setRevokeAccessLoading] = useState(false);

  const toggleRevokeAccessModal = () => {
    setShowRevokeAccess(!showRevokeAccess)
  }

  const handleRevokeAccess = async() => {
    const formData = new FormData();
    formData.append("client_id", id ? id : "");

    try {
      setRevokeAccessLoading(true);
      const { data } = await fileUpload(
        `${API.REVOKE_ACCESS_CLIENT}`,
        formData
      );
      setRevokeAccessLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleRevokeAccessModal();
      }
      return data?.data;
    } catch (error) {
      setRevokeAccessLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setRevokeAccessLoading(false);
      } else {
        toast.error(error?.message);
        setRevokeAccessLoading(false);
      }
    }
  }

  // const toggleDeleteModal = () => {
  //   setShowDelete(!showDelete);
  // };
  return (
    <>
      <span>
        <CustomTooltip
          text={"View"}
          children={
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#clientDetail"
            />
          }
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip
          text={"Edit"}
          children={
            <Link to={{ pathname: "/edit-client", search: `?id=${id}` }}>
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Link>
          }
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        {status === 0 ? 
          <CustomTooltip
            text={"Resend Invite"}
            children={
              <FontAwesomeIcon icon={faShareSquare} size="1x" onClick={() => toggleInviteClientModal()} />
            }
          /> :
          <CustomTooltip
            text={"Active"}
            children={
              <FontAwesomeIcon icon={faCheckCircle} size="1x" color="green"/>
            }
          />
        }
        {status === 1 &&
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={"Suspend Access"}
              children={
                <FontAwesomeIcon icon={faBan} size="1x" onClick={() => toggleRevokeAccessModal()} />
              }
            />
          </>
        }
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteClient"
        /> */}
      </span>

      {/* {showDelete && (
        <ClientDelete
          clientId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )} */}

      {showView && (
        <ClientDetailPopup
          showView={showView}
          clientId={id}
          toggleShowModal={toggleShowModal}
        />
      )}

      {showInviteClient && (
        <Modal
          size="lg"
          show={showInviteClient}
          onHide={toggleInviteClientModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Resend Client Invitation
          </Modal.Header>
          <Modal.Body>
            {clientInviteLoading && <Spin />}
            <div className="modal-body">
              Do you want to resend invitation?
            </div>
            <div className="modal-footer">
            <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleResendInvite();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleInviteClientModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showRevokeAccess && (
        <Modal
          size="lg"
          show={showRevokeAccess}
          onHide={toggleRevokeAccessModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Suspend Access
          </Modal.Header>
          <Modal.Body>
            {revokeAccessLoading && <Spin />}
            <div className="modal-body">
              Are you sure you want to suspend access of {name}?
            </div>
            <div className="modal-footer">
            <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleRevokeAccess();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleRevokeAccessModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ClientActionButton;
