import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DeleteParagrah from "./DeleteParagrah";
import UpdateParagrah from "./UpdateParagrah";

const ParagrahActionButton = ({ id, handleDelete, handleEdit }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleEditModal = () => {
    setShowEdit(!showEdit);
  }

  return (
    <>
      <span>
        <FontAwesomeIcon 
          icon={faEdit} 
          size="1x" 
          onClick={toggleEditModal}
          data-toggle="modal"
          // data-target="#editDiscount"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          // data-target="#deleteDiscount"
        />
      </span>

      {showDelete && (
        <DeleteParagrah
          paragrahId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
          businessId={businessId}
        />
      )}

      {showEdit && (
        <UpdateParagrah
          paragrahId={id}
          toggleEditModal={toggleEditModal}
          handleEdit={handleEdit}
          showEdit={showEdit}
          businessId={businessId}
        />
      )}
    </>
  );
};

export default ParagrahActionButton;
