import { MonthRangePicker } from '@semcore/date-picker';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { API, get } from '../../config';
import { toast } from 'react-toastify';
import Spin from './Spin';
import { pastFinancialdate } from './Misc';
import Select from 'react-select';

const BusinessStatusGraph = ({ data, businessId, accountData }) => {
  const navigate = useNavigate();
  const [monthRange, setMonthRange] = useState(pastFinancialdate);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [accountId, setAccountId] = useState('');

  const accountList =
    accountData?.length > 0 &&
    accountData?.map((item) => {
      const list = {
        label:
          item?.accountName +
          (item?.accountNumber
            ? '-' + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)')
            : ''),
        value: item?.id
      };
      return list;
    });
  let accountOptions = accountList ? [{ label: 'ALL', value: '' }, ...accountList] : [{ label: 'ALL', value: '' }];

  const CustomTooltip = ({ active, payload, label }) => {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#ffff',
          padding: '5px',
          border: '1px solid #cccc',
          position: 'relative',
          zIndex: 1
        }}
      >
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0 ? `${payload[0]?.payload?.month}` : ''}
        </label>
        <br />
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0 ? `Reconciled : ${payload[0]?.payload?.reconciled}` : ''}
        </label>
        <br />
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0
            ? `Not Reconciled : ${payload[0]?.payload?.not_reconciled}`
            : ''}
        </label>
        <br />
        <label style={{ fontWeight: '500', marginBottom: '2px' }}>
          {payload && payload?.length > 0
            ? `Total Transactions : ${payload[0]?.payload?.total_transactions}`
            : ''}
        </label>
      </div>
    );
  };

  const monthDiff = (d1, d2) => {
    let months;
    months = (d2?.getFullYear() - d1?.getFullYear()) * 12;
    months -= d1?.getMonth();
    months += d2?.getMonth();
    return months <= 0 ? 0 : months;
  };

  const handleMonthRange = (e) => {
    let monthDifference = monthDiff(e[0], e[1]);
    if (monthDifference > 11) {
      if (e[0]?.getMonth() === 0) {
        setMonthRange([e[0], new Date(e[0]?.getFullYear(), e[0]?.getMonth() + 12, 0)]);
        fetchChartData([e[0], new Date(e[0]?.getFullYear(), e[0]?.getMonth() + 12, 0)], accountId);
      } else {
        setMonthRange([e[0], new Date(e[0]?.getFullYear(), e[0]?.getMonth() + 12, 0)]);
        fetchChartData([e[0], new Date(e[0]?.getFullYear(), e[0]?.getMonth() + 12, 0)], accountId);
      }
    } else {
      setMonthRange([e[0], new Date(e[1]?.getFullYear(), e[1]?.getMonth() + 1, 0)]);
      fetchChartData([e[0], new Date(e[1]?.getFullYear(), e[1]?.getMonth() + 1, 0)], accountId);
    }
  };

  const fetchChartData = async(fetchMonthRange, account_id) => {
    if (businessId) {
      try {
        setBusinessLoading(true);
        const { data } = await get(
          `${API.BUSINESS_DETAILS}/${businessId}?start_date=${
            fetchMonthRange ? moment(fetchMonthRange[0]).format('DD-MM-YYYY') : (monthRange ? moment(monthRange[0]).format('DD-MM-YYYY') : '')
          }
          &end_date=${fetchMonthRange ? moment(fetchMonthRange[1]).format('DD-MM-YYYY') : (monthRange ? moment(monthRange[1]).format('DD-MM-YYYY') : '')}${account_id ? '&yodlee_account_id=' + account_id  : ''}`
        );

        setBusinessLoading(false);
        setChartData(data?.data?.reconciliation_status);
      } catch (error) {
        const { data } = error.response;
        setBusinessLoading(false);
        setChartData(null);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    }
    //eslint-disable-next-line
  }

  const today = new Date();

  var disabledFutureDate = [];
  for (let i = 1; i < 1000; i++) {
    disabledFutureDate.push(new Date(today?.getFullYear(), today?.getMonth() + i, 1));
  }

  const handleAccountChange = (item) => {
    setChartData([]);
    setAccountId(item?.value);
    fetchChartData(monthRange, item?.value);
  };

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }

  const dateFilterRange = [
    {
      children: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)]
    },
    {
      children: 'This Quarter',
      value: quarterRange
    },
    {
      children: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)]
    },
    {
      children: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)]
    },
    {
      children: 'Last Quarter',
      value: perviousQuarterRange
    },
    {
      children: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)]
    },
  ]

  return (
    <div className="business-status-graph">
      {businessLoading && <Spin />}
      <div className="business-status-filter">
        <div className="bank-account-dropdown">
          <Select
            style={{
              width: '50%',
              margin: '0'
            }}
            placeholder="Select Account"
            defaultValue={{ label: 'ALL', value: '' }}
            onChange={(item) => {
              handleAccountChange(item);
            }}
            loading={businessLoading}
            classNamePrefix="account_select_graph"
            options={accountOptions}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'grey'
              }
            })}
          />
        </div>
        <MonthRangePicker
          onChange={handleMonthRange}
          value={monthRange ? monthRange : pastFinancialdate}
          disabled={disabledFutureDate}
          unclearable={true}
          periods={dateFilterRange}
        />
      </div>
      <ResponsiveContainer width={'100%'} height={50} debounce={50}>
        <BarChart
          data={chartData ? chartData : data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5
          }}
          // barGap={180}
          onClick={(data) => {
            let dateString = data.activePayload[0]?.payload?.startDate?.split("-")
            let form = moment(new Date(dateString[2], dateString[1] - 1, 1)).format("DD-MM-YYYY");
            if (data && data.activePayload && data.activePayload.length > 0) {
              navigate(`/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`, { state: {form: form, accountStatusID: accountId}});
              // return history.push(`/${data.activePayload[0].x.y.id}`);
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis
            dataKey="month"
            textAnchor="end"
            sclaeToFit="true"
            verticalAnchor="start"
            interval={0}
            angle="-40"
            tickSize={0}
            fontSize={10}
            // stroke="#8884d8"
          /> */}
          {/* <YAxis /> */}
          <Tooltip
            content={<CustomTooltip />}
            position={{ x: 0, y: 50 }}
            style={{
              zIndex: 1
            }}
          />
          <Bar dataKey="reconciled" stackId="a" barSize={15} fill="#79AC78" />
          <Bar dataKey="not_reconciled" stackId="a" barSize={15} fill="#C70039" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BusinessStatusGraph;
