import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API, get } from "../../../config";
import Spin from "../../common/Spin";

const Summary = ({ businessId, valueRange, businessName }) => {
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(false);
  const fetchSummaryReport = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.SUMMARY_REPORT}?client_business_id=${
          businessId ? businessId : ""
        }&start_month=${
          valueRange ? moment(valueRange[0]).format("MM-YYYY") : ""
        }&end_month=${
          valueRange ? moment(valueRange[1]).format("MM-YYYY") : ""
        }`
      );
      setSummaryData(data?.data);
      setLoading(false);

      return data.data;
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, [businessId, valueRange]);

  useEffect(() => {
    fetchSummaryReport();
  }, [fetchSummaryReport]);

  const monthRangeDisplay =
    moment(valueRange[0]).format("MM") === moment(valueRange[1]).format("MM")
      ? `${moment(valueRange[0]).format("MMM")}, ${moment(valueRange[0]).format(
          "yyyy"
        )}`
      : `${moment(valueRange[0]).format("MMM")}-${moment(valueRange[1]).format(
          "MMM"
        )}, ${moment(valueRange[0]).format("yyyy")}`;

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <div className="bas-summary">
            <div className="row">
              <div className="col-md-3">
                <h6>Account</h6>
                <p>{businessName ? businessName : ""}</p>
              </div>
              <div className="col-md-3">
                <h6>Period</h6>
                <p>{monthRangeDisplay}</p>
              </div>
              <div className="col-md-3">
                <h6>GST accounting method</h6>
                <p>Cash</p>
              </div>
              <div className="col-md-3" style={{ textAlign: "right" }}>
                <h4>
                  {summaryData?.current_liabilities?.bas_code === "9" &&
                  summaryData?.current_liabilities?.amount
                    ? `${Math.abs(summaryData?.current_liabilities?.amount).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`
                    : "0.00"}
                </h4>
                <p>
                  {summaryData?.current_liabilities?.amount
                    ? summaryData?.current_liabilities?.amount > 0
                      ? "Total amount to receive"
                      : "Total amount to pay"
                    : ""}
                </p>
              </div>
            </div>
            <hr style={{margin: 0}} />
            {/* <div className="col-md-10">Summary</div> */}
          </div>
          <div className="bas-report">
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {summaryData?.total_sales?.title
                    ? summaryData?.total_sales?.title
                    : ""}
                </h4>
                {/* <p>Apr-Jun 2022</p> */}
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h6>
                    <span className="bas-code">G1</span> Total sales
                  </h6>
                </div>
                <div className="col-md-5">
                  <h6>GST inclusive</h6>
                </div>
                <div className="col-md-2" style={{ textAlign: "right" }}>
                  <h6>
                    {summaryData?.total_sales?.bas_code === "G1" &&
                    summaryData?.total_sales?.amount
                      ? `${summaryData?.total_sales?.amount.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : "0.00"}
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {summaryData?.gst_on_sales?.title
                    ? summaryData?.gst_on_sales?.title
                    : ""}
                </h4>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h6>
                    <span className="bas-code">1A</span>{" "}
                    {`GST on sales (Owed to ATO)`}
                  </h6>
                </div>
                <div className="col-md-5">{/* <h6>GST inclusive</h6> */}</div>
                <div className="col-md-2" style={{ textAlign: "right" }}>
                  <h6>
                    {summaryData?.gst_on_sales?.bas_code === "1A" &&
                    summaryData?.gst_on_sales?.gst_amount
                      ? `${summaryData?.gst_on_sales?.gst_amount.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : "0.00"}
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h6>
                    <span className="bas-code">8A</span> Total owed to the ATO
                  </h6>
                </div>
                <div className="col-md-5">{/* <h6>GST inclusive</h6> */}</div>
                <div className="col-md-2" style={{ textAlign: "right" }}>
                  <h6>
                    {summaryData?.gst_on_sales?.bas_code === "1A" &&
                    summaryData?.gst_on_sales?.gst_amount
                      ? `${summaryData?.gst_on_sales?.gst_amount.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : "0.00"}
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {summaryData?.gst_on_purchase?.title
                    ? summaryData?.gst_on_purchase?.title
                    : ""}
                </h4>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h6>
                    <span className="bas-code">1B</span>{" "}
                    {`GST on purchases (Owed by ATO)`}
                  </h6>
                </div>
                <div className="col-md-5">{/* <h6>GST inclusive</h6> */}</div>
                <div className="col-md-2" style={{ textAlign: "right" }}>
                  <h6>
                    {summaryData?.gst_on_purchase?.bas_code === "1B" &&
                    summaryData?.gst_on_purchase?.gst_amount
                      ? `${summaryData?.gst_on_purchase?.gst_amount.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : "0.00"}
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h6>
                    <span className="bas-code">8B</span> Total owed by the ATO
                  </h6>
                </div>
                <div className="col-md-5">{/* <h6>GST inclusive</h6> */}</div>
                <div className="col-md-2" style={{ textAlign: "right" }}>
                  <h6>
                    {summaryData?.gst_on_purchase?.bas_code === "1B" &&
                    summaryData?.gst_on_purchase?.gst_amount
                      ? `${summaryData?.gst_on_purchase?.gst_amount.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : "0.00"}
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {summaryData?.current_liabilities?.title
                    ? summaryData?.current_liabilities?.title
                    : ""}
                </h4>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h6>
                    <span className="bas-code">9</span>{" "}
                    {summaryData?.current_liabilities?.amount
                      ? summaryData?.current_liabilities?.amount > 0
                        ? "Your refund amount"
                        : "Your current liabilities"
                      : "Your current liabilities"}
                  </h6>
                </div>
                <div className="col-md-5">{/* <h6>GST inclusive</h6> */}</div>
                <div className="col-md-2" style={{ textAlign: "right" }}>
                  <h6>
                    {summaryData?.current_liabilities?.bas_code === "9" &&
                    summaryData?.current_liabilities?.amount
                      ? `${Math.abs(
                          summaryData?.current_liabilities?.amount
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      : "0.00"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Summary;
