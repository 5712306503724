import React from 'react';
import { useEffect } from 'react';
import { imageDefaultPrefixPath } from '../../../../../config';
import { checkIfFileExists } from '../../../../common/Misc';
import { useParams } from 'react-router-dom';
import { getBusinessData } from '../../../components/BusinessHelper';
import { useState } from 'react';
import BPAYLOGO from '../../../../../assets/images/bpay-logo.svg';
import PayID from '../../../../../assets/images/PayID_logo.svg';

const InvoicePreview = ({ type, values }) => {
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  return (
    <>
      {type === '0' ? (
        <>
          <div id="boxed-invoice">
            <div
              className="boxed-invoice"
              style={{ margin: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
            >
              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%' }}>
                      {BusinessLogo && (
                        <img
                          src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                          alt=""
                          style={{
                            maxWidth: '95px',
                            maxHeight: '80px',
                            marginRight: '5px'
                          }}
                        />
                      )}{' '}
                      {BusinessName}
                    </td>
                    <td style={{ textAlign: 'end', width: '50%' }}>
                      <p style={{ marginBottom: '0px' }}>##BUSINESS_STREET##</p>
                      <p style={{ margin: '0px' }}>
                        ##BUSINESS_SUBURB## ##BUSINESS_STATE## ##BUSINESS_POSTALCODE##
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr
                style={{
                  margin: 0,
                  marginTop: '8px',
                  marginBottom: '25px',
                  borderTop: '1px solid black',
                  width: '100%'
                }}
              />
              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                <tbody>
                  <tr style={{ display: 'flex', gap: '15px' }}>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        height: '130px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        width: '65%'
                      }}
                    >
                      {values?.client_option?.includes('Contact') ? (
                        <div>
                          {values?.client_option?.includes('AlternateName')
                            ? '##COMPANY_NAME##'
                            : '##CONTACT_BUSINESS_NAME##'}
                          <>
                            <p style={{ margin: '0px' }}>##CONTACT_BUSINESS_STREET##</p>
                            <p style={{ margin: '0px' }}>
                              ##CONTACT_BUSINESS_SUBURB## ##CONTACT_BUSINESS_STATE##
                              ##CONTACT_BUSINESS_POSTALCODE##
                            </p>
                            {/* <p>{contactDetails}</p> */}
                          </>
                        </div>
                      ) : (
                        <div>
                          {values?.client_option?.includes('AlternateName')
                            ? '##COMPANY_NAME##'
                            : '##CONTACT_NAME##'}
                          <>
                            <p style={{ margin: '0px' }}>##CONTACT_STREET##</p>
                            <p style={{ margin: '0px' }}>
                              ##CONTACT_SUBURB## ##CONTACT_STATE## ##CONTACT_POSTALCODE##
                            </p>
                            {/* <p>{contactDetails}</p> */}
                          </>
                        </div>
                      )}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                        height: '130px',
                        width: '35%'
                      }}
                    >
                      <h6 style={{ margin: '0px', fontSize: '16px' }}>Tax Invoice</h6>
                      <p style={{ fontWeight: 'bold', margin: '0px', marginBottom: '0px' }}>
                        ##INVOICE_NUMBER##
                      </p>
                      <p style={{ fontWeight: 'bold', margin: '0px', marginBottom: '8px' }}>
                        ##INVOICE_ABN##
                      </p>
                      <p style={{ margin: '0px' }}>
                        {values?.client_option?.includes('CustomerRef')
                          ? 'Ref: ##CUSTOMER_REF_NUMBER##'
                          : ''}
                      </p>
                      <p style={{ margin: '0px' }}>##INVOICE_DATE##</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{
                  width: '100%',
                  marginTop: '20px',
                  border: '1px solid black',
                  fontFamily: 'Poppins',
                  borderSpacing: '0px'
                }}
                cellSpacing={0}
                cellPadding={0}
              >
                <thead>
                  <tr>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '50%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              textAlign: 'inherit',
                              color: values?.font_color,
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '50%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              textAlign: 'inherit',
                              color: values?.font_color,
                              background: values?.primary_color
                            }
                      }
                    >
                      Description
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Quantity
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Unit Price
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      GST
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              width: '10%',
                              padding: '8px',
                              borderRight: '1px solid black',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Discount
                    </th>
                    <th
                      style={
                        values?.secondary_color
                          ? {
                              padding: '8px',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                            }
                          : {
                              padding: '8px',
                              borderBottom: '1px solid black',
                              color: values?.font_color,
                              textAlign: 'right',
                              background: values?.primary_color
                            }
                      }
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black'
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: '<p>TEST</p>' }}></span>
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $500.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $500
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={2}
                    >
                      Subtotal
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $500.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      $500.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={5}
                    >
                      Gross Amount
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}
                    >
                      $500.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={5}
                    >
                      Discount
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'right'
                      }}
                    >
                      $0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={5}
                    >
                      Total Amount Paid
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}
                    >
                      $0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        fontWeight: 'bold'
                      }}
                      colSpan={5}
                    >
                      Total Credit Note Amount
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderBottom: '1px solid black',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}
                    >
                      $0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '8px',
                        borderRight: '1px solid black',
                        height: '37px'
                      }}
                      colSpan={6}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontWeight: 'bold'
                        }}
                      >
                        {values?.sub_total_option?.includes('Term') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Terms: ##TERMS##</span>
                          </p>
                        ) : (
                          ''
                        )}
                        {values?.sub_total_option?.includes('DueDate') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Due Date: ##INVOICE_DUE_DATE##</span>
                          </p>
                        ) : (
                          ''
                        )}
                        {values?.invoice_detail_option?.includes('Amounts') ? (
                          <p style={{ margin: '0px' }}>
                            <span>Amount Outstanding: $500.00</span>
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                    {/* <td style={{ padding: '8px', fontWeight: 'bold' }}>$##INVOICE_TOTAL_AMOUNT##</td> */}
                  </tr>
                </tbody>
              </table>
              <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontStyle: 'italic',
                        textAlign: 'center'
                      }}
                    >
                      {/* <p style={{ margin: '20px 0px 10px 0px' }}>
                        Please detach the portion below and forward with your payment
                      </p> */}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr
                style={{
                  margin: 0,
                  marginTop: '20px',
                  marginBottom: '20px',
                  border: 0,
                  borderTop: '1px solid #eee',
                  width: '100%'
                }}
              />
              <table
                style={{
                  width: '100%',
                  border: '1px solid black',
                  fontFamily: 'Poppins',
                  borderSpacing: '0px'
                }}
                cellSpacing={0}
                cellPadding={0}
              >
                <tbody>
                  <tr>
                    <td
                      colSpan={3}
                      style={
                        values?.secondary_color
                          ? {
                              color: values?.font_color,
                              background: `transparent
                            linear-gradient(
                              180deg,
                              ${values?.secondary_color} 0%,
                              ${values?.primary_color} 100%
                            )
                            0% 0% no-repeat padding-box`
                            }
                          : {
                              color: values?.font_color,
                              background: values?.primary_color
                            }
                      }
                    >
                      <div
                        style={
                          values?.footer_display_option?.includes('BpayDetails') ||
                          values?.direct_bank_option?.includes('DirectDebit') ||
                          values?.footer_display_option?.includes('PayIDDetails')
                            ? {
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginTop: '6px',
                                marginBottom: '6px'
                              }
                            : {
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginTop: '6px',
                                marginBottom: '6px',
                                paddingBottom: '5px',
                                borderBottom: '1px solid black'
                              }
                        }
                      >
                        Remittance Advice
                      </div>
                    </td>
                  </tr>
                  <tr>
                    {values?.footer_display_option?.includes('BpayDetails') ? (
                      <td
                        style={
                          values?.footer_display_option?.includes('BpayDetails')
                            ? values?.direct_bank_option?.includes('DirectDebit')
                              ? {
                                  padding: '10px',
                                  width: '33.33333333%',
                                  border: '1px solid black',
                                  borderLeft: 'none'
                                }
                              : {
                                  padding: '10px',
                                  width: '50%',
                                  border: '1px solid black',
                                  borderLeft: 'none'
                                }
                            : {}
                        }
                        colSpan={
                          values?.footer_display_option?.includes('BpayDetails')
                            ? values?.direct_bank_option?.includes('DirectDebit')
                              ? 1
                              : 2
                            : 0
                        }
                      >
                        {values?.footer_display_option?.includes('BpayDetails') ? (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div>
                                <img src={BPAYLOGO} alt="b-pay-logo" height={50} />
                              </div>
                              <div
                                style={{
                                  border: '1px solid black',
                                  padding: '5px',
                                  margin: '7px',
                                  fontSize: '13px',
                                  width: '140px'
                                }}
                              >
                                <div>
                                  <span>Biller Code: </span>
                                  <span>{values?.bpayCode ? values?.bpayCode : 0}</span>
                                </div>
                                <div>
                                  <span>Ref: </span>
                                  <span>0</span>
                                </div>
                              </div>
                            </div>
                            <div style={{ fontSize: '12px' }}>
                              Telephone & internet banking - BPay contact your bank or financial
                              institution to make this payment from your cheque, savings, debit,
                              credit card or transaction account: More info: www.bpay.com.au
                            </div>
                            <div style={{ fontSize: '12px', marginTop: '15px' }}>
                              Registered to BPay Pty Ltd
                              <div>ABN 59079137518</div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                    ) : (
                      <></>
                    )}
                    {values?.direct_bank_option?.includes('DirectDebit') ? (
                      <td
                        style={
                          values?.footer_display_option?.includes('BpayDetails')
                            ? {
                                padding: '10px',
                                border: '1px solid black',
                                borderLeft: 'none',
                                width: '33.3333%'
                              }
                            : {
                                padding: '10px',
                                border: '1px solid black',
                                width: '50%'
                              }
                        }
                        colSpan={
                          values?.footer_display_option?.includes('BpayDetails')
                            ? 1
                            : values?.footer_display_option?.includes('PayIDDetails')
                            ? 2
                            : 3
                        }
                      >
                        <div
                          style={
                            values?.footer_display_option?.includes('BpayDetails') &&
                            values?.footer_display_option?.includes('PayIDDetails')
                              ? {
                                  height: '213px',
                                  flexDirection: 'column',
                                  display: 'flex',
                                  gap: '5px'
                                }
                              : values?.footer_display_option?.includes('BpayDetails')
                              ? {
                                  height: '175px',
                                  flexDirection: 'column',
                                  display: 'flex',
                                  gap: '5px'
                                }
                              : {
                                  flexDirection: 'column',
                                  display: 'flex',
                                  height: 'auto',
                                  gap: '3px'
                                }
                          }
                        >
                          <div>
                            <span>Account Name: </span>
                            <span>
                              {values?.accountName ? values?.accountName : '##ACCOUNT_NAME##'}
                            </span>
                          </div>
                          <div>
                            <span>BSB: </span>
                            <span>{values?.bsb ? values?.bsb : '##BSB##'}</span>
                          </div>
                          <div>
                            <span>Account No.: </span>
                            <span>
                              {values?.accountNumber ? values?.accountNumber : '##ACCOUNT_NUMBER##'}
                            </span>
                          </div>
                          {values?.footer_display_option?.includes('CustomerRef') ? (
                            <div>
                              <span>Our Ref: ##CUSTOMER_REF_NUMBER##</span>
                              <span></span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                    {values?.footer_display_option?.includes('PayIDDetails') ? (
                      <td
                        style={{
                          padding: '10px',
                          border: '1px solid black',
                          borderLeft: 'none',
                          borderRight: 'none'
                        }}
                        colSpan={
                          values?.footer_display_option?.includes('BpayDetails')
                            ? values?.direct_bank_option?.includes('DirectDebit')
                              ? 1
                              : 1
                            : values?.direct_bank_option?.includes('DirectDebit')
                            ? 1
                            : 3
                        }
                      >
                        <div
                          style={
                            values?.footer_display_option?.includes('BpayDetails')
                              ? values?.direct_bank_option?.includes('DirectDebit')
                                ? {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                    height: '213px'
                                  }
                                : {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                    height: '185px'
                                  }
                              : values?.direct_bank_option?.includes('DirectDebit')
                              ? {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '8px',
                                  height: 'auto'
                                }
                              : {
                                  height: 'auto'
                                }
                          }
                        >
                          <div>
                            <div>
                              <span>
                                <img
                                  src={PayID}
                                  alt="b-pay-logo"
                                  height={30}
                                  style={{ marginBottom: '5px' }}
                                />
                              </span>
                              <span></span>
                            </div>
                            <div>
                              <span>Email: </span>
                              <span>{values?.email ? values?.email : '##EMAIL##'}</span>
                            </div>
                            <div>
                              <span>Mobile: </span>
                              <span>{values?.mobile ? values?.mobile : '##MOBILE##'}</span>
                            </div>
                          </div>
                          {/* <div>
                                <span>Amount Outstanding: </span>
                                <span>$ </span>
                              </div> */}
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                  {values?.footer_display_option?.includes('CardDetails') ? (
                    <tr>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '12px',
                            gap: '20px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }}
                          >
                            <div>Mastercard</div>
                            <div
                              style={{
                                height: '15px',
                                width: '15px',
                                border: '1px solid black'
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }}
                          >
                            <div>Visa</div>
                            <div
                              style={{
                                height: '15px',
                                width: '15px',
                                border: '1px solid black'
                              }}
                            />
                          </div>
                          {values?.footer_display_option?.includes('Amex') ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                            >
                              <div>Diners</div>
                              <div
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  border: '1px solid black'
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {values?.footer_display_option?.includes('Amex') ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                            >
                              <div>Amex</div>
                              <div
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  border: '1px solid black'
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {values?.footer_display_option?.includes('CardDetails') ? (
                    <tr style={{ marginTop: '10px' }}>
                      <td colSpan={3} style={{ padding: '10px', width: '100%' }}>
                        <div style={{ marginTop: '15px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
                            <div className="invoice-detach-method-type-item">
                              <div>Card Number</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                              <div
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  border: '1px solid black'
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {values?.footer_display_option?.includes('CardDetails') ? (
                    <tr>
                      <td
                        style={{ padding: '10px', width: '100%', paddingTop: '20px' }}
                        colSpan={3}
                      >
                        <div
                          className="fixed-footer"
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                            <span>Cardholder </span>
                            <div
                              style={{
                                width: '90px',
                                borderBottom: '3px dotted black',
                                marginBottom: '4px',
                                marginRight: '5px'
                              }}
                            />
                          </div>
                          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                            <span>Signature </span>
                            <div
                              style={{
                                width: '90px',
                                borderBottom: '3px dotted black',
                                marginBottom: '4px',
                                marginRight: '5px'
                              }}
                            />
                          </div>
                          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                            <span>Expiry Date </span>
                            <div
                              style={{
                                width: '90px',
                                borderBottom: '3px dotted black',
                                marginBottom: '4px',
                                marginRight: '5px'
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      {/* <td style={{ padding: '10px', width: '30%' }}>
                      <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                        <span>Signature </span>
                        <div
                          style={{
                            width: '100px',
                            borderBottom: '4px dotted black',
                            marginBottom: '4px'
                          }}
                        />
                      </div>
                    </td>
                    <td style={{ padding: '10px', width: '30%' }}>
                      <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                        <span>Expiry Date </span>
                        <div
                          style={{
                            width: '100px',
                            borderBottom: '4px dotted black',
                            marginBottom: '4px'
                          }}
                        />
                      </div>
                    </td> */}
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="letter-invoice">
            <table
              style={{ width: '100%', fontFamily: 'Poppins', padding: '15px', fontSize: '12px' }}
            >
              <tbody>
                <tr>
                  <td>
                    <table style={{ width: '100%', fontFamily: 'Poppins' }}>
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ textAlign: 'right' }}>
                            {BusinessLogo && (
                              <img
                                src={`${imageDefaultPrefixPath}${BusinessLogo}`}
                                alt=""
                                style={{
                                  maxWidth: '95px',
                                  maxHeight: '80px',
                                  marginRight: '5px'
                                }}
                              />
                            )}{' '}
                            {BusinessName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '30%' }}>
                            <h2 style={{ fontSize: '20px', paddingBottom: '12px' }}>INVOICE</h2>
                            {values?.client_option?.includes('Contact') ? (
                              <>
                                {values?.client_option?.includes('AlternateName') ? (
                                  <p style={{ margin: '0px' }}>##COMPANY_NAME##</p>
                                ) : (
                                  <p style={{ margin: '0px' }}>##CONTACT_BUSINESS_NAME##</p>
                                )}
                                <p style={{ margin: '0px' }}>##CONTACT_BUSINESS_STREET##</p>
                                <p style={{ margin: '0px' }}>
                                  ##CONTACT_BUSINESS_SUBURB## ##CONTACT_BUSINESS_STATE##
                                  ##CONTACT_BUSINESS_POSTALCODE##
                                </p>
                              </>
                            ) : (
                              <>
                                {values?.client_option?.includes('AlternateName') ? (
                                  <p style={{ margin: '0px' }}>##COMPANY_NAME##</p>
                                ) : (
                                  <p style={{ margin: '0px' }}>##CONTACT_NAME##</p>
                                )}
                                <p style={{ margin: '0px' }}>##CONTACT_STREET##</p>
                                <p style={{ margin: '0px' }}>
                                  ##CONTACT_SUBURB## ##CONTACT_STATE## ##CONTACT_POSTALCODE##
                                </p>
                              </>
                            )}
                          </td>
                          <td style={{ width: '25%' }}></td>
                          <td style={{ width: '45%', textAlign: 'right' }}>
                            <table cellpadding="0" cellspacing="0" style={{ width: '100%' }}>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: 'top',
                                    textAlign: 'left',
                                    paddingRight: '15px'
                                  }}
                                >
                                  <p>
                                    <strong>Invoice Date</strong>
                                    <br />
                                    ##INVOICE_DATE##
                                  </p>
                                  <p>
                                    <strong>Invoice Number</strong>
                                    <br />
                                    ##INVOICE_NUMBER##
                                  </p>
                                  <p>
                                    <strong>ABN</strong>
                                    <br />
                                    ##ABN##
                                  </p>
                                </td>
                                <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                  <div>
                                    ##BUSINESS_NAME##
                                    <p style={{ margin: '0px' }}>##BUSINESS_STREET##</p>
                                    <p style={{ margin: '0px' }}>
                                      ##BUSINESS_SUBURB## ##BUSINESS_STATE## ##BUSINESS_POSTALCODE##
                                    </p>
                                    {/* <p>{contactDetails}</p> */}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      style={{ width: '100%', fontFamily: 'Poppins', marginTop: '55px' }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'left',
                                    width: '43%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'left',
                                    width: '43%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Description
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '5%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '5%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Quantity
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Unit Price
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            GST
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Discount
                          </th>
                          <th
                            style={
                              values?.secondary_color
                                ? {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: `transparent
                        linear-gradient(
                          180deg,
                          ${values?.secondary_color} 0%,
                          ${values?.primary_color} 100%
                        )
                        0% 0% no-repeat padding-box`
                                  }
                                : {
                                    padding: '5px',
                                    borderBottom: '1px solid #000',
                                    textAlign: 'right',
                                    width: '13%',
                                    color: values?.font_color,
                                    background: values?.primary_color
                                  }
                            }
                          >
                            Amount AUD
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: '5px',
                              textAlign: 'left',
                              borderBottom: '1px solid #ced4da'
                            }}
                          >
                            {/* <span
                                  dangerouslySetInnerHTML={{ __html: item?.description }}
                                ></span> */}
                            Development work - developer onsite per day
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            1
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            200.00
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            NO GST
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            0.00
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #ced4da',
                              textAlign: 'right'
                            }}
                          >
                            200
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>Subtotal</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>655.60</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>Discount</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>120.35</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>TOTAL Amount</td>
                          <td style={{ padding: '5px', textAlign: 'right' }}>0.00</td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td
                            style={{
                              padding: '5px',
                              borderTop: '1px solid #000',
                              textAlign: 'right',
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL AUD
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderTop: '1px solid #000',
                              textAlign: 'right',
                              fontWeight: 'bold'
                            }}
                          >
                            535.25
                          </td>
                        </tr>

                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #000',
                              textAlign: 'right'
                            }}
                          >
                            Less Amount Paid
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #000',
                              textAlign: 'right'
                            }}
                          >
                            240.00
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px', textAlign: 'left' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td style={{ padding: '5px', textAlign: 'right' }}></td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #000',
                              textAlign: 'right'
                            }}
                          >
                            Total Credit Note Amount
                          </td>
                          <td
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #000',
                              textAlign: 'right'
                            }}
                          >
                            0.00
                          </td>
                        </tr>
                        {values?.invoice_detail_option?.includes('Amounts') ? (
                          <>
                            <tr>
                              <td style={{ padding: '5px', textAlign: 'left' }}></td>
                              <td style={{ padding: '5px', textAlign: 'right' }}></td>
                              <td style={{ padding: '5px', textAlign: 'right' }}></td>
                              <td style={{ padding: '5px', textAlign: 'right' }}></td>
                              <td
                                style={{
                                  padding: '5px',
                                  borderTop: '1px solid #000',
                                  textAlign: 'right'
                                }}
                              >
                                <strong>AMOUNT DUE AUD</strong>
                              </td>
                              <td
                                style={{
                                  padding: '5px',
                                  borderTop: '1px solid #000',
                                  textAlign: 'right'
                                }}
                              >
                                <strong>295.25</strong>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    <table cellpadding="0" cellspacing="0" style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ paddingTop: '14px' }}>
                            {values?.sub_total_option?.includes('DueDate') ? (
                              <p
                                style={{
                                  fontSize: '15px',
                                  fontWeight: '600',
                                  marginBottom: '14px'
                                }}
                              >
                                Due Date: ##INVOICE_DUE_DATE##
                              </p>
                            ) : (
                              <></>
                            )}
                            <p>
                              When paying by cheque, please complete this payment advice, detach and
                              post to the address provided.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      style={{ width: '100%', fontSize: '12px', marginTop: '100px' }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <footer style={{ borderTop: '1px dashed #000', width: '100%' }}>
                              <table cellpadding="0" cellspacing="0" style={{ marginTop: '15px' }}>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: '50%',
                                        display: 'flex',
                                        flexDirection: 'column'
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: '600',
                                          marginBottom: '15px',
                                          marginTop: '0px'
                                        }}
                                      >
                                        PAYMENT ADVICE
                                      </p>
                                      <table style={{ width: '100%' }}>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{ verticalAlign: 'top', paddingRight: '5px' }}
                                            >
                                              To:
                                            </td>
                                            <td>
                                              ##BUSINESS_NAME##
                                              <br />
                                              ##STREET_ADDRESS##
                                              <br />
                                              ##SUBURB_NAME## ##STATE_SHORT_NAME## ##POSTAL_CODE##
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td style={{ width: '50%' }}>
                                      <table style={{ width: '100%' }}>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{ verticalAlign: 'top', paddingRight: '5px' }}
                                            >
                                              <strong>Customer</strong>
                                            </td>
                                            <td style={{ verticalAlign: 'top' }}>
                                              {values?.footer_display_option?.includes(
                                                'AlternateName'
                                              )
                                                ? '##COMPANY_NAME##'
                                                : '##CUSTOMER_NAME##'}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ verticalAlign: 'top', paddingRight: '5px' }}
                                            >
                                              <strong>Invoice Number</strong>
                                            </td>
                                            <td style={{ verticalAlign: 'top' }}>
                                              ##INVOICE_NUMBER##
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ verticalAlign: 'top', paddingRight: '5px' }}
                                            >
                                              <strong>Amount Outstanding</strong>
                                            </td>
                                            <td style={{ verticalAlign: 'top' }}>
                                              <strong>##AMOUNT_DUE##</strong>
                                            </td>
                                          </tr>
                                          {values?.footer_display_option?.includes(
                                            'InvoiceDate'
                                          ) ? (
                                            <tr>
                                              <td
                                                style={{
                                                  verticalAlign: 'top',
                                                  paddingRight: '5px'
                                                }}
                                              >
                                                <strong>Due Date</strong>
                                              </td>
                                              <td style={{ verticalAlign: 'top' }}>
                                                ##INVOICE_DUE_DATE##
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )}
                                          <tr>
                                            <td
                                              style={{ verticalAlign: 'top', paddingRight: '5px' }}
                                            >
                                              <strong>Amount Enclosed</strong>
                                            </td>
                                            <td style={{ verticalAlign: 'top' }}>
                                              <p style={{ borderBottom: '1px solid #000' }}>
                                                &nbsp;
                                              </p>
                                              <p>Enter the amount you are paying above</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </footer>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default InvoicePreview;
