import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API, get, imageDefaultPrefixPath } from "../../config";
// import { Image } from "antd";
// import { API, get } from "../../config";
// import { imageDefaultPrefixPath } from "../../config";

const UserProfile = () => {
  const userName = localStorage.getItem("sme-Name");
  const logout = () => {
    get(API.LOGOUT)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch(() => {
        var error = {};
        error["myna_error"] =
          "There is some issue with request, please try after some time.";
        this.setState({ errors: error });
      });
  };

  // const params = useParams();
  // const businessId = params?.business_id;
  const profilePhoto = useMemo(() => localStorage.getItem("sme-ProfilePhoto"), []);
  return (
    <div className="userprofile">
      <li className="nav-item dropdown">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {!profilePhoto || profilePhoto === "null" ? (
              <span>{(userName ? userName : "").charAt(0)}</span>
            ) : (
              <img
                src={`${imageDefaultPrefixPath}${localStorage.getItem(
                  "sme-ProfilePhoto"
                )}`}
                alt=""
              />
            )}{" "}
            {userName ? userName : ""}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as={Link} to="/user-settings?tab=myprofile"
              // target={businessId && "_blank"}
            >
              My Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </div>
  );
};

export default UserProfile;
