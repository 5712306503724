import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { API, get } from "../../../config";
import moment from "moment";
import Spin from "../../common/Spin";
import BankTransactionByBatch from "./components/BankTransactionByBatch";

import UserDetailPopup from "./components/UserDetailsPopup";
import Pagination from "../../common/Pagination";

const ImportHistory = ({ setBalanceInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [bankTransactionBatches, setBankTransactionBatches] = useState({});
  const [loading, setLoading] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [userId, setUserId] = useState([]);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const toggleShowModal = () => {
    setUserModal(false);
  };

  const fetchBankTransactionsBatches = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_BATCHES}?client_business_id=${
          businessId ? businessId : ""
        }&limit=${limit ? limit : ""}&page=${page ? page : ""}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setTotal(data?.data?.total);
      let accountActiveList = [];
      if(data?.accounts){
        accountActiveList = data?.accounts?.filter((i) => i?.accountStatus === 1 )
      }
      setBalanceInfo(accountActiveList);
      setBankTransactionBatches(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [businessId, setBalanceInfo, limit, page]);

  useState(() => {
    navigate(
      `/business/${businessId}/reconcile-transactions?tab=audit-trail&childTab=import-history&limit=${
        limit ? limit : 10
      }&page=${page ? page : 1}`
    );
  }, [navigate, businessId, page, limit]);

  const bankTransactionBatchesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: bankTransactionBatches[i]?.id,
        batch_id: bankTransactionBatches[i]?.id
          ? bankTransactionBatches[i]?.id
          : "",
        date: bankTransactionBatches[i]?.created_at
          ? moment(
              bankTransactionBatches[i]?.created_at,
              "DD-MM-YYYY HH:mm:ss"
            ).format("DD-MM-YYYY")
          : "-",
        description: bankTransactionBatches[i]?.description ? (
          <span
            onClick={(e) => {
              const id = e?.target?.attributes?.["data-id"];
              if(id){
                setUserModal(true);
                setUserId(id?.value);
              }
            }}
            data-toggle="modal"
            data-target="#userDetail"
            dangerouslySetInnerHTML={{
              __html: bankTransactionBatches[i]?.description,
            }}
          ></span>
        ) : (
          "-"
        ),
        total_records: bankTransactionBatches[i]?.total_records
          ? bankTransactionBatches[i]?.total_records.toLocaleString("en-US")
          : "",
      });
    }
    return items;
  };

  const bankTransactionBatchesData = bankTransactionBatchesGenerator(
    bankTransactionBatches?.length
  );

  useEffect(() => {
    fetchBankTransactionsBatches();
  }, [fetchBankTransactionsBatches]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      style: { width: "8%" },
      // sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      style: { width: "78%" },
      // sort: true,
    },
    {
      dataField: "total_records",
      text: "Number Of Records",
      align: "right",
      style: { width: "15%" },
      // sort: true,
    },
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <BankTransactionByBatch id={row?.batch_id} />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };
  return (
    <>
      <div className="cms-page audit-statement">
        {loading && <Spin />}
        <BootstrapTable
          keyField="batch_id"
          remote
          data={bankTransactionBatchesData}
          columns={columns}
          // onTableChange={handleTableChange}
          noDataIndication="No Data Found"
          expandRow={expandRow}
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={Number(page)}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>

      {userModal && (
        <UserDetailPopup
          id={userId}
          toggleShowModal={toggleShowModal}
          userModal={userModal}
        />
      )}
    </>
  );
};

export default ImportHistory;
