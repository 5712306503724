import React, { useEffect, useState } from 'react';
import Subscription from '../../firm/Subscription';
import CurrentSubscription from '../../firm/CurrentSubscription';
import { API, get } from '../../../config';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ListOfBusinessSubscribe from '../../firm/ListOfBusinessSubscribe';

const SubscriptionAfterLogin = () => {
  const tabsKey = {
    'current-subscription': 1,
    'more-packages': 2,
    businesses: 3
  };
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('childTab') || 'current-subscription';
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? 'current-subscription'
      : parseInt(defaultActiveKey) === 2
      ? 'more-packages'
      : 'businesses'
  );
  const [subscriptionDetail, setsubscriptionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getsubscription = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(API.GET_SUBSCRIPTION);
      if (data) {
        localStorage.setItem('sme-subscription', 1);
        setsubscriptionDetail(data?.data?.customer);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      const errors = error.response?.data?.errors;
      if (errors) {
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };

  useEffect(() => {
    navigate(`?tab=subscriptions&childTab=${activeTab}`, {
      replace: true
    });
  }, [activeTab, navigate]);

  useEffect(() => {
    getsubscription();
  }, []);

  return (
    <>
      <div className="table-top-btn">
        <ul
          className="nav nav-tabs"
          onClick={(event) => {
            setActiveTab(event?.target?.innerText.replace(/ /g, '-').toLowerCase());
          }}
        >
          <li className={activeTab === 'current-subscription' ? 'active' : ''}>
            <Link key={1} onClick={() => getsubscription()}>
              Current Subscription
            </Link>
          </li>
          <li className={activeTab === 'more-packages' ? 'active' : ''}>
            <Link key={2}>More Packages</Link>
          </li>
          {/* <li className={activeTab === 'businesses' ? 'active' : ''}>
            <Link key={3}>Businesses</Link>
          </li> */}
        </ul>
      </div>
      <div className="custom-table tab-table">
        {activeTab === 'more-packages' && (
          <Subscription subscriptionDetail={subscriptionDetail} afterLogin={true} />
        )}
        {activeTab === 'businesses' && (
          <ListOfBusinessSubscribe
            subscriptionDetail={subscriptionDetail}
            isLoading={isLoading}
            afterLogin={true}
          />
        )}
        {activeTab === 'current-subscription' && (
          <CurrentSubscription
            setActiveTab={setActiveTab}
            subscriptionDetail={subscriptionDetail}
            isLoading={isLoading}
            getsubscription={getsubscription}
            afterLogin={true}
          />
        )}
      </div>
    </>
  );
};

export default SubscriptionAfterLogin;
