import { API, get } from "../../../config";

export const getBusinessData = async (
  businessId,
  setBusinessName,
  setBusinessLogo
) => {
  try {
    const { data } = await get(`${API.BUSINESS_DETAILS}/` + businessId);
    setBusinessName(data?.data?.business_name);
    setBusinessLogo(data?.data?.logo);
    return data.data;
  } catch (e) {
    return null;
  }
};
