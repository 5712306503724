import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import QuoteSettings from './QuoteSettings';
import QuoteLayouts from '../LayoutSettings/QuoteLayouts';
import QuoteEmailSettings from './QuoteEmailSettings';
const tabsKey = {
  layout: 1,
  'email-setting': 2,
  global: 3
};

const ChildQuoteSettingsTab = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('childTab') || 'layout';
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? 'Layout'
      : parseInt(defaultActiveKey) === 2
      ? 'Email Setting'
      : 'Global'
  );

  return (
    <>
      <div className="table-top-btn">
        <ul
          className="nav nav-tabs"
          onClick={(event) => {
            setActiveTab(event?.target?.innerText);
          }}
        >
          <li className={activeTab === 'Layout' ? 'active' : ''}>
            <Link key={1}>Layout</Link>
          </li>
          <li className={activeTab === 'Email Setting' ? 'active' : ''}>
            <Link key={2}>Email Setting</Link>
          </li>
          <li className={activeTab === 'Global' ? 'active' : ''}>
            <Link key={3}>Global</Link>
          </li>
        </ul>
      </div>
      <div className="custom-table tab-table">
        {activeTab === 'Layout' && <QuoteLayouts />}
        {activeTab === 'Email Setting' && <QuoteEmailSettings />}
        {activeTab === 'Global' && <QuoteSettings />}
      </div>
    </>
  );
};
export default ChildQuoteSettingsTab;
