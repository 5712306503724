import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Form from './components/Form';
import { API, fileUpload, imageDefaultPrefixPath } from '../../../../config';
import {
  getBusinessDetail,
  useStateList,
  useSuburbListE
} from '../../../Settings/Business/components/BusinessHelper';
import { AddBusinessSchema } from '../../../common/Validation';
import Spin from '../../../common/Spin';
import { useCallback } from 'react';
// import { useOfficeList, useUserList } from '../../../Users/components/UserHelper';
// import { formatName, formatedAddress } from '../../../common/Misc';

const BusinessSettings = () => {
  const [loading, setLoading] = useState(false);
  const [businessData, setBusinessData] = useState();
  const [clientId, setClientId] = useState();
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const params = useParams();
  const businessId = params?.business_id;
  const navigate = useNavigate();

  const fetchBusinessData = useCallback(
    async (businessID) => {
      try {
        setLoading(true);
        const data = await getBusinessDetail(businessID);
        localStorage.setItem('sme-businessLogo', data?.logo);
        localStorage.setItem('sme-businessName', data?.business_name);
        setClientId(data?.client_id);
        setBusinessData(data);
        setSelectedUsers(data?.user_ids);
        setSelectedOffices(data?.office_ids);
        navigate(`/business/${businessId}/settings?tab=business-settings`, {
          replace: true
        });
        setLoading(false);
      } catch (e) {
        setBusinessData(null);
      }
    },
    [navigate, businessId]
  );

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }
  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.logo = value?.image ? value?.image : value?.logo ? value?.logo : '';

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, '') : '';
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, '') : '';
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, '') : '';
    // value.bsb = value?.bsb ? `${value?.bsb}`.replace(/-/g, "") : "";
    if (value?.accountNumber?.length < 9 && value?.accountNumber) {
      value.accountNumber = padWithLeadingZeros(value?.accountNumber, 9);
    }

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.value;

    value.logo =
      value?.logo && typeof value?.logo === 'string'
        ? value?.logo - imageDefaultPrefixPath
        : value?.logo;

    formData.append('client_id', clientId ? clientId : '');
    formData.append('business_name', value?.business_name ? value?.business_name : '');
    formData.append('ref_number', value?.ref_number ? value?.ref_number : '');
    formData.append('email', value?.email ? value?.email : '');
    formData.append('phone', value?.phone ? value?.phone : '');
    formData.append('mobile', value?.mobile ? value?.mobile : '');
    formData.append('abn', value?.abn ? value?.abn : '');
    formData.append('street', value?.street ? value?.street : '');
    formData.append('state_id', value?.state_id ? value?.state_id : '');
    formData.append('suburb_id', value?.suburb_id ? value?.suburb_id : '');
    formData.append('postal_code', value?.postal_code ? value?.postal_code : '');
    formData.append('account_name', value?.accountName ? value?.accountName : '');
    formData.append('account_number', value?.accountNumber ? value?.accountNumber : '');
    formData.append('bsb', value?.bsb ? value?.bsb : '');
    formData.append('logo', value?.logo ? value?.logo : '');

    if (selectedOffices) {
      if (selectedOffices.length > 0) {
        for (let i = 0; i < selectedOffices.length; i++) {
          formData.append(`office_ids[${i}]`, selectedOffices[i]);
        }
      }
    }

    if (selectedUsers) {
      if (selectedUsers.length > 0) {
        for (let i = 0; i < selectedUsers.length; i++) {
          formData.append(`user_ids[${i}]`, selectedUsers[i]);
        }
      }
    }
    formData.append('_method', 'put');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BUSINESS_ADD}/${businessId}`, formData);

      setLoading(false);
      if (data) {
        if (businessId) {
          fetchBusinessData(businessId);
          navigate(`/business/${businessId}/settings?tab=business-settings`, {
            replace: true
          });
        }
        toast.success(data?.message);
        window.setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      return data?.data;
    } catch (error) {
      setBusinessData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // navigate(`/business/${businessId}/settings?tab=business-settings`, {
    //   replace: true,
    // });
    fetchBusinessData(businessId);
  }, [businessId, fetchBusinessData]);

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find((item) => item?.value === businessData?.state_id);

  // Suburb
  const { suburbLoading, suburbList = [] } = useSuburbListE(businessData?.state_id);
  const suburbListing = suburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const suburb = suburbListing?.find((item) => item?.value === businessData?.suburb_id);

  return (
    <>
      <Formik
        initialValues={{
          business_name: businessData?.business_name,
          ref_number: businessData?.ref_number,
          abn: businessData?.abn,
          mobile: businessData?.mobile,
          phone: businessData?.phone,
          email: businessData?.email,
          street: businessData?.street,
          postal_code: businessData?.postal_code,
          state_id: state,
          suburb_id: suburb,
          logo: businessData?.logo ? imageDefaultPrefixPath + businessData?.logo : '',
          accountName: businessData?.account_name ? businessData?.account_name : '',
          accountNumber: businessData?.account_number ? businessData?.account_number : '',
          bsb: businessData?.bsb ? businessData?.bsb : '',
          office_ids: selectedOffices,
          user_ids: selectedUsers
        }}
        enableReinitialize
        innerRef={formValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={AddBusinessSchema}
      >
        {({ values, setErrors, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <>
              {loading ? (
                <Spin />
              ) : (
                <Form
                  values={values}
                  errors={errors}
                  setErrors={setErrors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  formValues={formValues}
                  overwrittenFields={overwrittenFields}
                  existingValues={existingValues}
                  stateListing={stateListing}
                  suburbListing={suburbListing}
                  stateLoading={stateLoading}
                  suburbLoadingE={suburbLoading}
                  setSelectedOffices={setSelectedOffices}
                  selectedOffices={selectedOffices}
                  setSelectedUsers={setSelectedUsers}
                  selectedUsers={selectedUsers}
                  clientId={clientId}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default BusinessSettings;
