import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EditChartOfAccounting from "../EditChartOfAccounting";
import AccountingDelete from "./AccountingDelete";

const AccountingActionButton = ({ id, handleDelete, fetchAccountData, isUsed, disableParent, childNodes }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleAccountModal = () => {
    setShowAccount(!showAccount);
  };

  return (
    <>
      <span>
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
          onClick={toggleAccountModal}
          data-toggle="modal"
          data-target="#account"
        />
        {(isUsed === 0 && disableParent === false && childNodes?.length === 0) &&
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              onClick={toggleDeleteModal}
              icon={faTrash}
              size="1x"
              data-toggle="modal"
              data-target="#deleteAccount"
            />
          </>
        }
      </span>

      {showDelete && (
        <AccountingDelete
          accountingId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}
      {showAccount && (
        <EditChartOfAccounting
          accountingId={id}
          businessId={businessId}
          toggleAccountModal={toggleAccountModal}
          fetchAccountData={fetchAccountData}
          showAccount={showAccount}
          // handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default AccountingActionButton;
