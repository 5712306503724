import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getContactPopupDetail } from "./ContactHelper";

const ContactDetailPopup = ({ contactId, toggleShowModal, showView }) => {
  const [contactDetail, setContactDetail] = useState(null);
  const [ContactLoading, setContactLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const contactDetailData = await getContactPopupDetail(contactId, true);
      setContactDetail(contactDetailData);
      setContactLoading(false);
    }
    fetchData();
  }, [contactId, setContactDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Contact Details
      </Modal.Header>
      <Modal.Body>
        {ContactLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {contactDetail?.profile_pic === null ||
                contactDetail?.profile_pic === "" ||
                contactDetail?.profile_pic === undefined ? (
                  <span>{contactDetail?.first_name? contactDetail?.first_name?.charAt(0) : contactDetail?.last_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${contactDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {contactDetail?.first_name
                    ? contactDetail?.first_name + " " + contactDetail?.last_name
                    : contactDetail?.last_name}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Name:</strong>{" "}
                  {contactDetail?.first_name ? contactDetail?.first_name : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong>{" "}
                  {contactDetail?.last_name ? contactDetail?.last_name : "-"}
                </p>
                <p>
                  <strong>Display Name:</strong>{" "}
                  {contactDetail?.display_name ? contactDetail?.display_name : "-"}
                </p>
                <p>
                  <strong>Email Address:</strong>{" "}
                  {contactDetail?.email ? contactDetail?.email : "-"}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {contactDetail?.phone
                    ? formatPhone(contactDetail?.phone)
                    : "-"}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {contactDetail?.mobile
                    ? formatMobile(contactDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {contactDetail?.business_name
                    ? contactDetail?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {contactDetail?.abn ? formatABN(contactDetail?.abn) : "-"}
                </p>
                <p>
                  <strong>Entity Type:</strong>{" "}
                  {contactDetail?.entity_type
                    ? contactDetail?.entity_type
                    : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    contactDetail?.street,
                    contactDetail?.suburb?.name,
                    contactDetail?.state?.name,
                    contactDetail?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    contactDetail?.shipping_street,
                    contactDetail?.shipping_suburb?.name,
                    contactDetail?.shipping_state?.name,
                    contactDetail?.shipping_postal_code
                  )}
                </p>
                <p>
                  <strong>Ref. Number:</strong>{" "}
                  {contactDetail?.ref_number
                    ? contactDetail?.ref_number
                    : "-"}
                </p>
                <p>
                  <strong>BPay Ref. Number:</strong>{" "}
                  {contactDetail?.bpay_ref_number
                    ? contactDetail?.bpay_ref_number
                    : "-"}
                </p>
                <p>
                  <strong>Contact Type:</strong>{" "}
                  {parseInt(contactDetail?.contact_type) === 1
                    ? "Customer"
                    : parseInt(contactDetail?.contact_type) === 2
                    ? "Supplier"
                    : parseInt(contactDetail?.contact_type) === 3
                    ? "Contractor"
                    : ""}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContactDetailPopup;
