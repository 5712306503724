import { toast } from "react-toastify";
import { API, get, post } from "../../../../config";

export const getUserDetail = async (id) => {
  try {
    const { data } = await get(`${API.GET_USER}/` + id);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const refreshBankTransactions = async (businessId, startDate, endDate, accountId) => {
  try {
    const { data } = await post(`${API.REFRESH_BANK_TRANSACTION}`, 
    accountId ?
    {
      client_business_id: businessId,
      start_date: startDate,
      end_date: endDate,
      yodlee_account_id: accountId ? accountId : ''
    }
    : {
      client_business_id: businessId,
      start_date: startDate,
      end_date: endDate,
    }
    );
    return data;
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
  }
};

export const getNextPageData = async (page, businessId) => {
  try {
    const { data } = await get(
      `${API.BANK_RECONCILIATION_LIST}?client_business_id=${businessId}&limit=10&page=${page}`
    );
    return data?.data;
  } catch (e) {
    throw e;
  }
};

export const getUnderReviewedNextPageData = async (page, businessId) => {
  try {
    const { data } = await get(
      `${
        API.BANK_RECONCILIATION_LIST
      }?client_business_id=${businessId}&limit=${10}&page=${page}`
    );
    return data?.data;
  } catch (e) {
    throw e;
  }
};
