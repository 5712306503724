import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatName,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getClientPopupDetail } from "./ClientHelper";

const ClientDetailPopup = ({ clientId, toggleShowModal, showView }) => {
  const [clientDetail, setClientDetail] = useState(null);
  const [clientLoading, setClientLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const clientDetailData = await getClientPopupDetail(clientId, true);
      setClientDetail(clientDetailData);
      setClientLoading(false);
    }
    fetchData();
  }, [clientId, setClientDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Client Details
      </Modal.Header>
      <Modal.Body>
        {clientLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {clientDetail?.profile_pic === null ||
                clientDetail?.profile_pic === "" ? (
                  <span>{clientDetail?.first_name ? clientDetail?.first_name?.charAt(0) : clientDetail?.last_name.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${clientDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {formatName(
                    clientDetail?.first_name,
                    clientDetail?.last_name
                  )}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>First Name:</strong>{" "}
                  {clientDetail?.first_name ? clientDetail?.first_name : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong> {clientDetail?.last_name}
                </p>
                <p>
                  <strong>Email Address:</strong> {clientDetail?.email}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {clientDetail?.phone ? formatPhone(clientDetail?.phone) : ""}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {clientDetail?.mobile
                    ? formatMobile(clientDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {clientDetail?.client?.business_name
                    ? clientDetail?.client?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {clientDetail?.client?.abn ? formatABN(clientDetail?.client?.abn) : "-"}
                </p>
                <p>
                  <strong>CRN:</strong>{" "}
                  {clientDetail?.client?.crn ? clientDetail?.client?.crn : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    clientDetail?.client?.street,
                    clientDetail?.client?.suburb?.name,
                    clientDetail?.client?.state?.name,
                    clientDetail?.client?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    clientDetail?.client?.shipping_street,
                    clientDetail?.client?.shipping_suburb?.name,
                    clientDetail?.client?.shipping_state?.name,
                    clientDetail?.client?.shipping_postal_code
                  )}
                </p>
                <p>
                  <strong>Display Name:</strong>{" "}
                  {clientDetail?.client?.display_name
                    ? clientDetail?.client?.display_name
                    : "-"}
                </p>
              </div>
            </div>
            <div className="modal-content">
              <h3>Office Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Office:</strong>{" "}
                  {clientDetail?.client?.office?.street
                    ? clientDetail?.client?.office?.street
                    : "-"}
                </p>
                <p>
                  <strong>Staff:</strong>{" "}
                  {clientDetail?.client?.staff
                    ? formatName(
                        clientDetail?.client?.staff?.first_name,
                        clientDetail?.client?.staff?.last_name
                      )
                    : "-"}
                </p>
                <p>
                  <strong>Manager:</strong>{" "}
                  {clientDetail?.client?.manager
                    ? formatName(
                        clientDetail?.client?.manager?.first_name,
                        clientDetail?.client?.manager?.last_name
                      )
                    : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ClientDetailPopup;
