import { toast } from "react-toastify";
import { API, get, post } from "../../../../../config";

export const getBusinessDetail = async (businessId) => {
  try {
    const { data } = await get(`${API.BUSINESS_DETAILS}/` + businessId);
    return data.data;
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
  }
};

export const getProviderBankDetail = async (businessId) => {
  try {
    const { data } = await get(
      `${API.GET_PROVIDERS_BANK}?client_business_id=${businessId}`
    );
    return data;
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
  }
};

export const getUserBasiq = async (businessId) => {
  try {
    const { data } = await post(`${API.BASIQ_GET_USER}`, {
      client_business_id: businessId,
    });
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const getYodleeAccessToken = async (businessId) => {
  try {
    const { data } = await post(`${API.YODLEE_GET_TOKEN}`, {
      client_business_id: businessId,
    });
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectBasiq = async () => {
  try {
    const { data } = await get(`${API.BASIQ_DISCONNECT}`);
    return data;
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(
        data.errors && data.errors.myna_error && data.errors.myna_error[0]
      );
    }
    return data;
  }
};

export const createUserBasiq = async (businessId) => {
  try {
    const { data } = await post(`${API.BASIQ_CREATE_USER}`, {
      client_business_id: businessId,
    });
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectProviderBank = async (businessId, providerId) => {
  try {
    const { data } = await get(
      `${API.PROVIDER_BANK_DISCONNECT}?client_business_id=${businessId}&provider_id=${providerId}`
    );
    return data;
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(
        data.errors && data.errors.myna_error && data.errors.myna_error[0]
      );
    }
    return data;
  }
};
