import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import SearchTermDelete from "./SearchTermDelete";

const SearchTermActionButton = ({ id, handleDelete }) => {
  const [showDelete, setShowDelete] = useState(false);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const params = useParams();
  const businessId = params?.business_id;
  return (
    <>
      <span>
        <Link to={`/business/${businessId}/edit-term?id=${id}`}>
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </Link>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteSearchTerm"
        />
      </span>

      {showDelete && (
        <SearchTermDelete
          searchTermId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}
    </>
  );
};

export default SearchTermActionButton;
