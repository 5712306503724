import React, { useLayoutEffect, useState } from 'react';
import { Input, Form } from 'antd';
import '../../components/Authentication/authentication.css';
import { toast } from 'react-toastify';
import { API, get, post } from '../../config';
import { Button } from 'react-bootstrap';
import Spin from '../../pages/common/Spin';

const ConfirmBackupEmail = (props) => {
  const { submitButton, verifyUrl, backupEmail } = props;
  // const url = localStorage.getItem("myna-auth-email")
  const [validator, setValidator] = useState(false);
  const [email, setEmail] = useState('');
  const [emailVerifyCode, setEmailVerifyCode] = useState('');
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const test = async () => {
      const { data } = await get(API.GET_USER_PROFILE);
      if (data?.backup_email) setEmail(data.backup_email);
    };
    if (!backupEmail) {
      test();
    } else {
      setEmail(backupEmail);
    }
  }, [backupEmail]);

  const changeEmailCode = (event) => {
    if (validator) {
      setValidator(false);
    }
    setEmailVerifyCode(event.target.value);
    // setEnterCode(true)
  };

  const confirmEmail = async () => {
    setLoading(true);
    // const { data } = await post(API.GOOGLE_2FA_VERIFY, { code: verifyCode });
    let params = { code: emailVerifyCode };
    if (verifyUrl === API.DELETE_2FA) {
      params.method = 2;
    }
    post(verifyUrl, { code: emailVerifyCode })
      .then(async (response) => {
        toast.success(response?.data?.message);
        await submitButton();
        setValidator(false);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Authentication code is invalid');
        setLoading(false);
        setValidator(true);
      });
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={confirmEmail}
        autoComplete="off"
      >
        { loading && <Spin /> }
        <h3>Check your backup email and enter the code</h3>
        <p>{`Enter the code sent to ${email} to confirm your email address`}</p>
        <Form.Item
          label=""
          name="authCode"
          rules={[
            { required: true, message: 'Please input your Email confirmation code!' },
            {
              required: true,
              message: 'confirmation code length should be 6',
              pattern: new RegExp(/^[0-9]{6}$/)
            },
            () => ({
              validator() {
                if (validator) {
                  return Promise.reject('confirmation code is not valid');
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input autoFocus placeholder="Confirmation Code" onChange={changeEmailCode} />
        </Form.Item>
        <Form.Item>
          <Button type="submit">Confirm</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ConfirmBackupEmail;
