/* eslint-disable no-loop-func */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import BackButton from '../../../components/Form/BackButton';
import { API, fileUpload, imageDefaultPrefixPath } from '../../../config';
import Spin from '../../common/Spin';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getBusinessData } from '../components/BusinessHelper';
import { checkIfFileExists, formatNumber } from '../../common/Misc';
import { DateRangePicker } from 'rsuite';
import ExpandedPLReport from './components/ExpandedPLReport';

const currentDate = new Date();
const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format('MM')) === 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : parseInt(moment(today).format('MM')) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1));
}

const BalanceSheetReport = ({ dateRangeReport, setDateRangeReport }) => {
  const params = useParams();
  // const { afterToday } = DateRangePicker;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const businessId = params?.business_id;

  const [bsLoading, setBSLoading] = useState(false);
  const [bsData, setBSData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const plTableRef = useRef();

  // const [valueRange, setValueRange] = useState(pastFinancialdate);

  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  const [dateValue, setDateValue] = useState(dateRangeReport ? dateRangeReport : defaultDateRange);
  const [startDate, setStartDate] = useState(
    dateRangeReport
      ? moment(dateRangeReport[0]).format('DD-MM-YYYY')
      : defaultDateRange
      ? moment(defaultDateRange[0]).format('DD-MM-YYYY')
      : ''
  );
  const [endDate, setEndDate] = useState(
    dateRangeReport
      ? moment(dateRangeReport[1]).format('DD-MM-YYYY')
      : defaultDateRange
      ? moment(defaultDateRange[1]).format('DD-MM-YYYY')
      : ''
  );

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0)
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0)
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0)
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0)
    ];
  }

  const dateFilterRange = [
    {
      label: 'This Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0)
      ],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    }
  ];

  const fetchBSData = useCallback(async () => {
    const formData = new FormData();
    formData.append('client_business_id', businessId);
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');
    formData.append('export', 0);
    if (businessId) {
      try {
        setBSLoading(true);
        const { data } = await fileUpload(`${API.BALANCE_SHEET_REPORT}`, formData);

        // Current Liabilities
        let totalCurrentLiabilities = 0;
        let ReportCurrentLiabilitiesData = [];

        // Non Current Liabilities
        let totalNonCurrentLiabilities = 0;
        let ReportNonCurrentLiabilitiesData = [];

        // Current Assets
        let totalCurrentAssets = 0;
        let ReportCurrentAssetsData = [];

        // Non Current Assets
        let totalNonCurrentAssets = 0;
        let ReportNonCurrentAssetsData = [];

        // Equity
        let totalEquity = 0;
        let ReportEquityData = [];

        for (let j = 0; j < data?.data?.length; j++) {
          if (data?.data[j]?.account_type_name === 'Current Liabilities') {
            if (data?.data[j]?.chart_of_accounts && data?.data[j]?.chart_of_accounts?.length > 0) {
              ReportCurrentLiabilitiesData = data?.data[j]?.chart_of_accounts?.map((item) => {
                // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
                let obj = {
                  ...item
                };
                totalCurrentLiabilities += Number(item?.amount);
                return obj;
              });
            }
          }

          if (data?.data[j]?.account_type_name === 'Non-Current Liabilities') {
            if (data?.data[j]?.chart_of_accounts && data?.data[j]?.chart_of_accounts?.length > 0) {
              ReportNonCurrentLiabilitiesData = data?.data[j]?.chart_of_accounts?.map((item) => {
                // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
                let obj = {
                  ...item
                };
                totalNonCurrentLiabilities += Number(item?.amount);
                return obj;
              });
            }
          }

          if (data?.data[j]?.account_type_name === 'Current Assets') {
            if (data?.data[j]?.chart_of_accounts && data?.data[j]?.chart_of_accounts?.length > 0) {
              ReportCurrentAssetsData = data?.data[j]?.chart_of_accounts?.map((item) => {
                // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
                let obj = {
                  ...item
                };
                totalCurrentAssets += Number(item?.amount);
                return obj;
              });
            }
          }

          if (data?.data[j]?.account_type_name === 'Non-Current Assets') {
            if (data?.data[j]?.chart_of_accounts && data?.data[j]?.chart_of_accounts?.length > 0) {
              ReportNonCurrentAssetsData = data?.data[j]?.chart_of_accounts?.map((item) => {
                // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
                let obj = {
                  ...item
                };
                totalNonCurrentAssets += Number(item?.amount);
                return obj;
              });
            }
          }

          if(data?.data[j]?.account_type_name === 'Equity') {
            if (data?.data[j]?.chart_of_accounts && data?.data[j]?.chart_of_accounts?.length > 0) {
              ReportEquityData = data?.data[j]?.chart_of_accounts?.map((item) => {
                // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
                let obj = {
                  ...item
                };
                totalEquity += Number(item?.amount);
                return obj;
              });
            }
          }
        }
        let TotalCurrentLiabilitiesData = {
          total_current_liabilities_name: 'Total Current Liabilities',
          total_current_liabilities: totalCurrentLiabilities
        };

        let TotalNonCurrentLiabilitiesData = {
          total_non_current_liabilities_name: 'Total Non Current Liabilities',
          total_non_current_liabilities: totalNonCurrentLiabilities
        };

        let TotalCurrentAssetsData = {
          total_current_assets_name: 'Total Current Assets',
          total_current_assets: totalCurrentAssets
        };

        let TotalNonCurrentAssetsData = {
          total_non_current_assets_name: 'Total Non Current Assets',
          total_non_current_assets: totalNonCurrentAssets
        };

        let TotalEquityData = {
          total_equity_name: 'Total Equity',
          total_equity: totalEquity
        };

        setBSLoading(false);
        setBSData({
          current_liabilities: [].concat(ReportCurrentLiabilitiesData),
          totalCurrentLiabilitiesData: TotalCurrentLiabilitiesData,
          non_current_liabilities: [].concat(ReportNonCurrentLiabilitiesData),
          totalNonCurrentLiabilitiesData: TotalNonCurrentLiabilitiesData,
          current_assets: [].concat(ReportCurrentAssetsData),
          totalCurrentAssetsData: TotalCurrentAssetsData,
          non_current_assets: [].concat(ReportNonCurrentAssetsData),
          totalNonCurrentAssetsData: TotalNonCurrentAssetsData,
          equity: [].concat(ReportEquityData),
          totalEquityData: TotalEquityData
        });
      } catch (error) {
        const { data } = error.response;
        setBSLoading(false);
        setBSData([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    }
    // eslint-disable-next-line
  }, [businessId, startDate, endDate]);

  const BSDataGenerator = (quantity) => {
    const items = [];

    if (quantity !== 0) {
      items.push({
        key: `Current Liabilities`,
        account: <div className="pl-report-type-heading">Current Liabilities</div>,
        amount: <div className="report_blank_data"></div>
      });

      for (let i = 0; i < bsData?.current_liabilities?.length; i++) {
        if (bsData?.current_liabilities[i]?.amount !== 0) {
          items.push({
            key: `item-current_liabilities-[${i}]`,
            id: bsData?.current_liabilities[i]?.chart_account_id,
            account: bsData?.current_liabilities[i]?.chart_account_name,
            amount: bsData?.current_liabilities[i]?.amount
              ? formatNumber(Math.abs(bsData?.current_liabilities[i]?.amount))
              : '0.00'
          });
        }
      }

      items.push({
        key: `totalCurrentLiabilities`,
        account: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalCurrentLiabilitiesData?.total_current_liabilities_name}
          </div>
        ),
        amount: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalCurrentLiabilitiesData?.total_current_liabilities
              ? formatNumber(
                  Math.abs(bsData?.totalCurrentLiabilitiesData?.total_current_liabilities)
                )
              : '0.00'}
          </div>
        )
      });

      items.push({
        key: `blank-row`,
        account: <div className="pl-report-type-heading"></div>,
        amount: <div className="report_blank_data"></div>
      });

      items.push({
        key: `Non Current Liabilities`,
        account: <div className="pl-report-type-heading">Non Current Liabilities</div>,
        amount: <div className="report_blank_data"></div>
      });

      for (let i = 0; i < bsData?.non_current_liabilities?.length; i++) {
        if (bsData?.non_current_liabilities[i]?.amount !== 0) {
          items.push({
            key: `item-non_current_liabilities-[${i}]`,
            id: bsData?.non_current_liabilities[i]?.chart_account_id,
            account: bsData?.non_current_liabilities[i]?.chart_account_name,
            amount: bsData?.non_current_liabilities[i]?.amount
              ? formatNumber(Math.abs(bsData?.non_current_liabilities[i]?.amount))
              : '0.00'
          });
        }
      }

      items.push({
        key: `totalNonCurrentLiabilities`,
        account: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalNonCurrentLiabilitiesData?.total_non_current_liabilities_name}
          </div>
        ),
        amount: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalNonCurrentLiabilitiesData?.total_non_current_liabilities
              ? formatNumber(
                  Math.abs(bsData?.totalNonCurrentLiabilitiesData?.total_non_current_liabilities)
                )
              : '0.00'}
          </div>
        )
      });

      items.push({
        key: `blank-row`,
        account: <div className="pl-report-type-heading"></div>,
        amount: <div className="report_blank_data"></div>
      });

      items.push({
        key: `Current Assets`,
        account: <div className="pl-report-type-heading">Current Assets</div>,
        amount: <div className="report_blank_data"></div>
      });

      for (let i = 0; i < bsData?.current_assets?.length; i++) {
        if (bsData?.current_assets[i]?.amount !== 0) {
          items.push({
            key: `item-current_assets-[${i}]`,
            id: bsData?.current_assets[i]?.chart_account_id,
            account: bsData?.current_assets[i]?.chart_account_name,
            amount: bsData?.current_assets[i]?.amount
              ? formatNumber(Math.abs(bsData?.current_assets[i]?.amount))
              : '0.00'
          });
        }
      }

      items.push({
        key: `totalCurrentAssets`,
        account: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalCurrentAssetsData?.total_current_assets_name}
          </div>
        ),
        amount: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalCurrentAssetsData?.total_current_assets
              ? formatNumber(Math.abs(bsData?.totalCurrentAssetsData?.total_current_assets))
              : '0.00'}
          </div>
        )
      });

      items.push({
        key: `blank-row`,
        account: <div className="pl-report-type-heading"></div>,
        amount: <div className="report_blank_data"></div>
      });

      items.push({
        key: `Non Current Assets`,
        account: <div className="pl-report-type-heading">Non Current Assets</div>,
        amount: <div className="report_blank_data"></div>
      });

      for (let i = 0; i < bsData?.non_current_assets?.length; i++) {
        if (bsData?.non_current_assets[i]?.amount !== 0) {
          items.push({
            key: `item-non_current_assets-[${i}]`,
            id: bsData?.non_current_assets[i]?.chart_account_id,
            account: bsData?.non_current_assets[i]?.chart_account_name,
            amount: bsData?.non_current_assets[i]?.amount
              ? formatNumber(Math.abs(bsData?.non_current_assets[i]?.amount))
              : '0.00'
          });
        }
      }

      items.push({
        key: `totalNonCurrentAssets`,
        account: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalNonCurrentAssetsData?.total_non_current_assets_name}
          </div>
        ),
        amount: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalNonCurrentAssetsData?.total_non_current_assets
              ? formatNumber(Math.abs(bsData?.totalNonCurrentAssetsData?.total_non_current_assets))
              : '0.00'}
          </div>
        )
      });

      items.push({
        key: `blank-row`,
        account: <div className="pl-report-type-heading"></div>,
        amount: <div className="report_blank_data"></div>
      });

      items.push({
        key: `Equity`,
        account: <div className="pl-report-type-heading">Equity</div>,
        amount: <div className="report_blank_data"></div>
      });

      for (let i = 0; i < bsData?.equity?.length; i++) {
        if (bsData?.equity[i]?.amount !== 0) {
          items.push({
            key: `item-equity-[${i}]`,
            id: bsData?.equity[i]?.chart_account_id,
            account: bsData?.equity[i]?.chart_account_name,
            amount: bsData?.equity[i]?.amount
              ? formatNumber(Math.abs(bsData?.equity[i]?.amount))
              : '0.00'
          });
        }
      }

      items.push({
        key: `totalEquity`,
        account: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalEquityData?.total_equity_name}
          </div>
        ),
        amount: (
          <div className="pl-report-type-heading pl-total-amount">
            {bsData?.totalEquityData?.total_equity
              ? formatNumber(Math.abs(bsData?.totalEquityData?.total_equity))
              : '0.00'}
          </div>
        )
      });
    }

    return items;
  };

  const PLReportData = BSDataGenerator(bsData?.length);

  useEffect(() => {
    fetchBSData();
    // eslint-disable-next-line
  }, [fetchBSData]);

  const columns = [
    {
      dataField: 'account',
      text: '',
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.account?.props?.className === 'pl-report-type-heading' && { colSpan: '2' }
    },
    {
      dataField: 'amount',
      text: 'Amount Ex.GST($)',
      align: 'right',
      headerAlign: (column, colIndex) => 'right',
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.amount?.props?.className === 'report_blank_data' && { hidden: true }
    }
  ];

  const handleExportReport = async (format) => {
    const formData = new FormData();
    formData.append('client_business_id', businessId);
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');
    formData.append('export', 1);
    formData.append('format', format);
    try {
      setExportLoading(true);
      const { data } = await fileUpload(`${API.BALANCE_SHEET_REPORT}`, formData);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement('a');
        a.href = export_report_path;
        var file = export_report_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'trial_balance_report.pdf');
        a.setAttribute('target', '_blank');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleDateRange = (e) => {
    setBSData([]);
    setDateValue(e);
    setDateRangeReport(e);
    setStartDate(e ? moment(e[0]).format('DD-MM-YYYY') : '');
    setEndDate(e ? moment(e[1]).format('DD-MM-YYYY') : '');
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandedPLReport
          id={row?.id}
          businessId={businessId}
          startDate={startDate}
          endDate={endDate}
          balanceSheet={true}
        />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: [
      'Current Liabilities',
      'totalCurrentLiabilities',
      'Non Current Liabilities',
      'totalNonCurrentLiabilities',
      'Current Assets',
      'totalCurrentAssets',
      'Non Current Assets',
      'totalNonCurrentAssets',
      'Equity',
      'totalEquity',
      'blank-row'
    ],
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      if (
        rowKey === 'Current Liabilities' ||
        rowKey === 'totalCurrentLiabilities' ||
        rowKey === 'Non Current Liabilities' ||
        rowKey === 'totalNonCurrentLiabilities' ||
        rowKey === 'Current Assets' ||
        rowKey === 'totalCurrentAssets' ||
        rowKey === 'Non Current Assets' ||
        rowKey === 'totalNonCurrentAssets' ||
        rowKey === 'Equity' ||
        rowKey === 'totalEquity' ||
        rowKey === 'blank-row'
      ) {
        return <div className="not-expaned-cell-pl-report" />;
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Balance Sheet
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1>
          <div className="pl-report-body row">
            <div className="content-details pl-report-details">
              <div className="table-top-btn report-header-row row">
                <div className="report-name report-period-name">
                  <span>
                    {' '}
                    Balance Sheet
                    {startDate && endDate && ` For the period from ${startDate} to ${endDate}`}
                  </span>
                </div>
                <div className="table-btn">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {exportLoading ? 'Loading…' : 'Export Balance Sheet'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleExportReport("csv")}>
                      CSV
                    </Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleExportReport('pdf')}>PDF</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleExportReport('xlsx')}>
                        Excel
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div
                  className="summary-filter"
                  style={{
                    padding: '0',
                    border: 'none',
                    marginTop: '0px',
                    marginLeft: '20px'
                  }}
                >
                  <Form>
                    <DateRangePicker
                      placeholder="Select Date Range"
                      cleanable={false}
                      onChange={(e) => handleDateRange(e)}
                      format="dd-MM-yyyy"
                      value={dateValue}
                      placement="bottomEnd"
                      ranges={dateFilterRange}
                      locale={{ ok: 'Apply' }}
                      // shouldDisableDate={afterToday()}
                    />
                  </Form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pl-report-table">
                    {bsLoading && <Spin />}
                    <BootstrapTable
                      keyField="key"
                      remote
                      ref={plTableRef}
                      data={PLReportData}
                      columns={columns}
                      expandRow={expandRow}
                      noDataIndication="No Data Found"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheetReport;
