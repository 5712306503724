import React, { useEffect, useState } from "react";
import { API, get, metaTitle } from '../../../config';
import "../../../assets/css/style.css";
import { toast } from 'react-toastify';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const AdminDashboard = () => {

  const [dashboardData, setDashboardData] = useState(null);

  const fetchDashboardDetails = async () => {
    try {
      const { data } = await get(API.ADMIN_DASHBOARD);
      setDashboardData(data.data);
    } catch (error) {
      const errors = error.response?.data?.errors;
      toast.error(error.response.data.message);
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }

  useEffect(() => {
    fetchDashboardDetails();
    window.document.title = `${metaTitle} | Admin Dashboard`
  }, []);

  return (
    <div className="admin-dashboard">
      <div className="admin-box">
        <div className="icon">
          <FontAwesomeIcon icon={faUsers} />
        </div>
        <h4>Total Subscriptions</h4>
        <h2>{dashboardData?.subscriptions ? dashboardData?.subscriptions : 0}</h2>
        <p>
          <strong>Last Updated:</strong>{' '}
          {moment().format('DD-MM-YYYY')}
        </p>

      </div>
      <div className="admin-box">
        <div className="icon">
          <FontAwesomeIcon icon={faUsers} />
        </div>
        <h4>Reg. By Invitation</h4>
        <h2>{dashboardData?.invUserCount ? dashboardData?.invUserCount : 0}</h2>
        <p>
          <strong>Last Updated:</strong>{' '}
          {moment().format('DD-MM-YYYY')}
        </p>

      </div>
      <div className="admin-box">
        <div className="icon">
          <FontAwesomeIcon icon={faUsers} />
        </div>
        <h4>Total Registered Users</h4>
        <h2>
          {dashboardData?.regUserCount ? dashboardData?.regUserCount : 0}
        </h2>
        <p>
          <strong>Last Updated:</strong>{' '}
          {moment().format('DD-MM-YYYY')}
        </p>
      </div>
    </div>
  );
};

export default AdminDashboard;
