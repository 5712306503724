import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/newstyle.css";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import AdminDashboard from "./pages/Admin/dashboard/Index";
import ProtectedRoute from "./ProtectedRoute";
import { useCallback, useEffect, useState } from "react";
import { API, post } from "./config";
import Clients from "./pages/Settings/clients/Clients";
import AddClients from "./pages/Settings/clients/AddClient";
import EditClient from "./pages/Settings/clients/EditClient";
import Tabs from "./tabs";
import BusinessDashBoard from "./pages/Business/BusinessDashBoard";
import Customers from "./pages/Business/customers/Customers";
import AddCustomer from "./pages/Business/customers/AddCustomer";
import EditCustomer from "./pages/Business/customers/EditCustomer";
import Contractors from "./pages/Business/contractors/Contractors";
import AddContractor from "./pages/Business/contractors/AddContractor";
import EditContractor from "./pages/Business/contractors/EditContractor";
import Suppliers from "./pages/Business/suppliers/Suppliers";
import AddSupplier from "./pages/Business/suppliers/AddSupplier";
import EditSupplier from "./pages/Business/suppliers/EditSupplier";
import ChartOfAccounts from "./pages/Business/accounting/ChartOfAccounts";
import Settings from "./pages/Business/settings/Settings";
import UserSetting from "./pages/user-setting/UserSetting";
import AllContacts from "./pages/Business/AllContacts/AllContacts";
import AddContact from "./pages/Business/AllContacts/AddContact";
import EditContact from "./pages/Business/AllContacts/EditContact";
// import ImportTransaction from "./pages/Business/Banking/ImportTransaction";
import ReconcileTransactionsTab from "./pages/Business/Banking/ReconcileTransactionTab";
import SearchTerms from "./pages/Business/settings/search-terms/SearchTerms";
import AddSearchTerm from "./pages/Business/settings/search-terms/AddSearchTerm";
import EditSearchTerm from "./pages/Business/settings/search-terms/EditSearchTerm";
import TrialBalanceReport from "./pages/Business/reports/TrialBalanceReport";
import GeneralLedgerReport from "./pages/Business/reports/GeneralLedgerReport";
import BusinessForClient from "./pages/Settings/Business/BusinessForClient";
import ContactStatements from "./pages/Business/reports/ContactStatements";
import FirmSignup from "./pages/firm/FirmSignup";
import TermsOfUse from "./pages/TermsOfUse";
import TermsAndConditions from "./pages/TermsAndConditions";
import Subscription from "./pages/firm/Subscription";
import Congratulation from "./pages/firm/Congratulation";
import EmailVerification from "./pages/firm/EmailVerification";
import ClientDashboard from "./pages/Settings/ClientDashboard";
import Users from "./pages/Users/Users";
import Offices from "./pages/Offices/Offices";
import InviteAccountingFirm from "./pages/InviteAccountingFirm/InviteAccountingFirm";
import GeneralSetting from "./pages/general-settings/GeneralSetting";
import Bas from "./pages/Business/BAS/Bas";
import AddInvoice from "./pages/Business/Invoicing/invoices/components/AddInvoice";
import ConnectBank from "./pages/Business/settings/integrations/ConnectBank";
import SubscriptionsForAdmin from "./pages/Admin/SubscriptionsForAdmin";
import RegisteredUsersForAdmin from "./pages/Admin/RegisteredUsresForAdmin";
// import Jobs from "./pages/Business/jobs/Jobs";
// import Tasks from "./pages/Business/tasks/Tasks";
import UsersForClient from "./pages/Settings/clients/UsersForClient";
import TransactionReport from "./pages/Business/reports/TransactionReport";
import PLReport from "./pages/Business/reports/PLReport";
import FinancialReportTab from "./pages/Business/reports/FinancialReportTab";
import DiscountCode from "./pages/Business/DiscountCode/DiscountCode";
import Template from "./pages/Business/settings/template/Template";
import StandardParagraph from "./pages/Business/StandardParagrah/StandardParagrah";
import Invoices from "./pages/Business/Invoicing/invoices/Invoices";
import InvoicingSettingsTab from "./pages/Business/Invoicing/InvoicingSettings/InvoicingSettingsTab";
import InvoiceLayoutSettings from "./pages/Business/Invoicing/InvoiceLayoutSettings/InvoiceLayoutSettings";
import Quotes from "./pages/Business/Invoicing/quotes/Quotes";
import Receipts from "./pages/Business/Invoicing/receipts/Receipts";
import TwoFactor from "./pages/auth/TwoFactor";
import TwoFactorRegister from "./pages/auth/TwoFactorRegister"
import TwoFactorChange from "./pages/auth/TwoFactorChange"
import TwoFactorDisable from "./pages/auth/TwoFactorDisable";
import AuthRoute from "./AuthRoute";
// import 'antd/dist/antd.min.css'
import QuoteLayoutSettings from "./pages/Business/Invoicing/QuoteLayoutSettings/QuoteLayoutSettings";
import ReceiptLayoutSettings from "./pages/Business/Invoicing/ReceiptLayoutSettings/ReceiptLayoutSettings";
import AddQuote from "./pages/Business/Invoicing/quotes/components/AddQuote";
import JobTaskSettings from "./pages/Business/settings/JobTaskSettings";
import CashFlowTabs from "./pages/Business/CashFlow/CashFlowTabs";
import LayoutSettings from "./pages/Business/Invoicing/LayoutSettings/LayoutSettings";
import JournalEntries from "./pages/Business/journal-entries/JournalEntries";
import AddJournalEntry from "./pages/Business/journal-entries/AddJournalEntry";
import EditJournalEntry from "./pages/Business/journal-entries/EditJournalEntry";
import EditQuote from "./pages/Business/Invoicing/quotes/components/EditQuote";
import AcceptQuote from "./pages/Business/Invoicing/quotes/components/AcceptQuote";
import EditInvoice from "./pages/Business/Invoicing/invoices/components/EditInvoice";
import AddReceipt from "./pages/Business/Invoicing/receipts/AddReceipt";
import EditReceipt from "./pages/Business/Invoicing/receipts/EditReceipt";
import AcceptInvoice from "./pages/Business/Invoicing/invoices/components/AcceptInvoice";
// import InvoicePayNow from "./pages/Business/Invoicing/invoices/InvoicePayNow";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tfa, setTfa] = useState(0);
  const halfHour = 25 * 60000;
  // const clientId = localStorage.getItem("sme-clientId"); 
  const role = localStorage.getItem("sme-role");

  // The Section Class Add on Body tag.
  const clientSection = document.getElementsByClassName('client-section');
  const businessSection = document.getElementsByClassName('business-section');
  let prevUrl = undefined;
  setInterval(() => {
    const currUrl = window.location.href;
    if (currUrl !== prevUrl) {
      // URL changed
      prevUrl = currUrl;
      if(clientSection[0]?.localName === "div" || clientSection[1]?.localName === "div"){
        document.body.classList.add('client-section');
        if(document.body.classList.contains('business-section')){
          document.body.classList.remove('business-section');
        }
      } else if(businessSection[0]?.localName === "div" || businessSection[1]?.localName === "div"){
        document.body.classList.add('business-section');
        if(document.body.classList.contains('client-section')){
          document.body.classList.remove('client-section');
        }
      } else {
        if(document.body.classList.contains('client-section')){
          document.body.classList.remove('client-section');
        }
        if(document.body.classList.contains('business-section')){
          document.body.classList.remove('business-section');
        }
      }
    }
  }, 60);

  const authenticate = useCallback(
    (token, email, tfaCheck = 0, refreshToken) => {
      if (tfaCheck) {
        localStorage.setItem("sme-auth-tfa", tfaCheck);
      }
      setIsAuthenticated(true);
      setTfa(tfa);

      localStorage.setItem("sme-auth-token", token);
      localStorage.setItem('sme-refresh-token', refreshToken);
      setInterval(() => {
        refresh();
      }, [halfHour]);
      localStorage.setItem("sme-auth-email", email);
    },
    // eslint-disable-next-line
    [tfa, halfHour]
  );

  useEffect(() => {
    const lsToken = localStorage.getItem("sme-auth-token");
    const email = localStorage.getItem("sme-auth-email");
    const tfaCheck = localStorage.getItem("sme-auth-tfa");
    const reToken = localStorage.getItem('sme-refresh-token');

    if (lsToken || reToken) {
      authenticate(lsToken, email, tfaCheck, reToken);
    }
  }, [authenticate]);

  const refresh = async () => {
    var currentdate = new Date();
    const authLoginTime = localStorage.getItem("sme-auth-login-time");
    function diff_minutes(dt2, dt1) {
      var diff = (dt2.getTime() - dt1) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
    const diff_time = diff_minutes(currentdate, authLoginTime);
    if (diff_time >= 45.0) {
      try {
        const response = await post(API.REFRESH, "");
        if (response?.data?.status === 200) {
          localStorage.setItem("sme-auth-token", response?.data?.access_token);
          localStorage.setItem("sme-refresh-token", response?.data?.refresh_token);
          localStorage.setItem("sme-auth-login-time", currentdate.getTime());
        }
      } catch (error) {
        const { data } = error.response;
        if(data.status === 500 && data.message.split(": ")[0] === "Unable to create lockable file"){
          refresh();
        }
        else {
          toast.error(data.message);
          setTimeout(() => {
            localStorage.clear();
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Login
                authenticate={authenticate}
                isAuthenticated={isAuthenticated}
                tfa={tfa}
              />
            }
          />
          <Route exact path="/subscription" element={<Subscription />} />
          <Route exact path="/congratulations" element={<Congratulation />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/signup" element={<FirmSignup />} />
          <Route
            exact
            path="/email-verification"
            element={<EmailVerification />}
          />
          <Route 
            exact 
            path="/2fa" 
            element={<AuthRoute component={TwoFactor}></AuthRoute>} 
            // element={<TwoFactor />}
          />
          <Route
            exact
            path="/2fa/register"
            element={<AuthRoute component={TwoFactorRegister}></AuthRoute>}
          />
          <Route
            exact
            path="/2fa/change"
            element={<AuthRoute component={TwoFactorChange}></AuthRoute>}
          />
          <Route
            exact
            path="/2fa/disable"
            element={<AuthRoute component={TwoFactorDisable}></AuthRoute>}
          />
          <Route exact path="/terms-of-use" element={<TermsOfUse />} />
          <Route
            exact
            path="/terms-and-condition"
            element={<TermsAndConditions />}
          />
          <Route exact path="/tabs" element={<Tabs />} />

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/clients"
              element={<ProtectedRoute component={Clients}></ProtectedRoute>}
            />
          )}

          {role !== "Admin" && (
            <Route
              path="/businesses"
              element={
                <ProtectedRoute component={ BusinessForClient }></ProtectedRoute>
              }
            />
          )}

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/client/:client_id/businesses"
              element={
                <ProtectedRoute component={ BusinessForClient }></ProtectedRoute>
              }
            />
          )}

          {role !== "Admin" && (
            <Route
              path="/client-users"
              element={
                <ProtectedRoute component={ UsersForClient }></ProtectedRoute>
              }
            />
          )}

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/client/:client_id/users"
              element={
                <ProtectedRoute component={ UsersForClient }></ProtectedRoute>
              }
            />
          )}

          <Route
            path="/accounting-firm-invitation"
            element={<InviteAccountingFirm />}
          />

          {role !== "Admin" && role !== "Client" && (
            <Route
              path="/users"
              element={<ProtectedRoute component={ Users }></ProtectedRoute>}
            />
          )}

          {role !== "Admin" && (
            <Route
              path="/offices"
              element={<ProtectedRoute component={ Offices }></ProtectedRoute>}
            />
          )}

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/client/:client_id/offices"
              element={<ProtectedRoute component={Offices}></ProtectedRoute>}
            />
          )}

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/add-client"
              element={<ProtectedRoute component={ AddClients }></ProtectedRoute>}
            />
          )}

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/edit-client"
              element={<ProtectedRoute component={ EditClient }></ProtectedRoute>}
            />
          )}

          {role !== "Client" && role !== "Admin" && (
            <Route
              path="/client/:client_id"
              element={
                <ProtectedRoute component={ ClientDashboard }></ProtectedRoute>
              }
            />
          )}

          <Route
            path="user-settings"
            element={<ProtectedRoute component={ UserSetting } notSubscription={true}></ProtectedRoute>}
          />

          <Route
            path="general-settings"
            element={
              <ProtectedRoute component={GeneralSetting} notSubscription={true}></ProtectedRoute>
            }
          />

          <Route
            path="/business/:business_id"
            element={
              <ProtectedRoute component={ BusinessDashBoard }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/customers"
            element={<ProtectedRoute component={ Customers }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-customer"
            element={<ProtectedRoute component={ AddCustomer }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-customer"
            element={<ProtectedRoute component={ EditCustomer }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/contractors"
            element={<ProtectedRoute component={ Contractors }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-contractor"
            element={
              <ProtectedRoute component={ AddContractor }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/edit-contractor"
            element={
              <ProtectedRoute component={ EditContractor }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/suppliers"
            element={<ProtectedRoute component={ Suppliers }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-supplier"
            element={<ProtectedRoute component={ AddSupplier }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-supplier"
            element={<ProtectedRoute component={ EditSupplier }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/chart-of-accounts"
            element={
              <ProtectedRoute component={ ChartOfAccounts }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/journal-entries"
            element={
              <ProtectedRoute component={JournalEntries}></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/journal-entries/add"
            element={
              <ProtectedRoute component={AddJournalEntry}></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/journal-entries/edit"
            element={
              <ProtectedRoute component={EditJournalEntry}></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/bas"
            element={<ProtectedRoute component={ Bas }></ProtectedRoute>}
          />
          {/* <Route
            path="business/:business_id/tasks"
            element={<ProtectedRoute component={Tasks}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/jobs"
            element={<ProtectedRoute component={Jobs}></ProtectedRoute>}
          /> */}
          <Route
            path="business/:business_id/cash-transactions"
            element={
              <ProtectedRoute
                component={ CashFlowTabs }
              ></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/invoices"
            element={<ProtectedRoute component={Invoices}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/quotes"
            element={<ProtectedRoute component={Quotes}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/receipts"
            element={<ProtectedRoute component={Receipts}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-receipt"
            element={<ProtectedRoute component={AddReceipt}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-receipt"
            element={<ProtectedRoute component={EditReceipt}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-invoice"
            element={<ProtectedRoute component={AddInvoice}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-invoice"
            element={<ProtectedRoute component={EditInvoice}></ProtectedRoute>}
          />
          {/* <Route 
            path="/invoice/:invoice_id/paynow" 
            element={<InvoicePayNow />}
          /> */}
          <Route 
            path="business/:business_id/quote/:quote_id/accept/:status" 
            element={<AcceptQuote />}
          />
          <Route 
            path="business/:business_id/invoice/:invoice_id/accept/:status" 
            element={<AcceptInvoice />}
          />
          <Route
            path="business/:business_id/add-quote"
            element={<ProtectedRoute component={AddQuote}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-quote"
            element={<ProtectedRoute component={EditQuote}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-quote/:id"
            element={<ProtectedRoute component={EditQuote}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/invoicing/settings"
            element={<ProtectedRoute component={InvoicingSettingsTab}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings"
            element={<ProtectedRoute component={LayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/invoice-template"
            element={<ProtectedRoute component={InvoiceLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/invoice-template/:id"
            element={<ProtectedRoute component={InvoiceLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/quote-template"
            element={<ProtectedRoute component={QuoteLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/quote-template/:id"
            element={<ProtectedRoute component={QuoteLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/receipt-template"
            element={<ProtectedRoute component={ReceiptLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/receipt-template/:id"
            element={<ProtectedRoute component={ReceiptLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/invoice-template/:templateId"
            element={<ProtectedRoute component={InvoiceLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/quote-template/:templateId"
            element={<ProtectedRoute component={QuoteLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings/receipt-template/:templateId"
            element={<ProtectedRoute component={ReceiptLayoutSettings}></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/paragraphs"
            element={<ProtectedRoute component={ StandardParagraph }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/discount-codes"
            element={<ProtectedRoute component={ DiscountCode }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/settings"
            element={<ProtectedRoute component={ Settings }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/job-task-settings"
            element={<ProtectedRoute component={ JobTaskSettings }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/template-settings"
            element={<ProtectedRoute component={ Template }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/terms"
            element={<ProtectedRoute component={ SearchTerms }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-term"
            element={
              <ProtectedRoute component={ AddSearchTerm }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/edit-term"
            element={
              <ProtectedRoute component={ EditSearchTerm }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/all-contacts"
            element={<ProtectedRoute component={ AllContacts }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/add-contact"
            element={<ProtectedRoute component={ AddContact }></ProtectedRoute>}
          />
          <Route
            path="business/:business_id/edit-contact"
            element={<ProtectedRoute component={ EditContact }></ProtectedRoute>}
          />

          {/* <Route
            path="business/:business_id/transactions"
            element={
              <ProtectedRoute component={ImportTransaction}></ProtectedRoute>
            }
          /> */}
          <Route
            path="business/:business_id/reconcile-transactions"
            element={
              <ProtectedRoute
                component={ ReconcileTransactionsTab }
              ></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/financial-reports"
            element={
              <ProtectedRoute
                component={ FinancialReportTab }
              ></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/general-ledger"
            element={
              <ProtectedRoute component={ GeneralLedgerReport }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/trial-balance-report"
            element={
              <ProtectedRoute component={ TrialBalanceReport }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/account-transactions-report"
            element={
              <ProtectedRoute component={ TransactionReport }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/contact-statement-report"
            element={
              <ProtectedRoute component={ ContactStatements }></ProtectedRoute>
            }
          />
          <Route
            path="business/:business_id/profit-and-loss-report"
            element={
              <ProtectedRoute component={ PLReport }></ProtectedRoute>
            }
          />
          <Route exact path="/connect-to-bank" element={ <ConnectBank /> } />

          {/* Admin Routes */}

          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute component={AdminDashboard}></ProtectedRoute>
            }
          />
          <Route
            path="/admin/subscriptions"
            element={
              <ProtectedRoute
                component={SubscriptionsForAdmin}
              ></ProtectedRoute>
            }
          />
          <Route
            path="/admin/registered-users"
            element={
              <ProtectedRoute
                component={RegisteredUsersForAdmin}
              ></ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
