import { faDownload, faEdit, faEye, faPaperPlane, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import ClientDelete from "./ClientDelete";
import CustomTooltip from '../../../../common/CustomTooltip';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spin from '../../../../common/Spin';
import { API, deleteCall, get, s3DefaultPath } from '../../../../../config';
import ViewQuote from './ViewQuote';
import axios from 'axios';

const QuoteActionButton = ({
  id,
  handleDelete,
  email,
  status,
  send,
  businessData,
  numberOption,
  file
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showQuoteSend, setShowQuoteSend] = useState(false);
  const [showQuoteReSend, setShowQuoteReSend] = useState(false);
  const [quoteSendLoading, setQuoteSendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleQuoteReSendModal = () => {
    setShowQuoteReSend(!showQuoteReSend);
  };

  const toggleQuoteSendModal = () => {
    setShowQuoteSend(!showQuoteSend);
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${s3DefaultPath}${path}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var file = path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'new_aba.aba');
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error('File is not exist.');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleResendQuote = async () => {
    try {
      setQuoteSendLoading(true);
      const { data } = await get(`${API.QUOTE_SEND}/${id}?client_business_id=${businessId}&send=1`);
      setQuoteSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleQuoteReSendModal();
      }
      return data?.data;
    } catch (error) {
      setQuoteSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setQuoteSendLoading(false);
      } else {
        toast.error(error?.message);
        setQuoteSendLoading(false);
      }
    }
  };
  
  const handleSendQuote = async () => {
    try {
      setQuoteSendLoading(true);
      const { data } = await get(`${API.QUOTE_SEND}/${id}?client_business_id=${businessId}&send=1`);
      setQuoteSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleQuoteSendModal();
      }
      return data?.data;
    } catch (error) {
      setQuoteSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setQuoteSendLoading(false);
      } else {
        toast.error(error?.message);
        setQuoteSendLoading(false);
      }
    }
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleQuoteDelete = async () => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.QUOTES_DELETE}/${id}`);
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <>
      <span>
        <CustomTooltip
          text={'View'}
          children={
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#clientDetail"
            />
          }
        />
        {parseInt(status) !== 1 && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Edit'}
              children={
                <Link
                  to={{
                    pathname: `/business/${businessId}/edit-quote`,
                    search: `?id=${id}&number_option=${numberOption}`
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </Link>
              }
            />
          </>
        )}
        {file && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={"Download Quote"}
              children={
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  onClick={() => {
                    downloadLetter(file?.file);
                  }}
                />
              }
            />
          </>
        )}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip
          text={"Delete"}
          children={
            <FontAwesomeIcon
              onClick={toggleDeleteModal}
              icon={faTrash}
              size="1x"
              data-toggle="modal"
              data-target="#deleteClient"
            />
          }
        /> */}
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        {parseInt(send) === 0 ? (
          <CustomTooltip
            text={'Send Quote'}
            children={
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="1x"
                onClick={() => toggleQuoteSendModal()}
              />
            }
          />
        ) : (
          <CustomTooltip
            text={'Resend Quote'}
            children={
              <FontAwesomeIcon
                icon={faShareSquare}
                size="1x"
                onClick={() => toggleQuoteReSendModal()}
              />
            }
          />
        )}
      </span>

      {showDelete && (
        <Modal
          size="lg"
          show={showDelete}
          onHide={toggleDeleteModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Delete Quote
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">Are you sure you want to delete this quote?</div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleQuoteDelete();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showView && (
        <ViewQuote
          showView={showView}
          id={id}
          toggleShowModal={toggleShowModal}
          businessData={businessData}
        />
      )}

      {showQuoteReSend && (
        <Modal
          size="lg"
          show={showQuoteReSend}
          onHide={toggleQuoteReSendModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Resend Quote
          </Modal.Header>
          <Modal.Body>
            {quoteSendLoading && <Spin />}
            <div className="modal-body">Do you want to resend quote this to {email}?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleResendQuote();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleQuoteReSendModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showQuoteSend && (
        <Modal
          size="lg"
          show={showQuoteSend}
          onHide={toggleQuoteSendModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Send Quote
          </Modal.Header>
          <Modal.Body>
            {quoteSendLoading && <Spin />}
            <div className="modal-body">Do you want to send quote this to {email}?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleSendQuote();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleQuoteSendModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default QuoteActionButton;
