import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API, get, imageDefaultPrefixPath, metaTitle } from '../../../../config';
import BootStrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spin from '../../../common/Spin';
import Pagination from '../../../common/Pagination';
// import BackButton from '../../../../components/Form/BackButton';
import { checkIfFileExists, formatName, formatNumber } from '../../../common/Misc';
import { getBusinessData } from '../../components/BusinessHelper';
import ReceiptActionButton from './ReceiptActionButton';
import { Button } from 'react-bootstrap';
import TitleBar from '../../../common/TitleBar';
// import InvoiceActionButton from "./components/InvoiceActionButton";

const Receipts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [receiptsList, setReceiptsList] = useState([]);
  const [receiptsLoading, setReceiptsLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [businessData, setBusinessData] = useState();
  const [loading, setLoading] = useState(false);
  const [lastReceiptNumber, setLastReceiptNumber] = useState();
  const [numberOption, setNumberOption] = useState();

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const fetchBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBusinessData(businessId, setBusinessName, setBusinessLogo);
      setBusinessData(data);
      setLoading(false);
    } catch (e) {
      setBusinessData(null);
    }
  }, [businessId]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    window.document.title = `${metaTitle} | Receipts`;
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const handleDelete = () => {
    fetchReceipts();
  };

  const fetchReceipts = useCallback(async () => {
    try {
      setReceiptsLoading(true);
      const { data } = await get(
        `${API.GET_RECEIPTS}?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${
          sortField ? sortField : ''
        }&sort_order=${sortOrder ? sortOrder : ''}&client_business_id=${
          businessId ? businessId : ''
        }`
      );

      setReceiptsLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const receiptsLists = data.data.data;
      setReceiptsList(receiptsLists);
      let lastNumber = data?.last_receipt_number
        ? String(data?.last_receipt_number)?.split('R')[1]
        : '';
      setLastReceiptNumber(
        lastNumber ? `R${String(padWithLeadingZeros(Number(lastNumber) + 1, 6))}` : 'R000001'
      );
      setNumberOption(data?.number_option);
      return data.data;
    } catch (e) {
      const { data } = e;
      setReceiptsLoading(false);
      setReceiptsList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, sortOrder, sortField, businessId]);

  const receiptsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: receiptsList[i]?.id,
        receipt_number: receiptsList[i]?.receipt_number ? receiptsList[i]?.receipt_number : '',
        invoice_number: receiptsList[i]?.invoice?.invoice_number
          ? receiptsList[i]?.invoice?.invoice_number
          : '',
        invoice_date: receiptsList[i]?.invoice?.date ? receiptsList[i]?.invoice?.date : '',
        invoice_due_date: receiptsList[i]?.invoice?.due_date
          ? receiptsList[i]?.invoice?.due_date
          : '',
        payment_date: receiptsList[i]?.payment_date ? receiptsList[i]?.payment_date : '',
        payment_type: receiptsList[i]?.payment_type
          ? receiptsList[i]?.payment_type === 'gift_card'
            ? 'Gift Card'
            : receiptsList[i]?.payment_type === 'ffr'
            ? 'Fee From Refund'
            : receiptsList[i]?.payment_type.charAt(0).toUpperCase() +
              receiptsList[i]?.payment_type.slice(1)
          : '',
        total_payable_amount: receiptsList[i]?.invoice?.total_payable_amount
          ? `$${formatNumber(receiptsList[i]?.invoice?.total_payable_amount)}`
          : '$0.00',
        amount_due: receiptsList[i]?.amount_due
          ? `$${formatNumber(receiptsList[i]?.amount_due)}`
          : '$0.00',
        payment_amount: receiptsList[i]?.payment_amount
          ? `$${formatNumber(receiptsList[i]?.payment_amount)}`
          : '$0.00',
        user: receiptsList[i]?.user
          ? formatName(receiptsList[i]?.user?.first_name, receiptsList[i]?.user?.last_name)
          : '',
        action: (
          <ReceiptActionButton
            id={receiptsList[i]?.id}
            handleDelete={handleDelete}
            status={receiptsList[i]?.is_draft}
            send={receiptsList[i]?.email_sent}
            email={receiptsList[i]?.invoice?.email_to}
            businessData={businessData}
            numberOption={numberOption}
            file={receiptsList[i]?.receipt_file?.[0]}
          />
        )
      });
    }
    return items;
  };

  const receipts = receiptsGenerator(receiptsList?.length);

  const columns = [
    {
      dataField: 'receipt_number',
      text: 'Receipt Number'
    },
    {
      dataField: 'invoice_number',
      text: 'Invoice Number'
    },
    {
      dataField: 'invoice_date',
      text: 'Invoice Date'
    },
    {
      dataField: 'invoice_due_date',
      text: 'Invoice Due Date'
    },
    {
      dataField: 'payment_date',
      text: 'Payment Date'
    },
    {
      dataField: 'payment_type',
      text: 'Payment Type'
    },
    {
      dataField: 'total_payable_amount',
      text: 'Invoice Total'
    },
    {
      dataField: 'payment_amount',
      text: 'Payment Amount'
    },
    {
      dataField: 'amount_due',
      text: 'Amount Outstanding'
    },
    {
      dataField: 'user',
      text: 'Received By'
    },
    {
      dataField: 'action',
      text: 'Action',
      style: { width: '127px' }
    }
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${
        sortField ? sortField : ''
      }&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          {/* <h1 className="page-title">
            <BackButton />
            Receipts
            <div className="page-title-right">
              {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
              {BusinessName}
            </div>
          </h1> */}
          <TitleBar
            label={"Receipts"}
            BusinessName={BusinessName}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate('/business/' + businessId + '/add-receipt', {
                      state: { lastReceiptNumber: lastReceiptNumber, numberOption: numberOption }
                    })
                  }
                >
                  Add New
                </Button>
                {/* <Button variant="primary">Import</Button> */}
              </div>
            </div>
            <div className="custom-table">
              {(receiptsLoading || loading) && <Spin />}
              <BootStrapTable
                keyField="key"
                remote
                data={receipts}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
                // expandRow={expandRow}
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipts;
