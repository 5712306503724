import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { ChangePasswordSchema } from "../../common/Validation";
import { API, IV, post, SECRETKEY } from "../../../config";
import Spin from "../../common/Spin";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [eye1, seteye1] = useState(true);
  const [password1, setpassword1] = useState("password");
  const [eye2, seteye2] = useState(true);
  const [password2, setpassword2] = useState("password");
  const [loading, setLoading] = useState(false);

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  const Eye1 = () => {
    if (password1 === "password") {
      setpassword1("text");
      seteye1(false);
    } else {
      setpassword1("password");
      seteye1(true);
    }
  };

  const Eye2 = () => {
    if (password2 === "password") {
      setpassword2("text");
      seteye2(false);
    } else {
      setpassword2("password");
      seteye2(true);
    }
  };

  var CryptoJS = require("crypto-js");

  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);

      const encOldPassword = CryptoJS.AES.encrypt(values?.old_password, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const finalEncryptedOldPassword = encOldPassword.ciphertext.toString(
        CryptoJS.enc.Base64
      );

      const encNewPassword = CryptoJS.AES.encrypt(values?.new_password, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const finalEncryptedNewPassword = encNewPassword.ciphertext.toString(
        CryptoJS.enc.Base64
      );

      const encConfirmPassword = CryptoJS.AES.encrypt(
        values?.confirm_password,
        fkey,
        {
          iv: fiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      const finalEncryptedConfirmPassword =
        encConfirmPassword.ciphertext.toString(CryptoJS.enc.Base64);

      const finalValue = {
        ...values,
        old_password: finalEncryptedOldPassword,
        new_password: finalEncryptedNewPassword,
        confirm_password: finalEncryptedConfirmPassword,
      };

      const { data } = await post(API.CHANGE_PASSWORD, finalValue);
      if (data.status === 200) {
        setLoading(false);
        toast.success(data.message);
      } else {
        toast.error(data.errors?.myna_error[0]);
      }
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.message;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          old_password: "",
          new_password: "",
          confirm_password: "",
        }}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, values, handleSubmit, handleBlur, handleChange }) => (
          <form onSubmit={handleSubmit} noValidate className="entry-section">
            {loading && <Spin />}
            <div className="row">
              <div className="form-group col-md-3">
                <label>
                  <span className="required">*</span>Current Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={password}
                    name="old_password"
                    value={values?.old_password ? values?.old_password : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                  <i
                    onClick={Eye}
                    style={{
                      position: "absolute",
                      right: "20px",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      marginTop: "-2px",
                    }}
                    className={`icon ${
                      eye ? "icon-eye-close" : "icon-eye-open"
                    }`}
                  ></i>
                </div>
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="old_password" />
                </span>
              </div>
              <div className="form-group col-md-3">
                <label>
                  <span className="required">*</span>New Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={password1}
                    name="new_password"
                    value={values?.new_password ? values?.new_password : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <i
                    onClick={Eye1}
                    style={{
                      position: "absolute",
                      right: "20px",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      marginTop: "-2px",
                    }}
                    className={`icon ${
                      eye1 ? "icon-eye-close" : "icon-eye-open"
                    }`}
                  ></i>
                </div>
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="new_password" />
                </span>
              </div>
              <div className="form-group col-md-3">
                <label>
                  <span className="required">*</span>Confirm New Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={password2}
                    name="confirm_password"
                    value={
                      values?.confirm_password ? values?.confirm_password : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <i
                    onClick={Eye2}
                    style={{
                      position: "absolute",
                      right: "20px",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      marginTop: "-2px",
                    }}
                    className={`icon ${
                      eye2 ? "icon-eye-close" : "icon-eye-open"
                    }`}
                  ></i>
                </div>
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="confirm_password" />
                </span>
              </div>
              <div className="action-box">
                <button type="submit">Save</button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
