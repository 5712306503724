import React, { Fragment, useCallback, useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  faCheck,
  faCheckToSlot,
  faDownload,
  faEllipsisV,
  faGrip,
  faListSquares,
  faMemory,
  // faMoneyBillTransfer,
  faPaperPlane,
  faTrash,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  Col,
  Modal,
  Container,
  Row,
  Dropdown,
} from "react-bootstrap";
import Spin from "../../../pages/common/Spin";
import { API, fileUpload, get, imageDefaultPrefixPath, yodleeConfigName, yodleeFastLink } from "../../../config";
import splitIcon from "../../../assets/images/split.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import AddNewContactModal from "./components/AddNewContactModal";
import InfinityScrollComponent from "../../common/InfinityScrollComponent";
import ReconcileTransactionDelete from "./components/ReconcileTransactionDelete";

import UploadFile from "../../common/UploadFile";
import {
  formatName,
  formatNumberWithoutComma,
  removeDuplicates,
} from "../../common/Misc";
import {
  getNextPageData,
  getUnderReviewedNextPageData,
  refreshBankTransactions,
} from "./components/TransactionsHelper";
import AddNewAccountModal from "./components/AddNewAccountModal";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../common/Pagination";
import ExpandedRowForReconcileTransaction from "./components/ExpandedRowForReconcileTransaction";
import AddNewJobModal from "./components/AddNewJobModal";
import AddNewTaskModal from "./components/AddNewTaskModal";
import { DateRangePicker } from "rsuite";
import { getProviderBankDetail, getYodleeAccessToken } from "../settings/integrations/components/IntegrationsHelper";
import { YodleeConnectModal } from "../settings/integrations/components/YodleeConnetctModal";
import CustomTooltip from "../../common/CustomTooltip";

const GSTOptions = [
  { label: "GST 10%", value: 1 },
  { label: "GST Excluded", value: 0 },
  { label: "BAS Excluded", value: 2 },
  { label: "GST on Imports", value: 3 },
  // { label: "GST Free Expenses", value: 2 },
  // { label: "BAS Excluded", value: 3 },
];

  const today = new Date();
  const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

const ReconcileTransactions = ({ setBalanceInfo, isUnderReviewed, setValueRange, valueRange, setSortField, setSortOrder, sortOrder, sortField, bankAccountId, setBankAccountId, balanceInfo }) => {
  const navigate = useNavigate();
  const params = useParams();
  const businessId = params?.business_id;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [latestStatement, setLatestStatement] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingContactList, setLoadingContactList] = useState(false);
  const [loadingJobList, setLoadingJobList] = useState(false);
  const [loadingTaskList, setLoadingTaskList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [accountOptions, setAccountOptions] = useState([]);
  const [allAccountValue, setAllAccountValue] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastElement, setLastElement] = useState(null);
  const [allContactValue, setAllContactValue] = useState([]);
  const [allContactList, setAllContactList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);

  const [bankTransactionId, setBankTransactionId] = useState();
  const [readyToBeReconciled, setReadyToBeReconciled] = useState("");
  const [addNewContact, setAddNewContact] = useState(false);
  const [bankTransactionData, setBankTransactionData] = useState();
  const [contactType, setContactType] = useState("");
  const [addContactItem, setAddContactItem] = useState();
  const [addContactItemID, setAddContactItemID] = useState();
  const [lastName, setLastName] = useState("");

  const [itemId, setItemId] = useState();
  const [splitAccountId, setSplitAccountId] = useState();
  const [splitJobId, setSplitJobId] = useState();
  const [splitTaskId, setSplitTaskId] = useState();
  const [splitGst, setSplitGst] = useState();
  const [splitDescription, setSplitDescription] = useState();
  const [split2Amount, setSplit2Amount] = useState();
  const [split1Amount, setSplit1Amount] = useState();
  const [splitDiv, setSplitDiv] = useState(false);

  const [amount1, setAmount1] = useState();
  const [amount2, setAmount2] = useState();
  const [showJobTask, setShowJobTask] = useState(false);
  const [showTaskJobId, setShowTaskJobId] = useState(false);
  const [bankAccountOptions, setBankAccountOptions] = useState();
  // const [bankAccountId ,setBankAccountId] = useState('');

  const [addNewAccount, setAddNewAccount] = useState(false);
  const [addNewJob, setAddNewJob] = useState(false);
  const [addNewTask, setAddNewTask] = useState(false);
  const [addAccountItem, setAddAccountItem] = useState();
  const [addJobItem, setAddJobItem] = useState();
  const [addTaskItem, setAddTaskItem] = useState();
  const [addAccountItemID, setAddAccountItemID] = useState();
  const [addJobItemID, setAddJobItemID] = useState();
  const [addTaskItemID, setAddTaskItemID] = useState();
  const [accountName, setAccountName] = useState();
  const [jobName, setJobName] = useState();
  const [taskName, setTaskName] = useState();
  const [splitAccountVal, setSplitAccountVal] = useState();
  const [splitJobVal, setSplitJobVal] = useState();
  const [splitTaskVal, setSplitTaskVal] = useState();
  const [splitGstVal, setSplitGstVal] = useState();
  const [reconcileData, setReconcileData] = useState([]);
  const [exportloading, setExportLoading] = useState(false);

  //List
  const [view, setView] = useState("grid");
  const [loadingForList, setLoadingForList] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [dataForList, setDataForList] = useState([]);
  const [showMemoriseModal, setShowMemoriseModal] = useState(false);
  const [memoriseDataList, setMemoriseDataList] = useState();

  const [accountFetchOptions, setAccountFetchOptions] = useState();
  const [accountFetchId, setAccountFetchId] = useState();
  const [accountMFA, setAccountMFA] = useState();

  const [accountYodleeId, setAccountYodleeId] = useState();
  const [openYodlee, setOpenYodlee] = useState(false);
  const [openYodleeLoading, setOpenYodleeLoading] = useState(false);
  const [fastLinkData, setFastLinkData] = useState({});
  const [providerBankData, setProviderBankData] = useState([]);
  const [isYodleeConnect, setIsYodleeConnect] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);

  const config = {
    params: { configName: "" },
  }

  const toggleMemoriseModal = () => {
    setShowMemoriseModal(!showMemoriseModal);
  }
  
  const { afterToday } = DateRangePicker;

  const [dateValue, setDateValue] = useState(defaultDateRange);
  const [startDate, setStartDate] = useState(
    defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );

  const sortOptions = [
    {
      label: 'Oldest First',
      value: 'ASC',
      field: 'transactionDate',
    },
    {
      label: 'Latest First',
      value: 'DESC',
      field: 'transactionDate',
    }
  ]

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const toggleShowJobTask = (id) => {
    setShowTaskJobId(id);
    setShowJobTask(!showJobTask);
  }

  const clearJobSelection = async (id, ItemID) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("job_id", null);
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }

  const clearTaskSelection = async (id, ItemID) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("task_id", null);
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }

  const clearSplitJobSelection = () => {
    setSplitJobVal()
  }

  const clearSplitTaskSelection = () => {
    setSplitTaskVal()
  }

  const handleDateRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleRefreshModal = () => {
    setShowRefresh(!showRefresh);
    if(!showRefresh){
      if(bankAccountId && accountFetchOptions){
        if(accountFetchOptions?.find((i) => i.value === bankAccountId)){
          setAccountFetchId(bankAccountId)
        }
      }
    }
  };

  const fetchReconcileTransaction = useCallback(async () => {
    try {
      setLoading(true);
      let URL = `${
        API.BANK_RECONCILIATION_LIST
      }?client_business_id=${businessId}&limit=${currentPage * 10}&page=${1}${bankAccountId ? '&yodlee_account_id=' + bankAccountId : ''}
      &from=${
        valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
      }&to=${
        valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }&search=${search ? search : ''}`;
      if (isUnderReviewed) {
        URL = `${
          API.BANK_RECONCILIATION_LIST
        }?client_business_id=${businessId}&limit=${
          currentPage * 10
        }&page=${1}&under_review=1${bankAccountId ? '&yodlee_account_id=' + bankAccountId : ''}
        &from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`;
      }
      get(URL).then((response) => {
        setLoading(false);
        let accountActiveList = [];
        if(response?.data?.accounts){
          accountActiveList = response?.data?.accounts?.filter((i) => i?.accountStatus === 1 )
        }
        setBalanceInfo(accountActiveList);
        const accountList = 
        response?.data?.accounts?.length > 0 &&
        response?.data?.accounts?.map((item) => {
          const list = {
            label: item?.provider?.providerName + (item?.accountNumber ? "-" + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)') : ""),
            value: item?.id,
          };
          return list;
        });
        const accountActiveStatusList = 
        accountActiveList.length > 0 &&
        accountActiveList.map((item) => {
          const list = {
            label: item?.provider?.providerName + (item?.accountNumber ? "-" + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)') : ""),
            value: item?.id,
            mfa: item?.provider?.isRealTimeMFA,
            accountId: item?.provider?.providerAccountId
          };
          return list;
        });
        setAccountFetchOptions(accountActiveStatusList);
        setBankAccountOptions(accountList ? [ { label: "ALL", value: ''}, ...accountList] : [{ label: "ALL", value: ''}]);
        if (response.data.data.per_page <= 10) {
          setCurrentPage(response.data.data.current_page);
        }
        setData(response.data.data);
        setReconcileData(response.data.data.data);
        setReadyToBeReconciled(response.data.data.data.length);
        const isMoreDataExist =
          response.data.data.data.length < response.data.data.total &&
          response.data.data?.next_page_url !== "" &&
          response.data.data?.next_page_url !== null;
        setHasMore(isMoreDataExist);
        setLatestStatement(response?.data?.last_import_date);
        var matchedRecords = [];
        isUnderReviewed === true
          ? response.data.data.data.forEach((element) => {
              element.bank_reconciliation.forEach((item) => {
                matchedRecords.push(element.id);
              });
            })
          : response.data.data.data.forEach((element) => {
              element.bank_reconciliation.forEach((item) => {
                if (
                  item?.chart_of_account_id &&
                  item?.contact_id &&
                  (item?.gst === 1 || item?.gst === 0 || item?.gst === 2 || item?.gst === 3) &&
                  item?.description
                ) {
                  matchedRecords.push(element.id);
                }
              });
            });

        let uniqueChars = [];
        matchedRecords.forEach((c) => {
          if (!uniqueChars.includes(c)) {
            uniqueChars.push(c);
          }
        });

        // setSplitGst("");
        // setSplitAccountId("");
        // setSplitAccountVal("");
        // setSplitJobId("");
        // setSplitJobVal("");
        // setSplitTaskId("");
        // setSplitTaskVal("");
        // setSplitGstVal("");
        setSelectedItems(uniqueChars);
        setLoading(false);
      });
    } catch (e) {
      setData([]);
      setSplitGstVal("");
      setSplitGst("");
      setSplitAccountId("");
      setSplitAccountVal("");
      setSplitJobId("");
      setSplitJobVal("");
      setSplitTaskId("");
      setSplitTaskVal("");
      setBalanceInfo([]);
      setLoading(false);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, [businessId, isUnderReviewed, currentPage, setBalanceInfo, bankAccountId, valueRange, sortField, sortOrder, search]);

  //List
  const fetchReconcileTransactionForList = useCallback(async () => {
    try {
      setLoadingForList(true);
      let URL = `${
        API.BANK_RECONCILIATION_LIST
      }?client_business_id=${businessId}&limit=${limit ? limit : 10}&page=${
        page ? page : 1
      }${bankAccountId ? '&yodlee_account_id=' + bankAccountId : ''}
      &from=${
        valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
      }&to=${
        valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`;
      if (isUnderReviewed) {
        URL = `${
          API.BANK_RECONCILIATION_LIST
        }?client_business_id=${businessId}&limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&under_review=1${bankAccountId ? '&yodlee_account_id=' + bankAccountId : ''}
        &from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`;
      }
      get(URL).then((response) => {
        setLoadingForList(false);
        let accountActiveList = [];
        if(response?.data?.accounts){
          accountActiveList = response?.data?.accounts?.filter((i) => i?.accountStatus === 1 )
        }
        setBalanceInfo(accountActiveList);
        const accountList = 
        response?.data?.accounts?.length > 0 &&
        response?.data?.accounts?.map((item) => {
          const list = {
            label: item?.provider?.providerName + (item?.accountNumber ? "-" + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)') : ""),
            value: item?.id
          };
          return list;
        });
        setBankAccountOptions(accountList ? [ { label: "ALL", value: ''}, ...accountList] : [{ label: "ALL", value: ''}]);
        setLimit(response.data.data?.per_page);
        setPage(response.data.data?.current_page);
        setTotal(response.data.data?.total);
        setFrom(response.data.data?.from);
        setTo(response.data.data?.to);

        setDataForList(response.data.data);
        // const arrayForReadyToBeReconciled =
        //   response.data.data.data &&
        //   response.data.data.data.length > 0 &&
        //   response.data.data.data.map((item) => {
        //     const value = item?.bank_reconciliation?.map((data, i) => {
        //       const main =
        //         data?.chart_of_account_id &&
        //         data?.gst &&
        //         data?.contact_id &&
        //         data?.description &&
        //         data?.amount
        //           ? true
        //           : false;
        //       return main;
        //     });
        //     const finalValue =
        //       value?.find((val) => val === false) === false ? true : false;

        //     return finalValue ? 0 : 1;
        //   });
        // let count = 0;
        // Object.values(arrayForReadyToBeReconciled).forEach((element) => {
        //   if (element === 1) {
        //     count += 1;
        //   }
        // });
        setReadyToBeReconciled(response.data.data.data.length);
        setLatestStatement(response?.data?.last_import_date);

        var matchedRecords = [];
        isUnderReviewed === true
          ? response.data.data.data.forEach((element) => {
              element.bank_reconciliation.forEach((item) => {
                matchedRecords.push(element.id);
              });
            })
          : response.data.data.data.forEach((element) => {
              element.bank_reconciliation.forEach((item) => {
                if (
                  item?.chart_of_account_id &&
                  item?.contact_id &&
                  (item?.gst === 1 || item?.gst === 0 || item?.gst === 2 || item?.gst === 3) &&
                  item?.description
                ) {
                  matchedRecords.push(element.id);
                }
              });
            });

        let uniqueChars = [];
        matchedRecords.forEach((c) => {
          if (!uniqueChars.includes(c)) {
            uniqueChars.push(c);
          }
        });
        setSelectedItems(uniqueChars);
      });
    } catch (e) {
      setDataForList([]);
      setBalanceInfo([]);
      setLoadingForList(false);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, [businessId, isUnderReviewed, setBalanceInfo, page, limit, bankAccountId, valueRange, sortField, sortOrder]);

  const handleDelete = () => {
    view === "grid" && fetchReconcileTransaction();
    view === "list" && fetchReconcileTransactionForList();
    setShowDelete(false);
  };

  useEffect(() => {
    navigate(
      `/business/${businessId}/reconcile-transactions?tab=${
        isUnderReviewed ? "under-review" : "reconcile-transactions"
      }&search=${search ? search : ''}`
    );
  }, [navigate, isUnderReviewed, businessId, search]);

  const handleAccountChange = useCallback(async () => {
    try {
      setLoading(true);
      get(
        `${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`
      ).then((response) => {
        setLoading(false);
        var revenue =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  element?.account_type_id === "fada83c9-4769-49b9-ab0e-fcd8c43de30e"
                );
              })
            : [];

        const revenueList =
          revenue && revenue?.length > 0
            ? revenue?.map((item, index) => {
                const list = {
                  label:
                  // eslint-disable-next-line
                  item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                  value: item?.code !== "2000" && (item?.id),
                  tax: item?.code !== "2000" && (item?.tax),
                  type: item?.code !== "2000" && (item?.type),
                };
                return list ? list : [];
              })
            : [];

          var expenses =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "fad90f00-6651-4c51-a29a-78398fa226fd"
                  );
                })
              : [];
  
          const expensesList =
            expenses && expenses?.length > 0
              ? expenses?.map((item) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];

          var currentLiabilities =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "3e5a94ef-b819-47c3-9955-d47da29e2ab0"
                  );
                })
              : [];
  
          const currentLiabilitiesList =
            currentLiabilities && currentLiabilities?.length > 0
              ? currentLiabilities?.map((item) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];

          var nonCurrentLiabilities =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "fcb7e129-7666-42ae-8c3d-11d016bbe340"
                  );
                })
              : [];
      
          const nonCurrentLiabilitiesList =
            nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
              ? nonCurrentLiabilities?.map((item) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];
          
          var currentAssets =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "994d6d38-497b-465d-9f57-3bdc2d70594a"
                  );
                })
              : [];
        
          const currentAssetsList =
            currentAssets && currentAssets?.length > 0
              ? currentAssets?.map((item) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];

          var nonCurrentAssets =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "4051efd3-9fb8-4adb-b0c7-7e565a078ac1"
                  );
                })
              : [];
          
          const nonCurrentAssetsList =
            nonCurrentAssets && nonCurrentAssets?.length > 0
              ? nonCurrentAssets?.map((item) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];

          var equity =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "520f936e-3017-4574-a9ab-6c9e68b591e4"
                  );
                })
              : [];
            
          const equityList =
            equity && equity?.length > 0
              ? equity?.map((item) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];

            const allAccountListing = [
            revenueList && { label: <div className="list-subHeading">Revenue</div>, options: revenueList },
            expensesList && { label: <div className="list-subHeading">Expenses</div>, options: expensesList },
            currentLiabilitiesList && { label: <div className="list-subHeading">Current Liabilities</div>, options: currentLiabilitiesList },
            nonCurrentLiabilitiesList && { label: <div className="list-subHeading">Non Current Liabilities</div>, options: nonCurrentLiabilitiesList },
            currentAssetsList && { label: <div className="list-subHeading">Current Assets</div>, options: currentAssetsList},
            nonCurrentAssetsList && { label: <div className="list-subHeading">Non Current Assets</div>, options: nonCurrentAssetsList},
            equityList && { label: <div className="list-subHeading">Equity</div>, options: equityList},
            // { label: <strong>+ Add New Account</strong>, value: "new" }
          ];

          setAccountOptions(allAccountListing)
          setAllAccountValue([
            ...revenueList,
            ...expensesList,
            ...currentLiabilitiesList,
            ...nonCurrentLiabilitiesList,
            ...currentAssetsList,
            ...nonCurrentAssetsList,
            ...equityList
          ])
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  const handleJobChange = useCallback(async () => {
    try {
      setLoading(true);
      get(
        `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        setLoading(false);
        const jobList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.code +
                (item?.description ? " (" + item?.description + ")" : ""),
              value: item?.id,
              tax: item?.tax,
            };
            return list;
          });
        setJobOptions(jobList ? [ ...jobList, { label: <strong>+ Add New Job</strong>, value: "new" }] : [{ label: <strong>+ Add New Job</strong>, value: "new" }]);
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]); 

  const handleTaskChange = useCallback(async () => {
    try {
      setLoading(true);
      get(
        `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        setLoading(false);
        const taskList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.code +
                (item?.description ? " (" + item?.description + ")" : ""),
              value: item?.id,
              tax: item?.tax,
            };
            return list;
          });
        setTaskOptions(taskList ? [ ...taskList, { label: <strong>+ Add New Task</strong>, value: "new" }] : [{ label: <strong>+ Add New Task</strong>, value: "new" }]);
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleAllCheckBox = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    if (itemName === "checkall") {
      setAllChecked(checked);
      if (checked) {
        const allData = [];

        isUnderReviewed === true
          ? data.data.forEach((item) => {
              allData.push(item.id);
            })
          : data.data.forEach((item) => {
              if (!handleReconcileDisable(item)) {
                allData.push(item.id);
              }
            });
        setSelectedItems(allData);
      } else {
        setSelectedItems([]);
      }
    }
  };

  const handleJobSelection = async (id, ItemID, item, transactionData) => {
    if (id?.value === id?.label || id?.value === "new") {
      setJobName(typeof id?.label === "string" ? id?.label : "");
      setAddJobItemID(ItemID);
      setAddJobItem(item);
      setAddNewJob(true);
      setBankTransactionData(transactionData);
    } else {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("job_id", id?.value);
        formData.append("_method", "put");
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
          formData,
          {}
        );
        if (response.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleTaskSelection = async (id, ItemID, item, transactionData) => {
    if (id?.value === id?.label || id?.value === "new") {
      setTaskName(typeof id?.label === "string" ? id?.label : "");
      setAddTaskItemID(ItemID);
      setAddTaskItem(item);
      setAddNewTask(true);
      setBankTransactionData(transactionData);
    } else {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("task_id", id?.value);
        formData.append("_method", "put");
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
          formData,
          {}
        );
        if (response.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleAccountSelection = async (id, ItemID, item, transactionData) => {
    if (id?.value === id?.label || id?.value === "new") {
      setAccountName(typeof id?.label === "string" ? id?.label : "");
      setAddAccountItemID(ItemID);
      setAddAccountItem(item);
      setAddNewAccount(true);
      setBankTransactionData(transactionData);
    } else {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("chart_of_account_id", id?.value);
        formData.append("gst", id?.tax);
        formData.append(
          "description",
          item?.description ? item?.description : transactionData?.description
        );
        formData.append("_method", "put");
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
          formData,
          {}
        );
        if (response.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleContactNameSelection = async (
    id,
    ItemID,
    item,
    baseType,
    bankTransaction
  ) => {
    if (id?.value === id?.label || id?.value === "new") {
      setLastName(typeof id?.label === "string" ? id?.label : "");
      setAddContactItemID(ItemID);
      setAddContactItem(item);
      setContactType(baseType === 1 ? "customer" : "supplier");
      setAddNewContact(true);
      setBankTransactionData(bankTransaction);
    } else {
      if (bankTransaction?.bank_reconciliation?.length === 1) {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("contact_id", id?.value);
          formData.append(
            "description",
            bankTransaction?.bank_reconciliation[0]?.description
              ? bankTransaction?.bank_reconciliation[0]?.description
              : bankTransaction?.description
          );
          formData.append("_method", "put");
          const response = await fileUpload(
            `${API.UPDATE_BANK_RECONCILIATION}/${bankTransaction?.bank_reconciliation[0]?.id}`,
            formData,
            {}
          );
          if (response.status === 200) {
            view === "grid" && fetchReconcileTransaction();
            view === "list" && fetchReconcileTransactionForList();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error?.response?.data?.errors) {
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
          } else {
            toast.error(error?.message);
          }
        }
      }

      if (bankTransaction?.bank_reconciliation?.length === 2) {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("contact_id", id?.value);
          formData.append(
            "description",
            bankTransaction?.bank_reconciliation[0]?.description
              ? bankTransaction?.bank_reconciliation[0]?.description
              : bankTransaction?.description
          );
          formData.append("_method", "put");

          const formData2 = new FormData();
          formData2.append("contact_id", id?.value);
          formData2.append(
            "description",
            bankTransaction?.bank_reconciliation[1]?.description
              ? bankTransaction?.bank_reconciliation[1]?.description
              : bankTransaction?.description
          );
          formData2.append("_method", "put");

          const response = await fileUpload(
            `${API.UPDATE_BANK_RECONCILIATION}/${bankTransaction?.bank_reconciliation[0]?.id}`,
            formData,
            {}
          );

          const response2 = await fileUpload(
            `${API.UPDATE_BANK_RECONCILIATION}/${bankTransaction?.bank_reconciliation[1]?.id}`,
            formData2,
            {}
          );
          if (response.status === 200 && response2.status === 200) {
            view === "grid" && fetchReconcileTransaction();
            view === "list" && fetchReconcileTransactionForList();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error?.response?.data?.errors) {
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
          } else {
            toast.error(error?.message);
          }
        }
      }
    }
  };

  const handleAddContactNameSelection = async (
    id,
    ItemID,
    item,
    bankTransactionData
  ) => {
    if (bankTransactionData?.bank_reconciliation?.length === 1) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("contact_id", id);
        formData.append(
          "description",
          bankTransactionData?.bank_reconciliation[0]?.description
            ? bankTransactionData?.bank_reconciliation[0]?.description
            : bankTransactionData?.description
        );
        formData.append("_method", "put");
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankTransactionData?.bank_reconciliation[0]?.id}`,
          formData,
          {}
        );
        if (response.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }

    if (bankTransactionData?.bank_reconciliation?.length === 2) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("contact_id", id);
        formData.append(
          "description",
          bankTransactionData?.bank_reconciliation[0]?.description
            ? bankTransactionData?.bank_reconciliation[0]?.description
            : bankTransactionData?.description
        );
        formData.append("_method", "put");

        const formData2 = new FormData();
        formData2.append("contact_id", id);
        formData2.append(
          "description",
          bankTransactionData?.bank_reconciliation[1]?.description
            ? bankTransactionData?.bank_reconciliation[1]?.description
            : bankTransactionData?.description
        );
        formData2.append("_method", "put");

        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankTransactionData?.bank_reconciliation[0]?.id}`,
          formData,
          {}
        );

        const response2 = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankTransactionData?.bank_reconciliation[1]?.id}`,
          formData2,
          {}
        );
        if (response.status === 200 && response2.status === 200) {
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleBlurDescription = async (
    ItemID,
    item,
    transactionData,
    chartOfAccountId
  ) => {
    const GST = item?.gst
      ? item?.gst
      : chartOfAccountId
      ? allAccountValue?.find((account) => account?.value === chartOfAccountId)
          ?.tax
      : "";
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("gst", GST);
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  // const handleMemoriseBlurDescription = async (
  //   ItemID,
  //   item,
  //   transactionData,
  //   chartOfAccountId
  // ) => {
  //   try {
  //     setLoading(true);
  //     const formData = new FormData();
  //     formData.append(
  //       "term",
  //       item?.term ? item?.term : transactionData?.term
  //     );
  //     formData.append("_method", "put");
  //     const response = await fileUpload(
  //       `${API.EDIT_TERM}/${ItemID}`,
  //       formData,
  //       {}
  //     );
  //     if (response.status === 200) {
  //       view === "grid" && fetchReconcileTransaction();
  //       view === "list" && fetchReconcileTransactionForList();
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     if (error?.response?.data?.errors) {
  //       Object.keys(error?.response?.data?.errors).forEach((key) => {
  //         toast.error(error?.response?.data?.errors[key][0]);
  //       });
  //     } else {
  //       toast.error(error?.message);
  //     }
  //   }
  // };

  const handleGSTSelection = async (item, ItemID) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("gst", item);
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleReconcile = async (ItemID) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("client_business_id", businessId);
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/reconcile/${ItemID}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleReconcileAll = () => {
    if (selectedItems.length === 0) {
      toast.warning("Please select atleast one bank statement concilation");
    } else {
      if (selectedItems.length > 0) {
        const formData = new FormData();
        formData.append("client_business_id ", businessId);
        selectedItems.map((itemData) => {
          const selectedData = data?.data?.find(
            (item) => item?.id === itemData
          );

          const value = selectedData?.bank_reconciliation?.map((data, i) => {
            const main =
              data?.chart_of_account_id &&
              (data?.gst === 0 || data?.gst === 1 || data?.gst === 2 || data?.gst === 3) &&
              data?.contact_id &&
              data?.description &&
              data?.amount &&
              selectedData?.id;
            return main;
          });
          removeDuplicates(value)[0] &&
            formData.append(
              "bank_transaction_ids[]",
              removeDuplicates(value)[0]
            );

          return formData;
        });

        if ([...formData.entries()].length) {
          fileUpload(API.BANK_RECONCILIATION_BULK, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success(response.data.message);
                view === "grid" && fetchReconcileTransaction();
                view === "list" && fetchReconcileTransactionForList();
              }
            })
            .catch((error) => {
              if (error?.response?.data?.errors) {
                Object.keys(error?.response?.data?.errors).forEach((key) => {
                  toast.error(error?.response?.data?.errors[key][0]);
                });
              } else {
                toast.error(error?.message);
              }
            });
        }
      }
    }
  };

  const sendReview = async (ItemID, Item) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("client_business_id", businessId);
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/send-for-review/${ItemID}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleJobList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingJobList(true);
      get(
        `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        setLoading(false);
        setLoadingJobList(false);

        const jobsList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.code +
                (item?.description ? " (" + item?.description + ")" : ""),
              value: item?.id,
              tax: item?.tax,
            };
            return list;
          });
        setJobOptions(jobsList ? [ ...jobsList, { label: <strong>+ Add New Job</strong>, value: "new" } ]: [{ label: <strong>+ Add New Job</strong>, value: "new" }]);
      });
    } catch (error) {
      setLoading(false);
      setLoadingJobList(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleTaskList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingTaskList(true);
      get(
        `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
      ).then((response) => {
        setLoading(false);
        setLoadingTaskList(false);

        const tasksList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label:
                item?.code +
                (item?.description ? " (" + item?.description + ")" : ""),
              value: item?.id,
              tax: item?.tax,
            };
            return list;
          });
        setTaskOptions(tasksList ? [ ...tasksList, { label: <strong>+ Add New Task</strong>, value: "new" } ] : [{ label: <strong>+ Add New Task</strong>, value: "new" }]);
      });
    } catch (error) {
      setLoading(false);
      setLoadingTaskList(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleContactList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingContactList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && { label: <div className="list-subHeading">Contractors</div>, options: contractorList },
          supplierList && { label: <div className="list-subHeading">Suppliers</div>, options: supplierList },
          customerList && { label: <div className="list-subHeading">Customers</div>, options: customerList },
          { label: <strong>+ Add New Contact</strong>, value: "new" },
        ];

        setAllContactValue([
          ...contractorList,
          ...supplierList,
          ...customerList,
        ]);

        setAllContactList(allContactListing);

        setLoading(false);
        setLoadingContactList(false);
      });
    } catch (e) {
      setLoading(false);
      setLoadingContactList(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  const handleDescription = (e, Item) => {
    const { id, value } = e.target;

    let newData = [...data.data];
    let index = newData.findIndex(
      (el) =>
        el?.bank_reconciliation[0]?.id === id ||
        el?.bank_reconciliation[1]?.id === id
    );
    if (Item?.bank_reconciliation[0]?.id === id) {
      Item.bank_reconciliation[0].description = value;
    }
    if (Item?.bank_reconciliation[1]?.id === id) {
      Item.bank_reconciliation[1].description = value;
    }
    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: Item?.bank_reconciliation,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleMemoriseDescription = (e, Item) => {
    const { id, value } = e.target;

    let newData = [...data.data];
    let index = newData.findIndex(
      (el) =>
        el?.bank_reconciliation[0]?.id === id ||
        el?.bank_reconciliation[1]?.id === id
    );
    if (Item?.bank_reconciliation[0]?.id === id) {
      Item.bank_reconciliation[0].term = value;
    }
    if (Item?.bank_reconciliation[1]?.id === id) {
      Item.bank_reconciliation[1].term = value;
    }
    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: Item?.bank_reconciliation,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleCheckBox = (id) => {
    const oldIndex = [...selectedItems];

    if (selectedItems.indexOf(id) === -1) {
      oldIndex.push(id);
    } else {
      oldIndex.splice(selectedItems.indexOf(id), 1);
    }
    if (isUnderReviewed === true) {
      if (oldIndex?.length === data?.data?.length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    } else {
      if (oldIndex.length === readyToBeReconciled) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }

    setSelectedItems(oldIndex);
  };

  const handleReconcileDisable = (item) => {
    const value = item?.bank_reconciliation?.map((data, i) => {
      const main =
        data?.chart_of_account_id &&
        (data?.gst === 0 || data?.gst === 1 || data?.gst === 2 || data?.gst === 3) &&
        data?.contact_id &&
        data?.description &&
        data?.amount
          ? true
          : false;
      return main;
    });
    const finalValue =
      value?.find((val) => val === false) === false ? true : false;
    return finalValue;
  };

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageData = isUnderReviewed
      ? await getUnderReviewedNextPageData(currentPage + 1, businessId)
      : await getNextPageData(currentPage + 1, businessId);

    const newObj = {
      ...nextPageData,
      data: [...data.data, ...nextPageData.data],
    };

    setData(newObj);
    setCurrentPage(newObj.current_page);
    setIsLoading(false);
    const isMoreDataExist =
      newObj.data.length < newObj.total &&
      nextPageData?.next_page_url !== "" &&
      nextPageData?.next_page_url !== null;
    setHasMore(isMoreDataExist);
  };

  const handleRefresh = async () => {
    if (businessId) {
      setLoading(true);
      if(startDate && endDate){
        const getTransaction = await refreshBankTransactions(businessId, startDate, endDate, accountFetchId);
        if (getTransaction?.status === 200) {
          toast.success(getTransaction?.message);
          view === "grid" && fetchReconcileTransaction();
          view === "list" && fetchReconcileTransactionForList();
        }
        setBankAccountId(accountFetchId);
        toggleRefreshModal();
      } else {
        toast.error("Start Date and End Date both are required");
      }
      setLoading(false);
    }
  };

  const handleSplit = (id) => {
    setItemId("");
    setSplitAccountId("");
    setSplitJobId("");
    setSplitTaskId("");
    setSplitGst("");
    setSplitDescription("");
    setSplit2Amount("");
    setSplit1Amount("");
    setSplitGst("");
    setSplitAccountId("");
    setSplitAccountVal("");
    setSplitJobId("");
    setSplitJobVal("");
    setSplitTaskId("");
    setSplitTaskVal("");
    setSplitGstVal("");
    setItemId(id);
    setSplitDiv(true);
  };

  const handleSplitDisable = (item) => {
    return item?.bank_reconciliation?.length === 1 ? false : true;
  };

  const handleSubmitSplit = async (
    ItemId,
    bankReconcilationId,
    contactId,
    description,
    accountType
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const formData1 = new FormData();
      formData.append("bank_transaction_id", ItemId ? ItemId : "");
      formData.append(
        "chart_of_account_id",
        splitAccountVal ? splitAccountVal : ""
      );
      formData.append(
        "job_id",
        splitJobVal ? splitJobVal : ""
      );
      formData.append(
        "task_id",
        splitTaskVal ? splitTaskVal : ""
      );
      formData.append("gst", splitGst ? splitGst : splitAccountId?.tax);
      formData.append("contact_id", contactId ? contactId : "");
      formData.append("description", splitDescription ? splitDescription : "");
      formData.append("amount", split2Amount ? split2Amount : "");
      formData.append("client_business_id", businessId ? businessId : "");

      formData1.append("amount", split1Amount ? split1Amount : "");
      formData1.append("description", description ? description : "");
      formData1.append("contact_id", contactId ? contactId : "");
      formData1.append("chart_of_account_id", accountType ? accountType : "");
      formData1.append("_method", "put");

      const response1 = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${bankReconcilationId}`,
        formData1,
        {}
      );

      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}`,
        formData,
        {}
      );
      if (response.status === 200 && response1.status === 200) {
        setItemId("");
        setSplitAccountId("");
        setSplitJobId("");
        setSplitTaskId("");
        setSplitGst("");
        setSplitDescription("");
        setSplit2Amount("");
        setSplit1Amount("");
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleAmount = (e, Item) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex(
      (el) =>
        el?.bank_reconciliation[0]?.id === id ||
        el?.bank_reconciliation[1]?.id === id
    );

    if (Item?.bank_reconciliation[0]?.id === id) {
      /^\d*(\.\d{0,2})?$/.test(value) === true
        ? parseFloat(value) > parseFloat(Item?.amount)
          ? setAmount1(Item?.amount)
          : setAmount1(value)
        : setAmount1(amount1);

      Item.bank_reconciliation[0].amount =
        /^\d*(\.\d{0,2})?$/.test(value) === true
          ? parseFloat(value) > parseFloat(Item?.amount)
            ? Item?.amount
            : value
          : amount1;
    }

    if (Item?.bank_reconciliation[1]?.id === id) {
      /^\d*(\.\d{0,2})?$/.test(value) === true
        ? parseFloat(value) > parseFloat(Item?.amount)
          ? setAmount2(Item?.amount)
          : setAmount2(value)
        : setAmount2(amount2);

      Item.bank_reconciliation[1].amount =
        /^\d*(\.\d{0,2})?$/.test(value) === true
          ? parseFloat(value) > parseFloat(Item?.amount)
            ? Item?.amount
            : value
          : amount2;
    }

    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: Item?.bank_reconciliation,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleBlurAmount = async (
    ItemID,
    item,
    transactionData,
    chartOfAccountId
  ) => {
    const GST1 = item?.gst
      ? item?.gst
      : chartOfAccountId
      ? allAccountValue?.find((account) => account?.value === chartOfAccountId)
          ?.tax
      : "";
    const GST2 = transactionData?.bank_reconciliation?.find(
      (val) => val?.id !== ItemID
    )?.gst
      ? transactionData?.bank_reconciliation?.find((val) => val?.id !== ItemID)
          ?.gst
      : transactionData?.bank_reconciliation?.find((val) => val?.id !== ItemID)
          ?.chart_of_account_id
      ? allAccountValue?.find(
          (account) =>
            account?.value ===
            transactionData?.bank_reconciliation?.find(
              (val) => val?.id !== ItemID
            )?.chart_of_account_id
        )?.tax
      : "";
    try {
      setLoading(true);
      const formData = new FormData();
      const formData2 = new FormData();
      const amount1 = item?.amount
        ? item?.amount > transactionData?.amount
          ? transactionData?.amount
          : item?.amount
        : 0.0;

      const amount2 = transactionData?.amount - amount1;
      const itemId1 = ItemID;
      const itemId2 = transactionData?.bank_reconciliation?.find(
        (val) => val?.id !== ItemID
      )?.id;

      formData.append("amount", formatNumberWithoutComma(amount1));
      formData.append("_method", "put");
      formData.append("gst", GST1);

      formData2.append("amount", formatNumberWithoutComma(amount2));
      formData2.append("_method", "put");
      formData2.append("gst", GST2);

      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${itemId1}`,
        formData,
        {}
      );
      const response2 = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${itemId2}`,
        formData2,
        {}
      );
      if (response.status === 200 && response2.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleEmailForClarificationAll = () => {
    if (selectedItems.length === 0) {
      toast.warning(
        "Please select atleast one bank statement for clarification"
      );
    } else {
      if (selectedItems.length > 0) {
        const formData = new FormData();
        formData.append("client_business_id", businessId);
        formData.append("business_url", window.location.href);
        selectedItems.map((itemData) => {
          formData.append("bank_transaction_ids[]", itemData);
          return formData;
        });

        if ([...formData.entries()].length) {
          fileUpload(API.EMAIL_FOR_CLARIFICATION, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success(response.data.message);
                view === "grid" && fetchReconcileTransaction();
                view === "list" && fetchReconcileTransactionForList();
              }
            })
            .catch((error) => {
              if (error?.response?.data?.errors) {
                Object.keys(error?.response?.data?.errors).forEach((key) => {
                  toast.error(error?.response?.data?.errors[key][0]);
                });
              } else {
                toast.error(error?.message);
              }
            });
        }
      }
    }
  };

  const handleSplitOkDisable = (item) => {
    const isDisable =
      item?.bank_reconciliation[0]?.id &&
      item?.bank_reconciliation[0]?.chart_of_account_id &&
      (parseInt(item?.bank_reconciliation[0]?.gst) === 1 ||
        parseInt(item?.bank_reconciliation[0]?.gst) === 0 ||
        parseInt(item?.bank_reconciliation[0]?.gst) === 2 ||
        parseInt(item?.bank_reconciliation[0]?.gst) === 3) &&
      item?.bank_reconciliation[0]?.contact_id &&
      item?.bank_reconciliation[0]?.description &&
      split1Amount &&
      split2Amount &&
      (splitAccountVal || splitAccountId) &&
      (parseInt(splitGst) === 1 ||
        parseInt(splitGst) === 0 ||
        parseInt(splitGst) === 2 ||
        parseInt(splitGst) === 3 ||
        parseInt(splitGstVal) === 1 ||
        parseInt(splitGstVal) === 0 ||
        parseInt(splitGstVal) === 2 ||
        parseInt(splitGstVal) === 3) &&
      splitDescription
        ? false
        : true;

    return isDisable;
  };

  // const handleMemorise = async (accouintId, description, jobId, taskId, contactId, descriptionMemorise) => {
  //   const formData = new FormData();
  //   formData.append("client_business_id", businessId);
  //   formData.append("term", description);
  //   formData.append("chart_of_account_id", accouintId);
  //   formData.append("description", descriptionMemorise);
  //   contactId && formData.append("contact_id", contactId ? contactId : '');
  //   jobId && formData.append("business_job_id", jobId !== 'null' && jobId !== null ? jobId : '');
  //   taskId && formData.append("business_task_id", taskId !== 'null' && taskId !== null ? taskId : '');

  //   try {
  //     setLoading(true);
  //     const { data } = await fileUpload(`${API.ADD_TERM}`, formData);
  //     setLoading(false);
  //     if (data) {
  //       toast.success(data?.message);
  //       view === "grid" && fetchReconcileTransaction();
  //       view === "list" && fetchReconcileTransactionForList();
  //     }
  //     return data?.data;
  //   } catch (error) {
  //     if (error?.response?.data?.errors) {
  //       Object.keys(error?.response?.data?.errors).forEach((key) => {
  //         toast.error(error?.response?.data?.errors[key][0]);
  //       });
  //       setLoading(false);
  //     } else {
  //       toast.error(error?.message);
  //       setLoading(false);
  //     }
  //   }
  // };

  const handleLeftMemorise = async (accouintId, description, jobId, taskId, contactId, descriptionMemorise) => {
    const formData = new FormData();
    formData.append("client_business_id", businessId);
    formData.append("term", description);
    formData.append("chart_of_account_id", accouintId);
    formData.append("description", descriptionMemorise);
    contactId && formData.append("contact_id", contactId ? contactId : '');
    jobId && formData.append("business_job_id", jobId !== 'null' && jobId !== null ? jobId : '');
    taskId && formData.append("business_task_id", taskId !== 'null' && taskId !== null ? taskId : '');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_TERM}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleLeftListMemorise = async (accouintId, description, jobId, taskId, contactId, descriptionMemorise) => {
    const formData = new FormData();
    formData.append("client_business_id", businessId);
    formData.append("term", description);
    formData.append("chart_of_account_id", accouintId);
    formData.append("description", descriptionMemorise);
    contactId && formData.append("contact_id", contactId ? contactId : '');
    jobId && formData.append("business_job_id", jobId !== 'null' && jobId !== null ? jobId : '');
    taskId && formData.append("business_task_id", taskId !== 'null' && taskId !== null ? taskId : '');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_TERM}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        setMemoriseDataList();
        toggleMemoriseModal();
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleBlurComment = async (itemId, Item, comment) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("comment", comment ? comment : "");
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_TRANSACTION}/${itemId}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleAddNewAccount = () => {
    setAddNewAccount(!addNewAccount);
  };

  const handleAddNewJob = () => {
    setAddNewJob(!addNewJob);
  };

  const handleAddNewTask = () => {
    setAddNewTask(!addNewTask);
  };

  const handleComment = (e, Item) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex((el) => el?.id === id);

    if (index > -1) {
      newData[index] = {
        ...newData[index],
        comment: value,
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleNewAccountSelection = async (
    id,
    ItemID,
    item,
    transactionData
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("chart_of_account_id", id?.id);
      formData.append("gst", id?.tax);
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleNewJobSelection = async (id, ItemID, item, transactionData) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("job_id", id?.id);
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleNewTaskSelection = async (id, ItemID, item, transactionData) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("task_id", id?.id);
      formData.append(
        "description",
        item?.description ? item?.description : transactionData?.description
      );
      formData.append("_method", "put");
      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/${ItemID}`,
        formData,
        {}
      );
      if (response.status === 200) {
        view === "grid" && fetchReconcileTransaction();
        view === "list" && fetchReconcileTransactionForList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleSplitAddJob = (id) => {
    if (id?.client_business_id){
      setSplitJobVal(id?.id);
      // setSplitAccountId(id);
    }
    else {
      if (id?.value === id?.label || id?.value === "new") {
        setAddNewJob(true);
        setJobName(typeof id?.label === "string" ? id?.label : "");
        setAddJobItemID("");
      } else {
        setSplitJobId(id);
        setSplitJobVal(id?.value);
      }
    }
  };

  const handleSplitAddTask = (id) => {
    if (id?.client_business_id){
      setSplitTaskVal(id?.id);
      // setSplitAccountId(id);
    }
    else {
      if (id?.value === id?.label || id?.value === "new") {
        setAddNewTask(true);
        setTaskName(typeof id?.label === "string" ? id?.label : "");
        setAddTaskItemID("");
      } else {
        setSplitTaskId(id);
        setSplitTaskVal(id?.value);
      }
    }
  };

  const handleSplitAddAccount = (id) => {
    if (id?.client_business_id){
      setSplitAccountVal(id?.id);
      // setSplitAccountId(id);
      setSplitGst(id?.tax);
      setSplitGstVal(id?.tax);
    }
    else {
      if (id?.value === id?.label || id?.value === "new") {
        setAddNewAccount(true);
        setAccountName(typeof id?.label === "string" ? id?.label : "");
        setAddAccountItemID("");
      } else {
        setSplitAccountVal(id?.value);
        setSplitAccountId(id);
        setSplitGst(id?.tax);
        setSplitGstVal(id?.tax);
      }
    }
  };

  useEffect(() => {
    view === "grid" && fetchReconcileTransaction();
  }, [view, fetchReconcileTransaction]);

  useEffect(() => {
    handleAccountChange();
  }, [handleAccountChange]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  useEffect(() => {
    handleJobList();
  }, [handleJobList]);

  useEffect(() => {
    handleTaskList();
  }, [handleTaskList]);

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
  };

  const handleUploadInvoice = (Id) => {
    setItemId(Id);
    setSplitDiv(false);
    setShowImport(true);
  };

  const handleClosemodal = () => {
    setShowImport(false);
  };

  const handleUpload = async () => {
    if (files) {
      setIsImportLoading(true);
      const formData = new FormData();
      // files.forEach((file) => {
      //   if (file !== undefined) {
      formData.append("comment", "Test");
      formData.append("invoice", files);
      formData.append("_method", "put");
      //   }
      // });
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      fileUpload(
        API.UPLOAD_INVOICE_BANK_TRANSACTION + "/" + itemId,
        formData,
        config
      )
        .then((response) => {
          if (response.status === 200) {
            setIsImportLoading(false);
            toast.success(response.data.message);
            handleImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsImportLoading(false);
          toast.error(data?.message);
          toast.error(
            data.errors &&
              data.errors.customer_file &&
              data.errors.customer_file[0]
          );
          toast.error(
            data.errors && data.errors.myna_error && data.errors.myna_error[0]
          );
          var error = {};
          error["myna_error"] =
            "There is some issue with request, please try after some time.";
        });
    }
  };

  //List View

  useEffect(() => {
    view === "list" && fetchReconcileTransactionForList();
  }, [view, isImportLoading, fetchReconcileTransactionForList]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleDownload = async (data) => {
    try {
      setExportLoading(true);

      var a = document.createElement("a");

      a.href = imageDefaultPrefixPath + data.invoice;

      a.setAttribute("download", data.invoice);

      // a.setAttribute("target", "_blank");

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      setExportLoading(false);
      // }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  /* const handleListAmount1 = (e, index) => {
    setSplit1Amount(
      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
        ? (parseFloat(e.target.value)
            ? parseFloat(e.target.value)
            : 0) > parseFloat(dataForList?.data[i]?.amount)
          ? dataForList?.data[i]?.amount
          : e.target.value
          ? e.target.value
          : 0
        : split1Amount
    );
    setSplit2Amount(
      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
        ? (parseFloat(e.target.value)
            ? parseFloat(e.target.value)
            : 0) > parseFloat(dataForList?.data[i]?.amount)
          ? 0.0
          : (
              parseFloat(dataForList?.data[i]?.amount) -
              (parseFloat(e.target.value)
                ? parseFloat(e.target.value)
                : 0)
            ).toFixed(2)
        : split2Amount
    );
  }; */

  const listViewDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: dataForList?.data[i]?.id,

        check: (
          <div className="reconcile-item-list-data refund-checkbox">
            <Form.Check
              type="checkbox"
              disabled={
                isUnderReviewed === true
                  ? false
                  : handleReconcileDisable(dataForList?.data[i])
              }
              checked={
                isUnderReviewed === true
                  ? selectedItems.indexOf(dataForList?.data[i].id) >= 0
                  : handleReconcileDisable(dataForList?.data[i]) === false
                  ? selectedItems.indexOf(dataForList?.data[i].id) >= 0
                  : false
              }
              onChange={() => {
                handleCheckBox(dataForList?.data[i].id);
              }}
            ></Form.Check>
          </div>
        ),

        date: dataForList?.data[i]?.transactionDate
          ? moment(dataForList?.data[i]?.transactionDate, "DD-MM-YYYY").format(
              "DD-MM-YYYY"
            )
          : "",
        description: dataForList?.data[i]?.description
          ? dataForList?.data[i]?.description
          : "",
        debit:
          dataForList?.data[i]?.baseType === 1
            ? ""
            : `${dataForList?.data[i]?.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
        credit:
          dataForList?.data[i]?.baseType === 1
            ? `${dataForList?.data[i]?.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : "",

        review_notes: isUnderReviewed ? (
          <Form.Control
            required
            type="text"
            id={dataForList?.data[i].id}
            placeholder="Add Comment"
            value={
              dataForList?.data[i].comment ? dataForList?.data[i].comment : ""
            }
            onChange={(e) => {
              handleComment(e, dataForList?.data[i]);
            }}
            onBlur={() =>
              handleBlurComment(
                dataForList?.data[i]?.id,
                dataForList?.data[i],
                dataForList?.data[i]?.comment
              )
            }
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  "Comment can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
              },
            ]}
            style={{
              border: "1px solid #d9d9d9",
              padding: "7px 10px",
              fontSize: "12px",
              borderRadius: "5px",
            }}
          />
        ) : (
          ""
        ),
        account_gst: (
          <div className="reconcilation-column-group-input">
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Account </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Account"
              isLoading={accountOptions.length === 0 ? true : false}
              onChange={(id) =>
                handleAccountSelection(
                  id,
                  dataForList?.data[i]?.bank_reconciliation[0].id,
                  dataForList?.data[i]?.bank_reconciliation[0],
                  dataForList?.data[i]
                )
              }
              value={
                allAccountValue?.find(
                  (account) =>
                    account?.value ===
                    dataForList?.data[i]?.bank_reconciliation[0]
                      ?.chart_of_account_id
                )
                  ? allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        dataForList?.data[i]?.bank_reconciliation[0]
                          ?.chart_of_account_id
                    )
                  : ""
              }
              classNamePrefix="chartofselect"
              options={
                [...accountOptions?.map((element) => {
                    return({
                      label: element?.label,
                      options: element?.options 
                    })                                                        
                  })
                  , { label: <strong>+ Add New Account</strong>, value: "new" }
                ]
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
            <Select
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select GST"
              loading={GSTOptions.length === 0 ? true : false}
              value={
                GSTOptions?.find(
                  (val) =>
                    val?.value ===
                    parseInt(dataForList?.data[i]?.bank_reconciliation[0]?.gst)
                )
                  ? GSTOptions?.find(
                      (val) =>
                        val?.value ===
                        parseInt(
                          dataForList?.data[i]?.bank_reconciliation[0]?.gst
                        )
                    )
                  : allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        dataForList?.data[i]?.bank_reconciliation[0]
                          ?.chart_of_account_id
                    )?.tax === 1
                  ? GSTOptions?.find((val) => val?.value === 1)
                  : allAccountValue?.find(
                    (account) =>
                      account?.value ===
                      dataForList?.data[i]?.bank_reconciliation[0]
                        ?.chart_of_account_id
                    )?.tax === 2
                  ? GSTOptions?.find((val) => val?.value === 2)
                  : allAccountValue?.find(
                    (account) =>
                      account?.value ===
                      dataForList?.data[i]?.bank_reconciliation[0]
                        ?.chart_of_account_id
                    )?.tax === 3
                  ? GSTOptions?.find((val) => val?.value === 3)
                  : allAccountValue?.find(
                      (account) =>
                        account?.value ===
                        dataForList?.data[i]?.bank_reconciliation[0]
                          ?.chart_of_account_id
                    )?.tax === 0
                  ? GSTOptions?.find((val) => val?.value === 0)
                  : ""
              }
              onChange={(id) =>
                handleGSTSelection(
                  id?.value,
                  dataForList?.data[i]?.bank_reconciliation[0].id
                )
              }
              classNamePrefix="chartofselect"
              options={GSTOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
          </div>
        ),
        additional:(
          <div className="reconcilation-column-group-input">
            <div className="reconcilation-clear-job-task-body">
            <Fragment>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Job </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Job"
              loading={jobOptions.length === 0 || loadingJobList === true ? true : false}
              onChange={(id) => {
                handleJobSelection(
                  id,
                  dataForList?.data[i]?.bank_reconciliation[0].id,
                  dataForList?.data[i]?.bank_reconciliation[0],
                  dataForList?.data[i]
                );
              }}
              value={
                jobOptions?.find(
                  (job) =>
                    job?.value ===
                    dataForList?.data[i]?.bank_reconciliation[0]?.job_id
                )
                  ? jobOptions?.find(
                      (job) =>
                        job?.value ===
                        dataForList?.data[i]?.bank_reconciliation[0]?.job_id
                    )
                  : ""
              }
              classNamePrefix="jobselect"
              options={jobOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              className={"selectpicker"}
            />
            {dataForList?.data[i]?.bank_reconciliation[0]?.job && <FontAwesomeIcon 
              className="clear-button-selection"
              icon={faXmark}
              size="1x"
              onClick={() => clearJobSelection('',dataForList?.data[i]?.bank_reconciliation[0].id)}
            />}
            </Fragment>
            </div>
            
            <div className="reconcilation-clear-job-task-body">
            <Fragment>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Task </strong>'{`${userInput}`}'
                </>
              )}
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Task"
              loading={taskOptions.length === 0 || loadingTaskList === true ? true : false}
              onChange={(id) => {
                handleTaskSelection(
                  id,
                  dataForList?.data[i]?.bank_reconciliation[0].id,
                  dataForList?.data[i]?.bank_reconciliation[0],
                  dataForList?.data[i]
                );
              }}
              value={
                taskOptions?.find(
                  (task) =>
                    task?.value ===
                    dataForList?.data[i]?.bank_reconciliation[0]?.task_id
                )
                  ? taskOptions?.find(
                      (task) =>
                        task?.value ===
                        dataForList?.data[i]?.bank_reconciliation[0]?.task_id
                    )
                  : ""
              }
              classNamePrefix="taskselect"
              options={taskOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              className={"selectpicker"}
            />
            {dataForList?.data[i]?.bank_reconciliation[0]?.task &&
              <FontAwesomeIcon 
                className="clear-button-selection"
                icon={faXmark}
                size="1x"
                onClick={() => clearTaskSelection('',dataForList?.data[i]?.bank_reconciliation[0].id)}
              />
            }
            </Fragment>
            </div>
          </div>
        ),
        contact_name: (
          <CreatableSelect
            formatCreateLabel={(userInput) => (
              <>
                <strong>+ Add New Contact </strong>'{`${userInput}`}'
              </>
            )}
            style={{
              width: "50%",
              margin: "0",
            }}
            placeholder="Select Contact"
            loading={
              allContactList.length === 0 || loadingContactList === true
                ? true
                : false
            }
            onChange={(id) =>
              handleContactNameSelection(
                id,
                dataForList?.data[i]?.bank_reconciliation[0].id,
                dataForList?.data[i]?.bank_reconciliation[0],
                dataForList?.data[i]?.baseType,
                dataForList?.data[i]
              )
            }
            value={
              allContactValue?.find(
                (contact) =>
                  contact?.value ===
                  dataForList?.data[i]?.bank_reconciliation[0]?.contact_id
              )
                ? allContactValue?.find(
                    (contact) =>
                      contact?.value ===
                      dataForList?.data[i]?.bank_reconciliation[0]?.contact_id
                  )
                : ""
            }
            classNamePrefix="chartofselect"
            options={allContactList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "grey",
              },
            })}
          />
        ),
        comment: (
          <Form.Control
            required
            type="text"
            id={dataForList?.data[i]?.bank_reconciliation[0].id}
            placeholder="Add Description"
            value={
              dataForList?.data[i]?.bank_reconciliation[0].description
                ? dataForList?.data[i]?.bank_reconciliation[0].description
                : ""
            }
            onChange={(e) => {
              handleDescription(e, dataForList?.data[i]);
            }}
            onBlur={() =>
              handleBlurDescription(
                dataForList?.data[i]?.bank_reconciliation[0].id,
                dataForList?.data[i]?.bank_reconciliation[0],
                dataForList?.data[i],
                dataForList?.data[i]?.bank_reconciliation[0]
                  ?.chart_of_account_id
              )
            }
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
              },
            ]}
            style={{
              border: "1px solid #d9d9d9",
              padding: "7px 10px",
              fontSize: "12px",
              borderRadius: "5px"
            }}
          />
        ),
        amount:
          dataForList?.data[i]?.bank_reconciliation?.length === 2 ? (
            <Form.Control
              required
              type="text"
              id={dataForList?.data[i]?.bank_reconciliation[0].id}
              placeholder="Add Amount"
              value={
                dataForList?.data[i]?.bank_reconciliation[0].amount
                  ? dataForList?.data[i]?.bank_reconciliation[0].amount
                  : ""
              }
              onChange={(e) => {
                handleAmount(e, dataForList?.data[i]);
              }}
              onBlur={() =>
                handleBlurAmount(
                  dataForList?.data[i]?.bank_reconciliation[0].id,
                  dataForList?.data[i]?.bank_reconciliation[0],
                  dataForList?.data[i],
                  dataForList?.data[i]?.bank_reconciliation[0]
                    ?.chart_of_account_id
                )
              }
              rules={[
                {
                  pattern: new RegExp(/^([0-9]+$)([.]?)/),
                  message: "Amount can contain numbers & dot(.)",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ) : (
            <Form.Control
              required
              type="text"
              placeholder="Add Amount"
              id={dataForList?.data[i]?.bank_reconciliation[0].id}
              // value={split1Amount ? split1Amount : ""}
              // value={
              //   data.data[i]?.split1Amount
              //     ? data.data[i]?.split1Amount
              //     : split1Amount
              // }
              value={
                data.data[i]?.split1Amount ? data.data[i]?.split1Amount : (dataForList?.data[i]?.bank_reconciliation[0].amount ? dataForList?.data[i]?.bank_reconciliation[0].amount : null)
              }
              onChange={(e) => {
                const { id } = e.target;

                let newData = [...data.data];

                let index = newData.findIndex(
                  (el) =>
                    el?.bank_reconciliation[0]?.id === id ||
                    el?.bank_reconciliation[1]?.id === id
                );

                setSplit1Amount(
                  /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                    ? (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0) > parseFloat(dataForList?.data[i]?.amount)
                      ? dataForList?.data[i]?.amount
                      : e.target.value
                      ? e.target.value
                      : 0
                    : split1Amount
                );

                setSplit2Amount(
                  /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                    ? (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0) > parseFloat(dataForList?.data[i]?.amount)
                      ? 0.0
                      : (
                          parseFloat(dataForList?.data[i]?.amount) -
                          (parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0)
                        ).toFixed(2)
                    : split2Amount
                );

                if (index > -1) {
                  newData[index] = {
                    ...newData[index],
                    split1Amount:
                      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                        ? (parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0) > parseFloat(dataForList?.data[i]?.amount)
                          ? dataForList?.data[i]?.amount
                          : e.target.value
                          ? e.target.value
                          : 0
                        : split1Amount,
                    split2Amount:
                      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                        ? (parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0) > parseFloat(dataForList?.data[i]?.amount)
                          ? 0.0
                          : (
                              parseFloat(dataForList?.data[i]?.amount) -
                              (parseFloat(e.target.value)
                                ? parseFloat(e.target.value)
                                : 0)
                            ).toFixed(2)
                        : split2Amount,
                  };
                  setData({ ...data, ...{ data: newData } });
                }
              }}
              rules={[
                {
                  pattern: new RegExp(/^([0-9]+$)([.]?)/),
                  message: "Amount can contain numbers & dot(.)",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ),
        action: (
          <div className="table-action-btn">
            {handleReconcileDisable(dataForList?.data[i]) === true ? (
              <CustomTooltip
                text={"Reconcile"}
                children={
                  <FontAwesomeIcon
                    // title="Reconcile"
                    className="disabled-font-awesome"
                    // icon={faMoneyBillTransfer}
                    icon={faCheckToSlot}
                    size="1x"
                  />
                }
              />
            ) : (
              <CustomTooltip
                text={"Reconcile"}
                children={
                  <FontAwesomeIcon
                    // title="Reconcile"
                    onClick={() => {
                      handleReconcile(dataForList?.data[i].id);
                    }}
                    style={{ color: "#0DA500" }}
                    // icon={faMoneyBillTransfer}
                    icon={faCheckToSlot}
                    size="1x"
                  />
                }
              />
            )}
            {!isUnderReviewed && 
            <>
              <CustomTooltip
                text={"Send For Review"}
                children={
                  <FontAwesomeIcon
                   icon={faPaperPlane}
                   size="1x"
                  //  title="Send For Review"
                   onClick={() => {
                     sendReview(dataForList?.data[i].id, dataForList?.data[i]);
                   }}
                  />
                }
              />
            </>}
            {/* ------------------- */}
            {itemId !== dataForList?.data[i]?.id &&
              dataForList?.data[i]?.bank_reconciliation?.length === 1 && (
                <>
                  {dataForList?.data[i]?.bank_reconciliation[0]
                    ?.chart_of_account_id && dataForList?.data[i]?.bank_reconciliation[0]
                    ?.contact_id ? (
                    // <FontAwesomeIcon
                    //   title="Memorise"
                    //   onClick={() => {
                    //     handleMemorise(
                    //       dataForList?.data[i]?.bank_reconciliation[0]
                    //         ?.chart_of_account_id,
                    //       dataForList?.data[i]?.bank_reconciliation[0]
                    //         ?.description,
                    //       dataForList?.data[i]?.bank_reconciliation[0]?.job_id,
                    //       dataForList?.data[i]?.bank_reconciliation[0]?.task_id,
                    //       dataForList?.data[i]?.bank_reconciliation[0]?.contact_id,
                    //       dataForList?.data[i]?.description
                    //     );
                    //   }}
                    //   icon={faMemory}
                    //   size="1x"
                    // />
                    <>
                    <CustomTooltip
                      text={"Memorise"}
                      children={
                        <FontAwesomeIcon
                          // title="Memorise"
                          onClick={() => {
                            toggleMemoriseModal()
                            setMemoriseDataList(dataForList?.data[i])
                          }}
                          icon={faMemory}
                          size="1x"
                        />
                      }
                    />              
                    </>

                  ) : (
                    <CustomTooltip
                      text={"Memorise"}
                      children={
                        <FontAwesomeIcon
                          className="disabled-font-awesome"
                          icon={faMemory}
                          size="1x"
                        />
                      }
                    /> 
                  )}
                </>
              )}
              <CustomTooltip
                text={"Delete"}
                children={
                  <FontAwesomeIcon
                    icon={faTrash}
                    size="1x"
                    title="Delete"
                    onClick={() => {
                      toggleDeleteModal();
                      setBankTransactionId(dataForList?.data[i]?.id);
                    }}
                  />
                }
              />
            {dataForList.data[i].baseType === 0 ? (
              <>
                <CustomTooltip
                  text={"Upload"}
                  children={
                    <FontAwesomeIcon
                      icon={faUpload}
                      size="1x"
                      title="Upload"
                      onClick={() => handleUploadInvoice(dataForList?.data[i].id)}
                    />
                  }
                />
              </>
            ) : null}
            {dataForList.data[i].invoice ? (
              <>
                <CustomTooltip
                  text={"Download"}
                  children={
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="1x"
                      title="Download"
                      // onClick={handleDownload(dataForList?.data[i])}
                      onClick={() => handleDownload(dataForList?.data[i])}
                    />
                  }
                />
              </>
            ) : null}
          </div>
        ),
        bank_transaction_data: dataForList?.data[i],
      });
    }
    return items;
  };

  const listViewData = listViewDataGenerator(dataForList?.data?.length);
  const columns = isUnderReviewed
    ? [
        {
          dataField: "check",
          text: "",
          style: { width: "1%" },
        },
        {
          dataField: "date",
          text: "Date",
          style: { width: "8%" },
        },
        {
          dataField: "description",
          text: "Description",
          style: { width: "8%" },
        },
        {
          dataField: "debit",
          text: "Debit",
          style: { width: "4%", textAlign: "right" },
        },
        {
          dataField: "credit",
          text: "Credit",
          style: { width: "4%", textAlign: "right" },
        },
        {
          dataField: "review_notes",
          text: "Review Notes",
          style: { width: "13%" },
        },
        {
          dataField: "account_gst",
          text: "Account/GST",
          style: { width: "13%" },
        },
        // {
        //   dataField: "gst",
        //   text: "Gst",
        //   style: { width: "12%" },
        // },
        {
          dataField: "contact_name",
          text: "Contact Name",
          style: { width: "13%" },
        },
        {
          dataField: "comment",
          text: "Comment",
          style: { width: "13%" },
        },
        {
          dataField: "amount",
          text: "Amount",
          style: { width: "8%" },
        },
        {
          dataField: "additional",
          text: 'Additional',
          style: {width: "13%"}
        },
        {
          dataField: "action",
          text: "",
          className: "text-center",
          style: { width: "7%" },
        },
      ]
    : [
        {
          dataField: "check",
          text: "",
          style: { width: "1%" },
        },
        {
          dataField: "date",
          text: "Date",
          style: { width: "8%" },
        },
        {
          dataField: "description",
          text: "Description",
          style: { width: "10%" },
        },
        {
          dataField: "debit",
          text: "Debit",
          style: { width: "6%", textAlign: "right" },
        },
        {
          dataField: "credit",
          text: "Credit",
          style: { width: "6%", textAlign: "right" },
        },
        {
          dataField: "account_gst",
          text: "Account/GST",
          style: { width: "13%" },
        },
        // {
        //   dataField: "gst",
        //   text: "Gst",
        //   style: { width: "12%" },
        // },
        {
          dataField: "contact_name",
          text: "Contact Name",
          style: { width: "15%" },
        },
        {
          dataField: "comment",
          text: "Comment",
          style: { width: "15%" },
        },
        {
          dataField: "amount",
          text: "Amount",
          style: { width: "8%" },
        },
        {
          dataField: "additional",
          text: 'Additional',
          style: {width: "13%"}
        },
        {
          dataField: "action",
          text: "",
          className: "text-center",
          style: { width: "7%" },
        },
      ];

  useEffect(() => {
    fetchReconcileTransaction();
  }, [fetchReconcileTransaction, isImportLoading]);

  const handleSplitCheck = (e, item, Id) => {
    setSplit1Amount(
      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
        ? (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0) >
          parseFloat(item?.amount)
          ? item?.amount
          : e.target.value
          ? e.target.value
          : 0
        : split1Amount
    );

    setSplit2Amount(
      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
        ? (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0) >
          parseFloat(item?.amount)
          ? 0.0
          : (
              parseFloat(item?.amount) -
              (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0)
            ).toFixed(2)
        : split2Amount
    );

    setReconcileData((reconcileData) =>
      reconcileData.map((data) =>
        data.id === Id
          ? {
              ...data,
              value:
                (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0) >
                parseFloat(item?.amount)
                  ? 0.0
                  : (
                      parseFloat(item?.amount) -
                      (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0)
                    ).toFixed(2),
            }
          : data
      )
    );
  };

  const handleSplitCheck2 = (e, item, ID) => {
    // const { value, id } = e.target;

    setSplit2Amount(
      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
        ? (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0) >
          parseFloat(item?.amount)
          ? item?.amount
          : e.target.value
          ? e.target.value
          : 0
        : split2Amount
    );

    setSplit1Amount(
      /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
        ? (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0) >
          parseFloat(item?.amount)
          ? 0.0
          : (
              parseFloat(item?.amount) -
              (parseFloat(e.target.value) ? parseFloat(e.target.value) : 0)
            ).toFixed(2)
        : split1Amount
    );
  };

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }
  
  const dateFilterRange = [
    {
      label: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
  ]

  const expandRow = {
    renderer: (row) => (
      <div className="expand-reconcile-transaction">
        <ExpandedRowForReconcileTransaction
          data={row}
          accountOptions={accountOptions}
          allAccountValue={allAccountValue}
          jobOptions={jobOptions}
          taskOptions={taskOptions}
          handleAccountSelection={handleAccountSelection}
          GSTOptions={GSTOptions}
          handleGSTSelection={handleGSTSelection}
          allContactList={allContactList}
          loadingContactList={loadingContactList}
          handleContactNameSelection={handleContactNameSelection}
          allContactValue={allContactValue}
          handleDescription={handleDescription}
          handleBlurDescription={handleBlurDescription}
          handleAmount={handleAmount}
          handleBlurAmount={handleBlurAmount}
          splitAccountVal={splitAccountVal}
          splitJobVal={splitJobVal}
          splitTaskVal={splitTaskVal}
          handleSplitAddAccount={handleSplitAddAccount}
          handleSplitAddJob={handleSplitAddJob}
          handleSplitAddTask={handleSplitAddTask}
          splitGstVal={splitGstVal}
          setSplitGst={setSplitGst}
          setSplitDescription={setSplitDescription}
          setSplit1Amount={setSplit1Amount}
          setSplit2Amount={setSplit2Amount}
          setSplitAccountId={setSplitAccountId}
          setSplitJobId={setSplitJobId}
          splitJobId={splitJobId}
          splitTaskId={splitTaskId}
          setSplitTaskId={setSplitTaskId}
          setItemId={setItemId}
          handleSplitOkDisable={handleSplitOkDisable}
          handleSubmitSplit={handleSubmitSplit}
          split1Amount={split1Amount}
          split2Amount={split2Amount}
          handleReconcileDisable={handleReconcileDisable}
          handleSplitDisable={handleSplitDisable}
          splitIcon={splitIcon}
          itemId={itemId}
          isUnderReviewed={isUnderReviewed}
          selectedItems={selectedItems}
          handleCheckBox={handleCheckBox}
          splitDescription={splitDescription}
          setSplitGstVal={setSplitGstVal}
          dataForList={dataForList}
          newData={data}
          setData={setData}
          reconcileData={reconcileData}
          handleJobSelection={handleJobSelection}
          handleTaskSelection={handleTaskSelection}
          clearSplitJobSelection={clearSplitJobSelection}
          clearSplitTaskSelection={clearSplitTaskSelection}
          clearJobSelection={clearJobSelection}
          clearTaskSelection={clearTaskSelection}
        />
      </div>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "right",
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded === false) {
        setItemId();
        //   setSplitAccountId("");
        //   setSplitGst("");
        //   setSplitDescription("");
        //   setSplit2Amount("");
        //   setSplit1Amount("");
      }
      if(expanded === true) {
        setItemId(
          listViewData
            .filter((item) => item.key === rowKey)
            .map((item) => item.key)
            .pop()
        )
      }

      return (
        <div className="">
          <b>
          <CustomTooltip
            text={"Split"}
            children={
              <span
                variant="secondary"
                className="reconcile-item-list-split"
                size="sm"
                // title="Split"
              >
                <img
                  src={splitIcon}
                  alt=""
                  style={{ height: "16px" }}
                  // onClick={() =>
                  //   setItemId(
                  //     listViewData
                  //       .filter((item) => item.key === rowKey)
                  //       .map((item) => item.key)
                  //       .pop()
                  //   )
                  // }
                />
              </span>
            }
          />
          </b>
        </div>
      );
    },
  };

  const handleBankAccountChange = (item) => {
    setBankAccountId(item?.value);
    // setBankTransactionData([]);
    setPage(1);
  }

  const handleSorting = (item) => {
    setBankTransactionData([]);
    setSortField(item?.field);
    setSortOrder(item?.value);
    setPage(1);
  }

  const handleReconcileDateRange = (e) => {
    setValueRange(e);
  }

  const handleAccountFetchChange = (item) => {
    setAccountFetchId(item?.value);
    setAccountMFA(item?.mfa);
    setAccountYodleeId(item?.accountId);
  }

  const getProviderBankData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getProviderBankDetail(businessId);
      setProviderBankData(data?.data);
      const yodleeStatus = data?.data[0]?.providerAccountId;
      setIsYodleeConnect(yodleeStatus);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [businessId]);

  const handleProviderBank = () => {
    getProviderBankData();
    setDisconnectModal(false);
  };

  
  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  const handleYodleeRefreshControl = async (providerID) => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(businessId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: "refresh",
        providerAccountId: providerID,
        configName: yodleeConfigName,
      };
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  return (
    <div className="cms-page audit-statement">
      {loading && <Spin />}
      <InfinityScrollComponent
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMore}
        loadOnMount={true}
        lastItemRef={lastElement}
      >
        <div className="cms-page">
          <div className="page-content-block">
            <div className="content-details refund-process">
              <div className="table-top-btn">
                <div>
                  <h2>
                    Total Transactions ready to be reconciled:{" "}
                    {readyToBeReconciled ? readyToBeReconciled : 0}
                  </h2>
                  <p>
                    Your last bank transaction import occurred on{" "}
                    {latestStatement?.created_at
                      ? moment(
                          latestStatement?.created_at,
                          "DD-MM-YYYY HH:mm:ss"
                        ).format("Do MMM YYYY")
                      : ""}
                  </p>
                </div>
                <div className="table-btn">
                  <Button
                    variant="primary"
                    onClick={() => {
                      toggleRefreshModal();
                    }}
                    disabled={balanceInfo.length > 0 ? false : true}
                    title={balanceInfo.length > 0 ? "" : "Please connect bank account"}
                  >
                    Fetch
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!data.data?.length}
                    onClick={() => {
                      handleReconcileAll();
                    }}
                  >
                    Reconcile
                  </Button>
                  {isUnderReviewed && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleEmailForClarificationAll();
                      }}
                    >
                      Email For Clarification
                    </Button>
                  )}
                </div>
              </div>
              <div style={{ width: "100%", height: "89%" }}>
                <div
                  id="grid-wrapper"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div
                    id="myGrid"
                    className="ag-theme-alpine ato-grid bank-reconcile-grid"
                  >
                    {loading && <Spin />}
                    <Form>
                      <div className="reconcile-select-filter-body">
                        <Form.Check
                          type="checkbox"
                          label={
                            "Select All " +
                            (selectedItems.length > 0
                              ? `(${selectedItems.length})`
                              : "")
                          }
                          name="checkall"
                          id="checkall"
                          checked={allChecked}
                          onChange={(e) => handleAllCheckBox(e)}
                        ></Form.Check>
                        <div className="reconcile-filters">
                            <div className="reconcile-bank-account">
                              <div className="bank-account-dropdown">
                                <p>Account</p>
                                <Select 
                                style={{
                                  width: "50%",
                                  margin: "0",
                                }}
                                placeholder="Select Account"
                                value={bankAccountId ? bankAccountOptions?.find((i) => i.value === bankAccountId) : {label: 'ALL', value: ''}}
                                onChange={(item) => {
                                  handleBankAccountChange(item);
                                }}
                                loading={loading}
                                classNamePrefix="account_select_bank"
                                options={bankAccountOptions}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "grey",
                                  },
                                })}
                                />
                              </div>
                            </div>
                            <div className="reconcile-bank-account">
                              <div className="bank-account-dropdown">
                                <p>Sort</p>
                                <Select 
                                style={{
                                  width: "50%",
                                  margin: "0",
                                }}
                                placeholder="Select Sort"
                                defaultValue={sortOrder === 'DESC' ? sortOptions[1] : sortOptions[0]}
                                onChange={(item) => {
                                  handleSorting(item);
                                }}
                                loading={loading}
                                classNamePrefix="account_select_bank"
                                options={sortOptions}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "grey",
                                  },
                                })}
                                />
                              </div>
                            </div>
                            <Form>
                              <DateRangePicker
                                placeholder="Select Date Range"
                                onChange={(e) => handleReconcileDateRange(e)}
                                format="dd-MM-yyyy"
                                disabledDate={afterToday()}
                                placement="bottomEnd"
                                value={valueRange}
                                ranges={dateFilterRange}
                                locale={{ok: "Apply"}}
                              />
                            </Form>
                        </div>
                        <div className="list-view">
                        <div className={view === "grid" ? 'reconcile-view view-active' : 'reconcile-view'}>
                          <FontAwesomeIcon
                            icon={faGrip}
                            onClick={() => {
                              setView("grid");
                            }}
                            size="2x"
                          />{" "}
                        </div>
                        <div className={view === "list" ? 'reconcile-view view-active' : 'reconcile-view'}>
                          <FontAwesomeIcon
                            icon={faListSquares}
                            onClick={() => {
                              setView("list");
                            }}
                            size="2x"
                          />
                        </div>
                      </div>
                      </div>
                      
                      {view === "grid" && (
                        <div className="form-box">
                          {data?.data?.map((item, index) => {
                            return (
                              <div
                                id={item.id}
                                ref={setLastElement}
                                key={index}
                                className="refund-process-box"
                                // className={getClassName(item)}
                              >
                                <Container>
                                  <Container>
                                    <Row className="refund-card-box">
                                      <Col
                                        xs={6}
                                        md={5}
                                        style={{ display: "flex" }}
                                      >
                                        <div className="refund-checkbox">
                                          <Form.Check
                                            type="checkbox"
                                            disabled={
                                              isUnderReviewed === true
                                                ? false
                                                : handleReconcileDisable(item)
                                            }
                                            checked={
                                              isUnderReviewed === true
                                                ? selectedItems.indexOf(
                                                    item.id
                                                  ) >= 0
                                                : handleReconcileDisable(
                                                    item
                                                  ) === false
                                                ? selectedItems.indexOf(
                                                    item.id
                                                  ) >= 0
                                                : false
                                            }
                                            onChange={() => {
                                              handleCheckBox(item.id);
                                            }}
                                          ></Form.Check>
                                        </div>
                                        <div
                                          className="refund-card"
                                          style={{
                                            width: "calc(100% - 25px)",
                                            marginLeft: "auto",
                                          }}
                                          // onClick={() => handleId(item.id)}
                                        >
                                          {item && (
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <FontAwesomeIcon
                                                  icon={faEllipsisV}
                                                  size="1x"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    toggleDeleteModal();
                                                    setBankTransactionId(
                                                      item?.id
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#deleteReconcileTransaction"
                                                >
                                                  Delete
                                                </Dropdown.Item>

                                                {item && (
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      sendReview(item.id, item);
                                                    }}
                                                  >
                                                    Send For Review
                                                  </Dropdown.Item>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                          <label>Bank Transaction</label>
                                          <div
                                            className={"refund-card-content"}
                                          >
                                            <div className="left-block">
                                              <p
                                                className="name"
                                                style={{ flexDirection: "row" }}
                                              >
                                                <strong>Date:</strong>{" "}
                                                {moment(
                                                  item?.transactionDate,
                                                  "DD-MM-YYYY"
                                                ).format("DD-MM-YYYY")}
                                              </p>
                                              <p>
                                                <strong
                                                  style={{
                                                    minWidth: "inherit",
                                                  }}
                                                >
                                                  Description:
                                                </strong>{" "}
                                                {item?.description
                                                  ? item?.description
                                                  : ""}
                                              </p>
                                              {/* {isUnderReviewed && (
                                              <Form.Group
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  flexFlow: "wrap",
                                                  alignItems: "center",
                                                  gridGap: "10px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                <strong
                                                // style={{
                                                //   margin: "0",
                                                //   width: "130px",
                                                // }}
                                                >
                                                  Comment
                                                </strong>
                                                <Form.Control
                                                  className="memorise-description"
                                                  required
                                                  type="text"
                                                  id={item.id}
                                                  placeholder="Add Comment"
                                                  value={
                                                    item.comment
                                                      ? item.comment
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleComment(e, item);
                                                  }}
                                                  onBlur={() =>
                                                    handleBlurComment(
                                                      item.id,
                                                      item,
                                                      item?.commenth
                                                    )
                                                  }
                                                  rules={[
                                                    {
                                                      pattern: new RegExp(
                                                        /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                      ),
                                                      message:
                                                        "Comment can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                    },
                                                  ]}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "5px 15px",
                                                    fontSize: "14px",
                                                    width: "calc(100% - 110px)",
                                                  }}
                                                />
                                              </Form.Group>
                                            )} */}
                                            </div>
                                            {isUnderReviewed && (
                                              <Form.Group
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  flexFlow: "wrap",
                                                  alignItems: "center",
                                                  gridGap: "10px",
                                                  marginBottom: "20px",
                                                  paddingTop: "6px",
                                                  paddingBottom: "30px",
                                                }}
                                              >
                                                <h6
                                                // style={{
                                                //   margin: "0",
                                                //   width: "130px",
                                                // }}
                                                >
                                                  Review Notes:
                                                </h6>
                                                <Form.Control
                                                  className="memorise-description"
                                                  required
                                                  type="text"
                                                  id={item.id}
                                                  placeholder="Add Comment"
                                                  value={
                                                    item.comment
                                                      ? item.comment
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleComment(e, item);
                                                  }}
                                                  onBlur={() =>
                                                    handleBlurComment(
                                                      item?.id,
                                                      item,
                                                      item?.comment
                                                    )
                                                  }
                                                  rules={[
                                                    {
                                                      pattern: new RegExp(
                                                        /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                      ),
                                                      message:
                                                        "Comment can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                    },
                                                  ]}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "7px 10px",
                                                    fontSize: "12px",
                                                    borderRadius: "5px",
                                                    width: "calc(100% - 110px)",
                                                  }}
                                                />
                                              </Form.Group>
                                            )}
                                            <div
                                              className="right-block"
                                              style={{
                                                position: "absolute",
                                                right: "20px",
                                              }}
                                            >
                                              <div className="rightalign">
                                                {item?.baseType === 1 ? (
                                                  <p>
                                                    <strong>Credit:</strong>{" "}
                                                  </p>
                                                ) : (
                                                  <p>
                                                    <strong>Debit:</strong>{" "}
                                                  </p>
                                                )}
                                                <span className={`${item?.baseType === 1 ? "amount-green" : "amount-red"}`}>
                                                  $
                                                {`${item?.amount.toLocaleString(
                                                  "en-US",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}`}
                                                </span>
                                              </div>
                                            </div>
                                            {/* {isUnderReviewed && (
                                            <Form.Group
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <h6
                                              // style={{
                                              //   margin: "0",
                                              //   width: "130px",
                                              // }}
                                              >
                                                Comment:
                                              </h6>
                                              <Form.Control
                                                className="memorise-description"
                                                required
                                                type="text"
                                                id={item.id}
                                                placeholder="Add Comment"
                                                value={
                                                  item.comment
                                                    ? item.comment
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  handleComment(e, item);
                                                }}
                                                onBlur={() =>
                                                  handleBlurComment(
                                                    item?.id,
                                                    item,
                                                    item?.comment
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    pattern: new RegExp(
                                                      /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                    ),
                                                    message:
                                                      "Comment can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                  },
                                                ]}
                                                style={{
                                                  border: "1px solid #d9d9d9",
                                                  padding: "5px 15px",
                                                  fontSize: "14px",
                                                  width: "calc(100% - 140px)",
                                                }}
                                              />
                                            </Form.Group>
                                          )} */}
                                          <hr />
                                                
                                            {((itemId !== item?.id && item?.bank_reconciliation?.length === 1) || (itemId !== item?.id && item?.bank_reconciliation?.length === 1 && splitDiv === false)) &&
                                              (
                                                <div style={{
                                                  width: "100%",
                                                  // textAlign: "end",
                                                }}>
                                                <div style={{
                                                  display: "inline-flex",
                                                  gap: "15px",
                                                  position:  "relative"
                                                }}>
                                                {item?.bank_reconciliation &&
                                                  item?.bank_reconciliation
                                                  ?.length === 1 &&
                                                  item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <Fragment key={index}>
                                                        <Form.Control
                                                          className={
                                                            itemId === item?.id && splitDiv === true
                                                              ? "split"
                                                              : "memorise-description"
                                                          }
                                                          required
                                                          type="text"
                                                          id={data.id}
                                                          placeholder="Term To Memorise"
                                                          value={
                                                            item
                                                              ?.bank_reconciliation[0]
                                                              ?.term ? item
                                                              ?.bank_reconciliation[0]
                                                              ?.term : item?.term?.term
                                                              ? item?.term?.term
                                                              : ""
                                                          }
                                                          onChange={(e) => {
                                                            handleMemoriseDescription(
                                                              e,
                                                              item
                                                            );
                                                          }}
                                                          // onBlur={() =>
                                                          //   handleMemoriseBlurDescription(
                                                          //     data.id,
                                                          //     data,
                                                          //     item,
                                                          //     data?.chart_of_account_id
                                                          //   )
                                                          // }
                                                          disabled={
                                                            !item
                                                              ?.term_id
                                                              ? false
                                                              : true
                                                          }
                                                          rules={[
                                                            {
                                                              pattern:
                                                                new RegExp(
                                                                  /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                                ),
                                                              message:
                                                                "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                            },
                                                          ]}
                                                          style={{
                                                            border:
                                                              "1px solid #d9d9d9",
                                                            padding: "7px 10px",
                                                            fontSize: "12px",
                                                            borderRadius: "5px",
                                                            // width:
                                                            //   "250px",
                                                            paddingRight: "34px"
                                                          }}
                                                        />
                                                        <Button
                                                          variant="secondary"
                                                          className="matching-btn memorise-btn tick-btn"
                                                          disabled={
                                                            item
                                                              ?.bank_reconciliation[0]
                                                              ?.chart_of_account_id &&
                                                            item
                                                              ?.bank_reconciliation[0]
                                                              ?.contact_id &&
                                                            item
                                                              ?.bank_reconciliation[0]
                                                              ?.term &&  
                                                            !item
                                                              ?.term_id
                                                              ? false
                                                              : true
                                                          }
                                                          size="sm"
                                                          onClick={() => {
                                                            handleLeftMemorise(
                                                              item
                                                                ?.bank_reconciliation[0]
                                                                ?.chart_of_account_id,
                                                              item
                                                                ?.bank_reconciliation[0]
                                                                ?.term,
                                                              item
                                                                ?.bank_reconciliation[0]
                                                                ?.job_id,
                                                              item
                                                                ?.bank_reconciliation[0]
                                                                ?.task_id,
                                                              item
                                                                ?.bank_reconciliation[0]
                                                                ?.contact_id,
                                                              item?.description
                                                            );
                                                          }}
                                                        >
                                                          <FontAwesomeIcon
                                                          title="Memorise"
                                                          icon={faCheck}
                                                          />
                                                        </Button>
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                                
                                                </div>
                                                </div>
                                              )
                                            }
                                            <div style={{
                                                  width: "100%",
                                                  // textAlign: "end",
                                                }}>
                                            <CustomTooltip
                                              text={"Split"}
                                              children={
                                                <Button
                                                  // title="Split"
                                                  variant="secondary"
                                                  className="matching-btn split-btn"
                                                  size="sm"
                                                  disabled={handleSplitDisable(
                                                    item
                                                  )}
                                                  onClick={() => {
                                                    handleSplit(item?.id);
                                                  }}
                                                >
                                                  <img
                                                    src={splitIcon}
                                                    alt=""
                                                    style={{ height: "19px" }}
                                                  />
                                                </Button>
                                              }
                                            />
                                            {item.baseType === 0 &&
                                            item.invoice ? (
                                            <CustomTooltip
                                              text={"Download Invoice"}
                                              children={
                                                <Button
                                                  // title="Download invoice"
                                                  variant="secondary"
                                                  className="matching-btn split-btn download-invoice"
                                                  size="sm"
                                                  onClick={() => {
                                                    handleDownload(item);
                                                  }}
                                                >
                                                  <img
                                                    src={downloadIcon}
                                                    alt=""
                                                    style={{ height: "19px" }}
                                                  />
                                                </Button>
                                              }
                                            />
                                            ) : null}
                                            {item.baseType === 0 ? (
                                              <CustomTooltip
                                              text={"Upload Invoice"}
                                              children={
                                                <Button
                                                  // title="Upload Invoice"
                                                  variant="secondary"
                                                  className={`${
                                                    item.invoice
                                                      ? `matching-btn split-btn upload-invoice`
                                                      : `matching-btn split-btn upload-no-invoice`
                                                  }`}
                                                  // className="matching-btn split-btn upload-invoice"
                                                  size="sm"
                                                  onClick={() => {
                                                    handleUploadInvoice(item?.id);
                                                  }}
                                                >
                                                  <img
                                                    src={uploadIcon}
                                                    alt=""
                                                    style={{ height: "19px" }}
                                                  />
                                                </Button>
                                              }
                                              />
                                            ) : null}
                                            {/* <ReconcileTransactionActionButton
                                              id={item.id}
                                              businessId={businessId}
                                              type={item.baseType}
                                              reconcileData={reconcileData}
                                              setIsImportLoading={
                                                setIsImportLoading
                                              }
                                              loading={isImportLoading}
                                              handleUploadInvoice={
                                                handleUploadInvoice
                                              }
                                            /> */}
                                          </div></div>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={6}
                                        md={1}
                                        className="matching-btn-section"
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Button
                                          variant="secondary"
                                          className="matching-btn"
                                          disabled={handleReconcileDisable(
                                            item
                                          )}
                                          size="sm"
                                          onClick={() => {
                                            handleReconcile(item.id);
                                          }}
                                        >
                                          Reconcile
                                        </Button>
                                      </Col>
                                      <Col xs={6} md={6}>
                                        <div className="refund-card">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <FontAwesomeIcon
                                                icon={faEllipsisV}
                                                size="1x"
                                              />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              {item && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    sendReview(item.id, item);
                                                  }}
                                                >
                                                  Send For Review
                                                </Dropdown.Item>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <label>Allocation to Accounts</label>

                                          <div
                                            className={"refund-card-content"}
                                          >
                                            {item?.bank_reconciliation &&
                                              item?.bank_reconciliation
                                                ?.length === 2 && (
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexFlow: "wrap",
                                                    alignItems: "center",
                                                    gridGap: "10px",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0",
                                                      width: "100px",
                                                    }}
                                                  ></div>
                                                  {item?.bank_reconciliation?.map(
                                                    (data, index) => {
                                                      return (
                                                        <div
                                                          className="split"
                                                          key={index}
                                                        >
                                                          <h6>{`Split ${
                                                            index + 1
                                                          }`}</h6>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )}

                                            {(itemId === item?.id && splitDiv === true) && (
                                              <div
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  flexFlow: "wrap",
                                                  alignItems: "center",
                                                  gridGap: "10px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0",
                                                    width: "100px",
                                                  }}
                                                ></div>
                                                <div className="split">
                                                  <h6>Split 1</h6>
                                                </div>
                                                <div className="split">
                                                  <h6>Split 2</h6>
                                                </div>
                                              </div>
                                            )}
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <h6
                                                style={{
                                                  margin: "0",
                                                  width: "100px",
                                                }}
                                              >
                                                Account
                                              </h6>
                                              {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length === 2 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <Fragment key={index}>
                                                        <CreatableSelect
                                                          formatCreateLabel={(
                                                            userInput
                                                          ) => (
                                                            <>
                                                              <strong>
                                                                + Add New
                                                                Account{" "}
                                                              </strong>
                                                              '{`${userInput}`}'
                                                            </>
                                                          )}
                                                          style={{
                                                            width: "50%",
                                                            margin: "0",
                                                          }}
                                                          placeholder="Select Account"
                                                          isLoading={
                                                            accountOptions.length ===
                                                            0
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(id) =>
                                                            handleAccountSelection(
                                                              id,
                                                              data.id,
                                                              data,
                                                              item
                                                            )
                                                          }
                                                          value={
                                                            allAccountValue?.find(
                                                              (account) =>
                                                                account?.value ===
                                                                data?.chart_of_account_id
                                                            )
                                                              ? allAccountValue?.find(
                                                                  (account) =>
                                                                    account?.value ===
                                                                    data?.chart_of_account_id
                                                                )
                                                              : ""
                                                          }
                                                          className={
                                                            // eslint-disable-next-line
                                                            item?.bank_reconciliation?.length === 2 || itemId === item?.id && splitDiv === true
                                                              ? "selectpicker split"
                                                              : "selectpicker"
                                                          }
                                                          classNamePrefix="chartofselect"
                                                          options={
                                                            // [...accountOptions.filter((i) => i.type === item?.baseType), { label: <strong>+ Add New Account</strong>, value: "new" }]
                                                            [...accountOptions?.map((element) => {
                                                                return({
                                                                  label: element?.label,
                                                                  options: element?.options
                                                                })                                                        
                                                              })
                                                              , { label: <strong>+ Add New Account</strong>, value: "new" }
                                                            ]
                                                          }
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "grey",
                                                            },
                                                          })}
                                                        />
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                                {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length === 1 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <Fragment key={index}>
                                                        <CreatableSelect
                                                          formatCreateLabel={(
                                                            userInput
                                                          ) => (
                                                            <>
                                                              <strong>
                                                                + Add New
                                                                Account{" "}
                                                              </strong>
                                                              '{`${userInput}`}'
                                                            </>
                                                          )}
                                                          style={{
                                                            width: "50%",
                                                            margin: "0",
                                                          }}
                                                          placeholder="Select Account"
                                                          isLoading={
                                                            accountOptions.length ===
                                                            0
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(id) =>
                                                            handleAccountSelection(
                                                              id,
                                                              data.id,
                                                              data,
                                                              item
                                                            )
                                                          }
                                                          value={
                                                            allAccountValue?.find(
                                                              (account) =>
                                                                account?.value ===
                                                                data?.chart_of_account_id
                                                            )
                                                              ? allAccountValue?.find(
                                                                  (account) =>
                                                                    account?.value ===
                                                                    data?.chart_of_account_id
                                                                )
                                                              : ""
                                                          }
                                                          className={"selectpicker split"}
                                                          classNamePrefix="chartofselect"
                                                          options={
                                                            // [...accountOptions.filter((i) => i.type === item?.baseType), { label: <strong>+ Add New Account</strong>, value: "new" }]
                                                            [...accountOptions?.map((element) => {
                                                                return({
                                                                  label: element?.label,
                                                                  options: element?.options
                                                                })                                                        
                                                              })
                                                              , { label: <strong>+ Add New Account</strong>, value: "new" }
                                                            ]
                                                          }
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "grey",
                                                            },
                                                          })}
                                                        />
                                                        {(itemId === "" || splitDiv === false)&&(
                                                        <Select
                                                          style={{
                                                            width: "50%",
                                                            margin: "0",
                                                          }}
                                                          placeholder="Select GST"
                                                          loading={
                                                            GSTOptions.length ===
                                                            0
                                                              ? true
                                                              : false
                                                          }
                                                          value={
                                                            GSTOptions?.find(
                                                              (val) =>
                                                                val?.value ===
                                                                parseInt(
                                                                  data?.gst
                                                                )
                                                            )
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    parseInt(
                                                                      data?.gst
                                                                    )
                                                                )
                                                              : allAccountValue?.find(
                                                                  (account) =>
                                                                    account?.value ===
                                                                    data?.chart_of_account_id
                                                                )?.tax === 1
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    1
                                                                )
                                                              : allAccountValue?.find(
                                                                (account) =>
                                                                  account?.value ===
                                                                  data?.chart_of_account_id
                                                              )?.tax === 2
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    2
                                                                )
                                                              : allAccountValue?.find(
                                                                (account) =>
                                                                  account?.value ===
                                                                  data?.chart_of_account_id
                                                              )?.tax === 3
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    3
                                                                )
                                                              : allAccountValue?.find(
                                                                  (account) =>
                                                                    account?.value ===
                                                                    data?.chart_of_account_id
                                                                )?.tax === 0
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    0
                                                                )
                                                              : ""
                                                          }
                                                          onChange={(id) =>
                                                            handleGSTSelection(
                                                              id?.value,
                                                              data.id
                                                            )
                                                          }
                                                          className={"selectpicker split"}
                                                          classNamePrefix="chartofselect"
                                                          options={GSTOptions}
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "grey",
                                                            },
                                                          })}
                                                        />
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                )}

                                              {(itemId === item?.id && splitDiv === true)&& (
                                                <>
                                                  {/* <Select
                                                  style={{
                                                    width: "50%",
                                                    margin: "0",
                                                  }}
                                                  placeholder="Select Account"
                                                  loading={
                                                    accountOptions.length === 0
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(id) => {
                                                    setSplitAccountId(id);
                                                    setSplitGst(id?.tax);
                                                  }}
                                                  className="selectpicker split"
                                                  classNamePrefix="chartofselect"
                                                  options={accountOptions}
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: "grey",
                                                    },
                                                  })}
                                                /> */}
                                                  <CreatableSelect
                                                    formatCreateLabel={(
                                                      userInput
                                                    ) => (
                                                      <>
                                                        <strong>
                                                          + Add New Account{" "}
                                                        </strong>
                                                        '{`${userInput}`}'
                                                      </>
                                                    )}
                                                    style={{
                                                      width: "50%",
                                                      margin: "0",
                                                    }}
                                                    placeholder="Select Account"
                                                    isLoading={
                                                      accountOptions.length ===
                                                      0
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(id) => {
                                                      handleSplitAddAccount(id);
                                                    }}
                                                    value={
                                                      allAccountValue?.find(
                                                        (account) =>
                                                          account?.value ===
                                                          splitAccountVal
                                                      )
                                                        ? allAccountValue?.find(
                                                            (account) =>
                                                              account?.value ===
                                                              splitAccountVal
                                                          )
                                                        : ""
                                                    }
                                                    className="selectpicker split"
                                                    classNamePrefix="chartofselect"
                                                    options={
                                                      [...accountOptions?.map((element) => {
                                                          return({
                                                            label: element?.label,
                                                            options: element?.options 
                                                         })                                                        
                                                        })
                                                        , { label: <strong>+ Add New Account</strong>, value: "new" }
                                                      ]
                                                    }
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 0,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "grey",
                                                      },
                                                    })}
                                                  />
                                                </>
                                              )}
                                            </div>
                                            {item?.bank_reconciliation?.length === 2 && (
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <h6
                                                style={{
                                                  margin: "0",
                                                  width: "100px",
                                                }}
                                              >
                                                {" "}
                                              </h6>
                                              {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length > 0 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <Fragment key={index}>
                                                        <Select
                                                          style={{
                                                            width: "50%",
                                                            margin: "0",
                                                          }}
                                                          placeholder="Select GST"
                                                          loading={
                                                            GSTOptions.length ===
                                                            0
                                                              ? true
                                                              : false
                                                          }
                                                          value={
                                                            GSTOptions?.find(
                                                              (val) =>
                                                                val?.value ===
                                                                parseInt(
                                                                  data?.gst
                                                                )
                                                            )
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    parseInt(
                                                                      data?.gst
                                                                    )
                                                                )
                                                              : allAccountValue?.find(
                                                                  (account) =>
                                                                    account?.value ===
                                                                    data?.chart_of_account_id
                                                                )?.tax === 1
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    1
                                                                )
                                                              : allAccountValue?.find(
                                                                (account) =>
                                                                  account?.value ===
                                                                  data?.chart_of_account_id
                                                              )?.tax === 2
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    2
                                                                )
                                                              : allAccountValue?.find(
                                                                (account) =>
                                                                  account?.value ===
                                                                  data?.chart_of_account_id
                                                              )?.tax === 3
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    3
                                                                )
                                                              : allAccountValue?.find(
                                                                  (account) =>
                                                                    account?.value ===
                                                                    data?.chart_of_account_id
                                                                )?.tax === 0
                                                              ? GSTOptions?.find(
                                                                  (val) =>
                                                                    val?.value ===
                                                                    0
                                                                )
                                                              : ""
                                                          }
                                                          onChange={(id) =>
                                                            handleGSTSelection(
                                                              id?.value,
                                                              data.id
                                                            )
                                                          }
                                                          className={
                                                            // eslint-disable-next-line
                                                            item?.bank_reconciliation?.length === 2 || itemId === item?.id && splitDiv === true
                                                              ? "selectpicker split"
                                                              : "selectpicker"
                                                          }
                                                          classNamePrefix="chartofselect"
                                                          options={GSTOptions}
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "grey",
                                                            },
                                                          })}
                                                        />
                                                      </Fragment>
                                                    );
                                                  }
                                                )}

                                              
                                            </div>
                                            )}
                                            
                                            {(itemId === item?.id && splitDiv === true) && (
                                              item?.bank_reconciliation?.map(
                                                (data, index) => {
                                                return (

                                                
                                              <div
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  flexFlow: "wrap",
                                                  alignItems: "center",
                                                  gridGap: "10px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                <h6
                                                  style={{
                                                    margin: "0",
                                                    width: "100px",
                                                  }}
                                                >
                                                  {" "}
                                                </h6>
                                                {console.log(data)}
                                                <>
                                                  <Select
                                                    style={{
                                                      width: "50%",
                                                      margin: "0",
                                                    }}
                                                    placeholder="Select GST"
                                                    loading={
                                                      GSTOptions.length ===
                                                      0
                                                        ? true
                                                        : false
                                                    }
                                                    value={
                                                      GSTOptions?.find(
                                                        (val) =>
                                                          val?.value ===
                                                          parseInt(
                                                            data?.gst
                                                          )
                                                      )
                                                        ? GSTOptions?.find(
                                                            (val) =>
                                                              val?.value ===
                                                              parseInt(
                                                                data?.gst
                                                              )
                                                          )
                                                        : allAccountValue?.find(
                                                            (account) =>
                                                              account?.value ===
                                                              data?.chart_of_account_id
                                                          )?.tax === 1
                                                        ? GSTOptions?.find(
                                                            (val) =>
                                                              val?.value ===
                                                              1
                                                          )
                                                        : allAccountValue?.find(
                                                          (account) =>
                                                            account?.value ===
                                                            data?.chart_of_account_id
                                                        )?.tax === 2
                                                        ? GSTOptions?.find(
                                                            (val) =>
                                                              val?.value ===
                                                              2
                                                          )
                                                        : allAccountValue?.find(
                                                          (account) =>
                                                            account?.value ===
                                                            data?.chart_of_account_id
                                                        )?.tax === 3
                                                        ? GSTOptions?.find(
                                                            (val) =>
                                                              val?.value ===
                                                              3
                                                          )
                                                        : allAccountValue?.find(
                                                            (account) =>
                                                              account?.value ===
                                                              data?.chart_of_account_id
                                                          )?.tax === 0
                                                        ? GSTOptions?.find(
                                                            (val) =>
                                                              val?.value ===
                                                              0
                                                          )
                                                        : ""
                                                    }
                                                    onChange={(id) =>
                                                      handleGSTSelection(
                                                        id?.value,
                                                        data.id
                                                      )
                                                    }
                                                    className={"selectpicker split"}
                                                    classNamePrefix="chartofselect"
                                                    options={GSTOptions}
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 0,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "grey",
                                                      },
                                                    })}
                                                  />
                                                  <Select
                                                    style={{
                                                      width: "50%",
                                                      margin: "0",
                                                    }}
                                                    placeholder="Select GST"
                                                    loading={
                                                      GSTOptions.length === 0
                                                        ? true
                                                        : false
                                                    }
                                                    value={
                                                      GSTOptions?.find(
                                                        (gst) =>
                                                          gst?.value ===
                                                          parseInt(splitGstVal)
                                                      )
                                                        ? GSTOptions?.find(
                                                            (gst) =>
                                                              gst?.value ===
                                                              parseInt(
                                                                splitGstVal
                                                              )
                                                          )
                                                        : ""
                                                    }
                                                    onChange={(id) => {
                                                      setSplitGst(id?.value);
                                                      setSplitGstVal(id?.value);
                                                    }}
                                                    className="selectpicker split"
                                                    classNamePrefix="chartofselect"
                                                    options={GSTOptions}
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 0,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "grey",
                                                      },
                                                    })}
                                                  />
                                                </>
                                                </div>
                                                );  
                                              }
                                              )
                                              )}

                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <h6
                                                style={{
                                                  margin: "0",
                                                  width: "100px",
                                                }}
                                              >
                                                Contact
                                              </h6>
                                              <CreatableSelect
                                                formatCreateLabel={(
                                                  userInput
                                                ) => (
                                                  <>
                                                    <strong>
                                                      + Add New Contact{" "}
                                                    </strong>
                                                    '{`${userInput}`}'
                                                  </>
                                                )}
                                                style={{
                                                  width: "50%",
                                                  margin: "0",
                                                }}
                                                placeholder="Select Contact"
                                                loading={
                                                  allContactList.length === 0 ||
                                                  loadingContactList === true
                                                    ? true
                                                    : false
                                                }
                                                onChange={(id) =>
                                                  handleContactNameSelection(
                                                    id,
                                                    item?.bank_reconciliation[0]
                                                      .id,
                                                    item
                                                      ?.bank_reconciliation[0],
                                                    item?.baseType,
                                                    item
                                                  )
                                                }
                                                value={
                                                  allContactValue?.find(
                                                    (contact) =>
                                                      contact?.value ===
                                                      item
                                                        ?.bank_reconciliation[0]
                                                        ?.contact_id
                                                  )
                                                    ? allContactValue?.find(
                                                        (contact) =>
                                                          contact?.value ===
                                                          item
                                                            ?.bank_reconciliation[0]
                                                            ?.contact_id
                                                      )
                                                    : ""
                                                }
                                                className="selectpicker"
                                                classNamePrefix="chartofselect"
                                                options={allContactList}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,
                                                    primary: "grey",
                                                  },
                                                })}
                                              />
                                            </div>
                                            <Form.Group
                                              style={{
                                                width: "calc(100% - 30px)",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                              className="desc-split"
                                            >
                                              <h6
                                                style={{
                                                  margin: "0",
                                                  width: "100px",
                                                }}
                                              >
                                                Description
                                              </h6>
                                              {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length === 1 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <Fragment key={index}>
                                                        <Form.Control
                                                          className={
                                                            itemId === item?.id && splitDiv === true
                                                              ? "split"
                                                              : "memorise-description"
                                                          }
                                                          required
                                                          type="text"
                                                          id={data.id}
                                                          placeholder="Add Description"
                                                          value={
                                                            data.description
                                                              ? data.description
                                                              : ""
                                                          }
                                                          onChange={(e) => {
                                                            handleDescription(
                                                              e,
                                                              item
                                                            );
                                                          }}
                                                          onBlur={() =>
                                                            handleBlurDescription(
                                                              data.id,
                                                              data,
                                                              item,
                                                              data?.chart_of_account_id
                                                            )
                                                          }
                                                          rules={[
                                                            {
                                                              pattern:
                                                                new RegExp(
                                                                  /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                                ),
                                                              message:
                                                                "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                            },
                                                          ]}
                                                          style={{
                                                            border: "1px solid #d9d9d9",
                                                            padding: "7px 10px",
                                                            fontSize: "12px",
                                                            borderRadius: "5px",
                                                            width:
                                                              "calc(100% - 110px)",
                                                          }}
                                                        />
                                                        {(itemId === "" || splitDiv === false) && (
                                                        <span className="expand-options">
                                                          <span onClick={() => toggleShowJobTask(item?.id)}>
                                                            {showJobTask &&  showTaskJobId === item?.id ? <b><i className="fa fas fa-chevron-down"></i></b> : <b><i className="fa fas fa-chevron-left"></i></b>}
                                                          </span>
                                                        </span>
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                              {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length === 2 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <Fragment key={index}>
                                                        <Form.Control
                                                          className={
                                                            // eslint-disable-next-line
                                                            item?.bank_reconciliation?.length === 2 || itemId === item?.id && splitDiv === true
                                                              ? "split"
                                                              : ""
                                                          }
                                                          required
                                                          type="text"
                                                          id={data.id}
                                                          placeholder="Add Description"
                                                          value={
                                                            data.description
                                                              ? data.description
                                                              : ""
                                                          }
                                                          onChange={(e) => {
                                                            handleDescription(
                                                              e,
                                                              item
                                                            );
                                                          }}
                                                          onBlur={() =>
                                                            handleBlurDescription(
                                                              data.id,
                                                              data,
                                                              item,
                                                              data?.chart_of_account_id
                                                            )
                                                          }
                                                          rules={[
                                                            {
                                                              pattern:
                                                                new RegExp(
                                                                  /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                                ),
                                                              message:
                                                                "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                            },
                                                          ]}
                                                          style={{
                                                            border: "1px solid #d9d9d9",
                                                            padding: "7px 10px",
                                                            fontSize: "12px",
                                                            borderRadius: "5px",
                                                            width:
                                                              "calc(100% - 110px)",
                                                          }}
                                                        />
                                                      </Fragment>
                                                    );
                                                  }
                                                )}

                                              {(itemId === item?.id && splitDiv === true) && (
                                                <Form.Control
                                                  className="split"
                                                  required
                                                  type="text"
                                                  placeholder="Add Description"
                                                  onChange={(e) => {
                                                    setSplitDescription(
                                                      e.target.value
                                                    );
                                                  }}
                                                  rules={[
                                                    {
                                                      pattern: new RegExp(
                                                        /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                      ),
                                                      message:
                                                        "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                                    },
                                                  ]}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "7px 10px",
                                                    fontSize: "12px",
                                                    borderRadius: "5px",
                                                    width: "calc(100% - 110px)",
                                                  }}
                                                />
                                              )}
                                              {((itemId === item?.id && splitDiv === true) || item?.bank_reconciliation
                                                ?.length === 2) && (
                                              <div className="expand-options" style={{ width: "100%" }}>
                                                <span onClick={() => toggleShowJobTask(item?.id)}>
                                                  {showJobTask &&  showTaskJobId === item?.id ? <b><i className="fa fas fa-chevron-down"></i></b> : <b><i className="fa fas fa-chevron-left"></i></b>}
                                                </span>
                                              </div>
                                              )}
                                            </Form.Group>
                                            
                                            {item?.bank_reconciliation &&
                                              item?.bank_reconciliation
                                                ?.length === 2 && (
                                                <Form.Group
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexFlow: "wrap",
                                                    alignItems: "center",
                                                    gridGap: "10px",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <h6
                                                    style={{
                                                      margin: "0",
                                                      width: "100px",
                                                    }}
                                                  >
                                                    Amount
                                                  </h6>
                                                  {item?.bank_reconciliation?.map(
                                                    (data, index) => {
                                                      return (
                                                        <Form.Control
                                                          key={index}
                                                          className="split"
                                                          required
                                                          type="text"
                                                          id={data.id}
                                                          placeholder="Add Amount"
                                                          value={
                                                            data.amount
                                                              ? data.amount
                                                              : ""
                                                          }
                                                          onChange={(e) => {
                                                            handleAmount(
                                                              e,
                                                              item
                                                            );
                                                          }}
                                                          onBlur={() =>
                                                            handleBlurAmount(
                                                              data.id,
                                                              data,
                                                              item,
                                                              data?.chart_of_account_id
                                                            )
                                                          }
                                                          rules={[
                                                            {
                                                              pattern:
                                                                new RegExp(
                                                                  /^([0-9]+$)([.]?)/
                                                                ),
                                                              message:
                                                                "Amount can contain numbers & dot(.)",
                                                            },
                                                          ]}
                                                          style={{
                                                            border:
                                                              "1px solid #d9d9d9",
                                                            padding: "7px 10px",
                                                            fontSize: "12px",
                                                            borderRadius: "5px",
                                                            width:
                                                              "calc(100% - 110px)",
                                                          }}
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </Form.Group>
                                              )}

                                            {(itemId === item?.id && splitDiv === true) && (
                                              <Form.Group
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  flexFlow: "wrap",
                                                  alignItems: "center",
                                                  gridGap: "10px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                <h6
                                                  style={{
                                                    margin: "0",
                                                    width: "100px",
                                                  }}
                                                >
                                                  Amount
                                                </h6>
                                                <Form.Control
                                                  required
                                                  className="split"
                                                  type="text"
                                                  placeholder="Add Amount"
                                                  id={item.id}
                                                  value={
                                                    split1Amount
                                                      ? split1Amount
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    handleSplitCheck(
                                                      e,
                                                      item,
                                                      item.id
                                                    )
                                                  }
                                                  rules={[
                                                    {
                                                      pattern: new RegExp(
                                                        /^([0-9]+$)([.]?)/
                                                      ),
                                                      message:
                                                        "Amount can contain numbers & dot(.)",
                                                    },
                                                  ]}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "7px 10px",
                                                    fontSize: "12px",
                                                    borderRadius: "5px",
                                                    width: "calc(100% - 110px)",
                                                  }}
                                                />

                                                <Form.Control
                                                  required
                                                  className="split"
                                                  type="text"
                                                  placeholder="Add Amount"
                                                  id={item.id}
                                                  value={
                                                    split2Amount
                                                      ? split2Amount
                                                      : ""
                                                    // ChangeData
                                                  }
                                                  onChange={(e) => {
                                                    handleSplitCheck2(
                                                      e,
                                                      item,
                                                      item.id
                                                    );
                                                    // setSplit2Amount(
                                                    //   /^\d*(\.\d{0,2})?$/.test(
                                                    //     e.target.value
                                                    //   ) === true
                                                    //     ? (parseFloat(
                                                    //         e.target.value
                                                    //       )
                                                    //         ? parseFloat(
                                                    //             e.target.value
                                                    //           )
                                                    //         : 0) >
                                                    //       parseFloat(
                                                    //         item?.amount
                                                    //       )
                                                    //       ? item?.amount
                                                    //       : e.target.value
                                                    //       ? e.target.value
                                                    //       : 0
                                                    //     : split2Amount
                                                    // );

                                                    // setSplit1Amount(
                                                    //   /^\d*(\.\d{0,2})?$/.test(
                                                    //     e.target.value
                                                    //   ) === true
                                                    //     ? (parseFloat(
                                                    //         e.target.value
                                                    //       )
                                                    //         ? parseFloat(
                                                    //             e.target.value
                                                    //           )
                                                    //         : 0) >
                                                    //       parseFloat(
                                                    //         item?.amount
                                                    //       )
                                                    //       ? 0.0
                                                    //       : (
                                                    //           parseFloat(
                                                    //             item?.amount
                                                    //           ) -
                                                    //           (parseFloat(
                                                    //             e.target.value
                                                    //           )
                                                    //             ? parseFloat(
                                                    //                 e.target
                                                    //                   .value
                                                    //               )
                                                    //             : 0)
                                                    //         ).toFixed(2)
                                                    //     : split1Amount
                                                    // );
                                                  }}
                                                  rules={[
                                                    {
                                                      pattern: new RegExp(
                                                        /^([0-9]+$)([.]?)/
                                                      ),
                                                      message:
                                                        "Amount can contain numbers & dot(.)",
                                                    },
                                                  ]}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "7px 10px",
                                                    fontSize: "12px",
                                                    borderRadius: "5px",
                                                    width: "calc(100% - 110px)",
                                                  }}
                                                />
                                              </Form.Group>
                                            )}
                                            {/* JOB */}
                                            {(showJobTask && showTaskJobId === item?.id) && 
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <h6
                                                style={{
                                                  margin: "0",
                                                  width: "100px",
                                                }}
                                              >
                                                Job
                                              </h6>
                                              {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length > 0 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <div className={
                                                        // eslint-disable-next-line
                                                        item?.job?.length === 2 || item?.bank_reconciliation?.length !== 1 || itemId === item?.id && splitDiv === true
                                                          ? "reconcilation-clear-select-body split"
                                                          : "reconcilation-clear-select-body"
                                                      }>
                                                      <Fragment key={index}>
                                                        <CreatableSelect
                                                          formatCreateLabel={(
                                                            userInput
                                                          ) => (
                                                            <>
                                                              <strong>
                                                                + Add New Job{" "}
                                                              </strong>
                                                              '{`${userInput}`}'
                                                            </>
                                                          )}
                                                          style={{
                                                            width: "50%",
                                                            margin: "0",
                                                          }}
                                                          placeholder="Select Job"
                                                          loading={
                                                            jobOptions.length ===
                                                            0
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(id) => {
                                                            handleJobSelection(
                                                              id,
                                                              data.id,
                                                              data,
                                                              item
                                                            );
                                                          }}
                                                          value={
                                                            jobOptions?.find(
                                                              (job) =>
                                                                job?.value ===
                                                                data?.job_id
                                                            )
                                                              ? jobOptions?.find(
                                                                  (job) =>
                                                                    job?.value ===
                                                                    data?.job_id
                                                                )
                                                              : ""
                                                          }
                                                          className={
                                                            // eslint-disable-next-line
                                                            item?.job?.length === 2 || item?.bank_reconciliation?.length !== 1 || itemId === item?.id && splitDiv === true
                                                              ? "selectpicker"
                                                              : "selectpicker"
                                                          }
                                                          classNamePrefix="chartofselect"
                                                          options={jobOptions}
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "grey",
                                                            },
                                                          })}
                                                        />
                                                        {data?.job && <FontAwesomeIcon 
                                                          className="clear-button-selection"
                                                          icon={faXmark}
                                                          size="1x"
                                                          onClick={() => clearJobSelection('',data.id)}
                                                        />}
                                                      </Fragment>
                                                    </div>
                                                    );
                                                  }
                                                )}

                                              {(itemId === item?.id && splitDiv === true) && (
                                                <>
                                                  {/* <Select
                                                  style={{
                                                    width: "50%",
                                                    margin: "0",
                                                  }}
                                                  placeholder="Select Account"
                                                  loading={
                                                    accountOptions.length === 0
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(id) => {
                                                    setSplitAccountId(id);
                                                    setSplitGst(id?.tax);
                                                  }}
                                                  className="selectpicker split"
                                                  classNamePrefix="chartofselect"
                                                  options={accountOptions}
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: "grey",
                                                    },
                                                  })}
                                                /> */}
                                                <div className={
                                                        // eslint-disable-next-line
                                                        item?.job?.length === 2 || item?.bank_reconciliation?.length !== 1 || itemId === item?.id && splitDiv === true
                                                          ? "reconcilation-clear-select-body split"
                                                          : "reconcilation-clear-select-body"
                                                      }>
                                                  <CreatableSelect
                                                    formatCreateLabel={(
                                                      userInput
                                                    ) => (
                                                      <>
                                                        <strong>
                                                          + Add New Job{" "}
                                                        </strong>
                                                        '{`${userInput}`}'
                                                      </>
                                                    )}
                                                    style={{
                                                      width: "50%",
                                                      margin: "0",
                                                    }}
                                                    placeholder="Select Job"
                                                    loading={
                                                      jobOptions.length === 0
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(id) => {
                                                      handleSplitAddJob(id);
                                                    }}
                                                    value={
                                                      jobOptions?.find(
                                                        (job) =>
                                                          job?.value ===
                                                          splitJobVal
                                                      )
                                                        ? jobOptions?.find(
                                                            (job) =>
                                                              job?.value ===
                                                              splitJobVal
                                                          )
                                                        : ""
                                                    }
                                                    className="selectpicker"
                                                    classNamePrefix="chartofselect"
                                                    options={jobOptions}
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 0,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "grey",
                                                      },
                                                    })}
                                                  />
                                                  {splitJobVal &&<FontAwesomeIcon 
                                                    className="clear-button-selection"
                                                    icon={faXmark}
                                                    size="1x"
                                                    onClick={() => clearSplitJobSelection()}
                                                  />}
                                                </div>
                                                </>
                                              )}
                                            </div>}

                                            {/* TASK */}
                                            {(showJobTask && showTaskJobId === item?.id) &&
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                flexFlow: "wrap",
                                                alignItems: "center",
                                                gridGap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <h6
                                                style={{
                                                  margin: "0",
                                                  width: "100px",
                                                }}
                                              >
                                                Task
                                              </h6>
                                              {item?.bank_reconciliation &&
                                                item?.bank_reconciliation
                                                  ?.length > 0 &&
                                                item?.bank_reconciliation?.map(
                                                  (data, index) => {
                                                    return (
                                                      <div className={
                                                        // eslint-disable-next-line
                                                        item?.task?.length === 2 || item?.bank_reconciliation?.length !== 1 || itemId === item?.id && splitDiv === true
                                                          ? "reconcilation-clear-select-body split"
                                                          : "reconcilation-clear-select-body"
                                                      }>
                                                      <Fragment key={index}>
                                                        <CreatableSelect
                                                          formatCreateLabel={(
                                                            userInput
                                                          ) => (
                                                            <>
                                                              <strong>
                                                                + Add New Task{" "}
                                                              </strong>
                                                              '{`${userInput}`}'
                                                            </>
                                                          )}
                                                          style={{
                                                            width: "50%",
                                                            margin: "0",
                                                          }}
                                                          placeholder="Select Task"
                                                          loading={
                                                            taskOptions.length ===
                                                            0
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(id) => {
                                                            handleTaskSelection(
                                                              id,
                                                              data.id,
                                                              data,
                                                              item
                                                            );
                                                          }}
                                                          value={
                                                            taskOptions?.find(
                                                              (task) =>
                                                                task?.value ===
                                                                data?.task_id
                                                            )
                                                              ? taskOptions?.find(
                                                                  (task) =>
                                                                    task?.value ===
                                                                    data?.task_id
                                                                )
                                                              : ""
                                                          }
                                                          className={
                                                            // eslint-disable-next-line
                                                            item?.task?.length === 2 || item?.bank_reconciliation?.length !== 1 || itemId === item?.id && splitDiv === true
                                                              ? "selectpicker"
                                                              : "selectpicker"
                                                          }
                                                          classNamePrefix="chartofselect"
                                                          options={taskOptions}
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "grey",
                                                            },
                                                          })}
                                                        />
                                                        {data?.task &&
                                                        <FontAwesomeIcon 
                                                          className="clear-button-selection"
                                                          icon={faXmark}
                                                          size="1x"
                                                          onClick={() => clearTaskSelection('',data.id)}
                                                        />
                                                        }
                                                      </Fragment>
                                                      </div>
                                                    );
                                                  }
                                                )}

                                              {(itemId === item?.id && splitDiv === true) && (
                                                <>
                                                <div className={
                                                        // eslint-disable-next-line
                                                        item?.task?.length === 2 || item?.bank_reconciliation?.length !== 1 || itemId === item?.id && splitDiv === true
                                                          ? "reconcilation-clear-select-body split"
                                                          : "reconcilation-clear-select-body"
                                                      }>
                                                  <CreatableSelect
                                                    formatCreateLabel={(
                                                      userInput
                                                    ) => (
                                                      <>
                                                        <strong>
                                                          + Add New Task{" "}
                                                        </strong>
                                                        '{`${userInput}`}'
                                                      </>
                                                    )}
                                                    style={{
                                                      width: "50%",
                                                      margin: "0",
                                                    }}
                                                    placeholder="Select Task"
                                                    loading={
                                                      taskOptions.length === 0
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(id) => {
                                                      handleSplitAddTask(id);
                                                    }}
                                                    value={
                                                      taskOptions?.find(
                                                        (task) =>
                                                          task?.value ===
                                                          splitTaskVal
                                                      )
                                                        ? taskOptions?.find(
                                                            (task) =>
                                                              task?.value ===
                                                              splitTaskVal
                                                          )
                                                        : ""
                                                    }
                                                    className="selectpicker"
                                                    classNamePrefix="chartofselect"
                                                    options={taskOptions}
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 0,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "grey",
                                                      },
                                                    })}
                                                  />
                                                  {splitTaskVal && <FontAwesomeIcon 
                                                    className="clear-button-selection"
                                                    icon={faXmark}
                                                    size="1x"
                                                    onClick={() => clearSplitTaskSelection()}
                                                  />}
                                                  </div>
                                                </>
                                              )}
                                            </div>}

                                            {(itemId !== item?.id && item?.bank_reconciliation?.length > 1) &&
                                              <div style={{
                                                width: "100%",
                                                textAlign: "end"
                                              }}>
                                              <hr />
                                              <Button
                                              variant="secondary"
                                              className="matching-btn"
                                              disabled={handleReconcileDisable(
                                                item
                                              )}
                                              size="sm"
                                              onClick={() => {
                                                handleReconcile(item.id);
                                              }}
                                              style={{
                                                minWidth: '90px',
                                                minHeight: '25px',
                                                fontSize: '14px'
                                              }}
                                            >
                                              Reconcile
                                            </Button>
                                            </div>
                                            }

                                            {((itemId !== item?.id && item?.bank_reconciliation?.length === 1) || (itemId !== item?.id && item?.bank_reconciliation?.length === 1 && splitDiv === false)) &&
                                              (
                                                <div style={{
                                                  width: "100%",
                                                  // textAlign: "end",
                                                }}>
                                                <hr />
                                                <div style={{
                                                  display: "flex",
                                                  // justifyContent: "space-between",
                                                  justifyContent: "flex-end",
                                                  width: "100%",
                                                }}>
                                                {/* <Button
                                                  variant="secondary"
                                                  className="matching-btn memorise-btn"
                                                  disabled={
                                                    item
                                                      ?.bank_reconciliation[0]
                                                      ?.chart_of_account_id &&
                                                    item
                                                      ?.bank_reconciliation[0]
                                                      ?.description
                                                      ? false
                                                      : true
                                                  }
                                                  size="sm"
                                                  onClick={() => {
                                                    handleMemorise(
                                                      item
                                                        ?.bank_reconciliation[0]
                                                        ?.chart_of_account_id,
                                                      item
                                                        ?.bank_reconciliation[0]
                                                        ?.description,
                                                      item
                                                        ?.bank_reconciliation[0]
                                                        ?.job_id,
                                                      item
                                                        ?.bank_reconciliation[0]
                                                        ?.task_id,
                                                      item
                                                        ?.bank_reconciliation[0]
                                                        ?.contact_id,
                                                      item?.description
                                                    );
                                                  }}
                                                >
                                                  Memorise
                                                </Button> */}
                                                <Button
                                                  variant="secondary"
                                                  className="matching-btn"
                                                  disabled={handleReconcileDisable(
                                                    item
                                                  )}
                                                  size="sm"
                                                  onClick={() => {
                                                    handleReconcile(item.id);
                                                  }}
                                                  style={{
                                                    // marginRight: '10px',
                                                    minWidth: '90px',
                                                    minHeight: '25px',
                                                    fontSize: '14px'
                                                  }}
                                                >
                                                  Reconcile
                                                </Button>
                                                </div>
                                                </div>
                                              )}

                                            {(itemId === item?.id && splitDiv === true) && (
                                              <>
                                                <Form.Group
                                                  style={{
                                                    display: "flex",
                                                    flexFlow: "wrap",
                                                    alignItems: "center",
                                                    gridGap: "10px",
                                                    marginLeft: "auto",
                                                  }}
                                                  className="action-box"
                                                >
                                                  <Button
                                                    onClick={() => {
                                                      handleSubmitSplit(
                                                        item?.id,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.id,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.contact_id,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.description,
                                                        item
                                                          ?.bank_reconciliation[0]
                                                          ?.chart_of_account_id,
                                                        splitAccountVal,
                                                        splitGst,
                                                        splitDescription,
                                                        split2Amount,
                                                        split1Amount
                                                      );
                                                    }}
                                                    disabled={handleSplitOkDisable(
                                                      item
                                                    )}
                                                    style={{
                                                      minWidth: '60px',
                                                      minHeight: '25px',
                                                      fontSize: '14px'
                                                    }}
                                                  >
                                                    Ok
                                                  </Button>
                                                  <Button
                                                    className="reset btn btn-primary"
                                                    onClick={() => {
                                                      setItemId("");
                                                      setSplitAccountId("");
                                                      setSplitGst("");
                                                      setSplitDescription("");
                                                      setSplit2Amount("");
                                                      setSplit1Amount("");
                                                    }}
                                                    style={{
                                                      minWidth: '83px',
                                                      minHeight: '25px',
                                                      fontSize: '14px'
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </Form.Group>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Container>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {view === "list" && (
                        <div
                          className={
                            isUnderReviewed
                              ? "reconcile-transactions-list under-reviewed"
                              : "reconcile-transactions-list"
                          }
                        >
                          {exportloading && <Spin />}
                          {loadingForList && <Spin />}
                          <BootstrapTable
                            keyField="key"
                            remote
                            data={listViewData}
                            columns={columns}
                            expandRow={expandRow}
                            // onTableChange={handleTableChange}
                            noDataIndication="No Data Found"
                          />
                          <Pagination
                            total={total}
                            limit={parseInt(limit)}
                            currentPage={page}
                            updateLimit={handleUpdateLimit}
                            updatePage={handleChangePage}
                            from={from}
                            to={to}
                          />
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InfinityScrollComponent>

      {showDelete && (
        <ReconcileTransactionDelete
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          itemId={bankTransactionId}
          showDelete={showDelete}
        />
      )}
      {addNewContact && (
        <AddNewContactModal
          addNewContact={addNewContact}
          handleAddNewContact={handleAddNewContact}
          handleContactList={handleContactList}
          contactType={contactType}
          businessId={businessId}
          lastName={lastName}
          handleContactNameSelection={handleAddContactNameSelection}
          addContactItemID={addContactItemID}
          addContactItem={addContactItem}
          bankTransactionData={bankTransactionData}
        />
      )}
      {addNewAccount && (
        <AddNewAccountModal
          addNewAccount={addNewAccount}
          handleAddNewAccount={handleAddNewAccount}
          addAccountItemID={addAccountItemID}
          accountName={accountName}
          businessId={businessId}
          addAccountItem={addAccountItem}
          handleNewAccountSelection={handleNewAccountSelection}
          bankTransactionData={bankTransactionData}
          handleAccountChange={handleAccountChange}
          setSplitAccountId={setSplitAccountVal}
          setSplitGst={setSplitGstVal}
          setSplitAccountId2={setSplitAccountId}
          setSplitGst2={setSplitGst}
          handleSplitAddAccount={handleSplitAddAccount}
        />
      )}
      {/* //TODO: add model like above. {addNewJob && (<AddNewJobModal)} */}
      {addNewJob && (
        <AddNewJobModal
          showNewJob={addNewJob}
          handleAddNewJob={handleAddNewJob}
          addJobItemID={addJobItemID}
          jobName={jobName}
          businessId={businessId}
          addJobItem={addJobItem}
          handleNewJobSelection={handleNewJobSelection}
          bankTransactionData={bankTransactionData}
          handleJobChange={handleJobChange}
          setSplitJobId={setSplitJobVal}
          handleSplitAddJob={handleSplitAddJob}
        />
      )}

      {/* //TODO: add model like above. {addNewTask && (<AddNewTaskModal)} */}
      {addNewTask && (
        <AddNewTaskModal
          showNewTask={addNewTask}
          handleAddNewTask={handleAddNewTask}
          addTaskItemID={addTaskItemID}
          taskName={taskName}
          businessId={businessId}
          addTaskItem={addTaskItem}
          handleNewTaskSelection={handleNewTaskSelection}
          bankTransactionData={bankTransactionData}
          handleTaskChange={handleTaskChange}
          setSplitTaskId={setSplitTaskVal}
          handleSplitAddTask={handleSplitAddTask}
        />
      )}

      {/* Modal of the Refresh */}
      <Modal
        size="lg"
        show={showRefresh}
        onHide={toggleRefreshModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section fetch-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Fetch Bank Feeds
        </Modal.Header>
        <Modal.Body>
          {(loading || openYodleeLoading) && <Spin />}
          <div className="modal-body">
           <div className="date-picker-refresh">
            <>
            <div>
            <p>Account</p>
            <Select 
              style={{
                width: "50%",
                margin: "0",
              }}
              placeholder="Select Account"
              value={accountFetchId ? (accountFetchOptions ? accountFetchOptions?.find((i) => i.value === accountFetchId) : '') : (accountFetchOptions ? accountFetchOptions[0] : '')}
              onChange={(item) => {
                handleAccountFetchChange(item);
              }}
              loading={loading}
              classNamePrefix="account_select_bank"
              options={accountFetchOptions}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              />
              </div>
            <Form>
                <p>Select Date Range</p>
                <DateRangePicker
                  placeholder="Select Date Range"
                  onChange={(e) => handleDateRange(e)}
                  format="dd-MM-yyyy"
                  value={dateValue}
                  placement="bottomEnd"
                  shouldDisableDate={afterToday()}
                  ranges={[]}
                  // showOneCalendar
                />
              </Form>
            </>
              {/* <h5>Select Date Range</h5> */}
            
           </div>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                if(accountMFA === 1){
                  handleYodleeRefreshControl(accountYodleeId)
                } else {
                  handleRefresh();
                }
              }}
            >
              Fetch
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleRefreshModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Upload Invoice
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <UploadFile
              isMultiple={true}
              isLoading={isImportLoading}
              setFiles={setFiles}
              files={files}
              handleUpload={handleUpload}
              handleCancel={handleClosemodal}
              acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg, text/jpg, image/png"
              ErrorMessage="xls, xlsx, docx, pdf, jpg, jpeg and png"
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>Import</Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <UploadFile
              isMultiple={true}
              isLoading={false}
              handleFileChange={""}
              handleUpload={""}
              handleCancel={""}
            />
          </div>
        </Modal.Body>
      </Modal> */}
      {showMemoriseModal &&
        <Modal
          size="lg"
          show={showMemoriseModal}
          onHide={toggleMemoriseModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Term To Memorise
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
             <Form.Control
              required
              type="text"
              id={memoriseDataList?.bank_reconciliation[0].id}
              placeholder="Term To Memorise"
              value={
                memoriseDataList
                  ?.bank_reconciliation[0]
                  ?.term ? memoriseDataList
                  ?.bank_reconciliation[0]
                  ?.term : memoriseDataList?.term?.term
                  ? memoriseDataList?.term?.term
                  : ""
              }
              onChange={(e) => {
                handleMemoriseDescription(e, memoriseDataList);
              }}
              disabled={ 
                !memoriseDataList
                ?.term_id ? false : true}
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={
                  memoriseDataList?.bank_reconciliation[0]
                  ?.chart_of_account_id &&
                  memoriseDataList
                  ?.bank_reconciliation[0]
                  ?.term &&
                  memoriseDataList?.bank_reconciliation[0]
                  ?.contact_id &&
                  !memoriseDataList
                  ?.term_id ? false : true}
                onClick={() => {
                  handleLeftListMemorise(
                    memoriseDataList?.bank_reconciliation[0]
                      ?.chart_of_account_id,
                    memoriseDataList?.bank_reconciliation[0]
                      ?.term,
                    memoriseDataList?.bank_reconciliation[0]?.job_id,
                    memoriseDataList?.bank_reconciliation[0]?.task_id,
                    memoriseDataList?.bank_reconciliation[0]?.contact_id,
                    memoriseDataList?.description
                  );
                }}
              >
                Memorise
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      }
      <Modal
        size="lg"
        show={openYodlee}
        onHide={handleYodleeModal}
        dialogClassName="modal-50w medium-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Auto Bank Feeds Account Integration
        </Modal.Header>
        <Modal.Body>
          <YodleeConnectModal
            handleYodleeModal={handleYodleeModal}
            fastLinkData={fastLinkData}
            businessId={businessId}
            handleProviderBank={handleProviderBank}
            setOpenYodleeLoading={setLoading}
            handleSucess={handleRefresh}
            accountFetchId={accountFetchId}
            setBankAccountId={setBankAccountId}
            providerBankData={providerBankData}
            isYodleeConnect={isYodleeConnect}
            disconnectModal={disconnectModal}
          />
        </Modal.Body>
      </Modal> 
    </div>
  );
};

export default ReconcileTransactions;
