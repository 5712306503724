import { API, get } from "../../../../../config";

export const getSearchTermDetail = async (searchTermId) => {
  try {
    const { data } = await get(`${API.EDIT_TERM}/` + searchTermId);
    return data.data;
  } catch (e) {
    return null;
  }
};
