import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EditCashReceipts from '../EditCashReceipts';
import CashReceiptDelete from './CashReceiptDelete';
import CashReceiptDetailPopup from './CashReceiptDetailPopup';

const CashReceiptActionButton = ({ id, handleDelete, fetchReceipts }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const params = useParams();
  const businessId = params?.business_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleEditModal = () => {
    setShowEdit(!showEdit);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  return (
    <>
      <span>
        <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#cashReceiptDetail"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
          onClick={toggleEditModal}
          data-toggle="modal"
          data-target="#editCashReceipt"
        />
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteCashReceipt"
        />
      </span>

      {showDelete && (
        <CashReceiptDelete
          cash_receiptId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}

      {showEdit && (
        <EditCashReceipts
          cash_receiptId={id}
          businessId={businessId}
          toggleEditModal={toggleEditModal}
          fetchReceipts={fetchReceipts}
          showEdit={showEdit}
        />
      )}

      {showView && (
        <CashReceiptDetailPopup
          cash_receiptId={id}
          toggleShowModal={toggleShowModal}
          showView={showView}
        />
      )}
    </>
  );
};

export default CashReceiptActionButton;
