import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootStrapTable from "react-bootstrap-table-next";
import Pagination from "../common/Pagination";
import Spin from "../common/Spin";
import { debounce } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { API, get, metaTitle } from "../../config";
import { toast } from "react-toastify";
import { formatMobile, formatName, formatPhone } from "../common/Misc";
import moment from "moment";
import TitleBar from "../common/TitleBar";

const RegisteredUsersForAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [registeredUserList, setRegisteredUserList] = useState([]);
  const [registeredUserLoading, setRegisteredUserLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || '');
  const [searchParamData, setSearchParamData] = useState(search || '');
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const fetchRegisteredUser = useCallback(async () => {
    try {
      setRegisteredUserLoading(true);
      const { data } = await get(
        `${API.GET_REGISTERED_USER_FOR_ADMIN}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ''}&sort_column=${
          sortField ? sortField : ''
        }&sort_order=${sortOrder ? sortOrder : ''}`
      );
      setRegisteredUserLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const registeredUserList = data.data.data;
      setRegisteredUserList(registeredUserList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setRegisteredUserLoading(false);
      setRegisteredUserList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [limit, page, searchParam, sortOrder, sortField]);

  const registeredUsersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: registeredUserList[i]?.id,
        id: registeredUserList[i]?.id ? registeredUserList[i]?.id : '',
        name: formatName(registeredUserList[i]?.first_name, registeredUserList[i]?.last_name),
        phone: registeredUserList[i]?.phone ? formatPhone(registeredUserList[i]?.phone) : '',
        mobile: registeredUserList[i]?.mobile ? formatMobile(registeredUserList[i]?.mobile) : '',
        role: registeredUserList[i]?.role?.name ? registeredUserList[i]?.role?.name : '',
        registered_date: registeredUserList[i]?.created_at
          ? moment(registeredUserList[i]?.created_at, 'DD-MM-YYYY HH:mm:SS').format('DD-MM-YYYY')
          : '',
        email: registeredUserList[i]?.email
      });
    }
    return items;
  };
  const registeredUsers = registeredUsersGenerator(registeredUserList?.length);

  useEffect(() => {
    fetchRegisteredUser();
    window.document.title = `${metaTitle} | Registered Users`;
  }, [fetchRegisteredUser]);

  useEffect(() => {
    navigate(
      `/admin/registered-users?limit=${limit ? limit : 10}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ''
      }&sort_column=${sortField ? sortField : ''}&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  const columns = [
    {
      dataField: 'name',
      text: 'Name'
      //   sort: true,
    },
    {
      dataField: 'email',
      text: 'Email'
      //   sort: true,
    },
    {
      dataField: 'phone',
      text: 'Phone No.'
      //   sort: true,
    },
    {
      dataField: 'mobile',
      text: 'Mobile No.'
      //   sort: true,
    },
    {
      dataField: 'role',
      text: 'Role'
    },
    {
      dataField: 'registered_date',
      text: 'Registered Date'
    }
    // {
    //   dataField: "action",
    //   text: "Action",
    //   className: "text-center",
    //   style: { width: "127px" },
    // },
  ];

  // const onSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchParam(value);
  //   setPage(1);
  // };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if (nextValue?.length >= 2 || nextValue?.length === 0) {
      debouncedSave(nextValue);
    }
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar
            label={"Registered Users"}
            businessNameData={false}
            globalSearch={false}
          />
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={handleSearchChange}
                    type="text"
                    value={searchParamData}
                    placeholder="Search Registered User"
                  />
                  {searchParamData ? (
                    <Button
                      className="btn-close"
                      onClick={() => {
                        setSearchParamData('');
                        setSearchParam('');
                      }}
                    >
                      x
                    </Button>
                  ) : (
                    <Button className="fa fa-search">Search</Button>
                  )}
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  variant="primary"
                  //   onClick={() => navigate("/add-client")}
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="custom-table">
              {registeredUserLoading && <Spin />}
              <BootStrapTable
                keyField="id"
                remote
                data={registeredUsers}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredUsersForAdmin;
