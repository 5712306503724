import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API, get } from "../../../../config";

export const getClientDetail = async (clientId) => {
  try {
    const { data } = await get(`${API.CLIENT_DETAILS}/` + clientId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const getClientPopupDetail = async (clientId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.CLIENT_DETAILS}/${clientId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.CLIENT_DETAILS}/${clientId}`);
      return data.data;
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(
        data.errors && data.errors.myna_error && data.errors.myna_error[0]
      );
    }
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};

export const useSuburbList = () => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState) {
        setSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}?state=${selectedState}`
        );
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
      setSuburbLoading(false);
    }
  }, [selectedState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSuburbList([]);
      setSelectedState(value);
    },
    suburbLoading,
    setSuburbList,
    selectedState,
  };
};

export const useSuburbListE = (id) => {
  const [suburbList, setSuburbList] = useState([]);
  const [shippingSuburbList, setShippingSuburbList] = useState([]);
  const [suburbLoading, setSuburbLoading] = useState(false);
  const [suburbLoading2, setSuburbLoading2] = useState(false);
  const init = useCallback(async () => {
    try {
      if (id) {
        setSuburbLoading(true);
        setSuburbLoading2(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}?state=${id}`);
        setSuburbLoading(false);
        setSuburbLoading2(false);
        setSuburbList(data.data);
        setShippingSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
      setShippingSuburbList([]);
      setSuburbLoading(false);
      setSuburbLoading2(false);
    }
  }, [id]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    suburbLoading,
    suburbLoading2,
    shippingSuburbList,
    setSuburbList,
    setShippingSuburbList,
  };
};

export const useShippingSuburbList = () => {
  const [shippingSuburbList, setShippingSuburbList] = useState([]);
  const [selectedShippingState, setSelectedShippingState] = useState("");
  const [shippingSuburbLoading, setShippingSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedShippingState) {
        setShippingSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}?state=${selectedShippingState}`
        );
        setShippingSuburbLoading(false);
        setShippingSuburbList(data.data);
      }
    } catch (e) {
      setShippingSuburbList([]);
      setShippingSuburbLoading(false);
    }
  }, [selectedShippingState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    shippingSuburbList,
    handleStateSelect1: (value) => {
      setShippingSuburbList([]);
      setSelectedShippingState(value);
    },
    shippingSuburbLoading,
    setShippingSuburbList,
    selectedShippingState,
  };
};

export const useOfficeList = () => {
  const [officeList, setOfficeList] = useState([]);
  const [officeLoading, setOfficeLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setOfficeLoading(true);
      const { data } = await get(`${API.GET_OFFICE_LIST}?page=1&limit=-1`);
      setOfficeLoading(false);
      setOfficeList(data.data.offices.data);
    } catch (e) {
      setOfficeList([]);
      setOfficeLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeLoading,
  };
};

export const useManagerList = () => {
  const [managerList, setManagerList] = useState([]);
  const [managerLoading, setManagerLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setManagerLoading(true);
      const { data } = await get(
        `${API.GET_STAFF_MANAGER_LIST}?page=1&type=manager&limit=-1`
      );
      setManagerLoading(false);
      setManagerList(data.data.users.data);
    } catch (e) {
      setManagerList([]);
      setManagerLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    managerList,
    managerLoading,
  };
};

export const useStaffList = () => {
  const [staffList, setStaffList] = useState([]);
  const [staffLoading, setStaffLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStaffLoading(true);
      const { data } = await get(
        `${API.GET_STAFF_MANAGER_LIST}?page=1&type=staff&limit=-1`
      );
      setStaffLoading(false);
      setStaffList(data.data.users.data);
    } catch (e) {
      setStaffList([]);
      setStaffLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    staffList,
    staffLoading,
  };
};
