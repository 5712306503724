import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import {
  API,
  entityTypes,
  fileUpload,
  imageDefaultPrefixPath,
} from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import { AddBusinessSchema } from "../../common/Validation";
import { useStateList, useSuburbListE } from "./components/BusinessHelper";
import { Modal } from "react-bootstrap";
// import { useOfficeList, useUserList } from "../../Users/components/UserHelper";
// import { formatName, formatedAddress } from "../../common/Misc";

const AddBusiness = ({ toggleBusinessModal, clientId, showBusiness, setBusinessList }) => {
  const [loading, setLoading] = useState(false);
  const [businessData, setBusinessData] = useState({});
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // function padWithLeadingZeros(num, totalLength) {
  //   return String(num).padStart(totalLength, '0');
  // }

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.logo = value?.image ? value?.image : value?.logo ? value?.logo : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";
    // value.bsb = value?.bsb ? `${value?.bsb}`.replace(/-/g, "") : "";s
    // if(value?.accountNumber?.length < 9 && value?.accountNumber){
    //   value.accountNumber = padWithLeadingZeros(value?.accountNumber, 9);
    // }
    value.entity_type = value?.entity_type?.value;

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.value;

    formData.append("client_id", clientId ? clientId : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "ref_number",
      value?.ref_number ? value?.ref_number : ""
    );
    formData.append(
      "head_client",
      value?.head_client ? 1 : 0
    );
    formData.append("email", value?.email ? value?.email : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("abn", value?.abn ? value?.abn : "");
    formData.append(
      "entity_type",
      value?.entity_type ? value?.entity_type : ""
    );
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append("logo", value?.logo ? value?.logo : "");
    formData.append(
      "account_name",
      value?.accountName ? value?.accountName : ""
    );
    formData.append(
      "account_number",
      value?.accountNumber ? value?.accountNumber : ""
    );
    formData.append(
      "bsb",
      value?.bsb ? value?.bsb : ""
    );

    if(selectedOffices){
      if(selectedOffices.length > 0){
        for(let i = 0; i < selectedOffices.length; i++){
          formData.append(`office_ids[${i}]`, selectedOffices[i]);
        }
      }
    }

    if(selectedUsers){
      if(selectedUsers.length > 0){
        for(let i = 0; i < selectedUsers.length; i++){
          formData.append(`user_ids[${i}]`, selectedUsers[i]);
        }
      }
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BUSINESS_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        setBusinessList([]);
        setSelectedOffices([]);
        setSelectedUsers([]);
        toggleBusinessModal();
        // navigate("/clients");
      }
      return data?.data;
    } catch (error) {
      setBusinessData(value);
      setLoading(false);
      setSelectedOffices([])
      setSelectedUsers([])
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  // const { stateLoading, stateList = [] } = useStateList();
  // const stateListing = stateList?.map((item) => {
  //   const { name: label, id: value, ...rest } = item;
  //   return { label, value, ...rest };
  // });

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === businessData?.state_id
  );

  // Suburb
  const { suburbLoading, suburbList = [] } = useSuburbListE(
    businessData?.state_id
  );
  const suburbListing = suburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const suburb = suburbListing?.find(
    (item) => item?.value === businessData?.suburb_id
  );

  // //Office List
  // const { officeLoading, officeList = [] } = useOfficeList(clientId);
  // const officeListing = officeList?.map((item) => {
  //   const name = formatedAddress(item?.street, item?.suburb?.name, item?.state?.short_name, item?.postal_code);
  //   const id = item?.id;
  //   const items = { name, id };
  //   const { name: label, id: value, ...rest } = items;
  //   return { label, value, ...rest };
  // });

  // //User List
  // const { userLoading, userList = [] } = useUserList(clientId);
  // const userListing = userList?.map((item) => {
  //   const name = formatName(item?.first_name, item?.last_name);
  //   const id = item?.client_user?.id;
  //   const items = { name, id };
  //   const { name: label, id: value, ...rest } = items;
  //   return { label, value, ...rest };
  // });

  return (
    <>
      <Modal
        size="lg"
        show={showBusiness}
        onHide={toggleBusinessModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Business
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              business_name: businessData?.business_name,
              ref_number: businessData?.ref_number,
              head_client: businessData?.head_client ? 1 : 0,
              abn: businessData?.abn,
              mobile: businessData?.mobile,
              phone: businessData?.phone,
              email: businessData?.email,
              entity_type: entityTypes?.find(
                (item) => item?.value === businessData?.entity_type
              ),
              street: businessData?.street,
              postal_code: businessData?.postal_code,
              state_id: state,
              suburb_id: suburb,
              logo: businessData?.logo
                ? imageDefaultPrefixPath + businessData?.logo
                : "",
              accountName: businessData?.account_name ? businessData?.account_name : '',
              accountNumber: businessData?.account_number ? businessData?.account_number : '',
              bsb: businessData?.bsb ? businessData?.bsb : '',
              office_ids: [],
              user_ids: [],
            }}
            // enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddBusinessSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleBusinessModal={toggleBusinessModal}
                    stateListing={stateListing}
                    stateLoading={stateLoading}
                    suburbLoading={suburbLoading}
                    // officeListing={officeListing}
                    // officeLoading={officeLoading}
                    setSelectedOffices={setSelectedOffices}
                    selectedOffices={selectedOffices}
                    clientId={clientId}
                    // userListing={userListing}
                    // userLoading={userLoading}
                    setSelectedUsers={setSelectedUsers}
                    selectedUsers={selectedUsers}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBusiness;
