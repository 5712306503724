import {
  faCircleCheck,
  faDollarSign,
  faDownload,
  faEdit,
  faEye,
  faFileCirclePlus,
  faPaperPlane,
  faPlus,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import ClientDelete from "./ClientDelete";
import CustomTooltip from '../../../../common/CustomTooltip';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spin from '../../../../common/Spin';
import { API, deleteCall, get, s3DefaultPath } from '../../../../../config';
import ViewInvoice from './ViewInvoice';
import axios from 'axios';
import AddCreditNote from '../CreditNote/AddCreditNote';
import CreditNote from '../CreditNote/CreditNote';

const InvoiceActionButton = ({
  id,
  handleDelete,
  email,
  status,
  send,
  businessData,
  numberOption,
  is_finalized,
  file,
  lastCreditNumber,
  total_amount_due,
  invoice_number,
  invoice_total,
  fetchInvoices,
  invoice
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showInvoiceSend, setShowInvoiceSend] = useState(false);
  const [showInvoiceFinalized, setShowInvoiceFinalized] = useState(false);
  const [showInvoiceReSend, setShowInvoiceReSend] = useState(false);
  const [invoiceSendLoading, setInvoiceSendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCreditNote, setShowCreditNote] = useState(false);
  const [showCreditNoteList, setShowCreditNoteList] = useState(false);

  const params = useParams();
  const businessId = params?.business_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${s3DefaultPath}${path}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var file = path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'new_aba.aba');
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error('File is not exist.');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const toggleInvoiceReSendModal = () => {
    setShowInvoiceReSend(!showInvoiceReSend);
  };

  const toggleInvoiceSendModal = () => {
    setShowInvoiceSend(!showInvoiceSend);
  };

  const toggleInvoiceFinalizedModal = () => {
    setShowInvoiceFinalized(!showInvoiceFinalized);
  };

  const toggleCreditNoteModal = () => {
    setShowCreditNote(!showCreditNote);
  };

  const toggleCreditNoteListModal = () => {
    setShowCreditNoteList(!showCreditNoteList);
  };

  const handleResendInvoice = async () => {
    try {
      setInvoiceSendLoading(true);
      const { data } = await get(
        `${API.INVOICE_SEND}/${id}?client_business_id=${businessId}&send=1`
      );
      setInvoiceSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleInvoiceReSendModal();
      }
      return data?.data;
    } catch (error) {
      setInvoiceSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setInvoiceSendLoading(false);
      } else {
        toast.error(error?.message);
        setInvoiceSendLoading(false);
      }
    }
  };

  const handleSendInvoice = async () => {
    try {
      setInvoiceSendLoading(true);
      const { data } = await get(
        `${API.INVOICE_SEND}/${id}?client_business_id=${businessId}&send=1`
      );
      setInvoiceSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleInvoiceSendModal();
      }
      return data?.data;
    } catch (error) {
      setInvoiceSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setInvoiceSendLoading(false);
      } else {
        toast.error(error?.message);
        setInvoiceSendLoading(false);
      }
    }
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleInvoiceDelete = async () => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.INVOICES_DELETE}/${id}`);
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleInvoiceFinalized = async () => {
    try {
      setInvoiceSendLoading(true);
      const { data } = await get(`${API.INVOICES_ADD}/${id}?is_finalized=1`);
      setInvoiceSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleInvoiceFinalizedModal();
      }
      return data?.data;
    } catch (error) {
      setInvoiceSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setInvoiceSendLoading(false);
      } else {
        toast.error(error?.message);
        setInvoiceSendLoading(false);
      }
    }
  };

  return (
    <>
      <span>
        <CustomTooltip
          text={'View'}
          children={
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#clientDetail"
            />
          }
        />
        {parseInt(is_finalized) === 0 && total_amount_due ? (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Edit'}
              children={
                <Link
                  to={{
                    pathname: `/business/${businessId}/edit-invoice`,
                    search: `?id=${id}&number_option=${numberOption}`
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </Link>
              }
            />
          </>
        ) : (
          total_amount_due ? (
            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <CustomTooltip
                text={'Enter Receipt'}
                children={
                  <Link
                    to={{
                      pathname: `/business/${businessId}/edit-invoice`,
                      search: `?id=${id}&number_option=${numberOption}`
                    }}
                  >
                    <FontAwesomeIcon icon={faDollarSign} size="1x" />
                  </Link>
                }
              />
            </>
          ) : (<></>)
        )}
        {file && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Download Invoice'}
              children={
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  onClick={() => {
                    downloadLetter(file?.file);
                  }}
                />
              }
            />
          </>
        )}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip
          text={"Delete"}
          children={
            <FontAwesomeIcon
              onClick={toggleDeleteModal}
              icon={faTrash}
              size="1x"
              data-toggle="modal"
              data-target="#deleteClient"
            />
          }
        /> */}
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        {parseInt(send) === 0 ? (
          <CustomTooltip
            text={'Send Invoice'}
            children={
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="1x"
                onClick={() => toggleInvoiceSendModal()}
              />
            }
          />
        ) : (
          <CustomTooltip
            text={'Resend Invoice'}
            children={
              <FontAwesomeIcon
                icon={faShareSquare}
                size="1x"
                onClick={() => toggleInvoiceReSendModal()}
              />
            }
          />
        )}
        {parseInt(is_finalized) === 0 ? (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Finalise'}
              children={
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  size="1x"
                  onClick={() => toggleInvoiceFinalizedModal()}
                />
              }
            />
          </>
        ) : (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Finalise'}
              children={<FontAwesomeIcon icon={faCircleCheck} size="1x" className="rag-green" />}
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Add Credit Note'}
              children={
                <FontAwesomeIcon icon={faPlus} size="1x" onClick={() => toggleCreditNoteModal()} />
              }
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Credit Notes'}
              children={
                <FontAwesomeIcon
                  icon={faFileCirclePlus}
                  size="1x"
                  onClick={() => toggleCreditNoteListModal()}
                />
              }
            />
          </>
        )}
      </span>

      {showDelete && (
        <Modal
          size="lg"
          show={showDelete}
          onHide={toggleDeleteModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Delete Invoice
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">Are you sure you want to delete this invoice?</div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleInvoiceDelete();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showView && (
        <ViewInvoice
          showView={showView}
          id={id}
          toggleShowModal={toggleShowModal}
          businessData={businessData}
          numberOption={numberOption}
        />
      )}

      {showInvoiceReSend && (
        <Modal
          size="lg"
          show={showInvoiceReSend}
          onHide={toggleInvoiceReSendModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Resend Invoice
          </Modal.Header>
          <Modal.Body>
            {invoiceSendLoading && <Spin />}
            <div className="modal-body">Do you want to resend invoice this to {email}?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleResendInvoice();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleInvoiceReSendModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showInvoiceSend && (
        <Modal
          size="lg"
          show={showInvoiceSend}
          onHide={toggleInvoiceSendModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Send Invoice
          </Modal.Header>
          <Modal.Body>
            {invoiceSendLoading && <Spin />}
            <div className="modal-body">Do you want to send invoice this to {email}?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleSendInvoice();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleInvoiceSendModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showInvoiceFinalized && (
        <Modal
          size="lg"
          show={showInvoiceFinalized}
          onHide={toggleInvoiceFinalizedModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Finalise Invoice
          </Modal.Header>
          <Modal.Body>
            {invoiceSendLoading && <Spin />}
            <div className="modal-body">Do you want to finalise this invoice?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleInvoiceFinalized();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleInvoiceFinalizedModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showCreditNote && (
        <Modal
          size="lg"
          show={showCreditNote}
          onHide={toggleCreditNoteModal}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section new-transaction-modal"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Add Credit Note
          </Modal.Header>
          <Modal.Body>
            <AddCreditNote
              id={id}
              toggleCreditNoteModal={toggleCreditNoteModal}
              lastCreditNumber={lastCreditNumber}
              total_amount_due={total_amount_due}
              invoice_number={invoice_number}
              invoice_total={invoice_total}
              fetchInvoices={fetchInvoices}
              invoice={invoice}
            />
          </Modal.Body>
        </Modal>
      )}
      {showCreditNoteList && (
        <Modal
          size="lg"
          show={showCreditNoteList}
          onHide={toggleCreditNoteListModal}
          dialogClassName="larg-popup review-popup small-review credit-note-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section new-transaction-modal"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Credit Notes
          </Modal.Header>
          <Modal.Body>
            <CreditNote id={id} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default InvoiceActionButton;
