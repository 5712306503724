import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import "../../assets/css/style.css";

const BusinessHeaderNav = () => {
  const params = useParams();
  const businessId = params?.business_id;
  const role = localStorage.getItem("sme-role");

  return (
    <ul className="header-nav">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              {(role === "Client" || role === "Client User") ? (
                <Link
                  to={{
                    pathname: "/businesses",
                  }}
                >
                  Home
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: "/clients",
                  }}
                >
                  Home
                </Link>
              )}
              {/* <Link
                to={{
                  pathname: "/clients",
                }}
              >
                Home
              </Link> */}
            </li>
            <li className="nav-item active">
              <Link
                to={{
                  pathname: "/business/" + businessId,
                }}
              >
                Dashboard
              </Link>
            </li>
            {/* <li className="nav-item active">
                <Link
                  to={{
                    pathname: "/#",
                  }}
                >
                </Link>
              </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Contacts
              </a>
              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown"> */}
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/customers`}>
                    Customers
                  </Dropdown.Item>
                </li>
                <div className="dropdown-divider"></div>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/suppliers`}>
                    Suppliers
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/contractors`}>
                    Contractors
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/all-contacts`}>
                    All Contacts
                  </Dropdown.Item>
                </li>
              </ul>
              {/* </div> */}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Accounting
              </a>

              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown"> */}
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/journal-entries`}>
                    Journal Entries
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/chart-of-accounts`}>
                    Chart of Accounts
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/bas`}>
                    BAS
                  </Dropdown.Item>
                </li>
                {/* <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/jobs`}>
                    Jobs/Matters/Projects
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/tasks`}>
                    Tasks/Milestones
                  </Dropdown.Item>
                </li>
                <li>
                </li> */}
                {/* <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/paragraphs`}>
                    Standard Paragraphs
                  </Dropdown.Item>
                </li> */}
                {/* <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/invoice`}>
                    Invoices
                  </Dropdown.Item>
                </li>  */}
                {/* <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/discount-codes`}>
                    Discount Codes
                  </Dropdown.Item>
                </li>  */}
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/cash-transactions`}>
                    Cash Transactions
                  </Dropdown.Item>
                </li>
              </ul>
              {/* </div> */}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Reports
              </a>

              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown"> */}
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/financial-reports?tab=trial-balance`}>
                    Financial Reports
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/account-transactions-report`}>
                    Transactions Report
                  </Dropdown.Item>
                </li>
                <div className="dropdown-divider"></div>
                {/* <li>
                  <Dropdown.Item
                    href={`/business/${businessId}/contact-statement-report`}
                  >
                    Contact Statements
                  </Dropdown.Item>
                </li> */}
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/bas?tab=gst-transactions`}>
                    GST Report
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/reconcile-transactions?tab=monthly-bank-reconciliation`}>
                    Monthly Bank Reconciliation
                  </Dropdown.Item>
                </li>
              </ul>
              {/* </div> */}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Invoicing
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/quotes`}>
                    Quotes
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/invoices`}>
                    Invoices
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/receipts`}>
                    Receipts
                  </Dropdown.Item>
                </li>
                {/* <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/invoicing/settings`}>
                    Options
                  </Dropdown.Item>
                </li> */}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Banking
              </a>

              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown"> */}
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/settings?tab=integrations`}>
                    Bank Accounts
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/reconcile-transactions?tab=bank-transactions`}>
                    View Transactions
                  </Dropdown.Item>
                </li>
                <div className="dropdown-divider"></div>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`}>
                    Reconcile Transactions
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/terms`}>
                    Memorisation
                  </Dropdown.Item>
                </li>
              </ul>
              {/* </div> */}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Settings
              </a>

              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown"> */}
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  {/* <Dropdown.Item href={`/business/${businessId}/terms`}>
                    Search Terms
                  </Dropdown.Item> */}
                  <Dropdown.Item as={Link} to={`/business/${businessId}/settings`}>
                    General Settings
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={`/business/${businessId}/invoicing/settings`}>
                    Invoice Settings
                  </Dropdown.Item>
                  {/* <Dropdown.Item as={Link} to={`/business/${businessId}/job-task-settings`}>
                    Job/Task Settings
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item as={Link} to={`/business/${businessId}/template-settings`}>
                    Template Settings
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item as={Link} to={`/business/${businessId}/template-settings`}>
                    Template Settings
                  </Dropdown.Item> */}
                </li>
                {/* <li>
                    <Dropdown.Item href="/clients">
                      Client Console
                    </Dropdown.Item>
                  </li> */}
                {/* <div className="dropdown-divider"></div> */}
                {/* <li>
                    <Dropdown.Item href="/user-console?page=1">
                      User Console
                    </Dropdown.Item>
                  </li> */}
                {/* <li>
                    <Dropdown.Item href="/user-console?page=1">
                      Accounting Firm
                    </Dropdown.Item>
                  </li> */}
              </ul>
              {/* </div> */}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Help
              </a>

              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Dropdown.Item as={Link} to="https://smecashbook.com.au/terms-conditions/" target="_blank">
                  <a
                    href="https://smecashbook.com.au/terms-conditions/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: '100%', display: 'block' }}
                  >
                    Terms of Use
                  </a>
                  </Dropdown.Item>
                </li>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </ul>
  );
};

export default BusinessHeaderNav;
