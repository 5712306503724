import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  API,
  fileUpload,
  get,
  metaTitle,
} from "../../../../config";
import { formatName } from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { AddSearchTermSchema } from "../../../common/Validation";
import Form from "./components/Form";
import { getSearchTermDetail } from "./components/SearchTermHelper";
import TitleBar from "../../../common/TitleBar";

const EditSearchTerm = () => {
  const navigate = useNavigate();
  let existingValues = [];
  let overwrittenFields = [];
  const formValues = useRef();
  const params = useParams();
  const businessId = params?.business_id;
  const [accontLoading, setAccountLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [allAccountValue, setAllAccountValue] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [jobOptions, setJobOptions] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskOptions, setTaskOptions] = useState([]);
  const [allContactList, setAllContactList] = useState([]);
  const [loadingContactList, setLoadingContactList] = useState(false);
  const [allContactValue, setAllContactValue] = useState([]);

  const [newTerm, setNewTerm] = useState();
  const [newTransactionType, setNewTransactionType] = useState();
  const [newAccount, setNewAccount] = useState();
  const [newContact, setNewContact] = useState();
  const [newJob, setNewJob] = useState();
  const [newTask, setNewTask] = useState();
  const [newDescription, setNewDescription] = useState();


  const [loading, setLoading] = useState(false);
  const [searchTermData, setSearchTermData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchTermId = query.get("id");

  
  const transactionOptions = [
    {
      label: "Debit",
      value: "0"
    },
    {
      label: "Credit",
      value: "1"
    },
  ]

  const amountOptions = [
    {
      label: "Amount less than equal to",
      value: "<="
    },
    {
      label: "Amount greater than equal to",
      value: ">="
    },
  ]

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleAccountChange = useCallback(async () => {
    try {
      setAccountLoading(true);
      get(
        `${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`
        ).then((response) => {
          setAccountLoading(false);
          var revenue =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "fada83c9-4769-49b9-ab0e-fcd8c43de30e"
                  );
                })
              : [];
  
          const revenueList =
            revenue && revenue?.length > 0
              ? revenue?.map((item, index) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];
  
            var expenses =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fad90f00-6651-4c51-a29a-78398fa226fd"
                    );
                  })
                : [];
    
            const expensesList =
              expenses && expenses?.length > 0
                ? expenses?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var currentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "3e5a94ef-b819-47c3-9955-d47da29e2ab0"
                    );
                  })
                : [];
    
            const currentLiabilitiesList =
              currentLiabilities && currentLiabilities?.length > 0
                ? currentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fcb7e129-7666-42ae-8c3d-11d016bbe340"
                    );
                  })
                : [];
        
            const nonCurrentLiabilitiesList =
              nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
                ? nonCurrentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
            
            var currentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "994d6d38-497b-465d-9f57-3bdc2d70594a"
                    );
                  })
                : [];
          
            const currentAssetsList =
              currentAssets && currentAssets?.length > 0
                ? currentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "4051efd3-9fb8-4adb-b0c7-7e565a078ac1"
                    );
                  })
                : [];
            
            const nonCurrentAssetsList =
              nonCurrentAssets && nonCurrentAssets?.length > 0
                ? nonCurrentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var equity =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "520f936e-3017-4574-a9ab-6c9e68b591e4"
                    );
                  })
                : [];
              
            const equityList =
              equity && equity?.length > 0
                ? equity?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
              const allAccountListing = [
              revenueList && { label: <div className="list-subHeading">Revenue</div>, options: revenueList },
              expensesList && { label: <div className="list-subHeading">Expenses</div>, options: expensesList },
              currentLiabilitiesList && { label: <div className="list-subHeading">Current Liabilities</div>, options: currentLiabilitiesList },
              nonCurrentLiabilitiesList && { label: <div className="list-subHeading">Non Current Liabilities</div>, options: nonCurrentLiabilitiesList },
              currentAssetsList && { label: <div className="list-subHeading">Current Assets</div>, options: currentAssetsList},
              nonCurrentAssetsList && { label: <div className="list-subHeading">Non Current Assets</div>, options: nonCurrentAssetsList},
              equityList && { label: <div className="list-subHeading">Equity</div>, options: equityList},
              { label: <strong>+ Add New Account</strong>, value: "new" }
            ];
  
            setAccountOptions(allAccountListing)
            setAllAccountValue([
              ...revenueList,
              ...expensesList,
              ...currentLiabilitiesList,
              ...nonCurrentLiabilitiesList,
              ...currentAssetsList,
              ...nonCurrentAssetsList,
              ...equityList
            ])
        });
    } catch (error) {
      setAccountLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const AccountName = allAccountValue && allAccountValue?.find(
    (item) => item?.value === searchTermData?.chart_of_account_id
  );

  const jobID = jobOptions?.find(
    (item) => item?.value === searchTermData?.business_job_id
  );

  const taskID = taskOptions?.find(
    (item) => item?.value === searchTermData?.business_task_id
  );

  const contactID = allContactValue && allContactValue?.find(
    (contact) => contact?.value === searchTermData?.contact_id
  )

  const transactionType = transactionOptions?.find(
    (item) => item?.value === String(searchTermData?.baseType)
  );

  const amountType = amountOptions?.find(
    (item) => item?.value === searchTermData?.amount_condition
  );


  const handleJobChange = useCallback(async () => {
    try {
      setJobLoading(true);
        get(
          `${API.GET_JOBS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
        ).then((response) => {
          const jobList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const list = {
                label:
                  item?.code +
                  (item?.description ? " (" + item?.description + ")" : ""),
                value: item?.id,
                tax: item?.tax,
              };
              return list;
            });
        setJobOptions(jobList ? [ ...jobList, { label: <strong>+ Add New Job</strong>, value: "new" }] : [{ label: <strong>+ Add New Job</strong>, value: "new" }]);
        setJobLoading(false);
      });
    } catch (error) {
      setJobLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleTaskChange = useCallback(async () => {
    try {
      setTaskLoading(true);
        get(
          `${API.GET_TASKS}?client_business_id=${businessId}&limit=-1&sort_column=code&sort_order=ASC`
        ).then((response) => {
          const taskList =
            response &&
            response?.data?.data?.data?.length > 0 &&
            response?.data?.data?.data?.map((item) => {
              const list = {
                label:
                  item?.code +
                  (item?.description ? " (" + item?.description + ")" : ""),
                value: item?.id,
                tax: item?.tax,
              };
              return list;
            });
        setTaskOptions(taskList ? [ ...taskList, { label: <strong>+ Add New Task</strong>, value: "new" }] : [{ label: <strong>+ Add New Task</strong>, value: "new" }]);
        setTaskLoading(false);
      });
    } catch (error) {
      setTaskLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleContactList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingContactList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

            const allContactListing = [
              contractorList && { label: <div className="list-subHeading">Contractors</div>, options: contractorList },
              supplierList && { label: <div className="list-subHeading">Suppliers</div>, options: supplierList },
              customerList && { label: <div className="list-subHeading">Customers</div>, options: customerList },
              { label: <strong>+ Add New Contact</strong>, value: "new" }
            ];

        setAllContactValue([
          ...contractorList,
          ...supplierList,
          ...customerList,
        ]);

        setAllContactList(allContactListing);

        setLoading(false);
        setLoadingContactList(false);
      });
    } catch (error) {
      setLoading(false);
      setLoadingContactList(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  useEffect(() => {
    handleAccountChange();
  }, [handleAccountChange]);

  useEffect(() => {
    handleJobChange();
  }, [handleJobChange]);

  useEffect(() => {
    handleTaskChange();
  }, [handleTaskChange]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList])

  const handleFormSubmit = async (value) => {
    value.chart_of_account_id = value?.chart_of_account_id
      ? value?.chart_of_account_id?.value
      : "";

    value.business_job_id = value?.business_job_id
      ? value?.business_job_id?.value
      : "";

    value.business_task_id = value?.business_task_id
      ? value?.business_task_id?.value
      : "";
    
    value.contact_id = value?.contact_id
      ? value?.contact_id?.value
      : "";

    value.transaction_type = value?.transaction_type
      ? value?.transaction_type?.value
      : "";

    value.amount = value?.amount
      ? value?.amount
      : "";
      
    value.amount_type = value?.amount_type
      ? value?.amount_type?.value
      : "";
    
    value.description = value?.description
      ? value?.description
      : "";

    const formData = new FormData();

    formData.append("client_business_id", businessId);
    formData.append("term", value?.term);
    formData.append("chart_of_account_id", value?.chart_of_account_id);
    formData.append("business_job_id", value?.business_job_id);
    formData.append("business_task_id", value?.business_task_id);
    formData.append("contact_id", value?.contact_id);
    if(value?.transaction_type){
      formData.append("baseType", value?.transaction_type);
    }

    if(value?.amount){
      formData.append("amount", value?.amount);
    }

    if(value?.amount_type){
      formData.append("amount_condition", value?.amount_type);
    }

    if(value?.description){
      formData.append("description", value?.description);
    }

    formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.EDIT_TERM}/${searchTermId}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate("/business/" + businessId + "/terms");
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        // await getUsers();
        setLoading(true);
        const data = await getSearchTermDetail(searchTermId);
        setSearchTermData(data);
        setLoading(false);
      } catch (e) {
        setSearchTermData(null);
        setLoading(false);
      }
    }
    fetchData();
    window.document.title = `${metaTitle} | Edit Memorisation`
  }, [searchTermId]);

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={searchTermData?.term}
          />
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  term: newTerm ? newTerm : searchTermData?.term ? searchTermData?.term : "",
                  chart_of_account_id: newAccount ? newAccount : AccountName ? AccountName : "",
                  business_job_id: newJob ?  newJob : jobID ? jobID : "",
                  business_task_id: newTask ? newTask : taskID ? taskID : "",
                  contact_id: newContact ? newContact : contactID ? contactID : "",
                  transaction_type: newTransactionType ? newTransactionType : transactionType ? transactionType : "",
                  amount: searchTermData?.amount ? searchTermData?.amount : "",
                  amount_type: amountType ? amountType : "",
                  description: newDescription ? newDescription : searchTermData?.description ? searchTermData?.description : "",
                }}
                enableReinitialize
                innerRef={formValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddSearchTermSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {(loading || loadingContactList || taskLoading || jobLoading || accontLoading) && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        accountOptions={accountOptions}
                        accontLoading={accontLoading}
                        jobOptions={jobOptions}
                        jobLoading={jobLoading}
                        taskOptions={taskOptions}
                        taskLoading={taskLoading}
                        allContactList={allContactList}
                        handleContactList={handleContactList}
                        loadingContactList={loadingContactList}
                        allContactValue={allContactValue}
                        transactionOptions={transactionOptions}
                        amountOptions={amountOptions}
                        getAccounts={handleAccountChange}
                        setNewTerm={setNewTerm}
                        setNewAccount={setNewAccount}
                        setNewContact={setNewContact}
                        setNewJob={setNewJob}
                        setNewTask={setNewTask}
                        setNewTransactionType={setNewTransactionType}
                        setNewDescription={setNewDescription}
                        handleJobChange={handleJobChange}
                        handleTaskChange={handleTaskChange}
                        businessId={businessId}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSearchTerm;
