import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReceiptLayoutSettingDelete from './ReceiptLayoutSettingDelete';
import { API, get } from '../../../../config';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Spin from '../../../common/Spin';
import ReceiptPreview from '../ReceiptLayoutSettings/components/ReceiptPreview';

const ReceiptLayoutActionButton = ({ id, handleDelete, is_system_generated }) => {
  const [showDelete, setShowDelete] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const pickerTypeList = [
    {
      label: 'Plain',
      value: 0
    },
    {
      label: 'Single',
      value: 1
    },
    {
      label: 'Multiple',
      value: 2
    }
  ];

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const getInvoiceTemplateSettings = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await get(`${API.INVOICE_SETTINGS}/${id}`);
      let intialData = data?.data?.settings;
      let clientOptions = [];
      if (intialData?.client_option?.alternateName) {
        clientOptions.push('AlternateName');
      }
      if (intialData?.client_option?.customer_ref) {
        clientOptions.push('CustomerRef');
      }
      if (intialData?.client_option?.non_individual) {
        clientOptions.push('Contact');
      }

      let invoiceDetailOption = [];
      if (intialData?.quote_detail_option?.amount) {
        invoiceDetailOption.push('Amounts');
      }

      let subTotalOption = [];
      if (intialData?.sub_total_option?.expiryDate) {
        subTotalOption.push('ExpiryDate');
      }
      if (intialData?.sub_total_option?.term) {
        subTotalOption.push('Term');
      }

      let finalSettings = {
        name: data?.data?.settings?.name ? data?.data?.settings?.name : '',
        receipt_style: data?.data?.settings?.receipt_style === 1 ? '1' : '0',
        client_option: clientOptions,
        picker_type: data?.data?.settings?.picker_type
          ? pickerTypeList?.find((i) => i.value === data?.data?.settings?.picker_type)
          : pickerTypeList[0],
        primary_color: data?.data?.settings?.primary_color
          ? data?.data?.settings?.primary_color
          : '#FFFFFF',
        secondary_color: data?.data?.settings?.secondary_color
          ? data?.data?.settings?.secondary_color
          : '',
        font_color: data?.data?.settings?.font_color
          ? data?.data?.settings?.font_color
          : '#000000',
        receipt_detail_option: invoiceDetailOption
          ? invoiceDetailOption
          : ['Amounts'],
        gst_option: data?.data?.settings?.gst_option === 1 ? '1' : '0',
        sub_total_format_option:
          data?.data?.settings?.sub_total_format_option === 1 ? '1' : '0',
        sub_total_option: subTotalOption
          ? subTotalOption
          : ['DueDate', 'Term']
      };
      setValues(finalSettings);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && showPreview) {
      getInvoiceTemplateSettings(id);
    }
  }, [getInvoiceTemplateSettings, id, showPreview]);
  return (
    <>
      <span>
        <Link to={`/business/${businessId}/template-settings/receipt-template/${id}`}>
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </Link>
        {is_system_generated === 0 && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              onClick={toggleDeleteModal}
              icon={faTrash}
              size="1x"
              data-toggle="modal"
              data-target="#deleteContractor"
            />
          </>
        )}
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <FontAwesomeIcon onClick={togglePreview} icon={faEye} size="1x" data-toggle="modal" />
        </>
      </span>

      {showDelete && (
        <ReceiptLayoutSettingDelete
          id={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}
      {showPreview && (
        <Modal
          size="lg"
          show={showPreview}
          onHide={togglePreview}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            View Receipt Layout
          </Modal.Header>
          <Modal.Body
            style={{
              padding: '15px'
            }}
          >
            {loading ? <Spin /> : <ReceiptPreview type={values?.receipt_style} values={values} />}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ReceiptLayoutActionButton;
