import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getBusinessPopupDetail } from "./BusinessHelper";

const BusinessDetailPopup = ({ businessId, toggleShowModal, showView }) => {
  const [businessDetail, setBusinessDetail] = useState(null);
  const [businessLoading, setBusinessLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const businessDetailData = await getBusinessPopupDetail(businessId, true);
      setBusinessDetail(businessDetailData);
      setBusinessLoading(false);
    }
    fetchData();
  }, [businessId, setBusinessDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Business Details
      </Modal.Header>
      <Modal.Body>
        {businessLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {businessDetail?.logo === null ||
                businessDetail?.logo === "" ? (
                  <span>{businessDetail?.business_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${businessDetail?.logo}`}
                    alt=""
                  />
                )}

                <h5>
                  {businessDetail?.business_name
                    ? businessDetail?.business_name
                    : ""}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Business Name:</strong>{" "}
                  {businessDetail?.business_name}
                </p>
                <p>
                  <strong>Email Address:</strong>{" "}
                  {businessDetail?.email ? businessDetail?.email : "-"}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {businessDetail?.phone
                    ? formatPhone(businessDetail?.phone)
                    : "-"}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {businessDetail?.mobile
                    ? formatMobile(businessDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {businessDetail?.abn ? formatABN(businessDetail?.abn) : "-"}
                </p>
                <p>
                  <strong>Entity Type:</strong>{" "}
                  {businessDetail?.entity_type
                    ? businessDetail?.entity_type
                    : "-"}
                </p>
                <p>
                  <strong>Ref. Number:</strong>{" "}
                  {businessDetail?.ref_number ? businessDetail?.ref_number : "-"}
                </p>
                <p>
                  <strong>Address:</strong>{" "}
                  {formatedAddress(
                    businessDetail?.street,
                    businessDetail?.suburb?.name,
                    businessDetail?.state?.name,
                    businessDetail?.postal_code
                  )}
                  {/* {businessDetail?.street ? businessDetail?.street : "-"} */}
                </p>
              </div>
              <h3>Account Details</h3>
              <div className="modal-content-details">
              <p>
                  <strong>Account Name:</strong>{" "}
                  {businessDetail?.account_name ? businessDetail?.account_name : "-"}
                </p>
                <p>
                  <strong>BSB:</strong>{" "}
                  {businessDetail?.bsb === null ||
                      businessDetail?.bsb === ''
                        ? '-'
                        : 'xxx' + businessDetail?.bsb?.toString().slice(-4)}
                </p>
                <p>
                  <strong>Account Number:</strong>{" "}
                  {businessDetail?.account_number === null ||
                      businessDetail?.account_number === ''
                        ? '-'
                        : 'xxxx xxxx ' +
                          businessDetail?.account_number?.toString().slice(-4)}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BusinessDetailPopup;
