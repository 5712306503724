import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  API,
  entityTypes,
  fileUpload,
  metaTitle,
} from "../../../config";
import {
  getContractorDetail,
  useStateList,
  useSuburbListE,
} from "./components/ContractorHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "./components/Form";
import Spin from "../../common/Spin";
import { AddContractorSchema } from "../../common/Validation";
import {
  formatedPostalCode,
  formatName,
} from "../../common/Misc";
import TitleBar from "../../common/TitleBar";

const EditContractor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const businessId = params?.business_id;
  const [loading, setLoading] = useState(false);
  const [contractorData, setContractorData] = useState();
  const formValues = useRef();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const contractorId = query.get("id");
  let existingValues = [];
  let overwrittenFields = [];

  useEffect(() => {
    async function fetchData() {
      try {
        // await getUsers();
        setLoading(true);
        const data = await getContractorDetail(contractorId);
        setContractorData(data);
        setLoading(false);
      } catch (e) {
        setContractorData(null);
      }
    }
    fetchData();
    window.document.title = `${metaTitle} | Edit Contractor`
  }, [contractorId]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";
    value.entity_type = value?.entity_type?.value;
    value.contact_type = value?.contact_type?.value;

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.value;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.value;

    formData.append("client_business_id", businessId);
    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("display_name", value?.display_name ? value?.display_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "ref_number",
      value?.ref_number ? value?.ref_number : ""
    );
    formData.append(
      "bpay_ref_number",
      value?.bpay_ref_number ? value?.bpay_ref_number : ""
    );
    formData.append(
      "entity_type",
      value?.entity_type ? value?.entity_type : ""
    );
    formData.append("abn", value?.abn);
    formData.append("contact_type", value?.contact_type);
    formData.append("_method", "put");
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.CONTRACTOR_Add}/${contractorId}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate("/business/" + businessId + "/contractors");
      }
      return data?.data;
    } catch (error) {
      setContractorData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === contractorData?.state_id
  );

  //ShippingState
  const shippingState = stateListing?.find(
    (item) => item?.value === contractorData?.shipping_state_id
  );

  // Suburb
  const { suburbLoading, suburbList = [] } = useSuburbListE(
    contractorData?.state_id
  );
  const suburbListing = suburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const suburb = suburbListing?.find(
    (item) => item?.value === contractorData?.suburb_id
  );

  // ShippingSuburb
  const { suburbLoading2, shippingSuburbList = [] } = useSuburbListE(
    contractorData?.shipping_state_id
  );
  const shippingSuburbListing = shippingSuburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const shippingSuburb = shippingSuburbListing?.find(
    (item) => item?.value === contractorData?.shipping_suburb_id
  );

  const ContactTypeListing = [
    { label: "Customer", value: 1 },
    { label: "Supplier", value: 2 },
    { label: "Contractor", value: 3 },
  ];

  const contactType = ContactTypeListing?.find(
    (item) => item?.value === parseInt(contractorData?.contact_type)
  );


  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={formatName(contractorData?.first_name, contractorData?.last_name)}
          />
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  first_name: contractorData?.first_name,
                  last_name: contractorData?.last_name,
                  display_name: contractorData?.display_name,
                  phone: contractorData?.phone,
                  mobile: contractorData?.mobile,
                  email: contractorData?.email,
                  business_name: contractorData?.business_name,
                  ref_number: contractorData?.ref_number,
                  bpay_ref_number: contractorData?.bpay_ref_number,
                  abn: contractorData?.abn,
                  entity_type: entityTypes?.find(
                    (item) => item?.value === contractorData?.entity_type
                  ),
                  contact_type: contactType,

                  street: contractorData?.street,
                  state_id: state,
                  suburb_id: suburb,
                  postal_code: formatedPostalCode(contractorData?.postal_code),

                  same_address: false,

                  shipping_street: contractorData?.shipping_street,
                  shipping_state_id: shippingState,
                  shipping_suburb_id: shippingSuburb,
                  shipping_postal_code: formatedPostalCode(
                    contractorData?.shipping_postal_code
                  ),
                }}
                enableReinitialize
                innerRef={formValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddContractorSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                        suburbListing={suburbListing}
                        shippingSuburbListing={shippingSuburbListing}
                        suburbLoadingE={suburbLoading}
                        suburbLoading2E={suburbLoading2}
                        ContactTypeListing={ContactTypeListing}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditContractor;
