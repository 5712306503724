import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { API, get } from '../../config';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../common/Pagination';
import CustomTooltip from '../common/CustomTooltip';
import Spin from '../common/Spin';
import { formatNumber } from '../common/Misc';

const ExpandRegisteredUserActionButton = ({ id }) => {
  const [showView, setShowView] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const toggleShowModal = () => {
    if (!showView) {
      fetchRegisteredUsers(page, limit);
    }
    setShowView(!showView);
  };

  const [registeredUserLoading, setRegisteredUserLoading] = useState(false);
  const [registeredUserData, setRegisteredUserData] = useState();

  const handleChangePage = (page) => {
    setPage(page)
    fetchRegisteredUsers(page);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
    fetchRegisteredUsers(page, limit);
  };

  const columns = [
    {
      text: 'Invoice Date',
      dataField: 'invoice_date'
    },
    {
      text: 'Amount',
      dataField: 'amount'
    },
    {
      text: 'GST',
      dataField: 'gst'
    },
    {
      text: 'Discount',
      dataField: 'discount'
    },
    {
      text: 'Total Amount',
      dataField: 'total_amount'
    },
    {
      text: 'Action',
      dataField: 'action'
    }
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
    }
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        var a = document.createElement('a');
        a.href = path;
        var file = path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'new_invoice.pdf');
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error('File is not exist.');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const paymentGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: registeredUserData[i]?.id,
        id: registeredUserData[i]?.id ? registeredUserData[i]?.id : '',
        invoice_date: registeredUserData[i]?.effectiveAt ? registeredUserData[i]?.effectiveAt : '-',
        amount: registeredUserData[i]?.subtotal
          ? `$${formatNumber(registeredUserData[i]?.subtotal)}`
          : '$0.00',
        gst: registeredUserData[i]?.totalTaxAmount
          ? `$${formatNumber(registeredUserData[i]?.totalTaxAmount)}`
          : '$0.00',
        discount: registeredUserData[i]?.totalDiscountAmount
          ? `$${formatNumber(registeredUserData[i]?.totalDiscountAmount)}`
          : '$0.00',
        total_amount: registeredUserData[i]?.subtotal
          ? `$${formatNumber(
              Number(
                Number(
                  Number(registeredUserData[i]?.subtotal) +
                    Number(registeredUserData[i]?.totalTaxAmount)
                ) - Number(registeredUserData[i]?.totalDiscountAmount)
              )
            )}`
          : '$0.00',
        action: (
          <>
            <CustomTooltip
              text={'Download Invoice'}
              children={
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  onClick={() => {
                    downloadLetter(registeredUserData[i]?.invoicePdf);
                  }}
                />
              }
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'View Invoice'}
              children={
                <FontAwesomeIcon
                  icon={faEye}
                  size="1x"
                  onClick={() => {
                    window.open(
                      registeredUserData[i]?.hostedInvoiceUrl,
                      '_blank' // <- This is what makes it open in a new window.
                    );
                  }}
                />
              }
            />
          </>
        )
      });
    }
    return items;
  };

  const subscriptionUserData = paymentGenerator(registeredUserData?.length);

  const fetchRegisteredUsers = useCallback(async (page, limit) => {
    try {
      setRegisteredUserLoading(true);
      const { data } = await get(
        `${API.REGISTERED_USER_SUBSCRIPTIONS_INVOICE}/${id}?limit=${limit ? limit : 1}&page=${
          page ? page : 1
        }`
      );
      setRegisteredUserLoading(false);
      const registeredUserData = data?.data?.data;
      setRegisteredUserData(registeredUserData);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      return data.data;
    } catch (e) {
      const { data } = e;
      setRegisteredUserLoading(false);
      setRegisteredUserData([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, [id]);

  // useEffect(() => {
  //   navigate(
  //     `?limit=${limit ? limit : 10}&page=${page ? page : 1}&sort_column=${
  //       sortField ? sortField : ''
  //     }&sort_order=${sortOrder ? sortOrder : ''}`,
  //     {
  //       replace: true
  //     }
  //   );
  // }, [navigate, limit, page, sortField, sortOrder]);

  return (
    <>
      <FontAwesomeIcon
        onClick={toggleShowModal}
        icon={faEye}
        size="1x"
        data-toggle="modal"
        data-target="#cashRegisteredUserDetail"
      />
      {showView && (
        <Modal
          size="lg"
          show={showView}
          onHide={toggleShowModal}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Invoice
          </Modal.Header>
          <Modal.Body>
            {registeredUserLoading ? (
              <Spin />
            ) : (
              <>
                <div className="cms-page">
                  <div className="page-content-block">
                    <div className="full-content-block">
                      <div className="content-details" style={{ paddingTop: '30px' }}>
                        <div className="custom-table">
                          {registeredUserLoading && <Spin />}
                          <BootstrapTable
                            keyField="id"
                            remote
                            data={subscriptionUserData}
                            onTableChange={handleTableChange}
                            columns={columns}
                            noDataIndication="No Data Found"
                          />

                          {registeredUserData.length !== 0 ? (
                            <Pagination
                              total={total}
                              limit={parseInt(limit)}
                              currentPage={page}
                              updateLimit={handleUpdateLimit}
                              updatePage={handleChangePage}
                              from={from}
                              to={to}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ExpandRegisteredUserActionButton;
