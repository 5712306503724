import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import BackButton from '../../../components/Form/BackButton';
import { API, get, metaTitle } from '../../../config';
import { formatName, formatNumber } from '../../common/Misc';
import Pagination from '../../common/Pagination';
import Spin from '../../common/Spin';
// import { getBusinessData } from '../components/BusinessHelper';
import { MultiSelect } from 'primereact/multiselect';
import ExpandJournalEntry from './ExpandJournalEntry';
import TitleBar from '../../common/TitleBar';
import ExcelJournalEntry from './ExcelJournalEntry';

const JournalEntries = () => {
  const params = useParams();
  const businessId = params?.business_id;
  // const businessName = localStorage.getItem('sme-businessName');
  // const businessLogo = localStorage.getItem('sme-businessLogo');
  // const [BusinessName, setBusinessName] = useState(businessName);
  // const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [lastBatchId, setLastBatchId] = useState();
  const [accontLoading, setAccountLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountTpesLoading, setAccountTpesLoading] = useState(false);
  const [accountTypesList, setAccountTypesList] = useState();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');

  const [journalEntryList, setJournalEntryList] = useState(null);
  const [journalEntryData, setJournalEntryData] = useState(null);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const [selectedContacts, setSelectedContacts] = useState(null);
  const [selectedMainContacts, setSelectedMainContacts] = useState([]);
  const [contactListLoading, setContactListLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);

  const [show, setShow] = useState(false);
  const [detailsData, setDetailsData] = useState({});

  const ExGSTClaculate = (value, tax) => {
    return tax === 0
      ? formatNumber(Number(value))
      : formatNumber(Number(Number(value) - (Number(value) / 1.1) * 0.1));
  };

  const fetchJournalEntryData = useCallback(async (id) => {
    if (id) {
      try {
        const { data } = await get(`${API.JOURNAL_ENTRIES}/${id}`);
        setJournalEntryData(data?.data?.journal_entry_details);
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, []);

  const fetchAccountTypes = useCallback(async () => {
    try {
      setAccountTpesLoading(true);
      const { data } = await get(`${API.GET_ACCOUNT_TYPES}`);
      setAccountTpesLoading(false);
      const accountTypesList = data.data;
      setAccountTypesList(accountTypesList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setAccountTpesLoading(false);
      setAccountTypesList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, []);

  const handleAccountList = useCallback(async () => {
    try {
      setAccountLoading(true);
      await get(`${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`).then(
        (response) => {
          var revenue =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === 'fada83c9-4769-49b9-ab0e-fcd8c43de30e';
                })
              : [];

          const revenueList =
            revenue && revenue?.length > 0
              ? revenue?.map((item, index) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          var expenses =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === 'fad90f00-6651-4c51-a29a-78398fa226fd';
                })
              : [];

          const expensesList =
            expenses && expenses?.length > 0
              ? expenses?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line

                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          var currentLiabilities =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '3e5a94ef-b819-47c3-9955-d47da29e2ab0';
                })
              : [];

          const currentLiabilitiesList =
            currentLiabilities && currentLiabilities?.length > 0
              ? currentLiabilities?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line

                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          var nonCurrentLiabilities =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === 'fcb7e129-7666-42ae-8c3d-11d016bbe340';
                })
              : [];

          const nonCurrentLiabilitiesList =
            nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
              ? nonCurrentLiabilities?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line

                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          var currentAssets =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '994d6d38-497b-465d-9f57-3bdc2d70594a';
                })
              : [];

          const currentAssetsList =
            currentAssets && currentAssets?.length > 0
              ? currentAssets?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line

                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          var nonCurrentAssets =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '4051efd3-9fb8-4adb-b0c7-7e565a078ac1';
                })
              : [];

          const nonCurrentAssetsList =
            nonCurrentAssets && nonCurrentAssets?.length > 0
              ? nonCurrentAssets?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line

                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          var equity =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '520f936e-3017-4574-a9ab-6c9e68b591e4';
                })
              : [];

          const equityList =
            equity && equity?.length > 0
              ? equity?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                      `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                      ' - ' +
                      `${item?.name ? item?.name : ''}` +
                      `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: item?.id,
                    tax: item?.tax,
                    code: `${
                      item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                    }`
                  };
                  return list ? list : [];
                })
              : [];

          const allAccountListing = [
            ...revenueList,
            ...expensesList,
            ...currentLiabilitiesList,
            ...nonCurrentLiabilitiesList,
            ...currentAssetsList,
            ...nonCurrentAssetsList,
            ...equityList
            // { label: <strong>+ Add New Account</strong>, value: "new" }
          ];

          setAccountOptions(allAccountListing);
          setAccountLoading(false);
        }
      );
    } catch (error) {
      setAccountLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  useEffect(() => {
    fetchAccountTypes();
    handleAccountList();
    //eslint-disable-next-line
  }, []);

  const fetchJournalEntries = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${API.JOURNAL_ENTRIES}?client_business_id=${businessId}&limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&contacts=${selectedMainContacts}&sort_column=${sortField ? sortField : ''}&sort_order=${
          sortOrder ? sortOrder : ''
        }`
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const journalEntryList = data.data.data;
      setJournalEntryList(journalEntryList);
      setLastBatchId(data?.last_batch_id + 1);
      return data.data;
    } catch (error) {
      setLoading(false);
      setJournalEntryList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [limit, page, businessId, selectedMainContacts, sortField, sortOrder]);

  const toggleShowModal = (data) => {
    setShow(true);
    setDetailsData(data);
  };

  const toggleCloseModal = () => {
    setShow(false);
  };

  const journalEntryGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: journalEntryList[i]?.id,
        transactionDate: journalEntryList[i]?.transactionDate
          ? journalEntryList[i]?.transactionDate
          : '-',
        period: journalEntryList[i]?.period ? journalEntryList[i]?.period : '-',
        batch_id: journalEntryList[i]?.batch_id ? journalEntryList[i]?.batch_id : '-',
        reference_number: journalEntryList[i]?.reference_number
          ? journalEntryList[i]?.reference_number
          : '-',
        amount: journalEntryList[i]?.amount ? `$${formatNumber(journalEntryList[i]?.amount)}` : '-',
        gst_amount: journalEntryList[i]?.gst_amount
          ? `$${formatNumber(journalEntryList[i]?.gst_amount)}`
          : '-',
        net_amount: journalEntryList[i]?.net_amount
          ? `$${formatNumber(journalEntryList[i]?.net_amount)}`
          : '-',
        action: (
          <>
            <FontAwesomeIcon
              onClick={() => {
                toggleShowModal(journalEntryList[i]);
                fetchJournalEntryData(journalEntryList[i].id);
              }}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#businessDetail"
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <Link to={`/business/${businessId}/journal-entries/edit?id=${journalEntryList[i].id}`}>
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Link>
          </>
        )
      });
    }
    return items;
  };

  const journalEntries = journalEntryGenerator(journalEntryList?.length);

  useEffect(() => {
    fetchJournalEntries();
    window.document.title = `${metaTitle} | Journal Entries`;
  }, [fetchJournalEntries]);

  useEffect(() => {
    fetchJournalEntryData();
  }, [fetchJournalEntryData]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  const columns = [
    {
      dataField: 'transactionDate',
      text: 'Transaction Date',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'period',
      text: 'Period',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      }
    },
    {
      dataField: 'batch_id',
      text: 'Batch Id ',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'reference_number',
      text: 'Reference No.',
      sort: true,
      headerStyle: () => {
        return { width: '30%' };
      }
    },
    {
      dataField: 'amount',
      text: 'Total Amount',
      headerStyle: () => {
        return { width: '20%' };
      }
    },

    // {
    //   dataField: 'gst_amount',
    //   text: 'GST Amount',
    //   headerStyle: () => {
    //     return { width: '15%' };
    //   }
    // },
    // {
    //   dataField: 'net_amount',
    //   text: 'Net Amount',
    //   headerStyle: () => {
    //     return { width: '15%' };
    //   }
    // },
    {
      dataField: 'action',
      text: 'Action',
      align: 'center'
      // style: { width: '8%' }
    }
  ];

  const handleContactList = useCallback(async () => {
    try {
      setContactListLoading(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && { label: 'Contractors', items: contractorList },
          supplierList && { label: 'Suppliers', items: supplierList },
          customerList && { label: 'Customers', items: customerList }
          // { label: "Unassigned", value: "" },
        ];

        // setAllContactValue([
        //   ...contractorList,
        //   ...supplierList,
        //   ...customerList,
        //   {label: 'Unassigned' , value: ''}
        // ]);

        setContactOptions(
          allContactListing ? allContactListing : [{ label: 'Unassigned', value: '' }]
        );
        setContactListLoading(false);
      });
    } catch (e) {
      setContactListLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const filterContactInputRef = useRef();

  const handleContactChange = (e) => {
    setSelectedContacts(e.value);
  };

  const groupedItemTemplate = (option) => {
    return (
      <div style={{ padding: '0.75rem 1.25rem' }}>
        <strong>{option.label}</strong>
      </div>
    );
  };

  const handleApplyFilter = () => {
    setSelectedMainContacts(selectedContacts);
    filterContactInputRef.current.hide();
  };

  const myContactResetFunction = () => {
    setSelectedContacts([]);
    setSelectedMainContacts([]);
    filterContactInputRef && filterContactInputRef.current.focus();
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandJournalEntry id={row?.key} businessId={businessId} />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: [
      'Revenues',
      'totalRevenue',
      'Expenses',
      'totalExpense',
      'totalData',
      'blank-row'
    ],
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      if (
        rowKey === 'Revenues' ||
        rowKey === 'totalRevenue' ||
        rowKey === 'Expenses' ||
        rowKey === 'totalExpense' ||
        rowKey === 'totalData' ||
        rowKey === 'blank-row'
      ) {
        return <div className="not-expaned-cell-pl-report" />;
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const viewJournalDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: journalEntryData[i]?.id,
        transactionDate: journalEntryData[i]?.transactionDate
          ? journalEntryData[i]?.transactionDate
          : '',
        account: journalEntryData[i]?.chart_of_account
          ? (journalEntryData[i]?.chart_of_account?.code?.length < 4
              ? padWithLeadingZeros(Number(journalEntryData[i]?.chart_of_account?.code), 4)
              : journalEntryData[i]?.chart_of_account?.code.toString()) +
            (journalEntryData[i]?.chart_of_account?.sub_code
              ? '.' + journalEntryData[i]?.chart_of_account?.sub_code.toString()
              : '') +
            ' - ' +
            journalEntryData[i]?.chart_of_account?.name
          : '',
        contact: journalEntryData[i]?.contact
          ? formatName(
              journalEntryData[i]?.contact?.first_name,
              journalEntryData[i]?.contact?.last_name
            )
          : '-',
        debit:
          journalEntryData[i]?.transaction_type === 0
            ? journalEntryData[i]?.amount
              ? '$' + formatNumber(Number(journalEntryData[i]?.amount))
              : '$0.00'
            : '-',
        credit:
          journalEntryData[i]?.transaction_type === 1
            ? journalEntryData[i]?.amount
              ? '$' + formatNumber(Number(journalEntryData[i]?.amount))
              : '$0.00'
            : '-',
        gst:
          journalEntryData[i]?.gst === 0
            ? 'GST Excluded'
            : journalEntryData[i]?.gst === 1
            ? 'GST 10%'
            : journalEntryData[i]?.gst === 2
            ? 'BAS Excluded'
            : 'GST on Imports',
        tax: journalEntryData[i]?.gst_amount
          ? '$' + formatNumber(Number(journalEntryData[i]?.gst_amount))
          : '-',
        ex_gst_amount: journalEntryData[i]?.amount
          ? '$' + ExGSTClaculate(journalEntryData[i]?.amount, journalEntryData[i]?.gst)
          : 0,
        description: journalEntryData[i]?.description ? journalEntryData[i]?.description : '-'
      });
    }
    return items;
  };
  const viewJournalData = viewJournalDataGenerator(journalEntryData?.length);

  const viewColumns = [
    {
      dataField: 'account',
      text: 'Account',
      style: { width: '15%' }
    },
    {
      dataField: 'description',
      text: 'Description',
      style: { width: '15%' }
    },
    {
      dataField: 'gst',
      text: 'GST',
      style: { width: '10%' }
    },
    {
      dataField: 'debit',
      text: 'Debit',
      style: { width: '11%' }
    },
    {
      dataField: 'credit',
      text: 'Credit',
      style: { width: '11%' }
    },
    {
      dataField: 'tax',
      text: 'Tax',
      style: { width: '11%' }
    },
    {
      dataField: 'ex_gst_amount',
      text: 'Ex. GST Amount',
      style: { width: '11%' }
    },
    {
      dataField: 'contact',
      text: 'Contact ',
      style: { width: '15%' }
    }
  ];

  const [totalDeference, setTotalDeference] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  useEffect(() => {
    let debitTotal = 0;
    let creditTotal = 0;
    journalEntryData &&
      journalEntryData?.length > 0 &&
      journalEntryData?.map((item) => {
        debitTotal += item?.transaction_type === 0 ? (item?.amount ? Number(item?.amount) : 0) : 0;
        creditTotal += item?.transaction_type === 1 ? (item?.amount ? Number(item?.amount) : 0) : 0;
        return 0;
      });
    setTotalDebit(formatNumber(Number(debitTotal)));
    setTotalCredit(formatNumber(Number(creditTotal)));
    let deference = debitTotal - creditTotal;
    setTotalDeference(formatNumber(Number(deference)));
  }, [journalEntryData]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={"Journal Entries"}
            />
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section form-group">
                  <MultiSelect
                    value={selectedContacts}
                    onChange={(e) => {
                      handleContactChange(e);
                    }}
                    options={contactOptions}
                    optionLabel="label"
                    filter
                    placeholder="Select Contact"
                    maxSelectedLabels={0}
                    optionGroupTemplate={groupedItemTemplate}
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    selectedItemsLabel={`${selectedContacts?.length} Contact(s) Selected`}
                    onHide={() => {
                      setSelectedMainContacts(selectedContacts);
                    }}
                    style={{
                      fontSize: '13px'
                    }}
                    ref={filterContactInputRef}
                    panelFooterTemplate={
                      <>
                        <hr />
                        <Button
                          className="multiSelect-button"
                          onClick={() => {
                            handleApplyFilter();
                          }}
                        >
                          Apply
                        </Button>
                        <Button
                          className="multiSelect-button"
                          onClick={() => {
                            myContactResetFunction();
                          }}
                        >
                          Reset
                        </Button>
                      </>
                    }
                  />
                </div>
                <div className="table-btn">
                  <ExcelJournalEntry
                    fetchJournalEntries={fetchJournalEntries}
                    lastBatchId={lastBatchId}
                    accountOptions={accountOptions}
                    accountTypesList={accountTypesList}
                    handleAccountList={handleAccountList}
                  />
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(`/business/${businessId}/journal-entries/add`, {
                        state: { lastBatchId: lastBatchId }
                      });
                    }}
                  >
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {(loading || contactListLoading || accountTpesLoading || accontLoading) && <Spin />}

                <BootstrapTable
                  keyField="key"
                  remote
                  data={journalEntries || []}
                  columns={columns}
                  expandRow={expandRow}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={toggleCloseModal}
        dialogClassName="larg-popup journal-modal review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header closeButton>Journal Entry Details</Modal.Header>
        <Modal.Body style={{ padding: '15px 15px 15px 15px' }}>
          <>
            <div>
              {/* <div className="modal-content header"> */}
              <div className="journal-entry-status" style={{ justifyContent: 'space-between' }}>
                <div className="journal-header-modal-content">
                  <p>
                    <span>Batch ID:</span> {detailsData?.batch_id ? detailsData?.batch_id : '-'}
                  </p>
                  <p>
                    <span>Reference No:</span>{' '}
                    {detailsData?.reference_number ? detailsData?.reference_number : '-'}
                  </p>
                  <p>
                    <span>Date:</span>{' '}
                    {detailsData?.transactionDate ? detailsData?.transactionDate : '-'}
                  </p>
                  <p>
                    <span>Period:</span> {detailsData?.period ? detailsData?.period : '-'}
                  </p>
                </div>
                <div className="journal-entry">
                  <p>
                    Total Debit
                    <i>{totalDebit ? totalDebit : 0.0}</i>
                  </p>
                  <p>
                    Total Credit<i>{totalCredit}</i>
                  </p>
                  <p>
                    Difference<i>{totalDeference}</i>
                  </p>
                </div>
              </div>
              <BootstrapTable
                keyField="key"
                data={viewJournalData}
                columns={viewColumns}
                noDataIndication="No Data Found"
              />
              <div className="edit-box">
                <Button
                  onClick={() => {
                    navigate(`/business/${businessId}/journal-entries/edit?id=${detailsData?.id}`);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JournalEntries;
