import React, { useState } from 'react';
import { Input, Form } from 'antd';
import '../../components/Authentication/authentication.css';
import { toast } from 'react-toastify';
import { API, post } from '../../config';
import { Button } from 'react-bootstrap';
import Spin from '../../pages/common/Spin';

const ConfirmAuthentication = (props) => {
  const { submitButton, verifyUrl } = props;
  const [validator, setValidator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');

  const changeAuthenticationCode = (event) => {
    if (validator) {
      setValidator(false);
    }
    setVerifyCode(event.target.value);
  };

  const confirmCode = async () => {
    setLoading(true);
    let params = { code: verifyCode };
    if (verifyUrl === API.DELETE_2FA) {
      params.method = 1;
    }
    post(verifyUrl, params)
      .then((response) => {
        setValidator(false);
        toast.success(response?.data?.message);
        submitButton();
        setLoading(false);
      })
      .catch(() => {
        toast.error('Authentication code is invalid');
        setLoading(false);
        setValidator(true);
      });
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={confirmCode}
        autoComplete="off"
      >
        { loading && <Spin /> }
        <p>Enter the 6-digit code found in your authenticator app</p>
        <Form.Item
          label=""
          name="authCode"
          rules={[
            { required: true, message: 'Please input your authentication code!' },
            {
              required: true,
              message: 'authentication code length should be 6',
              pattern: new RegExp(/^[0-9]{6}$/)
            },
            () => ({
              validator() {
                if (validator) {
                  return Promise.reject('Authentication code is invalid');
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input autoFocus placeholder="Authentication Code" onChange={changeAuthenticationCode} />
        </Form.Item>
        <Form.Item>
          <Button type="submit" loading={loading}>
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ConfirmAuthentication;
