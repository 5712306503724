import { Formik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import NumberGeneratorSettings from './components/NumberGeneratorSettings';
// import EmailSettings from './components/EmailSettings';
// import { ContentState, EditorState, convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
import { useCallback } from 'react';
// import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';
import { API, fileUpload, get } from '../../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spin from '../../../common/Spin';
import { useEffect } from 'react';
import PaymentTerms from './components/PaymentTerms';

const ReceiptSettings = () => {
  const params = useParams();
  const businessId = params?.business_id;
  // const [editorState, setEditorState] = useState(null);
  // const [editorNonIndividualState, setEditorNonIndividualState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState();
  const navigate = useNavigate();

  // const getBodyIndividual = (html) => {
  //   const contentBlock = htmlToDraft(html);
  //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //   const value = EditorState.createWithContent(contentState) || null;
  //   return value;
  // };

  // const getBodyNonIndividual = (html) => {
  //   const contentBlock = htmlToDraft(html);
  //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //   const value = EditorState.createWithContent(contentState) || null;
  //   return value;
  // };

  const getReceiptSettings = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_RECEIPT_SETTINGS}?client_business_id=${businessId}`);
      // const invoiceSettingsObj = data;
      // const html = invoiceSettingsObj?.data?.individual_email['body']
      //   ? invoiceSettingsObj?.data?.individual_email['body']
      //   : ``;
      // let body_individual = null;
      // if (html) {
      //   body_individual = getBodyIndividual(html);
      // }
      // const bodyNON = invoiceSettingsObj?.data?.non_individual_email['body']
      //   ? invoiceSettingsObj?.data?.non_individual_email['body']
      //   : ``;
      // let body_nonindividual = null;
      // if (bodyNON) {
      //   body_nonindividual = getBodyNonIndividual(bodyNON);
      // }
      // setEditorState(body_individual);
      // setEditorNonIndividualState(body_nonindividual);
      setSettingsData(data?.data);
      setLoading(false);
    } catch (error) {
      // setEditorNonIndividualState(null);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }, [businessId]);

  useEffect(() => {
    getReceiptSettings();
  }, [getReceiptSettings]);

  const handleFormSubmit = async (values) => {
    const formData = new FormData();

    // let body_individual = '';
    // if (editorState) {
    //   if (
    //     convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
    //     convertToRaw(editorState.getCurrentContent()).blocks[0].text === ''
    //   ) {
    //     body_individual = '';
    //   } else {
    //     body_individual = editorState
    //       ? draftToHtml(convertToRaw(editorState?.getCurrentContent()))
    //       : '';
    //   }
    // }

    // let body_nonindividual = '';

    // if (editorNonIndividualState) {
    //   if (
    //     convertToRaw(editorNonIndividualState.getCurrentContent()).blocks.length === 1 &&
    //     convertToRaw(editorNonIndividualState.getCurrentContent()).blocks[0].text === ''
    //   ) {
    //     body_nonindividual = '';
    //   } else {
    //     body_nonindividual = editorNonIndividualState
    //       ? draftToHtml(convertToRaw(editorNonIndividualState?.getCurrentContent()))
    //       : '';
    //   }
    // }

    formData.append('client_business_id', businessId);
    formData.append('number', values?.number_option);
    formData.append('payment_terms', values?.payment_terms ? values?.payment_terms : '14');
    // formData.append('individual_email[subject]', values?.subject_individual);
    // formData.append('individual_email[body]', body_individual);
    // formData.append('non_individual_email[subject]', values?.subject_nonindividual);
    // formData.append('non_individual_email[body]', body_nonindividual);

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.GET_RECEIPT_SETTINGS}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        getReceiptSettings();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    navigate('?tab=receipt&childTab=global', {
      replace: true
    });
  }, [navigate]);

  return (
    <>
      {loading && <Spin />}
      <Formik
        initialValues={{
          // subject_individual: settingsData?.individual_email?.subject
          //   ? settingsData?.individual_email?.subject
          //   : '',
          // subject_nonindividual: settingsData?.non_individual_email?.subject
          //   ? settingsData?.non_individual_email?.subject
          //   : '',
          number_option: (settingsData?.number !== null && settingsData?.number !== undefined) ? String(settingsData?.number) : '1',
          payment_terms: settingsData?.payment_terms ? settingsData?.payment_terms : '14'
          // body_individual: '',
          // body_nonindividual: ''
        }}
        enableReinitialize
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ values, setErrors, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <>
              {/* {loading && <Spin />} */}
              <form
                onSubmit={handleSubmit}
                noValidate
                className="entry-section invoice-settings-form"
              >
                <div className="form-box">
                  <div className="row">
                    {/* <div className="col-md-6">
                      <EmailSettings
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setEditorNonIndividualState={setEditorNonIndividualState}
                        setEditorState={setEditorState}
                        editorState={editorState}
                        editorNonIndividualState={editorNonIndividualState}
                      />
                    </div> */}
                    <div className="col-md-6">
                      <div className="row settings-box">
                        <div className="col-md-8">
                          <NumberGeneratorSettings label="Quote Number options" type="invoice" />
                        </div>
                        <div className="col-md-4">
                          <PaymentTerms
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                      <div className="settings-button-body">
                        <Button type="submit">Save</Button>
                        <Button data-dismiss="modal" aria-label="Close" onClick={() => {}}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ReceiptSettings;
