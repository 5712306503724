import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import Spin from '../../../../common/Spin';
import { API, fileUpload } from '../../../../../config';
import { AddDiscountSchema } from '../../../../common/Validation';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
// import { convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Select from "react-select";


const AddTemplate = ({ showAddTemplate, toggleAddTemplateModal, handleAdd }) => {
  const formValues = useRef();
  const [loading, setLoading] = useState(false);
  // const [editorState, setEditorState] = useState(null);
  const [editorHeaderState, setEditorHeaderState] = useState(null);
  const [editorBodyState, setEditorBodyState] = useState(null);
  const [editorFooterState, setEditorFooterState] = useState(null);


  // const [templateData, setTemplateData] = useState(null);

  const handleHeaderEditorChange = (state) => {
    setEditorHeaderState(state);
  };

  const handleBodyEditorChange = (state) => {
    setEditorBodyState(state);
  };

  const handleFooterEditorChange = (state) => {
    setEditorFooterState(state);
  };

  const getTemplate = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const value = EditorState.createWithContent(contentState) || null;
    return value;
  };

  const getTemplateData = useCallback(async () => {
    try {
      // const { data } = await get(API.GET_TEMPLATES);
      // const templateObj = data;
      // const html = recieptObj?.data?.receipt_template;
      // let recieptValue = null;
      // if (html) {
        // recieptValue = getTemplate(html);
        getTemplate();
      // }
      // setEditorState(recieptValue);
    } catch (e) {
      // setEditorState(null);
    }
  }, []);

  useEffect(() => {
    getTemplateData();
  }, [getTemplateData]);

  
  // const handleFinish = async () => {
  //   const value = draftToHtml(convertToRaw(editorHeaderState.getCurrentContent()));
  //   try {
  //     const formData = new FormData();
  //     formData.append('template', value);
  //     const { data } = await fileUpload(API.ADD_TEMPLATES, formData, {});
  //     toast.success(data.message);
  //     getTemplateData();
  //   } catch (e) {
  //     const errors = e.response?.data?.errors;
  //     Object.keys(errors).forEach((key) => {
  //       toast.error(errors[key][0]);
  //     });
  //   }
  // };

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_TEMPLATES}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleAdd();
        toggleAddTemplateModal();
      }
      return data?.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const templateTypeList = [
    { label: 'Email', value: 'Email' },
    { label: 'Invoice', value: 'Invoice' },
    { label: 'Receipt', value: 'Receipt'},
    { label: 'Quote', value: 'Quote'}
  ];

  return (
    <>
      <Modal
        size="lg"
        show={showAddTemplate}
        onHide={toggleAddTemplateModal}
        dialogClassName="larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          ADD Template
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              template_type: '',
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddDiscountSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                  <div className="form-box">
                    <div className="form-group" style={{display: "flex", alignItems: 'center', gap: 15}}>
                      <label>
                        Template For : 
                      </label>
                      <Select
                        name="template_type"
                        onChange={(e) => {
                          setFieldValue('template_type', e ? e : '');
                        }}
                        onBlur={handleBlur}
                        options={templateTypeList}
                        placeholder="Select Discount Type"
                        classNamePrefix="selectbox"
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "grey",
                          },
                        })}
                        defaultValue={templateTypeList[0]}
                      />
                      <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                        <ErrorMessage name="template_type" />
                      </span>
                    </div>
                    <div className='form-group full-width'>
                      <label>
                        Header
                      </label>
                      <Editor
                        editorState={editorHeaderState}
                        toolbar={{
                          options: ['inline', 'fontSize', 'history'],
                          inline: {
                            options: ['bold', 'italic', 'underline']
                          }
                        }}
                        onEditorStateChange={handleHeaderEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </div>
                    <div className='form-group full-width'>
                      <label>
                        Body
                      </label>
                      <Editor
                        editorState={editorBodyState}
                        toolbar={{
                          options: ['inline', 'fontSize', 'history'],
                          inline: {
                            options: ['bold', 'italic', 'underline']
                          }
                        }}
                        onEditorStateChange={handleBodyEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </div>
                    <div className='form-group full-width'>
                      <label>
                        Footer
                      </label>
                      <Editor
                        editorState={editorFooterState}
                        toolbar={{
                          options: ['inline', 'fontSize', 'history'],
                          inline: {
                            options: ['bold', 'italic', 'underline']
                          }
                        }}
                        onEditorStateChange={handleFooterEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </div>
                  </div>
                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          toggleAddTemplateModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTemplate;
