import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { metaTitle } from "../../../config";
import Jobs from "../jobs/Jobs";
import Tasks from "../tasks/Tasks";

const tabsKey = {
  "job-settings": 1,
  "task-settings": 2,
};

const JobTaskSettings = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("childTab") || "business-settings";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 2 ? "Task/Milestones" : "Jobs/Matters/Projects"
  );

  useEffect(() => {
    window.document.title = `${metaTitle} | ${activeTab}`
  },[activeTab])

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  defaultActiveKey={defaultActiveKey}
                  className="nav nav-tabs"
                  onClick={(event) => {
                    // setActiveKey(
                    //   event?.target["__reactFiber$ytws3iau74l"]?.key
                    // );
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li
                    className={
                      activeTab === "Jobs/Matters/Projects" ? "active" : ""
                    }
                  >
                    <Link
                      key={1}
                    >
                      Jobs/Matters/Projects
                    </Link>
                  </li>
                  <li className={activeTab === "Task/Milestones" ? "active" : ""}>
                    <Link
                      key={2}
                    >
                      Task/Milestones
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Task/Milestones" && <Tasks />}
                {activeTab === "Jobs/Matters/Projects" && <Jobs />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobTaskSettings;
