import { faDownload, faEye, faPaperPlane, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// import ClientDelete from "./ClientDelete";
import CustomTooltip from '../../../../common/CustomTooltip';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spin from '../../../../common/Spin';
import { API, deleteCall, get, s3DefaultPath } from '../../../../../config';
import CreditPreview from './CreditPreview';
import axios from 'axios';

const CreditNoteActionButton = ({
  id,
  handleDelete,
  email,
  status,
  send,
  businessData,
  file,
  numberOption
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showReceiptSend, setShowReceiptSend] = useState(false);
  const [showReceiptReSend, setShowReceiptReSend] = useState(false);
  const [receiptSendLoading, setReceiptSendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const businessId = params?.business_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleReceiptReSendModal = () => {
    setShowReceiptReSend(!showReceiptReSend);
  };

  const toggleReceiptSendModal = () => {
    setShowReceiptSend(!showReceiptSend);
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${s3DefaultPath}${path}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var file = path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'new_aba.aba');
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error('File is not exist.');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleResendReceipt = async () => {
    try {
      setReceiptSendLoading(true);
      const { data } = await get(
        `${API.CREDIT_SEND}/${id}?client_business_id=${businessId}&send=1`
      );
      setReceiptSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleReceiptReSendModal();
      }
      return data?.data;
    } catch (error) {
      setReceiptSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setReceiptSendLoading(false);
      } else {
        toast.error(error?.message);
        setReceiptSendLoading(false);
      }
    }
  };

  const handleSendReceipt = async () => {
    try {
      setReceiptSendLoading(true);
      const { data } = await get(
        `${API.CREDIT_SEND}/${id}?client_business_id=${businessId}&send=1`
      );
      setReceiptSendLoading(false);
      if (data) {
        toast.success(data?.message);
        handleDelete();
        toggleReceiptSendModal();
      }
      return data?.data;
    } catch (error) {
      setReceiptSendLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setReceiptSendLoading(false);
      } else {
        toast.error(error?.message);
        setReceiptSendLoading(false);
      }
    }
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleReceiptDelete = async () => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.RECEIPTS_DELETE}/${id}`);
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <>
      <span>
        <CustomTooltip
          text={'View'}
          children={
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#clientDetail"
            />
          }
        />
        {/* {parseInt(status) !== 1 && (
            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <CustomTooltip
                text={'Edit'}
                children={
                  <Link
                    to={{
                      pathname: `/business/${businessId}/edit-receipt`,
                      search: `?id=${id}&number_option=${numberOption}`
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </Link>
                }
              />
            </>
          )} */}
        {/* {parseInt(status) !== 1 && (
            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <CustomTooltip
                text={'Draft'}
                children={<FontAwesomeIcon icon={faFile} size="1x" />}
              />
            </>
          )}  */}
        {file && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip
              text={'Download Invoice'}
              children={
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  onClick={() => {
                    downloadLetter(file?.file);
                  }}
                />
              }
            />
          </>
        )}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <CustomTooltip
            text={"Delete"}
            children={
              <FontAwesomeIcon
                onClick={toggleDeleteModal}
                icon={faTrash}
                size="1x"
                data-toggle="modal"
                data-target="#deleteClient"
              />
            }
          /> */}
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        {parseInt(send) === 0 ? (
          <CustomTooltip
            text={'Send Credit Note'}
            children={
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="1x"
                onClick={() => toggleReceiptSendModal()}
              />
            }
          />
        ) : (
          <CustomTooltip
            text={'Resend Credit Note'}
            children={
              <FontAwesomeIcon
                icon={faShareSquare}
                size="1x"
                onClick={() => toggleReceiptReSendModal()}
              />
            }
          />
        )}
      </span>

      {showDelete && (
        <Modal
          size="lg"
          show={showDelete}
          onHide={toggleDeleteModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Delete Receipt
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">Are you sure you want to delete this receipt?</div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleReceiptDelete();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showView && (
        <CreditPreview
          showView={showView}
          id={id}
          toggleShowModal={toggleShowModal}
          businessData={businessData}
        />
      )}

      {showReceiptReSend && (
        <Modal
          size="lg"
          show={showReceiptReSend}
          onHide={toggleReceiptReSendModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Resend Credit Note
          </Modal.Header>
          <Modal.Body>
            {receiptSendLoading && <Spin />}
            <div className="modal-body">Do you want to resend credit note to this {email}?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleResendReceipt();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleReceiptReSendModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showReceiptSend && (
        <Modal
          size="lg"
          show={showReceiptSend}
          onHide={toggleReceiptSendModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Send Credit Note
          </Modal.Header>
          <Modal.Body>
            {receiptSendLoading && <Spin />}
            <div className="modal-body">Do you want to send credit note to this {email}?</div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleSendReceipt();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleReceiptSendModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CreditNoteActionButton;
