import React from "react";
import { Image } from 'antd';
import "../../components/Authentication/authentication.css"
import { Button } from "react-bootstrap";


const RegisterAuthentication = (props) => {
    const { setEnterCode, qrImage,secretKey } = props
    const url = localStorage.getItem("sme-auth-email")


    const handleClick = () => {
        setEnterCode(true)
    }


    if (!qrImage) return null
    return (
        <div className="AntContent">
            <div className="step1"><h5 className="heading-step">Step 1:</h5><p className="content-step1"> Open your Authenticator app. </p></div>
            <div className="step2"><h5>Step 2:</h5><p> Add your account to the app by scanning this QR code. </p></div>
            {qrImage &&
                < Image
                    width={200}
                    style={{maxHeight: "200px"}}
                    src={qrImage.replace('https://www.google.com', 'https://chart.googleapis.com')}
                />
            }
            <div><b>OR</b></div>
            <p>Enter a setup key: <b>{secretKey}</b></p>
            <div className="step3"><h5>Step 3:</h5><p> {`Enter the 6-digit code found in your authenticator app under ${url}.`} </p></div>
            <Button onClick={handleClick}>Enter Code</Button>
        </div>
    );
};

export default RegisterAuthentication;
