import React, { useEffect, useState } from "react";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatName,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { Modal } from "react-bootstrap";
import { getCustomerPopupDetail } from "./CustomerHelper";

const CustomerDetailPopup = ({ customerId, toggleShowModal, showView }) => {
  const [customerDetail, setCustomerDetail] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const customerDetailData = await getCustomerPopupDetail(customerId, true);
      setCustomerDetail(customerDetailData);
      setCustomerLoading(false);
    }
    fetchData();
  }, [customerId, setCustomerDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Customer Details
      </Modal.Header>
      <Modal.Body>
        {customerLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {customerDetail?.profile_pic === null ||
                customerDetail?.profile_pic === "" ||
                customerDetail?.profile_pic === undefined ? (
                  <span>{customerDetail?.first_name ? customerDetail?.first_name?.charAt(0) : customerDetail?.last_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${customerDetail?.profile_pic}`}
                    alt=""
                  />
                )}
                <h5>
                  {formatName(
                    customerDetail?.first_name,
                    customerDetail?.last_name
                  )}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>Name:</strong>{" "}
                  {customerDetail?.first_name
                    ? customerDetail?.first_name
                    : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong>{" "}
                  {customerDetail?.last_name ? customerDetail?.last_name : "-"}
                </p>
                <p>
                  <strong>Display Name:</strong>{" "}
                  {customerDetail?.display_name ? customerDetail?.display_name : "-"}
                </p>
                <p>
                  <strong>Email Address:</strong>{" "}
                  {customerDetail?.email ? customerDetail?.email : "-"}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {customerDetail?.phone
                    ? formatPhone(customerDetail?.phone)
                    : "-"}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {customerDetail?.mobile
                    ? formatMobile(customerDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {customerDetail?.business_name
                    ? customerDetail?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {customerDetail?.abn ? formatABN(customerDetail?.abn) : "-"}
                </p>
                <p>
                  <strong>Entity Type:</strong>{" "}
                  {customerDetail?.entity_type
                    ? customerDetail?.entity_type
                    : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    customerDetail?.street,
                    customerDetail?.suburb?.name,
                    customerDetail?.state?.name,
                    customerDetail?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    customerDetail?.shipping_street,
                    customerDetail?.shipping_suburb?.name,
                    customerDetail?.shipping_state?.name,
                    customerDetail?.shipping_postal_code
                  )}
                </p>
                <p>
                  <strong>Ref. Number:</strong>{" "}
                  {customerDetail?.ref_number
                    ? customerDetail?.ref_number
                    : "-"}
                </p>
                <p>
                  <strong>BPay Ref. Number:</strong>{" "}
                  {customerDetail?.bpay_ref_number
                    ? customerDetail?.bpay_ref_number
                    : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CustomerDetailPopup;
