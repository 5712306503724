import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { API, fileUpload } from '../../../../config';
import Pagination from '../../../common/Pagination';
import Spin from '../../../common/Spin';
import { formatNumber } from '../../../common/Misc';

const ExpandedPLReport = ({
  businessId,
  id,
  startDate,
  endDate,
  selectedJobs,
  selectedTasks,
  balanceSheet = false
}) => {
  const [expandPLLoading, setExpandPLLoading] = useState(false);
  const [expandPLData, setExpandPLData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const fetchPLExpandData = useCallback(async () => {
    const formData = new FormData();
    if (selectedJobs?.length > 0) {
      for (let i = 0; i < selectedJobs?.length; i++) {
        if (selectedJobs[i] !== null && selectedJobs[i] !== 'null' && selectedJobs[i] !== '') {
          formData.append(`job_ids[${i}]`, selectedJobs[i]);
        } else {
          formData.append(`job_ids[${i}]`, '');
          formData.append(`job_ids[${i + 1}]`, null);
        }
      }
    }
    if (selectedTasks?.length > 0) {
      for (let j = 0; j < selectedTasks?.length; j++) {
        if (selectedTasks[j] !== null && selectedTasks[j] !== 'null' && selectedTasks[j] !== '') {
          formData.append(`task_ids[${j}]`, selectedTasks[j]);
        } else {
          formData.append(`task_ids[${j}]`, '');
          formData.append(`task_ids[${j + 1}]`, null);
        }
      }
    }
    formData.append('chart_of_account_id', id);
    formData.append('client_business_id', businessId);
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');
    formData.append('export', 0);
    formData.append('limit', limit ? limit : 10);
    formData.append('page', page ? page : 1);

    formData.append('sort_column', sortField ? sortField : 'transactionDate');
    formData.append('sort_order', sortOrder ? sortOrder : 'ASC');

    try {
      if (businessId) {
        setExpandPLLoading(true);
        const { data } = await fileUpload(
          `${
            balanceSheet ? API.GET_BALANCE_SHEET_ENTRIES_DATA : API.GET_PROFIT_AND_LOSS_ENTRIES_DATA
          }`,
          formData
        );
        setExpandPLLoading(false);
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setExpandPLData(data?.data?.data);
      }
    } catch (e) {
      const { data } = e;
      setExpandPLLoading(false);
      setExpandPLData([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    //eslint-disable-next-line
  }, [businessId, startDate, endDate, id, limit, page]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  const PLDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let SplitDate = expandPLData[i]?.bank_transaction?.transactionDate?.split('-');
      let date = `${SplitDate[2]}-${SplitDate[1]}-${SplitDate[0]}`;
      items.push({
        key: expandPLData[i]?.id,
        transaction_id: expandPLData[i]?.bank_transaction?.transaction_id,
        account: `${
          expandPLData[i]?.chart_of_account?.code?.length < 4
            ? padWithLeadingZeros(Number(expandPLData[i]?.chart_of_account?.code?.length), 4)
            : expandPLData[i]?.chart_of_account?.code
        }${
          expandPLData[i]?.chart_of_account?.sub_code
            ? `.${expandPLData[i]?.chart_of_account?.sub_code}`
            : ''
        }`,
        date: date,
        source: expandPLData[i]?.source ? expandPLData[i]?.source : '-',
        description: expandPLData[i]?.description,
        tax_code:
          expandPLData[i]?.gst === 0
            ? 'GST Excluded'
            : expandPLData[i]?.gst === 1
            ? 'GST 10%'
            : expandPLData[i]?.gst === 2
            ? 'BAS Excluded'
            : 'GST on Imports',
        amount: expandPLData[i]?.amount ? `$${formatNumber(expandPLData[i]?.amount)}` : '$0.00',
        net_amount: expandPLData[i]?.net_amount
          ? `$${formatNumber(expandPLData[i]?.net_amount)}`
          : '$0.00',
        gst_amount: expandPLData[i]?.gst_amount
          ? `$${formatNumber(expandPLData[i]?.gst_amount)}`
          : '$0.00'
      });
    }
    return items;
  };
  const plData = PLDataGenerator(expandPLData?.length);

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      }
    },
    {
      dataField: 'description',
      text: 'Description'
    },
    {
      dataField: 'account',
      text: 'Account',
      headerStyle: () => {
        return { width: '10%', textAlign: 'center' };
      },
      style: {
        textAlign: 'center'
      }
    },
    {
      dataField: 'tax_code',
      text: 'Tax Code',
      headerStyle: () => {
        return { width: '14%' };
      }
    },
    {
      dataField: 'net_amount',
      text: 'Amount',
      headerStyle: () => {
        return { width: '20%' };
      }
    }
    // {
    //   dataField: "source",
    //   text: "Source",
    //   headerStyle: () => {
    //     return { width: '20%' };
    //   }
    // }
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchPLExpandData();
  }, [fetchPLExpandData]);

  // const defaultSorted = [
  //   {
  //     dataField: "date",
  //     order: "asc"
  //   }
  // ];

  return (
    <>
      <div className="content-details">
        <div className="custom-table trial-balance-expand-table">
          {expandPLLoading && <Spin />}
          <BootstrapTable
            remote
            keyField="key"
            data={plData}
            columns={columns}
            noDataIndication="No Data Found"
            onTableChange={handleTableChange}
            // defaultSorted={defaultSorted}
          />
          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default ExpandedPLReport;
