import moment from "moment";

/**
 *
 * @param {Phone Number} phone
 * @returns {Formated Phone Number}
 */
export const formatPhone = (phone) => {
  let format =
    phone.slice(0, 3) === "+61"
      ? phone.slice(0, 3) +
        " " +
        phone.slice(3, 4) +
        " " +
        phone.slice(4, 8) +
        " " +
        phone.slice(8, 12)
      : "+61 " +
        phone.slice(0, 1) +
        " " +
        phone.slice(1, 5) +
        " " +
        phone.slice(5, 9);
  return format;
};

/**
 *
 * @param {Mobile Number} mobile
 * @returns {Formated Mobile Number}
 */
export const formatMobile = (mobile) => {
  let format =
    mobile.slice(0, 3) === "+61"
      ? mobile.slice(0, 3) +
        " " +
        mobile.slice(3, 6) +
        " " +
        mobile.slice(6, 9) +
        " " +
        mobile.slice(9, 12)
      : "+61 " +
        mobile.slice(0, 3) +
        " " +
        mobile.slice(3, 6) +
        " " +
        mobile.slice(6, 9);
  return format;
};

/**
 *
 * @param {ABN Number} abn
 * @returns {Formated ABN Number}
 */
export const formatABN = (abn) => {
  let abnString = abn.toString();
  let format =
    abnString.slice(0, 2) +
    " " +
    abnString.slice(2, 5) +
    " " +
    abnString.slice(5, 8) +
    " " +
    abnString.slice(8, 11);

  return format;
};

/**
 *
 * @param {First Name} firstName
 * @param {Last Name} lastName
 * @returns {Formated Full Name}
 */
export const formatName = (firstName, lastName) => {
  let format = firstName ? firstName + " " + lastName : lastName;
  return format;
};

/**
 *
 * @param {Street Name} street
 * @param {Suburb Name} suburb
 * @returns {Formated Office Name}
 */
export const formatOfficeName = (street, suburb) => {
  let format = street
    ? street + (suburb ? ", " + suburb : "")
    : suburb
    ? suburb
    : "";
  return format;
};

/**
 *
 * @param {Street Name} street
 * @param {Suburb Name} suburb
 * @param {State Name} state
 * @param {Postal Code} code
 * @returns {Formated Full Address}
 */
export const formatFullAddress = (street, suburb, state, code) => {
  let format = (
    <p>
      {street && street + ","} {suburb && suburb + ","} {state && state + ","}{" "}
      {code && code + " "}
    </p>
  );

  return format;
};

export const checkIfFileExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

export const formatNumber = (number) => {
  const formatedNumber =
    typeof number === "number"
      ? number.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : 0.0;

  return formatedNumber;
};

export const formatNumberWithoutComma = (number) => {
  const formatedNumber = parseFloat(number)
    ? parseFloat(number).toFixed(2)
    : 0.0;

  return formatedNumber;
};

/**
 *
 * @param {Street Name} street
 * @param {Suburb Name} suburb
 * @param {State Name} state
 * @param {Postal Code} code
 * @returns
 */
export const formatedAddress = (street, suburb, state, code) => {
  if (!street && !suburb && !state && !code) return "-";
  let address = "";
  if (street) address = `${street}${code || state || suburb ? ", " : ""}`;
  if (suburb) address += `${suburb}${code || state ? ", " : ""}`;
  if (state) address += `${state}${code ? ", " : ""}`;
  if (code) address += `${formatedPostalCode(code)}`;

  return address;
};

const today = new Date();
export const pastFinancialdate =
  parseInt(moment(today).format("MM")) === 6
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 6
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

export const formatedPostalCode = (n) => {
  const length = n ? n.toString().length : "";

  const format = length
    ? length === 4
      ? n
      : length === 3
      ? `0${n}`
      : length === 2
      ? `00${n}`
      : length === 1
      ? `000${n}`
      : ""
    : "";

  return format;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};
