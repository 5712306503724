import { API, get } from "../../../config";

export const getUserDetail = async (clientId) => {
  try {
    const { data } = await get(`${API.GET_USER_PROFILE}`);
    return data.data;
  } catch (e) {
    return null;
  }
};
