import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ErrorMessage } from "formik";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleShowModal,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleShowModal: PropTypes.any,
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group full-width">
          <label>
            <span className="required">*</span>Code
          </label>
          <input
            type="text"
            name="code"
            value={values?.code ? values?.code : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="code" />
          </span>
        </div>
        <div className="form-group full-width">
          <label>
            <span className="required">*</span>Description
          </label>
          <textarea
            type="text"
            name="description"
            value={values?.description ? values?.description : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
            style={{
              borderRadius: "10px",
              margin: "0 0 15px",
              minHeight: "70px",
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="description" />
          </span>
        </div>
      </div>
      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleShowModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
