import React, { useCallback, useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { API, fileUpload, get } from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import Pagination from "../../common/Pagination";
import moment from "moment";

import ReconcileItemActionButton from "./components/ReconcileItemActionButton";
// import { pastFinancialdate } from "../../common/Misc";
import Select from "react-select";
import ExpandReconcileItem from "./components/ExpandReconcileItem";
import { debounce } from "lodash";
import { formatName } from "../../common/Misc";

const ReconcileItem = ({ setBalanceInfo, setValueRange, valueRange, bankAccountId, setBankAccountId }) => {
  // const { afterToday } = DateRangePicker;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const navigate = useNavigate();
  const params = useParams();
  const businessId = params?.business_id;
  const [reconcileItemList, setReconcileItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  const [isImportLoading, setIsImportLoading] = useState(false);

  // const [valueRange, setValueRange] = useState(pastFinancialdate);

  const [exportLoading, setExportLoading] = useState(false);
  const [bankAccountOptions, setBankAccountOptions] = useState();
  // const [bankAccountId ,setBankAccountId] = useState('');

  const [selectedRow, setSelectedRow] = useState([]);
  const [showUndoAll, setShowUndoAll] = useState(false);

  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [accountLoading, setAccountLoading] = useState(false);
  const [accountData, setAccountData] = useState();
  const [allAccountValue, setAllAccountValue] = useState([]);

  const [allContactList, setAllContactList] = useState([]);
  const [loadingContactList, setLoadingContactList] = useState(false);
  const [allContactValue, setAllContactValue] = useState([]);

  const fetchReconcileItem = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${
          API.BANK_RECONCILIATION_LIST
        }?client_business_id=${businessId}&limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&processed=1${bankAccountId ? '&yodlee_account_id=' + bankAccountId : ''}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&search=${searchParam ? searchParam : ""}`
      );

      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      let accountActiveList = [];
      if(data?.accounts){
        accountActiveList = data?.accounts?.filter((i) => i?.accountStatus === 1 )
      }
      setBalanceInfo(accountActiveList);
      const accountList = 
        data?.accounts?.length > 0 &&
        data?.accounts?.map((item) => {
          const list = {
            label: item?.provider?.providerName + (item?.accountNumber ? "-" + item?.accountNumber + (item?.accountStatus === 0 ? '(Inactive)' : '(Active)') : ""),
            value: item?.id
          };
          return list;
        });
        setBankAccountOptions(accountList && [ { label: "ALL", value: ''}, ...accountList]);
      setReconcileItemList(data?.data?.data);

      setLoading(false);
    } catch (e) {
      const { data } = e;
      setLoading(false);
      setReconcileItemList([]);
      setBalanceInfo([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
    navigate(
      `/business/${businessId}/reconcile-transactions?tab=reconcile-item&page=${
        page ? page : 1
      }&limit=${limit ? limit : 10}&search=${searchParam ? searchParam : ""}`
    );
  }, [navigate, businessId, limit, page, setBalanceInfo, valueRange, bankAccountId, sortField, sortOrder, searchParam]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const getAccounts = useCallback(async (baseType) => {
    try {
      setAccountLoading(true);
      get(
        `${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`
        ).then((response) => {
          setAccountLoading(false);
          var revenue =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return (
                    element?.account_type_id === "fada83c9-4769-49b9-ab0e-fcd8c43de30e"
                  );
                })
              : [];
  
          const revenueList =
            revenue && revenue?.length > 0
              ? revenue?.map((item, index) => {
                  const list = {
                    label:
                    // eslint-disable-next-line
                    item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                    value: item?.code !== "2000" && (item?.id),
                    tax: item?.code !== "2000" && (item?.tax),
                    type: item?.code !== "2000" && (item?.type),
                  };
                  return list ? list : [];
                })
              : [];
  
            var expenses =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fad90f00-6651-4c51-a29a-78398fa226fd"
                    );
                  })
                : [];
    
            const expensesList =
              expenses && expenses?.length > 0
                ? expenses?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var currentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "3e5a94ef-b819-47c3-9955-d47da29e2ab0"
                    );
                  })
                : [];
    
            const currentLiabilitiesList =
              currentLiabilities && currentLiabilities?.length > 0
                ? currentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentLiabilities =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "fcb7e129-7666-42ae-8c3d-11d016bbe340"
                    );
                  })
                : [];
        
            const nonCurrentLiabilitiesList =
              nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
                ? nonCurrentLiabilities?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
            
            var currentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "994d6d38-497b-465d-9f57-3bdc2d70594a"
                    );
                  })
                : [];
          
            const currentAssetsList =
              currentAssets && currentAssets?.length > 0
                ? currentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var nonCurrentAssets =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "4051efd3-9fb8-4adb-b0c7-7e565a078ac1"
                    );
                  })
                : [];
            
            const nonCurrentAssetsList =
              nonCurrentAssets && nonCurrentAssets?.length > 0
                ? nonCurrentAssets?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
            var equity =
              response?.data?.data?.data?.length > 0
                ? response?.data?.data?.data.filter((element) => {
                    return (
                      element?.account_type_id === "520f936e-3017-4574-a9ab-6c9e68b591e4"
                    );
                  })
                : [];
              
            const equityList =
              equity && equity?.length > 0
                ? equity?.map((item) => {
                    const list = {
                      label:
                      // eslint-disable-next-line
                      item?.code !== "2000" && ((`${item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code}`) + (`${item?.sub_code ? "." + item?.sub_code : ""}`) + " - " + (`${item?.name ? item?.name : ""}`) + (`${item?.account_type?.name ? " (" + item?.account_type?.name + ")" : ""}`)),
                      value: item?.code !== "2000" && (item?.id),
                      tax: item?.code !== "2000" && (item?.tax),
                      type: item?.code !== "2000" && (item?.type),
                    };
                    return list ? list : [];
                  })
                : [];
  
              const allAccountListing = [
              revenueList && { label: <div className="list-subHeading">Revenue</div>, options: revenueList },
              expensesList && { label: <div className="list-subHeading">Expenses</div>, options: expensesList },
              currentLiabilitiesList && { label: <div className="list-subHeading">Current Liabilities</div>, options: currentLiabilitiesList },
              nonCurrentLiabilitiesList && { label: <div className="list-subHeading">Non Current Liabilities</div>, options: nonCurrentLiabilitiesList },
              currentAssetsList && { label: <div className="list-subHeading">Current Assets</div>, options: currentAssetsList},
              nonCurrentAssetsList && { label: <div className="list-subHeading">Non Current Assets</div>, options: nonCurrentAssetsList},
              equityList && { label: <div className="list-subHeading">Equity</div>, options: equityList},
              { label: <strong>+ Add New Account</strong>, value: "new" }
            ];
  
            setAccountData(allAccountListing)
            setAllAccountValue([
              ...revenueList,
              ...expensesList,
              ...currentLiabilitiesList,
              ...nonCurrentLiabilitiesList,
              ...currentAssetsList,
              ...nonCurrentAssetsList,
              ...equityList
            ])
        });
    } catch (e) {
      setAccountLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const handleContactList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingContactList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

            const allContactListing = [
              contractorList && { label: <div className="list-subHeading">Contractors</div>, options: contractorList },
              supplierList && { label: <div className="list-subHeading">Suppliers</div>, options: supplierList },
              customerList && { label: <div className="list-subHeading">Customers</div>, options: customerList },
              { label: <strong>+ Add New Contact</strong>, value: "new" }
            ];

        setAllContactValue([
          ...contractorList,
          ...supplierList,
          ...customerList,
        ]);

        setAllContactList(allContactListing);

        setLoading(false);
        setLoadingContactList(false);
      });
    } catch (error) {
      setLoading(false);
      setLoadingContactList(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const handleUndo = () => {
    fetchReconcileItem();
  };

  const handleExport = async (format) => {
    try {
      setExportLoading(true);
      const { data } = await get(
        `${API.BANK_RECONCILIATION_LIST}?from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${
          valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""
        }&export=1&processed=1&format=${format}&client_business_id=${businessId}${bankAccountId ? '&yodlee_account_id=' + bankAccountId : ''}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "bank_reconciled_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const reconcileItemGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let sorted_date = reconcileItemList[i]?.bank_reconciliation.sort(function (a, b) {
        let tempB = b.updated_at.split(" ");
        let tempA = a.updated_at.split(" ");
        let dateB = tempB[0]?.split("-");
        let timeB = tempB[1]?.split(":");
        let dateA = tempA[0]?.split("-");
        let timeA = tempA[1]?.split(":");
        return new Date(dateB[2], dateB[1], dateB[0], timeB[0], timeB[1], timeB[2]) - new Date(dateA[2], dateA[1], dateA[0], timeA[0], timeA[1], timeA[2]);
      })
      let sorted_date_time = sorted_date[0]?.updated_at?.split(":")
      items.push({
        key: reconcileItemList[i]?.id,
        transactionDate: reconcileItemList[i]?.transactionDate
          ? reconcileItemList[i]?.transactionDate
          : "-",
        description: reconcileItemList[i]?.description
          ? reconcileItemList[i]?.description
          : "",
        bank_reconciliation: reconcileItemList[i]?.bank_reconciliation ? reconcileItemList[i]?.bank_reconciliation : '',
        invoice: reconcileItemList[i]?.invoice ? reconcileItemList[i]?.invoice : '',
        baseType: reconcileItemList[i]?.baseType === 1 ? 1 : 0,
        updated_at: sorted_date_time ? sorted_date_time[0] + ":" + sorted_date_time[1] : "-",
        credit:
          reconcileItemList[i]?.baseType === 1 ? (
            <span className="rag-green">
              $
              {reconcileItemList[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        debit:
          reconcileItemList[i]?.baseType === 0 ? (
            <span className="rag-red">
              $
              {reconcileItemList[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        bank_transaction_source: reconcileItemList[i]?.source
          ? reconcileItemList[i]?.source
          : "-",
        reconciliation_method: reconcileItemList[i]?.term_id ? "Memorisation" : "Manual",
        chart_of_account: reconcileItemList[i]?.bank_reconciliation.length > 1 ? ""  : `${reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account?.code?.length < 4 ? padWithLeadingZeros(Number(reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account?.code?.length), 4) : reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account?.code}${reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account?.sub_code ? `.${reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account?.sub_code}` : ""} - ${reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account?.name}`,
        action: (
          <ReconcileItemActionButton
            handleUndo={handleUndo}
            id={reconcileItemList[i]?.id}
            businessId={businessId}
            type={reconcileItemList[i]?.baseType}
            reconcileItemList={reconcileItemList}
            setIsImportLoading={setIsImportLoading}
            loading={isImportLoading}
            bank_reconciliation={reconcileItemList[i]?.bank_reconciliation}
            chart_of_accountId={reconcileItemList[i]?.bank_reconciliation[0]?.chart_of_account_id}
            accountLoading={accountLoading}
            accountData={accountData}
            allAccountValue={allAccountValue}
            getAccounts={getAccounts}
            allContactList={allContactList}
            handleContactList={handleContactList}
            loadingContactList={loadingContactList}
            allContactValue={allContactValue}
          />
        ),
      });
    }
    return items;
  };

  const reconcileItem = reconcileItemGenerator(reconcileItemList?.length);

  const columns = [
    {
      dataField: "transactionDate",
      text: "Date",
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      }
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
      headerStyle: () => {
        return { width: '20%' };
      }
    },
    {
      dataField: "credit",
      text: "Credit",
      // sort: true,
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    {
      dataField: "debit",
      text: "Debit",
      // sort: true,
      headerStyle: () => {
        return { width: '9%' };
      }
    },
    {
      dataField: "bank_transaction_source",
      text: "Transaction Source",
      headerStyle: () => {
        return { width: '10%' };
      }
      // sort: true,
    },
    {
      dataField: "reconciliation_method",
      text: "Method",
      headerStyle: () => {
        return { width: '6%' };
      }
      // sort: true,
    },
    {
      dataField: "chart_of_account",
      text: "Chart of Account",
      headerStyle: () => {
        return { width: '16%' };
      }
      // sort: true,
    },
    {
      dataField: "updated_at",
      text: "Updated On",
      headerStyle: () => {
        return { width: '10%' };
      }
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleDateRange = (e) => {
    cleanFilters();
    setValueRange(e);
    setPage(1);
    setBankAccountId(bankAccountId);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchReconcileItem();
  }, [fetchReconcileItem, isImportLoading]);

  const handleBankAccountChange = (item) => {
    cleanFilters();
    setBankAccountId(item?.value);
    // setBankTransactionData([]);
    setPage(1);
    
  }

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder);
    }
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandReconcileItem
        data={row}
        setIsImportLoading={setIsImportLoading}
        isImportLoading={isImportLoading}
        handleUndo={handleUndo}
        />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: [],
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  }

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }
  
  const dateFilterRange = [
    {
      label: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
  ]
  

  let singleSelectedRows = [];
  let allSelectedRows = [];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ['not-select'],
    selected: selectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        singleSelectedRows = [...selectedRow, row?.key];
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      }
      else {
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    }
  };

  const ref = useRef();
  function cleanFilters() {
    //unselet table
    if (ref && typeof ref === "object") {
      if (ref.current !== undefined) {
        ref.current.selectionContext.selected = [];
      }
    }
    setSelectedRow([]);
  }

  const toggleUndoAllModal = () => {
    setShowUndoAll(!showUndoAll);
  }

  const handleUndoAll = async() => {
    const formData = new FormData();
    if(selectedRow?.length > 0){
      for(let i=0; i<selectedRow?.length; i++){
        formData.append(`transaction_ids[${i}]`, selectedRow[i])
      }
    }
    formData.append("client_business_id", businessId);
    bankAccountId && formData.append("yodlee_account_id", bankAccountId);
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BANK_RECONCILED_TRANSACTION_UNDO_ALL}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleUndoAllModal();
        cleanFilters();
        setSelectedRow([]);
        fetchReconcileItem();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  }

    //eslint-disable-next-line
    const debouncedSave = useCallback(
      debounce(nextValue => {
            setSearchParam(nextValue)
            setPage(1)
          }, 300),
      [], 
    );
  
    const handleSearchChange = event => {
      const { value: nextValue } = event.target;
      setSearchParamData(nextValue);
      if(nextValue?.length >= 2 || nextValue?.length === 0){
        debouncedSave(nextValue);
      }
    };

  return (
    <>
      <div className="cms-page">
        <div className="table-top-btn">
          <div className="search-section">
            <Form>
              <FormControl
                onChange={handleSearchChange}
                type="text"
                value={searchParamData}
                placeholder="Search Reconciled Item"
              />
              {searchParamData 
              ? <Button className="btn-close" onClick={() => {
                setSearchParamData("")
                setSearchParam("")
              }}
              >x</Button> 
              : <Button className="fa fa-search">Search</Button>}
            </Form>
          </div>
          <Button
              variant="primary"
              onClick={() => {
                toggleUndoAllModal();
              }}
              style={{
                marginLeft: "10px"
              }}
              disabled={((selectedRow?.length === 0) ? true : false)}
            >
            {"Unallocate"}
            </Button>
          <div className="table-btn">
            <div className="reconcile-bank-account">
              <div className="bank-account-dropdown">
                <label>Account</label>
                <Select 
                style={{
                  width: "50%",
                  margin: "0",
                }}
                placeholder="Select Account"
                value={bankAccountId ? bankAccountOptions?.find((i) => i.value === bankAccountId) : {label: 'ALL', value: ''}}
                onChange={(item) => {
                  handleBankAccountChange(item);
                }}
                loading={loading}
                classNamePrefix="account_select_bank"
                options={bankAccountOptions}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
                />
              </div>
            </div>
            <Form>
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={(e) => handleDateRange(e)}
                format="dd-MM-yyyy"
                // disabledDate={afterToday()}
                placement="bottomEnd"
                value={valueRange}
                ranges={dateFilterRange}
                locale={{ok: "Apply"}}
              />
            </Form>
            <Dropdown>
              <Dropdown.Toggle variant="primary" /*onClick={handleExport}*/>
                {exportLoading ? "Loading…" : "Export"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport("csv")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport("pdf")}>
                  PDF
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport("xlsx")}>
                  Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <p
          style={{
            textAlign: "right",
            fontSize: "12px",
            margin: "10px 0",
            fontWeight: "700",
          }}
        >
          <a
            href="https://trust-easy-file-storage.s3.ap-southeast-2.amazonaws.com/public/common/docs/BankFeedsintoHL.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000" }}
          >
            Instructions on HandiLedger Import
          </a>
        </p>
        <div className="custom-table reconcile-items-table">
          {(loading || accountLoading) && <Spin />}
          <BootstrapTable
            keyField="key"
            remote
            data={reconcileItem}
            expandRow={expandRow}
            ref={ref}
            selectRow={selectRow}
            onTableChange={handleTableChange}
            columns={columns}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={Number(page)}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>

      {/* Modal of Undo All */}
      {showUndoAll && (
        <Modal
          size="lg"
          show={showUndoAll}
          onHide={toggleUndoAllModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Unallocate Reconciled Transaction
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Are you sure you want to unallocate selected reconciled transactions?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleUndoAllModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleUndoAll();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ReconcileItem;
