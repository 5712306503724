import React, { useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import { AddJobSchema } from '../../../common/Validation';
import Spin from '../../../common/Spin';
import { API, fileUpload } from '../../../../config';

const AddNewJobModal = ({
  handleAddNewJob,
  businessId,
  addNewJob,
  setNewJob,
  handleJobChange,
  newJobCode,
  setFinalData,
  finalData,
  index
}) => {
  const [loading, setLoading] = useState(false);

  const [jobData, setJobData] = useState();

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    formData.append('client_business_id', businessId);
    formData.append('code', value?.code);
    formData.append('description', value?.description ? value?.description : '');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.JOBS_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleAddNewJob();
        handleJobChange();
        let temp = {
          label:
            data?.data?.code +
            (data?.data?.description ? ' (' + data?.data?.description + ')' : ''),
          value: data?.data?.id
        };
        setNewJob(temp);
        if (setFinalData) {
          finalData[index].job_id = temp?.value;
          setFinalData(finalData);
        }
      }
      setJobData(value);
      // return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={addNewJob}
        onHide={handleAddNewJob}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Add New Job
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              job_id: jobData?.job_id,
              code: newJobCode ? newJobCode : jobData?.code,
              description: jobData?.description
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddJobSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <form onSubmit={handleSubmit} noValidate className="entry-section">
                    <div className="form-box">
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>Code
                        </label>
                        <input
                          type="text"
                          name="code"
                          value={values?.code ? values?.code : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="code" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>
                          <span className="required">*</span>Description
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          value={values?.description ? values?.description : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          style={{
                            borderRadius: '6px',
                            margin: '0 0 0px',
                            minHeight: '70px'
                          }}
                        />
                        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          handleAddNewJob();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewJobModal;
