import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const SubscriptionNotFound = () => {
  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate('/general-settings?tab=subscriptions', { replace: true });
  };

  const role = localStorage.getItem("sme-role");

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className={`${role !== "Client" ? "subscription-notfound" : "subscription-notfound client-subscription-notfound"} full-content-block`}>
          <h3>Subscription required</h3>
          {/* <p>Please Click on below button to Active your Subscription First</p> */}
          <p>Please click the button below to activate your subscription.</p>

          <Button className="btn-primary btn" onClick={redirectLogin}>
            Check your plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionNotFound;