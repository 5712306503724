import React, { useCallback, useEffect } from "react";
import BackButton from "../../components/Form/BackButton";
import { MonthRangePicker } from "@semcore/date-picker";
import { formatName, pastFinancialdate } from "../common/Misc";
import { useState } from "react";
import moment from "moment";
// import { getClientDetail } from "./clients/components/ClientHelper";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../common/Spin";
import { API, get, metaTitle } from "../../config";
import { toast } from "react-toastify";
import { Bar, Tooltip, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, PieChart, Pie, Cell } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const today = new Date();

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(
    new Date(today.getFullYear(), today.getMonth() + i, 1)
  );
}

const ClientDashboard = () => {

  const params = useParams();
  const clientId = params?.client_id;
  // const role = localStorage.getItem("sme-role");
  const [loading, setLoading] = useState(false);
  // const [clientDetail, setClientDetail] = useState(null);
  const [ clientItemID, setClientItemID ] = useState(clientId);
  // const [clientId, setClientId] = useState(null);
  const navigate = useNavigate();

  const [businessCount, setBusinessCount] = useState(null);
  const [expRevChartData, setExpRevChartData] = useState([]);
  const [expChartData, setExpChartData] = useState([]);
  const [revChartData, setRevChartData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [clientLoading, setClientLoading] = useState(false);
  const [clientValue, setClientValue] = useState(clientId);

  const defaultPiChartData = [{ value: 20, name: "No Data", total: 200 },{ value: 30, name: "No Data", total: 200 },{ value: 50, name: "No Data", total: 200 }];

  const [valueRange, setValueRange] = useState(pastFinancialdate);
  const monthRangeDisplay =
    moment(valueRange[0]).format("yyyy") ===
    moment(valueRange[1]).format("yyyy")
      ? `${moment(valueRange[0]).format("MMM")}-${moment(valueRange[1]).format(
          "MMM"
        )}, ${moment(valueRange[0]).format("yyyy")}`
      : `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format(
          "yyyy"
        )} - ${moment(valueRange[1]).format("MMM")} ${moment(
          valueRange[1]
        ).format("yyyy")}`;
  

  // // Fetching of Client Details
  // const fetchClientData = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const clientDetailData = await getClientDetail(clientID);
  //     setClientId(clientDetailData?.client?.id)
  //     setClientDetail(clientDetailData);
  //     setLoading(false);
  //   } catch (e) {
  //     const { data } = e;
  //     setLoading(false);
  //     toast.error(
  //       data &&
  //         data.errors &&
  //         data.errors.myna_error &&
  //         data.errors.myna_error[0]
  //     );
  //   }
  // }, [clientID]);

  // useEffect(() => {
  //   fetchClientData();
    
  // }, [fetchClientData,]);

  useEffect(() => {
    navigate(`/client/${clientItemID}`);
    setClientValue(clientItemID);
    window.document.title = `${metaTitle} | Client Dashboard`
  }, [clientItemID, navigate]);

  // Fetch Bussiness Data
  const fetchBusiness = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_BUSINESS}?client_id=${clientId ? clientId : ""}`);
      setLoading(false);
      setBusinessCount(data?.data?.data?.length);

    } catch (e) {
      const { data } = e;
      setLoading(false);
      setBusinessCount(0);
      toast.error(
        data &&
        data.errors &&
        data.errors.myna_error &&
        data.errors.myna_error[0]
      );
    }
  }, [clientId]);

  useEffect(() => {
    if(clientId !== null) { 
      fetchBusiness();
    }
  }, [fetchBusiness, clientId]);

  // Fetch of Bank Reconile Data
  const fetchBankReconcileData = useCallback(async () => {
    if (clientId) {
      try {
        setLoading(true);
        // const { data } = await fileUpload(
        //   `${API.GET_BANK_CONCILEDATA}?client_business_id=${
        //     clientID ? clientID : ""
        //   }&from_month=${
        //     moment(valueRange[0]).format("MM-YYYY")
        //       ? moment(valueRange[0]).format("MM-YYYY")
        //       : ""
        //   }&to_month=${
        //     moment(valueRange[1]).format("MM-YYYY")
        //       ? moment(valueRange[1]).format("MM-YYYY")
        //       : ""
        //   }`
        // );
        setLoading(false);

        // const expRevData = data?.data?.exRevChart ? data?.data?.exRevChart : [];
        setExpRevChartData(
          // dummy Data
          [
            { month: 'Jul 2023', Expenses: 10, Revenue: 50 }, { month: 'Aug 2023', Expenses: 20, Revenue: 30 }
          ]

          // expRevData
        );

        // const expData = [];

        // if (expData) {
        //   // const finalData = expData?.map((item) => (item?.value === 0 ? 0 : 1));
        //   // setExpChartData(
        //   //   removeDuplicates(finalData)?.length === 1 &&
        //   //     removeDuplicates(finalData)[0] === 0
        //   //     ? defaultPiChartData
        //   //     : expData.filter((item) => parseInt(item.value) !== 0)
        //   // );
        //   setExpChartData(expData);
        // } else {
        setExpChartData(defaultPiChartData);
        // }

        // setExpChartData(expData);

        // const revData = [];

        // if (revData) {
        //   const finalData = revData?.map((item) => (item?.value === 0 ? 0 : 1));
        //   setRevChartData(
        //     removeDuplicates(finalData)?.length === 1 &&
        //       removeDuplicates(finalData)[0] === 0
        //       ? defaultPiChartData
        //       : revData.filter((item) => parseInt(item.value) !== 0)
        //   );
        // } else {
        setRevChartData(defaultPiChartData);
        // }

        // setRevChartData(revData);

        // return data.data;
      } catch (e) {
        setLoading(false);
        const { data } = e;
        toast.error(
          data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
        );
      }
    }
    // eslint-disable-next-line
  }, [clientId, valueRange]);

  useEffect(() => {
    fetchBankReconcileData();
  }, [fetchBankReconcileData]);

  // ToolTips
  const CustomTooltipForExp = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>
            {payload && payload?.length > 0
              ? `${payload[0].name} (${payload[0].value}%)`
              : ""}
          </label>
          <br />
          <label>
            {payload && payload?.length > 0
              ? `$${payload[0].payload.total.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
              : ""}
          </label>
        </div>
      );
    }

    return null;
  };

  const CustomExpRevTooltip = ({ active, payload, label }) => {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label>
          {payload && payload?.length > 0
            ? `${payload[0]?.payload?.month}`
            : ""}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `Revenue : $${payload[0]?.payload?.Revenue?.toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
            : ""}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `Expenses : $${payload[0]?.payload?.Expenses?.toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
            : ""}
        </label>
      </div>
    );
  };

  const CustomTooltipForRev = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>
            {payload && payload?.length > 0
              ? `${payload[0].name} (${payload[0].value}%)`
              : ""}
          </label>
          <br />
          <label>
            {payload && payload?.length > 0
              ? `$${payload[0].payload.total.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
              : ""}
          </label>
        </div>
      );
    }

    return null;
  };

  // Fetch Clients
  const fetchClients = useCallback(async () => {
    try {
      setClientLoading(true);
      const { data } = await get(`${API.GET_CLIENTS}?limit=-1`);
      setClientLoading(false);
      const clientListing =
        data &&
        data?.data?.data?.length > 0 &&
        data?.data?.data?.map((item) => {
          const list = {
            label: formatName(item?.first_name, item?.last_name),
            value: item?.client?.id,
            businessName:  item?.client?.business_name
          };
          return list;
        });
      setClientsList(clientListing ? clientListing : []);
      return data.data;
    } catch (e) {
      const { data } = e;
      setClientLoading(false);
      setClientsList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const COLORS = [
    "#1F78B4",
    "#1B9BDF",
    "#034262",
    "#C7DAE3",
    "#A6CEE3",
    "#B2DF8A",
    "#d3f7b2",
    "#2b6a8a",
    "#6a9aba",
    "#0f71a3",
  ];

  const totalRev =
    revChartData && revChartData?.length > 0
      ? revChartData?.map((item) => {
          return parseFloat(item?.total);
        })
      : [];
  const totalRevenue =
    totalRev && totalRev?.length > 0
      ? totalRev.reduce((partialSum, a) => partialSum + a, 0)
      : 0;

  const totalExp =
    expChartData && expChartData?.length > 0
      ? expChartData?.map((item) => {
          return parseFloat(item?.total);
        })
      : [];
  const totalExpenditures =
    totalExp && totalExp?.length > 0
      ? totalExp.reduce((partialSum, a) => partialSum + a, 0)
      : 0;
      
  // const [showInviteAccountingFirm, setShowInviteAccountingFirm] = useState(false);

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }

  const dateFilterRange = [
    {
      children: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)]
    },
    {
      children: 'This Quarter',
      value: quarterRange
    },
    {
      children: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)]
    },
    {
      children: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)]
    },
    {
      children: 'Last Quarter',
      value: perviousQuarterRange
    },
    {
      children: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)]
    }
  ]

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <div className="page-title">
            <BackButton />
            <Select
              name="client_id"
              onChange={(e) => {
                setClientItemID(e?.value);
                setClientValue(e?.value);
              }}
              value={clientsList?.find(
                (item) => item?.value === clientValue
              )}
              options={clientsList}
              placeholder="Select Client"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              isLoading={clientLoading || loading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <div className="page-title-right">
              {clientsList?.find(
                (item) => item?.value === clientValue
              )?.businessName}
              {/* {formatName(clientDetail?.first_name, clientDetail?.last_name)} */}
            </div>
          </div>
          <div className="dashboard-container">
            {loading && <Spin />}

            <div className="row">
              <div className="card col-md-12">
                <>
                  <div className="summary-filter">
                    <MonthRangePicker
                      value={valueRange}
                      onChange={(date) => {
                        setValueRange(date);
                      }}
                      className="datefilter"
                      disabled={disabledFutureDate}
                      periods={dateFilterRange}
                    />
                  </div>
                </>
              </div>
              <div className="card col-md-8">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Revenue Vs Expenses</h5>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer
                        width={"100%"}
                        height={280}
                        debounce={50}
                      >
                        <BarChart
                          data={expRevChartData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 45,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="month"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                          />
                          <YAxis />
                          <Tooltip content={<CustomExpRevTooltip />} />
                          <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            wrapperStyle={{
                              top: 250,
                            }}
                          />
                          <Bar dataKey="Revenue" barSize={20} fill="#012F4E" />
                          <Bar dataKey="Expenses" barSize={20} fill="#0DCAF0" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 active-cards">
                <div className="row">
                  <div
                    className="card col-md-6"
                    onClick={() => {
                      navigate(`/client/businesses/${clientId}`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Total Bussiness</h6>
                          <h4>
                            {businessCount ? businessCount : "0"}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {contractorData?.last_added
                            ? moment(
                                contractorData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    onClick={() => {
                      navigate(`/client/client-users/${clientId}`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Total Users</h6>
                          <h4>
                            0
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {contractorData?.last_added
                            ? moment(
                                contractorData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-12"
                    onClick={() => {
                      navigate(`/client/offices/${clientId}`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Total Offices</h6>
                          <h4>
                            0
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {contractorData?.last_added
                            ? moment(
                                contractorData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Revenue</h5>
                      <div style={{ marginLeft: "auto" }}>
                        <strong>Period : </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer
                        width={"73%"}
                        height={400}
                        debounce={50}
                      >
                        <PieChart>
                          <Pie
                            data={revChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={1}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={true}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                            }) => {
                              const RADIAN = Math.PI / 180;
                              const radius =
                                25 + innerRadius + (outerRadius - innerRadius);
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <text
                                  x={x}
                                  y={y}
                                  key={`cell-${index}`}
                                  fill={"#000000"}
                                  textAnchor={x > cx ? "start" : "end"}
                                  dominantBaseline="central"
                                >
                                  {value ? `${value}%` : "0.00"}
                                </text>
                              );
                            }}
                          >
                            {revChartData.map((entry, index) => (
                              <>
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              </>
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForRev />} />
                        </PieChart>
                      </ResponsiveContainer>
                      <ul className="chart-legends">
                        {revChartData &&
                          revChartData?.length > 0 &&
                          revChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length],
                                  }}
                                ></FontAwesomeIcon>{" "}
                                {item?.name}
                              </p>
                            );
                          })}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "-17px",
                        float: "left",
                        width: "73%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {totalRevenue.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Expenditures</h5>
                      <div style={{ marginLeft: "auto" }}>
                        <strong>Period : </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer
                        width={"73%"}
                        height={400}
                        debounce={50}
                      >
                        <PieChart>
                          <Pie
                            data={expChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={1}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={true}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                            }) => {
                              const RADIAN = Math.PI / 180;
                              const radius =
                                25 + innerRadius + (outerRadius - innerRadius);
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <text
                                  x={x}
                                  y={y}
                                  key={`cell-${index}`}
                                  fill={"#000000"}
                                  textAnchor={x > cx ? "start" : "end"}
                                  dominantBaseline="central"
                                >
                                  {value ? `${value}%` : "0.00"}
                                </text>
                              );
                            }}
                          >
                            {expChartData.map((entry, index) => (
                              <>
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              </>
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForExp />} />
                        </PieChart>
                      </ResponsiveContainer>

                      <ul className="chart-legends">
                        {expChartData &&
                          expChartData?.length > 0 &&
                          expChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length],
                                  }}
                                ></FontAwesomeIcon>{" "}
                                {item?.name}
                              </p>
                            );
                          })}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "-17px",
                        float: "left",
                        width: "73%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {totalExpenditures.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
