import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../components/Form/BackButton";
import QuoteLayouts from "./QuoteLayouts";
import InvoiceLayouts from "./InvoiceLayouts";
import ReceiptLayouts from "./ReceiptLayouts";

const tabsKey = {
  "quote": 1,
  "invoice": 2,
  "receipt": 3,
};

const LayoutSettings = () => {
  const location = useLocation();
  const params = useParams();
  const businessId = params?.business_id;
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const selectedTab = query.get("tab") || "quote";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Quote Templates"
      : parseInt(defaultActiveKey) === 2
      ? "Invoice Templates"
      : "Receipt Templates"
  );

  useEffect(() => {
    window.document.title = `SME CashBook | ${activeTab} Settings`
  },[activeTab])

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
          <h1 className="page-title">
              <BackButton />
              {activeTab === "Invoice Template"
                ? "Invoice Templates"
                : activeTab === "Quote Template"
                ? "Quote Templates"
                : "Receipt Templates"}
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                    navigate(
                      `/business/${businessId}/template-settings?tab=${
                        event?.target?.innerText === "Quote Templates" ? "quote" :
                        event?.target?.innerText === "Invoice Templates" ? "invoice" :
                        "receipt"
                      }`
                    );
                  }}
                >
                  <li
                    className={
                      activeTab === "Quote Templates" ? "active" : ""
                    }
                  >
                    <Link
                      key={1}
                    >
                      Quote Templates
                    </Link>
                  </li>
                  <li
                    className={activeTab === "Invoice Templates" ? "active" : ""}
                  >
                    <Link
                      key={2}
                    >
                      Invoice Templates
                    </Link>
                  </li>
                  <li className={activeTab === "Receipt Templates" ? "active" : ""}>
                    <Link
                      key={3}
                    >
                      Receipt Templates
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table" style={{padding: "15px"}}>
                {activeTab === "Quote Templates" && (
                  <QuoteLayouts />
                )}
                {activeTab === "Invoice Templates" && (
                  <InvoiceLayouts />
                )}
                {activeTab === "Receipt Templates" && (
                  <ReceiptLayouts />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutSettings;
