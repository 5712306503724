import React from "react";
import { Button, Modal } from "react-bootstrap";
// import ReactCrop from "react-image-crop";
// import Avatar from "react-avatar";
import Cropper from "react-easy-crop";
import { useCallback } from "react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import getCroppedImg from "./CropImage";

const ImageCropModal = ({
  setShowModal,
  setSelectedFile,
  showModal,
  selectedFile,
  setFieldValue,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const toggleShowModal = () => {
    setShowModal(false);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setShowModal(false);
    try {
      const croppedImage = await getCroppedImg(
        selectedFile,
        croppedAreaPixels,
        rotation
      );
      // setCroppedImage(croppedImage);
      setSelectedFile(croppedImage);
      const finalImageFileObject = dataURLtoFile(croppedImage, "profile.jpeg");

      setFieldValue(`image`, finalImageFileObject);
    } catch (e) {
      console.error(e);
    }
  }, [
    croppedAreaPixels,
    rotation,
    selectedFile,
    setFieldValue,
    setShowModal,
    setSelectedFile,
  ]);

  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={toggleShowModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Crop Image
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 200,
              background: "#333",
            }}
          >
            <Cropper
              image={selectedFile}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
              <Typography variant="overline">Zoom</Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                style={{ padding: "22px 0px", marginLeft: 32 }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
              <Typography variant="overline">Rotation</Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                style={{ padding: "22px 0px", marginLeft: 32 }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              style={{ flexShrink: 0, marginLeft: 16 }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageCropModal;
