import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, get } from "../../../config";
import Spin from "../../common/Spin";
import { MonthRangePicker } from "@semcore/date-picker";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatNumber, pastFinancialdate } from "../../common/Misc";
import { Dropdown } from "react-bootstrap";
import ExpandedTrialBalance from "./components/ExpandedTrialBalance";
import TitleBar from "../../common/TitleBar";

// const currentDate = new Date();
// var disabledFutureDate = [];
// for (let i = 1; i < 1000; i++) {
//   disabledFutureDate.push(
//     new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1)
//   );
// }

const TrialBalanceReport = ({dateRangeReport, setDateRangeReport}) => {
  const params = useParams();
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const from = query.get('from');
  // const to = query.get('to');
  const businessId = params?.business_id;
  const [clientsReportData, setClientsReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [exportLoading, setExportLoading] = useState(false);
  const [exportTrialBalanceReportLoading, setExportTrialBalanceReportLoading] = useState(false);
  const [valueRange, setValueRange] = useState(dateRangeReport ? dateRangeReport : pastFinancialdate);

  const monthRangeDisplay =
    moment(valueRange[0]).format("yyyy") ===
    moment(valueRange[1]).format("yyyy")
      ? 
      moment(valueRange[0]).format("MMM") === moment(valueRange[1]).format("MMM") ?
      `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format("yyyy")}`
      :`${moment(valueRange[0]).format("MMM")}-${moment(valueRange[1]).format(
          "MMM"
        )}, ${moment(valueRange[0]).format("yyyy")}`
      : `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format(
          "yyyy"
        )} - ${moment(valueRange[1]).format("MMM")} ${moment(
          valueRange[1]
        ).format("yyyy")}`;


  const getClientsReportData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await get(
        `${API.GET_TRIAL_BALANCE_DATA}?start_month=${ moment(valueRange[0]).format("MM-YYYY") ? moment(valueRange[0]).format("MM-YYYY") : ""}&end_month=${moment(valueRange[1]).format("MM-YYYY") ? moment(valueRange[1]).format("MM-YYYY") : ""}&export=0&client_business_id=${businessId}`
      );    
      let totalDebit = 0;
      let totalCredit = 0;
      let customerReportData = [];
      if (data?.data?.reports?.data && data?.data?.reports?.data?.length > 0) {
        customerReportData = data?.data?.reports?.data?.map((item) => {
          // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
          let obj = {
            ...item
          };
          if (item?.type === 0) {
            totalDebit += Number(item?.code === "2050" || item?.code === "2000" ? item?.amount : item?.net_amount);
          } else {
            totalCredit += Number(item?.code === "2050" || item?.code === "2000" ? item?.amount : item?.net_amount);
          }
          return obj;
        });
      }
      let totalData = {
        total_name: 'Net Movement',
        total_debit_amount: totalDebit,
        total_credit_amount: totalCredit,
      };
      // let profit = ((totalData.total_credit_amount) - (totalData.total_debit_amount));
      // let netProfit = {
      //   net_name: "Net Profit",
      //   net_profit: profit,
      // } 
      // setClientsReportData([...[].concat(customerReportData), totalData, netProfit])
      setClientsReportData([...[].concat(customerReportData), totalData])
      setIsLoading(false);
    } catch (error) {
      const { data } = error.response;
      setClientsReportData([]);
      setIsLoading(false);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [valueRange, businessId]);

  useEffect(() => {
    getClientsReportData();
  }, [getClientsReportData]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const trialBalanceDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: clientsReportData[i]?.total_name ? clientsReportData[i]?.total_name : clientsReportData[i]?.net_name ? clientsReportData[i]?.net_name : 
        clientsReportData[i]?.id ? clientsReportData[i]?.id + String(clientsReportData[i]?.is_bank_reconciliation) : 'not-expand-row',
        batch_id: clientsReportData[i]?.total_name ? (<div>2</div>) : clientsReportData[i]?.id
        ? clientsReportData[i]?.id
        : "",
        is_bank_reconciliation: clientsReportData[i]?.is_bank_reconciliation,
        is_cash_transaction: clientsReportData[i]?.is_cash_transaction,
        code: ( clientsReportData[i]?.net_name ? clientsReportData[i]?.net_name : clientsReportData[i]?.total_name ? clientsReportData[i]?.total_name : clientsReportData[i]?.code ? `${clientsReportData[i]?.code?.length < 4 ? padWithLeadingZeros(Number(clientsReportData[i]?.code), 4) : clientsReportData[i]?.code}${clientsReportData[i]?.sub_code ? `.${clientsReportData[i]?.sub_code}` : ''}` : ""),
        account: clientsReportData[i]?.name ? clientsReportData[i]?.is_bank_reconciliation === 0 ? <>{clientsReportData[i]?.name}(<span className="required">*</span>)</>  : clientsReportData[i]?.name : "",
        account_type: clientsReportData[i]?.account_name ? clientsReportData[i]?.account_name : "",
        type: clientsReportData[i]?.type,
        credit:
          (clientsReportData[i]?.total_credit_amount ? `$${formatNumber(clientsReportData[i]?.total_credit_amount)}` : 
          clientsReportData[i]?.type === 1 ? ((clientsReportData[i]?.code === "2000" || clientsReportData[i]?.code === "2050") ? (clientsReportData[i]?.amount ? <span>${formatNumber(Number(clientsReportData[i]?.amount))}</span> : "-") : clientsReportData[i]?.net_amount ? <span>${formatNumber(Number(clientsReportData[i]?.net_amount))}</span> : "-") : ("-")),
        debit:
        (clientsReportData[i]?.total_debit_amount ? `$${formatNumber(clientsReportData[i]?.total_debit_amount)}` :
        clientsReportData[i]?.type === 0 ?  ((clientsReportData[i]?.code === "2000" || clientsReportData[i]?.code === "2050") ? (clientsReportData[i]?.amount ? <span>${formatNumber(Number(clientsReportData[i]?.amount))}</span> : "-") : clientsReportData[i]?.net_amount ?  <span>${formatNumber(Number(clientsReportData[i]?.net_amount))}</span> : "-") : ("-")),
      });
    }
    return items;
  };
  const trialBalanceData = trialBalanceDataGenerator(clientsReportData?.length);

  const columns = [
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "account",
      text: "Account",
    },
    {
      dataField: "account_type",
      text: "Account Type",
      classes: "trial-balance-account-cell"
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      classes: "trial-balance-debit-cell",
      headerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      classes: "trial-balance-credit-cell",
      headerAlign: (column, colIndex) => "right",
    },
  ];

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if(quarter === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarter === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarter === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarter === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }

  // let quarterCurrentRange = [];
  // if(quarter === 1){
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate)]
  // } else if(quarter === 2){
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate)]
  // } else if(quarter === 3){
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate)]
  // } else {
  //   quarterCurrentRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate)]
  // }

  const dateFilterRange = [
    {
      children: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)]
    },
    // {
    //   children: 'Month To Date',
    //   value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate)]
    // },
    {
      children: 'This Quarter',
      value: quarterRange
    },
    // {
    //   children: 'Quarter To Date',
    //   value: quarterCurrentRange
    // },
    {
      children: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)]
    },
    // {
    //   children: 'Financial Year To Date',
    //   value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate)]
    // },
    {
      children: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)]
    },
    {
      children: 'Last Quarter',
      value: perviousQuarterRange
    },
    {
      children: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)]
    },
    // {
    //   label: 'Quarter To Date',
    //   value: quarterRange
    // },
  ]
  
  const expandRow = {
    renderer: (row) => (
      <>
      {row.code === "3380" ?
        <ExpandedTrialBalance 
          id={row?.batch_id}
          businessId={businessId}
          startMonth={ moment(valueRange[0]).format("MM-YYYY") ? moment(valueRange[0]).format("MM-YYYY") : ""}
          endMonth={ moment(valueRange[1]).format("MM-YYYY") ? moment(valueRange[1]).format("MM-YYYY") : ""}
          code="3380"
          getClientsReportData={getClientsReportData}
          valueRange={valueRange}
          baseType={row.type}
          is_bank_reconciliation={row?.is_bank_reconciliation}
          is_cash_transaction={row?.is_cash_transaction}
        />
      :
      <ExpandedTrialBalance 
          id={row?.batch_id}
          businessId={businessId}
          startMonth={ moment(valueRange[0]).format("MM-YYYY") ? moment(valueRange[0]).format("MM-YYYY") : ""}
          endMonth={ moment(valueRange[1]).format("MM-YYYY") ? moment(valueRange[1]).format("MM-YYYY") : ""}
          getClientsReportData={getClientsReportData}
          valueRange={valueRange}
          code={row.code}
          baseType={row.type}
          is_bank_reconciliation={row?.is_bank_reconciliation}
          is_cash_transaction={row?.is_cash_transaction}
        />
      }
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: ['Net Movement', 'Net Profit', 'not-expand-row'],
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  }

  const handleExportTrialBalanceReport = async (format, reportType) => {
    try {
      setExportTrialBalanceReportLoading(true);
      const { data } = await get(
        `${API.GET_TRIAL_BALANCE_DATA}?start_month=${ moment(valueRange[0]).format("MM-YYYY") ? moment(valueRange[0]).format("MM-YYYY") : ""}&end_month=${moment(valueRange[1]).format("MM-YYYY") ? moment(valueRange[1]).format("MM-YYYY") : ""}&export=1&report_type=${reportType}&format=${format}&client_business_id=${businessId}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "trial_balance_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportTrialBalanceReportLoading(false);
      }
    } catch (e) {
      setExportTrialBalanceReportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"Trial Balance Report"}
          />
          <div className="content-details trial-balance-report-details">
            <div className="table-top-btn trial-btn report-header-row">
            <div className="report-name report-period-name">
                <span> Trial Balance For the period {monthRangeDisplay} (Net Movement)</span>
              </div>
              <div className="table-btn">
              <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportTrialBalanceReportLoading
                      ? "Loading…"
                      : "Export Trial Balance"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Dropdown
                        variant="primary"
                        drop="end"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle>HandiLedger</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "csv",
                                "handiLedger"
                              )
                            }
                          >
                            CSV
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "pdf",
                                "handiLedger"
                              )
                            }
                          >
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "xlsx",
                                "handiLedger"
                              )
                            }
                          >
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Dropdown
                        variant="primary"
                        drop="end"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle>Final Accounts</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "csv",
                                "finalAccounts"
                              )
                            }
                          >
                            CSV
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "pdf",
                                "finalAccounts"
                              )
                            }
                          >
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "xlsx",
                                "finalAccounts"
                              )
                            }
                          >
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="summary-filter"
                style={{
                  padding: "0",
                  border: "none",
                  marginTop: "0px",
                  marginLeft: 20
                }}
              >
                <MonthRangePicker
                  value={valueRange}
                  onChange={(date) => {
                    setClientsReportData([]);
                    if(date){
                      setValueRange(date);
                      if(date[0]?.getMonth() === date[1]?.getMonth()){
                        setDateRangeReport([date[0], new Date(date[1]?.getUTCFullYear(), date[1]?.getMonth() + 1, 0)]);
                      }
                      else{
                        setDateRangeReport([date[0], new Date(date[1]?.getUTCFullYear(), date[1]?.getMonth() + 1, 0)]);
                      }
                    }  
                  }}
                  periods={dateFilterRange}
                  unclearable={true}
                  className="datefilter"
                  // disabled={disabledFutureDate}
                />
              </div>
            </div>

            <div className="table-wrapper trial-balance-table">
              <div className="custom-table">
                {isLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  columns={columns}
                  expandRow={expandRow}
                  data={trialBalanceData}
                  noDataIndication="No Data Found"
                />
              </div>           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialBalanceReport;
