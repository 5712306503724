import React from "react";
import BusinessHeaderNav from "./BusinessHeaderNav";
import Sitelogo from "./Logo";
import UserProfile from "./UserProfile";

const BusinessHeader = () => {
  return (
    <div className="header-blocks">
      <header>
        <Sitelogo />
        <BusinessHeaderNav />
        <UserProfile />
      </header>
      <div className="header-bottom-block">
        {/* <SearchBar
          setIsVisible={setIsVisible}
          setSelectedCustomer={setSelectedCustomer}
        /> */}
      </div>
    </div>
  );
};

export default BusinessHeader;
