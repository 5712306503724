import React, { useEffect, useState } from "react";
import logo from "../../assets/images/SMECashbook-white.png";
// import logo from "../../assets/images/aibooks-logo-white.png";
import { imageDefaultPrefixPath } from "../../config";
import { checkIfFileExists } from "../../pages/common/Misc";

const Sitelogo = () => {
  const role = localStorage.getItem("sme-role");
  const [accountingFirmLogo, setAccountingFirmLogo] = useState("");
  const profile = localStorage.getItem("sme-AccountingFirmLogo")
    ? localStorage.getItem("sme-AccountingFirmLogo")
    : "";
  const token = localStorage.getItem("sme-auth-token");
  const pathname = window.location.pathname;

  useEffect(() => {
    if (profile) {
      checkIfFileExists(`${imageDefaultPrefixPath}${profile}`, (exists) => {
        if (exists) {
          setAccountingFirmLogo(`${imageDefaultPrefixPath}${profile}`);
        } else {
          setAccountingFirmLogo();
        }
      });
    }
  }, [profile]);

  return (
    <div className="sitelogo">
      <img
        src={logo}
        alt="TrustEasy"
        style={{ maxWidth: "500px", filter: "brightness(100)" }}
      />
      <span>Beta</span>
      {accountingFirmLogo && token && role !== "Client" && role !== "Admin" && pathname !== "/2fa"  && profile && (
        <div className="ac-branding-logo">
          <img src={accountingFirmLogo} alt="" className="ac-firm-logo" /> 
        </div>
      )}
    </div>
  );
};

export default Sitelogo;
