import React, { useCallback, useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate } from 'react-router-dom';
import Spin from '../common/Spin';
import Pagination from '../common/Pagination';
import { API, fileUpload, get, metaTitle } from '../../config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button, Form, FormControl } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import { formatABN, formatName } from '../common/Misc';
import { debounce } from 'underscore';

const ListOfBusinessSubscribe = ({ subscriptionDetail, isLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const defaultPage = query.get('page');
  const defaultLimit = query.get('limit');
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || '');
  const [searchParamData, setSearchParamData] = useState(search || '');
  const [subBusinessesList, setSubBusinessesList] = useState([]);
  const [subBusinessesLoading, setSubBusinessesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscribeBusiness, setSubscribeBusiness] = useState(
    subscriptionDetail?.quantity ? subscriptionDetail?.quantity : 0
  );
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [selectedClients, setSelectedClients] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);

  const filterClientInputRef = useRef();

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  let singleSelectedRows = [];
  let allSelectedRows = [];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: 'selection-row',
    selected: selectedRow,
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        if (subscribeBusiness > 0) {
          singleSelectedRows = [...selectedRow, row?.key];
          // setSubscribeBusiness(subscribeBusiness - 1);
        }
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
        // if (subscribeBusiness < subscriptionDetail?.quantity) {
        //   setSubscribeBusiness(subscribeBusiness + 1);
        // }
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      // let subBusinessQty = subscribeBusiness;
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
          } else {
            // if (subBusinessQty > 0) {
            if (subscribeBusiness > 0) {
              allSelectedRows.push(rows[i].key);
              // subBusinessQty = subBusinessQty - 1;
            }
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
          // if (subscribeBusiness < subscriptionDetail?.quantity) {
          //   subBusinessQty = subscriptionDetail?.quantity;
          // }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    }
  };

  const columns = [
    {
      text: 'Business Name',
      dataField: 'business_name'
      // sort: true
    },
    {
      text: 'Business Email',
      dataField: 'business_email'
      // sort: true
    },
    {
      text: 'Client Name',
      dataField: 'client_name'
      // sort: true
    },
    {
      text: 'Client Email',
      dataField: 'client_email'
      // sort: true
    },
    {
      text: 'ABN',
      dataField: 'abn'
    },
    {
      text: 'Added Date',
      dataField: 'added_date'
      // sort: true
    },
    {
      text: 'Subscribed',
      dataField: 'subscribed'
    }
    // {
    //   dataField: 'action',
    //   text: 'Action',
    //   className: 'text-center',
    //   style: { width: '127px' }
    // }
  ];

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if (nextValue?.length >= 2 || nextValue?.length === 0) {
      debouncedSave(nextValue);
    }
  };

  const fetchSubBusinesses = useCallback(async () => {
    try {
      setSubBusinessesLoading(true);
      const formData = new FormData();
      if (selectedClients?.length > 0) {
        for (let i = 0; i < selectedClients?.length; i++) {
          if (
            selectedClients[i] !== null &&
            selectedClients[i] !== 'null' &&
            selectedClients[i] !== ''
          ) {
            formData.append(`client_ids[${i}]`, selectedClients[i]);
          } else {
            formData.append(`client_ids[${i}]`, '');
            // formData.append(`client_ids[${i + 1}]`, null);
          }
        }
      }
      formData.append('limit', limit ? limit : 10);
      formData.append('page', page ? page : 1);
      formData.append('sort_column', sortField ? sortField : '');
      formData.append('sort_order', sortOrder ? sortOrder : '');
      formData.append('search', searchParam ? searchParam : '');
      const { data } = await fileUpload(`${API.SUBSCRIBE_BUSINESS_LIST}`, formData);
      setSubscribeBusiness(Number(subscriptionDetail?.quantity) - Number(data?.subscribed_count));
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const subBusinessesList = data.data.data;
      // let subBusiness = [];
      // for (let j = 0; j < data.data.data?.length; j++) {
      //   if (data.data.data[j]?.is_subscribed) {
      //     subBusiness.push(data.data.data[j]?.id);
      //   }
      // }
      // setSelectedRow(subBusiness);
      setSubBusinessesList(subBusinessesList);
      setSubBusinessesLoading(false);
      return data.data;
    } catch (e) {
      const { data } = e;
      setSubBusinessesLoading(false);
      setSubBusinessesList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    // eslint-disable-next-line
  }, [limit, page, sortField, sortOrder, searchParam, selectedClients, subscriptionDetail]);

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      setPage(1);
      setSortField(sortField);
      setSortOrder(sortOrder === 'asc' ? 'ASC' : sortOrder === 'desc' && 'DESC');
    }
  };

  useEffect(() => {
    fetchSubBusinesses();
    window.document.title = `${metaTitle} | Subscribe Businesses`;
  }, [fetchSubBusinesses]);

  const subscriptionsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: subBusinessesList[i]?.id,
        id: subBusinessesList[i]?.id ? subBusinessesList[i]?.id : '',
        business_name: subBusinessesList[i]?.business_name
          ? subBusinessesList[i]?.business_name
          : '',
        business_email: subBusinessesList[i]?.email ? subBusinessesList[i]?.email : '-',
        client_name: subBusinessesList[i]?.client?.client_details
          ? formatName(
              subBusinessesList[i]?.client?.client_details?.first_name,
              subBusinessesList[i]?.client?.client_details?.last_name
            )
          : '',
        client_email: subBusinessesList[i]?.client?.client_details
          ? subBusinessesList[i]?.client?.client_details?.email
          : '',

        abn: subBusinessesList[i]?.abn ? formatABN(subBusinessesList[i]?.abn) : '',
        added_date: subBusinessesList[i]?.created_at
          ? moment(subBusinessesList[i]?.created_at, 'DD-MM-YYYY HH:mm:SS').format('DD-MM-YYYY')
          : '',
        subscribed: (
          <div className="switch-body">
            <div className="toggle-switch">
              <input
                type="checkbox"
                className="switch-checkbox"
                name="is_subscribed"
                id={`is_subscribed-${i}`}
                defaultChecked={subBusinessesList[i]?.is_subscribed ? true : false}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    activeSubscribe(subBusinessesList[i]?.id);
                  } else {
                    disableSubscribe(subBusinessesList[i]?.id);
                  }
                }}
              />
              <label className="switch-label" htmlFor={`is_subscribed-${i}`}>
                <span className="switch-inner" />
                <span className="switch" />
              </label>
            </div>
          </div>
        ),

        action: (
          <></>
          // <SubBusinessActionButton
          //   id={subBusinessesList[i]?.id}
          //   handleDelete={handleDelete}
          //   fetchSubBusinesses={fetchSubBusinesses}
          // />
        )
      });
    }
    return items;
  };

  const subBusinesses = subscriptionsGenerator(subBusinessesList?.length);

  useEffect(() => {
    navigate(
      `?tab=subscriptions&childTab=businesses&limit=${limit ? limit : 10}&page=${
        page ? page : 1
      }&sort_column=${sortField ? sortField : ''}&sort_order=${sortOrder ? sortOrder : ''}`,
      {
        replace: true
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  const addSubscribeBusiness = async () => {
    const formData = new FormData();

    for (let i = 0; i < selectedRow.length; i++) {
      formData.append(`business_ids[${i}]`, selectedRow[i]);
    }
    formData.append('status', 1);
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ACTIVE_BUSINESSES}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        setSelectedRow([]);
        let tempData = fetchSubBusinesses();
        setSubBusinessesList(tempData?.data);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleClientChange = (e) => {
    setSelectedClients(e.value);
  };

  const handleClientList = useCallback(async () => {
    try {
      setLoading(true);
      get(`${API.GET_CLIENTS}?&limit=-1&sort_column=code&sort_order=ASC`).then((response) => {
        const clientList =
          response &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            const list = {
              label: formatName(item?.first_name, item?.last_name),
              value: item?.client?.id
            };
            return list;
          });
        setClientOptions(clientList ? [...clientList] : []);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.data?.message);
    }
  }, []);

  useEffect(() => {
    handleClientList();
  }, [handleClientList]);

  const handleApplyFilter = () => {
    filterClientInputRef.current.hide();
    fetchSubBusinesses();
  };

  const myClientResetFunction = () => {
    setSelectedClients([]);
    filterClientInputRef && filterClientInputRef.current.focus();
    filterClientInputRef.current.hide();
    fetchSubBusinesses([]);
  };

  const disableSubscribe = async (business_id) => {
    const formData = new FormData();
    formData.append(`business_ids[0]`, business_id);
    formData.append('status', 0);
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ACTIVE_BUSINESSES}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        fetchSubBusinesses();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const activeSubscribe = async (business_id) => {
    const formData = new FormData();
    formData.append(`business_ids[0]`, business_id);
    formData.append('status', 1);
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ACTIVE_BUSINESSES}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        fetchSubBusinesses();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <div className="cms-page">
          <div className="page-content-block">
            {subscriptionDetail?.status === undefined ? (
              <>
                <h5>You have no Subscription</h5>
              </>
            ) : (
              <div className="full-content-block">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <h6>Remaining Credit Businesses : {subscribeBusiness ? subscribeBusiness : 0}</h6>
                  <h6>
                    Total Businesses Subscribe :{' '}
                    {subscriptionDetail?.quantity && subscribeBusiness
                      ? Number(subscriptionDetail?.quantity ? subscriptionDetail?.quantity : 0) -
                        Number(subscribeBusiness ? subscribeBusiness : 0)
                      : 0}
                  </h6>
                </div>
                <div
                  className="content-details"
                  style={{
                    margin: '5px 0px 15px 0px'
                  }}
                >
                  <div className="table-top-btn">
                    <div className="search-section">
                      <Form>
                        <FormControl
                          onChange={handleSearchChange}
                          type="text"
                          value={searchParamData}
                          placeholder="Search"
                        />
                        {searchParamData ? (
                          <Button
                            className="btn-close"
                            onClick={() => {
                              setSearchParamData('');
                              setSearchParam('');
                            }}
                          >
                            x
                          </Button>
                        ) : (
                          <Button className="fa fa-search">Search</Button>
                        )}
                      </Form>
                    </div>
                    <div className="table-btn">
                      <div className="multiSelect-body-pl-report">
                        <div className="multiSelect-pl-report">
                          <MultiSelect
                            value={selectedClients}
                            onChange={(e) => {
                              handleClientChange(e);
                            }}
                            options={clientOptions}
                            optionLabel="label"
                            filter
                            placeholder="Select Client"
                            maxSelectedLabels={0}
                            ref={filterClientInputRef}
                            selectedItemsLabel={`${selectedClients?.length} Client(s) Selected`}
                            onHide={() => {
                              fetchSubBusinesses();
                            }}
                            panelFooterTemplate={
                              <>
                                <hr/>
                                <Button
                                  className="multiSelect-button"
                                  onClick={() => {
                                    handleApplyFilter();
                                  }}
                                >
                                  Apply
                                </Button>
                                <Button
                                  className="multiSelect-button"
                                  onClick={() => {
                                    myClientResetFunction();
                                  }}
                                >
                                  Reset
                                </Button>
                              </>
                            }
                          />
                        </div>
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => addSubscribeBusiness()}
                        disabled={selectedRow?.length === 0}
                      >
                        Subscribe Business
                      </Button>
                    </div>
                  </div>
                  <div className="custom-table">
                    {(subBusinessesLoading || loading) && <Spin />}
                    <BootstrapTable
                      keyField="key"
                      remote
                      selectRow={selectRow}
                      data={subBusinesses}
                      onTableChange={handleTableChange}
                      columns={columns}
                      noDataIndication="No Data Found"
                    />

                    {subBusinesses.length !== 0 ? (
                      <Pagination
                        total={total}
                        limit={parseInt(limit)}
                        currentPage={page}
                        updateLimit={handleUpdateLimit}
                        updatePage={handleChangePage}
                        from={from}
                        to={to}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListOfBusinessSubscribe;
