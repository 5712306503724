import React from 'react'

import { Tooltip } from 'antd';
import { useMemo } from 'react';

const CustomTooltip = ({text, children}) => {
  const mergedArrow = useMemo(() => {
    return {
      pointAtCenter: true,
    };
  }, []);
  return (
    <Tooltip placement="bottom" title={text} arrow={mergedArrow} overlayInnerStyle={{fontSize: "12px", borderRadius: "10px", margin: "4px"}} trigger="hover">
        {children}
    </Tooltip>
  )
}

export default CustomTooltip
