import React, { useState } from "react";
import ConfirmAuthentication from "../../components/Authentication/ConfirmAuthentication";
import ConfirmBackupEmail from "../../components/Authentication/ConfirmBackupEmail";
import "./authentication.css"
import { API, post } from "../../config";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spin from "../../pages/common/Spin";


const SelectAuthenticate = (props) => {
    const { confirmAuthentication, emailVerify, googleVerify } = props
    const AuthenticationMethod = {
        authenticator: "AUTHENTICATOR",
        email: "EMAIL",
        sendEmail: "SEND_EMAIL"
    }
    

    const [ methodSelected, setMethodSelected ] = useState(null);
    const [ sendCodeLoading, setSendCodeLoading ] = useState(false);

    const selectMethod = (value) => {
        if ([ AuthenticationMethod.authenticator, AuthenticationMethod.email ].includes(value)) {
            setMethodSelected(value)
        } else {
            setMethodSelected(null)
        }
    }

    const sendEmailCode = () => {
        setSendCodeLoading(true)
        post(API.EMAIL_AUTHENTICATION)
            .then((response) => {
                setMethodSelected(AuthenticationMethod.sendEmail)
                toast.success(response?.data?.message)
                setSendCodeLoading(false)
            })
            .catch(() => {
                var error = {};

                error[ "myna_error" ] =
                    "There is some issue with request, please try after some time.";
            });
    }


    const SelectedMethodComponent = () => {
        return (
            methodSelected === AuthenticationMethod.authenticator ?
                <>
                <div className="disable-tfa-content">
                    <div className="steps-content" style={{ margin: '60px auto 0' }}>
                        <ConfirmAuthentication
                            submitButton={confirmAuthentication}
                            verifyUrl={googleVerify}
                        />
                        <Link onClick={() => { setMethodSelected(null) }}>Use Another Authentication method</Link>
                    </div>
                </div>
                </>
                : emailComponent()
        )
    }

    const emailComponent = () => {
        return (methodSelected === AuthenticationMethod.email ?
            <>
                <div className="disable-tfa-content">
                <div className="steps-content" style={{ margin: '60px auto 0' }}>
                    {sendCodeLoading && <Spin />}
                    <h3>Complete authentication with your backup email address</h3>
                    <Button onClick={sendEmailCode} loading={sendCodeLoading}>Send Code</Button>
                    <p style={{ width: '100%', margin: '15px 0 0' }}>
                        <Link onClick={() => { setMethodSelected(null) }}>Use Another Authentication method</Link>
                    </p>
                </div>
                </div>
            </> : methodSelected === AuthenticationMethod.sendEmail &&
            <>
            <div className="disable-tfa-content">
                <div className="steps-content" style={{ margin: '60px auto 0' }}>
                    <ConfirmBackupEmail
                        submitButton={confirmAuthentication}
                        verifyUrl={emailVerify}
                    />
                    <Link onClick={() => { setMethodSelected(null) }}>Use Another Authentication method </Link>
                </div>
            </div>
            </>
        )
    }


    return (
        <>
            {!methodSelected ?
                (<>
                <div className="disable-tfa-content">
                    <div className="steps-content" style={{ margin: '60px auto 0' }}>
                        <h3>How would you like to authenticate this request?</h3>
                        <p>Before changing your backup email address, we need to confirm that it&apos;s you making this request</p>
                        <Button name={AuthenticationMethod.authenticator} onClick={() => selectMethod(AuthenticationMethod.authenticator)} style={{ marginRight: '15px' }}>
                            6 digit Code
                        </Button>
                        <Button name={AuthenticationMethod.email} onClick={() => selectMethod(AuthenticationMethod.email)}>
                            Backup email address
                        </Button>
                        {/* <Button id="test" name="test" onClick={selectMethod}>
                        6 digit Code
                    </Button>
                    <Button id="test1" name="test1" onClick={selectMethod}>
                        Backup email address
                    </Button> */}
                    </div>
                </div>
                </>)
                : SelectedMethodComponent()
            }
        </>
    );
};

export default SelectAuthenticate;
