import { API, get } from "../../../../config";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";

export const getSupplierDetail = async (supplierId) => {
  try {
    const { data } = await get(`${API.SUPPLIER_DETAILS}/` + supplierId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const useSuburbListE = (id) => {
  const [suburbList, setSuburbList] = useState([]);
  const [shippingSuburbList, setShippingSuburbList] = useState([]);
  const [suburbLoading, setSuburbLoading] = useState(false);
  const [suburbLoading2, setSuburbLoading2] = useState(false);
  const init = useCallback(async () => {
    try {
      if (id) {
        setSuburbLoading(true);
        setSuburbLoading2(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}?state=${id}`);
        setSuburbLoading(false);
        setSuburbLoading2(false);
        setSuburbList(data.data);
        setShippingSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
      setShippingSuburbList([]);
      setSuburbLoading(false);
      setSuburbLoading2(false);
    }
  }, [id]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    suburbLoading,
    suburbLoading2,
    shippingSuburbList,
    setSuburbList,
    setShippingSuburbList,
  };
};

export const getSupplierPopupDetail = async (supplierId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.SUPPLIER_DETAILS}/${supplierId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.SUPPLIER_DETAILS}/${supplierId}`);
      return data.data;
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(
        data.errors && data.errors.myna_error && data.errors.myna_error[0]
      );
    }
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};
export const useSuburbList = () => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState) {
        setSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}?state=${selectedState}`
        );
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
      setSuburbLoading(false);
    }
  }, [selectedState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSuburbList([]);
      setSelectedState(value);
    },
    suburbLoading,
    setSuburbList,
    selectedState,
  };
};

export const useShippingSuburbList = () => {
  const [shippingSuburbList, setShippingSuburbList] = useState([]);
  const [selectedShippingState, setSelectedShippingState] = useState("");
  const [shippingSuburbLoading, setShippingSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedShippingState) {
        setShippingSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}?state=${selectedShippingState}`
        );
        setShippingSuburbLoading(false);
        setShippingSuburbList(data.data);
      }
    } catch (e) {
      setShippingSuburbList([]);
      setShippingSuburbLoading(false);
    }
  }, [selectedShippingState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    shippingSuburbList,
    handleStateSelect1: (value) => {
      setShippingSuburbList([]);
      setSelectedShippingState(value);
    },
    shippingSuburbLoading,
    setShippingSuburbList,
    selectedShippingState,
  };
};
