import React, { useCallback, useEffect, useRef, useState } from "react";
import { API, fileUpload, get, metaTitle } from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
// import Pagination from "../../common/Pagination";
import { debounce } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../common/Pagination";
import AccountingActionButton from "./components/AccountingActionButton";
import AddChartOfAccounting from "./AddChartOfAccounting";
import UploadFile from "../../common/UploadFile";
import { Fragment } from "react";
import * as XLSX from 'xlsx';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Type } from 'react-bootstrap-table2-editor';
import TitleBar from "../../common/TitleBar";

// const swapKeysAndValues = (obj) => {
//   const swapped = Object.entries(obj).map(([key, value]) => ({ [value]: key }));

//   return Object.assign({}, ...swapped);
// };

const ChartOfAccounts = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [accountTpesLoading, setAccountTpesLoading] = useState(false);
  const [accountTypesList, setAccountTypesList] = useState([]);
  const accountFirstId = accountTypesList[0]?.id ? accountTypesList[0]?.id : "";
  const [accountId, setAccountId] = useState(accountFirstId);
  const params = useParams();

  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const businessId = params?.business_id;

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  
  const [isRestore, setIsRestore] = useState(false);
  const [showRestore, setShowRestore] = useState(false);

  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [ selectedRow, setSelectedRow ] = useState([]);
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelAccountData, setExcelAccountData] = useState();

  // const ABC = accountTypesList.map((item) => {
  //   return item.name.toLowerCase().replace(/ /g, "-");
  // });
  // const tabsKey = swapKeysAndValues(Object.assign({}, ABC));

  const accountFirstTab = accountTypesList[0]?.name
    .toLowerCase()
    .replace(/ /g, "-")
    ? accountTypesList[0]?.name.toLowerCase().replace(/ /g, "-")
    : "";

  // const selectedTab = query.get("tab") || accountFirstTab;
  // const defaultActiveKey = tabsKey[selectedTab];

  const [accountTab, setAccountTab] = useState(accountFirstTab);
  useEffect(() => {
    setAccountId(accountTypesList[0]?.id);
    setAccountTab(accountTypesList[0]?.name.toLowerCase().replace(/ /g, "-"));
  }, [accountTypesList]);

  const [accountData, setAccountData] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const handleDelete = () => {
    fetchAccountData();
  };

  const fetchAccountTypes = useCallback(async () => {
    try {
      setAccountTpesLoading(true);
      const { data } = await get(`${API.GET_ACCOUNT_TYPES}`);
      setAccountTpesLoading(false);
      const accountTypesList = data.data;
      setAccountTypesList(accountTypesList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setAccountTpesLoading(false);
      setAccountTypesList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, []);

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  const fetchAccountData = useCallback(async () => {
    setAccountData([]);
    if (businessId && accountId) {
      try {
        setAccountLoading(true);
        const { data } = await get(
          `${API.GET_ACCOUNT_DATA}?client_business_id=${
            businessId ? businessId : ""
          }&account_type_id=${accountId ? accountId : ""}&search=${
            searchParam ? searchParam : ""
          }&limit=${limit ? limit : 10}&page=${page ? page : 1}`
        );
        setAccountLoading(false);
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setAccountData(data?.data?.data);
      } catch (e) {
        const { data } = e;
        setAccountLoading(false);
        setAccountData([]);
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      }
    }
  }, [businessId, accountId, searchParam, limit, page]);

  const accountsDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let disableparent = []
      let childNodes = []
      if(accountData[i]?.sub_code === null){
        let parentItem = accountData?.filter((item) => item?.code === accountData[i]?.code && (item?.sub_code === null))
        if(parentItem){
          childNodes = accountData?.filter((item) => item?.code === parentItem[0]?.code && item?.sub_code)
          for(let i=0; i<childNodes?.length; i++){
            disableparent.push(childNodes[i]?.is_used === 1)
          }
        }
      }
      items.push({
        key: accountData[i]?.is_used === 1 ? 'not-select' : disableparent.includes(true) ? 'not-select' : accountData[i]?.id,
        name: accountData[i]?.name,
        type: accountData[i]?.type === 0 ? "Debit" : "Credit",
        tax_rate:
          accountData[i]?.tax === 1
            ? "GST " +
              (accountData[i]?.gst_percentage
                ? accountData[i]?.gst_percentage
                : 0) +
              "%"
            : accountData[i]?.tax === 2 ? "BAS Excluded" : accountData[i]?.tax === 3 ? "GST on Imports" : "GST Excluded",
        code: accountData[i]?.code ? accountData[i]?.code : '-',
        sub_code: accountData[i]?.sub_code ? accountData[i]?.sub_code : '-',
        action: (
          <AccountingActionButton
            id={accountData[i]?.id}
            handleDelete={handleDelete}
            fetchAccountData={fetchAccountData}
            isUsed={accountData[i]?.is_used}
            disableParent={disableparent.includes(true)}
            childNodes={childNodes}
          />
        ),
      });
    }
    return items;
  };
  const accountsData = accountsDataGenerator(accountData?.length);

  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(API.DOWNLOAD_ACCOUNT_TEMPLATE);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "chart-of-account.xls"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleImportModal = async () => {
    setShowImport(true);
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  // const handleUpload = async () => {
  //   if (files) {
  //     const formData = new FormData();

  //     formData.append("client_business_id", businessId);
  //     formData.append("chart_of_account_file", files);

  //     const config = { headers: { "Content-Type": "multipart/form-data" } };

  //     try {
  //       setIsImportLoading(true);
  //       const { data } = await fileUpload(
  //         `${API.IMPORT_ACCOUNT}`,
  //         formData,
  //         config
  //       );
  //       setIsImportLoading(false);
  //       if (data) {
  //         toast.success(data?.message);
  //         fetchAccountData();
  //         handleImportClose();
  //       }
  //       return data?.data;
  //     } catch (error) {
  //       if (error?.response?.data?.errors) {
  //         Object.keys(error?.response?.data?.errors).forEach((key) => {
  //           toast.error(error?.response?.data?.errors[key][0]);
  //         });
  //         setIsImportLoading(false);
  //       } else {
  //         toast.error(error?.message);
  //         setIsImportLoading(false);
  //       }
  //     }
  //   }
  // };

  const toggleRestoreModal = () => {
    setShowRestore(!showRestore);
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  };

  const handleRestore = async () => {
    try {
      setIsRestore(true);
      const { data } = await get(
        `${API.ACCOUNT_RESTORE}?client_business_id=${
          businessId ? businessId : ""
        }`
      );
      if (data) {
        toast.success(data?.message);
        setIsRestore(false);
        fetchAccountData();
        toggleRestoreModal();
      }
    }
    catch (e) {
      setIsRestore(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }

  // const handleDeteleAll = async () => {
  //   try {
  //     setIsDeleteAll(true);
  //     const { data } = await get(
  //       `${API.ACCOUNT_DELETE_ALL}?client_business_id=${
  //         businessId ? businessId : ""
  //       }`
  //     );
  //     if (data) {
  //       toast.success(data?.message);
  //       setIsDeleteAll(false);
  //       fetchAccountData();
  //       toggleDeleteAllModal();
  //     }
  //   }
  //   catch (e) {
  //     setIsDeleteAll(false);
  //     const errors = e.response?.data?.errors;
  //     Object.keys(errors).forEach((key) => {
  //       toast.error(errors[key][0]);
  //     });
  //   }

  // }

  let singleSelectedRows = [];
  let allSelectedRows = [];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ['not-select'],
    selected: selectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        let selectParentRow = accountData?.find((item) => item.id === row?.key)
        if(selectParentRow?.sub_code){
          singleSelectedRows = [...selectedRow, row?.key];
        } else {
          let childCOA = accountData?.filter((i) => i.code === selectParentRow?.code && i?.sub_code);
          let childkeys = []
          for(let i =0; i<childCOA?.length; i++){
            childkeys.push(selectedRow.includes(childCOA[i]?.id))
          }
          if(!childkeys.includes(false)){
            singleSelectedRows = [...selectedRow, row?.key];
          }
        }
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      }
      else {
        for(let i=0; i<rows.length; i++){
          if(selectedRow.includes(rows[i].key)){
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    }
  };

  const ref = useRef();
  function cleanFilters() {
    //unselet table
    if (ref && typeof ref === "object") {
      if (ref.current !== undefined) {
        ref.current.selectionContext.selected = [];
      }
    }
  }

  const handleDeleteAll = async() => {
    const formData = new FormData();
    if(selectedRow?.length > 0){
      for(let i=0; i<selectedRow?.length; i++){
        formData.append(`ids[${i}]`, selectedRow[i])
      }
    }
    formData.append("client_business_id", businessId);
    try {
      setIsDeleteAll(true);
      const { data } = await fileUpload(`${API.ACCOUNT_DELETE_ALL}`, formData);
      setIsDeleteAll(false);
      if (data) {
        toast.success(data?.message);
        toggleDeleteAllModal();
        cleanFilters();
        setSelectedRow([]);
        fetchAccountData();
      }
    } catch (error) {
      setIsDeleteAll(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setIsDeleteAll(false);
      } else {
        toast.error(error?.message);
        setIsDeleteAll(false);
      }
    }
  }

  let accuntingList = accountTypesList?.map((item) => {
    const { name: label, name: value, id, ...rest } = item;
    return { label, value, id, ...rest };
  });

  accuntingList.unshift({label: "Select Account"})


  const typeList = [
    { label: "Select Type", value: "Select Type"},
    { label: "Debit", value: "Debit", excelData: "d", base_type: 0 },
    { label: "Credit", value: "Credit", excelData: "c", base_type: 1},
  ];

  const taxList = [
    { label: "Select Tax", value: "Select Tax"},
    { label: "GST 10%", value: "GST 10%", tax_type: 1 },
    { label: "GST Excluded", value: "GST Excluded", tax_type: 0 },
    { label: "BAS Excluded", value: "BAS Excluded", tax_type: 2 },
    { label: "GST on Imports", value: "GST on Imports", tax_type: 3 },
  ];

  const handleExcelClose = () => {
    setExcelSelectedRow([]);
    setExcelNotSelectedRow([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const ExcelAccountColumns = [
    {
      dataField: "account",
      text: "Account",
      editCellClasses: 'edit-cell-excel-class',
      editor: {
        type: Type.SELECT,
        options: accuntingList
      },
      classes: (cell) => {
        if(String(cell)?.length === 0){
          return "error-highlight-cell"
        } else if (String(cell) === "Select Account") {
          return "error-cell"
        }
      }
    },
    {
      dataField: "classification",
      text: "Classification",
      editCellClasses: 'edit-cell-excel-class',
      classes: (cell) => {
        if(String(cell)?.length === 0){
          return "error-highlight-cell"
        }
      },
      validator: (newValue) => {
        if(String(newValue)?.length === 0){
          return {
            valid: false,
            message: 'Please enter classification'
          };
        }
        return true;
      },
    },
    {
      dataField: "code",
      text: "Division/Code",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(String(newValue)?.length > 7){
            return {
              valid: false,
              message: 'Please enter a valid code'
            };
          }
          if(String(newValue)?.length <= 7){
            if((String(newValue)?.match("/") && String(newValue)?.match("/")?.index === 1)){
              return true
            } else if((String(newValue)?.match("/") &&  String(newValue)?.match("/")?.index === 2)){
              return true
            } else if((String(newValue)?.match("-") && String(newValue)?.match("-")?.index === 1)){
              return true
            } else if((String(newValue)?.match("-") &&  String(newValue)?.match("-")?.index === 2)){
              return true
            } else {
              return {
                valid: false,
                message: 'Please enter a valid code'
              };
            }
          }
          if(String(newValue)?.length < 4){
            if(String(newValue)?.match("/")?.index === 2 || String(newValue)?.match("/")?.index === 1 || String(newValue)?.match("-")?.index === 2 || String(newValue)?.match("-")?.index === 2){
              return true
            } else {
              return {
                valid: false,
                message: 'Please enter a valid code'
              };
            }
          }
        }
        if(String(newValue)?.length === 0){
          return {
            valid: false,
            message: 'Please enter a valid code'
          };
        }
        return true;
      },
      classes: (cell) => {
        if(cell){
          if(String(cell)?.length > 7){
            return "error-cell"
          }
          if(String(cell)?.length === 7){
            if(String(cell)?.match("/") && String(cell)?.match("/")?.index !== 2){
              return "error-cell"
            }
            if(String(cell)?.match("-") && String(cell)?.match("-")?.index !== 2){
              return "error-cell"
            }
          }
          if(String(cell)?.length < 7){
            if(String(cell)?.match("/") && String(cell)?.match("/")?.index !== 1){
              return "error-cell"
            }
            if(String(cell)?.match("-") && String(cell)?.match("-")?.index !== 1){
              return "error-cell"
            }
          }
          if(String(cell)?.length > 4){
            if(!String(cell)?.match("/") && !String(cell)?.match("-")){
              return "error-cell"
            }
          }
        }
        if(String(cell)?.length === 0){
          return "error-highlight-cell"
        }
      }
    },
    {
      dataField: "sub_code",
      text: "Subcode",
      editCellClasses: 'edit-cell-excel-class',
      validator: (newValue) => {
        if(newValue){
          if(!String(newValue)?.match(/^[\d]*$/)){
            return {
              valid: false,
              message: 'Please enter a valid subCode'
            };
          }
          if(String(newValue)?.length > 2){
            return {
              valid: false,
              message: 'Please enter a valid subCode'
            };
          }
        }
      },
      classes: (cell) => {
        if(cell){
          if(!String(cell)?.match(/^[\d]{2}$/)){
            return "error-cell"
          }
          if(String(cell)?.length > 2){
            return "error-cell"
          }
        }
      }
    },
    {
      dataField: "description",
      text: "Description",
      editCellClasses: 'edit-cell-excel-class',
    },
    {
      dataField: "type",
      text: "Credit/Debit",
      editCellClasses: 'edit-cell-excel-class',
      editor: {
        type: Type.SELECT,
        options: typeList
      },
      classes: (cell) => {
        if(String(cell)?.length === 0){
          return "error-highlight-cell"
        } else if (String(cell) === "Select Type") {
          return "error-cell"
        }
      }
    },
    {
      dataField: "tax",
      text: "Tax",
      editCellClasses: 'edit-cell-excel-class',
      editor: {
        type: Type.SELECT,
        options: taxList
      },
      classes: (cell) => {
        if(String(cell)?.length === 0){
          return "error-highlight-cell"
        } else if (String(cell) === "Select Tax") {
          return "error-cell"
        }
      }
    },
  ]
  
  const [ excelSelectedRow, setExcelSelectedRow ] = useState([]);
  const [ excelNotSelectedRow, setExcelNotSelectedRow ] = useState([]);


  let singleExcelSelectedRows = [];
  let allExcelSelectedRows = [];

  const excelSelectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: excelNotSelectedRow,
    selected: excelSelectedRow,
    nonSelectableClasses: 'not-selected-class',
    onSelect: (row, isSelect, rowIndex, e) => {
      singleExcelSelectedRows = [...excelSelectedRow];
      if (isSelect) {
        singleExcelSelectedRows = [...excelSelectedRow, row?.key];
      } else {
        singleExcelSelectedRows.splice(excelSelectedRow.indexOf(row?.key), 1);
      }
      setExcelSelectedRow(singleExcelSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        for(let i=0; i<rows.length; i++){
          if(excelSelectedRow.includes(rows[i].key)){
          } else {
            allExcelSelectedRows.push(rows[i].key);
          }
        }
      }
      else {
        for(let i=0; i<rows.length; i++){
          if(excelSelectedRow.includes(rows[i].key)){
            excelSelectedRow.splice(excelSelectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setExcelSelectedRow([...excelSelectedRow, ...allExcelSelectedRows]);
    }
  };

  const excelRef = useRef();

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const ExcelReader = () => {
    let f = files
    const reader = new FileReader();
    let data = null;
    reader.onload = async(evt) => { // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, {header:1});
      /* Update state */
      let selectItem = []
      let selectNotItem = []
      const businessExcelGenerator = (quantity) => {
        const items = [];
        if(
          data[0][0] !== "Account" ||
          data[0][1] !== "Classification" ||
          data[0][2] !== "Code" ||
          data[0][3] !== "Subcode" ||
          data[0][4] !== "Description" ||
          data[0][5] !== "Credit/Debit" ||
          data[0][6] !== "Tax"
        ){
          setShowExcelModal(false);
          setFiles();
          setIsImportLoading(false);
          toast.error("Sorry, File is not in the given format, please download the latest format again");
        } else {
          for (let i = 1; i < quantity; i++) {
            let code = null
            if(String(data[i][2])?.length < 4){
              if(!String(data[i][2])?.match("/") &&  !String(data[i][2])?.match("-")){
                code = padWithLeadingZeros(String(data[i][2]), 4)
              }
              if(String(data[i][2])?.match("/") && (String(data[i][2])?.match("/")?.index === 1 || String(data[i][2])?.match("/")?.index === 2)){
                let temp = String(data[i][2]).split("/")
                let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                code = `${firstNumber}/${secondNumber}`
              }
              if(String(data[i][2])?.match("-") && (String(data[i][2])?.match("-")?.index === 1 || String(data[i][2])?.match("-")?.index === 2)){
                let temp = String(data[i][2]).split("-")
                let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                code = `${firstNumber}-${secondNumber}`
              }
            } else if(String(data[i][2])?.length < 8){
              if(String(data[i][2])?.match("/") && (String(data[i][2])?.match("/")?.index === 1 || String(data[i][2])?.match("/")?.index === 2)){
                let temp = String(data[i][2]).split("/")
                let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                code = `${firstNumber}/${secondNumber}`
              }
              if(String(data[i][2])?.match("-") && (String(data[i][2])?.match("-")?.index === 1 || String(data[i][2])?.match("-")?.index === 2)){
                let temp = String(data[i][2]).split("-")
                let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                code = `${firstNumber}-${secondNumber}`
              }
            }

            let subCode = null
            if(String(data[i][3])?.length < 2 && String(data[i][3])?.length !== 0){
              if(String(data[i][3])?.match(/^[\d]*$/)){
                subCode = padWithLeadingZeros(String(data[i][3]), 2)
              }
            }

            if(data[i].length > 0){
              if(code === null){
                if(data[i][2]){
                  if(String(data[i][2])?.length > 7){
                    selectNotItem.push(i)
                  } else if(String(data[i][2])?.length === 7){
                    if(String(data[i][2])?.match("/") && String(data[i][2])?.match("/")?.index !== 2){
                      selectNotItem.push(i)
                    } else if(String(data[i][2])?.match("-") && String(data[i][2])?.match("-")?.index !== 2){
                      selectNotItem.push(i)
                    } else {
                      selectItem.push(i);
                    }
                  } else if(String(data[i][2])?.length < 7){
                    if(String(data[i][2])?.match("/") && String(data[i][2])?.match("/")?.index !== 1){
                      selectNotItem.push(i)
                    } else if(String(data[i][2])?.match("-") && String(data[i][2])?.match("-")?.index !== 1){
                      selectNotItem.push(i)
                    } else {
                      selectItem.push(i);
                    }
                  } else {
                    selectItem.push(i);
                  }
                } else {
                  selectNotItem.push(i)
                }
              } else {
              // eslint-disable-next-line
                if(!code || !accuntingList.find((item) => item.label === data[i][0]) || !typeList.find((item) => item.excelData === String(data[i][5]).toLowerCase()) || !taxList.find((item) => item.value === data[i][6]) || data[i][1]?.length === 0 || !data[i][1]){
                  selectNotItem.push(i)
                } else {
                  selectItem.push(i);
                }
              }
              
              items.push({
                key: i,
                row_key: i,
                // eslint-disable-next-line
                account: data[i][0] ? (accuntingList.find((item) => item.label === data[i][0]) ? data[i][0] : "Select Account" ): "",
                classification: data[i][1] ? data[i][1] : "",
                code: code ? code : (data[i][2] ? data[i][2] : ""),
                sub_code: subCode ? subCode : (data[i][3] ? data[i][3] : ""),
                description: data[i][4] ? data[i][4] : "",
                // eslint-disable-next-line
                type: data[i][5] ? (typeList.find((item) => item.excelData === String(data[i][5]).toLowerCase()) ? typeList.find((item) => item.excelData === String(data[i][5]).toLowerCase()).label : "Select Type" ): "",
                // eslint-disable-next-line
                tax: data[i][6] ? (taxList.find((item) => item.value === data[i][6]) ? data[i][6] : "Select Tax" ): "",
              });
            }
          }
        }
        
        return items;
      };
      setExcelSelectedRow(selectItem)
      setExcelNotSelectedRow(selectNotItem)
      setExcelAccountData(businessExcelGenerator(data?.length))
    };
    reader.readAsBinaryString(f);
  }

  useEffect(() => {
    showExcelModal && ExcelReader();
    // eslint-disable-next-line
  },[showExcelModal])

  const handleExcelModal = () => {
    setShowExcelModal(true)
  }

  const handleUpload = async () => {
    if(excelSelectedRow.length > 1000){
      toast.warn("Maximum limit to import chart of accounts is 1000")
    } else {
    if (files) {
      setShowExcelModal(true)
      const formData = new FormData();

      formData.append("client_business_id", businessId);

      let arr = Array.from(new Set(excelSelectedRow));
      for(let i=0; i<arr.length; i++){
        let row = excelAccountData.find((item) => item.key === arr[i])
        if(row.account){
          if(row.account === "Select Account"){
            row.account = null
            row.account_id = null
          } else {
            row.account_id = accuntingList?.find((item) => item?.label === row?.account)?.id
          }
        }
        if(row.type){
          if(row.type === "Select Type"){
            row.type = null
          } else {
            row.type = typeList?.find((item) => item?.label === row?.type)?.base_type
          }
        }
        if(row.tax){
          if(row.tax === "Select Tax"){
            row.tax = null
          } else {
            row.tax = taxList?.find((item) => item?.label === row?.tax)?.tax_type
          }
        }
        if(row.sub_code === ""){
          row.sub_code = null
        }
        if(row.description === ""){
          row.description = null
        }
        // console.log(`accounts[${i}]`, row);
        formData.append(`accounts[${i}]`, JSON.stringify(row))
      }


      const config = { headers: { "Content-Type": "multipart/form-data" } };
      try {
        setIsImportLoading(true);
        const { data } = await fileUpload(
          `${API.IMPORT_ACCOUNT}`,
          formData,
          config
        );
        setIsImportLoading(false);
        if (data) {
          toast.success(data?.message);
          setExcelSelectedRow([]);
          setExcelNotSelectedRow([]);
          handleExcelClose();
          fetchAccountData();
          handleImportClose();
        }
        return data?.data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
          if(error?.response?.data?.data?.failedData.length > 0){
            let failData = []
            for(let i=0; i<error?.response?.data?.data?.failedData.length; i++){
              let accountItem = error?.response?.data?.data?.failedData[i]?.data
              if(accountItem.account === "" || accountItem.account_id === null || accountItem.account === null){
                accountItem.account = "Select Account"
              }
              if(accountItem.type === "" || accountItem.type === null){
                accountItem.type = "Select Type"
              } else {
                accountItem.type = accountItem?.type === 0 ? "Debit" : "Credit"
              }
              if(accountItem.tax === "" || accountItem.type === null){
                accountItem.tax = "Select Tax"
              } else {
                accountItem.tax = accountItem?.tax === 1 ? "GST 10%" : accountItem?.tax === 0 ? "GST Excluded" : accountItem?.tax === 2 ? "BAS Excluded" : "GST on Imports"
              }
              failData.push(accountItem);
            }
            setExcelSelectedRow([]);
            setExcelNotSelectedRow([]);
            setExcelAccountData(failData);
          }
          fetchAccountData();
          setIsImportLoading(false);
        } else {
          toast.error(error?.message);
          setIsImportLoading(false);
        }
      }
    }
    }
  };

  const handleExcelTableChange = async(type, { data, cellEdit: { rowId, dataField, newValue } }) => {
    const getWithPromiseAll = async() => {
      let temp = await Promise.all(
        data.map(async (row) => {
          if (row?.key === rowId) {
            const newRow = { ...row };
            let code = null
            if(dataField !== 'description' && dataField !== 'sub_code') {
              if((row?.code || (dataField === 'code' && newValue)) && 
                (row?.account !== "Select Account" || (dataField === 'account' && newValue !== "Select Account")) && 
                (row?.type !== "Select Type" || (dataField === 'type' && newValue !== "Select Type")) && 
                (row?.tax !== "Select Tax" || (dataField === 'tax' && newValue !== "Select Tax")) && 
                (row?.classification?.length !== 0 || (dataField === 'classification' && newValue?.length !== 0))
              ){
                if((((dataField === 'account' && newValue === "Select Account")) || 
                ((dataField === 'type' && newValue === "Select Type")) || 
                ((dataField === 'tax' && newValue === "Select Tax"))) &&
                (dataField !== 'code')
                ) {
                  let y = excelSelectedRow
                  y.splice(y.indexOf(row?.key), 1)
                  if(String(row?.code)?.length > 7){
                    setExcelSelectedRow(y)
                    excelNotSelectedRow.push(row?.key)
                  } else if(String(row?.code)?.length === 7){
                    if(String(row?.code)?.match("/") && String(row?.code)?.match("/")?.index !== 2){
                      setExcelSelectedRow(y)
                      excelNotSelectedRow.push(row?.key)
                    } else if(String(row?.code)?.match("-") && String(row?.code)?.match("-")?.index !== 2){
                      setExcelSelectedRow(y)
                      excelNotSelectedRow.push(row?.key)
                    }
                  } else if(String(row?.code)?.length < 7){
                    if(String(row?.code)?.match("/") && String(row?.code)?.match("/")?.index !== 1){
                      setExcelSelectedRow(y)
                      excelNotSelectedRow.push(row?.key)
                    } else if(String(row?.code)?.match("-") && String(row?.code)?.match("-")?.index !== 1){
                      setExcelSelectedRow(y)
                      excelNotSelectedRow.push(row?.key)
                    }
                  } else {
                    setExcelSelectedRow(y)
                    excelNotSelectedRow.push(row?.key)
                  }
                
                } else {
                  excelNotSelectedRow.splice(excelNotSelectedRow.indexOf(row?.key), 1);
                  excelSelectedRow.push(row?.key)
                }
              }
            }
            if(dataField === "code"){
              if(String(newValue)?.length < 4){
                if(!String(newValue)?.match("/") &&  !String(newValue)?.match("-")){
                  code = padWithLeadingZeros(String(newValue), 4)
                }
                if(String(newValue)?.match("/") && (String(newValue)?.match("/")?.index === 1 || String(newValue)?.match("/")?.index === 2)){
                  let temp = String(newValue).split("/")
                  let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                  let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                  code = `${firstNumber}/${secondNumber}`
                }
                if(String(newValue)?.match("-") && (String(newValue)?.match("-")?.index === 1 || String(newValue)?.match("-")?.index === 2)){
                  let temp = String(newValue).split("-")
                  let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                  let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                  code = `${firstNumber}-${secondNumber}`
                }
              } else if(String(newValue)?.length < 8){
                if(String(newValue)?.match("/") && (String(newValue)?.match("/")?.index === 1 || String(newValue)?.match("/")?.index === 2)){
                  let temp = String(newValue).split("/")
                  let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                  let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                  code = `${firstNumber}/${secondNumber}`
                }
                if(String(newValue)?.match("-") && (String(newValue)?.match("-")?.index === 1 || String(newValue)?.match("-")?.index === 2)){
                  let temp = String(newValue).split("-")
                  let firstNumber = padWithLeadingZeros(String(temp[0]), 2)
                  let secondNumber = padWithLeadingZeros(String(temp[1]), 4)
                  code = `${firstNumber}-${secondNumber}`
                }
              }
              newRow[dataField] = code ? code : newValue
            } else if (dataField === "sub_code") {
              let subCode = null
              if(String(newValue)?.length < 2){
                if(String(newValue)?.match(/^[\d]*$/)){
                  subCode = String(padWithLeadingZeros(String(newValue), 2))
                }
                if(String(newValue)?.length === 0){
                  subCode = null
                }
              }
              newRow[dataField] = subCode ? subCode : newValue
            } else if(dataField === "account") {
              let accountValue = accuntingList?.find((item) => item?.value === newValue)
              newRow[dataField] = accountValue ? accountValue?.label : "Select Account"
            } else {
              newRow[dataField] = newValue;
            }
            
            return newRow;            
          }
          return row;
        })
      )
      return temp
   }
    const result = await getWithPromiseAll();
    setExcelAccountData(result)
  }


  useEffect(() => {
    navigate(
      `?tab=${accountTab ? accountTab : ""}&search=${
        searchParam ? searchParam : ""
      }&limit=${limit ? limit : 10}&page=${page ? page : 1}`
    );
  }, [searchParam, limit, page, accountTab, navigate]);

  useEffect(() => {
    fetchAccountData();
    window.document.title = `${metaTitle} | Chart Of Account`
  }, [fetchAccountData]);

  const columns = [
    {
      dataField: "name",
      text: "Account Name",
    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "sub_code",
      text: "Sub Code",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "tax_rate",
      text: "Tax Rate",
    },
    {
      dataField: "action",
      text: "Action",
      style: { width: "97px" },
    },
  ];

  // const onSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchParam(value);
  //   setPage(1);
  // };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
          setPage(1)
        }, 300),
    [], 
  );

  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  const toggleAccountModal = () => {
    setShowAccount(!showAccount);
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <TitleBar 
              label={"Chart Of Accounts"}
            />
            <div className="content-details">
              <div
                className="table-top-btn"
                style={{ alignItems: "flex-start" }}
              >
                <div className="search-section">
                  <Form>
                    <FormControl
                      // onChange={(e) => debounce(onSearchChange(e), 300)}
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Chart Of Account"
                    />
                    {searchParamData 
                    ? <Button className="btn-close" onClick={() => {
                      setSearchParamData("")
                      setSearchParam("")
                    }}
                    >x</Button> 
                    : <Button className="fa fa-search">Search</Button>
                    }
                  </Form>
                </div>
                <div className="table-btn">
                  <Button
                    variant="primary"
                    onClick={toggleAccountModal}
                    data-toggle="modal"
                    data-target="#account"
                  >
                    Add New
                  </Button>
                  <div className="border-btn">
                    <DropdownButton className="info-icon">
                      <Dropdown.Item>
                        {/* <p>Account Type - Required</p>
                        <p>Name - Required</p> */}
                        <p>Classification - Required</p>
                        <p>Code - Required, 4 Digit</p>
                        <p>Sub Code - Optional, 4 Digit</p>
                        <p>Description - Optional</p>
                        <p>Type - Required</p>
                        <p>Tax - Required</p>
                      </Dropdown.Item>
                    </DropdownButton>
                    <Button
                      variant="link"
                      disabled={isLoadingDownload}
                      onClick={() => handleDownload()}
                      className="download-template"
                    >
                      Download Template
                    </Button>
                    <Button variant="primary" onClick={handleImportModal}>
                      Upload Chart Of Accounts
                    </Button>
                  </div>
                  <div className="border-btn">
                    <div className="table-btn">
                      <Button variant="primary"
                      disabled={isRestore}
                      onClick={toggleRestoreModal}
                      >Restore</Button>
                      <Button variant="primary"
                      disabled={accountData.length === 0 ? true : isDeleteAll ? true : false}
                      onClick={toggleDeleteAllModal}
                      >{selectedRow?.length > 0 ? "Delete Selected" : "Delete All"}</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-top-btn">
                <ul className="nav nav-tabs">
                  {accountTpesLoading && <Spin />}
                  {accountTypesList &&
                    accountTypesList?.length > 0 &&
                    accountTypesList?.map((item) => {
                      return (
                        <Fragment key={item?.id}>
                          <li
                            className={accountId === item?.id ? "active" : ""}
                          >
                            <Link
                              key={item?.id}
                              onClick={() => {
                                setAccountId(item?.id);
                                setAccountTab(
                                  item?.name.toLowerCase().replace(/ /g, "-")
                                );
                                setPage(1);
                              }}
                            >
                              {item?.name}
                            </Link>
                          </li>
                        </Fragment>
                      );
                    })}
                </ul>
              </div>

              <div className="custom-table tab-table coa-table">
                {accountLoading && <Spin />}
                <BootstrapTable
                  ref={ref}
                  keyField="key"
                  remote
                  data={accountsData}
                  columns={columns}
                  selectRow={selectRow}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAccount && (
        <AddChartOfAccounting
          businessId={businessId}
          toggleAccountModal={toggleAccountModal}
          fetchAccountData={fetchAccountData}
          showAccount={showAccount}
        />
      )}
      
      {showImport && (
        <Modal
          size="lg"
          show={showImport}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Upload Chart Of Account
          </Modal.Header>
          <Modal.Body>
            <div>
              <UploadFile
                businessId={businessId}
                isLoading={isImportLoading}
                setFiles={setFiles}
                files={files}
                handleUpload={handleExcelModal}
                handleCancel={handleImportClose}
                acceptFileFormat="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ErrorMessage="xls and xlsx"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      
      {showRestore && (
        <Modal
          size="lg"
          show={showRestore}
          onHide={toggleRestoreModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Restore Chart Of Accounts
          </Modal.Header>
          <Modal.Body>
            {isRestore && <Spin />}
            <div className="modal-body">
              Are you sure you want to restore chart of accounts?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleRestoreModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleRestore();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Modal of Delete All */}
      {showDeleteAll && (
        <Modal
        size="lg"
        show={showDeleteAll}
        onHide={toggleDeleteAllModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Delete Chart of Account
        </Modal.Header>
        <Modal.Body>
          {isDeleteAll && <Spin />}
          <div className="modal-body">
            Are you sure you want to delete {selectedRow?.length === 0 ? 'all' : 'selected'} chart of account?
          </div>
          <div className="modal-footer">
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleDeleteAllModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                handleDeleteAll();
              }}
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      )}

      {/* Modal of Excel Import*/}
      {showExcelModal && (
      <Modal
        // size="xl"
        show={showExcelModal}
        onHide={handleExcelClose}
        dialogClassName="full-popup large-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Upload Chart of Accounts
        </Modal.Header>
        <Modal.Body>
          <div className="excel-table-list" style={{padding: "20px"}}>
            {isImportLoading && <Spin />}
            <BootstrapTable
              keyField="key"
              selectRow={excelSelectRow}
              ref={excelRef}
              remote={{cellEdit: true}}
              data={excelAccountData ? excelAccountData : []}
              columns={ExcelAccountColumns}
              noDataIndication="No Data Found"
              cellEdit={ cellEditFactory({
                mode: 'click',
                blurToSave: true,
                timeToCloseMessage: 30000
              }) }
              onTableChange={handleExcelTableChange}
            />
            <div className="excel-list-footer"> 
              <Button
                variant="primary"
                onClick={handleUpload}
                data-toggle="modal"
                data-target="#business"
                disabled={excelSelectedRow.length === 0 ? true : false}
              >
                Import
              </Button>
              <div>Selected Records: {excelSelectedRow?.length}</div>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    )
    }
    </>
  );
};

export default ChartOfAccounts;
