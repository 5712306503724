import { ErrorMessage, Field } from 'formik';
import React from 'react';

const NumberGeneratorSettings = ({ label, type }) => {
  return (
    <div className="form-group">
      <div id="my-radio-group" className='settings-heading'>{label}</div>
      <div role="group" aria-labelledby="my-radio-group" className="number-option-body">
        <label>
          <Field type="radio" name="number_option" value="0" />
          Manual Entry
        </label>
        <label>
          <Field type="radio" name="number_option" value="1" />
          Next Number = Last ID + 1
        </label>
        <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
          <ErrorMessage name="number_option" />
        </span>
      </div>
    </div>
  );
};

export default NumberGeneratorSettings;
