import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Form } from 'antd';
import { API, post, get } from '../../config';
import Sitelogo from '../../components/Header/Logo';
import wFavicon from "../../assets/images/myna-white-favicon.png";
import { Button } from 'react-bootstrap';
import Spin from '../common/Spin';

const TwoFactor = () => {
  const navigate = useNavigate();
  const [twoFAMode, setTwoFAMode] = useState('google');
  const [message, setMessage] = useState({ success: '', error: '' });
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("sme-role");
  const invited = localStorage.getItem("sme-client-invited");

  const text = useMemo(
    () =>
      twoFAMode === 'google'
        ? 'Enter the 6-digit code found in your authenticator app'
        : 'We have send a token email Enter the 6-digit code found in your email',
    [twoFAMode]
  );

  var currentdate = new Date(); 

  const handleFinish = async (values) => {
    try {
      setMessage({});
      const email = localStorage.getItem('sme-auth-email');
      const apiURL =
        twoFAMode === 'google' ? API.GOOGLE_2FA_VERIFY : API.VERIFY_EMAIL_AUTHENTICATION;
      const { data } = await post(apiURL, {
        email: email,
        ...values
      });
      if (data.status === 200) {
        setTwoFAMode(null);
        setLoading(true);
        get(API.GET_USER_PROFILE)
          .then(async (response) => {
            const { first_name, profile_pic, last_name, id } = response.data.data;

            localStorage.setItem("sme-Name", first_name ? first_name : last_name);
            localStorage.setItem("sme-ProfilePhoto", profile_pic);
            localStorage.setItem("sme-userId", id);
            localStorage.setItem("sme-EmailVarification", 1);
            localStorage.setItem("sme-auth-login-time", currentdate.getTime());
            localStorage.setItem('sme-auth-tfa', 2);
            

            

            // SetCookie('_subscription', 1);
            localStorage.setItem('sme-subscription', 1);

            if (localStorage.getItem('role') === 'Admin') {
              window.location.href = '/admin/dashboard';
            } else {
              try {
                const { data } = await get(API.GET_SUBSCRIPTION);
                if (data) {
                  // await SetCookie('_subscription', 1);
                  localStorage.setItem('sme-subscription', 1);
                }
              } catch (error) {
                // SetCookie('_subscription', 0);
                localStorage.setItem('sme-subscription', 0);
                window.location.href = '/general-settings?tab=subscriptions';
              }
              if(role === 'Client'){
                localStorage.setItem('sme-subscription', 1);
              }
              if(invited) {
                window.location.href = "/user-settings?tab=change-password"
              } else {
                (role === "Client" || role === "Client User")
                ? window.location.href ='/businesses'
                : role === "Admin"
                ?  window.location.href ='/admin/dashboard'
                : parseInt(localStorage.getItem('sme-subscription')) === 0 
                ? window.location.href = '/general-settings?tab=subscriptions' 
                : window.location.href ='/clients'
              }
            }
          setLoading(false);
          })
          .catch(() => {
            var error = {};
            error['myna_error'] = 'There is some issue with request, please try after some time.';
            setLoading(false);
          });
      } else {
        setMessage({ error: data.errors?.myna_error[0] });
      }
    } catch (e) {
      const { data } = e.response;
      if (data && data.errors) {
        setMessage({ error: data && data.errors['code'][0] });
      } else {
        setMessage({
          error: 'There is some issue with request, please try after some time.'
        });
      }
    }
  };

  const handle2FAModeChange = async () => {
    try {
      const email = localStorage.getItem('sme-auth-email');
      setMessage({});
      setLoading(true);
      setTwoFAMode((prevState) => (prevState === 'google' ? 'email' : 'google'));
      if (twoFAMode === 'google') {
        const { data } = await post(API.EMAIL_AUTHENTICATION, {
          email: email
        });
        if (data.status === 200) {
          setMessage({ success: data.message });
        } else {
          setMessage({ error: data.errors });
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setMessage({
        error: 'There is some issue with request, please try after some time.'
      });
    }
  };

  const handleBackToLogin = () => {
    localStorage.clear();
    navigate('/', { replace: true });
  };

  return (
    <>

      <div className="login-screen">
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" /> SME
              CashBook acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
          <div className="form-block">
            {message.error && <p className="text-danger">{message.error}</p>}
            {message.success && <p className="text-success">{message.success}</p>}
            <Form name="2faForm" onFinish={handleFinish}>
              {loading && <Spin />}
              <div className="auth-text auth-image">{text}</div>
              <Form.Item
                name="code"
                rules={[
                  { required: true, message: 'Please input your authentication code!' },
                  {
                    message: 'Authentication code length should be 6',
                    pattern: new RegExp(/^[0-9]{6}$/)
                  }
                ]}
              >
                <Input autoFocus style={{ marginTop: "15px", padding: '15px 40px', borderRadius: '30px', borderWidth: '3px', border: '1px solid #d9d9d9'}} placeholder="Authentication Code" bordered={false} type="text" />
              </Form.Item>
              <div className="form-group">
                <Button type="submit">
                  Confirm
                </Button>
              </div>
            </Form>
            <p>
              <button onClick={handle2FAModeChange}  className="btn-nolink">
                Use another authentication method
              </button>
            </p>
            <p>
              <button onClick={handleBackToLogin}  className="btn-nolink">
                Back to Login
              </button>
            </p>
            {/* <Footer /> */}
            <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a href="/#" rel="noreferrer">
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="https://smecashbook.com.au/terms-conditions/" rel="noreferrer" target="_blank">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a href="/#" rel="noreferrer" target="_blank">
                Privacy Policy
              </a>
            </p>
            </div>
          </div>
          </div>
    </>
  );
};

export default TwoFactor;
