import React, { useEffect, useState } from 'react';

import { API, fileUpload, get, imageDefaultPrefixPath, metaTitle } from '../../../config';
import { checkIfFileExists, formatName, formatNumber } from '../../common/Misc';
import BackButton from '../../../components/Form/BackButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import CancelButton from '../../../components/Form/CancleButton';
import { Button, Form } from 'react-bootstrap';

import { DatePicker } from 'rsuite';
import moment from 'moment';
import { toast } from 'react-toastify';
import Spin from '../../common/Spin';
import NumberFormat from 'react-number-format';
import { getBusinessData } from '../components/BusinessHelper';
import { useCallback } from 'react';

const EditJournalEntry = () => {
  const GSTClaculateWithouComma = (value, tax) => {
    return tax === 1 ? Number((Number(value) / 1.1) * 0.1) : 0;
  };

  const ExGSTClaculateWithouComma = (value, tax) => {
    return tax === 1 ? Number(Number(value) - (Number(value) / 1.1) * 0.1) : value;
  };

  const MAX_LIMIT_OF_NUMBER = 1000000000000000;
  const MaxLimit = (values) => {
    const { formattedValue, floatValue } = values;
    if (floatValue == null) {
      return formattedValue === '';
    } else {
      return floatValue < MAX_LIMIT_OF_NUMBER && floatValue >= 0;
    }
  };

  const params = useParams();
  const businessId = params?.business_id;
  const businessName = localStorage.getItem('sme-businessName');
  const businessLogo = localStorage.getItem('sme-businessLogo');
  const [BusinessName, setBusinessName] = useState(businessName);
  const [BusinessLogo, setBusinessLogo] = useState(businessLogo);
  const [refNumber, setRefNumber] = useState();
  // const [cashTransaction, setCashTransaction] = useState(false);
  const [period, setPeriod] = useState();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const editId = query.get('id');
  const batch_ID = location?.state?.lastBatchId;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const taxList = [
    { label: 'GST 10%', value: 1 },
    { label: 'GST Excluded', value: 0 },
    { label: 'BAS Excluded', value: 2 },
    { label: 'GST on Imports', value: 3 }
  ];

  const defaulEntryData = [
    {
      account: '',
      description: '',
      gst: '',
      debit: '',
      credit: '',
      tax: '',
      exTax: '',
      contact: ''
    },
    {
      account: '',
      description: '',
      gst: '',
      debit: '',
      credit: '',
      tax: '',
      exTax: '',
      contact: ''
    }
  ];

  const newEntryData = {
    account: '',
    description: '',
    gst: '',
    debit: '',
    credit: '',
    tax: '',
    contact: '',
    notes: ''
  };

  const [entryData, setEntryData] = useState(defaulEntryData);
  const [errors, setErrors] = useState(defaulEntryData);

  const today = new Date();
  const [transactionDate, setTransactionDate] = useState(today);

  const [totalDeference, setTotalDeference] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [accontLoading, setAccountLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [allAccountValue, setAllAccountValue] = useState([]);

  const [allContactList, setAllContactList] = useState([]);
  const [loadingContactList, setLoadingContactList] = useState(false);
  const [allContactValue, setAllContactValue] = useState([]);

  const [batchID, setBatchID] = useState(batch_ID);

  useEffect(() => {
    !businessName && getBusinessData(businessId, setBusinessName, setBusinessLogo);
  }, [businessName, businessId]);

  useEffect(() => {
    checkIfFileExists(`${imageDefaultPrefixPath}${BusinessLogo}`, (exists) => {
      if (exists) {
        setBusinessLogo(BusinessLogo);
      } else {
        setBusinessLogo('');
      }
    });
  }, [BusinessLogo]);

  const handleAddEntry = (data, errorsData, index) => {
    setEntryData([...data, newEntryData]);
    setErrors([...errorsData, newEntryData]);
  };

  const handleDeleteEntry = (data, errorsData, index) => {
    data.splice(index, 1);
    errorsData.splice(index, 1);
    setEntryData([...data]);
    setErrors([...errorsData]);
  };

  const handleAccountChange = async (data, index, e) => {
    const amount = data[index]?.debit
      ? Number(data[index]?.debit)
      : data[index]?.credit
      ? Number(data[index]?.credit)
      : 0;
    const exTax = ExGSTClaculateWithouComma(amount, e?.tax);

    let finaltax = 0;
    if (amount > 0) {
      finaltax = GSTClaculateWithouComma(amount, e?.tax);
    } else {
      finaltax = 0;
    }

    const newArr = data.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          account: e,
          gst: taxList?.find((item) => parseInt(item?.value) === parseInt(e?.tax)),
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2)
        };
      }
      return obj;
    });

    setEntryData(newArr);
  };

  const handleDescriptionChange = async (data, index, e) => {
    let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index].description = 'Enter valid Description.';
      } else {
        errors[index].description = null;
      }
    }
    setErrors([...errors]);

    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleGSTChange = async (data, index, e) => {
    const amount = data[index]?.debit
      ? Number(data[index]?.debit)
      : data[index]?.credit
      ? Number(data[index]?.credit)
      : 0;
    const exTax = ExGSTClaculateWithouComma(amount, e?.value);

    let finaltax = 0;
    if (amount > 0) {
      finaltax = GSTClaculateWithouComma(amount, e?.value);
    } else {
      finaltax = 0;
    }

    const newArr = data.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          gst: e,
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2)
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleDebitChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ''));
        const amount = e ? Number(value).toFixed(2) : 0;
        const tax = data[index]?.gst?.value;
        const exTax = ExGSTClaculateWithouComma(amount, tax);

        let finaltax = 0;
        if (amount > 0) {
          finaltax = GSTClaculateWithouComma(amount, tax);
        } else {
          finaltax = 0;
        }
        return {
          ...obj,
          debit: value,
          credit: '',
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2)
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleCreditChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ''));
        const amount = e ? Number(value).toFixed(2) : 0;
        const tax = data[index]?.gst?.value;
        const exTax = ExGSTClaculateWithouComma(amount, tax);

        let finaltax = 0;
        if (amount > 0) {
          finaltax = GSTClaculateWithouComma(amount, tax);
        } else {
          finaltax = 0;
        }

        return {
          ...obj,
          credit: value,
          debit: '',
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2)
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleContactChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, contact: e };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  useEffect(() => {
    let debitTotal = 0;
    let creditTotal = 0;
    entryData &&
      entryData?.length > 0 &&
      entryData?.map((item) => {
        debitTotal += item?.debit ? Number(item?.debit) : 0;
        creditTotal += item?.credit ? Number(item?.credit) : 0;
        return 0;
      });
    setTotalDebit(formatNumber(Number(debitTotal)));
    setTotalCredit(formatNumber(Number(creditTotal)));
    let deference = debitTotal - creditTotal;
    setTotalDeference(formatNumber(Number(deference)));
  }, [entryData]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append(
      'transactionDate',
      transactionDate ? moment(transactionDate).format('DD-MM-YYYY') : ''
    );
    formData.append('client_business_id', businessId ? businessId : '');
    formData.append('period', period ? period : '');
    // formData.append('cash_transaction', cashTransaction ? 1 : 0);
    formData.append('reference_number', refNumber ? refNumber : '');
    formData.append('batch_id', batchID);
    formData.append('_method', 'put');

    values?.forEach((item, index) => {
      if (item) {
        const debit = Number(`${item?.debit}`.replace(/,/g, ''));
        const credit = Number(`${item?.credit}`.replace(/,/g, ''));
        const tax = Number(`${item?.tax}`.replace(/,/g, ''));
        const exTax = Number(`${item?.exTax}`.replace(/,/g, ''));

        formData.append(
          `journalEntries[${index}][id]`,
          item?.id ? item?.id : ''
        );

        formData.append(
          `journalEntries[${index}][chart_of_account_id]`,
          item?.account?.value ? item?.account?.value : ''
        );

        formData.append(
          `journalEntries[${index}][contact_id]`,
          item?.contact?.value ? item?.contact?.value : ''
        );
        formData.append(`journalEntries[${index}][transaction_type]`, item?.credit ? 1 : 0);

        formData.append(`journalEntries[${index}][amount]`, credit ? credit : debit);
        formData.append(`journalEntries[${index}][gst]`, item?.gst ? item?.gst?.value : 0);
        formData.append(`journalEntries[${index}][gst_amount]`, tax);
        formData.append(`journalEntries[${index}][net_amount]`, exTax);
        formData.append(
          `journalEntries[${index}][description]`,
          item?.description ? item?.description : ''
        );
      }
    });

    try {
      setLoading(true);
      if (batchID) {
        const response = await fileUpload(`${API.JOURNAL_ENTRIES}/${editId}`, formData, {});
        if (response?.data?.message) {
          toast.success(response?.data?.message);
          navigate(-1);
        }
      } else {
        toast.error('Batch no is required');
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errors = error?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const postDisabled = (values) => {
    if (values && values?.length > 1) {
      const checkData = values?.map((item, index) => {
        if (
          item?.account &&
          (item?.debit || item?.credit) &&
          Number(totalDeference) === 0.0 &&
          Number(totalDebit) !== 0
        ) {
          return false;
        } else {
          return true;
        }
      });
      return checkData?.find((item) => item === true) || !transactionDate ? true : false;
    } else {
      return true;
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleAccountList = useCallback(async () => {
    try {
      setAccountLoading(true);
      get(`${API.GET_ACCOUNT_DATA}?client_business_id=${businessId}&limit=-1&page=1`).then(
        (response) => {
          // let account = response?.data?.data?.data.map((element) => {
          //   return {
          //     value: element.id,
          //     label:
          //       element?.name +
          //       (element?.account_type?.name ? ' (' + element?.account_type?.name + ')' : '') +
          //       (element?.code
          //         ? ' - ' +
          //           (element?.code?.length < 4
          //             ? padWithLeadingZeros(element?.code, 4)
          //             : element?.code.toString())
          //         : '') +
          //       (element?.sub_code ? '.' + element?.sub_code.toString() : '')
          //   };
          // });
          // setAccountOptions(account);
          // setAccountLoading(false);
          var revenue =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === 'fada83c9-4769-49b9-ab0e-fcd8c43de30e';
                })
              : [];

          const revenueList =
            revenue && revenue?.length > 0
              ? revenue?.map((item, index) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          var expenses =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === 'fad90f00-6651-4c51-a29a-78398fa226fd';
                })
              : [];

          const expensesList =
            expenses && expenses?.length > 0
              ? expenses?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          var currentLiabilities =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '3e5a94ef-b819-47c3-9955-d47da29e2ab0';
                })
              : [];

          const currentLiabilitiesList =
            currentLiabilities && currentLiabilities?.length > 0
              ? currentLiabilities?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          var nonCurrentLiabilities =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === 'fcb7e129-7666-42ae-8c3d-11d016bbe340';
                })
              : [];

          const nonCurrentLiabilitiesList =
            nonCurrentLiabilities && nonCurrentLiabilities?.length > 0
              ? nonCurrentLiabilities?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          var currentAssets =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '994d6d38-497b-465d-9f57-3bdc2d70594a';
                })
              : [];

          const currentAssetsList =
            currentAssets && currentAssets?.length > 0
              ? currentAssets?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          var nonCurrentAssets =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '4051efd3-9fb8-4adb-b0c7-7e565a078ac1';
                })
              : [];

          const nonCurrentAssetsList =
            nonCurrentAssets && nonCurrentAssets?.length > 0
              ? nonCurrentAssets?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          var equity =
            response?.data?.data?.data?.length > 0
              ? response?.data?.data?.data.filter((element) => {
                  return element?.account_type_id === '520f936e-3017-4574-a9ab-6c9e68b591e4';
                })
              : [];

          const equityList =
            equity && equity?.length > 0
              ? equity?.map((item) => {
                  const list = {
                    label:
                      // eslint-disable-next-line
                      (item?.code !== '2000' && item?.code !== '2050') &&
                      `${
                        item?.code?.length < 4 ? padWithLeadingZeros(item?.code, 4) : item?.code
                      }` +
                        `${item?.sub_code ? '.' + item?.sub_code : ''}` +
                        ' - ' +
                        `${item?.name ? item?.name : ''}` +
                        `${item?.account_type?.name ? ' (' + item?.account_type?.name + ')' : ''}`,
                    value: (item?.code !== '2000' && item?.code !== '2050') && item?.id,
                    tax: (item?.code !== '2000' && item?.code !== '2050') && item?.tax
                  };
                  return list ? list : [];
                })
              : [];

          const allAccountListing = [
            revenueList && {
              label: <div className="list-subHeading">Revenue</div>,
              options: revenueList
            },
            expensesList && {
              label: <div className="list-subHeading">Expenses</div>,
              options: expensesList
            },
            currentLiabilitiesList && {
              label: <div className="list-subHeading">Current Liabilities</div>,
              options: currentLiabilitiesList
            },
            nonCurrentLiabilitiesList && {
              label: <div className="list-subHeading">Non Current Liabilities</div>,
              options: nonCurrentLiabilitiesList
            },
            currentAssetsList && {
              label: <div className="list-subHeading">Current Assets</div>,
              options: currentAssetsList
            },
            nonCurrentAssetsList && {
              label: <div className="list-subHeading">Non Current Assets</div>,
              options: nonCurrentAssetsList
            },
            equityList && {
              label: <div className="list-subHeading">Equity</div>,
              options: equityList
            }
            // { label: <strong>+ Add New Account</strong>, value: "new" }
          ];
          setAllAccountValue([
            ...revenueList,
            ...expensesList,
            ...currentLiabilitiesList,
            ...nonCurrentLiabilitiesList,
            ...currentAssetsList,
            ...nonCurrentAssetsList,
            ...equityList
          ]);

          setAccountOptions(allAccountListing);
          setAccountLoading(false);
        }
      );
    } catch (error) {
      setAccountLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  const handleContactList = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingContactList(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name)
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && {
            label: <div className="list-subHeading">Contractors</div>,
            options: contractorList
          },
          supplierList && {
            label: <div className="list-subHeading">Suppliers</div>,
            options: supplierList
          },
          customerList && {
            label: <div className="list-subHeading">Customers</div>,
            options: customerList
          }
        ];

        setAllContactValue([...contractorList, ...supplierList, ...customerList]);

        setAllContactList(allContactListing);

        setLoading(false);
        setLoadingContactList(false);
      });
    } catch (error) {
      setLoading(false);
      setLoadingContactList(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [businessId]);

  useEffect(() => {
    handleAccountList();
  }, [handleAccountList]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  useEffect(() => {
    var month = moment(transactionDate).month() + 1;
    if (month === 7) {
      setPeriod(1);
    } else if (month === 8) {
      setPeriod(2);
    } else if (month === 9) {
      setPeriod(3);
    } else if (month === 10) {
      setPeriod(4);
    } else if (month === 11) {
      setPeriod(5);
    } else if (month === 12) {
      setPeriod(6);
    } else if (month === 1) {
      setPeriod(7);
    } else if (month === 2) {
      setPeriod(8);
    } else if (month === 3) {
      setPeriod(9);
    } else if (month === 4) {
      setPeriod(10);
    } else if (month === 5) {
      setPeriod(11);
    } else {
      setPeriod(12);
    }
  }, [transactionDate]);

  const fetchJournalEntryData = useCallback(async () => {
    if (editId) {
      try {
        const { data } = await get(`${API.JOURNAL_ENTRIES}/${editId}`);
        let journalData = [];
        // eslint-disable-next-line
        data?.data?.journal_entry_details?.map((item) => {
          let account = '';
          if (item?.chart_of_account_id) {
            account = allAccountValue?.find((i) => i.value === item?.chart_of_account_id);
          }
          let contact = '';
          if (item?.contact_id) {
            contact = allContactValue?.find((i) => i.value === item?.contact_id);
          }
          let gst = '';
          gst = taxList?.find((i) => i.value === item?.gst);
          let exTax = '';
          if (item?.amount) {
            exTax = ExGSTClaculateWithouComma(item?.amount, item?.gst);
          }
          let data = {
            id: item?.id,
            account: account,
            description: item?.description,
            gst: gst,
            debit: item?.transaction_type === 0 ? item?.amount : '',
            credit: item?.transaction_type === 1 ? item?.amount : '',
            tax: item?.gst_amount,
            exTax: exTax,
            contact: contact
          };
          journalData.push(data);
        });
        setBatchID(data?.data?.batch_id);
        setRefNumber(data?.data?.reference_number);
        let dateParts = data?.data?.transactionDate?.split('-');
        setTransactionDate(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));
        setEntryData(journalData);
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
    // eslint-disable-next-line
  }, [editId, allAccountValue, allContactList]);

  useEffect(() => {
    fetchJournalEntryData();
    window.document.title = `${metaTitle} | Edit Journal Entry`
  }, [fetchJournalEntryData]);
  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Edit Journal Entry
              <div className="page-title-right">
                {BusinessLogo && <img src={`${imageDefaultPrefixPath}${BusinessLogo}`} alt="" />}{' '}
                {BusinessName}
              </div>
            </h1>
            {loading && <Spin />}
            <div className="content-details">
              <div
                className={
                  totalDeference === '0.00' && totalDebit !== 0
                    ? 'jounal-entry-card-completed'
                    : 'jounal-entry-card'
                }
              >
                <span className="journal-entry-status">
                  <Form>
                    {/* <div className="form-group half-width">
                      <label>Amount</label>
                      <NumberFormat
                        type="text"
                        name="amount"
                        disabled={entryData.length > 2 ? true : false}
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator={true}
                        onChange={(e) => {
                          handleAmountChange(entryData, e);
                        }}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                      />
                    </div> */}
                    <div className="row">
                      <div className="col-md-2">
                        <div className="journal-table">
                          <label>
                            <span className="required">*</span>Batch No.
                          </label>
                          <NumberFormat
                            type="text"
                            placeholder="Enter Batch No."
                            value={batchID}
                            onChange={(e) => {
                              setBatchID(e?.target?.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="journal-table">
                          <label>Reference No.</label>
                          <input
                            type="text"
                            placeholder="Enter Reference No."
                            value={refNumber}
                            onChange={(e) => {
                              setRefNumber(e?.target?.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="journal-date new-transaction-modal">
                          <label>
                            <span className="required">*</span>Date:
                          </label>
                          <DatePicker
                            name="transactionDate"
                            onChange={(e) => {
                              setTransactionDate(e);
                            }}
                            style={{
                              height: '25.14px'
                            }}
                            cleanable={false}
                            value={transactionDate ? transactionDate : ''}
                            placeholder="Select Date"
                            format="dd-MM-yyyy"
                            disabledDate={(date) => moment(date).isAfter(moment())}
                          />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="journal-table">
                          <label>Period:</label>
                          <input type="text" placeholder="Period" value={period} disabled />
                        </div>
                      </div>
                      <div
                        className="col-md-2"
                        style={{
                          height: '74px',
                          display: 'flex',
                          alignItems: 'flex-end'
                        }}
                      >
                        {/* <div className="journal-table">
                          <input
                            type="checkbox"
                            placeholder="Reference No."
                            checked={cashTransaction}
                            onChange={(e) => {
                              setCashTransaction(e?.target?.checked);
                            }}
                          />
                          <label style={{ marginLeft: '8px' }}>Cash Transaction</label>
                        </div> */}
                      </div>
                    </div>
                  </Form>
                  <div className="journal-entry">
                    <p>
                      Total Debit
                      <i>{totalDebit ? totalDebit : 0.0}</i>
                    </p>
                    <p>
                      Total Credit<i>{totalCredit}</i>
                    </p>
                    <p>
                      Difference<i>{totalDeference}</i>
                    </p>
                  </div>
                </span>
                {accontLoading && loadingContactList ? (
                  <Spin />
                ) : (
                  <div className="table table-bordered journal-table">
                    <div className="react-bootstrap-table journal-entries-table">
                      <table className="table-content">
                        <thead>
                          <tr>
                            <th scope="col">
                              <span className="required">*</span>Account
                            </th>
                            <th scope="col">Description</th>
                            <th scope="col">
                              <span className="required">*</span>GST
                            </th>
                            <th scope="col">
                              <span className="required">*</span>Debit
                            </th>
                            <th scope="col">
                              <span className="required">*</span>Credit
                            </th>
                            <th scope="col">Ex. GST Amount</th>
                            <th scope="col">Tax</th>
                            <th scope="col">Contact</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {entryData &&
                            entryData?.length > 0 &&
                            entryData?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: '15%' }}>
                                    <Select
                                      name="account"
                                      onChange={(e) => {
                                        handleAccountChange(entryData, index, e);
                                      }}
                                      options={accountOptions}
                                      value={item?.account ? item?.account : null}
                                      placeholder="Select Account Type"
                                      classNamePrefix="selectbox"
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary: 'grey'
                                        }
                                      })}
                                      isLoading={accontLoading}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                      isClearable={false}
                                    />
                                    <span className="text-danger">
                                      {errors[index]?.account ? errors[index]?.account : ''}
                                    </span>
                                  </td>

                                  <td style={{ width: '15%' }}>
                                    <textarea
                                      type="text"
                                      name={`description`}
                                      value={item?.description ? item?.description : ''}
                                      onChange={(e) => {
                                        handleDescriptionChange(entryData, index, e);
                                      }}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                    <span className="text-danger">
                                      {errors[index]?.description ? errors[index]?.description : ''}
                                    </span>
                                  </td>

                                  <td style={{ width: '10%' }}>
                                    <Select
                                      name={`gst`}
                                      value={item?.gst ? item?.gst : taxList[1]}
                                      onChange={(e) => {
                                        handleGSTChange(entryData, index, e);
                                      }}
                                      options={taxList}
                                      placeholder="Select GST"
                                      classNamePrefix="selectbox"
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary: 'grey'
                                        }
                                      })}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                    <span className="text-danger">
                                      {errors[index]?.gst ? errors[index]?.gst : ''}
                                    </span>
                                  </td>

                                  <td style={{ width: '10%' }}>
                                    <NumberFormat
                                      type="text"
                                      name="debit"
                                      value={item?.debit ? item?.debit : ''}
                                      onChange={(e) => {
                                        handleDebitChange(entryData, index, e);
                                      }}
                                      decimalScale={2}
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      isAllowed={(values) => MaxLimit(values)}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                    <span className="text-danger">
                                      {errors[index]?.debit ? errors[index]?.debit : ''}
                                    </span>
                                  </td>

                                  <td style={{ width: '10%' }}>
                                    <NumberFormat
                                      type="text"
                                      name="credit"
                                      value={item?.credit ? item?.credit : ''}
                                      onChange={(e) => {
                                        handleCreditChange(entryData, index, e);
                                      }}
                                      decimalScale={2}
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      isAllowed={(values) => MaxLimit(values)}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                    <span className="text-danger">
                                      {errors[index]?.credit ? errors[index]?.credit : ''}
                                    </span>
                                  </td>

                                  <td style={{ width: '10%' }}>
                                    <NumberFormat
                                      disabled
                                      type="text"
                                      name={`exTax`}
                                      value={item?.exTax ? item?.exTax : 0}
                                      decimalScale={2}
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      isAllowed={(values) => MaxLimit(values)}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                  </td>

                                  <td style={{ width: '10%' }}>
                                    <NumberFormat
                                      disabled
                                      type="text"
                                      name={`tax`}
                                      value={item?.tax ? item?.tax : 0}
                                      decimalScale={2}
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      isAllowed={(values) => MaxLimit(values)}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                  </td>

                                  <td style={{ width: '15%' }}>
                                    {/* <ContractorSelectWithTA
                                  regionName={'0'}
                                  businessId={businessId}
                                  value={item?.contact ? item?.contact : null}
                                  onChange={(e) => {
                                    handleContactChange(entryData, index, e);
                                  }}
                                  className="contactname-select"
                                  placeholder="Select Sub Contractor"
                                  isClearable={false}
                                /> */}
                                    <Select
                                      // type="select"
                                      name="contact"
                                      value={item?.contact ? item?.contact : null}
                                      onChange={(e) => {
                                        handleContactChange(entryData, index, e);
                                      }}
                                      options={allContactList}
                                      isClearable={true}
                                      placeholder="Select Contact"
                                      classNamePrefix="selectbox"
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary: 'grey'
                                        }
                                      })}
                                      isLoading={loadingContactList}
                                      autoComplete="off"
                                      onKeyPress={(e) => {
                                        e.which === 13 && e.preventDefault();
                                      }}
                                    />
                                    <span className="text-danger">
                                      {errors[index]?.contact ? errors[index]?.contact : ''}
                                    </span>
                                  </td>

                                  <td style={{ width: '2%' }}>
                                    {index + 1 === entryData?.length ? (
                                      <>
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            handleAddEntry(entryData, errors, index);
                                          }}
                                          icon={faAdd}
                                          size="1x"
                                        />
                                        {index > 1 && (
                                          <>
                                            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                            <FontAwesomeIcon
                                              onClick={() => {
                                                handleDeleteEntry(entryData, errors, index);
                                              }}
                                              icon={faTrash}
                                              size="1x"
                                            />
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      index > 1 && (
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            handleDeleteEntry(entryData, errors, index);
                                          }}
                                          icon={faTrash}
                                          size="1x"
                                        />
                                      )
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div className="action-box">
                  <Button
                    disabled={postDisabled(entryData)}
                    onClick={() => {
                      handleSubmit(entryData);
                    }}
                  >
                    Post
                  </Button>
                  <CancelButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditJournalEntry;
