import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Spin from "../../../../common/Spin";
import { disconnectProviderBank } from "./IntegrationsHelper";

const DisconnectBank = ({
  businessId,
  providerId,
  toggleDisconnectModal,
  handleDisconnect,
  setProviderBankLoading,
  disconnectModal,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDisconnectBank = async () => {
    try {
      setProviderBankLoading(true);
      setLoading(true);
      const disconnect = await disconnectProviderBank(businessId, providerId);
      setProviderBankLoading(false);
      setLoading(false);
      if (disconnect.status === 200) {
        toast.success(disconnect?.message);
        handleDisconnect();
      }
    } catch (e) {
      setLoading(false);
      setProviderBankLoading(false);
      return null;
    }
  };
  return (
    <Modal
      size="lg"
      show={disconnectModal}
      onHide={toggleDisconnectModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="business-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Disconnect Bank
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are you sure you want to disconnect this bank?
        </div>
        <div className="modal-footer">
          <Button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={toggleDisconnectModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleDisconnectBank();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisconnectBank;
