import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import AddBusiness from '../../Settings/Business/AddBusiness';
import { useParams } from 'react-router-dom';

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleUserModal,
  officeListing,
  officeLoading,
  businessListing,
  businessLoading,
  roleLoading,
  roleListing,
  selectedOffices,
  setSelectedOffices,
  selectedBusinesses,
  setSelectedBusinesses,
  clientId,
  businessSettings
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleUserModal: PropTypes.any,
    officeListing: PropTypes.any,
    officeLoading: PropTypes.any,
    roleLoading: PropTypes.any,
    roleListing: PropTypes.any
  };

  const [isChecked, setIsChecked] = useState(values?.is_admin_user);
  const [showBusiness, setShowBusiness] = useState(false);

  useEffect(() => {
    setIsChecked(values?.is_admin_user);
  }, [values?.is_admin_user]);

  const handleOfficeChange = (e, setFieldValue) => {
    setFieldValue('office_ids', e.value);
    setSelectedOffices(e.value);
  };

  const filterOfficeInputRef = useRef();

  const handleOfficeApplyFilter = () => {
    filterOfficeInputRef.current.hide();
  };

  const officeResetFunction = () => {
    setSelectedOffices([]);
    filterOfficeInputRef && filterOfficeInputRef.current.focus();
    filterOfficeInputRef.current.hide();
  };

  const handleBusinessChange = (e, setFieldValue) => {
    setFieldValue('business_ids', e.value);
    setSelectedBusinesses(e.value);
  };

  const filterBusinessInputRef = useRef();

  const handleBusinessApplyFilter = () => {
    filterBusinessInputRef.current.hide();
  };

  const BusinessResetFunction = () => {
    setSelectedBusinesses([]);
    filterBusinessInputRef && filterBusinessInputRef.current.focus();
    filterBusinessInputRef.current.hide();
  };

  const toggleBusinessModal = () => {
    // showBusiness && businessModal();
    setShowBusiness(!showBusiness);
  };

  const params = useParams();
  const clientIDParams = params?.client_id;
  const clientID = localStorage.getItem('sme-clientId')
    ? localStorage.getItem('sme-clientId')
    : clientIDParams
    ? clientIDParams
    : clientId;

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>First Name
          </label>
          <input
            type="text"
            name="first_name"
            value={values?.first_name ? values?.first_name : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="first_name" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Last Name Or Trading Name
          </label>
          <input
            type="text"
            name="last_name"
            value={values?.last_name ? values?.last_name : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="last_name" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Email
          </label>
          <input
            type="text"
            name="email"
            value={values?.email ? values?.email : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="email" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Role
          </label>
          <Select
            name="role_id"
            value={values?.role_id ? values?.role_id : ''}
            onChange={(e) => {
              setFieldValue(`role_id`, e ? e : '');
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={roleListing}
            classNamePrefix="selectbox"
            placeholder="Select Role"
            isLoading={roleLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="role_id" />
          </span>
        </div>
        {clientID && (
          <>
            <div className="form-group half-width">
              <label>Office</label>
              <MultiSelect
                value={selectedOffices}
                selectedItemsLabel={`${selectedOffices?.length} Office(s) Selected`}
                name="office_ids"
                onChange={(e) => {
                  handleOfficeChange(e, setFieldValue);
                }}
                options={officeListing ? officeListing : { label: 'Add New Office', value: 'new' }}
                optionLabel="label"
                // filter
                placeholder="Select Office"
                maxSelectedLabels={0}
                ref={filterOfficeInputRef}
                panelFooterTemplate={
                  <>
                    <hr/>
                    <Button
                      className="multiSelect-button"
                      onClick={() => {
                        handleOfficeApplyFilter();
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      className="multiSelect-button"
                      onClick={() => {
                        officeResetFunction();
                      }}
                    >
                      Reset
                    </Button>
                  </>
                }
              />
              <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                <ErrorMessage name="office_ids" />
              </span>
            </div>
            {!businessSettings && (
              <div className="form-group half-width">
                <label>Businesses</label>
                <MultiSelect
                  value={selectedBusinesses}
                  selectedItemsLabel={`${selectedBusinesses?.length} Businesses Selected`}
                  name="business_ids"
                  onChange={(e) => {
                    handleBusinessChange(e, setFieldValue);
                  }}
                  options={businessListing}
                  optionLabel="label"
                  // filter
                  placeholder="Select Businesses"
                  maxSelectedLabels={0}
                  ref={filterBusinessInputRef}
                  panelFooterTemplate={
                    <>
                      <hr/>
                      <Button
                        className="multiSelect-button"
                        onClick={() => {
                          handleBusinessApplyFilter();
                        }}
                      >
                        OK
                      </Button>
                      <Button
                        className="multiSelect-button"
                        onClick={() => {
                          BusinessResetFunction();
                        }}
                      >
                        Reset
                      </Button>
                    </>
                  }
                />
                <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
                  <ErrorMessage name="business_ids" />
                </span>
              </div>
            )}
          </>
        )}

        <div className="form-group half-width client-check-input">
          {/* <label>
            &nbsp;
          </label> */}
          <div className="client-checkbox-body">
            <input
              type="checkbox"
              name="is_admin_user"
              value={values?.is_admin_user}
              checked={isChecked}
              onClick={(e) => {
                setIsChecked(!isChecked);
                setFieldValue('is_admin_user', !isChecked);
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />{' '}
            <label style={{ margin: '0px' }}>Is Admin User?</label>
          </div>
          <span className="text-danger" style={{ color: 'red', fontSize: '20px' }}>
            <ErrorMessage name="is_admin_user" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="reset"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleUserModal();
          }}
        >
          Cancel
        </Button>
      </div>
      {showBusiness && (
        <AddBusiness
          clientId={clientId}
          toggleBusinessModal={toggleBusinessModal}
          // businessModal={businessModal}
          // setBusinessList={setBusinessList}
          showBusiness={showBusiness}
        />
      )}
    </form>
  );
};

export default Form;
