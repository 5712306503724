import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../../config";

export const YodleeConnectModal = ({
  fastLinkData,
  businessId,
  handleYodleeModal,
  handleProviderBank,
  setOpenYodleeLoading,
  handleSucess,
  setAccountId,
  accountFetchId
}) => {
  const handleSaveAccounts = async (providersData) => {
    try {
      setOpenYodleeLoading(true);
      const { data } = await fileUpload(`${API.SAVE_PROVIDERS_ACCOUNTS}`, {
        client_business_id: businessId,
        yodlee_providers: providersData,
      });
      if (data) {
        if(handleSucess) {
          await handleSucess();
          await setAccountId(accountFetchId);
        }
        toast.success(data?.message);
        handleYodleeModal();
        handleProviderBank();
        // navigate("/clients");
        setOpenYodleeLoading(false);
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
      setOpenYodleeLoading(false);
    }
  };

  useEffect(() => {
    window.fastlink.open(
      {
        fastLinkURL: fastLinkData?.fastLinkURL,
        accessToken: fastLinkData?.accessToken,
        params: fastLinkData?.params,
        onSuccess: function (data) {
          // will be called on success. For list of possible message, refer to onSuccess(data) Method.
          // console.log("onSuccess--->", data);
        },
        onError: function (data) {
          // will be called on error. For list of possible message, refer to onError(data) Method.

          data?.code && data?.message && toast.error(data?.message);
          data?.code &&
            !data?.message &&
            data?.description &&
            toast.error(data?.description);
          // console.log("onError--->", data);
        },
        onClose: function (providersData) {
          // will be called called to close FastLink. For list of possible message, refer to onClose(data) Method.
          // console.log("onClose--->", providersData?.sites);

          providersData?.sites &&
            providersData?.sites?.length > 0 &&
            handleSaveAccounts(providersData?.sites);

          handleYodleeModal();
        },
        onEvent: function (data) {
          // will be called on intermittent status update.
          // console.log("onEvent--->", data);
        },
      },
      "container-fastlink"
    );
  });

  return <div className="pt-0 pb-2 px-4" id="container-fastlink"></div>;
};
