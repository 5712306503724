export const printComponent = (componentId) => {
    const fileName = 'download';
  
    var mywindow = window.open('', 'PRINT', 'height=1200,width=1170');
  
    mywindow.document.write('<html><head>');
    mywindow.document.write('<style>');
    mywindow.document.write('@page { size: 7in 9.25in; margin: 7.8mm 5.08mm 7.8mm 5.08mm; }');
    mywindow.document.write('body{ -webkit-print-color-adjust:exact !important; print-color-adjust:exact !important; }');
    mywindow.document.write('</style>');
    mywindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900" rel="stylesheet">'
    );
    mywindow.document.write(`<title> ${fileName} </title>`)
    mywindow.document.write('</head><body >');

    mywindow.document.write(document.getElementById(componentId).innerHTML);
    // console.log(document.getElementById(componentId).innerHTML);
    mywindow.document.write('</body></html>');
  
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    // setTimeout(function () {
      mywindow.print();
    //   mywindow.close();
    // );
    return true;
  };
  