import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
// import Select from "react-select";
import CancelButton from "../../../../../components/Form/CancleButton";
import { Button } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import AddNewAccountModal from "../../../CashFlow/components/AddNewAccountModal";
import AddNewContactModal from "../../../CashFlow/components/AddNewContactModal";
import AddNewJobModal from "../../../CashFlow/components/AddNewJobModal";
import AddNewTaskModal from "../../../CashFlow/components/AddNewTaskModal";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  accountOptions,
  accontLoading,
  jobOptions,
  jobLoading,
  taskOptions,
  taskLoading,
  allContactList,
  handleContactList,
  loadingContactList,
  allContactValue,
  transactionOptions,
  amountOptions,
  getAccounts,
  businessId,
  setNewAccount,
  setNewContact,
  setNewJob,
  setNewTask,
  handleJobChange,
  handleTaskChange,
  setNewTerm,
  setNewDescription
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    accountOptions: PropTypes.any,
    accontLoading: PropTypes.any,
  };

  const [addNewAccount, setAddNewAccount] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [addNewJob, setAddNewJob] = useState(false);
  const [addNewTask, setAddNewTask] = useState(false);

  const [newAccountName, setNewAccountName] = useState("");
  const [newContactLastName, setNewContactLastName] = useState("");
  const [newJobCode, setNewJobCode] = useState("");
  const [newTaskCode, setNewTaskCode] = useState("");

  const handleAddNewAccount = () => {
    setAddNewAccount(!addNewAccount);
  };

  const handleAddNewContact = () => {
    setAddNewContact(!addNewContact);
  };

  const handleAddNewJob = () => {
    setAddNewJob(!addNewJob);
  };

  const handleAddNewTask = () => {
    setAddNewTask(!addNewTask);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card add-business-client">
        <h3>Search Term Memorisation</h3>
        <div className="form-box memorization-form-box">
          {/* <div class="form-box-half"> */}
          <div className="form-group three-col-width">
            <label>
              <span className="required">*</span>
              Term to Memorise
            </label>
            <input
              type="text"
              name="term"
              value={values?.term ? values?.term : ""}
              onChange={(e) => {
                setFieldValue('term', e.target.value);
                setNewTerm(e.target.value)
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="term" />
            </span>
          </div>
          <div className="form-group three-col-width">
            <label>
              <span className="required">*</span>Chart of Account Name
            </label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Account </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="chart_of_account_id"
              value={
                values?.chart_of_account_id ? values?.chart_of_account_id : ""
              }
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewAccountName(e?.label);
                  } else {
                    setNewAccountName("")
                  }
                  setAddNewAccount(true);
                } else {
                  setFieldValue(`chart_of_account_id`, e ? e : "");
                  setNewAccount(e);
                }
                // handleChange(e);
              }}
              onBlur={handleBlur}
              options={accountOptions}
              placeholder="Select Account Type"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              isLoading={accontLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="chart_of_account_id" />
            </span>
          </div>
          
          <div className="form-group three-col-width">
            <label>
            <span className="required">*</span>Contact Name
            </label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Contact </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="contact_id"
              value={
                values?.contact_id ? values?.contact_id : ""
              }
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewContactLastName(e?.label);
                  } else {
                    setNewContactLastName("")
                  }
                  setAddNewContact(true);
                } else {
                  setFieldValue(`contact_id`, e ? e : '');
                  setNewContact(e);
                }
              }}
              onBlur={handleBlur}
              options={allContactList}
              isClearable={true}
              placeholder="Select Contact Name"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              isLoading={loadingContactList}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="contact_id" />
            </span>
          </div>
          
          <div className="form-group three-col-width">
            <label>
              Job
            </label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Job </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="business_job_id"
              value={
                values?.business_job_id ? values?.business_job_id : ""
              }
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewJobCode(e?.label);
                  } else {
                    setNewJobCode("");
                  }
                  setAddNewJob(true);
                } else {
                  setFieldValue(`business_job_id`, e ? e : '');
                  setNewJob(e);
                }
              }}
              onBlur={handleBlur}
              options={jobOptions}
              isClearable={true}
              placeholder="Select Job"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              isLoading={jobLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
          </div>

          <div className="form-group three-col-width">
            <label>
              Task
            </label>
            <CreatableSelect
              formatCreateLabel={(userInput) => (
                <>
                  <strong>+ Add New Task </strong>'{`${userInput}`}'
                </>
              )}
              // type="select"
              name="business_task_id"
              value={
                values?.business_task_id ? values?.business_task_id : ""
              }
              onChange={(e) => {
                if (e?.value === 'new' || e?.__isNew__ === true) {
                  if(e?.__isNew__ === true){
                    setNewTaskCode(e?.label);
                  } else {
                    setNewTaskCode("");
                  }
                  setAddNewTask(true);
                } else {
                  setFieldValue(`business_task_id`, e ? e : '');
                  setNewTask(e);
                }
              }}
              onBlur={handleBlur}
              options={taskOptions}
              placeholder="Select Task"
              classNamePrefix="selectbox"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              isClearable={true}
              isLoading={taskLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
          </div>
          <div className="form-group full-width">
            <label>Description</label>
            <textarea
              type="text"
              name="description"
              value={values?.description ? values?.description : ""}
              onChange={(e) => {
                setFieldValue('description', e.target.value);
                setNewDescription(e.target.value)
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              style={{
                borderRadius: "10px",
                margin: "0 0 15px",
                minHeight: "70px",
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="description" />
            </span>
          </div>
          {/* <fieldset className="amount-filter-field-set">
            <legend>
             Amount Filter
            </legend>
            <div className="amount-form-box">
              <div className="form-group three-col-width">
                <label>
                  Transaction Type
                </label>
                <Select
                  // type="select"
                  name="transaction_type"
                  value={
                    values?.transaction_type ? values?.transaction_type : ""
                  }
                  onChange={(e) => {
                    setFieldValue(`transaction_type`, e ? e : "");
                    // handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={transactionOptions}
                  placeholder="Select Transaction Type"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  isClearable={true}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="transaction_type" />
                </span>
              </div>
              
              <div className="form-group three-col-width">
                <label>Filter</label>
                <Select
                  // type="select"
                  name="amount_type"
                  value={
                    values?.amount_type ? values?.amount_type : ""
                  }
                  onChange={(e) => {
                    setFieldValue(`amount_type`, e ? e : "");
                    // handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={amountOptions}
                  placeholder="Select Amount Filter"
                  classNamePrefix="selectbox"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "grey",
                    },
                  })}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  isClearable={true}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="amount_type" />
                </span>
              </div>
              <div className="form-group three-col-width">
                <label>Amount</label>
                <input
                  type="text"
                  name="amount"
                  value={values?.amount ? values?.amount : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
                <span
                  className="text-danger"
                  style={{ color: "red", fontSize: "20px" }}
                >
                  <ErrorMessage name="amount" />
                </span>
              </div>
            </div>
          </fieldset> */}
          {/* </div> */}
        </div>
      </div>

      <div className="action-box">
        <Button type="submit">Save</Button>
        <CancelButton />
      </div>

      {addNewAccount && (
        <AddNewAccountModal
          addNewAccount={addNewAccount}
          getAccounts={getAccounts}
          handleAddNewAccount={handleAddNewAccount}
          businessId={businessId}
          setNewAccount={setNewAccount}
          setFieldValue1={setFieldValue}
          newAccountName={newAccountName}
        />
      )}

      {addNewContact && (
        <AddNewContactModal
          addNewContact={addNewContact}
          handleAddNewContact={handleAddNewContact}
          handleContactList={handleContactList}
          businessId={businessId}
          setNewContact={setNewContact}
          setFieldValue1={setFieldValue}
          newContactLastName={newContactLastName}
        />
      )}

      {addNewJob && (
        <AddNewJobModal
          addNewJob={addNewJob}
          handleJobChange={handleJobChange}
          handleAddNewJob={handleAddNewJob}
          businessId={businessId}
          setNewJob={setNewJob}
          setFieldValue={setFieldValue}
          newJobCode={newJobCode}
        />
      )}

      {addNewTask && (
        <AddNewTaskModal
          addNewTask={addNewTask}
          handleTaskChange={handleTaskChange}
          handleAddNewTask={handleAddNewTask}
          businessId={businessId}
          setNewTask={setNewTask}
          setFieldValue={setFieldValue}
          newTaskCode={newTaskCode}
        />
      )}
    </form>
  );
};

export default Form;
