import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import { Button } from "react-bootstrap";
import InputMask from "react-input-mask";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleAccountModal,
  accuntingList,
  accountTpesLoading,
  typeList,
  taxList,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleAccountModal: PropTypes.any,
    accuntingList: PropTypes.any,
    accountTpesLoading: PropTypes.any,
    typeList: PropTypes.any,
    taxList: PropTypes.any,
  };

  const [codeMask , setCodeMask] = useState("");

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleBlurCode = (e, setFieldValue) => {
    let value = e.target.value
    let FinalValue = e.target.value
    if(value.match("/")){
      let tempValue = value.split("/")
      let firstNumber = padWithLeadingZeros(tempValue[0], 2)
      let secondNumber = padWithLeadingZeros(tempValue[1], 4)
      FinalValue = `${firstNumber}/${secondNumber}`
      setCodeMask("99/9999");
    } else if(value.match("-")){
      let tempValue = value.split("-")
      let firstNumber = padWithLeadingZeros(tempValue[0], 2)
      let secondNumber = padWithLeadingZeros(tempValue[1], 4)
      FinalValue = `${firstNumber}-${secondNumber}`
      setCodeMask("99-9999");
    } else {
      if(value){
        FinalValue = padWithLeadingZeros(value, 4)
      } 
    }
    setFieldValue("code", FinalValue)
  }

  const handleSubCodeBlur = (e, setFieldValue) => {
    let value = e.target.value
    let FinalValue = e.target.value
    if(value.length === 1){
      FinalValue = padWithLeadingZeros(value, 2)
    }
    setFieldValue("sub_code", FinalValue)
  }

  const handleCodeChange = (e, setFieldValue) => {
    let value = e.target.value;
    let FinalValue = e.target.value;
    if(value !== null && value !== ""){
      if(value.length > 4 || value.match("/") || value.match("-")){
        if(value.match("/")){
          if(value.length === 7 && value.match("/").index === 2){
            setCodeMask("99/9999");
            FinalValue = e.target.value
          }
          if(value.length > 5 && value.length < 7 && value.match("/").index === 1){
            let tempValue = value.split("/")
            let firstNumber = padWithLeadingZeros(tempValue[0], 2)
            let secondNumber = padWithLeadingZeros(tempValue[1], 4)
            FinalValue = `${firstNumber}/${secondNumber}`
            setCodeMask("99/9999");
          }
        }else if(value.match("-")) {
          if(value.length === 7 && value.match("-").index === 2){
            setCodeMask("99-9999");
            FinalValue = e.target.value
          }
          if(value.length > 5 && value.length < 7 && value.match("-").index === 1){
            let tempValue = value.split("-")
            let firstNumber = padWithLeadingZeros(tempValue[0], 2)
            let secondNumber = padWithLeadingZeros(tempValue[1], 4)
            FinalValue = `${firstNumber}-${secondNumber}`
            setCodeMask("99-9999");
          }
        } else {
          FinalValue = String(value).substring(0, 4)
        }
      } else if(value.length === 4 && !value.match("/") && !value.match("-")){
        FinalValue = e.target.value
      } else {
        setCodeMask("");
        FinalValue = e.target.value
      }
    } else {
      FinalValue = e.target.value
      setCodeMask("")
    }
    setFieldValue("code", FinalValue)
  }

  const handleCodePaste = (e, setFieldValue) => {
    setCodeMask("");
    setFieldValue("code", "")
  }

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Account Type
          </label>
          <Select
            // type="select"
            name="account_type_id"
            value={values?.account_type_id ? values?.account_type_id : ""}
            onChange={(e) => {
              setFieldValue(`account_type_id`, e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={accuntingList}
            placeholder="Select Account Type"
            classNamePrefix="selectbox"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "grey",
              },
            })}
            isLoading={accountTpesLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="account_type_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Type
          </label>
          <Select
            name="type"
            // type="select"
            value={values?.type ? values?.type : ""}
            onChange={(e) => {
              setFieldValue(`type`, e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={typeList}
            placeholder="Select Type"
            classNamePrefix="selectbox"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "grey",
              },
            })}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="type" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Tax
          </label>

          <Select
            name="tax"
            // type="select"
            value={values?.tax ? values?.tax : ""}
            onChange={(e) => {
              setFieldValue(`tax`, e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={taxList}
            placeholder="Select Tax"
            classNamePrefix="selectbox"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "grey",
              },
            })}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="tax" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Account Name
          </label>
          <input
            type="text"
            name="name"
            value={values?.name ? values?.name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="name" />
          </span>
        </div>

        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Code
          </label>
          <InputMask
            type="text"
            name="code"
            value={values?.code ? values?.code : ""}
            onChange={(e) => handleCodeChange(e, setFieldValue)}
            onBlur={(e) => handleBlurCode(e, setFieldValue)}
            mask={codeMask}
            maskChar={null}
            onPaste={(e) => handleCodePaste(e, setFieldValue)}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="code" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Sub Code</label>
          <InputMask
            type="text"
            name="sub_code"
            value={values?.sub_code ? values?.sub_code : ""}
            onChange={handleChange}
            onBlur={(e) => handleSubCodeBlur(e, setFieldValue)}
            mask="99"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="sub_code" />
          </span>
        </div>
        <div className="form-group full-width">
          <label>Description of Account</label>
          <textarea
            type="text"
            name="description"
            value={values?.description ? values?.description : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
            style={{
              borderRadius: "10px",
              margin: "0 0 15px",
              minHeight: "70px",
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="description" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleAccountModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
