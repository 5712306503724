import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { API, get } from '../../config';
import Pagination from '../common/Pagination';
import Spin from '../common/Spin';
import moment from 'moment';
import ExpandRegisteredUserActionButton from './ExpandRegisteredUserActionButton';
import { formatNumber } from '../common/Misc';

const ExpandRegisteredUser = ({ id }) => {
  const [expandRegisteredUserLoading, setExpandRegisteredUserLoading] = useState(false);
  const [expandRegisteredUserData, setExpandRegisteredUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const fetchRegisteredUserExpandData = useCallback(async () => {
    try {
      setExpandRegisteredUserLoading(true);
      const { data } = await get(
        `${API.REGISTERED_USER_SUBSCRIPTIONS}/${id}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }`
      );
      setExpandRegisteredUserLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setExpandRegisteredUserData(data?.data?.data);
    } catch (e) {
      const { data } = e;
      setExpandRegisteredUserLoading(false);
      setExpandRegisteredUserData([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    //eslint-disable-next-line
  }, [id, limit, page]);

  const RegisteredUserDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: expandRegisteredUserData[i]?.id,
        id: expandRegisteredUserData[i]?.id,
        package: expandRegisteredUserData[i]?.product_name
          ? expandRegisteredUserData[i]?.product_name
          : '',
        price: expandRegisteredUserData[i]?.price
          ? `$${formatNumber(Number(expandRegisteredUserData[i]?.price))}`
          : '$0.00',
        start_date: expandRegisteredUserData[i]?.subscription_start_date
          ? moment(expandRegisteredUserData[i]?.subscription_start_date).format('DD-MM-YYYY')
          : '-',
        end_date: expandRegisteredUserData[i]?.subscription_end_date
          ? moment(expandRegisteredUserData[i]?.subscription_end_date).format('DD-MM-YYYY')
          : '-',
        action: (
          <ExpandRegisteredUserActionButton
            id={expandRegisteredUserData[i]?.id}
            fetchRegisteredUserExpandData={fetchRegisteredUserExpandData}
          />
        )
      });
    }
    return items;
  };
  const RegisteredUserData = RegisteredUserDataGenerator(expandRegisteredUserData?.length);

  const columns = [
    {
      dataField: 'package',
      text: 'Package',
      style: { width: '15%' }
    },
    {
      dataField: 'price',
      text: 'Price',
      style: { width: '15%' }
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      style: { width: '15%' }
    },
    {
      dataField: 'end_date',
      text: 'End Date',
      style: { width: '10%' }
    },
    {
      dataField: 'action',
      text: 'Action ',
      style: { width: '5%', textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    }
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchRegisteredUserExpandData();
  }, [fetchRegisteredUserExpandData]);

  return (
    <>
      <div className="content-details">
        <div className="custom-table trial-balance-expand-table">
          {expandRegisteredUserLoading && <Spin />}
          <BootstrapTable
            keyField="key"
            data={RegisteredUserData}
            columns={columns}
            noDataIndication="No Data Found"
          />
          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            s
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default ExpandRegisteredUser;
