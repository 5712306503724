import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import { API, fileUpload, get, metaTitle } from "../../../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// import { debounce } from "lodash";
// import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import { formatName, formatNumber } from "../../common/Misc";
import { debounce } from "lodash";
import { MultiSelect } from "primereact/multiselect";
import TitleBar from "../../common/TitleBar";

// const defaultDateRange = [
//   new Date(
//     new Date().getFullYear(),
//     new Date().getMonth() - 6,
//     new Date().getDate()
//   ),
//   new Date(),
// ];

const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

const TransactionReport = () => {
  const navigate = useNavigate();
  const params = useParams();

  // const { afterToday } = DateRangePicker;

   // For the financial year
  // Oct-Dec = 1
  // Jan-Mar = 2
  // Apr-Jun = 3
  // Jul-Sep = 4
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth()/3) + 2;
    return m > 4? m - 4 : m;
  }
  let date = new Date();
  let quarter = getQuarter(date);
  let defaultMonthRange = [];
  if(quarter === 1){
    defaultMonthRange = [new Date(date.getFullYear(), 9, 1), new Date(date.getFullYear(), 12, 0)]
  } else if(quarter === 2){
    defaultMonthRange = [new Date(date.getFullYear(), 0, 1), new Date(date.getFullYear(), 3, 0)]
  } else if(quarter === 3){
    defaultMonthRange = [new Date(date.getFullYear(), 3, 1), new Date(date.getFullYear(), 6, 0)]
  } else {
    defaultMonthRange = [new Date(date.getFullYear(), 6, 1), new Date(date.getFullYear(), 9, 0)]
  }

  const businessId = params?.business_id;
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const search = query.get("search");
  // const [searchParam, setSearchParam] = useState(search || "");
  const [transactionReportData, setTransactionReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  // const [sortField, setSortField] = useState("name");
  // const [sortOrder, setSortOrder] = useState("ASC");
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const [total, setTotal] = useState();
  // const [from, setFrom] = useState();
  // const [to, setTo] = useState();
  const [dateValue, setDateValue] = useState(defaultMonthRange);
  const [startDate, setStartDate] = useState(
    defaultMonthRange ? moment(defaultMonthRange[0]).format("DD-MM-YYYY") : defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultMonthRange ? moment(defaultMonthRange[1]).format("DD-MM-YYYY") : defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [selectedContacts, setSelectedContacts] = useState(null);
  const [selectedMainContacts, setSelectedMainContacts] = useState([]);
  const [contactListLoading, setContactListLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);
  // const [allConatctValue, setAllContactValue] = useState([]);

  const filterContactInputRef = useRef();

  let currentDate = new Date();
  let quarterDate = getQuarter(currentDate);
  let quarterRange = [];
  if(quarterDate === 1){
    quarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarterDate === 2){
    quarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else if(quarterDate === 3){
    quarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  } else {
    quarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  }

  let perviousQuarterRange = [];
  if(quarterDate === 1){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear(), 9, 0)]
  } else if(quarterDate === 2){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 9, 1), new Date(currentDate.getFullYear(), 12, 0)]
  } else if(quarterDate === 3){
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 3, 0)]
  } else {
    perviousQuarterRange = [new Date(currentDate.getFullYear(), 3, 1), new Date(currentDate.getFullYear(), 6, 0)]
  }

  const dateFilterRange = [
    {
      label: 'This Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Quarter',
      value: quarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'This Financial Year',
      value: [new Date(currentDate.getFullYear(), 6, 1), new Date(currentDate.getFullYear() + 1, 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Month',
      value: [new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Quarter',
      value: perviousQuarterRange,
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
    {
      label: 'Last Financial Year',
      value: [new Date(currentDate.getFullYear() -1, 6, 1), new Date(currentDate.getFullYear(), 6, 0)],
      placement: 'bottom',
      appearance: 'default',
      closeOverlay: false
    },
  ]

  const fetchTransactionItem = useCallback(async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("client_business_id", businessId);
      formData.append("start_date", startDate ? startDate : "");
      formData.append("end_date", endDate ? endDate : "");
      formData.append("search", searchParam ? searchParam : "");

      if(selectedMainContacts?.length > 0){
        for(let i=0; i < selectedMainContacts?.length; i++){
          formData.append(`contact_ids[${i}]`, selectedMainContacts[i])
        }
      }
      

      const { data } = await fileUpload(`${API.ACCOUNT_TRANSACTION_REPORT}`, formData);

      if(data?.status === 200){
        // setLimit(data?.data?.per_page);
        // setPage(data?.data?.current_page);
        // setTotal(data?.data?.total);
        // setFrom(data?.data?.from);
        // setTo(data?.data?.to);
        setTransactionReportData(data?.data);
      }
  
      setLoading(false);
    } catch (error) {
      const { data } = error.response;
      setLoading(false);
      setTransactionReportData([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [
    businessId,
    endDate,
    startDate,
    searchParam,
    selectedMainContacts
  ]);

  useEffect(() => {
    navigate(
      `/business/${businessId}/account-transactions-report?start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}&search=${searchParam ? searchParam : ""}`
    );
  }, [
    navigate,
    businessId,
    startDate,
    endDate,
    searchParam
  ]);

  const transactionReportGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      if(transactionReportData[i]?.reports.length !== 0){
        items.push({
          key: transactionReportData[i]?.id ? transactionReportData[i]?.id : "",
          date: (<div className="transaction-report-heading">
            {transactionReportData[i]?.name
          ? transactionReportData[i]?.name
          : ""}
          </div>),
          source: (<div className="report_blank_data"></div>),
          description: (<div className="report_blank_data"></div>),
          contact: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data"></div>),
          credit: (<div className="report_blank_data"></div>),
          amount: (<div className="report_blank_data"></div>),
          gst_amount: (<div className="report_blank_data"></div>),
          net_amount: (<div className="report_blank_data"></div>),
        })
      }
      for ( let j = 0; j < transactionReportData[i]?.reports.length; j++) {
      items.push({
        key: transactionReportData[i]?.id ? transactionReportData[i]?.id : "",
        date: transactionReportData[i]?.reports[j]?.date
          ? moment(
            transactionReportData[i]?.reports[j]?.date,
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY")
          : "-",
        name: transactionReportData[i]?.name
        ? transactionReportData[i]?.name
        : "",
        
        source: transactionReportData[i]?.reports[j]?.source
          ? transactionReportData[i]?.reports[j]?.source
          : "",

        description: transactionReportData[i]?.reports[j]?.description
          ? transactionReportData[i]?.reports[j]?.description
          : "",

        contact: transactionReportData[i]?.reports[j]?.contact
          ? transactionReportData[i]?.reports[j]?.contact
          : "",
        
        debit: (transactionReportData[i]?.reports[j]?.transaction_type === 0
          ? <div>{`$${formatNumber(transactionReportData[i]?.reports[j]?.debit_amount)}`}</div>
          : "-"),
        
        credit: (transactionReportData[i]?.reports[j]?.transaction_type === 1
          ? <div>{`$${formatNumber(transactionReportData[i]?.reports[j]?.credit_amount)}`}</div>
          : "-"),
        
        // amount: transactionReportData[i]?.reports[j]?.amount
        //   ? transactionReportData[i]?.reports[j]?.amount
        //   : "",

        gst_amount: transactionReportData[i]?.reports[j]?.gst_amount
          ? `$${formatNumber(transactionReportData[i]?.reports[j]?.gst_amount)}`
          : "-",
        
        net_amount: transactionReportData[i]?.reports[j]?.net_amount
          ? `$${formatNumber(transactionReportData[i]?.reports[j]?.net_amount)}`
          : "-",
        
      });
      }
      if(transactionReportData[i]?.reports.length !== 0){
        items.push({
          key: transactionReportData[i]?.id ? `${transactionReportData[i]?.id}-total` : "",
          date: (<div className="transaction-report-heading transaction-report-heading-total transaction-report-total-body">
            <div> <span className="">Total</span> <span>{transactionReportData[i]?.name
          ? transactionReportData[i]?.name
          : ""}</span> </div>
          </div>),
          net_amount: (<div className="report_blank_data report-total">{transactionReportData[i]?.net_total ? `$${formatNumber(transactionReportData[i]?.net_total)}`: ''}</div>),
          description: (<div className="report_blank_data"></div>),
          contact: (<div className="report_blank_data"></div>),
          source: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data report-total">{transactionReportData[i]?.debit_total ? `$${formatNumber(transactionReportData[i]?.debit_total)}` : ''}</div>),
          credit: (<div className="report_blank_data report-total">{transactionReportData[i]?.credit_total ? `$${formatNumber(transactionReportData[i]?.credit_total)}` : ''}</div>),
          gst_amount: (<div className="report_blank_data report-total">{transactionReportData[i]?.gst_total ? `$${formatNumber(transactionReportData[i]?.gst_total)}` : ''}</div>),
        }) 
      }
      if(transactionReportData[i]?.reports.length !== 0 && i < (transactionReportData?.length - 1)){
        items.push({
          key: transactionReportData[i]?.id ? `${transactionReportData[i]?.id}-blank-row` : "",
          date: (<div className="report_blank_data"></div>),
          source: (<div className="report_blank_data"></div>),
          description: (<div className="report_blank_data"></div>),
          contact: (<div className="report_blank_data"></div>),
          debit: (<div className="report_blank_data"></div>),
          credit: (<div className="report_blank_data"></div>),
          gst_amount: (<div className="report_blank_data"></div>),
          net_amount: (<div className="report_blank_data"></div>),
          // gst_rate: (<div className="report_blank_data"></div>),
          // gst_rate_name: (<div className="report_blank_data"></div>),
        }) 
      }
    }
    return items;
  };

  const transactionReports = transactionReportGenerator(
    transactionReportData?.length
  );

  useEffect(() => {
    fetchTransactionItem();
    window.document.title = `${metaTitle} | Transactions Report`
  }, [fetchTransactionItem]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.date?.props?.className === "transaction-report-heading transaction-report-heading-total transaction-report-total-body" ? {"colSpan" : "4"} : 
        row?.date?.props?.className === "transaction-report-heading" ? {"colSpan" : "8"} : row?.date?.props?.className === "report_blank_data" ? {"colSpan" : "8"} : ""
      ),
      style: { width: "8%" },
      // sort: true,
    },
    // {
    //   dataField: "name",
    //   text: "Name",
    //   // sort: true,
    // },
    {
      dataField: "source",
      text: "Source",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.source?.props?.className === "report_blank_data" && {hidden : true}
      ),
      style: { width: "10%" }
      // sort: true,
    },
    {
      dataField: "contact",
      text: "Contact",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.contact?.props?.className === "report_blank_data" && {hidden : true}
      ),
      style: { width: "14%" }
      // sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.description?.props?.className === "report_blank_data" && {hidden : true}
      ),
      style: { width: "20%" }
      // sort: true,
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.debit?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerAlign: (column, colIndex) => "right",
      style: { width: "12%" }
      // sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.credit?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerAlign: (column, colIndex) => "right",
      style: { width: "12%" }
      // sort: true,
    },
    // {
    //   dataField: "amount",
    //   text: "Amount",
    //   // sort: true,
    // },
    {
      dataField: "gst_amount",
      text: "GST",
      align: "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.gst_amount?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerAlign: (column, colIndex) => "right",
      style: { width: "12%" }
      // sort: true,
    },
    {
      dataField: "net_amount",
      text: "NET Amount",
      align: "right",
      attrs: (cell, row, col, rowIndex, colIndex) => (
        row?.net_amount?.props?.className === "report_blank_data" && {hidden : true}
      ),
      headerAlign: (column, colIndex) => "right",
      style: { width: "12%" }
      // sort: true,
    }
  ];

  // const onSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchParam(value);
  //   setPage(1);
  // };

  const handleDateRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  // const handleUpdateLimit = (e) => {
  //   const limit = e.target.value;
  //   setLimit(limit);
  //   setPage(1);
  // };

  // const handleChangePage = (page) => {
  //   setPage(page);
  // };

  // const handleTableChange = (type, { page, sortField, sortOrder }) => {
  //   if (type === "sort") {
  //     setPage(1);
  //     setSortField(sortField);
  //     setSortOrder(
  //       sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
  //     );
  //   }
  // };

  const handleContactList = useCallback(async () => {
    try {
      setContactListLoading(true);
      get(
        `${API.GET_CONTACTS}?client_business_id=${businessId}&limit=-1&sort_column=contact_type&sort_order=ASC`
      ).then((response) => {
        var customer =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 1 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const customerList =
          customer && customer?.length > 0
            ? customer?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let supplier =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 2 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const supplierList =
          supplier && supplier?.length > 0
            ? supplier?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        let contractor =
          response?.data?.data?.data?.length > 0
            ? response?.data?.data?.data.filter((element) => {
                return (
                  parseInt(element?.contact_type) === 3 && {
                    id: element.id,
                    name: formatName(element?.first_name, element?.last_name),
                  }
                );
              })
            : [];

        const contractorList =
          contractor && contractor?.length > 0
            ? contractor?.map((item) => {
                const list = {
                  label: formatName(item?.first_name, item?.last_name),
                  value: item?.id,
                };
                return list ? list : [];
              })
            : [];

        const allContactListing = [
          contractorList && { label: "Contractors", items: contractorList },
          supplierList && { label: "Suppliers", items: supplierList },
          customerList && { label: "Customers", items: customerList },
          // { label: "Unassigned", value: "" },
        ];


        // setAllContactValue([
        //   ...contractorList,
        //   ...supplierList,
        //   ...customerList,
        //   {label: 'Unassigned' , value: ''}
        // ]);

        setContactOptions(allContactListing ? allContactListing : [{label: 'Unassigned' , value: ''}]);
        setContactListLoading(false);
      });
    } catch (e) {
      setContactListLoading(false);
      toast.error(e?.data?.message);
    }
  }, [businessId]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const handleContactChange = (e) => {
    setSelectedContacts(e.value)
  }

  const handleExport = async (format) => {
    try {
      setExportLoading(true);

      const formData = new FormData();
      formData.append("client_business_id", businessId);
      formData.append("start_date", startDate ? startDate : "");
      formData.append("end_date", endDate ? endDate : "");
      formData.append("search", searchParam ? searchParam : "");
      formData.append("export", 1);
      formData.append("format", format);

      if(selectedMainContacts?.length > 0){
        for(let i=0; i < selectedMainContacts?.length; i++){
          formData.append(`contact_ids[${i}]`, selectedMainContacts[i])
        }
      }


      const { data } = await fileUpload(`${API.ACCOUNT_TRANSACTION_REPORT}`, formData);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "account_transactions_report.xlsx"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(nextValue => {
          setSearchParam(nextValue)
        }, 300),
    [], 
  );

  const handleSearchChange = event => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if(nextValue?.length >= 2 || nextValue?.length === 0){
      debouncedSave(nextValue);
    }
  };

  const groupedItemTemplate = (option) => {
    return (
        <div style={{padding: "0.75rem 1.25rem"}}>
          <strong>{option.label}</strong>
        </div>
    );
  };

  const handleApplyFilter = () => {
    setSelectedMainContacts(selectedContacts);
    filterContactInputRef.current.hide();
  }
  
  const myContactResetFunction = () => {
    setSelectedContacts([]);
    setSelectedMainContacts([]);
    filterContactInputRef && filterContactInputRef.current.focus();
  }

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <TitleBar 
            label={"Account Transactions Report"}
          />
          <div className="content-details">
            <div className="table-top-btn report-header-row">
              <div className="report-name report-period-name">
                <span> Transactions Report 
                {startDate && endDate && (` For the period from ${startDate} to ${endDate}`)}
                </span>
              </div>
            </div>
            <div className="table-top-btn report-header-row">
              
              <div className="table-top-btn transaction-report-multi" style={{marginBottom: "0px"}}>
                <div className="search-section" style={{marginRight: "20px"}}>
                  <Form>
                    <FormControl
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Transaction"
                    />
                    {searchParamData 
                    ? <Button className="btn-close" onClick={() => {
                      setSearchParamData("")
                      setSearchParam("")
                    }}
                    >x</Button> 
                    : <Button className="fa fa-search">Search</Button>}
                  </Form>
                </div>
                <div className="multiSelect-body-pl-report">
                    <div className="multiSelect-pl-report">
                      <label style={{marginRight: "10px"}}> Filter By Contact: </label>
                      <MultiSelect value={selectedContacts} onChange={(e) => {handleContactChange(e)}} options={contactOptions} optionLabel="label" 
                      filter placeholder="Select Contact" maxSelectedLabels={0} optionGroupTemplate={groupedItemTemplate}
                      optionGroupLabel="label" optionGroupChildren="items"
                      selectedItemsLabel={`${selectedContacts?.length} Contact(s) Selected`}
                      onHide={() => {
                        setSelectedMainContacts(selectedContacts)
                      }}
                      style={{
                        fontSize: "13px"
                      }}
                      ref={filterContactInputRef}
                      panelFooterTemplate={
                        <>
                        <hr />
                        <Button
                        className="multiSelect-button"
                        onClick={() => {
                          handleApplyFilter();
                        }}
                        >
                          Apply
                        </Button>
                        <Button
                        className="multiSelect-button"
                        onClick={() => {
                          myContactResetFunction()
                        }}
                        >
                          Reset
                        </Button>
                        </>
                      }
                      />
                    </div>
                </div>
              </div>
              <div className="table-btn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportLoading ? "Loading…" : "Export Transactions"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={() => handleExportReport("csv")}>
                      CSV
                    </Dropdown.Item> */}
                    <Dropdown.Item onClick={() => handleExport("pdf")}>
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleExport("xlsx")}>
                      Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="summary-filter"
                style={{
                  padding: "0",
                  marginTop: "0px",
                  marginLeft: "20px"
                }}
              >
                <Form>
                  <DateRangePicker
                    cleanable={false}
                    placeholder="Select Date Range"
                    onChange={(e) => handleDateRange(e)}
                    format="dd-MM-yyyy"
                    value={dateValue}
                    placement="bottomEnd"
                    ranges={dateFilterRange}
                    locale={{ok: "Apply"}}
                    // shouldDisableDate={afterToday()}
                    // showOneCalendar
                  />
                </Form>
              </div>
            </div>
            
            <div className="custom-table">
              {(loading || contactListLoading) && <Spin />}
              <BootstrapTable
                keyField="contact_id"
                remote
                data={transactionReports}
                columns={columns}
                // selectRow={selectRow}
                // onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              {/* <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionReport;
