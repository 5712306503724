import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Spin from "../common/Spin";
import { useStateList, useSuburbListE } from "./components/OfficeHelper";
import { API, fileUpload } from "../../config";
import { AddOfficeSchema } from "../common/Validation";
import { formatName } from "../common/Misc";
import { useBusinessList, useUserList } from "../Users/components/UserHelper";
import { useParams } from "react-router-dom";

const AddOffice = ({ toggleOfficeModal, showOffice, clientId, businessSettings = false, clientAdd = false }) => {
  const [loading, setLoading] = useState(false);
  const [officeData, setOfficeData] = useState();
  const params = useParams();

  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const clientId = localStorage.getItem("sme-clientId");

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.value;

    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append("client_id", clientId ? clientId : "");

    if(selectedBusinesses){
      if(selectedBusinesses.length > 0){
        for(let i = 0; i < selectedBusinesses.length; i++){
          formData.append(`business_ids[${i}]`, selectedBusinesses[i]);
        }
      }
    }

    if(selectedUsers){
      if(selectedUsers.length > 0){
        for(let i = 0; i < selectedUsers.length; i++){
          formData.append(`user_ids[${i}]`, selectedUsers[i]);
        }
      }
    }

    if(businessSettings && clientAdd === false) {
      const businessId = params?.business_id;
      formData.append(`business_ids[${0}]`, businessId);
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.OFFICE}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleOfficeModal();
        setSelectedBusinesses([]);
        setSelectedUsers([]);
      }
      return data?.data;
    } catch (error) {
      setOfficeData(value);
      setLoading(false);
      setSelectedBusinesses([]);
      setSelectedUsers([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === officeData?.state_id
  );

  // Suburb
  const { suburbLoading, suburbList = [] } = useSuburbListE(
    officeData?.state_id
  );
  const suburbListing = suburbList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const suburb = suburbListing?.find(
    (item) => item?.value === officeData?.suburb_id
  );

  //Business List
  const { businessLoading, businessList = [] } = useBusinessList();
  const businessListing = businessList?.map((item) => {
    const name = item?.business_name;
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

    //User List
    const { userLoading, userList = [] } = useUserList(clientId ? clientId  : '');
    const userListing = userList?.map((item) => {
      const name = formatName(item?.first_name, item?.last_name);
      const id = item?.client_user?.id;
      const items = { name, id };
      const { name: label, id: value, ...rest } = items;
      return { label, value, ...rest };
    });

  return (
    <>
      <Modal
        size="lg"
        show={showOffice}
        onHide={toggleOfficeModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Office
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              street: officeData?.street,
              postal_code: officeData?.postal_code,
              state_id: state,
              suburb_id: suburb,
              business_ids: [],
              user_ids: []
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddOfficeSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading || userLoading || businessLoading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleOfficeModal={toggleOfficeModal}
                    stateListing={stateListing}
                    stateLoading={stateLoading}
                    erSuburbLoading={suburbLoading}
                    businessLoading={businessLoading}
                    businessListing={businessListing}
                    selectedBusinesses={selectedBusinesses}
                    setSelectedBusinesses={setSelectedBusinesses}
                    userListing={userListing}
                    userLoading={userLoading}
                    setSelectedUsers={setSelectedUsers}
                    selectedUsers={selectedUsers}
                    clientId={clientId}
                    businessSettings={businessSettings}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOffice;
