import moment from 'moment';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

const ExpandReconcileItem = ({ data }) => {
  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }
  const reconcileItemExpandGenerator = (data) => {
    const items = [];
      for (let j = 0; j < data.bank_reconciliation.length; j++) {
        items.push({
          key: data?.bank_reconciliation[j].id,
          date: data?.transactionDate
            ? moment(data?.transactionDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
            : '-',
          description: data?.bank_reconciliation[j]?.description
            ? data?.bank_reconciliation[j]?.description
            : '',
          credit:
            data?.baseType === 1 ? (
              <span className="rag-green">
                $
                {data?.bank_reconciliation[j]?.amount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </span>
            ) : (
              '-'
            ),
          debit:
            data?.baseType === 0 ? (
              <span className="rag-red">
                $
                {data?.bank_reconciliation[j]?.amount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </span>
            ) : (
              '-'
            ),
          balance: data?.balance ? data?.balance : '-',
          contact: data?.bank_reconciliation[j]?.contact ? (data?.bank_reconciliation[j]?.contact.first_name ? data?.bank_reconciliation[j]?.contact?.first_name + " " + data?.bank_reconciliation[j]?.contact?.last_name : data?.bank_reconciliation[j]?.contact?.last_name) : '-',
          bank_transaction_source: data?.bank_transaction_source ? data?.bank_transaction_source : '-',
          chart_of_account: data?.bank_reconciliation[j]?.chart_of_account?.name
            ? `${data?.bank_reconciliation[j]?.chart_of_account?.code?.length < 4 ? padWithLeadingZeros(Number(data?.bank_reconciliation[j]?.chart_of_account?.code), 4) : data?.bank_reconciliation[j]?.chart_of_account?.code} ${data?.bank_reconciliation[j]?.chart_of_account?.sub_code ? `.${data?.bank_reconciliation[j]?.chart_of_account?.sub_code}` : ""} - ` + data?.bank_reconciliation[j]?.chart_of_account?.name
            : '-',
        });
      }
    return items;
  };
  const reconcileExpandItem = reconcileItemExpandGenerator(data);
  const expandColumns = [
    {
      dataField: 'date',
      text: 'Date',
    },
    {
      dataField: 'description',
      text: 'Description'
      // sort: true,
    },
    {
      dataField: 'credit',
      text: 'Credit'
      // sort: true,
    },
    {
      dataField: 'debit',
      text: 'Debit'
      // sort: true,
    },
    {
      dataField: 'contact',
      text: 'Contact Name'
      // sort: true,
    },
    {
      dataField: 'bank_transaction_source',
      text: 'Bank Transaction Source'
      // sort: true,
    },
    {
      dataField: 'chart_of_account',
      text: 'Chart of Account'
      // sort: true,
    }
  ];
  return (
    <>
      <div className="content-details">
        <div className="custom-table trial-balance-expand-table">
          <BootstrapTable
            keyField="key"
            remote
            data={reconcileExpandItem}
            columns={expandColumns}
            noDataIndication="No Data Found"
          />
        </div>
      </div>
    </>
  );
};

export default ExpandReconcileItem;
