import qs from "qs";
import instance, { defaultBaseUrl } from "./APIConfig/Interceptor";

export const API = {
  // login and authentication
  LOGIN: "/login",
  LOGOUT: "/logout",
  REFRESH: "/refresh",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  GOOGLE_2FA_REGISTER: '/mfa/google-2fa-register',
  GOOGLE_2FA_VERIFY: '/mfa/google-2fa-verify',
  VERIFY_EMAIL_AUTHENTICATION: '/mfa/verify-email-authentication',
  EMAIL_AUTHENTICATION: '/mfa/email-authentication',
  VERIFY_BACKUP_EMAIL: '/mfa/verify-backup-email',
  ADD_BACKUP_EMAIL: '/mfa/add-backup-email',
  DELETE_2FA: '/mfa/disable',
  

  UPDATE_PROFILE: "/profile",

  //Register
  FIRM_SIGNUP: "/register",
  EMAIL_VERIFICATION: "/email-verification",

  //Subsciption
  SUBSCRIPTION: "/stripe/products",
  FIRM_SUBSCRIPTION: "/stripe/subscription",
  GET_SUBSCRIPTION: '/stripe/subscription-info',
  CANCEL_SUBSCRIPTION: 'stripe/cancel-subscription',
  UPDATE_SUBSCRIPTION: 'stripe/subscription-update',
  GET_PAYMENTMETHOD_LIST: 'stripe/payment-methods',

  STRIPE_PUBLISHABLE_KEY:
    "pk_live_51LlSdvIaTKI7WMHY6PAtxefigZrb6BxVRXhoqkIdwbwolNch0A48CMDMFOk2YZ5kIF2k9jYyNxtJOm2YfgftwLfA00oFy7cVQH",

  //User Settings
  GET_USER_PROFILE: "/profile",

  // Clients
  GET_CLIENTS: "/clients",
  CLIENT_DETAILS: "/clients",
  CLIENT_ADD: "/clients",
  CLIENT_DELETE: "/clients",
  DOWNLOAD_CLIENT_TEMPLATE: "/clients/download-sample",
  IMPORT_CLIENT: "/clients/import",
  INVITE_CLIENT: "/clients/invite",
  REVOKE_ACCESS_CLIENT: "/clients/suspend-access",

  // Clients User
  GET_CLIENT_USER: "/client-users", 
  ADD_CLIENT_USER: "/client-users",
  EDIT_CLIENT_USER: "/client-users", 
  DELETE_CLIENT_USER: "/client-users",

  // Business
  GET_BUSINESS: "/businesses",
  BUSINESS_DELETE: "/businesses",
  BUSINESS_DETAILS: "/businesses",
  BUSINESS_ADD: "/businesses",
  BUSINESS_HEAD_CLIENT: "/businesses/change-head-client",
  DOWNLOAD_BUSINESS_TEMPLATE: "/businesses/download-sample",
  IMPORT_BUSINESS: "/businesses/import",
  SUBSCRIBE_BUSINESS_LIST: "/businesses/all",
  ACTIVE_BUSINESSES: "/businesses/subscribe",

  // Customer
  GET_CUSTOMERS: "/customers",
  CUSTOMER_DELETE: "/customers",
  CUSTOMER_DETAILS: "/customers",
  CUSTOMER_Add: "/customers",

  // Contractor
  GET_CONTRACTORS: "/contractors",
  CONTRACTOR_DELETE: "/contractors",
  CONTRACTOR_DETAILS: "/contractors",
  CONTRACTOR_Add: "/contractors",

  // Supplier
  GET_SUPPLIERS: "/suppliers",
  SUPPLIER_DELETE: "/suppliers",
  SUPPLIER_DETAILS: "/suppliers",
  SUPPLIER_Add: "/suppliers",

  // Jobs
  GET_JOBS: "/jobs",
  JOBS_DELETE: "/jobs",
  JOBS_DETAILS: "/jobs",
  JOBS_ADD: "/jobs",

  // Tasks
  GET_TASKS: "/tasks",
  TASKS_DELETE: "/tasks",
  TASKS_DETAILS: "/tasks",
  TASKS_ADD: "/tasks",

  // Contacts
  GET_CONTACTS: "/contacts",
  CONTACT_DELETE: "/contacts",
  CONTACT_DETAILS: "/contacts",
  CONTACT_Add: "/contacts",
  DOWNLOAD_CONTACTS_TEMPLATE: "/contacts/download-sample",
  IMPORT_CONTACT: "/contacts/import",
  GET_INVOICE_CONTACTS: "invoice-contacts",

  //Accounting
  GET_ACCOUNT_TYPES: "/account-types",
  GET_ACCOUNT_DATA: "/chart-of-accounts",
  ACCOUNT_DELETE: "/chart-of-accounts",
  ACCOUNT_DETAILS: "/chart-of-accounts",
  ACCOUNT_ADD: "/chart-of-accounts",
  IMPORT_ACCOUNT: "/chart-of-accounts/import",
  DOWNLOAD_ACCOUNT_TEMPLATE: "/chart-of-accounts/get-template",
  ACCOUNT_RESTORE: "/chart-of-accounts/restore",
  ACCOUNT_DELETE_ALL: "/chart-of-accounts/delete-all",

  // Cash Payments
  GET_CASH_PAYMENT: "/cash-payments",
  CASH_PAYMENT_DELETE: "/cash-payments",
  CASH_PAYMENT_DETAILS: "/cash-payments",
  CASH_PAYMENT_ADD: "/cash-payments",

  // Cash Receipts
  GET_CASH_RECEIPT: "/cash-receipts",
  CASH_RECEIPT_DELETE: "/cash-receipts",
  CASH_RECEIPT_DETAILS: "/cash-receipts",
  CASH_RECEIPT_ADD: "/cash-receipts",

  //Journal Entry
  JOURNAL_ENTRIES: "/journal-entries",
  DOWNLOAD_JOURNAL_ENTRIES: "/journal-entries/download-sample",
  IMPORT_JOURNAL_ENTRIES: "/journal-entries/import",
  
  // Common
  GET_STATE_LIST: "/states",
  GET_SUBURB_LIST: "/suburbs",
  GET_OFFICE_LIST: "/offices",
  GET_STAFF_MANAGER_LIST: "/users",

  //Basiq integration
  BASIQ_GET_USER: "/basiq/get-user",
  BASIQ_CREATE_USER: "basiq/create-user",
  BASIQ_DISCONNECT: "basiq/disconnect",

  //Yodlee integration
  YODLEE_GET_TOKEN: "/yodlee/get-token",
  SAVE_ACCOUNTS: "/yodlee/accounts",
  SAVE_PROVIDERS_ACCOUNTS: "/yodlee/providers",
  REQUEST_CLIENT_TO_CONNECT: "/yodlee/request-client-to-connect",

  //Providers(Bank)
  GET_PROVIDERS_BANK: "/yodlee/providers",
  PROVIDER_BANK_DISCONNECT: "/yodlee/providers/disconnect",

  //Search terms
  GET_TERMS: "/terms",
  TERM_DELETE: "/terms",
  ADD_TERM: "/terms",
  EDIT_TERM: "/terms",

  // Invoices
  GET_INVOICES: "/invoices",
  INVOICES_DELETE: "/invoices",
  INVOICES_DETAILS: "/invoices",
  INVOICES_ADD: "/invoices",
  INVOICE_SEND: "/resend-invoice-email",
  CONTACT_INVOICE: "/contact-invoices",
  INVOICE_PAY: "/invoices/paynow",

  // Credit Note
  GET_CREDIT_NOTE: "/credit-notes",
  ADD_CREDIT_NOTE: "/credit-notes",
  CREDIT_SEND: "/resend-credit-notes-email",

   // Quotes
   GET_QUOTES: "/quotes",
   QUOTES_DELETE: "/quotes",
   QUOTES_DETAILS: "/quotes",
   QUOTES_ADD: "/quotes",
   QUOTE_SEND: "/resend-quote-email",

  // Receipts
  GET_RECEIPTS: "/receipts",
  RECEIPTS_DELETE: "/receipts",
  RECEIPTS_DETAILS: "/receipts",
  RECEIPTS_ADD: "/receipts",
  RECEIPT_SEND: "/resend-receipt-email",

  // Invoicing Settings
  GET_INVOICE_SETTINGS: "/invoicing-settings/invoice",
  GET_QUOTE_SETTINGS: "/invoicing-settings/quote",
  GET_RECEIPT_SETTINGS: "/invoicing-settings/receipt",
  INVOICE_SETTINGS: "/template-settings",
  TEMPLATE_DEFAULT: "/template-settings-default",


  //Banking
  BANK_TRANSACTION_LIST: "/bank-transactions",
  BANK_TRANSACTION_BATCHES: "/bank-transaction-batches",
  TRANSACTION_DELETE: "/bank-transactions",
  BANK_TRANSACTION_DELETE_ALL: "/bank-transactions/delete-all",
  BANK_TRANSACTION_BY_BATCHES: "/bank-transaction-batches",
  REFRESH_BANK_TRANSACTION: "/yodlee/transactions",
  BANK_RECONCILIATION_LIST: "/bank-reconciliations",
  UPDATE_BANK_RECONCILIATION: "/bank-reconciliations",
  BANK_RECONCILIATION_BULK: "/bank-reconciliations/bulk",
  EMAIL_FOR_CLARIFICATION: "/bank-reconciliations/email-for-clarification",
  GET_ACCOUNTS: "/get-accounts",
  ADD_BANK_TRANSACTION: "/bank-transactions",
  UPDATE_BANK_TRANSACTION: "/bank-transactions",
  UPLOAD_INVOICE_BANK_TRANSACTION: "/bank-transactions",
  BANK_RECONCILED_TRANSACTION_UNDO_ALL: "/bank-reconciliations/bulk-undo",
  BANK_RECONCILED_TRANSACTION_REALLOCATE: "/bank-reconciliations/reallocate",
  PERMANETALY_DELETE_BANK_STATEMENT: "bank-transactions/delete-permanent",


  //Report
  GET_TRIAL_BALANCE_DATA: "/reports/trial-balance-report",
  GET_TRIAL_BALANCE_ENTRIES_DATA: "/reports/trial-balance-entries-report",
  BANK_RECONCILED_TRANSACTION_BULK_REALLOCATE: "/bank-reconciliations/bulk-reallocate",

  GENERAL_LEDGER_REPORT: "/reports/general-ledger-report",
  LEDGER_ENTRY_REPORT: "/reports/ledger-entries-report",
  CNTACT_STATEMENT_REPORT: "/reports/contact-statement-report",
  ACCOUNT_TRANSACTION_REPORT: "/reports/account-transactions",
  PROFIT_AND_LOSS_REPORT: "/reports/profit-and-loss-report",
  GET_PROFIT_AND_LOSS_ENTRIES_DATA: "/reports/profit-and-loss-entries-report",
  MONTHLY_BANK_RECONCILIATION_REPORT: "/reports/monthly-bank-reconciliation-report",
  MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES: "/reports/monthly-bank-reconciliation-entries-report",
  BALANCE_SHEET_REPORT: "/reports/balance-sheet-report",
  GET_BALANCE_SHEET_ENTRIES_DATA: "/reports/balance-sheet-entries-report",

  //Users
  GET_USER: "/users",
  USER: "/users",

  //Office
  GET_OFFICE: "/offices",
  OFFICE: "/offices",

  //Role
  GET_ROLE_LIST: "/roles",

  //Business Dashboard
  GET_BUSINESS_DASHBOARD: "/business-dashboard",
  GET_BANK_CONCILEDATA: "/dashboard-reconciled-data",

  //Invite Accounting Firm
  INVITE_ACCOUNTING_FIRM: "/invite-accounting-firm",
  GET_INVITATIONS: "/invitations",
  INVITATIONS: "/invitations",

  //General Setting
  ACCOUNTING_FIRM_SETTINGS: "/accounting-firms",

  //BAS
  SUMMARY_REPORT: "/reports/gst-summary-bas",
  GST_TRANSACTION: "/reports/gst-transactions-bas",
  GST_TRANSACTION_DETAILS: "/reports/gst-transactions-details-bas",

  //Admin
  ADMIN_DASHBOARD: "/admin/dashboard",
  GET_SUBSCRIPTION_FOR_ADMIN: "/admin/subscriptions",
  GET_REGISTERED_USER_FOR_ADMIN: "/admin/registered-users",
  REGISTERED_USER_SUBSCRIPTIONS: "/admin/subscriptions/user/detail",
  REGISTERED_USER_SUBSCRIPTIONS_INVOICE: "/admin/subscriptions/invoices",

  //Discount Codes
  GET_DISCOUNT_CODE: "/discount-codes",
  ADD_DISCOUNT_CODE: "/discount-codes",
  UPDATE_DISCOUNT_CODE: "/discount-codes",
  DELETE_DISCOUNT_CODE: "/discount-codes",

  //Standard Paragrah
  GET_STANDARD_PARAGRAH: "/paragraphs",
  ADD_STANDARD_PARAGRAH: "/paragraphs",
  UPDATE_STANDARD_PARAGRAH: "/paragraphs",
  DELETE_STANDARD_PARAGRAH: "/paragraphs",

  //Templates
  GET_TEMPLATES: "/templates",
  ADD_TEMPLATES: "/templates",
  UPDATE_TEMPLATES: "/templates",
  DELETE_TEMPLATES: "/templates",

  //Global Search
  GLOBAL_SEARCH: "/global-search"
};

export function get(endPoint, params = {}) {
  return instance.get(endPoint, params);
}

export function deleteCall(endPoint) {
  return instance.delete(endPoint);
}

export function putCall(endPoint, params) {
  return instance.put(endPoint, params);
}

export function post(endPoint, params, isStringify = true) {
  return instance.post(
    endPoint,
    params && (isStringify ? JSON.stringify(params) : qs.stringify(params))
  );
}

export function fileUpload(endPoint, params, config) {
  return instance.post(endPoint, params, config);
}

export function fileUploadPut(endPoint, params, config) {
  return instance.put(endPoint, params, config);
}

// export const imageDefaultPrefixPath =
// "http://029c-43-242-122-212.ngrok.io/client/sme-cashbook-api/public/uploads/";

export const s3DefaultPath =
  "https://sme-cashbook-file-storage.s3.ap-southeast-2.amazonaws.com/public/uploads/";

export const imageDefaultPrefixPath = defaultBaseUrl() + "uploads/";

//Yodlee
export const yodleeFastLink =
  "https://fl4.prod.yodlee.com.au/authenticate/ANZDevexProd-248/fastlink?channelAppName=anzdevexprod";

export const yodleeConfigName = "CAB_Aggregation";

export const yodleeDefaultImg =
  "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/plus-512.png";

export const IV = "6d49gdf7hf4h26gf";
export const SECRETKEY = "VahHC3EdXsqFuB5UKwux7Ww6eDQcJVaP";

export const entityTypes = [
  { label: "Company", value: "Company" },
  // { label: "Global", value: "Global" },
  { label: "Partnership", value: "Partnership" },
  { label: "Sole Trader", value: "Sole Trader" },
  { label: "Superfund", value: "Superfund" },
  { label: "Trust", value: "Trust" },
  { label: "Other", value: "Other" },
];

export const metaTitle = "SME Cashbook";