import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API, fileUpload, get } from '../../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spin from '../../common/Spin';
import { Button } from 'react-bootstrap';
import { MonthPicker } from '@semcore/date-picker';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../common/Pagination';

const MonthlyBankReconciliationReport = ({
  setBalanceInfo,
  BusinessName,
  setValueRange,
  setBankAccountId
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const month = query.get('month');
  const today = Date.now();
  var makeDate = new Date(today);
  makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
  const params = useParams();
  const businessId = params?.business_id;
  const defaultDate = moment(makeDate).format('MM-YYYY');
  const [date, setDate] = useState(month || defaultDate);
  const [monthlyData, setMonthlyData] = useState();
  // const [businessName, setBusinessName] = useState('');
  const [exportLoading, setExportLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState();
  const [accountloading, setAccountLoading] = useState(false);
  const [accountId, setAccountId] = useState();

  const [expandAdd, setExpandAdd] = useState(false);
  const [expandDeduct, setExpandDeduct] = useState(false);
  const [expandLoading, setExpandLoading] = useState(false);
  const [expandDataAdd, setExpandDataAdd] = useState();
  const [expandDataDeduct, setExpandDataDeduct] = useState();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState("transactionDate");
  const [sortOrder, setSortOrder] = useState("asc");

  const [pageDeduct, setPageDeduct] = useState(1);
  const [limitDeduct, setLimitDeduct] = useState(10);
  const [totalDeduct, setTotalDeduct] = useState();
  const [fromDeduct, setFromDeduct] = useState();
  const [toDeduct, setToDeduct] = useState();
  const [sortFieldDeduct, setSortFieldDeduct] = useState("transactionDate");
  const [sortOrderDeduct, setSortOrderDeduct] = useState("asc");

  const [isDataLoading, setIsDataLoading] = useState(false);

  const formatNumber = (value) => {
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getAccounts = useCallback(async () => {
    try {
      setAccountLoading(true);
      const { data } = await get(
        `${API.GET_ACCOUNTS}?client_business_id=${businessId ? businessId : ''}`
      );
      const account =
        data?.data &&
        data?.data?.length > 0 &&
        data?.data?.map((item) => {
          return {
            label: `${item?.provider?.providerName} (${
              item?.accountNumber
            }) - ${capitalizeFirstLetter(item?.container)}`,
            value: item?.id
          };
        });
      setAccountLoading(false);
      setAccountOptions(account ? account : []);
      setAccountId(account?.[0].value);
      setBankAccountId(account?.[0].value);
    } catch (error) {
      setAccountLoading(false);
      setAccountOptions([]);
    }
    // eslint-disable-next-line
  }, [businessId]);

  useEffect(() => {
    getAccounts();
    // let data = date.split('-');
    // setValueRange([new Date(data[1], data[0] - 1, 1), new Date(data[1], data[0], 0)]);
    
    // eslint-disable-next-line
  }, [getAccounts]);

  const handleAccountChange = (item) => {
    setAccountId(item?.value);
    setBankAccountId(item?.value);
  };

  const fetchMonthlyBankReconciliationData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const formData = new FormData();
      formData.append('client_business_id', businessId);
      formData.append('month', date);
      formData.append('yodlee_account_id', accountId);
      const { data } = await fileUpload(`${API.MONTHLY_BANK_RECONCILIATION_REPORT}`, formData);
      setMonthlyData(data?.data);
      let accountActiveList = [];
      if (data?.data?.accounts) {
        accountActiveList = data?.data?.accounts?.filter((i) => i?.accountStatus === 1);
      }
      setBalanceInfo(accountActiveList);
      setIsDataLoading(false);
      toast.success(data?.message);
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setIsDataLoading(false);
    }
  }, [setBalanceInfo, date, businessId, accountId]);

  useEffect(() => {
    if (accountId !== undefined && accountId !== null) {
      fetchMonthlyBankReconciliationData();
    }
  }, [fetchMonthlyBankReconciliationData, accountId]);

  useEffect(() => {
    navigate(
      date
        ? `/business/${businessId}/reconcile-transactions?page=1&tab=monthly-bank-reconciliation&month=${date}`
        : `/business/${businessId}/reconcile-transactions?page=1&tab=monthly-bank-reconciliation`,
      {
        replace: true
      }
    );
    // eslint-disable-next-line
  }, [date]);

  const handleExportReport = async () => {
    setExportLoading(true);
    try {
      const formData = new FormData();
      formData.append('client_business_id', businessId);
      formData.append('month', date);
      formData.append('yodlee_account_id', accountId);
      formData.append('export', 1);

      const { data } = await fileUpload(`${API.MONTHLY_BANK_RECONCILIATION_REPORT}`, formData);

      const { file } = data?.data;

      if (file) {
        const response = await axios.get(`${file}`, {
          responseType: 'blob'
        });
        const fileData = response?.data;

        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var files = file.split('/');
        a.setAttribute('download', files[files.length - 1] || 'monthly_bank_reconciliation.pdf');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (error) {
      setExportLoading(false);
      const { data } = error?.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data?.errors && data?.errors.myna_error && data?.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  function monthAddPadding(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const lastDate = (date) => {
    var data = date.split('-');
    let lastMonthDate = new Date(data[1], data[0], 0);
    return (
      lastMonthDate.getDate() +
      '/' +
      monthAddPadding(lastMonthDate.getMonth() + 1, 2) +
      '/' +
      lastMonthDate.getFullYear()
    );
  };

  const toggleExpandRowAdd = () => {
    setExpandAdd(!expandAdd);
  };

  const toggleExpandRowDeduct = () => {
    setExpandDeduct(!expandDeduct);
  };

  const fetchMonthlyBankReconciliationReportEntriesAdd = useCallback(
    async () => {
      const formData = new FormData();
      formData.append('client_business_id', businessId);
      formData.append('baseType', 1);
      formData.append('limit', limit ? limit : 10);
      formData.append('page', page ? page : 1);
      formData.append('month', date);
      formData.append('yodlee_account_id', accountId);
      formData.append('sort_column', sortField)
      formData.append('sort_order', sortOrder)
      try {
        if (businessId) {
          setExpandLoading(true);
          const { data } = await fileUpload(`${API.MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES}`, formData);
          setExpandLoading(false);
          setLimit(data?.data?.per_page);
          setPage(data?.data?.current_page);
          setTotal(data?.data?.total);
          setFrom(data?.data?.from);
          setTo(data?.data?.to);
          setExpandDataAdd(data?.data?.data);
        }
      } catch (e) {
        const { data } = e;
        setExpandLoading(false);
        setExpandDataAdd([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
      //eslint-disable-next-line
    },
    [businessId, limit, accountId, date, page, sortOrder, sortField]
  );

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const ExpandDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: expandDataAdd[i]?.id,
        transactionDate: expandDataAdd[i]?.transactionDate ? expandDataAdd[i]?.transactionDate : '-',
        amount: expandDataAdd[i]?.amount ? `$${formatNumber(expandDataAdd[i]?.amount.toFixed(2))}` : '$0.00',
        description: expandDataAdd[i]?.description ? expandDataAdd[i]?.description : '-',
        reconciliationStatus: expandDataAdd[i]?.bank_reconciliation?.[0]?.status === "2" ? "Under Review" : 'Pending'
      });
    }
    return items;
  };

  const expandDataAdds = ExpandDataGenerator(expandDataAdd?.length);

  const handleUpdateLimitDeduct = (e) => {
    const limitDeduct = e.target.value;
    setLimitDeduct(limitDeduct);
    setPageDeduct(1);
  };

  const handleChangePageDeduct = (page) => {
    setPageDeduct(page);
  };

  const fetchMonthlyBankReconciliationReportEntriesDeduct = useCallback(
    async () => {
      const formData = new FormData();
      formData.append('client_business_id', businessId);
      formData.append('baseType', 0);
      formData.append('limit', limitDeduct ? limitDeduct : 10);
      formData.append('page', pageDeduct ? pageDeduct : 1);
      formData.append('month', date);
      formData.append('yodlee_account_id', accountId);
      formData.append('sort_column', sortFieldDeduct)
      formData.append('sort_order', sortOrderDeduct)
      try {
        if (businessId) {
          setExpandLoading(true);
          const { data } = await fileUpload(`${API.MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES}`, formData);
          setExpandLoading(false);
          setLimitDeduct(data?.data?.per_page);
          setPageDeduct(data?.data?.current_page);
          setTotalDeduct(data?.data?.total);
          setFromDeduct(data?.data?.from);
          setToDeduct(data?.data?.to);
          setExpandDataDeduct(data?.data?.data);
        }
      } catch (e) {
        const { data } = e;
        setExpandLoading(false);
        setExpandDataDeduct([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
      //eslint-disable-next-line
    },
    [businessId, limitDeduct, accountId, date, pageDeduct, sortFieldDeduct, sortOrderDeduct]
  );

  const ExpandDataGeneratorDeduct = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: expandDataDeduct[i]?.id,
        transactionDate: expandDataDeduct[i]?.transactionDate ? expandDataDeduct[i]?.transactionDate : '-',
        amount: expandDataDeduct[i]?.amount ? `$${formatNumber(expandDataDeduct[i]?.amount.toFixed(2))}` : '$0.00',
        description: expandDataDeduct[i]?.description ? expandDataDeduct[i]?.description : '-',
        reconciliationStatus: expandDataDeduct[i]?.bank_reconciliation[0]?.status === "2" ? "Under Review" : 'Pending'
      });
    }
    return items;
  };

  const expandDataDeducts = ExpandDataGeneratorDeduct(expandDataDeduct?.length);

  const columns = [
    {
      dataField: 'transactionDate',
      text: 'Transaction Date',
      sort: true,
      style: { width: "12%" }
    },
    {
      dataField: 'amount',
      text: 'Amount',
      style: { width: "12%" }
    },
    {
      dataField: 'description',
      text: 'Description'
    },
    {
      dataField: 'reconciliationStatus',
      text: 'Reconciliation Status',
      style: { width: "13%" }
    }
  ];

  useEffect(() => {
    if (expandAdd) {
      fetchMonthlyBankReconciliationReportEntriesAdd();
    }
    if (expandDeduct) {
      fetchMonthlyBankReconciliationReportEntriesDeduct();
    }
  }, [expandAdd, expandDeduct, fetchMonthlyBankReconciliationReportEntriesAdd, fetchMonthlyBankReconciliationReportEntriesDeduct]);

  const currentDate = new Date();

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleTableChangeDeduct = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPageDeduct(1);
      setSortFieldDeduct(sortField);
      setSortOrderDeduct(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };
  return (
    <>
      <div className="table-top-btn trial-btn monthly-bank-reconciliation-page">
        <div className="monthly-bank-reconciliation-btn">
          <MonthPicker
            format="MM-YYYY"
            picker="month"
            value={month == null ? moment(date, 'MM-YYYY') : moment(month, 'MM-YYYY')}
            onChange={(e) => {
              setDate(moment(e).format('MM-YYYY'));
              // let data = moment(e).format('MM-YYYY').split('-');
              // setValueRange([new Date(data[1], data[0] - 1, 1), new Date(data[1], data[0], 0)]);
            }}
            disabled={[[currentDate, false]]}
            className="datefilter"
            unclearable={true}
          />
        </div>
        <div className="bank-account-dropdown">
          <p>Account</p>
          <Select
            style={{
              width: '50%',
              margin: '0'
            }}
            placeholder="Select Account"
            value={
              accountId ? accountOptions?.find((i) => i.value === accountId) : accountOptions?.[0]
            }
            onChange={(item) => {
              handleAccountChange(item);
            }}
            loading={accountloading}
            classNamePrefix="account_select_bank"
            options={accountOptions}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'grey'
              }
            })}
          />
        </div>
        <div className="table-btn">
          <Button onClick={() => handleExportReport()} disabled={exportLoading} type="print">
            {exportLoading ? 'Loading…' : 'Export'}
          </Button>
        </div>
      </div>
      <div className="monthly-bank-reconciliation" id="monthly-bank-reconciliation">
        {(isDataLoading || accountloading) && <Spin />}
        <div className="row">
          <div className="col-md-12 center-align dark-row border">{BusinessName}</div>
        </div>
        <div className="row">
          <div className="col-md-2 center-align dark-row border">Account Number</div>
          <div className="col-md-3 center-align dark-row border">Account Name</div>
          <div className="col-md-2 center-align dark-row border">Date of Reconciliation</div>
          <div className="col-md-2 center-align dark-row border">End of month bank statement</div>
          <div className="col-md-3 center-align dark-row border">
            End of month bank account balance ($)
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 center-align white-row border">
            {' '}
            {monthlyData?.accountDetails?.accountNumber
              ? monthlyData?.accountDetails?.accountNumber
              : ''}
          </div>
          <div className="col-md-3 center-align white-row border">
            {monthlyData?.accountDetails?.accountName
              ? monthlyData?.accountDetails?.accountName
              : ''}
          </div>
          <div className="col-md-2 center-align white-row border">
            {currentDate
              ? currentDate.getDate() +
                '/' +
                monthAddPadding(currentDate.getMonth() + 1, 2) +
                '/' +
                currentDate.getFullYear()
              : ''}
          </div>
          <div className="col-md-2 center-align white-row border">
            {' '}
            {date ? lastDate(date) : ''}
          </div>
          <div className="col-md-3 right-align white-row border">
            {monthlyData?.bankBalance ? formatNumber(monthlyData?.bankBalance) : '0.00'}
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 center-align dark-row border">Reconciliation</div>
          <div className="col-md-5 right-align dark-row border">Value ($)</div>
        </div>
        <div className="row">
          <div className="col-md-12 center-align dark-row border">
            Bank Statement Reconciliation
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 left-align white-row border">
            Account balance on {date ? lastDate(date) : ''}
          </div>
          <div className="col-md-5 right-align white-row border">
            {monthlyData?.bankBalance ? formatNumber(monthlyData?.bankBalance) : '0.00'}
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-7 left-align white-row border">
            <span style={{ cursor: 'pointer' }} onClick={toggleExpandRowAdd}>
              {expandAdd ? (
                <i className="fa fa-caret-down"></i>
              ) : (
                <i className="fa fa-caret-right"></i>
              )}
            </span>
            <span
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => {
                let data =date.split('-');
                setValueRange([new Date(data[1], data[0] - 1, 1), new Date(data[1], data[0], 0)]);
                navigate(
                  `/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`
                );
              }}
            >
              Deduct: Unreconciled Deposits on Bank Statement
            </span>
          </div>
          <div className="col-md-5 right-align white-row border">
            {' '}
            {monthlyData?.notReconciledCredit
              ? formatNumber(monthlyData?.notReconciledCredit)
              : '0.00'}
          </div>
        </div>
        {expandAdd && (
          <div className="row">
            <div className="col-md-12 monthly-expand-table border">
              <div className="custom-table">
                {expandLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  onTableChange={handleTableChange}
                  data={expandDataAdds}
                  columns={columns}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}

                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-7 left-align gray-row border">Subtotal</div>
          <div className="col-md-5 right-align gray-row border">
            {monthlyData?.bankBalance - monthlyData?.notReconciledCredit
              ? formatNumber(monthlyData?.bankBalance - monthlyData?.notReconciledCredit)
              : '0.00'}
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 left-align white-row border">
          <span style={{ cursor: 'pointer' }} onClick={toggleExpandRowDeduct}>
              {expandDeduct ? (
                <i className="fa fa-caret-down"></i>
              ) : (
                <i className="fa fa-caret-right"></i>
              )}
            </span>
            <span
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => {
                let data =date.split('-');
                setValueRange([new Date(data[1], data[0] - 1, 1), new Date(data[1], data[0], 0)]);
                navigate(
                  `/business/${businessId}/reconcile-transactions?tab=reconcile-transactions`
                );
              }}
            >
              Add: Unreconciled Payments on Bank Statement
            </span>
          </div>
          <div className="col-md-5 right-align white-row border">
            {monthlyData?.notReconciledDebit
              ? formatNumber(monthlyData?.notReconciledDebit)
              : '0.00'}
          </div>
        </div>
        {expandDeduct && (
          <div className="row">
            <div className="col-md-12 monthly-expand-table border">
              <div className="custom-table">
                {expandLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  data={expandDataDeducts}
                  columns={columns}
                  noDataIndication="No Data Found"
                  remote
                  onTableChange={handleTableChangeDeduct}
                />
                <Pagination
                  total={totalDeduct}
                  limit={parseInt(limitDeduct)}
                  currentPage={pageDeduct}
                  updateLimit={handleUpdateLimitDeduct}
                  updatePage={handleChangePageDeduct}
                  from={fromDeduct}
                  to={toDeduct}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-7 left-align light-theam-row border">
            Total
          </div>
          <div className="col-md-5 right-align light-theam-row border">
            {(monthlyData?.bankBalance -
            monthlyData?.notReconciledCredit) +
            monthlyData?.notReconciledDebit
              ? formatNumber(
                (monthlyData?.bankBalance -
                  monthlyData?.notReconciledCredit) +
                  monthlyData?.notReconciledDebit
                )
              : '0.00'}
          </div>
        </div>
        {/* General Ledger Balance (Net Movement plus Opening Balance) */}
        {/* <div className="row">
          <div className="col-md-7 left-align dark-row border">
            Balance as per Trial Balance Report
          </div>
          <div className="col-md-5 right-align dark-row border">
            {monthlyData?.balanceAsPerTrial ? formatNumber(monthlyData?.balanceAsPerTrial) : '0.00'}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MonthlyBankReconciliationReport;
